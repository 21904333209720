import React, { ReactElement } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getTimeDurationByGivenTimestamp } from "../../../../services/timeService";
import { Link } from "react-router-dom";
import { getDateForLink } from "../../../../services/reportsService";
import { ICurrentTeam } from "../../../../types/teams";
import {
  ISelectionRange,
  IUserProjectsPeople,
} from "../../../../types/reports";

interface IProps {
  userProjects: IUserProjectsPeople[];
  durationTimeFormat: string;
  selectionRange: ISelectionRange;
  currentTeam: ICurrentTeam;
}

const ReportsProjectsListByUsers = ({
  userProjects,
  durationTimeFormat,
  selectionRange,
  currentTeam,
}: IProps): ReactElement => {
  const sortedUsers = _.orderBy(userProjects, ({ total }) => total, ["desc"]);

  const sortedUserProjects = _.map(sortedUsers, (userProjects) => ({
    ...userProjects,
    projects: _.orderBy(userProjects.projects, ({ duration }) => duration, [
      "desc",
    ]),
  }));

  return (
    <div className="projects_data">
      {sortedUserProjects.map((user) => (
        <div className="projects_user" key={user.username}>
          <div className="projects_user_data">
            <div className="user-name">{user.username}</div>
            <div className="project-name" />
            <div className="time">
              {getTimeDurationByGivenTimestamp(user.total, durationTimeFormat)}
            </div>
          </div>
          {user.projects.map((project, index) => (
            <Link
              to={{
                pathname: `/reports/detailed/projects/${encodeURIComponent(
                  project.name,
                )}/team/${user.email || "all"}/from/${getDateForLink(
                  selectionRange.startDate,
                )}/to/${getDateForLink(selectionRange.endDate)}/teamId/${
                  currentTeam.data.id
                }`,
                prevPageReport: true,
              }}
              style={{ textDecoration: "none" }}
              key={user.username + index}
            >
              <div className="projects_user_data">
                <div className="user-name" />
                <div className="project-name">{project.name}</div>
                <div className="time">
                  {getTimeDurationByGivenTimestamp(
                    project.duration,
                    durationTimeFormat,
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
  currentTeam: store.teamReducer.currentTeam,
  durationTimeFormat: store.userReducer.durationTimeFormat,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsProjectsListByUsers);
