import React, { Component } from "react";
import PropTypes from "prop-types";
import { LaneTitle, NewLaneButtons, Section } from "../Base";
import { AddButton, CancelButton } from "../Elements";
import NewLaneTitleEditor from "react-trello/dist/widgets/NewLaneTitleEditor";
import uuidv1 from "uuid/v1";

class NewLane extends Component {
  updateTitleValidation = (value) => {
    this.setState({ isEmpty: value });
  };

  handleSubmit = () => {
    if (this.getValue().length === 0) {
      this.updateTitleValidation(true);
    } else {
      this.updateTitleValidation(false);
      this.props.onAdd({
        id: uuidv1(),
        title: this.getValue(),
      });
    }
  };

  getValue = () => this.refInput.getValue();

  onClickOutside = (a, b, c) => {
    if (this.getValue().length > 0) {
      this.handleSubmit();
    } else {
      this.props.onCancel();
    }
  };

  render() {
    const { onCancel, t } = this.props;
    return (
      <Section>
        <LaneTitle>
          <div
            style={{
              outline: this.state?.isEmpty ? "solid 2px red" : "none",
            }}
          >
            <NewLaneTitleEditor
              ref={(ref) => (this.refInput = ref)}
              placeholder={t("placeholder.title")}
              onCancel={this.props.onCancel}
              onSave={this.handleSubmit}
              resize="vertical"
              border
              autoFocus
            />
          </div>
        </LaneTitle>

        <NewLaneButtons>
          <AddButton onClick={this.handleSubmit}>
            {t("button.Add lane")}
          </AddButton>
          <CancelButton onClick={onCancel}>{t("button.Cancel")}</CancelButton>
        </NewLaneButtons>
      </Section>
    );
  }
}

NewLane.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

NewLane.defaultProps = {};

export default NewLane;
