import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import AllPlans from "../../components/Unstructured/Subscriprions/AllPlans";
import OverView from "../../components/Unstructured/Subscriprions/OverView";
import InvAndPaym from "../../components/Unstructured/Subscriprions/InvAndPaym";
import ModalPortal from "../../components/Unstructured/ModalPortal";
import BillingInfoModal from "../../components/Unstructured/Subscriprions/BillingInfoModal";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

import {
  getCustomerDataAction,
  getSubscriptionDataAction,
  resetCoupon,
  setProductIdForUpgrade,
} from "../../redux/actions/SubscriptionPageAction";
import { AppConfig } from "../../config";

import "./style.scss";
import { Loading } from "../../components/Unstructured/Loading";
import { withRouter, useParams } from "react-router-dom";
import { getUserDataAction } from "../../redux/actions/UserActions";
import { checkAccessByRole, ROLES } from "../../services/authentication";
import classNames from "classnames";

const stripePromise = loadStripe(AppConfig.stripePublicKey);

const PERIODS = {
  MONTH: "MONTH",
  YEAR: "YEAR",
};

const tabsMapReverse = {
  0: "plans",
  1: "overview",
  2: "history",
};

const pricesPeriodMap = {
  [PERIODS.MONTH]: "month",
  [PERIODS.YEAR]: "year",
};

const CustomTabs = withStyles({
  indicator: {
    height: "3px",
    backgroundColor: " #00B173",
  },
  "@media (max-width: 768px)": {
    flexContainer: {
      flexDirection: "column",
    },
    indicator: {
      backgroundColor: "transparent",
    },
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    width: "auto",
    padding: "10px 0 0 0",
    margin: "0 20px",
    textTransform: "none",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#aaa5a5",
    opacity: 1,
    "&:hover": {
      color: "#00B173",
    },
    "&$selected": {
      color: " #00B173",
    },
    "&:focus": {
      color: "#00B173",
    },
  },
  "@media (max-width: 768px)": {
    root: {
      maxWidth: "100%",
      padding: "0",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const SubscriptionPage = ({
  vocabulary,
  history,
  isInitialFetching,
  getSubscriptionDataAction,
  getCustomerDataAction,
  plansData,
  customerData,
  trialAvailable,
  getUserDataAction,
  resetCoupon,
  currentTeam,
  setProductIdForUpgrade,
  userRole,
}) => {
  const { tab: tabParam } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showBilling, setShowBilling] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [period, setPeriod] = useState(PERIODS.MONTH);
  const [computedPlans, setComputedPlans] = useState([]);
  const {
    v_subscription,
    v_hr_people,
    v_page_access_denied,
    v_page_access_request_owner,
  } = vocabulary;

  const PLAN_KEYS = ["Free", "Starter", "Business"];

  const PLANS = {
    Free: {
      current: true,
      // best: " ",
      title: "Free forever",
      class: "free",
      color: "#8492A5",
      priceM: 0,
      priceY: 0,
      id: 1,
      backText: "Free cloud",
      description: [
        "Unlimited time tracking",
        "Unlimited projects, clients",
        "Reports",
        "Invoicing",
        "Web app, mobile app",
        "Data history – 90 days",
        "Teams (for dedicated teams, etc)",
      ],
      additional: ["Decimal time format", "Integrations"],
      image: "../../../subCat.png",
    },
    Starter: {
      // best: "Best value",
      title: "Starter",
      class: "basic",
      color: "#27AE60",
      priceM: 4,
      priceY: 39,
      id: 2,
      description: [
        "Everything in Free +",
        "Advanced invoicing",
        "Exportable Reports",
        "Base of employees",
        "Roles Management",
        "Billable hours",
        "Tags for users and profile setup (level)",
        "Unlimited Data history",
      ],
      additional: [
        "Reminders",
        "Auto pause timer",
        "Rounding time",
        "Email time reports",
        "Notifications",
      ],
      image: "../../../subCat.png",
    },
    Business: {
      // best: " ",
      title: "Business",
      class: "pro",
      color: "#299bc1",
      priceM: 7,
      priceY: 65,
      id: 3,
      description: [
        "Everything in Starter+",
        "Resource planning (workload, day off, vacations)",
        "Real-time reports",
        "Teams (for dedicated teams, etc)",
        "Cross-team reports",
        "Recruiting (demo phase)",
        "Import/Export data to Wobbly (included)",
      ],
      additional: [
        "Jira sync",
        "Priority support",
        "Custom features and integrations",
      ],
      image: "../../../subCat.png",
    },
  };

  const getTabNumberFromParams = (param, tabs) => {
    if (param) {
      let tabNumber = "";

      for (const value in tabs) {
        if (tabs[value] === param) {
          tabNumber = value;
        }
      }

      if (tabNumber) {
        return Number(tabNumber);
      }
      return 0;
    }
    return 0;
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    history.push(tabsMapReverse[newValue]);
  };

  const handleSetBilling = (plan) => {
    setSelectedPlan(plan);
    setProductIdForUpgrade(plan.externalPlanData.id);
    setShowBilling(true);
  };

  const handleResetAll = () => {
    setShowBilling(false);
    setSelectedPlan(null);
    history.push("/subscription/plans");
  };

  const handleFinish = () => {
    handleResetAll();
    getUserDataAction();
    setSelectedTab(1);
    resetCoupon(0);
    history.push(tabsMapReverse[1]);
  };

  const handleCongratsModalClose = () => {
    getUserDataAction();
    setShowBilling(false);
    setSelectedPlan(null);
    resetCoupon(0);
    history.push("/subscription/plans");
  };

  const TABS = [
    {
      title: v_subscription.header.all,
      id: 0,
      component: (
        <AllPlans
          handleSetBilling={handleSetBilling}
          period={period}
          setPeriod={setPeriod}
          plans={computedPlans}
          trialAvailable={trialAvailable}
          isTrialing={customerData?.status === "trialing"}
        />
      ),
    },
    { title: v_subscription.header.overview, id: 1, component: <OverView /> },
    {
      title: v_subscription.header.invAndPaym,
      id: 2,
      component: <InvAndPaym />,
    },
  ];

  useEffect(() => {
    setSelectedTab(getTabNumberFromParams(tabParam, tabsMapReverse));
  }, []);

  useEffect(() => {
    getCustomerDataAction();
    getSubscriptionDataAction();
  }, [getSubscriptionDataAction, getCustomerDataAction]);

  useEffect(() => {
    if (plansData && Boolean(plansData.length) && customerData && currentTeam) {
      const result = PLAN_KEYS.map((key) => {
        const data = PLANS[key];
        const externalPlanData = plansData.find((p) => p.name === key);

        externalPlanData.pricePeriod = externalPlanData.prices.filter(
          (price) =>
            price.recurring.interval === pricesPeriodMap[period] ||
            price.nickname == 0,
        )[0];
        // <---
        // data.current = (customerData.plan.id === externalPlanData.pricePeriod.id)
        // <---
        data.current =
          currentTeam.data.plan.plan_name === externalPlanData.name
          // Для випадку підписок на рік
          //  &&
          // customerData.plan.interval ===
          //   externalPlanData.pricePeriod.recurring.interval;
        data.upgradable =
          customerData.plan?.nickname < externalPlanData.pricePeriod?.nickname;

        return { data, externalPlanData };
      });

      setComputedPlans(result);
    }
  }, [plansData, period, customerData, currentTeam]);

  // Сет початкового періода в залежності від того, яка у юзера підписка. Для випадку підписок на рік
  // useEffect(() => {
  //   if (customerData && customerData.plan.interval) {
  //     setPeriod(PERIODS[customerData.plan.interval.toUpperCase()]);
  //   }
  // }, [plansData]);

  return (
    <Loading flag={isInitialFetching} mode="parentSize" withLogo={false}>
      <div
        className={classNames("subscription-page", {
          "subscription-page--blured":
            userRole &&
            checkAccessByRole(userRole, [
              ROLES.ROLE_MEMBER,
              ROLES.ROLE_INVOICES_MANAGER,
            ]),
        })}
      >
        <Elements
          stripe={stripePromise}
          options={{
            locale:
              vocabulary.lang.short === "uk" ? "ru" : vocabulary.lang.short,
          }}
        >
          <div className="subscription-page">
            {showBilling && (
              <ModalPortal>
                <BillingInfoModal
                  selectedPlan={selectedPlan}
                  period={period}
                  plans={computedPlans}
                  setSelectedTab={setSelectedTab}
                  handleClose={handleResetAll}
                  handleFinish={handleFinish}
                  handleCloseCongrats={handleCongratsModalClose}
                />
              </ModalPortal>
            )}
            <div className="subscription-page__header">
              <div className="subscription-page__tabs">
                <CustomTabs value={selectedTab} onChange={handleChangeTab}>
                  {TABS.map((tab) => (
                    <CustomTab key={tab.id} label={tab.title} />
                  ))}
                </CustomTabs>
              </div>
            </div>
            <div className="subscription-page__content">
              {TABS[selectedTab].component}
            </div>
          </div>
        </Elements>
      </div>
      {userRole &&
        checkAccessByRole(userRole, [
          ROLES.ROLE_MEMBER,
          ROLES.ROLE_INVOICES_MANAGER,
        ]) && (
          <div className="subscription-page__access-blocked">
            <div className="subscription-page__access-blocked-text-block">
              <span className="subscription-page__access-blocked-text">
                {v_page_access_denied}
              </span>
              <span className="subscription-page__access-blocked-text">
                {v_page_access_request_owner}
              </span>
            </div>
          </div>
        )}
    </Loading>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.responsiveReducer.isMobile,
  vocabulary: state.languageReducer.vocabulary,
  isInitialFetching: state.subscriptionPageReducer.isInitialFetching,
  plansData: state.subscriptionPageReducer.plansData,
  customerData: state.subscriptionPageReducer.customerData,
  trialAvailable: state.userReducer.user.subscriptionTrialAvailable,
  currentTeam: state.teamReducer.currentTeam,
  userRole: state.teamReducer.currentTeam.data.role,
});

const mapDispatchToProps = {
  getSubscriptionDataAction,
  getCustomerDataAction,
  getUserDataAction,
  resetCoupon,
  setProductIdForUpgrade,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage),
);
