import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
} from "../../../services/authentication";
import ClientsDropdown from "../ClientsDropdown";
import ProjectsDropdown from "../ProjectsDropdown";
import UsersSelectComponent from "../UsersSelectComponent";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import { getClientsAction } from "../../../redux/actions/ClientsActions";
import projectsPageAction, {
  getRelationProjectsListAction,
  addProjectAction,
  getProjectColorsAction,
  getProjectsListActions,
} from "../../../redux/actions/ProjectsActions";
import "./style.scss";
import { getCurrentTeamDetailedDataAction } from "../../../redux/actions/TeamActions";
import Modal from "../../Atoms/Modal";
import { Loading } from "../Loading";
const ProjectModal = ({ open }) => {
  const dispatch = useDispatch();
  const vocabulary = useSelector((state) => state.languageReducer.vocabulary);
  const clientsList = useSelector((state) => state.clientsReducer.clientsList);
  const relationProjectsList = useSelector(
    (state) => state.projectReducer.relationProjectsList,
  );
  const projectsList = useSelector(
    (state) => state.projectReducer.projectsList,
  );
  const userReducer = useSelector((state) => state.userReducer);
  const teamUsers = useSelector(
    (state) => state.teamReducer.currentTeamDetailedData.data,
  );
  const projectColors = useSelector(
    (state) => state.projectReducer.projectColors,
  );
  const projectRequest = useSelector(
    (state) => state.projectReducer.isFetchingProject,
  );

  const [projectName, setProjectName] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    id: "a642f337-9082-4f64-8ace-1d0e99fa7258",
    name: "green",
  });
  const [listOpen, setListOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showUsersSelect, setShowUsersSelect] = useState(false);

  useEffect(() => {
    dispatch(getProjectColorsAction());
    dispatch(getCurrentTeamDetailedDataAction());
    dispatch(
      getClientsAction({
        filterStatus: "all",
      }),
    );

    if (userReducer.user.tokenJira) {
      dispatch(getRelationProjectsListAction());
    }

    document.addEventListener("mousedown", closeList);

    return () => {
      document.removeEventListener("mousedown", closeList);
    };
  }, []);

  const teamMembersSelector = (teamData) =>
    teamData
      .filter(
        (item) => item.is_active,
        // !checkIsAdminByRole(item.role_collaboration.title) &&
        // !checkIsOwnerByRole(item.role_collaboration.title),
      )
      .map((teamUser) => teamUser.user);

  const teamMembersMemoizedSelector = useCallback(
    teamMembersSelector(teamUsers),
    [teamUsers],
  );

  const addProject = () => {
    if (navigator.onLine === false) {
      dispatch(
        showNotificationAction({
          text: vocabulary.v_connection_problem,
          type: "lost-connection",
        }),
      );
      return false;
    }
    if (!projectName.trim().length) {
      dispatch(
        showNotificationAction({
          text: vocabulary.v_a_project_name_error,
          type: "warning",
        }),
      );
      return false;
    }

    dispatch(
      addProjectAction({
        name: projectName.trim(),
        projectColorId: selectedValue.id,
        clientId: selectedClient ? selectedClient.id : null,
        jiraProjectId: selectedProject ? selectedProject : null,
        users: selectedUsers.map((item) => item.id),
      }),
    );
  };

  const closeList = (e) => {
    if (
      listOpen &&
      !e.target.closest(".create_projects_modal_data_select_container")
    ) {
      setListOpen(false);
    }
  };

  const onProjectNameChange = (value) => {
    if (value.length <= 50) {
      setProjectName(value.replace("  ", " ").trimStart());
    }
    return null;
  };
  const noValidationName = projectName.includes("%");

  return (
    <Modal
      open={open}
      onClose={() =>
        dispatch(
          projectsPageAction("TOGGLE_PROJECT_MODAL", {
            toggle: false,
          }),
        )
      }
      title={vocabulary.v_create_project}
      classes={{
        wrapper: "project-modal",
      }}
    >
      <div className="create_projects_modal_data">
        <div
          className="create_projects_modal_data_input_container"
          data-label={vocabulary.v_add_project_name}
        >
          <input
            className={cn("project-input", {
              "project-input__error": noValidationName === true,
            })}
            type="text"
            value={projectName}
            onChange={(e) => onProjectNameChange(e.target.value)}
            placeholder={`${vocabulary.v_project_name}...`}
          />
          <div
            className="create_projects_modal_data_select_container"
            onClick={(e) => setListOpen(!listOpen)}
          >
            <div className="select_main">
              <div className={`circle ${selectedValue.name}`} />
            </div>
            <i className={`vector ${listOpen ? "vector_up" : ""}`} />
            {listOpen && projectColors && (
              <div className="select_list">
                {projectColors.map((value) => (
                  <div
                    key={value.id}
                    className={"item"}
                    onClick={(e) => setSelectedValue(value)}
                  >
                    <div className={`circle ${value.name}`} />
                  </div>
                ))}
              </div>
            )}
          </div>
          {noValidationName && (
            <div className="no-valid-project-name">
              {vocabulary.v_a_project_no_valid_name}
            </div>
          )}
          <ClientsDropdown
            clientsList={clientsList}
            clientSelect={(data) => setSelectedClient(data ?? null)}
            vocabulary={vocabulary}
          />
          <div
            className="create-project__users-select-wrapper"
            data-label={vocabulary.v_add_member}
          >
            <div
              className="create-project__users-selected"
              onClick={() => setShowUsersSelect(!showUsersSelect)}
            >
              {selectedUsers.length ? (
                <span className="create-project__users-selected-text">
                  {selectedUsers.map((item) => item.username).join(", ")}
                </span>
              ) : (
                <span className="create-project__placeholder">
                  {vocabulary.v_member_dots}
                </span>
              )}
              <i
                className={`create-project__vector ${
                  showUsersSelect ? "create-project__vector--up" : ""
                }`}
              />
            </div>
            {showUsersSelect && (
              <div className="create-project__dropdown-container">
                <UsersSelectComponent
                  users={teamMembersMemoizedSelector}
                  selectedUsers={selectedUsers}
                  vocabulary={vocabulary}
                  toggleSelect={(selectedItems) =>
                    setSelectedUsers(selectedItems)
                  }
                  closePopup={() => setShowUsersSelect(false)}
                />
              </div>
            )}
          </div>
          {/*WOB-3448 remove field for sync with Jira*/}
          {/*{userReducer.user.tokenJira && (*/}
          {/*  <ProjectsDropdown*/}
          {/*    relationProjectsList={relationProjectsList}*/}
          {/*    projectSelect={(data) => setSelectedProject(data ?? null)}*/}
          {/*    vocabulary={vocabulary}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </div>
      <div className="create_projects_modal_button_container">
        {projectRequest && (
          <div className="create_projects_modal_button_container__loader-logo">
            <button disabled={projectRequest}>
              <Loading
                mode="overlay"
                flag={projectRequest}
                withLogo={false}
                circle
                width="3.6rem"
                height="3.6rem"
              />
            </button>
          </div>
        )}
        {!projectRequest && (
          <button
            className="create_projects_modal_button_container_button"
            onClick={addProject}
            disabled={noValidationName === true}
          >
            {vocabulary.v_create_project}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ProjectModal;
