import React from "react";
import "./style.scss";
import { Link, withRouter } from "react-router-dom";

const InvoicesManagerModal = ({ vocabulary, cancelHandler, downloadPDF }) => (
  <div className="confirmation-modal-manager" data-theme={"light"}>
    <div className="confirmation-modal-manager__background" />
    <div className="confirmation-modal-manager__container">
      <i
        className="confirmation-modal-manager__container__close"
        onClick={cancelHandler}
      />
      <h2 className="confirmation-modal-manager__text">
        Please login, create an account, or choose to download immediately
      </h2>
      <div className="confirmation-modal-manager__buttons-wrap">
        <Link
          className="confirmation-modal-manager__button confirmation-modal-manager__button--confirm"
          to="/register"
        >
          {vocabulary.v_login}
        </Link>
        <Link
          className="confirmation-modal-manager__button confirmation-modal-manager__button--create"
          to="/register"
        >
          {"Create Account"}
        </Link>
      </div>

      <div className="confirmation-modal-manager__border">
        <hr className="confirmation-modal-manager__border__left" />
        <span>OR</span>
        <hr className="confirmation-modal-manager__border__right" />
      </div>
      <div className="confirmation-modal-manager__download">
        <a onClick={downloadPDF}>Download without an account</a>
      </div>
    </div>
  </div>
);

export default InvoicesManagerModal;
