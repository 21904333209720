import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppConfig } from "../../../config";
import { apiCall } from "../../../services/apiService";
import cn from "classnames";

// Components
import SwitchLanguageLogin from "../../../components/Unstructured/SwitchLanguageLogin";

// Actions
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

// Styles
import "../style.scss";

class UnsubscribeReports extends Component {
  state = {
    isLoading: false,
  };

  handleUnsubscribe = () => {
    const { vocabulary } = this.props;
    const {
      v_success_unsubscribe_reports,
      v_error_already_unsubscribe_reports,
    } = vocabulary;
    const search = this.props.location.search;
    const userId = new URLSearchParams(search).get("id");
    apiCall(`${AppConfig.apiURL}email/unsubscribe-reports`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: userId,
      }),
    }).then(
      ({ message }) => {
        let text = "";

        switch (message) {
          case "User successfully unsubscribed from reports":
            text = v_success_unsubscribe_reports;
            break;

          default:
            break;
        }

        this.props.showNotificationAction({
          text,
          type: "success",
        });
      },

      (err) => {
        err.json().then(({ message }) => {
          let text = "";

          switch (message) {
            case "Internal server error":
              text = "ERROR";
              break;

            case "ERROR.USER_ALREADY_UNSUBSCRIBED_FROM_REPORTS":
              text = v_error_already_unsubscribe_reports;
              break;

            default:
              break;
          }

          this.props.showNotificationAction({
            text,
            type: "error",
          });
        });
      },
    );
  };

  render() {
    const { vocabulary } = this.props;
    const { v_unsubscribe, v_sure_reports } = vocabulary;

    return (
      <div className="unsubscribe_modal_wrapper">
        <div className="fixed_right_corner">
          <SwitchLanguageLogin dropdown />
        </div>
        <i
          className={cn("page_title", {
            page_title__light: localStorage.getItem("themes") === "light",
          })}
        />

        <div className="unsubscribe_form_container">
          <div>
            <h1>{v_unsubscribe}</h1>
            <div className="question">{v_sure_reports}</div>
          </div>
          <button type="submit" onClick={() => this.handleUnsubscribe()}>
            {v_unsubscribe}
          </button>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  showNotificationAction,
};

export default withRouter(
  connect(null, mapDispatchToProps)(UnsubscribeReports),
);
