import moment from "moment";

export const SHOW_BY_DAY = "day";
export const SHOW_BY_WEEK = "week";
export const SHOW_BY_MONTH = "month";
export const SHOW_BY_YEAR = "year";

export const TIMELINE_UNIT_HOUR = "hour";
export const TIMELINE_UNIT_DAY = "day";
export const TIMELINE_UNIT_MONTH = "month";

export const DAY_TIMESTAMP = 60 * 60 * 24000;
export const WEEK_TIMESTAMP = DAY_TIMESTAMP * 7;
export const MONTH_TIMESTAMP = DAY_TIMESTAMP * 31;
export const YEAR_TIMESTAMP = MONTH_TIMESTAMP * 12;
