import React from "react";
import "./style.scss";

export const NoInfo = ({ text, btnText, buttonHandler = () => {} }) => (
  <div className="no_info_wrapper">
    <div className="no_info_wrapper__header">
      <i className="no_info_wrapper__icon" />
    </div>
    <div className="no_info_wrapper__body">
      <div className="no_info_wrapper__body-text">
        <span>{text}</span>
      </div>
      <button className="no_info_wrapper__body-button" onClick={buttonHandler}>
        <i className="icon-button-plus" />
        {btnText}
      </button>
    </div>
  </div>
);
