import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import "./style.scss";
import { AppConfig } from "../../../config";

// Services
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole,
  ROLES,
} from "../../../services/authentication";
import {
  deleteUserFromProjectAction,
  getProjectsListActions,
} from "../../../redux/actions/ProjectsActions";
import { decodeTimeEntryIssue } from "../../../services/timeEntryService";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";
import { followCursor } from "tippy.js";

// Components
import {
  ArrowIcon,
  EditProjectIcon,
  AddToArchiveIcon,
  RemoveFromArchiveIcon,
  DeleteIcon,
  ReportIcon,
} from "../../Atoms/SvgIcons";
import moment from "moment";

const ProjectsDataRow = ({
  projectData,
  vocabulary,
  setEditItem,
  currentTeam,
  expandedStatus,
  changeProjectActiveStatus,
  changeProjectFavouriteStatus,
  searchValue,
  currentUser,
  TeamUserStatus,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const { name, client, projectColor, user_project } = projectData;
  const {
    v_project_name,
    v_client,
    v_edit,
    v_detailed_proj,
    v_archivate,
    v_unarchivate,
    v_filter_archived,
    v_project_members,
  } = vocabulary;

  useEffect(() => {
    if (expandedStatus) {
      if (!user_project.length) {
        return;
      }
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandedStatus, user_project]);

  const createLinkToProjectReport = ({ projectName, usersEmail, date }) =>
    `/reports/detailed/projects/${encodeURIComponent(
      projectName,
    )}/team/${usersEmail.join(",")}/from/${date.from}/to/${date.to}/teamId/${
      currentTeam.data.id
    }`;

  const path = createLinkToProjectReport({
    projectName: projectData.name,
    usersEmail: projectData.user_project.map((item) => item.user.email),
    date: {
      from: moment().startOf("month").format("YYYY-MM-DD"),
      to: moment().endOf("month").format("YYYY-MM-DD"),
    },
  });

  const userProject = user_project.filter(
    (item) => item.user.id === currentUser.id,
  );
  // const endDate = user_project.map((it) => it.user.timer[0]?.end_datetime);
  const nowDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <tr
        onClick={() => {
          if (!user_project.length) {
            return;
          }
          setExpanded((prev) => !prev);
        }}
        className={classnames("project-row-data", {
          "expanded-row": expanded,
        })}
      >
        <td className="project-row-data__toggle-container">
          {Boolean(user_project.length) && (
            <div className="project-row-data__projects_toggle">
              <div className="project-row-data__toggle-title">
                {v_project_members}
              </div>
              <div
                className={classnames("projects_toggle-icon", {
                  "projects_toggle-icon--rotated": expanded,
                })}
              >
                <ArrowIcon
                  className="projects_toggle-icon__img"
                  color="var(--project-icon-path-color)"
                />
              </div>
            </div>
          )}
        </td>
        <td
          className="project-row-data__name-col"
          data-label={`${v_project_name}: `}
        >
          {name}
          <span
            className="project-row-data__name-color"
            style={{ backgroundColor: projectColor.name }}
          />
        </td>
        <td className="project-row-data__color-col">
          <span>
            {userProject[0]?.favourite === true && (
              <svg
                className="project-row-data__star-orange"
                onClick={(e) => {
                  e.stopPropagation();
                  changeProjectFavouriteStatus(projectData.id, false);
                }}
              />
            )}
            {userProject[0]?.favourite !== true && (
              <svg
                className="project-row-data__star"
                onClick={(e) => {
                  e.stopPropagation();
                  changeProjectFavouriteStatus(projectData.id, true);
                }}
              />
            )}
          </span>
          <div className={`project-row-data__color ${projectColor.name}`} />
        </td>
        <CustomTippy
          content={`${client?.name} ${","} ${client?.company_name}`}
          disabled={!client}
          plugins={[followCursor]}
          followCursor={true}
          arrow={false}
        >
          <td
            className="project-row-data__client-col"
            data-label={`${v_client}: `}
          >
            {client
              ? `${client.company_name} ${
                  client.name ? `(${client.name})` : ""
                }`
              : "-"}
          </td>
        </CustomTippy>

        <td className="project-row-data__buttons-col">
          {(checkIsAdminByRole(currentTeam.data.role) ||
            checkIsOwnerByRole(currentTeam.data.role) ||
            checkIsHRManagerByRole(currentTeam.data.role) ||
            checkIsProjectManagerByRole(currentTeam.data.role)) &&
            (projectData.isActive ? (
              <div className="buttons-wrap">
                <CustomTippy content={v_edit} offset={[0, -5]}>
                  <div>
                    <EditProjectIcon
                      className="edit_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditItem(projectData);
                      }}
                    />
                  </div>
                </CustomTippy>
                <CustomTippy content={v_detailed_proj}>
                  <div>
                    <Link
                      to={{
                        pathname: path,
                        prevPageProgect: true,
                        searchValue: searchValue,
                      }}
                    >
                      <ReportIcon className="report_button" />
                    </Link>
                  </div>
                </CustomTippy>
                <CustomTippy content={v_archivate}>
                  <div>
                    <AddToArchiveIcon
                      className="archive_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        changeProjectActiveStatus(projectData.id, false);
                      }}
                    />
                  </div>
                </CustomTippy>
              </div>
            ) : (
              <div className="unarchive-wrap">
                <div className="unarchive-wrap__text">{v_filter_archived}</div>
                <CustomTippy content={v_unarchivate}>
                  <div>
                    <RemoveFromArchiveIcon
                      className="unarchive_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        changeProjectActiveStatus(projectData.id, true);
                      }}
                    />
                  </div>
                </CustomTippy>
              </div>
            ))}
        </td>
      </tr>
      {expanded &&
        Boolean(user_project.length) &&
        user_project.map((user, index) => (
          <tr
            className={classnames("project-expanded highlighted", {
              "highlighted-last": index === user_project.length - 1,
            })}
            key={user.user.id}
          >
            <td />
            <td className="project-expanded__member">
              <div className="project-expanded__user">
                <div className="project-expanded__user-avatar">
                  {TeamUserStatus && (
                    <div>
                      {TeamUserStatus.filter(
                        (it) => it.id === user.user.id,
                      )[0] &&
                        !TeamUserStatus.filter(
                          (it) => it.id === user.user.id,
                        )[0].timer_current_v2 &&
                        TeamUserStatus.filter((it) => it.id === user.user.id)[0]
                          .timer_v2.length > 0 && (
                          <div
                            className="project-expanded__icon-online"
                            style={{ backgroundColor: "orange" }}
                          />
                        )}
                      {TeamUserStatus.filter(
                        (it) => it.id === user.user.id,
                      )[0] &&
                        TeamUserStatus.filter((it) => it.id === user.user.id)[0]
                          .timer_current_v2 && (
                          <div className="project-expanded__icon-online" />
                        )}
                      {TeamUserStatus.filter(
                        (it) => it.id === user.user.id,
                      )[0] &&
                        !TeamUserStatus.filter(
                          (it) => it.id === user.user.id,
                        )[0].timer_current_v2 &&
                        TeamUserStatus.filter((it) => it.id === user.user.id)[0]
                          .timer_v2.length === 0 && (
                          <div
                            className="project-expanded__icon-online"
                            style={{ backgroundColor: "red" }}
                          />
                        )}
                      {!user.user.avatar ? (
                        <div className="avatar-small" />
                      ) : (
                        <div
                          className="avatar-small avatar-cover"
                          style={{
                            backgroundImage: `url(${AppConfig.apiURL}${user.user.avatar})`,
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="project-expanded__user-name">
                  {user.user.username}
                </div>
              </div>
            </td>
            <td className="project-expanded__user-color" />
            <td className="project-expanded__user-client" />
            <td className="project-expanded__user-time">
              {((checkIsAdminByRole(currentTeam.data.role) &&
                !checkIsOwnerByRole(user.user.role)) ||
                (checkIsOwnerByRole(currentTeam.data.role) &&
                  !checkIsOwnerByRole(user.user.role)) ||
                (checkIsHRManagerByRole(currentTeam.data.role) &&
                  !checkIsOwnerByRole(user.user.role) &&
                  !checkIsAdminByRole(user.user.role)) ||
                (checkIsProjectManagerByRole(currentTeam.data.role) &&
                  !checkIsOwnerByRole(user.user.role) &&
                  !checkIsAdminByRole(user.user.role))) &&
                user.user.role &&
                (currentUser.id === user.user.id ? (
                  <DeleteIcon className="project-expanded__edit-button-icon-disabled" />
                ) : (
                  <DeleteIcon
                    className="project-expanded__edit-button-icon"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await dispatch(
                        deleteUserFromProjectAction(
                          user.user.id,
                          projectData.id,
                        ),
                      );
                      await dispatch(
                        getProjectsListActions({
                          page: 1,
                          withPagination: true,
                          withTimerList: false,
                        }),
                      );
                    }}
                  />
                ))}
            </td>
          </tr>
        ))}
    </>
  );
};

export default ProjectsDataRow;
