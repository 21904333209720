import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";

const Textarea = styled.textarea`
  // background-color: var(--bg-modal-box);
  background-color: var(--background-box-time-day);
  color: var(--text);
  border-radius: 8px;
  border: 1px solid var(--border-color-team);
  padding: 10px 50px 10px 15px;
  // width: 100%;
  resize: none;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none;
  }

  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #b9bbbe;
  }
`;

function AutoExpandingTextarea(props) {
  const { value, width="100%", height="44px", onChange, ...rest } = props;
  const [textareaHeight, setTextareaHeight] = useState(height);
  const maxTextareaHeight = 300; // Set the maximum height as needed
  const textareaRef = useRef(null);

  const handleTextChange = (event) => {
    const { value } = event.target;
    onChange(event);
    adjustTextareaHeight(value);
  };

  useEffect(() => {
    adjustTextareaHeight(value);
  }, []);

  const adjustTextareaHeight = (text) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = height; // Reset the height to auto to recalculate
      const newHeight = Math.min(maxTextareaHeight, textarea.scrollHeight);
      textarea.style.height = `${newHeight}px`; // Set the height to the new height
      textarea.style.overflowY =
        newHeight >= maxTextareaHeight ? "scroll" : "hidden"; // Show scrollbar if needed
    }
  };

  return (
    <Textarea
      ref={textareaRef}
      value={value}
      onChange={handleTextChange}
      style={{
        height: textareaHeight,
        width: width,
        resize: "none",
        overflowY: "hidden",
        maxHeight: `${maxTextareaHeight}px`,
      }}
      {...rest}
    />
  );
}

export default AutoExpandingTextarea;
