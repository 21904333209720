import React from "react";
import "./style.scss";

const InvoicesManagerIconDeleteModal = ({
  vocabulary,
  cancelHandler,
  title,
  deleteInvoice,
}) => (
  <div className="confirmation-modal-manager-icon-delete" data-theme={"light"}>
    <div className="confirmation-modal-manager-icon-delete__background" />
    <div className="confirmation-modal-manager-icon-delete__container">
      <i
        className="confirmation-modal-manager-icon-delete__container__close"
        onClick={cancelHandler}
      />
      <h2 className="confirmation-modal-manager-icon-delete__text">
        Are you sure you want to delete this invoice? This can not be undone.
      </h2>
      <div className="confirmation-modal-manager-icon-delete__buttons-wrap">
        <button
          className="confirmation-modal-manager-icon-delete__button confirmation-modal-manager-icon-delete__button--confirm-delete"
          onClick={deleteInvoice}
        >
          {"Delete Invoice"}
        </button>
        <button
          className="confirmation-modal-manager-icon-delete__button confirmation-modal-manager-icon-delete__button--cancel-delete"
          onClick={cancelHandler}
        >
          {"Cancel"}
        </button>
      </div>
    </div>
  </div>
);

export default InvoicesManagerIconDeleteModal;
