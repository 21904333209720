import React from "react";
import cn from "classnames";
import { connect } from "react-redux";
import "./style.scss";

const SwitcherContent = ({ selected, options, onSelect }) => {
  // Конвертуємо булеве значення в рядок
  const selectedValue = typeof selected === "boolean"
    ? (selected ? "year" : "month")
    : selected;


  return (
    <>
      {options.map((option, index) => (
        <p
          key={index}
          className={cn("preriod-switcher__text", {
            "preriod-switcher__black": selectedValue === option.value
          })}
          onClick={() => onSelect(option.value)}
        >
          {option.label}
          {option.discount && <span className="percent"> {option.discount}</span>}
        </p>
      ))}
      <div
        className={cn("preriod-switcher__runner", {
          "preriod-switcher__right": selectedValue === options[0].value,
          "preriod-switcher__left": selectedValue !== options[0].value,
        })}
      />
    </>
  );
}

const Switcher = ({
                    isMobile = false,
                    selected = "month",
                    options = [
                      { value: "year", label: "Year", discount: "-20%" },
                      { value: "month", label: "Month" }
                    ],
                    onSelect
                  }) => (
  <div className="switcher-info">
    <div className="preriod-switcher">
      <div
        className={
          isMobile
            ? "preriod-switcher__conteiner-mobile"
            : "preriod-switcher__conteiner"
        }
        onClick={() => {}}
      >
        <SwitcherContent selected={selected} options={options} onSelect={onSelect} />
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  isMobile: state.responsiveReducer.isMobile,
});

export default connect(mapStateToProps)(Switcher);
