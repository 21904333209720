import * as Yup from "yup";
export const validationSchema = Yup.object({
  title: Yup.string().max(30, "Max length 30"),
  email: Yup.string().email("Incorect email"),
  phone: Yup.string().max(12, "Incorrect phone number"),
  feedback: Yup.string().max(1000, "Max length 1000"),
  linksArray: Yup.array().max(5, "5 other links available"),
  file: Yup.mixed()
    .test("fileSize", "The file is too large", (value) => {
      return !value || value.size <= 1024 * 1024;
    })
    .test("type", "Only pdf format is accepted", (value) => {
      return !value || value.type === "application/pdf";
    }),
  clientFile: Yup.mixed()
    .test("fileSize", "The file is too large", (value) => {
      return !value || value.size <= 1024 * 1024;
    })
    .test("type", "Only pdf format is accepted", (value) => {
      return !value || value.type === "application/pdf";
    }),
  boardId: Yup.string().required("This field is required"),
});

export const jobType = [
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" },
  { value: "office", label: "Office" },
];

export const levelOptions = [
  { value: "TRAINEE", label: "Trainee" },
  { value: "JUNIOR", label: "Junior" },
  { value: "MIDDLE", label: "Middle" },
  { value: "SENIOR", label: "Senior" },
];
