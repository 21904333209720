import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import _ from "lodash";

import { initSocket } from "../../../api/configSocket";

// Actions
import { setTimerTickAction } from "../../../redux/actions/MainPageAction";

// Services
import {
  checkAppVersion,
  logoutByUnauthorized,
} from "../../../services/authentication";
import { updatePageTitle } from "../../../services/pageTitleService";

import { Loading } from "../Loading";
import { initialPageRequests } from "../../../redux/actions/CombinationsOfActions";

class PrivateRoute extends Component {
  componentDidMount() {
    if (!checkAppVersion()) {
      return logoutByUnauthorized();
    }

    this.props.initialPageRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, currentTimer, setTimerTickAction } = this.props;

    if (!checkAppVersion()) {
      return logoutByUnauthorized();
    }

    if (!prevProps.user && user) {
      initSocket();
    }

    if (!_.isEqual(prevProps.currentTimer, currentTimer)) {
      if (currentTimer) {
        // set timerTick
        if (!this.setInterval) {
          this.setInterval = setInterval(() => {
            setTimerTickAction();
          }, 1000);
        }
      } else {
        // reset timerTick
        updatePageTitle(null);
        clearInterval(this.setInterval);
        this.setInterval = null;
        setTimerTickAction("reset");
      }
    }
  }

  componentWillUnmount() {
    updatePageTitle(null);
    clearInterval(this.setInterval);
  }

  render() {
    const { render, user, isFetching, isInitialFetching, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (isInitialFetching || isFetching || user) {
            if (isInitialFetching || isFetching) {
              return <Loading flag={true} />;
            } else {
              return render();
            }
          } else {
            return <Redirect to="/login" />;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  isFetching: state.userReducer.isFetching,
  isInitialFetching: state.userReducer.isInitialFetching,
  currentTimer: state.mainPageReducer.currentTimer,
});

const mapDispatchToProps = {
  setTimerTickAction,
  initialPageRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
