import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

export interface ISpinner {
  size: number;
}

const spinnerStyles = makeStyles((theme) => ({
  root: {
    color: "#27ae60",
  },
}));

const Spinner = ({ size }: ISpinner): ReactElement => {
  const classes = spinnerStyles();

  return (
    <CircularProgress size={size} thickness={3} className={classes.root} />
  );
};

export default Spinner;
