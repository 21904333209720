import React, { useState } from "react";
import { connect } from "react-redux";
import ReleaseNotesModal from "../ReleaseNotesModal";
import { APP_VERSION, VERSIONS_LIST } from "../../../constants/versions";
import {
  isSameVersion,
  formatDropdownDateForRender,
} from "../../../services/versionService";

import "./style.scss";

const VersionOverlay = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isShowCircle, setIsShowCircle] = useState(!isSameVersion());
  const closeModal = (event) => {
    event.stopPropagation();
    setOpenModal(false);
    setIsShowCircle(false);
  };

  return (
    <div className="version-overlay" onClick={() => setOpenModal(true)}>
      v. {APP_VERSION}
      {openModal && (
        <ReleaseNotesModal
          closeCallback={closeModal}
          selectedVersion={APP_VERSION}
        />
      )}
      {isShowCircle && (
        <ReleaseNotesModal
          closeCallback={closeModal}
          selectedVersion={APP_VERSION}
        />
      )}
      {isShowCircle ? <span className="version-overlay__circle" /> : null}
    </div>
  );
};

export default VersionOverlay;
