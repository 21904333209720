import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

import Modal from "../../../Atoms/Modal";
import { Formik } from "formik";
import {
  ButtonContainer,
  CancelButton,
  CountryPhoneContainer,
  DropdownContainer,
  StyledForm,
  Input,
  LabelContainer,
  LabelPhoneInput,
  SalaryContainer,
  SalaryWrapper,
  SaveButton,
  TextLabel,
  DateContainer,
  LanguageWrapper,
  StyledSVG,
  LinesContainer,
  ErrorText,
  WrapperDeleteModal,
  DeleteButton,
  SuccessWrapper,
  Title,
  TitleInfo,
  WrapperImage,
  WrapperApplicationModal,
} from "./My.styled";
import { uuid } from "uuidv4";

import DropdownWithSearch from "../../../Atoms/DropdownWithSearch";

import "./style.scss";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TechnologyComponent from "../../TechnologyComponent";
import LineIcon from "../../../../assets/images/ComponentIcons/LineIcon";
import CatIcon from "../../../../assets/images/ComponentIcons/CatIcon";
import BackArrowSvg from "../../../../assets/images/ComponentIcons/BackArrow";

import {
  validationSchema,
  optionsCurrency,
  priorityOptions,
  levelOptions,
  expirienceOptions,
  employmentOptions,
  directionsOptions,
} from "./utils";

import { searchInArrayByValue } from "../../../../services/utils/index";
import DatepickerSelect from "../../DatepickerSelect";
import { RequiredfieldStar } from "../CreateVacanciesModal/My.styled";

const defaultDirectionState = {
  isOpen: false,
  type: "edit", // create or edit
  directionId: null,
};

const defoultInithialValues = {
  title: "",
  direction: "",
  level: "",
  experience: "",
  minSalary: "",
  maxSalary: "",
  salary: "",
  deadline: "",
  priority: "",
  country: "",
  countryCode: "",
  city: "",
  employment: "",
  positionCount: "", // змінено на position_count
  project: "",
  languages: [], // перетворено на масив, якщо language не null
  requirement: "",
  comment: "",
};

const CreateNewApplication = forwardRef(
  (
    {
      vocabulary,
      directionData,
      pageDirection,
      handleGetDirections,
      handleCreate,
      handleUpdate,
      removeApplicationHandler,
    },
    ref,
  ) => {
    const [directionModal, setDirectionModal] = useState({
      ...defaultDirectionState,
    });
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [keyFormik, setKeyFormik] = useState(false);

    const [typeForm, setTypeForm] = useState("first");
    const [backendData, setBackendData] = useState(false);
    const [InitialValues, setInitialValues] = useState(defoultInithialValues);

    const formikRef = useRef(null);
    const {
      v_hr_people,
      v_save,
      v_cancel,
      v_back,
      v_delete,
      v_from,
      v_to,
      v_subscription,
    } = vocabulary;
    const { billingModal } = v_subscription;

    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };

    useEffect(() => {
      const { isOpen } = directionModal;

      // add defaults values to form if edit
      if (!isOpen) {
        handleUnmountForm();
      }
    }, [directionModal]);

    const handleUnmountForm = () => {
      if (formikRef.current) {
        setTypeForm("first");
        setKeyFormik(keyFormik + 1);
        setInitialValues(defoultInithialValues);
      }
    };

    const handleOnSubmit = async (values) => {
      try {
        if (InitialValues?.title) {
          await handleUpdate({
            orderId: backendData.id,
            ...values,
            priority: values.priority.toUpperCase(),
            minSalary: Number(values.minSalary),
            maxSalary: Number(values.maxSalary),
            languages: values.languages.map((language) => language.title),
          });
        } else {
          await handleCreate({
            boardId: uuid(),
            id: uuid(),
            ...values,
            priority: values.priority.toUpperCase(),
            minSalary: Number(values.minSalary),
            maxSalary: Number(values.maxSalary),
            languages: values.languages.map((language) => language.title),
          });
        }

        await handleGetDirections({ typeConfirm: false });

        // Set typeForm after a successful operation.
        setTypeForm("fourth");

        // Wait for one second before executing handleOnDelete.
        setTimeout(() => {
          handleOnDelete();
        }, 1000);
      } catch (error) {
        handleOnDelete();
        // Handle any error that might occur during the async operations.
        console.error("Error during the handleOnSubmit execution:", error);
      }
    };

    const handleOnDelete = async () => {
      await handleUnmountForm();
      setDirectionModal((prevState) => ({
        ...defaultDirectionState,
      }));
    };

    const handleOnOpen = async () => {
      setDirectionModal((prevState) => ({
        ...prevState,
        isOpen: true,
      }));
    };

    const handleDeleteApplication = async () => {
      await removeApplicationHandler(backendData.id);
      await handleGetDirections({ typeConfirm: false });
      setDeleteModal(false);
      handleOnDelete();
    };

    const transformLanguages = (languages) => {
      return languages.map((item) => ({
        title: item.language,
        id: item.id,
      }));
    };

    useImperativeHandle(ref, () => ({
      handleOpenCreateModal: (item) => {
        if (item) {
          setInitialValues({
            title: item.title || "",
            direction: item.direction || "", // додано з backendData
            level: item.level || "",
            experience: item.experience || "",
            minSalary: item.min_salary || "", // змінено на min_salary
            maxSalary: item.max_salary || "", // змінено на max_salary
            currency: item.currency || "", // додано з backendData
            deadline: item.deadline || "",
            priority: item.priority || "",
            country: item.country || "",
            countryCode: item.country_code || "", // додано з backendData
            city: item.city || "",
            employment: item.employment || "",
            positionCount: item.position_count || "", // змінено на position_count
            project: item.project || "",
            languages: item.languages ? transformLanguages(item.languages) : [], // перетворено на масив, якщо language не null
            requirement: item.requirement || "",
            comment: item.comment || "",
          });
          setBackendData(item);
        }
        handleOnOpen();
      },
    }));

    const ErrorMessage = (error) => <ErrorText>{error}</ErrorText>;

    const renderTypeForms = useCallback(
      (formik) => {
        switch (typeForm) {
          case "first":
            return (
              <div>
                <LabelContainer>
                  <TextLabel>
                    {v_hr_people.applications.name}{" "}
                    <RequiredfieldStar>*</RequiredfieldStar>
                  </TextLabel>
                  <Input
                    type="text"
                    value={formik.values.title}
                    onChange={formik.handleChange("title")}
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    placeholder={v_hr_people.applications.typeName}
                    hasError={formik.errors.title}
                  />
                  {ErrorMessage(formik.errors.title)}
                </LabelContainer>
                <DropdownContainer>
                  <TextLabel>{v_hr_people.applications.direction}</TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.applications.typeDirection}
                    options={directionsOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("direction", value)
                    }
                    value={
                      searchInArrayByValue(
                        directionsOptions,
                        formik.values.direction,
                      ) || ""
                    }
                    onKeyDown={handleKeyDown}
                    oudsidesStyles
                  />
                </DropdownContainer>
                <DropdownContainer>
                  <TextLabel>{v_hr_people.applications.level}</TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.applications.typeLevel}
                    options={levelOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("level", value)
                    }
                    value={
                      searchInArrayByValue(levelOptions, formik.values.level) ||
                      ""
                    }
                    onKeyDown={handleKeyDown}
                    oudsidesStyles
                  />
                </DropdownContainer>
                <DropdownContainer>
                  <TextLabel>{v_hr_people.applications.experience}</TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.applications.typeExperience}
                    options={expirienceOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("experience", value)
                    }
                    value={
                      searchInArrayByValue(
                        expirienceOptions,
                        formik.values.experience,
                      ) || ""
                    }
                    onKeyDown={handleKeyDown}
                    oudsidesStyles
                  />
                </DropdownContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.applications.salary}</TextLabel>
                  <SalaryContainer>
                    <SalaryWrapper>
                      <Input
                        type="text"
                        value={formik.values.minSalary}
                        onChange={formik.handleChange("minSalary")}
                        onBlur={formik.handleBlur}
                        onKeyDown={handleKeyDown}
                        placeholder={v_hr_people.applications.from}
                      />
                    </SalaryWrapper>
                    <SalaryWrapper>
                      <Input
                        type="text"
                        value={formik.values.maxSalary}
                        onChange={formik.handleChange("maxSalary")}
                        onBlur={formik.handleBlur}
                        onKeyDown={handleKeyDown}
                        placeholder={v_hr_people.applications.to}
                      />
                    </SalaryWrapper>
                    <SalaryWrapper>
                      <DropdownWithSearch
                        isSearchable={false}
                        isMulti={false}
                        placeHolder={"USD"}
                        options={optionsCurrency}
                        onChange={({ value }) =>
                          formik.setFieldValue("salary", value)
                        }
                        value={
                          searchInArrayByValue(
                            optionsCurrency,
                            formik.values.salary,
                          ) || ""
                        }
                        onKeyDown={handleKeyDown}
                        oudsidesStyles
                      />
                    </SalaryWrapper>
                  </SalaryContainer>
                </LabelContainer>
                <ButtonContainer>
                  {backendData ? (
                    <DeleteButton
                      type="button"
                      onClick={() => setDeleteModal(true)}
                    >
                      {v_delete}
                    </DeleteButton>
                  ) : null}
                  <SaveButton
                    type="button"
                    onClick={async () => {
                      const errors = await formik.validateForm();

                      if (!errors.title) {
                        formik.setErrors({});
                        setTypeForm("second");
                      }
                    }}
                  >
                    {billingModal.next}
                  </SaveButton>
                </ButtonContainer>
              </div>
            );

          case "second":
            const date = moment(formik.values?.deadline).format("YYYY-MM-DD");

        return (
          <div>
            <LabelContainer>
              <TextLabel>{v_hr_people.applications.deadline} <RequiredfieldStar>*</RequiredfieldStar></TextLabel>
              <DateContainer className="container_for_calendar">
                <DatepickerSelect
                  disableToolbar={false}
                  date={formik.values.deadline}
                  onChangeDate={(date) => {
                    formik.setFieldValue("deadline", date);
                  }}
                  placeholder={v_hr_people.applications.deadline}
                  dropdownTitle={v_hr_people.applications.deadline}
                />
              </DateContainer>
              {ErrorMessage(formik.errors.deadline)}
            </LabelContainer>
            <DropdownContainer hasError={formik.errors.priority}>
              <TextLabel>{v_hr_people.applications.applicationPriority} <RequiredfieldStar>*</RequiredfieldStar></TextLabel>
              <DropdownWithSearch
                isSearchable={false}
                isMulti={false}
                placeHolder={v_hr_people.applications.applicationPriority}
                options={priorityOptions}
                onChange={({ value }) =>
                  formik.setFieldValue("priority", value)
                }
                value={searchInArrayByValue(priorityOptions, formik.values.priority) || ''}
                onKeyDown={handleKeyDown}
                oudsidesStyles
              />
              {ErrorMessage(formik.errors.priority)}
            </DropdownContainer>
            <DropdownContainer>
              <TextLabel>{v_hr_people.applications.country}</TextLabel>
              <CountryPhoneContainer>
                <PhoneInput
                  country={formik.values.countryCode || ""}
                  enableSearch={true}
                  countryCodeEditable={false}
                  autoFormat={false}
                  searchPlaceholder={v_hr_people.applications.searchCountry}
                  dropdownClass="custom-dropdown"
                  onChange={(value, data) => {
                    formik.setFieldValue("country", data?.name);
                    formik.setFieldValue("countryCode", data?.countryCode);
                  }}
                  onKeyDown={handleKeyDown}
                />
                <LabelPhoneInput>
                  <p>{formik.values.country ? formik.values.country : v_hr_people.applications.selectCountry}</p>
                </LabelPhoneInput>
              </CountryPhoneContainer>
              <LabelContainer>
                <TextLabel>{v_hr_people.applications.city}</TextLabel>
                <Input
                  type="text"
                  value={formik.values.city}
                  onChange={formik.handleChange("city")}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  placeholder={v_hr_people.applications.city}
                />
              </LabelContainer>
              <LabelContainer>
                <DropdownContainer>
                  <TextLabel>{v_hr_people.applications.typeEmployment}</TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.applications.selectEmployment}
                    options={employmentOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("employment", value)
                    }
                    value={searchInArrayByValue(employmentOptions, formik.values.employment) || ''}
                    onKeyDown={handleKeyDown}
                    oudsidesStyles
                  />
                </DropdownContainer>
              </LabelContainer>
              <LabelContainer>
                <TextLabel>{v_hr_people.applications.numberPositions}</TextLabel>
                <Input
                  type="text"
                  value={formik.values.positionCount}
                  onChange={formik.handleChange("positionCount")}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  placeholder={v_hr_people.applications.typeNumberPositions}
                />
              </LabelContainer>
              <LabelContainer>
                <TextLabel>{v_hr_people.applications.project}</TextLabel>
                <Input
                  type="text"
                  value={formik.values.project}
                  onChange={formik.handleChange("project")}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  placeholder={v_hr_people.applications.typeProject}
                />
              </LabelContainer>
            </DropdownContainer>
            <ButtonContainer>
              <CancelButton
                type="button"
                onClick={() => setTypeForm("first")}
              >
                <BackArrowSvg /> {v_back}
              </CancelButton>
              <SaveButton
                type="button"
                onClick={async () => {
                  const errors = await formik.validateForm()

                      if (!errors.priority && !errors.deadline) {
                        formik.setErrors({});
                        setTypeForm("third");
                      }
                    }}
                  >
                    {billingModal.next}
                  </SaveButton>
                </ButtonContainer>
              </div>
            );

          case "third":
            return (
              <div>
                <LabelContainer>
                  <TextLabel>
                    {v_hr_people.applications.languageSkills}
                  </TextLabel>
                  <LanguageWrapper>
                    <TechnologyComponent
                      userTechnologies={formik.values.languages}
                      setUserTechnologies={(techArr) => {
                        // this.setState({ userTechnologies: techArr });
                        formik.setFieldValue("languages", techArr);
                      }}
                      showNotificationAction={false}
                      vocabulary={vocabulary}
                      themeLight={true}
                      title={v_hr_people.applications.languageSkills}
                      placeholder={
                        v_hr_people.applications.enterLanguagesSkills
                      }
                      isCreateTechnologies={false}
                    />
                  </LanguageWrapper>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.applications.requirements}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.requirement}
                    onChange={formik.handleChange("requirement")}
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    placeholder={v_hr_people.applications.enterRequirements}
                  />
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.applications.comment}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.comment}
                    onChange={formik.handleChange("comment")}
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    placeholder={v_hr_people.applications.enterComment}
                  />
                </LabelContainer>
                <ButtonContainer>
                  <CancelButton
                    type="button"
                    onClick={() => setTypeForm("second")}
                  >
                    <BackArrowSvg /> {v_back}
                  </CancelButton>
                  <SaveButton type="button" onClick={formik.handleSubmit}>
                    {v_save}
                  </SaveButton>
                </ButtonContainer>
              </div>
            );

      case "fourth":
        return (
          <SuccessWrapper>
            <Title>{v_hr_people.applications.successful}</Title>
            <TitleInfo>{v_hr_people.applications.youHaveSuccessful}</TitleInfo>
            <WrapperImage>
              <CatIcon/>
            </WrapperImage>
          </SuccessWrapper>
        )

          default:
            return null;
        }
      },
      [typeForm, InitialValues, backendData],
    );

    const renderLines = useCallback(
      () => (
        <LinesContainer>
          <StyledSVG active={typeForm === "first"}>
            <LineIcon active={typeForm === "first"} />
          </StyledSVG>
          <StyledSVG active={typeForm === "second"}>
            <LineIcon active={typeForm === "second"} />
          </StyledSVG>
          <StyledSVG active={typeForm === "third"}>
            <LineIcon active={typeForm === "third"} />
          </StyledSVG>
        </LinesContainer>
      ),
      [typeForm],
    );

    const renderButtons = () => (
      <ButtonContainer>
        <CancelButton type="button" onClick={handleOnDelete}>
          {v_cancel}
        </CancelButton>
        <SaveButton type="submit">{v_save}</SaveButton>
      </ButtonContainer>
    );

    const renderDirectionModal = useCallback(
      () => (
        <WrapperApplicationModal isHide={typeForm === "fourth"}>
          <Modal
            open={directionModal.isOpen}
            title="New Application"
            onClose={handleOnDelete}
            headerAdditionalElements={renderLines()}
            disablePortal={true}
            classes={{
              header: "recruitment-modal__header",
              title: "recruitment-modal__title",
              wrapper: "recruitment-modal",
              container: "recruitment-modal__container",
            }}
          >
            <>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                initialValues={InitialValues}
                onSubmit={handleOnSubmit}
                key={keyFormik}
              >
                {(formik) => {
                  formikRef.current = formik;
                  return (
                    <>
                      <StyledForm onSubmit={formik.handleSubmit}>
                        {renderTypeForms(formik)}
                      </StyledForm>
                    </>
                  );
                }}
              </Formik>
            </>
          </Modal>
        </WrapperApplicationModal>
      ),
      [directionModal, typeForm, InitialValues],
    );

    return (
      <>
        <WrapperDeleteModal>
          <Modal
            open={isDeleteModal}
            title="Are you sure you want to delete the application?"
            onClose={() => setDeleteModal(false)}
            disablePortal={true}
            classes={{
              header: "recruitment-modal__header_delete",
              title: "recruitment-modal__title_delete",
              wrapper: "recruitment-modal_delete",
              container: "recruitment-modal__container_delete",
            }}
          >
            <ButtonContainer>
              <CancelButton type="button" onClick={() => setDeleteModal(false)}>
                {v_cancel}
              </CancelButton>
              <DeleteButton type="submit" onClick={handleDeleteApplication}>
                {v_delete}
              </DeleteButton>
            </ButtonContainer>
          </Modal>
        </WrapperDeleteModal>
        {renderDirectionModal()}
      </>
    );
  },
);

export default CreateNewApplication;
