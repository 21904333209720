export default {
  lang: { short: "uk", long: "Українська" },

  "ERROR.USER.THIS_FACEBOOK_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Цей обліковий запис у Facebook вже підключено до іншого облікового запису",
  "ERROR.USER.THIS_SOCIAL_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Цей соціальний акаунт вже підключений",
  "ERROR.USER.THIS_EMAIL_ALREADY_CONNECTED_TO_ANOTHER_FB_ACCOUNT":
    "Обліковий запис з цією електронною поштою вже підключений до іншого облікового запису Facebook",
  "ERROR.USER.ACCOUNT_ALREADY_EXISTED":
    "Обліковий запис користувача вже існує.",
  "ERROR.TIMER.JIRA_WORKLOG_1_MIN_THRESHHOLD":
    "Мінімальний час для додавання ворклогу в Jira становить 1 хвилина.",
  "ERROR.TIMER.JIRA_ADD_WORKLOG_FAILED":
    'Додавання ворклогу в Jira закінчилось помилково. Переконайтеся будь ласк, що доступи до Jira вірні та назва задачі відповідає формату "JIRA-1 Comment".',
  "ERROR.TIMER.JIRA_WORKLOG_ALREADY_ADDED":
    "Поточна задача вже була додана до ворклогу Jira.",
  "Request path contains unescaped characters":
    "Перевірте правильність номера завдачі",
  "Request failed with status code 404":
    "Завдання з таким номером не знайдено в Jira",
  "Request failed with status code 401":
    "Неправильні облікові дані для синхронізації з Jira, 'перевірте коректність в профілі користувача'",

  "TIMER.JIRA_SYNC_SUCCESS":
    "Тестове з'єднання з Jira було успішно встановлено.",
  "ERROR.TIMER.JIRA_SYNC_FAILED":
    "Тестове з'єднання з Jira не було встановлено. Переконайтеся що всі введені дані вірні.",

  "SUCCESS.USER.RESET_EMAIL_CHECK":
    "Перевірте електронну пошту на наявність секретного коду для відновлення паролю.",
  "SUCCESS.USER.RESET_PASSWORD": "Ви успішно відновили пароль.",
  "SUCCESS.USER.PASSWORD_CHANGED": "Ви успішно змінили.",
  "SUCCESS.USER.UPDATE_USER": "Користувач був успішно оновлений.",
  "ERROR.CHECK_REQUEST_PARAMS":
    "Внутрішня помилка при відправці запиту на сервер.",
  "ERROR.INTERNAL":
    "Вибачте, щось пішло не так. Будь ласка, спробуйте пізніше.",
  "ERROR.NOT_AUTHORIZED": "Неавторизований запит.",
  "ERROR.PROJECT.UPDATE_FAILED": "Помилка під час оновлення проекту.",
  "ERROR.TEAM.CREATE_FAILED": "Не вдалося створити команду.",
  "ERROR.TEAM.SWITCH_FAILED": "Сталася помилка при перемиканні команди.",
  "ERROR.TEAM.RENAME_FAILED": "Сталася помилка під час зміни імені команди.",
  "ERROR.TIMER.UPDATE_FAILED": "Сталася помилка під час оновлення таймера.",
  "ERROR.TIMER.DELETE_FAILED": "Сталася помилка під час видалення таймера",
  "ERROR.USER.NOT_MEMBER": "Користувач не перебуває в жодній команді.",
  "ERROR.USER.RESET_EMAIL_FAILED":
    "Сталася помилка під час надсилання електронного листа для скидання пароля.",
  "ERROR.USER.CURRENT_PASSWORD_WRONG": "Поточний пароль невірний.",
  "ERROR.USER.CHANGE_PASSWORD_FAILED": "Сталася помилка під час зміни пароля.",
  "ERROR.USER.EMAIL_PASSWORD_WRONG":
    "Неправильна електроннна адреса або пароль.",
  "ERROR.USER.CREATE_INVITE_FAILED":
    "Сталася помилка під час створення запрошення для учасника.",
  "ERROR.USER.ALREADY_TEAMMATE": "Користувач вже складається в команді.",
  "ERROR.USER.EMAIL_EXISTS": "Електронна пошта вже існує.",
  "ERROR.USER.CREATE_USER_FAILED":
    "Сталася помилка під час створення користувача.",
  "ERROR.USER.UPDATE_USER_PERMISSIONS_DENIED":
    "У вас немає дозволу на оновлення користувача.",
  "ERROR.USER.UPDATE_USER_FAILED":
    "Сталася помилка під час оновлення користувача.",
  "ERROR.USER.UPDATE_USER_EMAIL_EXISTS": "Ця електронна адреса вже існує.",
  "ERROR.USER.UPDATE_USER_ROLE_FAILED":
    "Ви не зможете змінити роль у своїй команді.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ROLE_FAILED":
    "Ви не можете змінити роль власника команди.",
  "ERROR.USER.UPDATE_ACTIVE_STATUS_FAILED":
    "Ви не можете встановити свій обліковий запис як неактивний у своїй власній команді.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ACTIVE_STATUS_FAILED":
    "Ви не можете встановити обліковий запис власника команди як неактивний.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Користувача було успішно видалено з команди.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_SUCCEED":
    "Користувача було успішно видалено з команди.",
  "ERROR.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Під час видалення з команди сталася помилка.",

  v_a_avatar_upload_error:
    "Помилка завантаження! Завантажений файл не є зображенням або його розмір перевищує 1 Мб.",
  v_a_data_updated_ok: "Ваші дані були оновлені",
  v_a_change_password_great_ok: "Чудово! Тепер ви можете увійти знову!",
  v_a_change_password_ok: "Ви успішно змінили пароль!",
  v_a_password_same_error: "Паролі не співпадають!",
  v_a_invite_sent: "Запрошення відправлено!",
  v_a_invite_sent_error:
    "Сталася помилка під час надсилання запрошення користувачеві!",
  v_a_invite_sent_user_error:
    "Щось пішло не так... Видаліть користувача з розділу 'Заборонено' та запросіть знову",
  v_a_project_created: "Проект створено",
  v_a_project_updated: "Проект оновлено",
  v_a_project_existed: "Проект вже існує",
  v_a_project_created_error: "Проект не може бути створений",
  v_a_project_no_valid_name: "Назва проекту не може містити '%'",
  v_a_project_created_synchronization_with_jira_error:
    "Проект Jira вже підключений до проекту",
  v_a_team_name_empty_error: "Ім'я команди не може бути порожнім!",
  v_a_project_edit_error: "Проект не може бути відредагований",
  v_a_time_start_error: "Неправильний час початку, будь ласка, перевірте його!",
  v_a_time_end_error: "Неправильний час кінця, будь ласка, перевірте його!",
  v_a_time_already_busy: "Завдання не може збігатися в часі з іншим завданням",
  v_a_time_future_time_error: "Завдання не можна записати для майбутнього часу",
  v_a_time_already_busy_current_task: "Цей час уже зайнятий поточним завданням",
  v_a_task_name_error: "Будь ласка, введіть ім'я задачі перед збереженням",
  v_a_team_existed: "Команда вже існує",
  v_a_team_rename_error: "Команда не може бути перейменована",
  v_a_team_create_error: "Команда не може бути створена",
  v_a_task_name_before: "Будь ласка, введіть назву завдання перед",
  v_a_project_before: "Будь ласка, виберіть проект перед",
  v_a_select_project: "Виберіть проект",
  v_a_starting: "запуском",
  v_a_stopping: "зупинкою",
  v_a_time_tracking: "часу",
  v_a_account_create: "Аккаунт створено",
  v_a_confirm_password_error: "Неправильне підтвердження пароля",
  v_a_project_name_error: "Поле введення назви проекту не може бути порожнім",
  v_a_task_delete: "Ви дійсно хочете видалити цей запис?",
  v_a_incorect_email: "Невірно вказано e-mail",
  v_a_error_authorization: "Щось пішло не так. Спробуйте пізніше",
  v_a_incorect_password: "Пароль має містити не менше 6 символів",
  v_a_client_existed: "Клієнт вже існує",
  v_a_client_name_empty_error: "Ім'я клієнта не може бути порожнім!",

  v_set_default: "вибрати",
  v_default: "обрана",
  v_help: "Допомога",
  v_upload_image: "Завантажити зображення",
  v_delete_image: "Видалити зображення",
  v_phone: "Телефон",
  v_more_option: "Більше опцій",
  v_active_team: "Активна команда",
  v_set: "Встановити",
  v_team_is_active: "команду активною",
  v_ok: "Застосовувати",
  v_cancel: "Скасувати",
  v_language: "Мова",
  v_cofirm_new_password: "Підтвердіть новий пароль",
  v_current_password: "Поточний пароль",
  v_save_changes: "Зберегти",
  v_your_name: "Ім'я",
  v_my_profile: "Профіль",
  v_profile_settings: "Налаштування",
  v_change_password: "Змінити пароль",
  v_new_password: "Новий пароль",
  v_new_team: "Нова команда",
  v_send: "Надіслати",
  v_check_email:
    "Еякщо ваш email зареєстрований в Wobbly перевірте вашу пошту та дотримуйтеся інструкції в листі",
  v_check_email_error: "Ваш електронний лист не надіслано!",
  v_enter_email: "Введіть email",
  v_create_team: "Створити команду",
  v_search_project: "Пошук проекту",
  v_search_client: "Пошук клієнта",
  v_start_timer: "Запустити таймер",
  v_edit_task: "Змінити",
  v_delete_task: "Видалити",
  v_add_confirm_password: "Підтвердіть пароль",
  v_registration_terms_and_policy:
    "При реєстрації ви погоджуєтеся з умовами та політикою конфіденційності",
  v_login: "Логін",
  v_log_in: "Увійти",
  v_email: "Електронна пошта",
  v_email_error: "Занадто довгий email, доступно лише 72 символи",
  v_register: "Зареєструватися",
  v_password: "Пароль",
  v_unsubscribe: "Відписатись",
  v_sure: "Ви дійсно бажаєте відписатись від розсилки?",
  v_success_unsubscribe: "Користувач відписався від розсилки",
  v_error_already_unsubscribe: "Користувач вже відписаний від розсилки",
  v_sure_reports: "Ви дійсно бажаєте відписатись від розсилки звітів?",
  v_success_unsubscribe_reports: "Користувач відписався від розсилки звітів",
  v_error_already_unsubscribe_reports:
    "Користувач вже відписаний від розсилки звітів",
  v_enter: "Вхід",
  v_incorrect_password_or_email:
    "Ваша електронна пошта або пароль неправильні. Спробуйте знову.",
  v_cofirm_password: "Підтвердження пароля",
  v_forgot_your_password: "Відновити пароль",
  v_dont_have_an_account_yet: "До цих пір немає аккаунта",
  v_already_have_an_account: "Вже є аккаунт",
  v_sign_up: "Зареєструйтеся",
  v_timer: "Таймер",
  v_reports: "Звіт",
  v_report_header: "Звіт:",
  v_summary: "Загальний",
  v_projects: "Проекти",
  v_project: "Проект",
  v_people: "Люди",
  v_description: "Опис",
  v_teams: "Команди",
  v_bench: "Лава",
  v_team: "Команда",
  v_add_team: "Нова команда",
  v_add_your_password: "Введіть пароль",
  v_my_team: "Моя команда",
  v_total_time: "Загальний час",
  v_task_name: "Назва задачі",
  v_time_start: "Час початку",
  v_time_end: "Час закінчення",
  v_change: "Змінити",
  v_last_change: "Остання зміна",
  v_create_new_project: "Створити новий проект",
  v_apply: "Застосувати",
  v_edit_project: "Редагувати",
  v_time: "Час",
  v_create_project: "Створити проект",
  v_log_out: "Вийти",
  v_add_user: "Відправляти запит",
  v_invite_to_team: "Запросити в команду",
  v_team_role: "Роль",
  v_edit_user: "Редагувати користувача",
  v_name: "Ім'я",
  v_surname: "Прізвище",
  v_edit_team_name: "Змінити назву команди",
  v_rename_team: "Змінити ім'я",
  v_team_roles: "Ролі",
  v_summary_report: "Загальний звіт",
  v_total: "Всього",
  v_total_time: "Загальний відстежуваний час",
  v_total_billable: "Оплачуваний",
  v_total_not_billable: "Не оплачуваний",
  v_export: "Експортувати",
  v_archivate: "Архівувати",
  v_unarchivate: "Розархівувати",
  v_detailed_proj: "Детально",
  v_detailed_export: "Детальний звіт",
  v_simple_export: "Зведений звіт",
  v_user: "Користувач",
  v_select_all: "Виділити всіх",
  v_select_none: "Прибрати всіх",
  v_project_name: "Назва проекту",
  v_issue: "Задача",
  v_sum_tasks: "Задачі",
  v_sum_time: "Загальний час",
  v_user_name: "Ім'я користувача",
  v_add_your_email: "Введіть електронну пошту",
  v_add_your_task_name: "Введіть назву задачі",
  v_find: "Пошук",
  v_back: "Назад",
  v_team_name: "Назва команди",
  v_team_access: "Доступ",
  v_connection_problem: "Втрачено інтернет з'єднання",
  v_connection_restored: "З'єднання відновлено",
  v_all_role: "Всі ролі",
  v_all_access: "Всі доступи",
  v_all: "Всі",
  v_active: "Дозволено",
  v_not_active: "Заборонено",
  v_today: "Сьогодні",
  v_yesterday: "Вчора",
  v_thisWeek: "Поточний тиждень",
  v_lastWeek: "Минулий тиждень",
  v_thisMonth: "Поточний місяць",
  v_lastMonth: "Минулий місяць",
  v_this_year: "Поточний рік",
  v_last_year: "Минулий рік",
  v_days_up_to_today: "днів до сьогодні",
  v_days_starting_today: "днів від сьогодні",
  v_jira_synchronization: "Jira синхронізація",
  v_type: "тип",
  v_chart_label_total: "Всього годин за дату",
  v_enter_to: "Увійдіть у",
  v_to_get_token: "для отримання Api токену",
  v_login_with: "Увійти через",
  v_or: "або",
  v_or_continue_with: "або продовжити",
  v_date_format: "Формат дати",
  v_first_day_of_week: "Перший день тижня",
  v_time_format: "Формат часу",
  v_duration_display_format: "Формат відображення тривалості",
  v_switch_team_to_the: "Команда змінилася на",
  v_show: "показати",
  v_hide: "сховати",
  v_clients: "Клієнти",
  v_client: "Клієнт",
  v_add_new_client: "Додати нового клієнта",
  v_client_name: "Ім'я клієнта",
  v_add_client: "Додати клієнта",
  v_edit_client_name: "Редагувати ім'я клієнта",
  v_edit_client: "Редагувати",
  v_select_client: "Виберіть клієнта",
  v_sync_with_jira_project: "Синхронізація з проектом Джира",
  v_sync_with_jira_project_exist:
    'Проект Jira вже підключений до проекту "{{projectName}}"',
  v_jira_synchronization_problem:
    "Задачі з Jira не були синхронізовані. Переконайтеся будь ласк, що доступи до Jira вірні",
  v_jira_synchronization_ok: "Задачі були успішно синхронізовані з Jira",
  v_add_project_name: "Додайте назву проекту",
  v_edit_project_name: "Змініть назву проекту",
  v_verify: "Перевірити",
  v_classic: "Класичний",
  v_improved: "Покращенний",
  v_decimal: "Десятковий",
  v_short_day: "ДД",
  v_short_month: "ММ",
  v_short_year: "РРРР",
  v_empty: "Пусто",
  v_empty_email: "Потрібна електронна пошта",
  v_empty_password: "Потрібен пароль",
  v_cofirm_password_required: "Потрібно підтвердити пароль",
  v_add_old_password: "Введіть поточний пароль",
  v_add_new_password: "Введіть новий пароль",
  v_jira_synchronization_confirm: "Ви дійсно хочете синхронізувати?",
  v_add_member: "Додати учасника",
  v_member_dots: "Учасник...",
  v_combine: "Поєднати задачі",
  v_delete_user: "Видалити користувача",
  v_delete_user_text:
    "Користувача буде остаточно видалено. Усі команди та записи буде втрачено. Ви впевнені, що хочете видалити?",
  v_user_deleted: "Користувача видалено",

  v_v_required: "Обов'язкове поле",
  v_v_incorect_url: "Неправильна URL-адреса",
  v_no_report: "Немає звіту для показу!",
  v_no_entries: "Немає записів для показу.",
  v_no_entries_sub: "Давно ви не записували свої завдання!",
  v_no_projects: "Поточна команда не має для вас проектів",
  v_no_projects_sub:
    "Попросіть адміністратора вашої команди додати вас до проекту",
  v_no_clients: "Немає клієнтів для показу!",

  v_enter_number: "Число",

  v_invoices: "Рахунки",
  v_cat_not_invoices: "У вас ще немає рахунків-фактур із таким статусом",
  v_add_new_invoice: "Новий рахунок",
  v_all_invoices: "All invoices",
  v_invoice: "Рахунок",
  v_confirm_payment: "Підтвердити оплату",
  v_cancel_confirm: "Скасувати підтвердження",
  v_send_invoice: "Надіслати рахунок",
  v_from: "Від",
  v_to: "Кому",
  v_amount: "К-сть",
  v_rate: "Ставка",
  v_tax: "Податок",
  v_subtotal: "Підсумок",
  v_enter_project: "Текст",
  v_pic_tax: "Податок",
  v_payment_confirmation_window_confirm:
    "Ви впевнені, що хочете підтвердити оплату?",
  v_payment_confirmation_window_cancel:
    "Ви впевнені, що хочете скасувати платіж?",
  v_edit_confirmation_window:
    "Рахунок вже був відісланий клієнту, ви впевнені що хочете відредагувати?",
  v_client_no_email: "Електронна адреса клієнта не вказана",
  v_letter_send: "Рахунок надіслано",
  v_add_part_of_payment: "Додайте платіж",
  v_added_payments: "Додані платежі",
  v_date: "Дата",
  v_currency: "Валюта",
  v_outstanding: "Залишок",
  v_add_a_payment: "Додайте платіж",
  v_sum: "Сума",
  v_add_payment: "Додайте платіж",
  v_no_zero_error: "Не може бути нулем",
  v_no_negative: "Не може бути негативним",
  v_not_less: "Сума повинна бути меншою або рівною непогашеній",
  v_no_payments: 'Без платежів. Натисніть "+", щоб додати',
  v_no_active_invoices: "Немає активних рахунків-фактур",
  v_no_active_invoices_text:
    "У системі не знайдено активних рахунків-фактур. Це може означати, що всі рахунки-фактури сплачено або їх взагалі немає.",
  v_create_new_invoice: "Створити Новий Рахунок",

  v_invoice_summary: "Загальний Рахунок",
  v_invoice_reference: "Довідка",
  v_comments: "Коментарі",
  v_select_currency: "Виберіть Валюту",
  v_invoice_number: "Номер Рахунку",
  v_invoice_date: "Дата Рахунку",
  v_invoice_due: "Дата Оплати",
  v_edit: "Редагувати",
  v_clone: "Дублювати",
  v_delete: "Видалити",
  v_download: "Завантажити",
  v_pic_date: "Pic Date",
  v_address: "Адреса",
  v_add_sender: "Додати відправника",
  v_send_by_email: "Відправити по email",
  v_choose_recipient: "Choose recipient",
  v_select_logo_file: "Перетягніть  свій логотип сюди або виберіть файл",
  v_send_invoice_placeholder: "placeholder",
  v_no_overdues: "Немає простроченних",
  v_no_total: "Немає очікуючих",
  v_no_drafts: "Немає створених",
  v_last_invoices: "Останні рахунки",
  v_clear_filters: "Очистити фільтри",
  v_filter_all_projects: "Усі проекти",
  v_filter_all_clients: "Усі клієнти",
  v_filter_all_invoices: "Усі рахунки",
  v_filter_active: "Активні",
  v_filter_archived: "Архівні",
  v_filter_draft: "Чорнові",
  v_filter_awaiting: "Очікують",
  v_filter_reviewed: "Переглянуті",
  v_filter_paid: "Сплачені",
  v_filter_overdue: "Прострочені",
  v_grand_total: "Загальна сума залишку:",
  v_changing_status: "Змінити статус на",

  v_will_generate: "Буде згенеровано",
  v_save: "Зберегти",
  v_overdue: "Прострочено",
  v_total_outstanding: "Загальна сума",
  v_draft: "Чернетка",
  v_paid: "Сплачено",
  v_paid_full: "Сплачено повністю",
  v_paid_zero: "Жодних платежів",
  v_reviewed: "Переглянуто. Змінити статус?",
  v_overdue_tip: "Прострочено. Змінити статус?",
  v_draft_tip: "Чернетка. Змінити статус?",
  v_paid_tip: "Cплачено. Змінити статус?",

  v_delete_member: "Видалити учасника",
  v_clone_invoice: "Ваш рахунок був зкопійован",
  v_send_payment: "Надіслати рахунок",
  v_no_invoices: "Немає рахунків для показу",
  v_owner: "Власник",
  v_admin: "Адміністратор",
  v_manager: "Менеджер рахунків",
  v_hr_manager: "HR менеджер",
  v_proj_manager: "Менеджер проекту",
  v_recruiter: "Рекрутер",
  v_member: "Учасник",
  v_roles: "Ролі",
  v_country: "Країна",
  v_city: "Місто",
  v_state: "Область",
  v_zip: "Iндекс",
  v_address_lowC: "адреса",
  e_mail: "Ел. пошта",

  v_enter_text: "Введіть текст",
  v_technologies: "Технології",
  v_add_technology: "Додати",
  v_company_name: "Назва компанії",

  client_was_deleted: "Клієнта видалено",
  client_was_edited: "Клієнт був оновлений",
  client_was_created: "Клієнт був створений",
  client_dont_was_deleted:
    "Ви можете видалити клієнта, т.к. на ньому є закріплені рахунки. Будь ласка, видаліть рахунки, пов'язані з ним, і повторіть спробу!",

  v_select_logo_file_bigger: "Перетягніть сюди фотографію або виберіть файл",
  v_zip_code: "Поштовий індекс",
  v_enter_country: "Введіть країну",

  v_street: "Вулиця",
  v_enter_city: "Введіть місто",
  v_enter_language: "Виберіть мову",
  v_delete_client: "Видалити клієнта",
  v_enter_state: "Введіть область",
  company_name: "Назва компанії",
  full_name: "Ім'я/прізвище",

  select_country: "Виберіть країну",

  v_edit_client_title: "Редагувати клієнта",
  no_valid_number: "Номер телефону недійсний",
  no_valid_zip: "Недійсний поштовий індекс",
  no_valid_user_name: "The First/Last name field cannot contain spaces",
  no_valid_number_mask: "+XXXXXXXXXXXX",
  v_fill_fields: "Заповніть усі необхідні поля",
  v_fill_fields_company_name: "Введіть назву компанії в полі відправника",
  v_tags: "Теги",
  v_tags_tooltip: "Навички, технології тощо.",
  v_cv: "Резюме користувача",
  v_edit_sender: "Редагувати відправника",
  v_copy: "Скопіювати",
  v_delete_invoice: "Видалити Рахунок",
  v_prompt: "Ви впевнені, що хочете видалити рахунок?",
  v_confirm: "Підтвердіть",
  v_invoice_number_exist: "Номер рахунка існує",
  v_awaiting: "В очікуванні. Змінити статус?",
  v_price: "Сума",
  v_status: "Статус",
  v_physical_address: "Фізична адреса",
  v_error_company_name: "Заповніть поле - Назва компанії",
  v_time_date: "Час і дата",
  v_err_technology_exist: "Тег вже додан",

  v_search: "Пошук",
  v_reset: "Cкинути",
  v_sync_all: "Синхронізувати всі",
  v_sync_success: "Синхронізація успішна",
  v_result: "Результат",

  v_support: "Підтримка",
  v_tell_us: "Розкажіть нам про вашу проблему",
  v_type_message: "Введіть повідомлення ....",
  v_min: "Повинно бути не менше 8 символів",
  v_max: "Повинно бути менше 500 символів",
  v_message_error: "Помилка: Лист не може бути порожнім",
  v_message_sent: "Повідомлення надіслано успішно",

  v_copy_and_share: "Скопіювати і поділитися посиланням",
  v_show_more: "Показати більше",
  v_copy_the_link: "Скопіювати Посилання",
  v_copy_link: "Скопіювати Посилання",
  v_done: "Готово",
  v_link_copied: "Посилання скопійовано",
  v_add_a_discount: "Додати знижку",
  v_add_discount: "Додати знижку",
  v_discount: "Знижка",
  v_discount_subtotal: "від суми проміжного рахунку",
  v_round_to: "Округлити на:",
  v_report_round: "Десяткове округлення звіту",
  v_decimal_report: "Десятковий звіт",
  v_minutes: "хвилин",

  v_info: {
    timeTracking: "Відслідковування часу",
    resourcePlanning: "Планування Ресурсів",
    reporting: "Звітування",
    realTimeReports: "Звіти в реальному часі",
    invoicing: "Виставлення рахунків",
    owner: "Власник",
    admin: "Адмін",
    member: "Член",
    invoiceManager: "Менеджер з рахунків",
  },

  v_extra: {
    price: "€5/міс",
    feature1: "+1 клієнт",
    feature2: "+1 команда",
    feature3: "+2 проекти",
    star: "*одноразова можливість",
  },

  v_choose: "Choose your plan",

  v_coupon_doesnt_exist: "Цей купон не існує",
  v_coupon_expired: "Цей купон прострочений і не може бути застосований",
  v_coupon_used_up: "Цей купон використано та не може бути застосований",

  v_rp_history: {
    historyS: "історія",
    historyL: "Історія",
    header: {
      when: "Дата зміни",
      who: "Редактор",
      type: "Тип зміни",
    },
    table: {
      created: "Створено",
      updated: "Оновлено",
      from: "З: ",
      to: "На: ",
      plan: "Назва плану",
      period: "Період плану",
      project: "Назва проекту",
      user: "Призначено",
      description: "Опис",
    },
  },

  v_hr_people: {
    leftBar: "Рекрутинг",
    header: {
      title: "Рекрутинг",
      board: "Напрямки",
      applications: "Заявки",
      vacancies: "Вакансії",
      candidates: "Кандидати",
    },
    candidates_modal: {
      preview: "попередній перегляд",
      draft: "чернетка",
      edit: "редагування",
      personal: "Особиста інформація",
      social: "Соціальні мережі",
      start_work: "Можливість початку роботи",
      about: "Про кандидата",
      booking: "Бронювання зустрічі та відгуків",
      jobType: "Тип роботи",
      other: "Інше",
      desire: "Бажана дата",
      start: "Початок роботи",
      experience: "Досвід",
      cv: "CV",
      cvClient: "Резюме для клієнта",
      level: "Рівень",
      salary: "Зарплата",
      hrInt: "Дата співбесіди з HR",
      techInt: "Дата технічної співбесіди",
      feedHr: "Зворотній зв'язок HR",
      feedTech: "Зворотній зв'язок по тех частині",
      namePlace: "Введіть ім'я...",
      surnamePlace: "Введіть прізвище...",
      emailPlace: "Введіть e-mail...",
      countryPlace: "Оберіть країну",
      birthdayPlace: "Виберіть дату вашого дня народження",
      otherPlace: "Інші посилання тощо",
      desirePlace: "Бажана дата роботи",
      startPlace: "Дата початку роботи",
      cvPlace: "Прикріпити резюме",
      direction: "Напрямок",
      vacancy: "Вакансія",
      noVacancies: "Немає вакансій",
      minSalPlace: "Від",
      maxSalPlace: "Дo",
      hrIntPlace: "HR-співбесіда",
      techIntPlace: "Технічна співбесіда",
      feedHrPlace: "Введіть відгук HR...",
      feedTechPlace: "Введіть технічний відгук...",
    },
    directions: {
      no_directions_text: "Напрямків поки немає",
      new_direction: "Новий напрямок",
      search_by_name_placeholder: "Пошук за назвою...",
    },
    candidates: {
      new_candidates: "Нові кандидати",
      no_candidates_text: "Кандидатів ще немає",
      headerTable: {
        name: "Ім'я",
        job_type: "Тип роботи",
        country: "Країна",
        city: "Місто",
        level: "Рівень",
        direction: "Напрямок",
        salary: "Зарплата:",
        latest_update: "Останнє оновлення",
        creation_date: "Дата створення",
        junior: "Молодший",
        middle: "Середній",
        senior: "Старший",
        trainee: "Стажер",
        remote: "Віддалений",
        hybrid: "Гібридний",
        office: "Офіс",
      },
    },
    switcherForVacancies: {
      active: "Active",
      archive: "Archive",
    },
    vacancies: {
      no_vacancies_text: "Вакансій поки що немає",
      new_vacancies: "Нові вакансії",
      vacancies_created: "Вакансія створена",
      vacancies_edited: "Вакансія відредагована",
      link_copied: "Посилання скопійовано",
      vacancy_data: "Дані вакансії",
      job_application: "Заявка на роботу",
      select_job_application: "Виберіть заявку на роботу",
      vacancy_name: "Назва вакансії",
      company: "Компанія",
      type_here: "Введіть тут...",
      priority: "Пріоритет",
      choose_status: "Виберіть статус",
      choose_priority: "Виберіть пріоритет",
      direction: "Напрямок",
      experience: "Досвід",
      level: "Рівень",
      job_description: "Опис роботи",
      type_text_here: "Введіть текст тут...",
      number_of_positions: "Кількість позицій",
      type_of_employment: "Тип зайнятості",
      expected_salary: "Очікувана зарплата",
      job_location: "Місце роботи",
      location_optional: "Місце (опціонально)",
      job_type: "Тип роботи",
      remote: "Віддалено",
      hybrid: "Гібридно",
      office: "Офіс",
      foreign_languages: "Іноземні мови",
      additional_information: "Додаткова інформація",
      link_to_job: "Посилання на вакансії (на різних платформах)",
      requirements: "Вимоги",
      nice_to_have: "Бажано мати",
      working_conditions: "Умови роботи",
      preview: "перегляд",
      responsibilities: "Обов'язки",
      salary_range: "Діапазон заробітної плати",
      exit: "Вихід",
    },
    vacanciesHeaderTable: {
      name: "Назва вакансії",
      status: "Статус",
      priorities: "Пріоритети",
      number_of_candidates: "Кількість кандидатів",
      latest_update: "Останнє оновлення",
      deadline: "Дедлайн",
      created: "Створено",
    },
    new_direction_modal: {
      edit: "Редагувати напрямок",
      create: "Створити напрямок",
      name_dir: "Назва напрямку",
      name_placeholder: "Введіть, щоб додати напрямок...",
      vacancies: "Додані вакансії",
      vacancies_placeholder: "Вибрати або додати",
    },
    applications: {
      no_applications_text: "Наразі немає жодної заявки",
      new_applications: "Нова заявка",
      archive: "Архів",
      name: "Ім'я",
      typeName: "Введіть ім'я...",
      direction: "Напрямок",
      typeDirection: "Введіть напрямок...",
      level: "Рівень",
      typeLevel: "Введіть рівень...",
      experience: "Досвід",
      typeExperience: "Введіть досвід...",
      salary: "Зарплата",
      from: "Від",
      to: "До",
      deadline: "Термін",
      invalidDate: "Неправильна дата",
      applicationPriority: "Пріоритет заявки...",
      country: "Країна",
      searchCountry: "Шукати країну...",
      selectCountry: "Оберіть країну",
      city: "Місто",
      typeEmployment: "Тип зайнятості",
      selectEmployment: "Оберіть тип зайнятості",
      numberPositions: "Кількість позицій",
      typeNumberPositions: "Введіть кількість позицій",
      project: "Проект",
      typeProject: "Введіть назву проекту",
      languageSkills: "Мовні навички",
      enterLanguagesSkills: "Введіть мовні навички",
      requirements: "Вимоги",
      enterRequirements: "Введіть вимоги",
      comment: "Коментар",
      enterComment: "Введіть коментар",
      successful: "Успішно",
      youHaveSuccessful: "Ви успішно створили додаток",
    },
    headerTable: {
      name: "Ім'я",
      vacancies_available: "Наявні вакансії",
      priorities: "Пріоритети",
      number_of_candidates: "Кількість кандидатів",
      latest_update: "Останнє оновлення",
      deadline: "Кінцевий термін",
      name_applications: "Ім'я заявки",
      location: "Місцезнаходження",
      date_of_creation: "Дата створення",
    },
    prioritiesSelectors: {
      high: "Високий",
      medium: "Середній",
      low: "Низький",
    },
    deadlineSelectors: {
      newest: "Найновіші",
      oldest: "Найстаріші",
    },
    statusSelectors: {
      open: "Відкрито",
      closed: "Зачинено",
      rejected: "Відхилено",
      onHold: "На утриманні",
    },
  },

  v_subscription: {
    extraFeatures: "Придбати додаткові функції",
    leftBar: "Передплати",
    header: {
      all: "Усі плани",
      overview: "Огляд",
      invAndPaym: "Рахунки та платежі",
    },

    switcher: {
      trial: "-day free trial included",
      month: "Month",
      year: "Annual",
      discount: "Save up to 20%",
    },

    plan: {
      btnCurrent: "Current plan",
      btnUpgrade: "Upgrade",
      btnDowngarde: "Downgrade",
      mo: "mo per user",
      yr: "mo billed annually",
      btnUpgradeNow: "Upgrade now",
    },

    free: {
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "2 Projects",
      history: "35 days data history",
      clients: "1 Client",
      teams: "1 Team",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Data storage 1 GB",
    },

    basic: {
      teams: "10 Teams",
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "10 Projects",
      history: "365 days data history",
      clients: "10 Clients",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Unlimited data Storage",
    },
    donut: {
      teams: "15 Команд",
      tracks: "Необмежений час відстеження",
      projects: "15 Активних проектів",
      history: "до 365 днів зберігання історії",
      clients: "15 клієнтів",
      invoices: "Виставлення рахунка включено",
      integrations: "Інтеграція включена",
      storage: "10 GB даних",
    },

    pro: {
      teams: "Unlimited teams",
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "Unlimited projects",
      history: "Unlimited data history",
      clients: "Unlimited clients",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Unlimited data Storage",
    },
    billingModalDowngrade: {
      title: "Огляд підписки",
      currentPlan: "Ваш поточний план",
      pait: "проплачений до",
      text: "план буде переведено на новий план після закінчення терміну дії поточної підписки",
      button: "Продовжуйте",
    },
    billingModal: {
      next: "Далі",
      startExploring: "Почніть вивчати",
      trialHasNotExpired:
        "Ви все ще не повністю використали 'Пробний час', він буде втрачено, якщо ви продовжите. Ви хочете зробити покупку зараз?",
      secondStep: {
        title: "Огляд передплати",
        plan: "План",
        firstCharge: "Перший платіж",
        billingInfo: "Інформація для рахунку",
        changeBilling: "Змінити",
        paymentInfo: "Платіжна інформація",
        changePayment: "Змінити",
      },
      congratsStep: {
        congrats: "Вітання!",
        info: "Тепер ви можете використовувати всі функції, включаючи",
      },
      noData: "Будь-ласка, заповність платіжну інформацію",
    },
    billingInfoEditModal: {
      title: "Інформація про оплату",
    },
    overview: {
      trialWarning:
        "Будь-ласка, додайте платіжну інформацію, щоб продовжити використання {{planName}} після пробного періоду.",
      trialAddPaymentInfo: "Додати платіжну інформацію",
      updateBillingInfo: "Оновити",
    },
    invoicesAndPayments: {
      title: "Історія платежів",
    },
  },
  v_plan_details: {
    subscriptionExpired:
      "Your plan will be changed to 'Free' after your current subscription expires   ",
    subscriptionExpiredTrial:
      "Your plan will be changed to 'Free' after your current trial subscription expires   ",
    // "Ваш план буде змінено на 'Free' після закінчення терміну дії поточної підписки   ",
    status: "Статус передплати",
    subscriptionRenewingOn: "Вашу підписку буде оновлено",
    subscriptionRenewingOnFree: "Free",
    currentPlan: "Обраний план",
    costUserMonth: "Ціна користувач/місяць",
    users: "Користувачі",
    daysLeftOnTrial: "Залишилося днів пробного періоду",
  },
  v_billing_info: {
    title: "Рахунок",
    changeBillingInfo: "Оновити інформацію",
  },
  v_billing_info_details: {
    country: "Країна",
    city: "Місто",
    zip: "Поштовий індекс",
    emailAddress: "Електронна пошта",
    street: "Вулиця",
    houseNumber: "Номер будинку",
    firstName: "Ім'я",
    lastName: "Прізвище",
    companyName: "Назва компанії",
    phoneNumber: "Номер телефону",
  },
  v_payment_info: {
    title: "Оплата",
    addPaymentInfo: "Додати інформацію",
    changePaymentInfo: "Оновити інформацію",
  },
  v_payment_method_details: {
    cardDeclined: "Ваша картка не підтримується!",
    paymentMethod: "Метод оплати",
    noPaymentMethod: "Немає доступних методів оплати",
    labels: {
      country: "Країна",
      expiryMonth: "Місяць закінчення",
      expityYear: "Рік закінчення",
      cardHolder: "Власник картки",
      masked: "PAN картки",
      holder: "Holder",
      bic: "BIC",
      iban: "IBAN",
    },
    methods: {
      CreditCard: "Кредитна картка",
      BankAccount: "Банківський рахунок",
    },
  },
  v_billing_history_table: {
    date: "Дата",
    description: "Опис",
    amount: "Сума",
    invoice: "Рахунок",
  },
  v_payment_info_modal: {
    next: "Далі",
  },
  v_payment_info_state: {
    updateSuccess: "Платіжна інформація була успішно оновлена",
    updateError: "Помилка оновлення платіжної інформації",
  },
  v_billing_info_form: {
    country: { label: "Країна", placeholder: "Виберіть країну" },
    city: { label: "Місто", placeholder: "Enter city" },
    postalCode: { label: "Поштовий індекс", placeholder: "Введіть індекс" },
    emailAddress: { label: "Електронна пошта", placeholder: "Введіть пошту" },
    street: { label: "Вулиця", placeholder: "Введіть вулицю" },
    houseNumber: {
      label: "Номер будинку",
      placeholder: "Введіть номер будинку",
    },
    firstName: { label: "Ім'я", placeholder: "Введіть і'мя" },
    lastName: { label: "Прізвище", placeholder: "Введіть прізвище" },
    companyName: {
      label: "Назва компанії",
      placeholder: "Введіть назву компанії",
    },
    phoneNumber: {
      label: "Номер телефону",
      placeholder: "Введіть номер телефону",
    },
  },
  v_billing_info_state: {
    updateSuccess: "Інформація для рахунку була успішно оновлена",
    updateError: "Помилка оновлення інформації для рахунку",
  },
  v_upgrade_pay_state: {
    updateSuccess: "Підписку успішно оновлено",
    updateError: "Помилка оновлення підписки",
  },
  v_page_access_denied: "У вас недостатньо прав для перегляду цієї сторінки",
  v_page_access_request_owner: "Попросіть власника команди надати вам доступ",
  v_page_invoice_deleted: "Цей рахунок був видалений",
  v_project_not_available: "Проект для цієї задачі недоступний",
  v_upgrade_projects_title:
    "Покращіть тарифний план для створення нових проектів",
  v_upgrade_projects_description:
    "На жаль, ви досягли максимальної кількості активних проектів за вашим планом.",
  v_upgrade_teams_title: "Покращіть тарифний план для створення нових команд",
  v_upgrade_teams_description:
    "На жаль, ви досягли максимальної кількості команд за вашим планом.",
  v_upgrade_button: "Покращити план",
  v_upgrade_sidebar_title_trial_banner: "Ви використовуєте пробну версію",
  v_upgrade_sidebar_title_trial_banner_coupon: "Останній день пробної версії",
  v_upgrade_sidebar_text_trial_banner: "днів залишилось",
  v_upgrade_sidebar_text_trial_banner_few_days: "день залишився",
  v_last_trial_day: "Останній пробний день",
  v_last_trial_day_with_coupon: "Покращіть свій план за допомогою купона",
  v_upgrade_sidebar_title: "Покращіть план для більшої кількості функцій.",
  v_upgrade_sidebar_button: "Покращити",
  v_apply_sidebar_button: "Застосувати код",
  v_upgrade_clients_title:
    "Покращіть тарифний план для створення нових клієнтів",
  v_upgrade_clients_description:
    "На жаль, ви досягли максимальної кількості клієнтів за вашим планом.",
  v_upgrade_admin_note:
    "Якщо ви хочете оновити свій план, зверніться до власника команди.",
  v_no_results: "Немає результатів для показу",
  v_project_members: "Участники проекту",
  v_upgrade_label: "Оновіть свій план",

  v_resource_planning: "Планування ресурсів",
  v_collapse: "Collapse",
  v_expand: "Expand",
  v_add_plan: "Додати План",
  v_delete_plan: "Видалити План",
  v_delete_plan_confirm: "Ви впевнені, що хочете видалити план?",
  v_edit_plan_confirm: "Ви впевнені, що хочете редагувати план?",
  v_no_recorded_team_time: "Немає записаного часу у команді",
  v_not_active_user: "Не активний",
  v_real_time_report: "Звіт у реальному часі",
  v_rt_reports_plug: {
    title:
      "Керуйте розкладом співробітників у режимі реального часу за допомогою програмного забезпечення для планування роботи.",
    description:
      "Отримуйте інформацію про стан проекту та завантаженість - звіти в режимі реального часу для ефективного виконання завдань та управління проектами.",
  },
  v_rp_plug: {
    title:
      "Спростіть планування роботи команди за допомогою планування ресурсів",
    description:
      "Відстежуйте загальне навантаження для ефективного розподілу ресурсів. Потрібно більше можливостей?",
  },
  v_see_plans: "Подивитись Плани",
  v_tooltip_upgrade: "Ця функція доступна, якщо ви оновите свій пакет",
  v_real_time: "У реальному часі",
  v_activity_view: "Перегляд активності за 5 днів",
  v_resource_planning_page: {
    v_edit_plan_title: "Редагувати план",
    v_add_plan_title: "Додати план",
    v_teams: "Команди",
    v_members: "Учасники",
    v_type_task_name: "Введіть назву завдання...",
    v_projects: "Проекти",
    v_dates: "Дати",
    v_from: "Від",
    v_to: "До",
    v_select_or_add: "Виберіть або Додайте",
    v_description: "Опис",
    v_type_description: "Введіть, щоб додати опис...",
    v_cancel: "Скасувати",
    v_show_all_projects: "Показати всі проекти",
    v_show_all_members: "Показати всіх учасників",
    v_show_all_tags: "Показати всі теги",
    v_birthday_date: "Дата народження",
    v_join_date: "Дата приєднання",
    v_vacation_reset: "Скидання відпустки",
    v_country: "Країна",
    v_local_time: "Місцевий час",
    v_workload: "Навантаження",
    v_day_off: "Вихідний",
    v_total_left: "Всього/Залишилось",
    v_sick_leave: "Лікарняний",
    v_vacation: "Відпустка",
    v_day_off_with_work_off: "Неоплачувана відпустка",
    v_tags: "Теги",
    v_success_created_plan: "План створено",
    v_success_edited_plan: "План відредагований",
    v_success_deleted_plan: "План видалено",
    v_filter_no_results: "Збігів не знайдено",
    v_error_start_date: "Час старту плану більше кінця",
    v_error_set_wright_date:
      "Не коректний проміжок часу, задайте коректно період",
    v_last_seen: "Останнє відвідування",
    v_user_profile_error: "Щось пішло не так",
    v_success_edited_profile: "Профіль відредаговано",
    v_user_profile: "Профіль користувача",
    v_edit_user_profile: "Редагувати Профіль",
    v_error_user_is_not_exist_in_project:
      "Користувач не доданий до цього проекту",
    v_error_user_does_not_exist_in_this_project:
      "Користувач не існує в цьому проекті",
    v_error_user_not_admin_owner:
      "Лише Owner/Admin може створити план для іншого користувача",
    v_error_you_can_not_change_holidays:
      "Ви не можете змінити дату державних свят",
    v_error_delete_plan_in_the_past: "Неможливо видалити план у минулому",
  },
  v_left_bar: {
    time: "Час",
    manage: "Управління",
    invoice: "Рахунки",
    people: "Люди",
    other: "Інше",
  },
  v_days: "д.",
  v_members: "Учасники",
  v_filters: "Фільтри",
  v_clear_all: "Clear all",
  v_show_by: "Показати за:",
  v_day: "День",
  v_week: "Тиждень",
  v_month: "Місяць",
  v_year: "Рік",
  v_assignee: "Призначений",
  v_plan_info: "Інформація про план",
  v_all_members: "Всі учасники",
  v_select_projects: "Виберіть проекти...",
  v_notifications: "Сповіщення",
  v_not_notifications: "У вас немає сповіщень",
  v_release_notes: {
    v_title: "Release Notes",
    v_whats_new: "What's New:",
    v_improved: "Improved/Fixed:",
    v_version: "Version",
    v_versions: {
      "1.7.26": {
        v_new: [
          "Work on bugs/improvements.",
          "Subscription payment model has been changed, now the amount depends on the number of users in the team.",
          "The functionality and benefits of subscriptions have been changed.",
          "Added more detailed notifications in case of synchronization error with Jira.",
          "Added the ability to control Workload on the 'Resource Planning' page. View available load and view and edit actual load.",
          "Bench functionality has been added. Possibility to filter out users with no plans on the 'Resource Planning' page.",
        ],
        v_improved: [
          "Fixed a visual error on the login page when the sidebar was visible.",
        ],
      },
      "1.6.25": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Team' and 'Reports'",
          "New sidebar design implemented",
          "Added the possibility to see the total time for billable and not billable tracks on the 'Reports' page",
          "Added the possibility of inviting several users to the Team at a time on the 'Team' page",
        ],
        v_improved: [
          "Fixed bug with creating a plan for the wrong date that was selected and inability to edit plan daterange on the 'Resource Planning' page",
          "Visual improvements on the 'Resource Planning' page",
          "Fixed a bug with updating the status of an invoice when it is assigned the 'Awaiting' status on the 'Invoices' page",
          "Visual improvements on the 'Invoices' page",
        ],
      },
      "1.6.24": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Teams', 'User Profile', 'Reports', 'Projects', 'Clients' and 'Timer'",
        ],
        v_improved: [
          "Track no longer stops when switching Teams",
          "Fixed a bug when Owner could not remove Admin from the project on the 'Projects' page",
          "Removed the field of synch with Jira when creating and editing a project on the 'Projects' page",
          "Visual and functional improvements on the 'Projects' page",
          "Fixed a bug when Invoice Manager saw the report only by itself on the 'Reports' page",
          "Fixed visual bug with incorrect highlighting of daterange in the calendar on the 'Reports' page",
          "Fixed a bug with incorrect display of birth date calendar on the 'User Profile' page",
          "Fixed a bug where user tags were lost when changing role on the 'Team' page",
          "Visually improved user info window on the 'Resource Planning' page",
          "Improved display of the tooltip with the plan description when there are few users on the 'Resource Planning' page",
          "Improved work of the tooltip with plan description on the 'Resource Planning' page",
          "Changed the order of action for copying a link to an invoice on the 'Invoices' page",
          "Fixed bug with infinite loading of the list of Teams when viewing a sent invoice on the 'Invoices' page",
          "Visual improvements when filling out an invoice on the 'Invoices' page",
        ],
      },
      "1.6.23": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the possibility to purchase a subscription, for which the trial is valid",
        ],
        v_improved: [
          "Fixed a bug where user's name in filters was duplicated on the 'Report' page",
          "Fixed a bug where PM, HR manager and Invoice managers could not use cross-timed reports on the 'Report' page",
          "Fixed a bug where the color of the tooltip did not change when switching the theme on the 'Resource Planning' page",
          "Fixed a bug where PM and HR manager could not invite new users to the team on the 'Team' page",
        ],
      },
      "1.6.22": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to see reports on users with denied access",
        ],
        v_improved: [
          "Improved filter logic on the 'Reports' page",
          "Fixed several bugs on the 'Reports' page",
          "Reports are updated immediately after a Team change",
          "Fixed several bugs on the 'Project' page",
          "Fixed search on 'Client' and 'Project' pages",
          "Improvements have been made to the change history on the 'Resource Planning' page",
        ],
      },
      "1.6.21": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to set tracks for the future in 'Timer' page",
          "Added automatic trimming of the estimate when copying a Jira-synchronized task",
          "Added history of plan changes in 'Resource Planning' page",
        ],
        v_improved: [
          "Improved work of the reports page",
          "Fixed a bug where the report was exported incorrectly when cross-team was enabled in 'Reports' page",
          "Several visual improvemets in sidebar",
          "Several visual improvemets in 'Resource Planning' page",
        ],
      },
      "1.6.20": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Roles', 'Resource Planning', 'Timer', 'Projects' and 'E-mail'",
        ],
        v_improved: [
          "Added mass synch with Jira in 'Timer' page",
          "Role-based accesses have been updated",
          "Fixed minor errors with roles",
          "Fixed bug with drag and drop plan by Recruiter and Memebers in 'Resource Planning' page",
          "Fixed a bug where the recruiter and the memeber only saw the plans of the projects to which they were added 'Resource Planning' page",
          "Fixed minor errors in 'Resource Planning' page",
          "Added weekly report emails for new roles",
          "Weekly reports updated, time period added",
          "Fixed a bug with deleting a user from the project in 'Projects' page",
        ],
      },
      "1.6.19": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Team', ''Resource Planning' and 'Invoices'",
          "Added connection with support",
        ],
        v_improved: [
          "User roles have been updated, added new roles in 'Team' page",
          "Added tooltips for collapsed sidebar",
          "Corrected the display of holidays in 'Resource Planning' page",
          "Visual bugs fixed in 'Reports' page",
          "Visual bugs fixed in 'Invoices' page",
        ],
      },
      "1.6.18": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Register page', 'Projects' and 'Invoices'",
          "Added the ability to log in with Google",
        ],
        v_improved: [
          "Several visual improvemets in 'Register page'",
          "Several visual improvemets in 'Reports' page",
          "Fixed a bug when large reports hang up in 'Repors' page",
          "Updated weekly e-mail reports on time tracked",
          "Fixed a bug when a user was not deleted from the project  in 'Projects' page",
          "Several visual improvemets in 'Free invoice generator' page",
          "Several visual improvemets in 'Invoices' page",
        ],
      },
      "1.6.17": {
        v_new: [
          "Work on bugs/improvements in the 'Subscriptions', 'Reports', 'Clients', 'Email' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Several visual improvemets in 'Subscriptions' page",
          "Several visual improvemets in 'Reports' page",
          "Several visual improvemets in 'Clients' page",
          "Several visual improvemets in 'Resource Planning' page",
          "New weekly e-mail reports on time tracked",
        ],
      },
      "1.6.16": {
        v_new: [
          "Work on bugs/improvements in the 'Invoice' and 'Resource Planning'",
        ],
        v_improved: [
          "Several minor improvements in the 'Resource Planning' section",
          "Added ability to change invoice status in the 'Invoices' section",
        ],
      },
      "1.6.15": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Projects' and  'Subscriptions'",
        ],
        v_improved: [
          "Improved calendar in the Add Plan window in the 'Resource Planning' section",
          "Fixed calendar in the User Profile window for web-mobile in the 'Resource Planning' section",
          "Several minor improvements for web and web-mobile in the 'Resource Planning' section",
          "Visual and logical update for coupons in the 'Subscription' section",
          "Several minor improvements in the 'Subscription' section",
          "Fixed 'Team' filter when Cross-team is active in the 'Reports' section",
          "Fixed a bug when interacting with the report on a user not from the current team in the 'Reports' section",
          "Several minor improvements in the 'Reports' section",
          "Fixed 'Total outstanding' in the 'Invoices' section",
          "Fixed tool-tips in the 'Invoices' section",
          "Fixed Free Invoice Generator",
          "Fixed detailed report in the 'Projects' section",
        ],
      },
      "1.6.14": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Subscriptions' and E-mail sections",
        ],
        v_improved: [
          "Fixed a bug when filters were dropping when scrolling to a new month in the 'Resource Planning' section",
          "Fixed colors of projects in the calendar in the 'Resource Planning' section",
          "The selected filter is moved up in the 'Resource Planning' section",
          "Fixed an error when filters were dropping when deleting a plan in the 'Resource Planning' section",
          "Fixed project colors in filters in the 'Resource Planning' section",
          "Visual improvemetns of 'Resource Planning' page in the 'Resource Planning' section",
          "Fixed photo picker in invoices in the 'Invoices' section",
          "Visual improvemetns of 'Invoices' page for web-mobile",
          "Fixed several minor visual/performance bugs in the 'Invoices' section",
          "Coupons are now saved from the beginning to the end of the trial in the 'Subscriptions' section",
          "The full amount is now visible when you buy a year's subscription in the 'Subscriptions' section",
          "Fixed a bug when users' E-mails were displayed instead of their names in real-time reports when cross-team reports is active in the 'Reports' section",
          "A filter by team has been added to the cross-team reports in the 'Reports' section",
          "Improved web-mobile version in the 'Reports' section",
          "Improved Real-Time Reports page in the 'Reports' section",
          "Fixed several minor visual/performance bugs in the 'Reports' section",
          "Fixed an error that prevented emails from being sent correctly in the 'E-mails' section",
          "Visual improvements/performance in other sections",
        ],
      },
      "1.6.13": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning' and E-mail sections",
        ],
        v_improved: [
          "Fixes filters for summary cross-team reports on the 'Reports'",
          "Reports now sorted by time on the 'Reports'",
          "Added count of users in selected status by online on the 'Reports'",
          "Changed URL for real-time reports on the 'Reports'",
          "Visual changes 'Add plan' window on the 'Resource Planning'",
          "Improved displaying plan name by plan duration in 'Resource Planning'",
          "The plan now has a default duration of 24 hours on the selected day in 'Resource Planning'",
          "Improved 'Join date' window in User Profile window in 'Resource Planning'",
          "Visual improvements in User Profile window in 'Resource Planning'",
          "Visual improvements 'Resource Planning' page",
          "Invoices page reworked when there no invoices",
          "Visual improvements 'Invoices' page",
          "Fractional numbers are available in the QTY and rate fields in 'Invoices'",
          "Fixed bug when trial didn't start",
          "Added errors when using invalid coupons",
          "Added possibility to unsubscribe from e-mails",
          "Several visual improvements",
        ],
      },
      "1.6.12": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "The possibility of using promotional codes has been added",
        ],
        v_improved: [
          "Visual improvements to the loaded Invoice",
          "Fixed a bug when the invoice was not created due to the time zone",
          "Now plan can be created without name in 'Resource Planning'",
          "Bug fixed, cross-team report showed the wrong time, tracked by the user in 'Reports'",
          "Added the ability to enter fractional numbers in QTY and Rate in 'Invoice'",
          "Visual improvements to filters on the 'Resource Planning'",
          "Fixed several minor visual/performance bugs",
        ],
      },
      "1.6.11": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "Added Cross-team Reports",
        ],
        v_improved: [
          "Fixed sidebar opening bug when switching to Subscriptions page",
          "Visual improvements to the running timer",
          "Fixed online filters in real-time reports 'Reports' page",
          "Shows the number of the week in the year when the show buy week on the 'Resource Planning' page",
          "Fixed header on the 'Resource Planning' page",
          "Fixed a bug where the created plan was not displayed on the 'Resource Planning' page",
          "Added the ability to start the trial period without entering a card",
          "Several visual improvements",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.10": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Fixed visual bugs in invoice creating page",
          "Fixed visual bugs on 'Free Invoice Generator' page",
          "'Grand total' replaced by 'Grand total left', displays the total amount expected to be paid on 'Invoices' page",
          "The unpaid part is displayed for each invoice now",
          "Fixed visual bugs in 'Resource Planning'",
          "Added the ability to show calendar by year in 'Resource Planning'",
          "Visual improvements to filters on the 'Resource Planning' page",
          "Visual improvements to filters on the 'Reports' page",
          "Real-time Reports show tags instead of roles",
          "Fixed translation in 'Subscriptions'",
          "Fixed timer display when sidebar is hidden",
          "Improved emails",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.9": {
        v_new: [
          "New Subscriptions",
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Increased the number of characters displayed in the task name in 'Reports'",
          "Fixed location of 'summary / real-time' buttons in 'Reports'",
          "Added the ability to hide to hide side bar",
          "Now the header in the 'Resource Planning' is hidden when scrolling down",
          "Filters in 'Resource Planning' are moved to separate buttons",
          "Improved display of plan names in 'Resource Planning'",
          "When hovering on the plan in 'Resource Planning', you can see the start date, end date and duration of the plan",
          "Added the ability to hide usernames in 'Resource Planning'",
          "Fixed slider in 'Resource Planning'",
          "The space is automatically removed in the username if it is in front of the name in 'User profile'",
          "User CV is not displayed when editing a user through 'My team'",
          "Fixed bugs when moving and creating plans in 'Resource Planning'",
          "Week numbering bug fixed in 'Resource Planning'",
          "Fixed a bug with the username when adding it to the team in Notifications",
          "Notifications are now displayed from newest to oldest",
          "Fixed several minor visual bugs",
        ],
      },
      "1.5.9": {
        v_new: [
          "Work on bugs/improvements in the Resource Planning section.",
          "Release versions removed from notification bell. Useful app notifications will now be displayed there.",
        ],
        v_improved: [
          "Added an error when dragging a plan to a user who is not in the project, but to whom the plan was transferred in 'Resource Planning'",
          "Fixed pop-up window when hovering over the plan with the cursor. The window is now displayed under the cursor.",
          "Fixed display of users with a long name in the 'Resource Planning' section",
          "Improved readability of texts in the 'Resource Planning' section on user plans",
          "Changed the limit on the number of characters in the username in the 'Profile Settings' of the user. The user can enter 100 characters",
          "Fixed display of username with long name in user plan under Resource Planning",
          "Added sticky header in resource scheduling",
        ],
      },
      "1.5.8": {
        v_new: [
          "Several new messages have been added to the e-mail, which will now be sent and will help the user working with Wobbly.",
          "Added weekly e-mail reports for owner/admin about members' time tracked in favorites projects",
          "Added weekly e-mail reports for members on time tracked",
        ],
        v_improved: [
          "Members now have the ability to switch Billable/Non-billable in Reports for their tracked tasks",
          "Owner and Admin now have the ability to switch Billable/Non-billable in Reports for the tracked tasks of all users",
          "Only Owner and Admin now can edit Member's User profile in Resource planning",
          "Added the ability to select a year when setting the date of birth in Profile settings",
          "Added the ability to delete a plan in the past in Resource planning",
        ],
      },
      "1.5.7": {
        v_new: [
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed error editing User Profile card by Admin and Owner. The edit button was missing for these roles.",
          "Fixed filters in the 'Resource Planning' section. The filter did not display any results.",
          "Fixed display of user statuses in the 'Resource planning' section. The user's online status was displayed if the user's timer was active in another team.",
          "Fixed navigation arrows for switching dates in the Resource Planning section. The arrows moved by dates within two weeks/months.",
          "Improved visual in Resource Planning section.",
          "Improved work of filters in the 'Reports' section, 'Real-time' page.",
          "Fixed a bug in the 'Reports' section, 'Summary' page. A report with numerous projects and users was not displayed.",
          "Improved/simplified the creation of a per-user plan in the Resource Planning section. When creating a plan for a team member, only those projects on which the user is added are displayed.",
          "Fixed the result of displaying users in the 'Reports' section, 'Real-time' page. Users with status 'Recent' was not displayed in the filter if it was with an active timer in another team.",
        ],
      },
      "1.5.6": {
        v_new: [
          "Added the ability to delete a user account.",
          "Slightly redesigned interaction with the 'Reports' section. 'Reports' are divided into 'Summary' and 'Real-time' pages.",
          "Added some new error messages that the user might encounter.",
        ],
        v_improved: [
          "Improved interaction with the calendar when creating/editing a plan in the 'Resource Planning' section.",
          "Fixed login error messages. Some errors were not displayed.",
          "Fixed time division in the 'Timer' section after 00.00. Tasks that were stopped manually after 00.00 were saved in one time period.",
          "Fixed a bug with displaying project colors in the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.4.5": {
        v_new: [
          "Added 'Real-time report' to the 'Reports' section. Now Owner and Admin can view which task the user is currently recording or the last recorded task for five days.",
          "Added several new colors for projects.",
        ],
        v_improved: [
          "Fixed a bug with changing teams. Now, when changing the team on one browser, the user's team will change on the other browser.",
          "Improved the speed of the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.3.4": {
        v_new: ["Bug fixes, visual fixes, application speed improvements."],
        v_improved: [
          "Fixed a few minor visual bugs.",
          "The light and dark switch has been moved to the app logo from the “Profile Settings” section. Also, now visually changed from a switch to a button.",
          "Improved speed of processing requests and responses in the Timer, Reports, and Projects sections.",
          "Improved the visual components of the “Light” and “Dark” themes.",
          "Added the ability to download a 1-minute rounded report in 'Decimal report'.",
          "Fixed “Billable /Non-billable” task merge bug when viewing project/people reports in detail. Now, tasks with the same name on different “Billable/Non-Billable” statuses are not merged.",
        ],
      },
      "1.3.3": {
        v_new: [
          "Added the ability to record the time as Billable/Non billable in the 'Timer' section.",
          "Added Online status for users. In the 'Projects' section, when viewing users on projects, you can see the green status Online which displayed if the user is currently recording a task in the timer.",
          "Added 'Decimal rounding of reports' in the 'Reports' section. Now the user can upload reports in the new format.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed display of usernames in the left menu field.",
          "Fixed editing task time in the 'Timer' section. Tasks from different projects could overlap in time.",
          "Fixed a bug with displaying a client in a project if the client was deleted from the 'Clients' section. The client that was deleted is no longer displayed in the project.",
        ],
      },
      "1.2.2": {
        v_new: [
          "Added a new ability to add projects to favorites, for quick access to projects.",
          "Added display of the total recorded time per month for all teams under the user profile.",
        ],
        v_improved: [
          "User profile. Added a notification if the user missed a required field.",
          "Invoices. Recent invoices now show the company name instead of the customer's name.",
          "The task in the timer can be started without a name.",
          "Added several error messages that users may encounter.",
          "Now users can see other members of the shared project.",
        ],
      },
      "1.1.1": {
        v_new: ["Added new Wobbly light theme in profile settings"],
        v_improved: [
          "Improved search for invoices by: Client name, Company name, Invoice number, Description, Reference. Now the invoice can be found by all these fields",
          "Fixed a bug with changing logo in invoices.",
          "Fixed bug with pulling archived clients when increase/decrease a subscription.",
          "Fixed errors when downloading a detailed/summary report. Reports could come encrypted or be incomplete.",
          "Fixed a bug when combining task names in the Reports section.",
          "Fixed search in the Projects section.",
          "Added several error messages that users may encounter.",
          "Fixed bugs in the Timer section. Editing tasks with overlapping times on different teams.",
          "Fixed errors when purchasing a subscription by a user with the Admin role. The subscription now applies to all team members.",
          "Fixed client removal. When deleting a client, the invoices that were sent to him now do not disappear",
        ],
      },
      "1.0": {
        v_new: ["Added release notes"],
        v_improved: [
          "Fixed display special characters in the project name by code when viewing detailed reports in the 'Reports' section.",
          "We fixed a bug where the partial search in the 'Projects' section did not display all matches.",
          "Fixed monitor 'Total hours by date' in the 'Reports' section. Fixed visual display by dates.",
          "Fixed search by invoice numbers and page transitions. Now the search is not reset when viewing the invoice.",
          "Added the ability to search for an invoice by Description and Reference.",
          "Added error notification when creating/editing a project with the same name.",
          "Fixed a bug related to special characters in the description of tasks in the 'Timer' section.",
          "Fixed a bug with inviting a new user to the team",
          "Added a few new error messages that the user may experience.",
          "Fixed a bug in the Timer section. Recording time in advance and editing time with the intersection of tasks by time.",
        ],
      },
    },
  },
};
