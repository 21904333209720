import React, { useState } from "react";
import { Runner, Text, Container, PeriodSwitcher } from "./My.styled.ts";
import cn from "classnames";

const VacanciesSwitcher = ({ period, handleSwitcher, trans }) => {
  const handleToggle = () => {
    handleSwitcher(!period); // Передаем обратно переключенное значение периода
  };

  return (
    <Container>
      <PeriodSwitcher period={period} onClick={handleToggle}>
        <Text isActive={!period} onClick={handleToggle}>
          {trans.active}
        </Text>
        <Text isActive={period} onClick={handleToggle}>
          {trans.archive}
        </Text>
        <Runner
          className={cn("preriod-switcher__runner", {
            "preriod-switcher__right": !period,
            "preriod-switcher__left": period,
          })}
        />
      </PeriodSwitcher>
    </Container>
  );
};

export default VacanciesSwitcher;
