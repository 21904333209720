import axios from "axios";
import { logoutByUnauthorized } from "../services/authentication";
import { AppConfig } from "../config";
import { getTokenFromLocalStorage } from "../services/tokenStorageService";

export const baseURL = AppConfig.apiURL;

export const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage();

    if (token) {
      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.data.statusCode === 401) {
        logoutByUnauthorized();
      }
      return Promise.reject(error);
    }
    return;
  },
);
