import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";

import BillingInfoEditModal from "../BillingInfoEditModal";
import SecondStep from "./SecondStep";
import CongratsStep from "./CongratsStep";
import BillingModalWrapper from "../BillingModalWrapper";
import PaymentInfoModal from "../../PaymentInfoModal";

import {
  getStripeCustomerDataSuccess,
  changeSubscriptionLoader,
} from "../../../../redux/actions/SubscriptionPageAction";
import { showNotificationAction } from "../../../../redux/actions/NotificationActions";

import { PERIODS } from "../../../../constants/constants";

import "./style.scss";
import {
  finalizeSubscription,
  updateSubscription,
} from "../../../../api/payment";
import { getUserDataAction } from "../../../../redux/actions/UserActions";
import { getCurrentTeamAction } from "../../../../redux/actions/TeamActions";
import { useDispatch } from "react-redux";
/**
 * Map Wobbly's internal period contants
 * to Billwerk's period valuds
 */

const STEPS_MAP = {
  BILLING_INFO: "BILLING_INFO",
  PAYMENT_INFO: "PAYMENT_INFO",
  OVERVIEW: "OVERVIEW",
  SUCCESS: "SUCCESS",
};

const BillingInfoModal = ({
  vocabulary,
  selectedPlan,
  period,
  handleClose,
  handleFinish,
  userData,
  subscriptionData,
  getStripeCustomerDataSuccess,
  getUserDataAction,
  getCurrentTeamAction,
  isFetching,
  handleCloseCongrats,
  couponId,
  plans,
  productId,
  productIdForUpgrade,
}) => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isFetchingUpgrade, setIsFetchingUpgrade] = useState(false);
  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState(subscriptionData);

  const stripe = useStripe();
  /**
   * Initial tab depends on payment and billing data
   * If payment and billing data was already defined,
   * the overview modal must be shown.
   * Else, the modal for editing unfilled data (billing
   * and / or payment) must be shown.
   */
  const [billingStep, setBillingStep] = useState(() => {
    if (subscriptionData && subscriptionData.default_payment_method) {
      return STEPS_MAP.OVERVIEW;
    } else {
      return STEPS_MAP.BILLING_INFO;
    }
  });

  /**
   * Handler for billing form closing
   *
   * If billing data was already defined before,
   * then it is editing and user should be returned
   * to overview modal
   * Else, modal should be closed
   */
  const handleCloseBillingInfo = () => {
    handleClose();
  };

  /**
   * Handler for payment form closing
   *
   * If payment data was already defined before,
   * then it is editing and user should be returned
   * to overview modal
   * Else, modal should be closed
   */
  const handleClosePaymentInfo = (isSuccess = false) => {
    if (isSuccess) {
      setBillingStep(STEPS_MAP.OVERVIEW);
    } else {
      handleClose();
    }
  };

  /**
   * Handler for billing form submit
   */
  const handleUpdateBillingDetails = async (formValue) => {
    setPaymentDetails(formValue);
    setBillingStep(STEPS_MAP.PAYMENT_INFO);
  };

  /**
   * Handler for payment form submit
   */
  const handleUpdatePaymentDetails = () => {
    setBillingStep(STEPS_MAP.SUCCESS);
  };

  /**
   * Upgrade plan handler
   */
  const handleUpgradePay = async () => {
    if (subscriptionData && subscriptionData.default_payment_method) {
      setIsFetchingUpgrade(true);
      await upgradeSubscription();
      await doTimeout();
      getCurrentTeamAction();
      setIsFetchingUpgrade(false);
    } else {
      setBillingStep(STEPS_MAP.PAYMENT_INFO);
    }
  };

  function doTimeout() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  }

  const upgradeSubscription = async () => {
    const {
      externalPlanData: { pricePeriod },
    } = selectedPlan;
    try {
      const { data: subscription } = await updateSubscription({
        paymentMethodId: subscriptionData.default_payment_method.id,
        priceId: pricePeriod.id,
        couponId: productId === productIdForUpgrade ? couponId : null,
        productId: productIdForUpgrade,
      });

      verifySubscription(subscription);
    } catch (error) {
      console.log(error);
    }
  };

  const verifySubscription = async (subscription) => {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;

      if (
        status === "requires_action" ||
        status === "requires_payment_method"
      ) {
        try {
          const res = await stripe.confirmCardPayment(client_secret);

          if (res.error) {
            throw res.error;
          } else {
            confirmSubscription(subscription.id);
          }
        } catch (error) {
          dispatch(
            showNotificationAction({
              text: error.message,
              type: "error",
            }),
          );
          console.log("[error]", error);
        }
      } else {
        getStripeCustomerDataSuccess(subscription);
        setBillingStep(STEPS_MAP.SUCCESS);
      }
    } else {
      getStripeCustomerDataSuccess(subscription);
      setBillingStep(STEPS_MAP.SUCCESS);
    }
  };

  const confirmSubscription = async (subscriptionId) => {
    try {
      const { data: subscription } = await finalizeSubscription(subscriptionId);

      getStripeCustomerDataSuccess(subscription);
      setBillingStep(STEPS_MAP.SUCCESS);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (subscriptionData && subscriptionData.default_payment_method) {
      const {
        default_payment_method: {
          billing_details: {
            name,
            email,
            phone,
            address: { country, line1, postal_code },
          },
        },
      } = subscriptionData;

      setPaymentDetails({
        name: name || "",
        country: country || "",
        emailAddress: email || "",
        address: line1 || "",
        phoneNumber: phone || "",
        postalCode: postal_code || "",
      });
    } else {
      const {
        username: userName,
        email: userEmail,
        phone: userPhone,
        country: userCountry,
        city: userCity,
        zip: userZip,
      } = userData;

      setPaymentDetails({
        name: userName || "",
        country: userCountry || "",
        emailAddress: userEmail || "",
        address: userCity || "",
        phoneNumber: userPhone || "",
        postalCode: userZip || "",
      });
    }
  }, [subscriptionData, userData]);

  const STEPS = {
    [STEPS_MAP.BILLING_INFO]: paymentDetails ? (
      <BillingInfoEditModal
        formValues={paymentDetails}
        submitTitle={vocabulary.v_subscription.billingModal.next}
        isProcessing={false}
        handleClose={handleCloseBillingInfo}
        handleSubmit={handleUpdateBillingDetails}
      />
    ) : null,
    [STEPS_MAP.PAYMENT_INFO]: (
      <PaymentInfoModal
        isProcessing={isFetching}
        handleSubmit={handleUpdatePaymentDetails}
        handleClose={handleClosePaymentInfo}
        paymentDetails={paymentDetails}
        selectedPlan={selectedPlan}
        changePaymentMethod
      />
    ),
    [STEPS_MAP.OVERVIEW]: (
      <SecondStep
        customerData={subscriptionData}
        selectedPlan={selectedPlan}
        period={period}
        plans={plans}
        handleChangeBillingData={() => setBillingStep(STEPS_MAP.BILLING_INFO)}
        handleChangePaymentData={() => setBillingStep(STEPS_MAP.PAYMENT_INFO)}
        handleNext={handleUpgradePay}
        handleClose={handleClose}
        isProcessing={isFetchingUpgrade}
      />
    ),
    [STEPS_MAP.SUCCESS]: (
      <CongratsStep
        vocabulary={vocabulary}
        customerData={customerData}
        selectedPlan={selectedPlan}
        handleClose={handleClose}
        handleExplore={handleFinish}
        handleCloseCongrats={handleCloseCongrats}
      />
    ),
  };

  return <BillingModalWrapper>{STEPS[billingStep]}</BillingModalWrapper>;
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  subscriptionData: state.subscriptionPageReducer.customerData,
  isFetching: state.subscriptionPageReducer.isFetching,
  userData: state.userReducer.user,
  couponId: state.subscriptionPageReducer.couponId,
  productId: state.subscriptionPageReducer.productId,
  productIdForUpgrade: state.subscriptionPageReducer.productIdForUpgrade,
});

const mapDispatchToProps = {
  showNotificationAction,
  getStripeCustomerDataSuccess,
  getUserDataAction,
  getCurrentTeamAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfoModal);
