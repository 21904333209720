import React, { ReactElement, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import { SearchIcon, CrossIcon } from "../../Atoms/SvgIcons";
import ResourcePlanningSearchResult from "../ResourcePlanningSearchResult";

import { IRPMemberSearchResult } from "../../../types/resource-planning.interface";
import { ITeamData, ITeamDetailedData } from "../../../types/team.interface";
import { IDictionary } from "../../../types/dictionary";

import { updateFilterValues } from "../../../redux/actions/ResourcePlanningActions";

import "./style.scss";
import classNames from "classnames";

interface ISearchComponentProps {
  vocabulary: IDictionary<any>;
  currentTeamDetailedData: ITeamDetailedData;
  selectMember: (id: string) => void;
  updateFilter: (body: any) => void;
}

const SearchComponentResourcePlanning = ({
  vocabulary,
  currentTeamDetailedData,
  updateFilter,
}: ISearchComponentProps): ReactElement => {
  const initialMembers: IRPMemberSearchResult[] = currentTeamDetailedData.data
    .filter((item: ITeamData) => item.is_active)
    .map((item: ITeamData) => ({
      id: item.user.id,
      name: item.user.username,
      avatar: item.user.avatar,
    }));

  const { v_search } = vocabulary;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState("");
  const [isShowResults, setIsShowResults] = useState(false);
  const [members, setMembers] = useState(initialMembers);

  useEffect(() => {
    setIsShowResults(!!value);
  }, [value]);

  const clearSearch = () => {
    setValue("");
    updateFilter({ usersId: [] });
  };

  const handleInputChange = (inputValue: string) => {
    setValue(inputValue);

    if (!inputValue) {
      setMembers(initialMembers);
    } else {
      const foundedMembers: IRPMemberSearchResult[] = initialMembers.filter(
        (i: IRPMemberSearchResult) =>
          i.name.toLowerCase().includes(inputValue.toLowerCase()),
      );

      setMembers(foundedMembers);
    }
  };

  const handleSelectResult = (id: string) => {
    updateFilter({ usersId: [id] });

    setIsShowResults(false);
  };

  return (
    <div className="rp-search">
      <div
        className={classNames("rp-search__container", {
          "rp-search__container--active": isShowResults,
        })}
      >
        <div className="rp-search__icon-container">
          <SearchIcon className="rp-search__search-icon" color="white" />
        </div>
        <form
          action="true"
          className={"rp-search__form"}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input
            className={classNames("rp-search__input", {
              "rp-search__input--active": isShowResults,
            })}
            type="search"
            ref={inputRef}
            placeholder={v_search}
            value={value}
            onChange={(event) => handleInputChange(event.target.value)}
            onFocus={() => setIsShowResults(!!value)}
          />
        </form>
        {!!value.length && (
          <div className="rp-search__icon-container" onClick={clearSearch}>
            <CrossIcon className="rp-search__close-icon" color="#B9BBBE" />
          </div>
        )}
      </div>
      {isShowResults && (
        <ResourcePlanningSearchResult
          selectResult={handleSelectResult}
          members={members}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
  currentTeamDetailedData: store.teamReducer.currentTeamDetailedData,
});

const mapDispatchToProps = {
  updateFilter: updateFilterValues,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchComponentResourcePlanning);
