import React, { ReactElement } from "react";
import { connect } from "react-redux";

import Avatar from "../Avatar";

import "./style.scss";
import { AppConfig } from "../../../config";
import { IDictionary } from "../../../types/dictionary";
import { IRPMemberSearchResult } from "../../../types/resource-planning.interface";

interface ISearchResultProps {
  vocabulary: IDictionary<any>;
  members: IRPMemberSearchResult[];
  selectResult: (id: string) => void;
}

const ResourcePlanningSearchResult = ({
  vocabulary,
  members,
  selectResult,
}: ISearchResultProps): ReactElement => (
  <div className="rp-search-result">
    <div className="rp-search-result__container">
      <div className="rp-search-result__label">{vocabulary.v_members}</div>
      {members.map((member: IRPMemberSearchResult) => (
        <div
          className="rp-search-result__item"
          onClick={() => selectResult(member.id)}
          key={member.id}
        >
          <Avatar src={`${AppConfig.apiURL}/${member.avatar}`} />
          <div className="rp-search-result__name">{member.name}</div>
        </div>
      ))}
      {!members.length && (
        <div className="rp-search__no-results">{vocabulary.v_no_results}</div>
      )}
    </div>
  </div>
);

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(ResourcePlanningSearchResult);
