import styled from "styled-components/macro";
import { Field, Form } from "formik";
// import ArrowImage from "../../../../assets/images/icons/arrow-down-gray.svg"
import ArrowImage from "../../../../assets/images/icons/arrow-down.svg";

export const StyledForm = styled(Form)`
  margin: 0 5%;
  margin-bottom: 10%;
  padding-bottom: 50px;
`;

export const DropdownContainer = styled.div`
  margin-top: 30px;
  .input-dropdown {
    border: 1px solid
      ${({ hasError }) => (hasError ? "red" : "var(--border-color-team)")};
  }
  & > div {
  }
`;

export const Input = styled(Field)`
  background: none;
  color: var(--text);
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) => (hasError ? "red" : "var(--border-color-team)")};
  padding: 10px 15px;
  width: 100%;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  max-height: 40px;

  ::placeholder {
    font-weight: 500;
    color: #b9bbbe;
  }
`;

export const LabelContainer = styled.div``;

export const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
  position: absolute;
`;

export const DateContainer = styled.div`
  position: relative;
  p{
    z-index: 22;
    position: absolute;
    margin-left: 20px;
  }
  .daterange-select__date-separator{
    display: none;
  }
  .daterange-select__selected-date{
    justify-content: flex-end;
  }
  .daterange-select__date{
    display: none;
  }
  .daterange-select__selected-date {
    background: none;
  }
  }

  .daterange-select__date {
    display: none;
  }

  .daterange-select__selected-date {
    background: none;
  }

  .daterange-select__button-save {
    display: none;
  }

`;

export const TextLabel = styled.p`
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  margin-top: 15px;
`;

export const Button = styled.button`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  margin: 0 5px;
  @media (max-width: 415px) {
    padding: 14px 14px;
  }
`;

export const SalaryContainer = styled.div`
  display: flex;
`;

export const SalaryWrapper = styled.div`
  margin: 0 5px;
`;

export const SaveButton = styled(Button)`
  background: #00b173;
`;

export const DeleteButton = styled(Button)`
  background: #eb5757;
`;

export const CancelButton = styled(Button)`
  background: #52565f;
`;

export const LabelPhoneInput = styled.div`
  position: absolute;
  left: 15px;

  p {
    font-size: 16px;
    color: #b9bbbe;
  }
`;

export const CountryPhoneContainer = styled.div`
  margin: 0 0 10px;
  position: relative;
  display: flex;
  align-items: center;
  background: none !important;

  .react-tel-input {
    display: flex;
    border: var(--invoices-detailed-border-input);
    //box-shadow: var(--project-box-shadow);
    height: 40px;

    .selected-flag {
      z-index: 0;
      background: none !important;
    }
  }
  .flag {
    right: 38px !important;
  }
  .form-control {
    background: var(--background-box-time-day);
    border: 0;
    color: var(--user-setting-color);
    font-size: 16px;
    height: 45px;
    line-height: 22px;
    padding: 1px 0 1px 58px;
    width: 100%;
    display: none;
  }
  .flag-dropdown {
    background: none;
    border: 0;
    border-radius: 4px 0 0 4px;
    width: 100%;

    &:hover .selected-flag {
      background: transparent;
      border-radius: 4px 0 0 4px;
    }
  }

  .flag-dropdown.open {
    background: none !important;
  }

  .arrow {
    background: url(${ArrowImage}) no-repeat center !important;
    border: none !important;
    height: 24px !important;
    left: 15px !important;
    margin-top: -2px !important;
    position: relative !important;
    top: -4px !important;
    transition: all 0.3s !important;
    width: 24px !important;
  }

  .arrow.up {
    border: none;
    transform: rotate(-180deg);
  }

  .selected-flag {
    //background: var(--background-box-time-day) !important;
    border: 1px solid #4d5159;
    border-radius: 5px !important;
    width: 100%;
  }

  .country-list {
    background: var(--background-box-time-day);
    box-shadow: 2px 2px 4px var(--reports-export-modal-shadow);
    padding: 5px;
    top: 40px;
    width: 312px;
    right: 0;
    margin: 0 !important;
  }

  .country {
    color: var(--user-setting-dropdown-color);
    padding: 5px 10px;

    &:hover {
      background-color: var(--bg-hover-user-setting-list-item) !important;
      color: var(--user-setting-dropdown-color);
    }
    .dial-code {
      color: #b9bbbe;
    }
  }

  .search {
    top: -6px !important;
    background-color: var(--bg-hover-user-setting-list-item) !important;
  }

  .highlight {
    background-color: var(--bg-hover-invoices-list-item) !important;
    color: var(--user-setting-dropdown-color) !important;
  }
`;

export const LanguageWrapper = styled.div`
  margin: 0 5px;

  input {
    color: white;
  }

  .technology__suggestions {
    display: none;
  }
`;

export const StyledSVG = styled.div`
  padding: 0 2px;

  svg {
    width: ${(props) => (props.active ? "50px" : "25px")};
    transition: fill 0.3s ease, width 0.3s ease; /* плавний перехід */
  }

  rect {
    width: ${(props) => (props.active ? "50px" : "25px")};
    fill: ${(props) => (props.active ? "#00B173" : "#52565F")};
    transition: fill 0.3s ease, width 0.3s ease; /* плавний перехід */
  }
`;

export const LinesContainer = styled.div`
  display: flex;
  position: absolute;
  right: 88px;
`;

export const WrapperDeleteModal = styled.div`
  .recruitment-modal_delete {
    z-index: 9999999999;
  }
  .modal__blackdrop {
    z-index: 9999999999;
  }
`;

export const WrapperApplicationModal = styled.div`
  background-color: red;
  .recruitment-modal__header {
    display: ${(props) => (props.isHide ? "none" : "flex")};
  }
`;

export const SuccessWrapper = styled.div``;

export const Title = styled.p`
  padding-top: 25px;
  font-weight: 700;
  font-size: 24px;
  color: white;
`;

export const TitleInfo = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: white;
`;

export const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
`;
