import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border: 1px solid #52565F;
  margin-top: 20px;
  border-radius: 8px;
  padding: 0px 5px;
  width: 186px;
`;



export const PeriodSwitcher = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 34px;
  padding: 8px 0px;
`;

export const Text = styled.p<{ isActive?: boolean }> `
  font-family: 'Manrope', sans-serif;
  color: white;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 0px 20px;
`;

export const Runner = styled.div`
  background: #00b173;
  border-radius: 8px;
  height: calc(100% - 4px);
  left: 0;
  position: absolute;
  top: 2px;
  transition: all 0.3s ease;
  width: 84px;

  &.preriod-switcher__right {
    left: calc(50% - 2px);
  }

  &.preriod-switcher__left {
    left: 2px;
  }
`;


// export const Button = styled.button<{ isActive?: boolean }> `
//   font-size: 16px;
//   padding: 8px 16px;
//   border: none;
//   cursor: pointer;
//   transition: background-color 0.3s;
//   outline: none;

//   ${({ isActive }) => isActive
//     ? `
//       background-color: #00B173;
//       color: white;
//     `
//     : `
//       background-color: lightgray;
//       color: black;
//     `
//   }
// `;