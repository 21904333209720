import React from "react";
import "./style.scss";

const InvoicesManagerIconsModal = ({ vocabulary, cancelHandler, title }) => (
  <div className="confirmation-modal-manager-icon" data-theme={"light"}>
    <div className="confirmation-modal-manager-icon__background" />
    <div className="confirmation-modal-manager-icon__container">
      <i
        className="confirmation-modal-manager-icon__container__close"
        onClick={cancelHandler}
      />
      <h2 className="confirmation-modal-manager-icon__text">
        Please save before attempting to {title}
      </h2>
      <div className="confirmation-modal-manager-icon__buttons-wrap">
        <button
          className="confirmation-modal-manager-icon__button confirmation-modal-manager-icon__button--confirm"
          onClick={cancelHandler}
        >
          {"OK"}
        </button>
      </div>
    </div>
  </div>
);

export default InvoicesManagerIconsModal;
