import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";

import { Loading } from "../../Unstructured/Loading";
import Modal from "../../Atoms/Modal";
import ResourcePlanningUserModalForm from "../ResourcePlanningUserModalForm";
import ResourcePlanningUserModalInfo from "../ResourcePlanningUserModalInfo";
import { EditPlanIcon } from "../../Atoms/SvgIcons";

import { AppConfig } from "../../../config";

import {
  getUserInfoAction,
  editUserInfoAction,
} from "../../../redux/actions/ResourcePlanningActions";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

import { ROLES_TITLES } from "../../../services/authentication";

import "./style.scss";
import { STATUS_PROCESSING } from "../../../constants/status";

const InfoItem = ({ title, value }) => (
  <div className="user-modal__info">
    <div className="user-modal__label" title={title}>
      {title}
    </div>
    <div className="user-modal__value">{value}</div>
  </div>
);

class ResourcePlanningUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      isInitialFetching: true,
    };
  }

  async componentDidMount() {
    await this.props.getUserInfoAction(this.props.userId);

    this.setState({ isInitialFetching: false });
  }

  closeModal = () => {
    this.props.onModalClose();
  };

  editUser = () => {
    this.setState({
      isEdit: true,
    });
  };

  cancelEdit = () => {
    this.setState({
      isEdit: false,
    });
  };

  submitForm = async (data) => {
    await this.props.editUserInfoAction({
      userId: this.props.userId,
      body: data,
    });

    this.setState({ isEdit: false });
  };

  getLastSeenValue = () => {
    const { lastActive } = this.props.userData;
    const { lang } = this.props.vocabulary;

    return moment(lastActive).locale(lang.short).fromNow();
  };

  render() {
    const { isEdit, isInitialFetching } = this.state;
    const { vocabulary, open, currentTeam, userData, userModalStatus } =
      this.props;
    const { v_edit, v_resource_planning_page } = vocabulary;
    const {
      v_last_seen,
      v_user_profile_error,
      v_user_profile,
      v_edit_user_profile,
    } = v_resource_planning_page;

    const user = userData?.user || null;
    const role_collaboration = userData?.role_collaboration || null;
    const role_current_user = currentTeam.data.role;
    const endDate = userData?.lastActive || null;
    const nowDate = new Date().toISOString().split("T")[0];

    return (
      <Modal
        open={open}
        title={!isEdit ? v_user_profile : v_edit_user_profile}
        onClose={this.closeModal}
        headerAdditionalElements={
          (!isInitialFetching &&
            !isEdit &&
            userData &&
            ROLES_TITLES[role_current_user] === "Owner") ||
          ROLES_TITLES[role_current_user] === "Admin" ||
          ROLES_TITLES[role_current_user] === "Project manager" ||
          ROLES_TITLES[role_current_user] === "HR manager" ? (
            <button
              className="user-modal__edit-button"
              type="button"
              aria-label={v_edit}
              onClick={this.editUser}
            >
              <EditPlanIcon className="user-modal__edit-icon" />
              <span>{v_edit}</span>
            </button>
          ) : null
        }
        classes={{
          modal: "user-modal",
          container: "user-modal__container",
          wrapper: "user-modal__wrapper",
        }}
      >
        <Loading
          flag={userModalStatus === STATUS_PROCESSING}
          mode="parentSize"
          withLogo={false}
        >
          <div className="user-modal__body">
            {userData ? (
              <>
                <div className="user-modal__header">
                  <div className="user-modal__container-avatar">
                    <div>
                      {user.avatar === null ? (
                        <div
                          className={classNames("user-modal__img-none", {
                            "user-modal__img-none__white":
                              localStorage.getItem("themes") === "light",
                          })}
                        />
                      ) : (
                        <div
                          className="user-modal__avatar"
                          style={{
                            backgroundImage: `url("${AppConfig.apiURL}/${
                              user?.avatar || ""
                            }")`,
                          }}
                        ></div>
                      )}
                      {user.timer_current_v2 !== undefined ? (
                        <div className="user-modal__icon-online" />
                      ) : user.timer_current_v2 === undefined &&
                        endDate !== null ? (
                        <span>
                          {new Date(endDate).toISOString().split("T")[0] ===
                          nowDate ? (
                            <div
                              className="user-modal__icon-online"
                              style={{ backgroundColor: "orange" }}
                            />
                          ) : (
                            <div
                              className="user-modal__icon-online"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </span>
                      ) : (
                        <div
                          className="user-modal__icon-online"
                          style={{ backgroundColor: "red" }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="user-modal__name">
                      {user?.username || ""}
                    </div>
                    <div className="user-modal__profile-info">
                      <div
                        className={classNames("user-modal__role", {
                          "user-modal__role__owner":
                            role_collaboration?.title === "ROLE_OWNER",
                          "user-modal__role__admin":
                            role_collaboration?.title === "ROLE_ADMIN",
                          "user-modal__role__invoices-manager":
                            role_collaboration?.title ===
                            "ROLE_INVOICES_MANAGER",
                          "user-modal__role__member":
                            role_collaboration?.title === "ROLE_MEMBER",
                          "user-modal__role__recruiter":
                            role_collaboration?.title === "ROLE_RECRUITER",
                          "user-modal__role__hr-manager":
                            role_collaboration?.title === "ROLE_HR_MANAGER",
                          "user-modal__role__project-manager":
                            role_collaboration?.title ===
                            "ROLE_PROJECT_MANAGER",
                        })}
                      >
                        {ROLES_TITLES[role_collaboration?.title]}
                      </div>
                      {!isEdit &&
                      user.timer_current_v2 === undefined &&
                      userData.lastActive !== undefined ? (
                        <div className="user-modal__last-seen">
                          {v_last_seen} {this.getLastSeenValue()}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {isEdit ? (
                  <ResourcePlanningUserModalForm
                    userData={userData}
                    onSubmit={this.submitForm}
                    onCancel={this.cancelEdit}
                  />
                ) : (
                  <ResourcePlanningUserModalInfo userData={userData} />
                )}
              </>
            ) : (
              <div className="user-modal__error">{v_user_profile_error}</div>
            )}
          </div>
        </Loading>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  currentTeam: state.teamReducer.currentTeam,
  userData: state.resourcePlanningReducer.userInfo,
  userModalStatus: state.resourcePlanningReducer.userModalStatus,
  error: state.resourcePlanningReducer.error,
});

const mapDispatchToProps = {
  getUserInfoAction,
  editUserInfoAction,
  showNotificationAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcePlanningUserModal),
);
