import { AppConfig } from "../config";

export const PERIODS = {
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export const SUBSCRIPTIONS = AppConfig.subscriptionList;

export const UNLIMITED_ERROR = "Upgrade your plan to have an unlimited number of invoices.";
