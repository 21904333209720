import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import Checkbox from "@material-ui/core/Checkbox";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

// Services
import { apiCall } from "../../../services/apiService";
import { decodeTimeEntryIssue } from "../../../services/timeEntryService";

// Components

// Actions

// Queries

// Config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";

const materialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
      },
    },
  },
});

class ReportsByProjectSearchBar extends Component {
  state = {
    toggleSelectUser: false,
    userDataSelected: [],
    userDataFiltered: [],
    userDataEtalon: [],
    searchValue: "",
    selectNoneActive: false,

    disabled: false,
  };

  checkedDisable = () => {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: false });
  };

  componentDidMount() {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userDataEtalon !== this.state.userDataEtalon) {
      const userDataEtalonSorted = this.state.userDataEtalon;

      this.setState({
        userDataEtalon: userDataEtalonSorted.sort((a, b) =>
          this.sortEtalonUser(a, b),
        ),
      });
    }
    if (
      prevProps.reportUsers !== this.props.reportUsers &&
      Boolean(this.props.reportUsers.length) &&
      !prevProps.reportUsers.length
    ) {
      this.setState({
        userDataEtalon: this.props.reportUsers,
        userDataSelected: this.props.reportUsers,
      });
    }
    if (
      prevProps.searchValue.length !== this.props.searchValue.length ||
      prevProps.checked !== this.props.checked
    ) {
      this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    }
    if (prevState.userDataSelected !== this.state.userDataSelected) {
      if (this.state.userDataSelected.length === 0) {
        this.props.reportsPageAction("SELECT_NONE_ACTIVE", { data: false });
      }
    }
  }

  sortEtalonUser = (a, b) => {
    const nameA = (a.username || "").trim().toLowerCase();
    const nameB = (b.username || "").trim().toLowerCase();

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }
  };

  openSelectUser() {
    this.setState({ toggleSelectUser: true });
    this.findUser(this.state.userDataEtalon);
    document.addEventListener("click", this.closeDropdownUser);
  }

  clearUserSearch() {
    this.smallSelectUserInputRef.value = "";
    this.findUser(this.state.userDataEtalon);
  }

  closeDropdownUser = (e) => {
    if (
      this.selectListUsersRef &&
      !this.selectListUsersRef.contains(e.target)
    ) {
      this.setState(
        {
          toggleSelectUser: false,
        },
        () => {
          document.removeEventListener("click", this.closeDropdownUser);
        },
      );
    }
  };

  findUser(items, searchText = "") {
    if (searchText.length > 0) {
      const filteredArr = items.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase().trim()),
      );

      this.setState({ userDataFiltered: filteredArr });
    } else {
      this.setState({ userDataFiltered: items });
    }
  }

  applySearch() {
    if (this.props.searchValue.length) {
      this.props.applySearch(
        this.state.userDataSelected.map((u) => u.email),
        this.props.searchValue,
      );
    } else {
      this.props.applySearch(
        this.state.userDataSelected.map((u) => u.email),
        "",
      );
    }
  }

  getCheckedUsers(email) {
    return this.state.userDataSelected.some((item) => item.email === email);
  }

  toggleUser(user) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    this.props.reportsPageAction("SELECT_NONE_ACTIVE", { data: true });
    const users = JSON.parse(JSON.stringify(this.state.userDataSelected));
    let exists = false;

    for (let i = 0; i < users.length; i++) {
      const currentUser = users[i];

      if (currentUser.email === user.email) {
        exists = true;
        users.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      users.push(user);
    }

    this.setState({ userDataSelected: users });
  }

  selectAllUsers() {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    this.props.reportsPageAction("SELECT_NONE_ACTIVE", { data: true });
    this.setState({
      userDataSelected: this.state.userDataEtalon,
    });
  }

  selectNoneUsers() {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    this.props.reportsPageAction("SELECT_NONE_ACTIVE", { data: false });
    this.setState({ userDataSelected: [] });
  }

  render() {
    const { vocabulary } = this.props;
    const { v_user, v_find, v_select_all, v_select_none, v_apply } = vocabulary;

    return (
      <div className="wrapper_reports_by_project_search_bar">
        <div className="reports_by_project_search_bar_search_field_container">
          <i
            className={cn("magnifer", {
              magnifer__light: localStorage.getItem("themes") === "light",
            })}
          />
          <input
            type="text"
            onKeyUp={(e) => (e.keyCode === 13 ? this.applySearch() : null)}
            value={this.props.searchValue}
            // onChange={(e) => this.setState({ searchValue: e.target.value })}
            onChange={this.props.searchValueHandler}
            className="reports_by_project_search_bar_search_field"
          />
        </div>

        <div className="reports_by_project_search_bar_search_field_container select">
          <div className="reports_by_project_search_select_wrapper">
            <div
              className="reports_by_project_search_select_header"
              onClick={(_) => this.openSelectUser()}
            >
              <div>
                {v_user}
                :&nbsp;
                {this.state.userDataSelected.length === 0
                  ? v_select_none
                  : this.state.userDataSelected.length ===
                    this.state.userDataEtalon.length
                  ? v_select_all
                  : this.state.userDataSelected.map((item, index) => (
                      <span key={item.username + index}>
                        {index === 0 ? item.username : `, ${item.username}`}
                      </span>
                    ))}
              </div>
              <i
                className={cn(
                  `arrow_down ${this.state.toggleSelectUser ? "arrow_up" : ""}`,
                  {
                    arrow_down__light_themes:
                      localStorage.getItem("themes") === "light",
                  },
                )}
              />
            </div>
          </div>
          {this.state.toggleSelectUser && (
            <div
              className="select_body"
              ref={(div) => (this.selectListUsersRef = div)}
            >
              <div className="search_menu_select">
                <input
                  type="text"
                  onKeyUp={(_) =>
                    this.findUser(
                      this.state.userDataEtalon,
                      this.smallSelectUserInputRef.value,
                    )
                  }
                  ref={(input) => (this.smallSelectUserInputRef = input)}
                  placeholder={`${v_find}...`}
                />
                <div
                  ref={(div) => (this.selectAllUsersRef = div)}
                  onClick={(_) => this.selectAllUsers()}
                >
                  {v_select_all}
                </div>
                <div
                  ref={(div) => (this.selectNoneUsersRef = div)}
                  onClick={(_) => this.selectNoneUsers()}
                >
                  {v_select_none}
                </div>
                <i
                  className={cn("small_clear", {
                    small_clear__light:
                      localStorage.getItem("themes") === "light",
                  })}
                  onClick={(_) => this.clearUserSearch()}
                />
              </div>
              <div className="select_items_container">
                {this.state.userDataFiltered.map((item, index) => (
                  <div className="select_users_item" key={item.email}>
                    <label>
                      <ThemeProvider theme={materialTheme}>
                        <Checkbox
                          color={"primary"}
                          value={item.email || ""}
                          checked={this.getCheckedUsers(item.email)}
                          onChange={(_) => {
                            this.toggleUser(item);
                          }}
                        />
                      </ThemeProvider>{" "}
                      <span className="select_users_item_username">
                        {item.username}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="reports_by_project_search_bar_button_container">
          <button
            className="reports_by_project_search_bar_button"
            disabled={!this.props.isDisabledFilter} // <--
            onClick={() => {
              this.applySearch();
              this.checkedDisable();
            }}
          >
            {v_apply}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isDisabledFilter: state.reportsPageReducer.isDisabledFilter,
});

export default connect(mapStateToProps)(ReportsByProjectSearchBar);
