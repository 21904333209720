import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { createBrowserHistory } from "history";

import * as serviceWorker from "./serviceWorker";

import App from "./App";

import { AppConfig } from "./config";

export const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <GoogleOAuthProvider clientId={AppConfig.socialAuth.fbAppGoogleId}>
          <App />
          </GoogleOAuthProvider>
        </Router>
      </Provider>
  </React.StrictMode>,

  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
