import React, { ReactElement } from "react";
import { IDictionary } from "../../../../types/dictionary";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getTimeDurationByGivenTimestamp } from "../../../../services/timeService";
import { getDateForLink } from "../../../../services/reportsService";
import { ICurrentTeam } from "../../../../types/teams";
import { ISelectionRange } from "../../../../types/reports";

interface IProps {
  vacabulary: IDictionary<any>;
  currentTeam: ICurrentTeam;
  durationTimeFormat: string;
  users: IDictionary<any>;
  selectionRange: ISelectionRange;
}

const ReportsProjectsByTeams = ({
  currentTeam,
  users,
  durationTimeFormat,
  selectionRange,
}: IProps): ReactElement => {
  const sortedUsers = _.orderBy(users, "total", ["desc"]);

  return (
    <div className="projects_data">
      {users &&
        sortedUsers.map((user, index) => {
          const teams = _.map(
            _.omit(user, ["email", "total", "username"]),
            (projects, index) => ({
              teamName: index,
              teamTotal: _.sumBy(projects, "duration"),
              teamProjects: _.orderBy(projects, "duration", ["desc"]),
            }),
          );

          const sortedTeams = _.orderBy(teams, "teamTotal", ["desc"]);

          return (
            <div className="projects_user" key={user?.email + index}>
              <div className="projects_user_data team_user_data">
                <div className="user-name">{user?.username}</div>
                <div className="project-name" />
                <div className="time">
                  {getTimeDurationByGivenTimestamp(
                    user.total,
                    durationTimeFormat,
                  )}
                </div>
              </div>
              {sortedTeams.map(
                ({ teamName, teamTotal, teamProjects }, index) => (
                  <>
                    <div className="projects_user_data" key={teamName + index}>
                      <div className="team_user-name" />
                      <div className="team-name">
                        {" "}
                        team: <span>{teamName}</span>
                      </div>
                      <div className="project-name" />
                      <div className="time team_total_time">
                        {getTimeDurationByGivenTimestamp(
                          teamTotal,
                          durationTimeFormat,
                        )}
                      </div>
                    </div>
                    {teamProjects.map((proj, index) => (
                      <Link
                        to={{
                          pathname: `/reports/detailed/projects/${encodeURIComponent(
                            proj.name,
                          )}/team/${user.email || "all"}/from/${getDateForLink(
                            selectionRange.startDate,
                          )}/to/${getDateForLink(
                            selectionRange.endDate,
                          )}/teamId/${proj.teamId || currentTeam.data.id}`,
                          prevPageReport: true,
                        }}
                        style={{ textDecoration: "none" }}
                        key={user.email + index}
                      >
                        <div key={proj + index}>
                          <div className="projects_user_data">
                            <div className="user-name" />
                            <div className="project-name">{proj.name}</div>
                            <div className="time">
                              {getTimeDurationByGivenTimestamp(
                                proj.duration,
                                durationTimeFormat,
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </>
                ),
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
  currentTeam: store.teamReducer.currentTeam,
  durationTimeFormat: store.userReducer.durationTimeFormat,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsProjectsByTeams);
