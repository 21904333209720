import React from "react";

// dependencies
import Lottie from "lottie-react";
import animationDataWhite from "../../../../lotties/whiteCat.json";
import animationDataBlack from "../../../../lotties/blackCat.json";
// Styles
import "./blankList.scss";
import animation_cat from "../../../../assets/images/Dark_Theme-Cat.gif";
import animation_cat_white from "../../../../assets/images/Light_Theme-Cat.gif";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationDataWhite,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptionsBlack = {
  loop: true,
  autoplay: true,
  animationData: animationDataBlack,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const BlankListComponent = ({ text = "", subtext, position }) => (
  <div className="cat-wrapper" style={position}>
    {localStorage.getItem("themes") === "light" ? (
      <div className="cat-wrapper__animation">
        <Lottie {...defaultOptions} />
      </div>
    ) : (
      <div className="cat-wrapper__animation">
        <Lottie {...defaultOptionsBlack} />
      </div>
    )}
    <p className={"animation-cat-comment"}>
      {text}
      <br />
      {subtext}
    </p>
  </div>
);
