import React, { Component } from "react";
import { EditPlanIcon, CalendarIcon } from "../../Atoms/SvgIcons";
import { checkAccessByRole, ROLES } from "../../../services/authentication";

import moment from "moment";

import "./style.scss";

export default class ResourcePlanningPlanModalReadonly extends Component {
  getProjectNameById = () => {
    const { data } = this.props;

    return data.itemProps.projectName || "";
  };

  getUserNameById = (id) => {
    const { currentTeamDetailedData } = this.props;

    return (
      currentTeamDetailedData.data.find((u) => u.user?.id === id)?.user
        ?.username || ""
    );
  };

  render() {
    const {
      vocabulary,
      data,
      editPlan,
      openModalDelete,
      openHistoryModal,
      user,
      timeFormat,
      userRole,
    } = this.props;
    const {
      v_resource_planning_page,
      lang,
      v_date,
      v_assignee,
      v_delete,
      v_description,
      v_projects,
      v_last_change,
      v_rp_history,
    } = vocabulary;

    const { v_edit_plan_title, v_task_name } = v_resource_planning_page;

    return (
      <>
        <div className="plan-modal-info__info-item">
          <div className="plan-modal-info__label">{v_task_name}</div>
          <div className="plan-modal-info__text">{data.title}</div>
        </div>
        <div className="plan-modal-info__row">
          <div className="plan-modal-info__info-item">
            <div className="plan-modal-info__label">{v_projects}</div>
            <div className="plan-modal-info__text">
              {this.getProjectNameById()}
            </div>
          </div>
          <div className="plan-modal-info__info-item">
            <div className="plan-modal-info__label">{v_date}</div>
            <div className="plan-modal-info__dates-info">
              <CalendarIcon color="white" />
              <span className="plan-modal-info__date-info">
                {moment(data.start).locale(lang.short).format("MMM DD")} -{" "}
                {moment(data.end).locale(lang.short).format("MMM DD")}
              </span>
            </div>
          </div>
          <div className="plan-modal-info__info-item">
            <div className="plan-modal-info__label">
              {"Set the load in hours:"}
            </div>
            <div className="plan-modal-info__text">
              {data.itemProps.load_hours}
              {"h"}
            </div>
          </div>
        </div>
        <div className="plan-modal-info__row2">
          <div className="plan-modal-info__info-item">
            <div className="plan-modal-info__label">{v_assignee}</div>
            <div className="plan-modal-info__badges-container ">
              <div className="plan-modal-info__badge">
                {this.getUserNameById(data.itemProps.user_id)}
              </div>
            </div>
          </div>
          <div className="plan-modal-info__info-item">
            <div className="plan-modal-info__history-row">
              <div className="plan-modal-info__label">{v_last_change}</div>
            </div>
            <div className="plan-modal-info__change">
              {data.itemProps?.timer_planning_history[0]?.updated_data ? (
                <span className="plan-modal-info__change-badge">
                  {data.itemProps.timer_planning_history[0].updated_data
                    .updateBy ||
                    data.itemProps?.timer_planning_history[0]?.updated_data
                      .createdBy}
                </span>
              ) : null}
              <div>
                {moment(data.itemProps?.timer_planning_history[0]?.created_at)
                  .locale(lang.short)
                  .format(
                    `${
                      timeFormat === "12" ? "MMMM DD / h:mm" : "MMMM DD / HH:mm"
                    }`,
                  ) +
                  moment(
                    data.itemProps?.timer_planning_history[0]?.created_at,
                  ).format(`${timeFormat === "12" ? " a" : " "}`)}
              </div>
            </div>
          </div>
          <div className="plan-modal-info__info-item">
            <button
              className="plan-modal-info__history-btn"
              onClick={() => openHistoryModal()}
            >
              {v_rp_history.historyS}
            </button>
          </div>
        </div>
        <div className="plan-modal-info__info-item2">
          <div className="plan-modal-info__label">{v_description}</div>
          <div className="plan-modal-info__description">
            {data.itemProps.description}
          </div>
        </div>
        {userRole &&
          (!checkAccessByRole(userRole, [
            ROLES.ROLE_MEMBER,
            ROLES.ROLE_RECRUITER,
          ]) ||
            data.itemProps.user_id === user.id) && (
            <div className="plan-modal-info__footer">
              <button
                className="plan-modal-info__btn plan-modal-info__btn--cancel"
                onClick={() => editPlan()}
              >
                <EditPlanIcon color="#fff" />
                <span>{v_edit_plan_title}</span>
              </button>
              <button
                className="plan-modal-info__btn plan-modal-info__btn--delete"
                onClick={() => openModalDelete()}
              >
                {v_delete}
              </button>
            </div>
          )}
      </>
    );
  }
}
