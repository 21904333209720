import React from "react";
import ReleaseNotesDropdown from "../ReleaseNotesDropdown";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";

// Styles
import "./style.scss";

const PageHeader = ({
  title,
  children,
  disabledTitle = false,
  denide = false,
}) => (
  <div className="header-wrapper">
    {!disabledTitle && (
      <h1 className="header-wrapper__title">
        {title}
        <CustomTippy
          content={
            <div>
              {"This feature is available if you upgrade your package"}
              <p>{"Upgrade your plan"}</p>
            </div>
          }
          className="RP-tippy"
          placement="bottom"
        >
          {denide && <i className="rp-lock-icon" />}
        </CustomTippy>
      </h1>
    )}
    <div
      className={
        window.location.pathname !== "/reports/summary" &&
        window.location.pathname !== "/reports/real-time"
          ? "header-wrapper__content"
          : "reports_header-wrapper_content"
      }
    >
      <div className="header-wrapper__child">{children}</div>
      {window.location.pathname === "/timer" && <ReleaseNotesDropdown />}
    </div>
  </div>
);

export default PageHeader;
