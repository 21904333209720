import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { getPaymentHistoryAction } from "../../../../redux/actions/SubscriptionPageAction";

import { Loading } from "../../Loading";

import BillingHistoryTable from "../../BillingHistoryTable";

import "./style.scss";
import DateRangeWrapper from "../../DateRangeWrapper";
import { BlankListComponent } from "../../CommonComponents/BlankListcomponent/BlankListComponent";

const InvAndPaym = ({
  vocabulary,
  getPaymentHistoryAction,
  paymentHistory,
  isFetching,
}) => {
  const defaultRange = {
    startDate: moment().startOf("year").toDate(),
    endDate: moment().endOf("year").toDate(),
  };
  const [range, setRange] = useState(defaultRange);

  useEffect(() => {
    getPaymentHistoryAction({
      fromDate: moment().startOf("year").unix(),
      toDate: moment().endOf("year").unix(),
    });
  }, []);

  const onDataRangeChange = async (value) => {
    setRange(value);
    await getPaymentHistoryAction({
      fromDate: moment(value.startDate).unix(),
      toDate: moment(value.endDate).endOf("day").unix(),
    });
  };

  const handleReset = async () => {
    await getPaymentHistoryAction({
      fromDate: moment().startOf("year").unix(),
      toDate: moment().endOf("year").unix(),
    });
    setRange(defaultRange);
  };

  return (
    <div className="inv-and-paym">
      <div className="inv-and-paym__header">
        <div className="inv-and-paym__title">
          {vocabulary.v_subscription.invoicesAndPayments.title}
        </div>
        <div className="inv-and-paym__controls">
          <DateRangeWrapper
            onChange={(value) => onDataRangeChange(value)}
            value={range}
          />
          <button className="inv-and-paym__reset-btn" onClick={handleReset}>
            {vocabulary.v_reset}
          </button>
        </div>
      </div>
      <Loading flag={isFetching} mode="parentSize" withLogo={false}>
        {paymentHistory.length ? (
          <div className="inv-and-paym__table-wrap">
            <BillingHistoryTable paymentHistory={paymentHistory} />
          </div>
        ) : (
          <BlankListComponent
            subtext={vocabulary.v_no_results}
            // position={{ bottom: "-20%" }}
          />
        )}
      </Loading>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isFetching: state.subscriptionPageReducer.isFetching,
  paymentHistory: state.subscriptionPageReducer.paymentHistory,
});
const mapDispatchToProps = {
  getPaymentHistoryAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvAndPaym);
