import React from "react";
import { connect } from "react-redux";
import cn from "classnames";

import {
  AmericanExpSVG,
  MastercardSVG,
  PayPalSVG,
  VisaSVG,
} from "../Subscriprions/CardBrands";
import CheckoutForm from "../Subscriprions/CheckoutForm";

import "./style.scss";

const PaymentInfoModal = ({
  handleSubmit,
  handleClose,
  paymentDetails,
  selectedPlan,
  changePaymentMethod,
  isProcessing,
}) => (
  <div
    className="payment-info-modal"
    data-theme={localStorage.getItem("themes")}
  >
    <div className="payment-info-modal__header">
      <div
        className={cn("payment-info-modal__logo", {
          "payment-info-modal__logo__light":
            localStorage.getItem("themes") === "light",
        })}
      />
      <button
        className={cn("payment-info-modal__close", {
          "payment-info-modal__close__light":
            localStorage.getItem("themes") === "light",
        })}
        onClick={() => handleClose()}
      />
    </div>
    <div className="payment-info-modal__body">
      <div className="payment-info-modal__form">
        <div className="payment-info-modal__body-header">
          <h2 className="payment-info-modal__header-title">
            Credit Card Details
          </h2>
          <h3 className="payment-info-modal__header-subtitle">
            Enter your card details for payment
          </h3>
          <div className="payment-info-modal__card-brands">
            <VisaSVG />
            <AmericanExpSVG />
            <PayPalSVG />
            <MastercardSVG />
          </div>
        </div>
        <CheckoutForm
          paymentDetails={paymentDetails}
          selectedPlan={selectedPlan}
          handleSubmit={handleSubmit}
          changePaymentMethod={changePaymentMethod}
          successHandler={(isSuccess) => handleClose(isSuccess)}
          isProcessing={isProcessing}
        />
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(PaymentInfoModal);
