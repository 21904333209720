import React, { useEffect} from "react";
import { connect } from "react-redux";
import { HeadRow, TableCell } from "./My.styled";
import "./style.scss";
import { getHistory } from "../../../redux/actions/ResourcePlanningActions";
import { ExpandHistory } from "./ExpandHistory";

const ResourcePlanningPlanHistoryModal = ({
  vocabulary,
  closeHistoryModal,
  data,
  getHistory,
  history,
  timeFormat,
  teamUsers,
}) => {
  const planId = data.id;
  const { v_rp_history } = vocabulary;
  
  useEffect(() => {
    getHistory({ planId });
  }, [planId]);

  return (
    <div className="history-modal">
      <div className="history-modal__background" />

      <div className="history-modal__container">
        <div className="history-modal__container-header">
          <div className="history-modal__container-header-title">
            {v_rp_history.historyL}
          </div>
          <i
            className="history-modal__container-header-close"
            onClick={() => closeHistoryModal()}
          />
        </div>
        <table className="history-modal__container-table">
          <thead className="history-modal__container-table__header">
            <HeadRow>
              <TableCell>{"         "}</TableCell>
              <TableCell>{v_rp_history.header.when}</TableCell>
              <TableCell>{v_rp_history.header.type}</TableCell>
              <TableCell>{v_rp_history.header.who}</TableCell>
            </HeadRow>
          </thead>
          <tbody className="history-modal__container-table__body">
            {history.map(({ created_at, updated_data }, index) => (
              <ExpandHistory
                created_at={created_at}
                updated_data={updated_data}
                index={index}
                timeFormat={timeFormat}
                teamUsers={teamUsers}
                vocabulary={vocabulary}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  history: state.resourcePlanningReducer.planHistory,
  teamUsers: state.teamReducer.currentTeamDetailedData.data,
});

const mapDispatchToProps = {
  getHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcePlanningPlanHistoryModal);
