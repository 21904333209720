import React, { Component } from "react";
import { connect } from "react-redux";

import Timeline, {
  SidebarHeader,
  TimelineHeaders,
  DateHeader,
  CustomMarker,
} from "react-calendar-timeline";

import _ from "lodash";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";

import moment from "moment";
import ResourcePlanningGroupItem from "../ResourcePlanningGroupItem";
import ResourcePlanningIntervalItem from "../ResourcePlanningIntervalItem";
import ResourcePlanningItem from "../ResourcePlanningItem";
import ResourcePlanningPlanModal from "../../../components/Unstructured/ResourcePlanningPlanModal";
import InfoLabel from "../GenerateGroupsAndItemForResourcePlanning/InfoLabell";

import {
  updateTimeline,
  editPlanAction,
  getPlansByTeamAction,
  updateShowBy,
  changeThemesAction,
  getWorkload,
} from "../../../redux/actions/ResourcePlanningActions";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

import "./style.scss";
import {
  DAY_TIMESTAMP,
  MONTH_TIMESTAMP,
  YEAR_TIMESTAMP,
  WEEK_TIMESTAMP,
  SHOW_BY_DAY,
  SHOW_BY_WEEK,
  SHOW_BY_MONTH,
  SHOW_BY_YEAR,
} from "../../../constants/timeline";

import { STATUS_ERROR, STATUS_SUCCESS } from "../../../constants/status";
import containerResizeDetector from "react-calendar-timeline/lib/resize-detector/container";
import classNames from "classnames";
import { ArrowIconLeft } from "../../Atoms/SvgIcons";

// make sure you include the timeline stylesheet or the timeline will not be styled
// import 'react-calendar-timeline/lib/Timeline.css';

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title",
};

const DEFAULT_SIDEBAR_WIDTH = 226;
const COLLAPSED_SIDEBAR_WIDTH = 70;

class ResourcePlanningLibrary extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      items: props.items,
      draggedItem: undefined,
      selectedItems: [],
      time: null,
      groupId: null,
      isOpenPlanModal: false,
      sidebarWidth: 226,
      positionDetails: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.items, this.props?.items)) {
      this.setState({ items: this.props.items });
    }
    if (prevProps.themes !== this.props.themes) {
      this.props.changeThemesAction(this.props.themes);
    }
    if (prevProps.isMobile !== this.props.isMobile) {
      this.toggleDetailsIsMobile();
    }
  }

  handleItemMove = async (itemId, dragTime, newGroupOrder) => {
    const { groups } = this.props;
    const { items } = this.state;
    const group = groups[newGroupOrder];
    let item = items.find((i) => i.id === itemId);
    const prevItemGroupId = item.group;

    const prevStart = moment(item.start).toDate().toISOString();
    const prevEnd = moment(item.end).toDate().toISOString();

    item = {
      ...item,
      end: dragTime + (item.end - item.start),
      start: dragTime,
      group: group.id,
    };

    const body = {
      startDate: moment(item.start).toDate().toISOString(),
      endDate: moment(item.end).toDate().toISOString(),
      duration: item.end - item.start,
    };

    if (prevStart === body.startDate && prevEnd === body.endDate) {
      delete body["startDate"];
      delete body["endDate"];
      delete body["duration"];
    }

    if (prevItemGroupId !== group.id) {
      body.userId = group.id;
    }

    this.setState((state) => ({
      items: state.items.map((i) => {
        if (i.id === item.id) {
          return { ...item };
        }

        return i;
      }),
      draggedItem: undefined,
      selectedItems: [],
    }));

    if (Object.keys(body).length === 0) {
      return;
    }
    await this.props.editPlanAction(body, itemId);

    const {
      v_success_edited_plan,
      v_error_user_is_not_exist_in_project,
      v_error_user_does_not_exist_in_this_project,
      v_error_user_not_admin_owner,
      v_error_you_can_not_change_holidays,
    } = this.props.vocabulary.v_resource_planning_page;

    const status = this.props.planModalStatus;

    if (status === STATUS_SUCCESS) {
      this.props.showNotificationAction({
        text: v_success_edited_plan,
        type: "success",
      });
    }

    if (status === STATUS_ERROR) {
      this.setState({ items: this.props.items });
      const error = this.props.planModalError;

      if (error.response.data.message === "user is not exist in this project") {
        this.props.showNotificationAction({
          text: v_error_user_is_not_exist_in_project,
          type: "error",
        });
      } else if (error.response.data.message === "ERROR.USER.NOT.ADMIN/OWNER") {
        this.props.showNotificationAction({
          text: v_error_user_not_admin_owner,
          type: "error",
        });
      } else if (
        error.response.data.message ===
        "You cannot change the date of public holidays"
      ) {
        this.props.showNotificationAction({
          text: v_error_you_can_not_change_holidays,
          type: "error",
        });
      } else if (
        error.response.data.message === "User does not exist in this project"
      ) {
        this.props.showNotificationAction({
          text: v_error_user_does_not_exist_in_this_project,
          type: "error",
        });
      } else {
        this.props.showNotificationAction({
          text: error?.message,
          type: "error",
        });
      }
    }
  };

  handleItemResize = async (itemId, time, edge) => {
    const { items } = this.state;
    let item = items.find((i) => i.id === itemId);

    const prevStart = moment(item.start).toDate().toISOString();
    const prevEnd = moment(item.end).toDate().toISOString();

    item = {
      ...item,
      start:
        edge === "left"
          ? moment(time).startOf("day")
          : moment(item.start).startOf("day"),
      end:
        edge === "left"
          ? moment(item.end).endOf("day")
          : moment(time).endOf("day"),
    };

    const body = {
      startDate: moment(item.start).toDate().toISOString(),
      endDate: moment(item.end).toDate().toISOString(),
      duration: item.end - item.start,
    };

    if (prevStart === body.startDate && prevEnd === body.endDate) {
      delete body["startDate"];
      delete body["endDate"];
      delete body["duration"];
    }

    this.setState((state) => ({
      items: state.items.map((i) => {
        if (i.id === item.id) {
          return { ...item };
        }

        return i;
      }),
      draggedItem: null,
      selectedItems: [],
    }));

    if (Object.keys(body).length === 0) {
      return;
    }
    await this.props.editPlanAction(body, itemId);

    await this.props.getWorkload();

    const {
      v_success_edited_plan,
      v_error_user_is_not_exist_in_project,
      v_error_user_does_not_exist_in_this_project,
      v_error_user_not_admin_owner,
      v_error_you_can_not_change_holidays,
    } = this.props.vocabulary.v_resource_planning_page;

    const status = this.props.planModalStatus;

    if (status === STATUS_SUCCESS) {
      this.props.showNotificationAction({
        text: v_success_edited_plan,
        type: "success",
      });
    }

    if (status === STATUS_ERROR) {
      this.setState({ items: this.props.items });
      const error = this.props.planModalError;

      if (error.response.data.message === "user is not exist in this project") {
        this.props.showNotificationAction({
          text: v_error_user_is_not_exist_in_project,
          type: "error",
        });
      } else if (error.response.data.message === "ERROR.USER.NOT.ADMIN/OWNER") {
        this.props.showNotificationAction({
          text: v_error_user_not_admin_owner,
          type: "error",
        });
      } else if (
        error.response.data.message ===
        "You cannot change the date of public holidays"
      ) {
        this.props.showNotificationAction({
          text: v_error_you_can_not_change_holidays,
          type: "error",
        });
      } else if (
        error.response.data.message === "User does not exist in this project"
      ) {
        this.props.showNotificationAction({
          text: v_error_user_does_not_exist_in_this_project,
          type: "error",
        });
      } else {
        this.props.showNotificationAction({
          text: error?.message,
          type: "error",
        });
      }
    }
  };

  handleItemDrag = ({ eventType, itemId, time, edge, newGroupOrder }) => {
    let item = this.state.draggedItem ? this.state.draggedItem.item : undefined;

    if (!item) {
      item = this.state.items.find((i) => i.id === itemId);
    }
    this.setState({
      draggedItem: {
        item: item,
        group: this.props.groups[newGroupOrder],
        time,
      },
    });
  };

  onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    const { updateTimeline } = this.props;

    updateScrollCanvas(
      moment(visibleTimeStart).valueOf(),
      moment(visibleTimeEnd).valueOf(),
    );
    updateTimeline({ visibleTimeStart, visibleTimeEnd });
  };

  onBoundsChange = () => {
    const {
      getPlansByTeamAction,
      timeRange,
      visibleTimeEnd,
      visibleTimeStart,
    } = this.props;

    const visibleTimestampStart = moment(visibleTimeStart).valueOf();
    const visibleTimestampEnd = moment(visibleTimeEnd).valueOf();

    if (
      visibleTimestampStart < timeRange.start ||
      visibleTimestampEnd > timeRange.end
    ) {
      getPlansByTeamAction();
    }
  };

  handleZoom = (timelineContext) => {
    const { updateShowBy, showBy } = this.props;
    const { canvasTimeStart, canvasTimeEnd } = timelineContext;

    const week = WEEK_TIMESTAMP;
    const month = DAY_TIMESTAMP * 28;
    const year = month * 12;

    const diff = canvasTimeEnd - canvasTimeStart;
    let showByValue = SHOW_BY_DAY;

    if (diff < week) {
      showByValue = SHOW_BY_DAY;
    } else if (diff >= week && diff < month) {
      showByValue = SHOW_BY_WEEK;
    } else if (diff >= month && diff < year) {
      showByValue = SHOW_BY_MONTH;
    } else if (diff >= year) {
      showByValue = SHOW_BY_YEAR;
    }

    if (showBy != showByValue) {
      updateShowBy(showByValue);
    }
  };

  openPlanInfoModal = (item) => {
    this.props.openPlanInfoModal(item);
    if (window.innerWidth <= 700) {
    }
  };

  handleItemSelect = (itemId, e, time) => {
    this.setState({ selectedItems: [itemId], isShowPopupItem: false });
  };

  handleItemDeselect = (e) => {
    this.setState({ selectedItems: [] });
  };

  openPlanModal = () => {
    this.setState({
      isOpenPlanModal: true,
    });
  };

  closePlanModal = () => {
    this.setState({ isOpenPlanModal: false });
  };

  handleCanvasClick = (groupId, time, event, item) => {
    this.openPlanModal();
    this.setState({ time: time, groupId: groupId });
  };

  toggleDetails = () => {
    this.setState({ positionDetails: !this.state.positionDetails });
    this.setState((prevState) => ({
      sidebarWidth:
        prevState.sidebarWidth === DEFAULT_SIDEBAR_WIDTH
          ? COLLAPSED_SIDEBAR_WIDTH
          : DEFAULT_SIDEBAR_WIDTH,
    }));
  };

  toggleDetailsIsMobile = () => {
    this.setState({
      positionDetails: false,
      sidebarWidth: COLLAPSED_SIDEBAR_WIDTH,
    });
  };

  render() {
    const {
      items,
      selectedItems,
      time,
      isOpenPlanModal,
      groupId,
      sidebarWidth,
      positionDetails,
    } = this.state;
    const {
      unit,
      visibleTimeEnd,
      visibleTimeStart,
      showBy,
      vocabulary,
      defaultTimeStart,
      defaultTimeEnd,
      timeFormat,
      openUserModal,
      groups,
      projectsList,
      themes,
    } = this.props;
    const { lang, v_all_members, v_details, v_expand, v_collapse } = vocabulary;

    const dateForMarker =
      moment(new Date()) > moment(visibleTimeStart)
        ? moment(new Date()).valueOf()
        : moment(visibleTimeStart).valueOf();
    const width = this.containerRef.current
      ? sidebarWidth === DEFAULT_SIDEBAR_WIDTH
        ? this.containerRef?.current?.offsetWidth
        : this.containerRef?.current?.offsetWidth - 1.5
      : "auto";

    return (
      <div ref={this.containerRef}>
        <div
          style={{
            width,
          }}
        >
          <Timeline
            groups={groups}
            items={items}
            keys={keys}
            fullUpdate={false}
            itemTouchSendsClick={false}
            stackItems={true}
            itemHeightRatio={0.85}
            canMove={true}
            canResize={"both"}
            minResizeWidth={0}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            visibleTimeStart={moment(visibleTimeStart).valueOf()}
            visibleTimeEnd={moment(visibleTimeEnd).valueOf()}
            onItemMove={this.handleItemMove}
            onItemResize={this.handleItemResize}
            onItemDrag={this.handleItemDrag}
            dragSnap={1440 * 60 * 1000}
            onItemSelect={this.handleItemSelect}
            onItemDeselect={this.handleItemDeselect}
            onZoom={this.handleZoom}
            selected={selectedItems}
            lineHeight={52}
            sidebarWidth={sidebarWidth}
            resizeDetector={containerResizeDetector}
            traditionalZoom={false}
            minZoom={DAY_TIMESTAMP}
            maxZoom={YEAR_TIMESTAMP}
            onTimeChange={this.onTimeChange}
            onBoundsChange={this.onBoundsChange}
            onCanvasClick={this.handleCanvasClick}
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
              timelineContext,
            }) => (
              <ResourcePlanningItem
                item={item}
                itemContext={itemContext}
                timelineContext={timelineContext}
                getItemProps={getItemProps}
                getResizeProps={getResizeProps}
                openPlanModal={this.openPlanInfoModal}
                projectsList={projectsList}
                lang={lang}
              />
            )}
            groupRenderer={({ group }) => (
              <ResourcePlanningGroupItem
                positionDetails={positionDetails}
                group={group}
                openUserModal={openUserModal}
              />
            )}
          >
            <TimelineHeaders
              className="sticky"
              style={{
                height: "46px",
              }}
            >
              <SidebarHeader>
                {({ getRootProps }) => (
                  <div className="left-headers" {...getRootProps()}>
                    {/* <span className="left-headers-title">{v_all_members}</span> */}
                    {/* <div style={{ display: "inline-block" }}> */}
                    <CustomTippy
                      content={
                        this.state.positionDetails ? v_expand : v_collapse
                      }
                    >
                      <span
                        className="hide-names-btn"
                        onClick={() => this.toggleDetails()}
                      >
                        <ArrowIconLeft
                          className={classNames(
                            this.state.positionDetails
                              ? "arrow-up"
                              : "arrow-down",
                          )}
                          color="white"
                        />
                        {/* {v_details} */}
                      </span>
                    </CustomTippy>
                    {/* </div> */}
                  </div>
                )}
              </SidebarHeader>
              <DateHeader
                unit={unit}
                intervalRenderer={({ getIntervalProps, intervalContext }) => (
                  <ResourcePlanningIntervalItem
                    getIntervalProps={getIntervalProps}
                    intervalContext={intervalContext}
                    unit={unit}
                    showBy={showBy}
                    lang={lang}
                    timeFormat={timeFormat}
                  />
                )}
              />
            </TimelineHeaders>
            <CustomMarker date={dateForMarker}>
              {({ styles }) => {
                const customStyles = {
                  ...styles,
                  width: "100vw",
                  zIndex: 81,
                  opacity: "0.1",
                };

                return (
                  <div style={customStyles} className="rp-custom-marker" />
                );
              }}
            </CustomMarker>
          </Timeline>
          {isOpenPlanModal && (
            <ResourcePlanningPlanModal
              open={true}
              onModalClose={this.closePlanModal}
              time={time}
              clientId={groupId}
              themes={themes}
            />
          )}

          {this.state.draggedItem && (
            <InfoLabel
              item={this.state.draggedItem.item}
              group={this.state.draggedItem.group}
              time={this.state.draggedItem.time}
              lang={lang}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  unit: store.resourcePlanningReducer.unit,
  visibleTimeStart: store.resourcePlanningReducer.visibleTimeStart,
  visibleTimeEnd: store.resourcePlanningReducer.visibleTimeEnd,
  showBy: store.resourcePlanningReducer.showBy,
  vocabulary: store.languageReducer.vocabulary,
  defaultTimeStart: store.resourcePlanningReducer.defaultTimeStart,
  defaultTimeEnd: store.resourcePlanningReducer.defaultTimeEnd,
  timeFormat: store.userReducer.timeFormat,
  items: store.resourcePlanningReducer.items,
  groups: store.resourcePlanningReducer.groups,
  filterValues: store.resourcePlanningReducer.filterValues,
  timeRange: store.resourcePlanningReducer.timeRange,
  projectsList: store.projectReducer.projectsList,
  planModalStatus: store.resourcePlanningReducer.planModalStatus,
  planModalError: store.resourcePlanningReducer.error,
  isMobile: store.responsiveReducer.isMobile,
});

const mapDispatchToProps = {
  updateTimeline,
  editPlanAction,
  getPlansByTeamAction,
  updateShowBy,
  showNotificationAction,
  changeThemesAction,
  getWorkload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcePlanningLibrary);
