import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { getTimeDurationByGivenTimestamp } from "../../../../services/timeService";

import _ from "lodash";
import _fp from "lodash/fp";
import { IUserProjectsPeople } from "../../../../types/reports";

interface IProps {
  usersByTeams: IUserProjectsPeople[];
  durationTimeFormat: string;
}

const ReportsTeamsList = ({
  usersByTeams,
  durationTimeFormat,
}: IProps): ReactElement => {
  const sortedUsersByTeams = _fp.pipe(
    _fp.map((usersByTeams: IUserProjectsPeople) => ({
      ...usersByTeams,
      projects: _.orderBy(usersByTeams.projects, "duration", ["desc"]),
    })),
    _fp.orderBy("total", ["desc"]),
  )(usersByTeams as IUserProjectsPeople[]) as IUserProjectsPeople[];

  return (
    <div className="projects_data">
      {sortedUsersByTeams.map((team, index) => (
        <div className="projects_user" key={team.team + index}>
          <div className="projects_user_data team_user_data">
            <div className="user-name">{team.team}</div>
            <div className="project-name" />
            <div className="time">
              {getTimeDurationByGivenTimestamp(team.total, durationTimeFormat)}
            </div>
          </div>
          {team.projects.map((project, index) => (
            <div key={project.name + index}>
              <div className="projects_user_data">
                <div className="user-name" />
                <div className="project-name">{project.name}</div>
                <div className="time">
                  {getTimeDurationByGivenTimestamp(
                    project.duration,
                    durationTimeFormat,
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
  currentTeam: store.teamReducer.currentTeam,
  durationTimeFormat: store.userReducer.durationTimeFormat,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTeamsList);
