import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";

import ResourcePlanningDateNavigation from "../ResourcePlanningDateNavigation";
import ResourcePlanningShowBy from "../ResourcePlanningShowBy";
import ResourcePlanningPlanModal from "../ResourcePlanningPlanModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CrossIcon } from "../../Atoms/SvgIcons";
import {
  updateFilterValues,
  postBenchListAction,
} from "../../../redux/actions/ResourcePlanningActions";
// import { postBenchList } from "../../../api/resourcePlanning";

import "./style.scss";
import ResourcePlanningFilterProjects from "../ResourcePlanningFilters/ResourcePlanningFilterProjects";
import ResourcePlanningFilterMembers from "../ResourcePlanningFilters/ResourcePlanningFilterMembers";
import ResourcePlanningFilterTags from "../ResourcePlanningFilters/ResourcePlanningFilterTags";

class ResourcePlanningTimelineHeader extends Component {
  state = {
    isOpenPlanModal: false,
    isReadonlyPlanModal: false,
    isEditPlanModal: false,
    currentPlanInfo: null,
    isBenchActive: false,
    filteredBenchList: [],
    newBenchList: [],
  };

  updateBenchFilter = async (ids) => {
    const key = "usersId";

    const selectedValues = this.props.filterValues?.[key] || [];
    const body = {};

    const updatedValues = selectedValues.includes(ids[0])
      ? selectedValues.filter((i) => !ids.includes(i))
      : [...selectedValues, ...ids];

    body[key] = updatedValues;
    this.props.updateFilterValues(body);
  };

  filterUsersWithNoPlans = () => {
    if (this.state.isBenchActive) {
      this.props.filterUsersWithNoPlans();
    }
  };

  filterForBenchList = (data) => {
    const itemList = data.map((item) => item.id);

    this.updateBenchFilter(itemList);
  };

  handlBench = async (startDate, endDate) => {
    if (!this.state.isBenchActive) {
      await this.props.postBenchListAction({ startDate, endDate });

      // bench не активен ели benchList пустой;
      if (this.props.benchList.length === 0) {
        this.setState((prevState) => ({
          isBenchActive: !prevState.isBenchActive,
        }));
      }
      if (this.props.benchList.length !== 0) {
        await this.filterForBenchList(this.props.benchList);
      }
    } else {
      // Очмсика списка bench
      this.props.updateFilterValues(null);
    }

    this.setState((prevState) => ({
      isBenchActive: !prevState.isBenchActive,
    }));
  };

  handlBenchReset = () => {
    this.setState({
      isBenchActive: false,
    });
  };

  openPlanModal = () => {
    this.setState({
      isOpenPlanModal: true,
    });
  };

  closePlanModal = () => {
    this.setState({
      isOpenPlanModal: false,
      isReadonlyPlanModal: false,
      isEditPlanModal: false,
      currentPlanInfo: null,
    });
  };

  clearFilters = () => {
    this.props.updateFilterValues(null);
    //  bench неактивен
    this.setState({
      isBenchActive: false,
    });
  };

  render() {
    const { vocabulary, filterValues, workload } = this.props;
    const { v_add_plan, v_clear_all, v_bench } = vocabulary;
    const {
      isOpenPlanModal,
      isReadonlyPlanModal,
      isEditPlanModal,
      currentPlanInfo,
      isBenchActive,
    } = this.state;
    const isActiveClearButton =
      filterValues?.projectsId?.length ||
      filterValues?.tagsId?.length ||
      filterValues?.usersId?.length;

    return (
      <div className="timeline-header">
        <div className="timeline-header-top">
          <div className="filters">
            <ResourcePlanningFilterProjects />
            <ResourcePlanningFilterMembers
              handlBenchReset={this.handlBenchReset}
            />
            <ResourcePlanningFilterTags />
            <button
              className={classNames("rp-filter__btn", {
                "rp-filter__btn--bench": isBenchActive === true,
              })}
              onClick={() => {
                this.handlBench(
                  moment(this.props.visibleTimeStart).toISOString(),
                  moment(this.props.visibleTimeEnd).toISOString(),
                );
              }}
            >
              {v_bench}
            </button>
          </div>
          <div className="nav-and-show">
            <ResourcePlanningDateNavigation
              isBenchActive={this.state.isBenchActive}
            />
            <ResourcePlanningShowBy />
          </div>
          <button
            className="header-wrapper__child-button"
            onClick={this.openPlanModal}
          >
            <i className="icon-button-plus" />
            {v_add_plan}
          </button>
          {isOpenPlanModal && (
            <ResourcePlanningPlanModal
              open={true}
              isEdit={isEditPlanModal}
              isReadonly={isReadonlyPlanModal}
              onModalClose={this.closePlanModal}
              data={currentPlanInfo}
            />
          )}
        </div>
        <div className="timeline-header-bottom">
          {!!isActiveClearButton && (
            <button
              className="rp-filter__clear-all-filter-btn"
              onClick={this.clearFilters}
            >
              <span>{v_clear_all}</span>
              <CrossIcon
                className="rp-filter__clear-all-filter-btn__cross-icon"
                color="#F04747"
                onClick={this.isBenchActive}
              />
            </button>
          )}
          <div className="timeline-header-bottom__workload">
            <span>{"Load total time:"}</span>
            {workload.usersWorkLoad}
            {"/"}
            {workload.totalWorkLoad}
            {"h"}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateFilterValues,
  postBenchListAction,
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  filterValues: state.resourcePlanningReducer.filterValues,
  workload: state.resourcePlanningReducer.workload,
  benchList: state.resourcePlanningReducer.benchList,
  showBy: state.resourcePlanningReducer.showBy,
  visibleTimeStart: state.resourcePlanningReducer.visibleTimeStart,
  visibleTimeEnd: state.resourcePlanningReducer.visibleTimeEnd,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcePlanningTimelineHeader),
);
