import React, { Component } from "react";
import { connect } from "react-redux";

// Services
import { apiCall } from "../../../services/apiService";
import { fetchReportsFilters } from "../../../api/reports";
import { getCurrentTeamDetailedData } from "../../../api/team";

// Components
import ReportsFilterDropdown from "../Reports/ReportsFilterDropdown";

// Actions
import { getClientsAction } from "../../../redux/actions/ClientsActions";
import reportsPageAction from "../../../redux/actions/ReportsPageAction";

// Config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";

class ReportsSearchBar extends Component {
  state = {};

  applySearch(init = false) {
    this.props.applySearch(init);
  }

  toggleProject(project) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const projects = JSON.parse(
      JSON.stringify(this.props.filter.selectedProjects),
    );
    let exists = false;
    for (let i = 0; i < projects.length; i++) {
      const currentProject = projects[i];

      if (currentProject.name === project.name) {
        exists = true;
        projects.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      projects.push(project);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsers: this.filterUsersByProject(projects),
      selectedProjects: projects,
      selectedClients: this.filterClientsByProject(projects),
    });

    this.props.reportsPageAction("SET_SELECTED_USERS", {
      data: this.filterUsersByProject(projects).map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
      data: projects.map((p) => p.name),
    });
  }

  toggleProjectCrossTeam(project) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const projects = JSON.parse(
      JSON.stringify(this.props.filter.selectedProjectsCross),
    );
    let exists = false;

    for (let i = 0; i < projects.length; i++) {
      const currentProject = projects[i];

      if (currentProject.id === project.id) {
        exists = true;
        projects.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      projects.push(project);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsersCross: this.filterUsersByProjectCross(projects),
      selectedProjectsCross: projects,
      selectedClientsCross: this.filterClientsByProject(projects),
      selectedTeams: this.filterTeamsByProject(projects),
    });

    this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
      data: this.filterUsersByProjectCross(projects).map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
      data: projects.map((p) => p.id),
    });
    this.props.reportsPageAction("SET_SELECTED_TEAM", {
      data: this.filterTeamsByProject(projects).map((u) => u.id),
    });
  }

  toggleUser(user) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const users = JSON.parse(JSON.stringify(this.props.filter.selectedUsers));
    let exists = false;

    for (let i = 0; i < users.length; i++) {
      const currentUser = users[i];

      if (currentUser.email === user.email) {
        exists = true;
        users.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      users.push(user);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsers: users,
      selectedProjects: this.filterProjectsByUser(users),
      selectedClients: this.filterClientsByUser(users),
    });

    this.props.reportsPageAction("SET_SELECTED_USERS", {
      data: users.map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
      data: this.filterProjectsByUser(users).map((p) => p.name),
    });
  }

  toggleUserCrossTeam(user) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const users = JSON.parse(
      JSON.stringify(this.props.filter.selectedUsersCross),
    );
    let exists = false;

    for (let i = 0; i < users.length; i++) {
      const currentUser = users[i];

      if (currentUser.email === user.email) {
        exists = true;
        users.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      users.push(user);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsersCross: users,
      selectedProjectsCross: this.filterProjectsByUserCross(users),
      selectedClientsCross: this.filterClientsByUserCross(users),
      selectedTeams: this.filterTeamsByUser(users),
    });

    this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
      data: users.map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
      data: this.filterProjectsByUserCross(users).map((p) => p.id),
    });
    this.props.reportsPageAction("SET_SELECTED_TEAM", {
      data: this.filterTeamsByUser(users).map((u) => u.id),
    });
  }

  toggleClient(client) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const clients = JSON.parse(
      JSON.stringify(this.props.filter.selectedClients),
    );
    let exists = false;
    for (let i = 0; i < clients.length; i++) {
      const currentClient = clients[i];

      if (currentClient.company_name === client.company_name) {
        exists = true;
        clients.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      clients.push(client);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsers: this.filterUsersByClient(clients),
      selectedProjects: this.filterProjectsByClient(clients),
      selectedClients: clients,
    });

    this.props.reportsPageAction("SET_SELECTED_USERS", {
      data: this.filterUsersByClient(clients).map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
      data: this.filterProjectsByClient(clients).map((p) => p.name),
    });
  }

  toggleClientCrossTeam(client) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const clients = JSON.parse(
      JSON.stringify(this.props.filter.selectedClientsCross),
    );
    let exists = false;

    for (let i = 0; i < clients.length; i++) {
      const currentClient = clients[i];

      if (currentClient.company_name === client.company_name) {
        exists = true;
        clients.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      clients.push(client);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsersCross: this.filterUsersByClientCross(clients),
      selectedProjectsCross: this.filterProjectsByClient(clients),
      selectedClientsCross: clients,
      selectedTeams: this.filterTeamsByClient(clients),
    });

    this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
      data: this.filterUsersByClientCross(clients).map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
      data: this.filterProjectsByClient(clients).map((p) => p.id),
    });
    this.props.reportsPageAction("SET_SELECTED_TEAM", {
      data: this.filterTeamsByClient(clients).map((u) => u.id),
    });
  }

  toggleTeam(team) {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    const teams = JSON.parse(JSON.stringify(this.props.filter.selectedTeams));
    let exists = false;

    for (let i = 0; i < teams.length; i++) {
      const currentTeam = teams[i];

      if (currentTeam.id === team.id) {
        exists = true;
        teams.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      teams.push(team);
    }

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedTeams: teams,
      selectedProjectsCross: this.filterProjectsByTeam(teams),
      selectedClientsCross: this.filterClientsByTeam(teams),
      selectedUsersCross: this.filterUserByTeam(teams),
    });

    this.props.reportsPageAction("SET_SELECTED_TEAM", {
      data: teams.map((u) => u.id),
    });
    this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
      data: this.filterUserByTeam(teams).map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
      data: this.filterProjectsByTeam(teams).map((p) => p.id),
    });
  }

  selectAllProjects() {
    if (!this.props.isCrossTeam) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: this.filterUsersByProject(this.props.filter.allProjects),
        selectedProjects: this.props.filter.allProjects,
        selectedClients: this.filterClientsByProject(this.props.filter.allProjects),
      });

      this.props.reportsPageAction("SET_SELECTED_USERS", {
        data: this.filterUsersByProject(this.props.filter.allProjects).map((u) => u.email),
      });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
        data: this.props.filter.allProjects.map((p) => p.name),
      });
    } else {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsersCross: this.filterUsersByProjectCross(this.props.filter.allProjectsCross),
        selectedProjectsCross: this.props.filter.allProjectsCross,
        selectedClientsCross: this.filterClientsByProject(this.props.filter.allProjectsCross),
        selectedTeams: this.filterTeamsByProject(this.props.filter.allProjectsCross),
      });

      this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
        data: this.filterUsersByProjectCross(this.props.filter.allProjectsCross).map((u) => u.email),
      });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
        data: this.props.filter.allProjectsCross.map((p) => p.id),
      });
      this.props.reportsPageAction("SET_SELECTED_TEAM", {
        data: this.filterTeamsByProject(this.props.filter.allProjectsCross).map((u) => u.id),
      });
    }

    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectAllUsers() {
    if (!this.props.isCrossTeam) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: this.props.filter.allUsers,
        selectedProjects: this.filterProjectsByUser(this.props.filter.allUsers),
        selectedClients: this.filterClientsByUser(this.props.filter.allUsers),
      });

      this.props.reportsPageAction("SET_SELECTED_USERS", {
        data: this.props.filter.allUsers.map((u) => u.email),
      });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
        data: this.filterProjectsByUser(this.props.filter.allUsers).map((p) => p.name),
      });
    } else {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsersCross: this.props.filter.allUsersCross,
        selectedProjectsCross: this.filterProjectsByUserCross(this.props.filter.allUsersCross),
        selectedClientsCross: this.filterClientsByUserCross(this.props.filter.allUsersCross),
        selectedTeams: this.filterTeamsByUser(this.props.filter.allUsersCross),
      });

      this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
        data: this.props.filter.allUsersCross.map((u) => u.email),
      });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
        data: this.filterProjectsByUserCross(this.props.filter.allUsersCross).map((p) => p.id),
      });
      this.props.reportsPageAction("SET_SELECTED_TEAM", {
        data: this.filterTeamsByUser(this.props.filter.allUsersCross).map((u) => u.id),
      });
    }

    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectAllClients() {
    if (!this.props.isCrossTeam) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: this.filterUsersByClient(this.props.filter.allClients),
        selectedProjects: this.filterProjectsByClient(this.props.filter.allClients),
        selectedClients: this.props.filter.allClients,
      });

      this.props.reportsPageAction("SET_SELECTED_USERS", {
        data: this.filterUsersByClient(this.props.filter.allClients).map((u) => u.email),
      });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
        data: this.filterProjectsByClient(this.props.filter.allClients).map((p) => p.name),
      });
    } else {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsersCross: this.filterUsersByClientCross(this.props.filter.allClientsCross),
        selectedProjectsCross: this.filterProjectsByClient(this.props.filter.allClientsCross),
        selectedClientsCross: this.props.filter.allClientsCross,
        selectedTeams: this.filterTeamsByClient(this.props.filter.allClientsCross),
      });

      this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
        data: this.filterUsersByClientCross(this.props.filter.allClientsCross).map((u) => u.email),
      });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
        data: this.filterProjectsByClient(this.props.filter.allClientsCross).map((p) => p.id),
      });
      this.props.reportsPageAction("SET_SELECTED_TEAM", {
        data: this.filterTeamsByClient(this.props.filter.allClientsCross).map((u) => u.id),
      });
    }
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectAllTeams() {
    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedTeams: this.props.filter.allTeams,
      selectedProjectsCross: this.filterProjectsByTeam(this.props.filter.allTeams),
      selectedClientsCross: this.filterClientsByTeam(this.props.filter.allTeams),
      selectedUsersCross: this.filterUserByTeam(this.props.filter.allTeams),
    });

    this.props.reportsPageAction("SET_SELECTED_TEAM", {
      data: this.props.filter.allTeams.map((u) => u.id),
    });
    this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
      data: this.filterUserByTeam(this.props.filter.allTeams).map((u) => u.email),
    });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
      data: this.filterProjectsByTeam(this.props.filter.allTeams).map((p) => p.id),
    });

    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectNoneUsers() {
    if (!this.props.isCrossTeam) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: [],
        selectedProjects: [],
        selectedClients: [],
      });
      this.props.reportsPageAction("SET_SELECTED_USERS", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS", { data: [] });
    } else {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsersCross: [],
        selectedProjectsCross: [],
        selectedClientsCross: [],
        selectedTeams: [],
      });
      this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_TEAM", { data: [] });
    }
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectNoneProjects() {
    if (!this.props.isCrossTeam) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: [],
        selectedProjects: [],
        selectedClients: [],
      });

      this.props.reportsPageAction("SET_SELECTED_USERS", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS", { data: [] });
    } else {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsersCross: [],
        selectedProjectsCross: [],
        selectedClientsCross: [],
        selectedTeams: [],
      });

      this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_TEAM", { data: [] });
    }

    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectNoneClients() {
    if (!this.props.isCrossTeam) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: [],
        selectedProjects: [],
        selectedClients: [],
      });
      this.props.reportsPageAction("SET_SELECTED_USERS", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS", { data: [] });
    } else {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsersCross: [],
        selectedProjectsCross: [],
        selectedClientsCross: [],
        selectedTeams: [],
      });
      this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", { data: [] });
      this.props.reportsPageAction("SET_SELECTED_TEAM", { data: [] });
    }
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
  }

  selectNoneTeams() {
    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      selectedUsersCross: [],
      selectedProjectsCross: [],
      selectedClientsCross: [],
      selectedTeams: [],
    });
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
    this.props.reportsPageAction("SET_SELECTED_TEAM", { data: [] });
    this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", { data: [] });
    this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", { data: [] });
  }

  filterUsersByProject = (projects) => {
    const variableUsers = this.props.filter.allUsers;

    function filterById(user_project, projects) {
      let isUserInProj = false;

      projects.forEach((project) => {
        user_project.forEach((proj) => {
          if (project.id === proj.project_v2.id) {
            isUserInProj = true;
            return;
          }
        });
      });

      return isUserInProj;
    }

    const filteredUsers = variableUsers.filter((user) =>
      filterById(user.user_project, projects),
    );
    return filteredUsers;
  };

  filterUsersByProjectCross = (projects) => {
    const variableUsers = this.props.filter.allUsersCross;

    function filterById(teamIds, projects) {
      let isUserInProj = false;

      projects.forEach((project) => {
        if (project.teamId === teamIds.find((id) => project.teamId === id)) {
          isUserInProj = true;
          return;
        }
      });

      return isUserInProj;
    }

    const filteredUsers = variableUsers.filter((user) =>
      filterById(user.teamIds, projects),
    );
    return filteredUsers;
  };

  filterProjectsByUser = (users) => {
    const variableProjects = this.props.filter.allProjects;

    function filterById(id, users) {
      let isProjWithUser = false;

      users.forEach((user) => {
        user.user_project.forEach((project) => {
          if (project.project_v2.id === id) {
            isProjWithUser = true;
            return;
          }
        });
      });

      return isProjWithUser;
    }

    const filteredProjects = variableProjects.filter((project) =>
      filterById(project.id, users),
    );
    return filteredProjects;
  };

  filterProjectsByUserCross = (users) => {
    const variableProjects = this.props.filter.allProjectsCross;
    function filterById(teamId, users) {
      let isProjWithUser = false;

      users.forEach((user) => {
        if (user.teamIds.find((id) => teamId === id)) {
          isProjWithUser = true;
          return;
        }
      });

      return isProjWithUser;
    }

    const filteredProjects = variableProjects.filter((project) =>
      filterById(project.teamId, users),
    );
    return filteredProjects;
  };

  filterUsersByClient = (clients) => {
    const variableUsers = this.props.filter.allUsers;

    function filterById(user_project, clients) {
      let isUserWithClient = false;
      clients.forEach((client) => {
        user_project.forEach((proj) => {
          if (client.id === proj.project_v2.client_id) {
            isUserWithClient = true;
            return;
          }
        });
      });

      return isUserWithClient;
    }

    const filteredUsers = variableUsers.filter((user) =>
      filterById(user.user_project, clients),
    );
    return filteredUsers;
  };

  filterUsersByClientCross = (clients) => {
    const variableUsers = this.props.filter.allUsersCross;

    function filterById(teamIds, clients) {
      let isUserWithClient = false;

      clients.forEach((client) => {
        if (client.team.id === teamIds.find((id) => client.team.id === id)) {
          isUserWithClient = true;
          return;
        }
      });

      return isUserWithClient;
    }

    const filteredUsers = variableUsers.filter((user) =>
      filterById(user.teamIds, clients),
    );
    return filteredUsers;
  };

  filterClientsByUser = (users) => {
    const variableClients = this.props.filter.allClients;

    function filterById(id, users) {
      let isClientWithUser = false;

      users.forEach((user) => {
        user.user_project.forEach((proj) => {
          if (proj.project_v2.client_id === id) {
            isClientWithUser = true;
            return;
          }
        });
      });

      return isClientWithUser;
    }

    const filteredClients = variableClients.filter((client) =>
      filterById(client.id, users),
    );
    return filteredClients;
  };

  filterClientsByUserCross = (users) => {
    const variableClients = this.props.filter.allClientsCross;

    function filterById(teamId, users) {
      let isClientWithUser = false;

      users.forEach((user) => {
        if (user.teamIds.find((id) => teamId === id)) {
          isClientWithUser = true;
          return;
        }
      });

      return isClientWithUser;
    }

    const filteredClients = variableClients.filter((client) =>
      filterById(client.team.id, users),
    );
    return filteredClients;
  };

  filterClientsByProject = (projects) => {
    const variableClients = !this.props.isCrossTeam
      ? this.props.filter.allClients
      : this.props.filter.allClientsCross;
    const newArr = [];
    projects.forEach((project) => {
      variableClients.forEach((client) => {
        if (project.client && client.id === project.client.id) {
          if (!newArr.find((item) => item.id === client.id)) {
            newArr.push(client);
          }
        }
      });
    });
    return newArr;
  };

  filterProjectsByClient = (clients) => {
    const variableProjects = !this.props.isCrossTeam
      ? this.props.filter.allProjects
      : this.props.filter.allProjectsCross;
    const projectIds = [];
    const newArr = [];
    clients.forEach((client) => {
      if (client.project_v2.length !== 0) {
        client.project_v2.forEach((project) => {
          projectIds.push(project.id);
        });
      }
    });
    projectIds.forEach((id) => {
      variableProjects.forEach((project) => {
        if (id === project.id) {
          newArr.push(project);
        }
      });
    });
    return newArr;
  };

  filterProjectsByTeam = (teams) => {
    const { allProjectsCross } = this.props.filter;

    function filterById(teamId, teams) {
      let isProjInTeam = false;

      teams.forEach((team) => {
        if (teamId === team.id) {
          isProjInTeam = true;
          return;
        }
      });

      return isProjInTeam;
    }

    const filteredProjects = allProjectsCross.filter((project) =>
      filterById(project.teamId, teams),
    );
    return filteredProjects;
  };

  filterTeamsByProject = (projects) => {
    const { allTeams } = this.props.filter;

    function filterById(teamId, projects) {
      let isTeamHasProject = false;

      projects.forEach((project) => {
        if (teamId === project.teamId) {
          isTeamHasProject = true;
          return;
        }
      });

      return isTeamHasProject;
    }

    const filteredTeams = allTeams.filter((team) =>
      filterById(team.id, projects),
    );
    return filteredTeams;
  };

  filterClientsByTeam = (teams) => {
    const { allClientsCross } = this.props.filter;

    function filterById(teamId, teams) {
      let isClientInTeam = false;

      teams.forEach((team) => {
        if (teamId === team.id) {
          isClientInTeam = true;
          return;
        }
      });

      return isClientInTeam;
    }

    const filteredClients = allClientsCross.filter((client) =>
      filterById(client.team.id, teams),
    );
    return filteredClients;
  };

  filterTeamsByClient = (clients) => {
    const { allTeams } = this.props.filter;

    function filterById(teamId, clients) {
      let isTeamHasClient = false;

      clients.forEach((client) => {
        if (teamId === client.team.id) {
          isTeamHasClient = true;
          return;
        }
      });

      return isTeamHasClient;
    }

    const filteredTeams = allTeams.filter((team) =>
      filterById(team.id, clients),
    );
    return filteredTeams;
  };

  filterTeamsByUser = (users) => {
    const { allTeams } = this.props.filter;

    function filterById(teamUsers, users) {
      let isTeamHasUsers = false;

      teamUsers.forEach((teamUser) => {
        if (users.find((user) => user.id === teamUser.id)) {
          isTeamHasUsers = true;
          return;
        }
      });

      return isTeamHasUsers;
    }

    const filteredTeams = allTeams.filter((team) =>
      filterById(team.users, users),
    );
    return filteredTeams;
  };

  filterUserByTeam = (items) => {
    const { allUsersCross } = this.props.filter;
    const newArr = [];
    const projectIds = [];
    const uniqueValue = [];

    function itemCheck(item) {
      if (uniqueValue.indexOf(item.email) === -1) {
        uniqueValue.push(item.email);
        return true;
      }
      return false;
    }

    items.forEach((client) => {
      if (client.users.length !== 0) {
        client.users.forEach((project) => {
          projectIds.push(project.id);
        });
      }
    });
    projectIds.forEach((id) => {
      allUsersCross.forEach((project) => {
        if (id === project.id) {
          newArr.push(project);
        }
      });
    });
    return newArr.filter((item) => itemCheck(item));
  };

  checkedDisable = () => {
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: false });
  };

  componentDidMount() {
    if (!this.props.isInitFilters) {
      this.props.reportsPageAction("SET_SELECTED_USERS", {
        data: [this.props.user?.email],
      });

      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        selectedUsers: [this.props.user?.email],
      });

      this.props.reportsPageAction("SET_INIT_FILTERS", true);
    }

    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: false });

    this.props.reportsPageAction("UPDATE_FILTER", {
      ...this.props.filter,
      allClients: this.props.inputClientData || [],
    });

    if (!this.props.isCrossTeam) {
      this.initReportsFilters();
      this.initCurrentTeam();
      this.initProjectList();
      this.applySearch();
    }
  }

  initReportsFilters() {
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;

    fetchReportsFilters(startDate, endDate).then(
      ({ data }) => {
        const teamUserCrossTeam = data.users;

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          allUsersCross: teamUserCrossTeam,
        });

        const selectedUsers = this.props.isCrossTeam
          ? this.props.selectedUsersCross
          : this.props.selectedUsers;

        for (let i = 0; i < selectedUsers.length; i++) {
          const inputUser = selectedUsers[i];

          for (let j = 0; j < teamUserCrossTeam.length; j++) {
            const currentUser = teamUserCrossTeam[j];

            if (JSON.stringify(currentUser).indexOf(inputUser) > -1) {
              this.toggleUserCrossTeam(currentUser);
              this.props.reportsPageAction("SET_IS_DISABLED_FILTER", {
                data: false,
              });
              break;
            }
          }
        }

        const projectsCrossTeam = data.projects;

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          allProjectsCross: projectsCrossTeam,
        });

        const selectedProjects = this.props.selectedProjectsCross;

        for (let i = 0; i < selectedProjects.length; i++) {
          const inputProject = selectedProjects[i];

          for (let j = 0; j < projectsCrossTeam.length; j++) {
            const currentProject = projectsCrossTeam[j];

            if (currentProject.name === inputProject) {
              this.toggleProjectCrossTeam(currentProject);
              break;
            }
          }
        }

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          allClientsCross: data.clients || [],
        });

        const teamCrossTeam = data.teams;

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          allTeams: teamCrossTeam,
        });

        const inputTeamData = this.props.filter.allTeams;

        for (let i = 0; i < inputTeamData.length; i++) {
          const inputUser = inputTeamData[i];

          for (let j = 0; j < teamCrossTeam.length; j++) {
            const currentUser = teamCrossTeam[j];

            if (JSON.stringify(currentUser).indexOf(inputUser) > -1) {
              this.toggleTeam(currentUser);
              this.props.reportsPageAction("SET_IS_DISABLED_FILTER", {
                data: false,
              });
              break;
            }
          }
        }
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  initProjectList() {
    apiCall(`${AppConfig.apiURL}project/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      ({ data: projects }) => {
        const selectedProjects = this.props.selectedProjects;
        const projectsForUpdate = [];

        for (let i = 0; i < selectedProjects.length; i++) {
          const project = projects.find(
            (project) => project.name === selectedProjects[i],
          );

          if (project) {
            projectsForUpdate.push(project);
          }
        }

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          selectedProjects: projectsForUpdate,
          allProjects: projects,
          selectedClients: this.filterClientsByProject(projectsForUpdate),
        });

        this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
          data: projectsForUpdate.map((p) => p.name),
        });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
    this.props.getClientsAction();
  }

  initCurrentTeam() {
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;

    getCurrentTeamDetailedData(startDate, endDate).then(
      ({ data }) => {
        const teamUsers = data.user_team;
        const teamId = data.id;
        const users = teamUsers
          .map((teamUser) => ({
            ...teamUser.user,
            is_active: teamUser.is_active,
            teamIds: [teamId],
          }))

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          allUsers: users,
        });

        const selectedUsers = this.props.selectedUsers;
        const usersForUpdate = [];

        for (let i = 0; i < selectedUsers.length; i++) {
          const user = users.find((user) => user.email === selectedUsers[i]);

          if (user) {
            usersForUpdate.push(user);
          }
        }

        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          selectedUsers: usersForUpdate,
        });

        this.props.reportsPageAction("SET_SELECTED_USERS", {
          data: usersForUpdate.map((u) => u.email),
        });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCrossTeam !== this.props.isCrossTeam) {
      if (this.props.isCrossTeam) {
        this.props.reportsPageAction("UPDATE_FILTER", {
          ...this.props.filter,
          selectedUsersCross: this.props.filter.allUsersCross,
          selectedProjectsCross: this.props.filter.allProjectsCross,
          selectedClientsCross: this.filterClientsByProject(
            this.props.filter.allProjectsCross,
          ),
          selectedTeams: this.props.filter.allTeams,
        });

        this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
          data: this.props.filter.allUsersCross.map((u) => u.email),
        });

        this.props.reportsPageAction("SET_SELECTED_PROJECTS_CROSS_TEAM", {
          data: this.props.filter.allProjectsCross.map((p) => p.id),
        });

        this.props.reportsPageAction("SET_SELECTED_TEAM", {
          data: this.props.filter.allTeams.map((u) => u.id),
        });
      }

      setTimeout(() => {
        this.applySearch();
      }, 10);
    }

    if (prevProps.inputClientData !== this.props.inputClientData) {
      this.props.reportsPageAction("UPDATE_FILTER", {
        ...this.props.filter,
        allClients: this.props.inputClientData,
      });
    }
  }

  render() {
    const { vocabulary } = this.props;
    const { v_user, v_project, v_apply, v_client, v_team } = vocabulary;

    return (
      <div className="wrapper_reports_search_bar">
        <ReportsFilterDropdown
          isCrossTeam={this.props.isCrossTeam}
          label={v_user}
          onSelectAll={() => this.selectAllUsers()}
          onSelectNone={() => this.selectNoneUsers()}
          options={
            this.props.isCrossTeam
              ? this.props.filter.allUsersCross.map((user) => ({
                  name: user.name || user.username || user.email,
                  value: user.email,
                  data: {
                    ...user,
                  },
                }))
              : this.props.filter.allUsers.map((user) => ({
                  name: user.name || user.username || user.email,
                  value: user.email,
                  data: {
                    ...user,
                  },
                }))
          }
          selectedOptions={
            this.props.isCrossTeam
              ? this.props.filter.selectedUsersCross.map((user) => ({
                  name: user.name || user.username || user.email,
                  value: user.email,
                  data: {
                    ...user,
                  },
                }))
              : this.props.filter.selectedUsers.map((user) => ({
                  name: user.name || user.username || user.email,
                  value: user.email,
                  data: {
                    ...user,
                  },
                }))
          }
          onToggleOption={(option) =>
            this.props.isCrossTeam
              ? this.toggleUserCrossTeam({ ...option.data })
              : this.toggleUser({
                  ...option.data,
                })
          }
        />

        <ReportsFilterDropdown
          isCrossTeam={this.props.isCrossTeam}
          label={v_project}
          onSelectAll={() => this.selectAllProjects()}
          onSelectNone={() => this.selectNoneProjects()}
          options={
            this.props.isCrossTeam
              ? this.props.filter.allProjectsCross.map((project) => ({
                  name: project.projectName,
                  value: project.id,
                  data: { ...project },
                }))
              : this.props.filter.allProjects.map((project) => ({
                  name: project.name,
                  value: project.id,
                  data: { ...project },
                }))
          }
          selectedOptions={
            this.props.isCrossTeam
              ? this.props.filter.selectedProjectsCross.map((project) => ({
                  name: project.projectName,
                  value: project.id,
                  data: { ...project },
                }))
              : this.props.filter.selectedProjects.map((project) => ({
                  name: project.name,
                  value: project.id,
                  data: { ...project },
                }))
          }
          onToggleOption={(option) =>
            this.props.isCrossTeam
              ? this.toggleProjectCrossTeam({ ...option.data })
              : this.toggleProject({ ...option.data })
          }
        />

        <ReportsFilterDropdown
          isCrossTeam={this.props.isCrossTeam}
          label={v_client}
          onSelectAll={() => this.selectAllClients()}
          onSelectNone={() => this.selectNoneClients()}
          options={
            this.props.isCrossTeam
              ? this.props.filter.allClientsCross.map((client) => ({
                  name: client.company_name,
                  value: client.id,
                  data: { ...client },
                }))
              : this.props.filter.allClients.map((client) => ({
                  name: client.company_name,
                  value: client.id,
                  data: { ...client },
                }))
          }
          selectedOptions={
            this.props.isCrossTeam
              ? this.props.filter.selectedClientsCross.map((client) => ({
                  name: client.company_name,
                  value: client.id,
                  data: { ...client },
                }))
              : this.props.filter.selectedClients.map((client) => ({
                  name: client.company_name,
                  value: client.id,
                  data: { ...client },
                }))
          }
          onToggleOption={(option) =>
            this.props.isCrossTeam
              ? this.toggleClientCrossTeam({ ...option.data })
              : this.toggleClient({
                  ...option.data,
                })
          }
        />

        {this.props.isCrossTeam && (
          <ReportsFilterDropdown
            isCrossTeam={this.props.isCrossTeam}
            label={v_team}
            onSelectAll={() => this.selectAllTeams()}
            onSelectNone={() => this.selectNoneTeams()}
            options={this.props.filter.allTeams.map((team) => ({
              name: team.name,
              value: team.id,
              data: { ...team },
            }))}
            selectedOptions={this.props.filter.selectedTeams.map((team) => ({
              name: team.name,
              value: team.id,
              data: { ...team },
            }))}
            onToggleOption={(option) => this.toggleTeam({ ...option.data })}
          />
        )}

        <div className="reports_search_bar_button_container">
          <button
            className="reports_search_bar_button"
            disabled={!this.props.isDisabledFilter}
            onClick={() => {
              this.applySearch();
              this.checkedDisable();
            }}
          >
            {v_apply}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isCrossTeam: state.reportsPageReducer.isCrossTeam,
  selectedProjects: state.reportsPageReducer.selectedProjects,
  selectedProjectsCross: state.reportsPageReducer.selectedProjectsCross,
  isDisabledFilter: state.reportsPageReducer.isDisabledFilter,
  selectedTeams: state.reportsPageReducer.selectedTeams,
  selectedUsers: state.reportsPageReducer.selectedUsers,
  filter: state.reportsPageReducer.filter,
  user: state.userReducer.user,
  isInitFilters: state.reportsPageReducer.isInitFilters,
  selectedUsersCross: state.reportsPageReducer.selectedUsersCross,
});

const mapDispatchToProps = {
  getClientsAction,
  reportsPageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsSearchBar);
