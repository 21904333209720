import { APP_VERSION } from "../constants/versions";
import moment from "moment";

const localStorageToken = "app_version";
const dropdownDateFormat = "MMMM D, YYYY";
const releaseNotesFormat = "MMM D, YYYY";

export const isSameVersion = () => {
  const versionFromLocalStorage = localStorage.getItem(localStorageToken);
  const currentVersion = APP_VERSION;

  return currentVersion === versionFromLocalStorage;
};

export function setVersionToLocalStorage(version) {
  localStorage.setItem(localStorageToken, version);
}

export function formatDate(date, lang = "en", format) {
  const dateObject = moment(date, "DD.MM.YYYY").toDate();

  return moment(dateObject).locale(lang).format(format);
}

export function formatDropdownDateForRender(date, lang = "en") {
  return formatDate(date, lang, dropdownDateFormat);
}

export function formatReleaseNotesDateForRender(date, lang = "en") {
  return formatDate(date, lang, releaseNotesFormat);
}
