import moment from "moment";
import {
  STATUS_ERROR,
  STATUS_PROCESSING,
  STATUS_SUCCESS,
} from "../../constants/status";
import {
  SET_UNIT,
  UPDATE_TIMELINE,
  UPDATE_CURRENT_DATE,
  SET_VISIBLE_DATE,
  SET_SHOW_BY,
  CREATE_PLAN_REQUEST,
  CREATE_PLAN_REQUEST_SUCCESS,
  CREATE_PLAN_REQUEST_ERROR,
  GET_PLANS_BY_TEAM_REQUEST,
  GET_PLANS_BY_TEAM_REQUEST_SUCCESS,
  GET_PLANS_BY_TEAM_REQUEST_ERROR,
  EDIT_PLAN_REQUEST,
  EDIT_PLAN_REQUEST_SUCCESS,
  EDIT_PLAN_REQUEST_ERROR,
  DELETE_PLAN_REQUEST,
  DELETE_PLAN_REQUEST_SUCCESS,
  DELETE_PLAN_REQUEST_ERROR,
  SET_FILTER_VALUES,
  SET_CALENDAR_DATA,
  SET_TIME_RANGE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_REQUEST_SUCCESS,
  GET_USER_INFO_REQUEST_ERROR,
  GET_PLAN_HISTORY,
  GET_TOTAL_WORKLOAD,
  EDIT_USER_INFO_REQUEST,
  EDIT_USER_INFO_REQUEST_SUCCESS,
  EDIT_USER_INFO_REQUEST_ERROR,
  CHANGE_THEMES,
  POST_BENCH_LIST_REQUEST,
  POST_BENCH_LIST_REQUEST_SUCCESS,
  POST_BENCH_LIST_REQUEST_ERROR,
} from "../actions/ResourcePlanningActions";
import { ca } from "date-fns/locale";

const initialState = {
  unit: "hour",
  defaultTimeStart: moment().startOf("day"),
  defaultTimeEnd: moment().startOf("day").add(1, "day"),
  visibleTimeStart: moment().startOf("day"),
  visibleTimeEnd: moment().startOf("day").add(1, "day"),
  showBy: "day",
  currentDate: moment(),
  error: null,
  filterValues: null,
  groups: [],
  items: [],
  timeRange: {
    start: moment().subtract(1, "month").valueOf(),
    end: moment().add(1, "month").valueOf(),
  },
  userInfo: null,
  userModalStatus: null,
  planModalStatus: null,
  plansStatus: null,
  plans: null,
  planHistory: [],
  workload: { totalWorkLoad: 0, usersWorkLoad: 0 },
  isFetching: false,
  themeState: localStorage.getItem("themes"),
  benchList: [],
};

export function resourcePlanningReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case SET_UNIT:
      return {
        ...state,
        unit: payload,
      };

    case UPDATE_TIMELINE:
      return {
        ...state,
        ...payload,
      };

    case UPDATE_CURRENT_DATE:
      return {
        ...state,
        currentDate: payload,
      };

    case SET_VISIBLE_DATE:
      return {
        ...state,
        visibleTimeStart: payload.visibleTimeStart,
        visibleTimeEnd: payload.visibleTimeEnd,
      };

    case SET_SHOW_BY:
      return {
        ...state,
        showBy: payload,
      };

    case CREATE_PLAN_REQUEST:
      return {
        ...state,
        planModalStatus: STATUS_PROCESSING,
        isFetching: true,
      };

    case CREATE_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        planModalStatus: STATUS_SUCCESS,
        isFetching: false,
      };

    case CREATE_PLAN_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        planModalStatus: STATUS_ERROR,
        isFetching: false,
      };

    case EDIT_PLAN_REQUEST:
      return {
        ...state,
        planModalStatus: STATUS_PROCESSING,
      };

    case EDIT_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        planModalStatus: STATUS_SUCCESS,
      };

    case EDIT_PLAN_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        planModalStatus: STATUS_ERROR,
      };

    case DELETE_PLAN_REQUEST:
      return {
        ...state,
        planModalStatus: STATUS_PROCESSING,
      };

    case DELETE_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        planModalStatus: STATUS_SUCCESS,
      };

    case DELETE_PLAN_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        planModalStatus: STATUS_ERROR,
      };

    case GET_PLANS_BY_TEAM_REQUEST:
      return {
        ...state,
        plansStatus: STATUS_PROCESSING,
      };

    case GET_PLANS_BY_TEAM_REQUEST_SUCCESS:
      return {
        ...state,
        plans: payload,
        plansStatus: STATUS_SUCCESS,
      };

    case GET_PLANS_BY_TEAM_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        plansStatus: STATUS_ERROR,
      };

    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues:
          payload !== null ? { ...state.filterValues, ...payload } : null,
      };

    case SET_CALENDAR_DATA:
      return {
        ...state,
        groups: payload.groups,
        items: payload.items,
      };

    case SET_TIME_RANGE:
      return {
        ...state,
        timeRange: payload,
      };

    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        userModalStatus: STATUS_PROCESSING,
        error: null,
        userInfo: null,
      };

    case GET_USER_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        userModalStatus: STATUS_SUCCESS,
        userInfo: payload,
      };

    case GET_USER_INFO_REQUEST_ERROR:
      return {
        ...state,
        userModalStatus: STATUS_ERROR,
        error: payload,
      };

    case POST_BENCH_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        benchList: payload,
      };

    case GET_PLAN_HISTORY:
      return {
        ...state,
        planHistory: payload,
      };

    case GET_TOTAL_WORKLOAD:
      return {
        ...state,
        workload: payload,
      };

    case EDIT_USER_INFO_REQUEST:
      return {
        ...state,
        userModalStatus: STATUS_PROCESSING,
        error: null,
      };

    case EDIT_USER_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        userModalStatus: STATUS_SUCCESS,
      };

    case EDIT_USER_INFO_REQUEST_ERROR:
      return {
        ...state,
        userModalStatus: STATUS_ERROR,
        error: payload,
      };
    case EDIT_USER_INFO_REQUEST_ERROR:
      return {
        ...state,
        userModalStatus: STATUS_ERROR,
        error: payload,
      };
    case CHANGE_THEMES:
      return {
        ...state,
        themeState: payload,
      };

    default:
      return state;
  }
}
