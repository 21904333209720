import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "lodash";

import classNames from "classnames";

import { Loading } from "../Loading";

// Actions
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

// Styles
import "./style.scss";
import {
  stopTimerSocket,
  startTimerSocket,
  updateTimerSocket,
} from "../../../api/configSocket";
import ProjectsListPopup from "../ProjectsListPopup";
import { getProjectsListActions } from "../../../redux/actions/ProjectsActions";
import {
  syncAllTasks,
  billableStatusAction,
  getTimeEntriesListAction,
} from "../../../redux/actions/MainPageAction";

const PlayIcon = (props) => {
  const { className, onClick } = props;

  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={`${className}-circle`}
        cx="18"
        cy="18"
        r="18"
        fill="#27AE60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3513 16.4372L14.9265 10.1482C14.6478 9.97256 14.3019 10.0018 14.0406 10.0018C12.9954 10.0018 13 10.8565 13 11.073V23.927C13 24.11 12.9954 24.9982 14.0406 24.9982C14.3019 24.9982 14.6479 25.0273 14.9265 24.8517L24.3513 18.5628C25.1248 18.0753 24.9912 17.5 24.9912 17.5C24.9912 17.5 25.1249 16.9247 24.3513 16.4372Z"
        fill="white"
      />
    </svg>
  );
};

const StopIcon = (props) => {
  const { className, onClick } = props;

  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={`${className}-circle`}
        cx="18"
        cy="18"
        r="18"
        fill="#EB5757"
      />
      <rect x="12" y="12" width="12" height="12" rx="2" fill="white" />
    </svg>
  );
};

const SyncIcon = (props) => {
  const { className, onClick, name, disabled } = props;

  return (
    <svg
      className={className}
      onClick={onClick}
      disabled={disabled}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833252 3.33334V8.33334H5.83325"
        stroke="#C1C0C0"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1668 16.6667V11.6667H14.1667"
        stroke="#C1C0C0"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0749 7.50001C16.6523 6.30567 15.934 5.23785 14.987 4.39619C14.0401 3.55454 12.8954 2.96648 11.6597 2.68689C10.424 2.4073 9.13762 2.4453 7.92059 2.79732C6.70356 3.14935 5.59554 3.80394 4.69992 4.70001L0.833252 8.33334M19.1666 11.6667L15.2999 15.3C14.4043 16.1961 13.2963 16.8507 12.0792 17.2027C10.8622 17.5547 9.57584 17.5927 8.34016 17.3131C7.10447 17.0335 5.95975 16.4455 5.01281 15.6038C4.06586 14.7622 3.34756 13.6944 2.92492 12.5"
        stroke="#C1C0C0"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <title>{name}</title>
    </svg>
  );
};

class AddTask extends Component {
  state = {
    issue: "",
    project: null,
    isUpdating: false,
    billable: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { timeEntriesList, currentTimer, projectsList } = props;
    const isArchived = (projectIdFromTimeEntry, allProjects) => {
      const project = allProjects.find(
        (item) => item.id === projectIdFromTimeEntry,
      );

      if (project && project.isActive) {
        return false;
      }
      return true;
    };
    const findUnarchivedProject = (timeEntriesList, projectsList) =>
      timeEntriesList.find(
        (item) => !isArchived(item.project.id, projectsList),
      );

    // check first render
    if (state.project === null) {
      if (currentTimer) {
        return {
          issue: currentTimer.issue,
          project: currentTimer.project,
        };
      } else {
        let project = null;

        if (timeEntriesList[0]) {
          const unarchivedProject = findUnarchivedProject(
            timeEntriesList,
            projectsList,
          )?.project;

          if (unarchivedProject) {
            project = unarchivedProject;
          } else {
            project = projectsList[0];
          }
        } else {
          project = projectsList[0];
        }
        return {
          project,
        };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { isUpdating, issue } = this.state;
    const { currentTimer: curr } = this.props;
    const prev = prevProps.currentTimer;

    if (!_.isEqual(prev, curr)) {
      if (!prev && curr) {
        this.setStateByCurrentTimer();
        this.setState({
          isUpdating: false,
        });
      } else if (prev && !curr) {
        this.setStateInitial();
        this.setState({
          isUpdating: false,
        });
      } else if (prev && curr && prev.id !== curr.id) {
        this.setStateByCurrentTimer();
        this.setState({
          isUpdating: false,
        });
      } else if (prev && curr && prev.id === curr.id) {
        // Check update project
        if (prev.project.id !== curr.project.id) {
          this.setStateByCurrentTimer();
        }
        this.setState({
          isUpdating: false,
        });
      }
    } else if (isUpdating && !issue) {
      this.setState({
        isUpdating: false,
      });
    }
  }

  updateTaskIssueDebounced = _.debounce(() => {
    const { issue } = this.state;
    const {
      currentTimer: { issue: currentIssue },
    } = this.props;

    if (issue === currentIssue) {
      this.setState({ isUpdating: false });
      return;
    }
    updateTimerSocket({
      issue,
    });
  }, 1000);

  setStateByCurrentTimer = () => {
    const { currentTimer } = this.props;

    this.setState({
      issue: currentTimer.issue,
      project: currentTimer.project,
    });
  };

  setStateInitial = () => {
    this.setState({
      issue: "",
    });
  };

  startTimer = (event) => {
    const { showNotificationAction, vocabulary } = this.props;
    const {
      v_a_task_name_before,
      v_a_starting,
      v_a_time_tracking,
      v_a_project_before,
    } = vocabulary;
    const { issue, project } = this.state;

    if (project) {
      if (issue.trim() || !issue.trim()) {
        this.setState({
          isUpdating: true,
        });
        startTimerSocket({
          issue,
          projectId: project.id,
        });
      }
    } else {
      showNotificationAction({
        text: `${v_a_project_before} ${v_a_starting} ${v_a_time_tracking}`,
        type: "warning",
      });
    }
  };

  stopTimer = (event) => {
    const { issue } = this.state;
    const { showNotificationAction, vocabulary } = this.props;
    const { v_a_task_name_before, v_a_stopping, v_a_time_tracking } =
      vocabulary;

    if (issue.trim()) {
      this.setState({
        isUpdating: true,
      });
      stopTimerSocket();
    } else {
      this.setState({
        issue: "",
      });
      showNotificationAction({
        text: `${v_a_task_name_before} ${v_a_stopping} ${v_a_time_tracking}`,
        type: "warning",
      });
    }
  };

  onChangeInput = (event) => {
    const { currentTimer } = this.props;
    const value = event.target.value;

    this.setState({
      issue: value,
    });

    if (currentTimer && value.trim() && currentTimer.issue !== value.trim()) {
      this.setState({ isUpdating: true }, () =>
        this.updateTaskIssueDebounced(),
      );
    }
  };

  onChangeProject = (project) => {
    const { currentTimer } = this.props;
    const { issue } = this.state;

    if (currentTimer && currentTimer.project.id === project.id) {
      this.setState({
        isUpdating: false,
      });
      updateTimerSocket({
        projectId: project.id,
        issue,
      });
      return;
    }

    if (currentTimer) {
      this.setState({
        isUpdating: true,
      });
      updateTimerSocket({
        projectId: project.id,
        issue,
      });
    } else {
      this.setState({
        project,
      });
    }
  };

  changeBillableStatus = async (id, isBillable) => {
    const { issue } = this.state;

    await this.props.billableStatusAction(id, isBillable);
    updateTimerSocket({
      billable: isBillable,
      issue,
    });
    this.props.getTimeEntriesListAction();
    this.props.getProjectsListActions({
      withTimerList: false,
      withUserInfo: true,
    });
  };

  render() {
    const { issue, project, isUpdating } = this.state;
    const {
      currentTimer,
      vocabulary,
      timerTick,
      isMobile,
      user,
      isSyncingTasks,
      syncAllTasks,
      currentTeam,
    } = this.props;
    const { v_add_your_task_name, v_jira_synchronization } = vocabulary;
    const disabled =
      currentTeam.data.userPlan?.name === "Free" ||
      currentTeam.data.userPlan?.name === "Starter";
    return (
      !isMobile && (
        <div className={classNames("add-task")}>
          <input
            onFocus={(event) => (event.target.placeholder = "")}
            onBlur={(event) =>
              (event.target.placeholder = v_add_your_task_name)
            }
            onKeyDown={(event) =>
              event.keyCode === 13 &&
              !currentTimer &&
              !isUpdating &&
              this.startTimer()
            }
            type="text"
            value={issue}
            onChange={this.onChangeInput}
            placeholder={v_add_your_task_name}
            className="add-task__input"
          />

          <span
            className={classNames(
              currentTimer === null
                ? "add-task__disabled"
                : currentTeam.data.userPlan?.name === "Free"
                ? "add-task__disabled-sub-check"
                : currentTimer === null || !currentTimer.billable
                ? "add-task__inactive-billable"
                : currentTimer.billable
                ? "add-task__active-billable"
                : null,
            )}
            onClick={(e) => {
              this.changeBillableStatus(
                currentTimer.id,
                !currentTimer.billable,
              );
            }}
          >
            $
          </span>
          {user.tokenJira && (
            <SyncIcon
              className={classNames("add-task__sync", {
                "add-task__sync--rotating": isSyncingTasks,
                "add-task__sync--disabled": disabled,
              })}
              onClick={syncAllTasks}
              name={v_jira_synchronization}
              disabled={disabled}
            />
          )}
          <ProjectsListPopup
            withFolder
            disabled={isUpdating}
            onChange={this.onChangeProject}
            selectedProject={project}
          />
          <span className="add-task__duration">
            {timerTick ? timerTick : "00:00:00"}
          </span>
          <Loading
            mode="overlay"
            flag={isUpdating}
            withLogo={false}
            circle
            width="3.6rem"
            height="3.6rem"
          >
            {currentTimer ? (
              <StopIcon
                className={classNames("add-task__stop-icon")}
                onClick={this.stopTimer}
              />
            ) : (
              <PlayIcon
                className={classNames("add-task__play-icon")}
                onClick={this.startTimer}
              />
            )}
          </Loading>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  currentTimer: state.mainPageReducer.currentTimer,
  isSyncingTasks: state.mainPageReducer.isSyncingTasks,
  vocabulary: state.languageReducer.vocabulary,
  projectsList: state.projectReducer.projectsList,
  timeEntriesList: state.mainPageReducer.timeEntriesList,
  timerTick: state.mainPageReducer.timerTick,
  user: state.userReducer.user,
  isMobile: state.responsiveReducer.isMobile,
  currentTeam: state.teamReducer.currentTeam,
});

const mapDispatchToProps = {
  showNotificationAction,
  syncAllTasks,
  billableStatusAction,
  getTimeEntriesListAction,
  getProjectsListActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
