import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTippy from "../../components/Unstructured/Tooltip/CustomTippy";
import { followCursor } from "tippy.js";
import classNames from "classnames";
import {
  ROLES,
  ROLES_TITLES,
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsProjectManagerByRole,
  checkIsHRManagerByRole,
} from "../../services/authentication";
import AddToTeamModal from "../../components/Unstructured/AddToTeamModal";
import RenameTeamModal from "../../components/Unstructured/RenameTeamModal";
import EditTeamModal from "../../components/Unstructured/EditTeamModal";
import { Loading } from "../../components/Unstructured/Loading";
import PageHeader from "../../components/Unstructured/PageHeader/index";
import TeamSearchBar from "../../components/Unstructured/TeamSearchBar";
import { getCurrentTeamDetailedDataAction } from "../../redux/actions/TeamActions";
import { AppConfig } from "../../config";
import "./style.scss";
import { BlankListComponent } from "../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import Dropdown from "../../components/Atoms/Dropdown";
import { EditIcon, FilterIcon } from "../../components/Atoms/SvgIcons";

const TeamPage = () => {
  // Geting data from store
  const vocabulary = useSelector((state) => state.languageReducer.vocabulary);
  const createUserModal = useSelector(
    (state) => state.teamPageReducer.createUserModal,
  );
  const editUserModal = useSelector(
    (state) => state.teamPageReducer.editUserModal,
  );
  const editedUser = useSelector((state) => state.teamPageReducer.editedUser);
  const isMobile = useSelector((state) => state.responsiveReducer.isMobile);
  const currentTeamDetailedData = useSelector(
    (state) => state.teamReducer.currentTeamDetailedData,
  );
  const currentTeam = useSelector((state) => state.teamReducer.currentTeam);
  const switchTeam = useSelector((state) => state.teamReducer.switchTeam);
  const owner_id = useSelector(
    (state) => state.teamReducer.currentTeam.data.owner_id,
  );
  const user = useSelector((state) => state.userReducer.user);

  // Hook for dispatching actions
  const dispatch = useDispatch();

  const DROPDOWN_ROLES = [
    {
      id: 1,
      name: "all",
      label: vocabulary.v_all_role,
    },
    {
      id: 2,
      name: "owner",
      label: vocabulary.v_owner,
    },
    {
      id: 3,
      name: "admin",
      label: vocabulary.v_admin,
    },
    {
      id: 4,
      name: "invoices-manager",
      label: vocabulary.v_manager,
    },
    {
      id: 5,
      name: "project-manager",
      label: vocabulary.v_proj_manager,
    },
    {
      id: 6,
      name: "hr-manager",
      label: vocabulary.v_hr_manager,
    },
    {
      id: 7,
      name: "recruiter",
      label: vocabulary.v_recruiter,
    },
    {
      id: 8,
      name: "member",
      label: vocabulary.v_member,
    },
  ];

  const DROPDOWN_ACCESS = [
    {
      id: 1,
      name: "all",
      label: vocabulary.v_all_access,
    },
    {
      id: 2,
      name: "granted",
      label: vocabulary.v_active,
    },
    {
      id: 3,
      name: "denied",
      label: vocabulary.v_not_active,
    },
  ];

  // State
  const [renameModal, setRenameModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [roleValue, setRoleValue] = useState(DROPDOWN_ROLES[0]);
  const [accessValue, setAccessValue] = useState(DROPDOWN_ACCESS[1]);
  const [technologyValue, setTechnologyValue] = useState("");

  useEffect(() => {
    dispatch(getCurrentTeamDetailedDataAction());
  }, []);

  const teamPageAction = (type, payload) => {
    dispatch({ type, payload });
  };

  const headerItems = () => {
    const { v_name, v_phone, v_roles, v_team_access } = vocabulary;

    return [
      <span key="name" onClick={() => setTechnologyValue("")}>
        {v_name}
      </span>,
      v_phone,
      "E-mail",
      v_roles,
      v_team_access,
    ];
  };

  const openAddUserModal = () => {
    teamPageAction("TOGGLE_ADD_USER_MODAL", {
      createUserModal: !createUserModal,
    });
  };

  const openEditModal = (item) => {
    teamPageAction("TOGGLE_EDIT_USER_MODAL", { editUserModal: true });
    teamPageAction("SET_EDIT_USER", { editedUser: item });
  };

  const showBigAvatar = (event, index) => {
    if (index < 2) {
      event.target.nextSibling.style.transform = "translateY(40px) scale(1)";
    } else {
      event.target.nextSibling.style.transform = "translateY(-88%) scale(1)";
    }
  };

  const hideBigAvatar = (event, index) => {
    if (index < 2) {
      event.target.nextSibling.style.transform = "translateY(40px) scale(0)";
    } else {
      event.target.nextSibling.style.transform = "translateY(-88%) scale(0)";
    }
  };

  const filterData = (data) => {
    const { v_active, v_not_active } = vocabulary;

    return data
      .filter(
        (item) =>
          roleValue.name === "all" ||
          (roleValue.name === "member" &&
            item.role_collaboration.title === "ROLE_MEMBER") ||
          (roleValue.name === "admin" &&
            item.role_collaboration.title === "ROLE_ADMIN") ||
          (roleValue.name === "owner" &&
            item.role_collaboration.title === "ROLE_OWNER") ||
          (roleValue.name === "recruiter" &&
            item.role_collaboration.title === "ROLE_RECRUITER") ||
          (roleValue.name === "hr-manager" &&
            item.role_collaboration.title === "ROLE_HR_MANAGER") ||
          (roleValue.name === "project-manager" &&
            item.role_collaboration.title === "ROLE_PROJECT_MANAGER") ||
          (roleValue.name === "invoices-manager" &&
            item.role_collaboration.title === "ROLE_INVOICES_MANAGER"),
      )
      .filter(
        (item) =>
          accessValue.name === "all" ||
          (accessValue.name === "granted" && item.is_active) ||
          (accessValue.name === "denied" && !item.is_active),
      )
      .filter(
        (item) =>
          searchValue === "" ||
          ((item.user || {}).username || "")
            .toLowerCase()
            .indexOf(searchValue.toLowerCase().trim()) !== -1 ||
          ((item.user || {}).user_technology || []).findIndex(
            (item) =>
              item.technology.title
                .toLowerCase()
                .indexOf(searchValue.toLowerCase().trim()) !== -1,
          ) !== -1 ||
          ((item.user || {}).email || "")
            .toLowerCase()
            .indexOf(searchValue.toLowerCase().trim()) !== -1 ||
          ((item.user || {}).phone || "")
            .replace(/\D/g, "")
            .indexOf(searchValue.replace(/\D/g, "") || "none") !== -1 ||
          ((item.user || {}).id === owner_id &&
            "owner".indexOf(searchValue.toLowerCase().trim()) !== -1) ||
          ((item.user || {}).id !== owner_id &&
            item.role_collaboration.title
              .slice(5)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase().trim()) !== -1) ||
          (item.is_active &&
            v_active.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !==
              -1) ||
          (!item.is_active &&
            v_not_active
              .toLowerCase()
              .indexOf(searchValue.toLowerCase().trim()) !== -1) ||
          ROLES_TITLES[item.role_collaboration.title]
            .toLowerCase()
            .trim()
            .indexOf(searchValue.toLowerCase().trim()) !== -1,
      );
  };

  const sortData = (data) => {
    const hasTechnology = [];
    const hasntTechnology = [];

    data.forEach((item) => {
      if (
        ((item.user || {}).user_technology || []).findIndex(
          (item) =>
            item.technology.title.toLowerCase().trim() === technologyValue,
        ) !== -1
      ) {
        hasTechnology.push(item);
      } else {
        hasntTechnology.push(item);
      }
    });
    return hasTechnology.concat(hasntTechnology);
  };

  const transformTechnologiesList = (list) => {
    const newList = [];

    list.forEach((item) => {
      if (
        !newList.find(
          (tech) =>
            tech.technology.title ===
            item.technology.title.toLowerCase().trim(),
        )
      ) {
        newList.push({
          ...item,
          technology: {
            ...item.technology,
            title: item.technology.title.toLowerCase().trim(),
          },
        });
      }
    });
    return newList;
  };

  const renderUsersRows = () => {
    const {
      v_active,
      v_not_active,
      v_name,
      v_team_role,
      v_team_access,
      v_phone,
      v_edit,
    } = vocabulary;

    const filteredItems = filterData(currentTeamDetailedData.data);
    const sortedItems = sortData(filteredItems);

    const items = sortedItems.map((item, index) => {
      const currentUser = item.user || {};
      const { username, email, phone, avatar, user_technology } = currentUser;
      const isActive = item.is_active;

      let role = item.role_collaboration.title;

      if (currentUser) {
        if (currentUser.id === owner_id) {
          role = ROLES.ROLE_OWNER;
        }
      }
      let currentTeamRole = currentTeam.data.role;

      if (user.id === owner_id) {
        currentTeamRole = ROLES.ROLE_OWNER;
      }

      return (
        <tr className="team_page_row" key={item.user.id}>
          <td data-label={v_name} className="team_page_col user-container">
            <div
              className="team-page-label"
              onClick={() => isMobile && setTechnologyValue("")}
            >
              {v_name}
            </div>
            {!avatar ? (
              <div className="avatar-small" />
            ) : (
              <>
                <div
                  onMouseEnter={(event) => showBigAvatar(event, index)}
                  onMouseLeave={(event) => hideBigAvatar(event, index)}
                  className="avatar-small avatar-cover"
                  style={{
                    backgroundImage: `url(${AppConfig.apiURL}${avatar})`,
                  }}
                />
                <div
                  className="avatar-big"
                  style={{
                    backgroundImage: `url(${AppConfig.apiURL}${avatar})`,
                  }}
                />
              </>
            )}
            <div>
              <CustomTippy
                content={username}
                plugins={[followCursor]}
                followCursor={true}
                arrow={false}
              >
                <div className="user-name">{username}</div>
              </CustomTippy>
              <div className="technology_container">
                {transformTechnologiesList(user_technology || []).map(
                  (item, key) => (
                    <span
                      key={key}
                      className="technology"
                      onClick={() => setTechnologyValue(item.technology.title)}
                    >
                      {item.technology.title}
                    </span>
                  ),
                )}
              </div>
            </div>
          </td>
          <td data-label={v_phone} className="team_page_col phone_container">
            <div className="team-page-label">{v_phone}</div>
            <div className="team-page-phone">{phone ? phone : "-"}</div>
          </td>
          <td data-label="E-mail" className="team_page_col email_container">
            <div className="team-page-label">E-mail</div>
            <div className="team-page-email">{email}</div>
          </td>
          <td data-label={v_team_role} className="team_page_col role_container">
            <div className="team-page-label">{v_team_role}</div>

            <div className="team-access-role">
              <div className={`access_container ${role || ROLES.ROLE_MEMBER}`}>
                {ROLES_TITLES[role]}
              </div>
            </div>
          </td>
          <td data-label={v_team_access} className="team_page_col access_wrap">
            <div className="team-page-label">{v_team_access}</div>
            <div
              className={classNames("team-access-container", {
                "team-access-container-admin":
                  checkIsAdminByRole(currentTeamRole) ||
                  checkIsOwnerByRole(currentTeamRole) ||
                  checkIsProjectManagerByRole(currentTeam.data.role) ||
                  checkIsHRManagerByRole(currentTeam.data.role),
              })}
              onClick={() =>
                isMobile &&
                (checkIsOwnerByRole(currentTeamRole) ||
                  (checkIsProjectManagerByRole(currentTeam.data.role) &&
                    !checkIsOwnerByRole(role) &&
                    !checkIsAdminByRole(role)) ||
                  (checkIsHRManagerByRole(currentTeam.data.role) &&
                    !checkIsOwnerByRole(role) &&
                    !checkIsAdminByRole(role)) ||
                  (checkIsAdminByRole(currentTeamRole) &&
                    !checkIsOwnerByRole(role)))
                  ? openEditModal(item)
                  : null
              }
            >
              {isActive ? v_active : v_not_active}
              {(checkIsOwnerByRole(currentTeamRole) ||
                (checkIsProjectManagerByRole(currentTeam.data.role) &&
                  !checkIsOwnerByRole(role) &&
                  !checkIsAdminByRole(role)) ||
                (checkIsHRManagerByRole(currentTeam.data.role) &&
                  !checkIsOwnerByRole(role) &&
                  !checkIsAdminByRole(role)) ||
                (checkIsAdminByRole(currentTeamRole) &&
                  !checkIsOwnerByRole(role))) && (
                <CustomTippy
                  content={v_edit}
                  offset={[20, 25]}
                  placement="top-end"
                  arrow={false}
                >
                  <div>
                    <EditIcon
                      className="edit_button item_button"
                      onClick={() => openEditModal(item)}
                    />
                  </div>
                </CustomTippy>
              )}
            </div>
          </td>
        </tr>
      );
    });

    return items;
  };

  const userRows = renderUsersRows();

  const checkUsersRole =
    checkIsAdminByRole(currentTeam.data.role) ||
    checkIsOwnerByRole(currentTeam.data.role) ||
    checkIsProjectManagerByRole(currentTeam.data.role) ||
    checkIsHRManagerByRole(currentTeam.data.role);

  return (
    <Loading
      flag={
        currentTeamDetailedData.isInitialFetching ||
        currentTeamDetailedData.isFetching ||
        switchTeam.isFetching
      }
      mode="parentSize"
      withLogo={false}
    >
      <div
        className={classNames("team-page", {
          "team-page--mobile": isMobile,
        })}
      >
        {createUserModal && <AddToTeamModal teamPageAction={teamPageAction} />}
        {editUserModal && (
          <EditTeamModal
            teamPageAction={teamPageAction}
            editedUser={editedUser}
            isTeamOwner={checkIsOwnerByRole(currentTeam.data.role)}
          />
        )}
        {renameModal && (
          <RenameTeamModal closeCallback={() => setRenameModal(false)} />
        )}
        <div className="data_container_team_page">
          <PageHeader title={`${vocabulary.v_team}: ${currentTeam.data.name}`}>
            <div className="team_page_main-controls">
              {(checkIsAdminByRole(currentTeam.data.role) ||
                checkIsOwnerByRole(currentTeam.data.role) ||
                checkIsProjectManagerByRole(currentTeam.data.role) ||
                checkIsHRManagerByRole(currentTeam.data.role)) && (
                <button
                  className="header-wrapper__child-button"
                  onClick={() => setRenameModal(true)}
                >
                  {vocabulary.v_rename_team}
                </button>
              )}

              {(checkIsAdminByRole(currentTeam.data.role) ||
                checkIsOwnerByRole(currentTeam.data.role) ||
                checkIsProjectManagerByRole(currentTeam.data.role) ||
                checkIsHRManagerByRole(currentTeam.data.role)) && (
                <button
                  className="header-wrapper__child-button"
                  onClick={() => {
                    openAddUserModal();
                  }}
                >
                  {vocabulary.v_invite_to_team}
                </button>
              )}
            </div>
          </PageHeader>
          <div className="team_page_searchBar">
            <TeamSearchBar search={(value) => setSearchValue(value)} />
          </div>
          <div className="team-page__filters">
            <div className="team-page__filters-title">
              <FilterIcon className="team-page__filters-icon" color="white" />
              <div className="team-page__filters-text">Filters:</div>
            </div>
            <div className="team-page__dropdowns-wrap">
              <div className="team-page__roles-dropdown">
                <Dropdown
                  placeholder="Select role"
                  classes="team-page__dropdown"
                  selected={roleValue}
                  list={DROPDOWN_ROLES}
                  onSelect={(role) => setRoleValue(role)}
                  roleUser={checkUsersRole}
                />
              </div>
              <div className="team-page__access-dropdown">
                <Dropdown
                  placeholder="Select access"
                  classes="team-page__dropdown"
                  selected={accessValue}
                  list={DROPDOWN_ACCESS}
                  onSelect={(access) => setAccessValue(access)}
                  roleUser={checkUsersRole}
                />
              </div>
            </div>
          </div>
          <div className="team_page_data">
            {userRows.length > 0 ? (
              <table className="team_page_data_table">
                <thead>
                  <tr>
                    {headerItems().map((element, index) => (
                      <th key={`team-group-header_${index}`}>{element}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-container" colSpan={5}>
                      <div className="table-scroll">
                        <table>
                          <tbody>{userRows}</tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <BlankListComponent
                subtext={vocabulary.v_no_results}
                // position={{ bottom: "-20%" }}
              />
            )}
          </div>
        </div>
      </div>
    </Loading>
  );
};

export default TeamPage;
