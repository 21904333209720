import classNames from "classnames";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  SHOW_BY_DAY,
  SHOW_BY_MONTH,
  SHOW_BY_WEEK,
  SHOW_BY_YEAR,
} from "../../../constants/timeline";

import "./style.scss";

function ResourcePlanningIntervalItem({
  getIntervalProps,
  intervalContext,
  unit,
  showBy,
  lang,
  timeFormat,
  screenWidth,
}) {
  const isActive = moment().isSame(intervalContext.interval.startTime, unit);

  const IntervalText = () => {
    const date = intervalContext.interval.startTime;

    switch (showBy) {
      case SHOW_BY_DAY:
        return (
          <div className="rp-interval-item__interval">
            {isActive && (
              <div className="rp-interval-item__text rp-interval-item__text--secondary">
                T
              </div>
            )}
            <div className="rp-interval-item__text">
              {moment(date)
                .locale(lang.short)
                .format(timeFormat === "12" ? "A h:mm" : "H:mm")}
            </div>
          </div>
        );

      case SHOW_BY_WEEK:
        return (
          <div className="rp-interval-item__interval">
            <div className="rp-interval-item__text">
              {moment(date).locale(lang.short).format("dd")},{" "}
              {moment(date).locale(lang.short).format("D")}
            </div>
          </div>
        );

      case SHOW_BY_MONTH:
        return (
          <div className="rp-interval-item__interval">
            <div className="rp-interval-item__text rp-interval-item__text--secondary">
              {moment(date).locale(lang.short).format("dd")}
            </div>
            <div className="rp-interval-item__text">
              {moment(date).format("DD")}
            </div>
          </div>
        );

      case SHOW_BY_YEAR:
        return (
          <div className="rp-interval-item__interval">
            <div className="rp-interval-item__text">
              {moment(date)
                .locale(lang.short)
                .format(screenWidth < 1281 ? "MMM" : "MMMM")}
            </div>
          </div>
        );

      default: {
        return (
          <div className="rp-interval-item__interval">
            <div className="rp-interval-item__text">
              {moment(date).locale(lang.short).format("DD MMMM YYYY")}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div
      className={classNames("rp-interval-item", {
        "rp-interval-item--active": isActive,
      })}
      {...getIntervalProps()}
    >
      <div className="rp-interval-item__container">
        <IntervalText />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  screenWidth: state.responsiveReducer.viewport.width,
});

export default connect(mapStateToProps)(ResourcePlanningIntervalItem);
