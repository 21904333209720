import styled from "styled-components";
import ArrowUp from "../../../assets/images/icons/icon-arrow-up.svg"
import ArrowDown from "../../../assets/images/icons/icon-arrow-down.svg"

export const TableHeaderContainer = styled.thead`
  list-style: none;
  width: 100%;
`;

export const TableHeaderItem = styled.td`
  .projects_toggle-icon {
    cursor: pointer;
    height: 16px;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    width: 16px;

    &--rotated {
      transform: rotate(180deg);
    }

    &__img {
      height: 16px;
      width: 16px;
    }
  }
`;

export const TableHeaderTitle = styled.div`
  display: flex;
  padding: 0 10px;
  /* margin-right: 9px; */
  color: var(--text);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
`;

export const EditButtonTitle = styled.div`
  display: block;
  width: 48px;
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const ArrowUpIcon = styled.i`
  display: flex;
  cursor: pointer;
  height: 8px;
  width: 12px;
  background: url(${ArrowUp}) no-repeat center;
`;

export const ArrowDownIcon = styled.i`
  display: flex;
  cursor: pointer;
  height: 8px;
  width: 12px;
  background: url(${ArrowDown}) no-repeat center;
`;
