import React from "react";
import * as Moment from "moment";

import CustomTippy from "../../Tooltip/CustomTippy";
import { CURRENCY, NO_DATA } from "../../../../constants/recruitment";

import { TableCell, TableCellName, TableRow } from "../ListComponent/My.styled";

const ListOfCandidates = ({ candidates = [], handleOpenViewMode }) => (
  <tbody>
    {" "}
    {candidates.map((candidate, index) => (
      <TableRow
        key={candidate.id}
        onClick={() => handleOpenViewMode(candidate)}
      >
        <TableCell>
          <CustomTippy
            content={
              !candidate.first_name && !candidate.last_name
                ? "No name"
                : `${candidate.first_name || ""} ${candidate.last_name || ""}`
            }
          >
            <TableCellName>{`${candidate?.first_name || ""}${
              candidate?.last_name || ""
            }`}</TableCellName>
          </CustomTippy>
        </TableCell>
        <TableCell>{candidate.jobType ? candidate.jobType : NO_DATA}</TableCell>
        <TableCell>{candidate.country ? candidate.country : NO_DATA}</TableCell>
        <TableCell>{candidate.city ? candidate.city : NO_DATA}</TableCell>
        <TableCell>{candidate.level ? candidate.level : NO_DATA}</TableCell>
        <TableCell>
          {candidate.direction ? candidate.direction : NO_DATA}
        </TableCell>
        <TableCell>
          {candidate.maxSalary && candidate.minSalary
            ? `${candidate.minSalary} - ${candidate.maxSalary} ${CURRENCY.USD}`
            : NO_DATA}
        </TableCell>
        <TableCell>
          {Moment(candidate.updated_at).format("DD.MM.YYYY")}
        </TableCell>
        <TableCell>
          {Moment(candidate.created_at).format("DD.MM.YYYY")}
        </TableCell>
      </TableRow>
    ))}
  </tbody>
);

export default ListOfCandidates;
