import {
  createPlan,
  getPlansByTeam,
  editPlan,
  deletePlan,
  getPlanHistory,
  getUserInfo,
  editUserInfo,
  getTotalWorkload,
  postBenchList,
} from "../../api/resourcePlanning";
import moment from "moment";
import {
  SHOW_BY_DAY,
  SHOW_BY_YEAR,
  TIMELINE_UNIT_DAY,
  TIMELINE_UNIT_HOUR,
  TIMELINE_UNIT_MONTH,
} from "../../constants/timeline";

import generateCalendarData from "../../components/Unstructured/GenerateGroupsAndItemForResourcePlanning/generateGroupsAndItem";
import { showNotificationAction } from "./NotificationActions";

export const SET_UNIT = "SET_UNIT";
export const UPDATE_TIMELINE = "UPDATE_TIMELINE";
export const UPDATE_SHOW_BY = "UPDATE_SHOW_BY";
export const UPDATE_CURRENT_DATE = "UPDATE_CURRENT_DATE";
export const SET_VISIBLE_DATE = "SET_VISIBLE_DATE";
export const SET_SHOW_BY = "SET_SHOW_BY";

export const CREATE_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const CREATE_PLAN_REQUEST_SUCCESS = "CREATE_PLAN_REQUEST_SUCCESS";
export const CREATE_PLAN_REQUEST_ERROR = "CREATE_PLAN_REQUEST_ERROR";

export const EDIT_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const EDIT_PLAN_REQUEST_SUCCESS = "CREATE_PLAN_REQUEST_SUCCESS";
export const EDIT_PLAN_REQUEST_ERROR = "CREATE_PLAN_REQUEST_ERROR";

export const DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST";
export const DELETE_PLAN_REQUEST_SUCCESS = "DELETE_PLAN_REQUEST_SUCCESS";
export const DELETE_PLAN_REQUEST_ERROR = "DELETE_PLAN_REQUEST_ERROR";

export const GET_PLANS_BY_TEAM_REQUEST = "GET_PLANS_BY_TEAM_REQUEST";
export const GET_PLANS_BY_TEAM_REQUEST_SUCCESS =
  "GET_PLANS_BY_TEAM_REQUEST_SUCCESS";
export const GET_PLANS_BY_TEAM_REQUEST_ERROR =
  "GET_PLANS_BY_TEAM_REQUEST_ERROR";

export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const SET_CALENDAR_DATA = "SET_CALENDAR_DATA";
export const SET_TIME_RANGE = "SET_TIME_RANGE";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_REQUEST_SUCCESS = "GET_USER_INFO_REQUEST_SUCCESS";
export const GET_USER_INFO_REQUEST_ERROR = "GET_USER_INFO_REQUEST_ERROR";

export const EDIT_USER_INFO_REQUEST = "EDIT_USER_INFO_REQUEST";
export const EDIT_USER_INFO_REQUEST_SUCCESS = "EDIT_USER_INFO_REQUEST_SUCCESS";
export const EDIT_USER_INFO_REQUEST_ERROR = "EDIT_USER_INFO_REQUEST_ERROR";
export const CHANGE_THEMES = "CHANGE_THEMES";

export const GET_PLAN_HISTORY = "GET_PLAN_HISTORY";

export const GET_TOTAL_WORKLOAD = "GET_TOTAL_WORKLOAD";


export const POST_BENCH_LIST_REQUEST = "POST_BENCH_LIST_REQUEST";
export const POST_BENCH_LIST_REQUEST_SUCCESS =
  "POST_BENCH_LIST_REQUEST_SUCCESS";
export const POST_BENCH_LIST_REQUEST_ERROR = "POST_BENCH_LIST_REQUEST_ERROR";

export const postBenchListRequest = () => ({
  type: POST_BENCH_LIST_REQUEST,
});

export const postBenchListRequestSuccess = (payload) => ({
  type: POST_BENCH_LIST_REQUEST_SUCCESS,
  payload,
});

export const postBenchListRequestError = (payload) => ({
  type: POST_BENCH_LIST_REQUEST_ERROR,
  payload,
});

export const postBenchListAction = (payload) => async (dispatch) => {
  dispatch(postBenchListRequest());
  try {
    const data = await postBenchList(payload);

    dispatch(postBenchListRequestSuccess(data.data));
  } catch (error) {
    dispatch(postBenchListRequestError(error));
    console.log("error", error);
  }
};

export const getPlanHistoryAction = (payload) => ({
  type: GET_PLAN_HISTORY,
  payload,
});

export const getHistory = (planId) => async (dispatch) => {
  try {
    const response = await getPlanHistory(planId.planId);

    dispatch(getPlanHistoryAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const getTotalWorkloadAction = (payload) => ({
  type: GET_TOTAL_WORKLOAD,
  payload,
});

export const getWorkload =
  (payload = {}) =>
  async (dispatch) => {
    const body = {
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
    };

    Object.keys(payload).forEach((key) => {
      if (payload[key]) {
        body[key] = payload[key];
      }
    });

    try {
      const response = await getTotalWorkload(body);
      dispatch(getTotalWorkloadAction(response.data));
    } catch (error) {
      console.log("error", error);
    }
  };

export const changeThemesAction = (payload) => ({
  type: CHANGE_THEMES,
  payload,
});

export const setUnit = (payload) => ({
  type: SET_UNIT,
  payload,
});

export const updateShowBy = (payload) => (dispatch) => {
  if (payload === SHOW_BY_DAY) {
    dispatch(setUnit(TIMELINE_UNIT_HOUR));
  } else if (payload === SHOW_BY_YEAR) {
    dispatch(setUnit(TIMELINE_UNIT_MONTH));
  } else {
    dispatch(setUnit(TIMELINE_UNIT_DAY));
  }

  dispatch(
    updateTimeline({
      visibleTimeStart: moment().startOf(payload),
      visibleTimeEnd: moment().startOf(payload).add(1, payload),
    }),
  );

  dispatch(setShowBy(payload));
};

export const setShowBy = (payload) => ({
  type: SET_SHOW_BY,
  payload,
});

export const moveTimeline = (payload) => (dispatch, getState) => {
  const { visibleTimeStart, showBy } = getState().resourcePlanningReducer;
  let newVisibleTimeStart = null;
  let newVisibleTimeEnd = null;

  if (payload === "left") {
    newVisibleTimeStart = moment(visibleTimeStart).add(-1, showBy);
    newVisibleTimeEnd = moment(visibleTimeStart);
  } else {
    newVisibleTimeStart = moment(visibleTimeStart).add(1, showBy);
    newVisibleTimeEnd = moment(visibleTimeStart).add(2, showBy);
  }

  dispatch(
    updateTimeline({
      visibleTimeStart: newVisibleTimeStart,
      visibleTimeEnd: newVisibleTimeEnd,
    }),
  );
};

export const updateCurrentDate = (payload) => ({
  type: UPDATE_CURRENT_DATE,
  payload,
});

export const setVisibleDate = (payload) => ({
  type: SET_VISIBLE_DATE,
  payload,
});

export const updateTimeline =
  ({ visibleTimeStart, visibleTimeEnd }) =>
  (dispatch) => {
    dispatch(setVisibleDate({ visibleTimeStart, visibleTimeEnd }));
    dispatch(updateCurrentDate(visibleTimeStart));
  };

export const editPlanAction = (payload, id) => async (dispatch, getState) => {
  dispatch(editPlanRequest());

  try {
    const data = await editPlan(payload, id);

    dispatch(editPlanRequestSuccess(data));

    const filterValues = getState().resourcePlanningReducer.filterValues;

    dispatch(getPlansByTeamAction(filterValues || {}));
  } catch (error) {
    dispatch(editPlanRequestError(error));
    dispatch(getPlansByTeamAction());
  }
};

export const editPlanRequest = () => ({
  type: EDIT_PLAN_REQUEST,
});

export const editPlanRequestSuccess = (payload) => ({
  type: EDIT_PLAN_REQUEST_SUCCESS,
  payload,
});

export const editPlanRequestError = (payload) => ({
  type: EDIT_PLAN_REQUEST_ERROR,
  payload,
});

export const deletePlanAction = (payload, id) => async (dispatch, getState) => {
  dispatch(deletePlanRequest());

  try {
    const data = await deletePlan(payload, id);

    dispatch(deletePlanRequestSuccess(data));
    const filterValues = getState().resourcePlanningReducer.filterValues;

    dispatch(getPlansByTeamAction(filterValues || {}));
  } catch (error) {
    dispatch(deletePlanRequestError(error));
    dispatch(getPlansByTeamAction());
  }
};

export const deletePlanRequest = () => ({
  type: DELETE_PLAN_REQUEST,
});

export const deletePlanRequestSuccess = (payload) => ({
  type: DELETE_PLAN_REQUEST_SUCCESS,
  payload,
});

export const deletePlanRequestError = (payload) => ({
  type: DELETE_PLAN_REQUEST_ERROR,
  payload,
});

export const createPlanAction = (payload) => async (dispatch, getState) => {
  dispatch(createPlanRequest());

  try {
    const data = await createPlan(payload);

    dispatch(createPlanRequestSuccess(data));

    const filterValues = getState().resourcePlanningReducer.filterValues;

    dispatch(getPlansByTeamAction(filterValues || {}));
  } catch (error) {
    dispatch(createPlanRequestError(error));
  }
};

export const createPlanRequest = () => ({
  type: CREATE_PLAN_REQUEST,
});

export const createPlanRequestSuccess = (payload) => ({
  type: CREATE_PLAN_REQUEST_SUCCESS,
  payload,
});

export const createPlanRequestError = (payload) => ({
  type: CREATE_PLAN_REQUEST_ERROR,
  payload,
});

export const getPlansByTeamAction =
  (payload = {}) =>
  async (dispatch, getState) => {
    const { visibleTimeStart, visibleTimeEnd } =
      getState().resourcePlanningReducer;

    dispatch(getPlansByTeamActionRequest());

    const body = {
      startDate: moment(visibleTimeStart).subtract(10, "years").toDate(),
      endDate: moment(visibleTimeEnd).add(10, "years").toDate(),
    };

    Object.keys(payload).forEach((key) => {
      if (payload[key]) {
        body[key] = payload[key];
      }
    });

    try {
      const { data } = await getPlansByTeam(body);

      dispatch(generateCalendarDataAction({ plans: data, body }));
      dispatch(
        setTimeRange({
          start: moment(body.startDate).valueOf(),
          end: moment(body.endDate).valueOf(),
        }),
      );
      dispatch(getPlansByTeamActionRequestSuccess(data));
    } catch (error) {
      dispatch(getPlansByTeamActionRequestError(error));
    }
  };

export const getPlansByTeamActionRequest = () => ({
  type: GET_PLANS_BY_TEAM_REQUEST,
});

export const getPlansByTeamActionRequestSuccess = (payload) => ({
  type: GET_PLANS_BY_TEAM_REQUEST_SUCCESS,
  payload,
});

export const getPlansByTeamActionRequestError = (payload) => ({
  type: GET_PLANS_BY_TEAM_REQUEST_ERROR,
  payload,
});

export const updateFilterValues = (payload) => (dispatch, getState) => {
  dispatch(setFilterValues(payload));

  const filterValues = getState().resourcePlanningReducer.filterValues;

  if (filterValues) {
    dispatch(getPlansByTeamAction(filterValues));
    dispatch(getWorkload(filterValues));
  } else {
    dispatch(getPlansByTeamAction());
  }
};

export const setFilterValues = (payload) => ({
  type: SET_FILTER_VALUES,
  payload,
});

export const generateCalendarDataAction =
  ({ plans, body }) =>
  (dispatch, getState) => {
    const team = getState().teamReducer.TeamUserStatus;
    const tags = getState()
      .teamReducer.currentTeamDetailedData.data.map((item) =>
        item.user.user_technology.map((technology) => ({
          id: technology.technology.id,
          title: technology.technology.title,
        })),
      )
      .flat();
    let users = [];
    const usersId = body.usersId || [];
    const tagsId = body.tagsId || [];
    const projectsId = body.projectsId || [];

    if (usersId && usersId.length) {
      usersId.forEach((userId) => {
        const user = team.find((item) => item.id === userId);

        if (user) {
          users.push(user);
        }
      });
    } else {
      users = team;
    }

    if (tagsId && tagsId.length) {
      const tagsTitle = tagsId.map((tagId) => {
        return tags.find((tag) => tag.id === tagId).title;
      });
      const internalUsers = [];

      tagsTitle.forEach((tagTitle) => {
        users.forEach((user) => {
          user.user_technology.forEach((tag) => {
            if (
              tag.technology.title === tagTitle &&
              !internalUsers.includes(user)
            ) {
              internalUsers.push(user);
            }
          });
        });
      });

      users = internalUsers;
    }

    if (projectsId && projectsId.length) {
      const internalUsers = [];

      const uniquePlans = [
        ...new Map(plans.map((item) => [item.user_id, item])).values(),
      ];

      uniquePlans.forEach((plan) => {
        const result = users.find((user) => user.id === plan.user_id);

        if (result) {
          internalUsers.push(result);
        }
      });

      users = internalUsers;
    }

    const { groups, items } = generateCalendarData(users, plans);

    dispatch(setCalendarData({ groups, items }));
  };

export const setCalendarData = (payload) => ({
  type: SET_CALENDAR_DATA,
  payload,
});

export const setTimeRange = (payload) => ({
  type: SET_TIME_RANGE,
  payload,
});

export const getUserInfoAction = (userId) => async (dispatch, getState) => {
  dispatch(getUserInfoRequest());

  const teamId = getState().teamReducer.currentTeam?.data?.id || "";

  try {
    const { data } = await getUserInfo({ teamId, userId });

    dispatch(getUserInfoRequestSuccess(data));
  } catch (error) {
    dispatch(getUserInfoRequestError(error));
  }
};

export const getUserInfoRequest = () => ({
  type: GET_USER_INFO_REQUEST,
});

export const getUserInfoRequestSuccess = (payload) => ({
  type: GET_USER_INFO_REQUEST_SUCCESS,
  payload,
});

export const getUserInfoRequestError = (payload) => ({
  type: GET_USER_INFO_REQUEST_ERROR,
  payload,
});

export const editUserInfoAction =
  ({ userId, body }) =>
  async (dispatch, getState) => {
    dispatch(editUserInfoRequest());

    try {
      const { data } = await editUserInfo({ userId, body });

      dispatch(editUserInfoRequestSuccess(data));

      const vocabulary = getState().languageReducer.vocabulary;

      dispatch(
        showNotificationAction({
          text:
            vocabulary?.v_resource_planning_page?.v_success_edited_profile ||
            "",
          type: "success",
        }),
      );

      dispatch(getUserInfoAction(userId));
    } catch (error) {
      showNotificationAction({
        text: error?.message,
        type: "error",
      });
      dispatch(editUserInfoRequestError(error));
    }
  };

export const editUserInfoRequest = () => ({
  type: EDIT_USER_INFO_REQUEST,
});

export const editUserInfoRequestSuccess = (payload) => ({
  type: EDIT_USER_INFO_REQUEST_SUCCESS,
  payload,
});

export const editUserInfoRequestError = (payload) => ({
  type: EDIT_USER_INFO_REQUEST_ERROR,
  payload,
});
