import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { IDictionary } from "../../../types/dictionary";
import { IRPUserInfo } from "../../../types/resource-planning.interface";
import { Formik } from "formik";
import DatepickerSelect from "../DatepickerSelect";

import moment from "moment";

import "./style.scss";
import { IEditUserBody } from "../../../api/resourcePlanning";

interface IUserModalFormProps extends RouteComponentProps {
  vocabulary: IDictionary<any>;
  userData: IRPUserInfo;
  onCancel: () => void;
  onSubmit: (data: IEditUserBody) => Promise<void>;
}

interface IUserModalFormState {
  initialFormValues: IUserModalFormValues;
  isDisabledSubmit: boolean;
}

interface IUserModalFormValues {
  vacationReset: Date | string | null;
  joinDate: Date | string | null;
  workload: number;
  dayOffLeft: number;
  dayOffTotal: number;
  sickLeaveLeft: number;
  sickLeaveTotal: number;
  vacationLeft: number;
  vacationTotal: number;
  workOff: number;
}

class ResourcePlanningUserModalForm extends Component<
  IUserModalFormProps,
  IUserModalFormState
> {
  constructor(props: IUserModalFormProps) {
    super(props);

    const {
      day_off,
      sick_leave,
      vacation,
      work_off,
      vacation_reset,
      join_date,
      workload,
    } = this.props.userData;

    const dayOffs = day_off.split("/");
    const sickLeaves = sick_leave.split("/");
    const vacations = vacation.split("/");

    this.state = {
      initialFormValues: {
        vacationReset: vacation_reset || null,
        joinDate: join_date || null,
        workload: +workload,
        dayOffLeft: +dayOffs[0],
        dayOffTotal: +dayOffs[1],
        sickLeaveLeft: +sickLeaves[0],
        sickLeaveTotal: +sickLeaves[1],
        vacationLeft: +vacations[0],
        vacationTotal: +vacations[1],
        workOff: +work_off,
      },
      isDisabledSubmit: false,
    };
  }

  editProfile = async (values: IUserModalFormValues) => {
    this.setState({ isDisabledSubmit: true });

    const workload: number = values?.workload || 0;

    const data: IEditUserBody = {
      workOff: values.workOff,
      sickLeave: `${values?.sickLeaveLeft || 0}/${values?.sickLeaveTotal || 0}`,
      vacation: `${values?.vacationLeft || 0}/${values?.vacationTotal || 0}`,
      dayOff: `${values?.dayOffLeft || 0}/${values?.dayOffTotal || 0}`,
      vacationReset: values?.vacationReset || null,
      joinDate: values?.joinDate || null,
      workload,
    };

    await this.props.onSubmit(data);

    this.setState({ isDisabledSubmit: false });
  };

  cancelEdit = () => {
    this.props.onCancel();
  };

  render() {
    const { initialFormValues, isDisabledSubmit } = this.state;
    const { vocabulary } = this.props;
    const { v_resource_planning_page, v_save_changes, v_cancel } = vocabulary;
    const {
      v_join_date,
      v_vacation_reset,
      v_workload,
      v_day_off,
      v_sick_leave,
      v_vacation,
      v_day_off_with_work_off,
    }: IDictionary<string> = v_resource_planning_page;

    const initialValues = { ...initialFormValues };

    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          this.editProfile(values);
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <>
            <form
              className="rp-user-modal-form"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className="rp-user-modal-form__row">
                <div className="rp-user-modal-form__input-container">
                  <div className="rp-user-modal-form__label">{v_join_date}</div>
                  <DatepickerSelect
                    disableToolbar={false}
                    date={formik.values.joinDate}
                    onChangeDate={(date: Date) => {
                      formik.values.joinDate = date;
                    }}
                    placeholder={v_join_date}
                    dropdownTitle={v_join_date}
                  />
                </div>
                <div className="rp-user-modal-form__input-container">
                  <div className="rp-user-modal-form__label">
                    {v_vacation_reset}
                  </div>
                  <DatepickerSelect
                    disableToolbar={false}
                    date={formik.values.vacationReset}
                    onChangeDate={(date: Date) => {
                      formik.values.vacationReset = date;
                    }}
                    placeholder={v_vacation_reset}
                    dropdownTitle={v_vacation_reset}
                  />
                </div>
              </div>
              <div className="rp-user-modal-form__input-container">
                <label htmlFor="workload" className="rp-user-modal-form__label">
                  {v_workload}
                </label>
                <input
                  type="number"
                  name="workload"
                  id="workload"
                  min={0}
                  className="rp-user-modal-form__input"
                  value={
                    formik.values.workload > 8760
                      ? (formik.values.workload = 8760)
                      : formik.values.workload
                  }
                  onChange={formik.handleChange}
                  onKeyDown={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;

                    if (charCode > 31 && (charCode < 48 || charCode > 57))
                      e.preventDefault();
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="rp-user-modal-form__separator"></div>

              <div className="rp-user-modal-form__row">
                <div className="rp-user-modal-form__input-container">
                  <label
                    htmlFor="workload"
                    className="rp-user-modal-form__label"
                  >
                    {v_day_off}
                  </label>
                  <div className="rp-user-modal-form__inputs">
                    <input
                      type="number"
                      name="dayOffLeft"
                      id="dayOffLeft"
                      min={0}
                      className="rp-user-modal-form__input"
                      value={
                        formik.values.dayOffLeft > 366
                          ? (formik.values.dayOffLeft = 366)
                          : formik.values.dayOffLeft
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    /
                    <input
                      type="number"
                      name="dayOffTotal"
                      id="dayOffTotal"
                      min={0}
                      className="rp-user-modal-form__input"
                      value={
                        formik.values.dayOffTotal > 366
                          ? (formik.values.dayOffTotal = 366)
                          : formik.values.dayOffTotal
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="rp-user-modal-form__input-container">
                  <label
                    htmlFor="workload"
                    className="rp-user-modal-form__label"
                  >
                    {v_sick_leave}
                  </label>
                  <div className="rp-user-modal-form__inputs">
                    <input
                      type="number"
                      id="sickLeaveLeft"
                      name="sickLeaveLeft"
                      min={0}
                      className="rp-user-modal-form__input"
                      value={
                        formik.values.sickLeaveLeft > 366
                          ? (formik.values.sickLeaveLeft = 366)
                          : formik.values.sickLeaveLeft
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    /
                    <input
                      type="number"
                      id="sickLeaveTotal"
                      name="sickLeaveTotal"
                      min={0}
                      className="rp-user-modal-form__input"
                      value={
                        formik.values.sickLeaveTotal > 366
                          ? (formik.values.sickLeaveTotal = 366)
                          : formik.values.sickLeaveTotal
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="rp-user-modal-form__row">
                <div className="rp-user-modal-form__input-container">
                  <label
                    htmlFor="workload"
                    className="rp-user-modal-form__label"
                  >
                    {v_vacation}
                  </label>
                  <div className="rp-user-modal-form__inputs">
                    <input
                      type="number"
                      name="vacationLeft"
                      id="vacationLeft"
                      min={0}
                      className="rp-user-modal-form__input"
                      value={
                        formik.values.vacationLeft > 366
                          ? (formik.values.vacationLeft = 366)
                          : formik.values.vacationLeft
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    /
                    <input
                      type="number"
                      name="vacationTotal"
                      id="vacationTotal"
                      min={0}
                      className="rp-user-modal-form__input"
                      value={
                        formik.values.vacationTotal > 366
                          ? (formik.values.vacationTotal = 366)
                          : formik.values.vacationTotal
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="rp-user-modal-form__input-container">
                  <label
                    htmlFor="workload"
                    className="rp-user-modal-form__label"
                  >
                    {v_day_off_with_work_off}
                  </label>
                  <input
                    type="number"
                    name="workOff"
                    id="workOff"
                    min={0}
                    placeholder={v_day_off_with_work_off}
                    className="rp-user-modal-form__input"
                    value={
                      formik.values.workOff > 366
                        ? (formik.values.workOff = 366)
                        : formik.values.workOff
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="rp-user-modal-form__footer">
                <button
                  type="button"
                  aria-label="Cancel"
                  className="rp-user-modal-form__btn"
                  onClick={this.cancelEdit}
                >
                  {v_cancel}
                </button>
                <button
                  type="submit"
                  disabled={isDisabledSubmit}
                  className="rp-user-modal-form__btn rp-user-modal-form__btn--success"
                >
                  {v_save_changes}
                </button>
              </div>
            </form>
          </>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: any) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcePlanningUserModalForm),
);
