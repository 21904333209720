import React, { Component, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Input from "../BaseComponents/Input";
import Checkbox from "@material-ui/core/Checkbox";
import CountriesDropdown from "../CountriesDropdown";
import "./style.scss";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import countries from "../CountriesDropdown/countriesFlat.json";
import Modal from "../../Atoms/Modal";
import AvatarLoader from "../../Atoms/AvatarLoader";
import { baseURL } from "../../../api/configAPI";

const phoneRegExp = /^\+[0-9() -]{9,20}$/;

const ClientModal = ({
  open,
  client,
  onModalClose,
  onAddClient,
  onEditClient,
  onDeleteClient,
}) => {
  const [deleteCheckbox, setDeleteCheckbox] = useState(false);
  const [clientAvatar, setClientAvatar] = useState("");
  const [isOpenCountriesDropdown, setIsOpenCountriesDropdown] = useState(false);

  const dispatch = useDispatch();
  const { vocabulary } = useSelector((state) => state.languageReducer);

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const handleChangeCheckbox = (event) => {
    setDeleteCheckbox(event.target.checked);
  };

  const closeDropdown = (e) => {
    if (isOpenCountriesDropdown && !e.target.closest(".flag-input-container")) {
      setIsOpenCountriesDropdown(false);
    }
  };

  const onAvatarLoadError = () => {
    dispatch(
      showNotificationAction({
        text: vocabulary.v_a_avatar_upload_error,
        type: "error",
      }),
    );
  };

  const getValue = (valueName) => {
    if (client) {
      return client[valueName] ? client[valueName] : "";
    } else {
      return "";
    }
  };

  return (
    <Modal
      open={open}
      title={client ? vocabulary.v_edit_client_title : vocabulary.v_add_client}
      onClose={onModalClose}
      classes={{ wrapper: "client-modal" }}
    >
      <Formik
        // enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          country: getValue("country"),
          city: getValue("city"),
          state: getValue("state"),
          language: getValue("language"),
          phone: getValue("phone"),
          zip: getValue("zip"),
          name: getValue("name"),
          email: getValue("email"),
          company_name: getValue("company_name"),
        }}
        validationSchema={Yup.object({
          zip: Yup.string().matches(
            /^[a-zA-Z0-9-\]\[\s]{0,11}$/, //eslint-disable-line
            "no_valid_zip",
          ),
          email: Yup.string().email("v_a_incorect_email"),
          company_name: Yup.string().required("v_v_required").trim(),
          phone: Yup.string().matches(phoneRegExp, "no_valid_number"),
        })}
        onSubmit={(values) => {
          if (deleteCheckbox) {
            onDeleteClient(client.id);
            dispatch(
              showNotificationAction({
                text: vocabulary.client_was_deleted,
                type: "success",
              }),
            );
          } else if (navigator.onLine === false) {
            dispatch(
              showNotificationAction({
                text: vocabulary.v_connection_problem,
                type: "lost-connection",
              }),
            );
            return false;
          } else {
            client
              ? (() => {
                  onEditClient(values, client.id, clientAvatar);
                  dispatch(
                    showNotificationAction({
                      text: vocabulary.client_was_edited,
                      type: "success",
                    }),
                  );
                })()
              : (() => {
                  onAddClient(values, clientAvatar);
                })();
          }
        }}
      >
        {(formik) => (
          <form
            className="client-modal__body billing-modal__container-form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <section className="client-info__section">
              <div className="client-info__avatar-loader">
                <AvatarLoader
                  imageUrl={
                    client && client.avatar ? baseURL + client.avatar : ""
                  }
                  onImageChange={(file) => setClientAvatar(file)}
                  onValidationError={onAvatarLoadError}
                />
              </div>
              <Input
                config={{
                  id: "company_name",
                  name: "company_name",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.company_name,
                  placeholder: vocabulary.v_enter_text,
                }}
                errorMsg={formik.errors.company_name}
                label={`${vocabulary.company_name}*`}
                withValidation
              />
            </section>
            <section className="client-info__section">
              <Input
                config={{
                  id: "name",
                  name: "name",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.name,
                  placeholder: vocabulary.v_enter_text,
                }}
                label={vocabulary.v_client_name}
                withValidation
              />
              <div className="flag-input-container">
                <div className="flag-input-container-title">
                  {vocabulary.v_country}
                </div>
                <div
                  className="flag-input-container-select"
                  onClick={() =>
                    setIsOpenCountriesDropdown(!isOpenCountriesDropdown)
                  }
                >
                  <div className="flag-input-container-selected">
                    {countries[formik.values.country] ? (
                      <>
                        <img
                          className="flag-input-container-selected-flag"
                          src={countries[formik.values.country].flag}
                          alt=""
                        />
                        <span className="flag-input-container-selected-text">
                          {countries[formik.values.country].name.common}
                        </span>
                      </>
                    ) : (
                      <span className="flag-input-container-selected-text-empty">
                        {vocabulary.select_country}
                      </span>
                    )}
                  </div>
                  <div
                    className={classNames("flag-input-container-select-arrow", {
                      rotated: isOpenCountriesDropdown,
                    })}
                  />
                </div>
                {isOpenCountriesDropdown && (
                  <div className="flag-input-container-select-dropdown">
                    <CountriesDropdown
                      inputPlaceholder={`${vocabulary.v_search}...`}
                      epmtyText={vocabulary.v_empty}
                      onSelect={(item) => {
                        formik.values.country = item.code;
                        setIsOpenCountriesDropdown(false);
                      }}
                    />
                  </div>
                )}
                <div
                  className="wrapper-base-input__error-message"
                  style={{ height: "1rem" }}
                />
              </div>
            </section>
            <section className="client-info__section">
              <Input
                config={{
                  id: "email",
                  name: "email",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.email,
                  placeholder: vocabulary.v_enter_text,
                }}
                errorMsg={formik.errors.email}
                label={vocabulary.v_email}
                withValidation
              />
              <Input
                config={{
                  id: "state",
                  name: "state",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.state,
                  placeholder: vocabulary.v_enter_state,
                }}
                label={vocabulary.v_state}
                withValidation
              />
            </section>
            <section className="client-info__section">
              <Input
                config={{
                  id: "phone",
                  name: "phone",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.phone,
                  placeholder: vocabulary.v_enter_number,
                }}
                errorMsg={formik.errors.phone}
                label={vocabulary.v_phone}
                withValidation
              />
              <Input
                config={{
                  id: "city",
                  name: "city",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.city,
                  placeholder: vocabulary.v_enter_text,
                }}
                label={`${
                  vocabulary.v_city
                }, ${vocabulary.v_address.toLowerCase()}`}
                withValidation
              />
            </section>
            <section className="client-info__section section-solo">
              <Input
                config={{
                  id: "zip",
                  name: "zip",
                  type: "text",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.zip,
                  placeholder: vocabulary.v_enter_number,
                }}
                label={vocabulary.v_zip_code}
                errorMsg={formik.errors.zip}
                withValidation
              />
            </section>

            {client && (
              <section className="client-info__section-delete">
                <Checkbox
                  color="primary"
                  onChange={handleChangeCheckbox}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span>{vocabulary.v_delete_client}</span>
              </section>
            )}
            <button type="submit" className="client-modal__button">
              {client ? vocabulary.v_save : vocabulary.v_add_client}
            </button>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ClientModal;
