import styled from "styled-components/macro";

export const Form = styled.form`
  margin: 0 5%;
  margin-bottom: 10%;
`;

export const Input = styled.input`
  background: none;
  color: var(--text);
  border-radius: 8px;
  border: 1px solid var(--border-color-team);
  padding: 10px 15px;
  width: 100%;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  max-height: 40px;
  ::placeholder {
    font-weight: 500;
    color: #b9bbbe;
  }
`;

export const TextLabel = styled.p`
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
`;

export const Button = styled.button`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  margin: 0 5px;
  @media (max-width: 415px) {
    padding: 14px 14px;
  }
`;

export const SaveButton = styled(Button)`
  background: #00b173;
`;

export const DeleteButton = styled(Button)`
  background: #eb5757;
`;

export const CancelButton = styled(Button)`
  background: #52565f;
`;
