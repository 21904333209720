import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { apiCall } from "../../../services/apiService";

// Components
import Input from "../BaseComponents/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ArrowIcon } from "../../Atoms/SvgIcons";
import LinkToVacanciesComponent from "../People/LinkToVacancies";

// Actions
import { addUserToCurrentTeamAction } from "../../../redux/actions/TeamActions";

// Queries

// Config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";
import { Loading } from "../Loading";

const materialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
      },
    },
  },
});

const CrossIcon = ({ className, color }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 5L5 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5L15 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

class AddToTeamModal extends Component {
  state = {
    projectDataSelected: [],
    projectDataFiltered: [],
    toggleSelectProject: false,
  };

  componentDidMount() {
    apiCall(`${AppConfig.apiURL}project/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (result) => {
        const projects = result.data;

        this.setState({ projectDataEtalon: projects });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  closeModal() {
    this.props.teamPageAction("TOGGLE_ADD_USER_MODAL", {
      createUserModal: false,
    });
  }

  openSelectProject() {
    this.setState({ toggleSelectProject: true });
    this.findProject(this.state.projectDataEtalon);
    document.addEventListener("click", this.closeDropdownProject);
  }

  closeDropdownProject = (e) => {
    if (
      this.selectListProjectsRef &&
      !this.selectListProjectsRef.contains(e.target)
    ) {
      this.setState(
        {
          toggleSelectProject: false,
        },
        () => {
          document.removeEventListener("click", this.closeDropdownProject);
        },
      );
    }
  };

  toggleProject(project) {
    const projects = JSON.parse(JSON.stringify(this.state.projectDataSelected));
    let exists = false;

    for (let i = 0; i < projects.length; i++) {
      const currentProject = projects[i];

      if (currentProject.name === project.name) {
        exists = true;
        projects.splice(i, 1);
        break;
      }
    }

    if (!exists) {
      projects.push(project);
    }

    this.setState({
      projectDataSelected: projects,
    });
  }

  findProject(items, searchText = "") {
    if (searchText.length > 0) {
      const filteredArr = items.filter((item) => {
        const values = [];

        values.push(item["name"]);
        return item.name
          .toLowerCase()
          .includes(searchText.toLowerCase().trim());
      });

      this.setState({ projectDataFiltered: filteredArr });
    } else {
      this.setState({ projectDataFiltered: items });
    }
  }

  getCheckedProjects(id) {
    return this.state.projectDataSelected.some((item) => item.id === id);
  }

  selectAllProjects() {
    this.setState({
      projectDataSelected: this.state.projectDataEtalon,
    });
  }

  selectNoneProjects() {
    this.setState({
      projectDataSelected: [],
    });
  }

  beginningListSelectedCheckbox = (newArrTopCheckbox, item) => {
    const x = newArrTopCheckbox.findIndex((v) => v === item);

    if (x !== -1) {
      newArrTopCheckbox.splice(x, 1);
    }
    newArrTopCheckbox.unshift(item);
    return newArrTopCheckbox;
  };

  clearProjectSearch() {
    this.smallSelectProjectInputRef.value = "";
    this.findProject(this.state.projectDataEtalon);
  }

  render() {
    const { vocabulary, currentTeamDetailedData, addUserToCurrentTeamAction } =
      this.props;
    const {
      v_invite_to_team,
      v_add_user,
      v_select_none,
      v_select_all,
      v_find,
      v_project,
      v_select_projects,
      v_empty_email,
      v_hr_people,
    } = vocabulary;

    const availableTags = 100;

    return (
      <div className="wrapper_add_user_modal">
        <div className="add_user_modal_background" />
        <div className="add_user_modal_container">
          <Loading
            mode="overlay"
            flag={currentTeamDetailedData.isModalLoading}
            withLogo={false}
          >
            <div className="add_user_modal_header">
              <div className="add_user_modal_header_title">
                {v_invite_to_team}
              </div>
              <i
                className="add_user_modal_header_close"
                onClick={(e) => this.closeModal()}
              />
            </div>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                techEmail: [],
                emailsList: [],
              }}
              validationSchema={Yup.object().shape({
                emailsList: Yup.array()
                  .required(v_empty_email)
                  .of(
                    Yup.string().email(
                      ({ value }) => `${value} is not a valid email! `,
                    ),
                  ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                addUserToCurrentTeamAction({
                  emailsList: values.emailsList,
                  projectsIds: this.state.projectDataSelected.map((p) => p.id),
                });
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <form
                  className="add_user_modal_data"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <div className="emails-container">
                    <LinkToVacanciesComponent
                      userTechnologies={formik.values.techEmail}
                      setUserTechnologies={(techArr) => {
                        formik.setFieldValue("techEmail", techArr);
                        const techArrString = techArr.map(
                          (option) => option.link,
                        );
                        formik.setFieldValue("emailsList", techArrString);
                      }}
                      showNotificationAction={false}
                      vocabulary={vocabulary}
                      themeLight={true}
                      title={"Language skills"}
                      placeholder={v_hr_people.candidates_modal.emailPlace}
                      availableTags={availableTags}
                      disableParam={formik.values?.emailsList.length}
                      hasError={formik.errors.emailsList}
                      withValidation
                    />
                  </div>
                  <div className="errorMsg">{formik.errors.emailsList}</div>
                  <div className="add_to_team_modal_projects_container">
                    <div className="add_to_team_modal_select_wrapper">
                      <div
                        className="add_to_team_modal_select_header"
                        onClick={(_) => this.openSelectProject()}
                        ref={(div) => (this.projectInputRef = div)}
                      >
                        <div>
                          {this.state.projectDataSelected.length > 0 && (
                            <>{v_project}:&nbsp;</>
                          )}

                          {this.state.projectDataSelected.length === 0
                            ? v_select_projects
                            : this.state.projectDataSelected.length ===
                              this.state.projectDataEtalon.length
                            ? v_select_all
                            : this.state.projectDataSelected.map(
                                (item, index) => (
                                  <span key={item.name + index}>
                                    {index === 0 ? item.name : `, ${item.name}`}
                                  </span>
                                ),
                              )}
                        </div>
                        <ArrowIcon
                          className="add_to_team_modal_arrow"
                          color="#828282"
                        />
                      </div>
                    </div>
                    {this.state.toggleSelectProject && (
                      <div
                        className="add_to_team_modal_select_body"
                        ref={(div) => (this.selectListProjectsRef = div)}
                      >
                        <div className="add_to_team_modal_search_menu_select">
                          <div className="add_to_team_modal_search_container">
                            <input
                              className="add_to_team_modal_search_input"
                              type="text"
                              onKeyUp={(_) => {
                                this.findProject(
                                  this.state.projectDataEtalon,
                                  this.smallSelectProjectInputRef.value,
                                );
                              }}
                              ref={(input) =>
                                (this.smallSelectProjectInputRef = input)
                              }
                              placeholder={`${v_find}...`}
                            />
                            <button
                              type="button"
                              className="add_to_team_modal_clear_button"
                              onClick={(_) => this.clearProjectSearch()}
                            >
                              <CrossIcon
                                className="add_to_team_modal_cross_icon"
                                color="black"
                              />
                            </button>
                          </div>

                          <button
                            type="button"
                            className="add_to_team_modal_select_button"
                            ref={(div) => (this.selectAllProjectsRef = div)}
                            onClick={(_) => this.selectAllProjects()}
                          >
                            {v_select_all}
                          </button>
                          <button
                            type="button"
                            className="add_to_team_modal_select_button"
                            ref={(div) => (this.selectNoneProjectsRef = div)}
                            onClick={(_) => this.selectNoneProjects()}
                          >
                            {v_select_none}
                          </button>
                        </div>
                        <div className="add_to_team_modal_select_items_container">
                          {this.state.projectDataFiltered.map((item, index) => (
                            <div
                              className="add_to_team_modal_select_item"
                              key={item.id}
                            >
                              <label>
                                <ThemeProvider theme={materialTheme}>
                                  <Checkbox
                                    color={"primary"}
                                    value={item.name}
                                    checked={this.getCheckedProjects(item.id)}
                                    onChange={() => {
                                      this.toggleProject(item);
                                      this.beginningListSelectedCheckbox(
                                        this.state.projectDataFiltered,
                                        item,
                                      );
                                    }}
                                  />
                                </ThemeProvider>{" "}
                                <span className="add_to_team_modal_select_item_title">
                                  {item.name}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="add_user_modal_button_container_button"
                  >
                    {v_add_user}
                  </button>
                </form>
              )}
            </Formik>
          </Loading>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  currentTeamDetailedData: state.teamReducer.currentTeamDetailedData,
});

const mapDispatchToProps = {
  addUserToCurrentTeamAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToTeamModal);
