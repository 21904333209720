import styled from "styled-components/macro";

export const HeadRow = styled.tr`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--text);
`;

export const TableCell = styled.td`
  padding: 10px;
  width:200px;
  &:first-child {
    width:40px;
  }
`;

