import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole
} from "../../services/authentication";
import ProjectSearchBar from "../../components/Unstructured/projectSearchBar";
import ProjectData from "../../components/Unstructured/ProjectsData";
import ProjectModal from "../../components/Unstructured/ProjectModal";
import { Loading } from "../../components/Unstructured/Loading";
import PageHeader from "../../components/Unstructured/PageHeader/index";
import FilterByStatus from "../../components/Unstructured/FilterByStatus";
import projectsPageAction, {
  getProjectsListActions,
  changeProjectActiveStatusAction,
  changeProjectFavouriteAction,
  resetProjectsParamsAction,
} from "../../redux/actions/ProjectsActions";
import { addUserRoleToProjects } from "../../services/parseFunctions";
import "./style.scss";
import { BlankListComponent } from "../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import { SUBSCRIPTIONS } from "../../constants/constants";
import { changeUpgradeModalStatusAction } from "../../redux/actions/SubscriptionPageAction";
import { withRouter } from "react-router-dom";

class ProjectsPage extends Component {
  state = {
    isInitialFetching: true,
    stateSearchValue: this.props.location?.prevSearchValue || "",
  };

  async componentDidMount() {
    await this.props.getProjectsListActions({
      page: 1,
      withPagination: true,
      withTimerList: false,
    });
     this.setState({
       isInitialFetching: false,
     });
  }

  componentWillUnmount() {
    this.props.resetProjectsParamsAction();
  }

  componentDidUpdate(prevProps, prevState) {
    const { projectsList, projectsPageAction } = this.props;

    if (projectsList && !_.isEqual(prevProps.projectsList, projectsList)) {
      this.setState({
        isInitialFetching: false,
      });
      projectsPageAction("CREATE_PROJECT", {
        tableData: addUserRoleToProjects(projectsList),
      });
    }
    if (prevState.stateSearchValue !== this.state.stateSearchValue) {
      this.props.getProjectsListActions({
        page: 1,
        withPagination: true,
        withTimerList: false,
        searchValue: this.state.stateSearchValue,
      });
    }
  }

  searchProject = () => {
    this.props.getProjectsListActions({
      page: 1,
      withPagination: true,
      withTimerList: false,
      searchValue: this.state.stateSearchValue,
    });
  };

  resetFilters = () => {
    this.setState({ stateSearchValue: "" });
    this.props.getProjectsListActions({
      page: 1,
      withPagination: true,
      withTimerList: false,
      searchValue: "",
      filterStatus: "all",
    });
  };

  setFilterStatus = (status) => {
    this.props.getProjectsListActions({
      page: 1,
      withPagination: true,
      withTimerList: false,
      filterStatus: status,
    });
  };

  changeProjectActiveStatus = async (projectId, isActive) => {
    await this.props.changeProjectActiveStatusAction(projectId, isActive);
    this.props.getProjectsListActions({
      page: 1,
      withPagination: true,
      withTimerList: false,
    });
  };

  changeProjectFavouriteStatus = async (projectId, isFavourite) => {
    await this.props.changeProjectFavouriteAction(projectId, isFavourite);
    this.props.getProjectsListActions({
      page: 1,
      withPagination: true,
      withTimerList: false,
    });
  };

  findUserPlane = (planeId, planes) =>
    planes.find((item) => planeId === item.id);

  checkOnPlane = () => {
    const {
      v_upgrade_projects_title,
      v_upgrade_projects_description,
      v_upgrade_admin_note,
    } = this.props.vocabulary;
    const isAdmin = checkIsAdminByRole(this.props.currentTeam.data.role);

    if (
      this.props.currentTeam.data.userPlan.maxProjects &&
      this.props.totalProjects >=
        this.props.currentTeam.data.userPlan.maxProjects
    ) {
      this.props.changeUpgradeModalStatusAction({
        isOpen: true,
        content: {
          title: v_upgrade_projects_title,
          description: `${v_upgrade_projects_description} ${
            isAdmin ? v_upgrade_admin_note : ""
          }`,
        },
      });
      return;
    }

    this.props.projectsPageAction("TOGGLE_PROJECT_MODAL", { toggle: true });
  };

  render() {
    const {
      tableData,
      addNewProjectModalToggle,
      projectsPageAction,
      isMobile,
      vocabulary,
      currentTeam,
      filterStatus,
    } = this.props;
    const {
      v_create_new_project,
      v_projects,
      v_filter_all_projects,
      v_filter_active,
      v_filter_archived,
      v_clear_filters,
    } = vocabulary;

    const { isInitialFetching, stateSearchValue } = this.state;

    const checkUsersRole =
      checkIsAdminByRole(currentTeam.data.role) ||
      checkIsOwnerByRole(currentTeam.data.role)||
      checkIsHRManagerByRole(currentTeam.data.role)||
      checkIsProjectManagerByRole(currentTeam.data.role);

    return (
      <Loading flag={isInitialFetching} mode="parentSize" withLogo={false}>
        <div
          className={classNames("wrapper_projects_page", {
            "wrapper_projects_page--mobile": isMobile,
          })}
        >
          <div className="data_container_projects_page">
            <div className="data_container_projects_page_top">
              <PageHeader title={v_projects}>
                {checkUsersRole && (
                  <button
                    className="header-wrapper__child-button"
                    onClick={this.checkOnPlane}
                  >
                    {v_create_new_project}
                  </button>
                )}
              </PageHeader>

              <div className="project-page-filters">
                <ProjectSearchBar
                  searchValue={stateSearchValue}
                  searchValueHandler={(e) =>
                    this.setState({ stateSearchValue: e.target.value })
                  }
                  filterProjects={this.searchProject}
                />
                <div className="project-page-filters__clear">
                  <div
                    className="project-page-filters__button"
                    onClick={this.resetFilters}
                  >
                    {v_clear_filters}
                  </div>
                </div>
              </div>
            </div>
            <FilterByStatus
              status={filterStatus}
              onClick={this.setFilterStatus}
              items={[
                {
                  id: "all",
                  text: v_filter_all_projects,
                },
                {
                  id: "active",
                  text: v_filter_active,
                },
                {
                  id: "archived",
                  text: v_filter_archived,
                },
              ]}
            />
            {tableData.length > 0 ? (
              <ProjectData
                tableInfo={tableData}
                projectsPageAction={projectsPageAction}
                editedProject={this.props.editedProject}
                editProjectModal={this.props.editProjectModal}
                changeProjectActiveStatus={this.changeProjectActiveStatus}
                changeProjectFavouriteStatus={this.changeProjectFavouriteStatus}
                getProjects={this.getProjects}
                searchValue={this.state.stateSearchValue.toLowerCase()}
              />
            ) : (
              <BlankListComponent
                subtext={this.props.vocabulary.v_no_results}
                // position={{ bottom: "-15%" }}
              />
            )}
          </div>
        </div>
        {checkUsersRole && <ProjectModal open={addNewProjectModalToggle} />}
      </Loading>
    );
  }
}

const mapStateToProps = (store) => ({
  tableData: store.projectReducer.tableData,
  addNewProjectModalToggle: store.projectReducer.addNewProjectModalToggle,
  editedProject: store.projectReducer.editedProject,
  editProjectModal: store.projectReducer.editProjectModal,
  isMobile: store.responsiveReducer.isMobile,
  vocabulary: store.languageReducer.vocabulary,
  currentTeam: store.teamReducer.currentTeam,
  projectsList: store.projectReducer.projectsList,
  totalProjects: store.projectReducer.total,
  isPaginationFetching: store.projectReducer.pagination.listFetching,
  filterStatus: store.projectReducer.filterStatus,
  searchValue: store.projectReducer.searchValue,
});

const mapDispatchToProps = {
  projectsPageAction,
  getProjectsListActions,
  changeProjectActiveStatusAction,
  changeProjectFavouriteAction,
  resetProjectsParamsAction,
  changeUpgradeModalStatusAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectsPage),
);
