import { instance } from "../configAPI";
import { TAddProjectRequest, TChangeProjectRequest } from "./types";
import { AxiosPromise } from "axios";

export const getProjectsListApi = ({
  withTimerList = false,
  withUserInfo = false,
  page = null,
  limit = null,
  searchValue = null,
  isActive = null,
}) =>
  instance({
    url: "/project/list",
    method: "GET",
    params: {
      withTimerList,
      withUserInfo,
      page,
      limit,
      isActive,
      searchValue,
    },
  });

export const getRelationProjectsListApi = (): AxiosPromise =>
  instance({
    url: `/project/jira/list`,
    method: "GET",
  });

export const addProjectApi = ({
  name,
  projectColorId,
  clientId,
  jiraProjectId,
  users,
}: TAddProjectRequest): AxiosPromise =>
  instance({
    url: "/project/add",
    method: "POST",
    data: JSON.stringify({
      project: {
        name,
        projectColorId,
        clientId,
        jiraProjectId,
      },
      users,
    }),
  });

export const changeProjectApi = ({
  id,
  name,
  projectColorId,
  users,
  clientId,
  jiraProjectId,
}: TChangeProjectRequest): AxiosPromise =>
  instance({
    url: `/project/${id}`,
    method: "PATCH",
    data: JSON.stringify({
      project: {
        name,
        projectColorId,
        clientId,
        jiraProjectId,
      },
      users,
    }),
  });

export const getReportsProjectsApi = ({ startDate, endDate }: any) =>
  instance({
    url: "/project/reports-projects",
    method: "POST",
    data: JSON.stringify({
      startDate,
      endDate,
    }),
  });

export const deleteUserFromProjectApi = (userId: any, projectId: any) =>
  instance({
    url: `/project/user/${projectId}`,
    method: "DELETE",
    data: {
      id: userId,
    },
  });

export const changeProjectActiveStatusApi = (projectId: any, status: any) =>
  instance({
    url: `/project/${projectId}/active-status`,
    method: "PATCH",
    data: {
      isActive: status,
    },
  });

export const changeProjectFavouriteApi = (projectId: any, status: any) =>
  instance({
    url: `/project/${projectId}/favourite`,
    method: "PATCH",
    data: {
      isFavourite: status,
    },
  });

// TODO: Need to change endpoint name, from "/project-color/list" to "/project/color/list"
export const getProjectColorListApi = () =>
  instance({
    url: "/project-color/list",
    method: "GET",
  });
