import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import { BellIcon } from "../../Atoms/SvgIcons";
import ReleaseNotesModal from "../ReleaseNotesModal";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";

import "./style.scss";
import { getNotificationsAction } from "../../../redux/actions/MainPageAction";
import { VERSIONS_LIST } from "../../../constants/versions";
import {
  isSameVersion,
  formatDropdownDateForRender,
} from "../../../services/versionService";

class ReleaseNotesDropdown extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();

    this.state = {
      versionsList: [...VERSIONS_LIST],
      isOpen: false,
      isOpenModal: false,
      isShowCircle: !isSameVersion(),
      selectedVersion: VERSIONS_LIST[0].version,
      notificationLength: Number(localStorage.getItem("lengthNotification")),
      isShowNotificationsCircle: true,
      isShowNewNotificationCircle: false,
    };
  }

  triggerBellIcon = () => {
    this.openDropdown();
  };

  openDropdown = () => {
    const { onChangeVisibility, disabled } = this.props;
    this.setState({
      isShowNotificationsCircle: false,
      isShowNewNotificationCircle: false,
    });
    if (disabled) {
      return;
    }
    this.setState(
      {
        isOpen: true,
      },
      () => onChangeVisibility(true),
    );
    document.addEventListener("click", this.closeDropdown);
  };

  closeDropdown = (event) => {
    if (event.target.classList.length) {
      const { onChangeVisibility } = this.props;

      document.removeEventListener("click", this.closeDropdown);
      this.setState(
        {
          isOpen: false,
        },
        () => onChangeVisibility(false),
      );
    }
  };
  componentDidMount(prevProps) {
    this.props.getNotificationsAction();
  }
  componentDidUpdate(prevProps, prevState) {
    const { allNotificationsList } = this.props;
    if (this.props.allNotificationsList !== prevProps.allNotificationsList) {
      localStorage.setItem(
        "lengthNotification",
        this.props.allNotificationsList.length,
      );
    }
    if (this.props.notificationsList !== prevProps.notificationsList) {
      this.setState({
        isShowNewNotificationCircle: true,
      });
    }
  }
  componentWillUnmount() {
    const { onChangeVisibility } = this.props;

    onChangeVisibility(false);
    document.removeEventListener("click", this.closeDropdown);
  }

  selectVersion = (version) => {
    this.setState({ selectedVersion: version, isOpenModal: true });
  };

  closeModal = () => {
    this.setState({ isOpenModal: false, isShowCircle: false });
  };

  render() {
    const { vocabulary, isMobile, allNotificationsList } = this.props;
    const { v_release_notes, lang, v_notifications, v_not_notifications } =
      vocabulary;
    const { v_version } = v_release_notes;
    const {
      isOpen,
      versionsList,
      isOpenModal,
      isShowCircle,
      selectedVersion,
      isShowNewNotificationCircle,
      isShowNotificationsCircle,
      notificationLength,
    } = this.state;

    return (
      <div
        className={classNames("release-notes-dropdown", {
          "release-notes-dropdown--mobile": isMobile,
        })}
      >
        <CustomTippy content={v_notifications}>
          <div
            className="release-notes-dropdown__button"
            onClick={this.triggerBellIcon}
          >
            {isShowNewNotificationCircle ||
            (this.props.allNotificationsList.length !== notificationLength &&
              isShowNotificationsCircle) ? (
              <span className="release-notes-dropdown__circle" />
            ) : null}
            <BellIcon className="release-notes-dropdown__bell-icon" />
          </div>
        </CustomTippy>
        {isOpen && (
          <div
            ref={this.dropdown}
            className={classNames("release-notes-dropdown__dropdown")}
          >
            <div className="release-notes-dropdown__dropdown-list">
              {/* {this.props.notificationsList.length > 0
                ? this.props.notificationsList.map((item) => {
                    const { created_at, data, id, iseReead } = item;
                    return (
                      <div
                        key={id}
                        className="release-notes-dropdown__dropdown-list-item"
                        // onClick={() => this.selectVersion(version)}
                      >
                        <div className="release-notes-dropdown__name">
                          {data.text}
                        </div>
                        <div className="release-notes-dropdown__date">
                          {moment(created_at)
                            .locale(lang.short)
                            .format("MMM DD HH:mm")}
                        </div>
                      </div>
                    );
                  })
                : null} */}
              {allNotificationsList.length > 0 ? (
                allNotificationsList.map((item) => {
                  const { created_at, data, id, iseReead } = item;
                  return (
                    <div
                      key={id}
                      className="release-notes-dropdown__dropdown-list-item"
                      // onClick={() => this.selectVersion(version)}
                    >
                      <div className="release-notes-dropdown__name">
                        {data.text}
                        {/* {v_version} {version} */}
                      </div>
                      <div className="release-notes-dropdown__date">
                        {moment(created_at)
                          .locale(lang.short)
                          .format("MMM DD HH:mm")}
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="release-notes-dropdown__not-notifications">
                  {v_not_notifications}
                </span>
              )}
              {/* {versionsList.map((versionListItem) => {
                const { date, version } = versionListItem;

                return (
                  <div
                    key={version}
                    className="release-notes-dropdown__dropdown-list-item"
                    onClick={() => this.selectVersion(version)}
                  >
                    <div className="release-notes-dropdown__name">
                      {v_version} {version}
                    </div>
                    <div className="release-notes-dropdown__date">
                      {formatDropdownDateForRender(date, lang.short)}
                    </div>
                  </div>
                );
              })} */}
            </div>
          </div>
        )}
        {isOpenModal && (
          <ReleaseNotesModal
            closeCallback={this.closeModal}
            selectedVersion={selectedVersion}
          />
        )}
        {/* {isShowCircle && (
          <ReleaseNotesModal
            closeCallback={this.closeModal}
            selectedVersion={selectedVersion}
          />
        )} */}
      </div>
    );
  }
}

ReleaseNotesDropdown.defaultProps = {
  onChangeVisibility: () => {},
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isMobile: state.responsiveReducer.isMobile,
  notificationsList: state.mainPageReducer.notificationsList,
  allNotificationsList: state.mainPageReducer.allNotificationsList,
});
const mapDispatchToProps = {
  getNotificationsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReleaseNotesDropdown);
