import React, { ReactElement } from "react";
import "./style.scss";
import ModalPortal from "../../Unstructured/ModalPortal";

export type TModalClasses = {
  modal?: string;
  blackdrop?: string;
  wrapper?: string;
  container?: string;
  header?: string;
  title?: string;
};

export interface IModal {
  open: boolean;
  onClose: (event: any) => void;
  children: ReactElement;
  title?: string;
  classes?: TModalClasses;
  disablePortal?: boolean;
  headerAdditionalElements?: ReactElement;
  headerStatusInModal?: ReactElement;
}

const Modal = ({
  open,
  onClose,
  children,
  title = "",
  classes,
  disablePortal = false,
  headerAdditionalElements,
  headerStatusInModal,
}: IModal): ReactElement => (
  <ModalPortal disabled={disablePortal}>
    <div
      className={`modal ${classes?.modal ?? ""} ${open ? "modal--open" : ""}`}
      data-theme={localStorage.getItem("themes")}
    >
      <div
        className={`modal__blackdrop ${classes?.blackdrop ?? ""}`}
        onClick={(event) => onClose(event)}
      />
      <div className={`modal__wrapper ${classes?.wrapper ?? ""}`}>
        <div className={`modal__container ${classes?.container ?? ""}`}>
          <div
            className={`modal__header ${classes?.header ?? ""} ${
              title ? "modal__header--bottom-border" : ""
            }`}
          >
            <div className="modal__title-container">
              <div className={`modal__title ${classes?.title ?? ""}`}>
                {title}
                {headerStatusInModal ? headerStatusInModal : null}
              </div>
              {headerAdditionalElements ? headerAdditionalElements : null}
            </div>
            <div
              className="modal__close-icon"
              onClick={(event) => onClose(event)}
            />
          </div>
          <div className="modal__content">{children}</div>
        </div>
      </div>
    </div>
  </ModalPortal>
);

export default Modal;
