import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { sendSupportMessage } from "../../../api/user";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import * as Yup from "yup";
import cn from "classnames";

import "./style.scss";

const SupportModal = ({ vocabulary, showNotificationAction, closeModal }) => {
  const {
    v_tell_us,
    v_send,
    v_reset,
    v_type_message,
    v_min,
    v_max,
    v_message_sent,
    v_message_error,
  } = vocabulary;
  const sendMessageToSupport = async (values) => {
    try {
      await sendSupportMessage(values);
      showNotificationAction({ text: v_message_sent, type: "success" });
      closeModal();
    } catch (error) {
      showNotificationAction({ text: v_message_error, type: "error" });
    }
  };

  return (
    <div>
      <div className="support-wrapper__background" />
      <div className="support-wrapper__container">
        <div
          className="closeStatusModal"
          onClick={() => {
            closeModal();
          }}
        />
        <h2 className="support-wrapper__text">
          <span>{v_tell_us}</span>
        </h2>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ message: "" }}
          onSubmit={(values, { setSubmitting }) => {
            sendMessageToSupport(values);
            setSubmitting(false);
          }}
          validationSchema={Yup.object({
            message: Yup.string().min(8, `${v_min}`).max(500, `${v_max}`),
          })}
        >
          {(formik) => (
            <>
              <Form>
                <div className="support-modal-form">
                  <div className="support-modal-form__input-container">
                    <Field
                      as="textarea"
                      className={cn("support-modal-form__input", {
                        error: formik.errors.message && formik.touched.message,
                      })}
                      placeholder={v_type_message}
                      type={"textarea"}
                      name={"message"}
                      id={"message"}
                    />
                    {formik.touched.message && formik.errors.message && (
                      <div className="errors">{formik.errors.message}</div>
                    )}
                  </div>
                </div>
                <div className="support-modal-form__footer">
                  <button
                    type="submit"
                    className="support-modal-form__btn__send"
                  >
                    <span>{v_send}</span>
                  </button>
                  <button
                    type="reset"
                    className="support-modal-form__btn__cancel"
                  >
                    <span>{v_reset}</span>
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
  sendSupportMessage,
  showNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportModal);
