import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { DateRange } from "react-date-range";
import { enGB, ru, de, it, ua } from "react-date-range/src/locale";
import { usePopper } from "react-popper";
import moment from "moment";

import InputMask from "react-input-mask";
import { checkRangeValidity, compareRanges } from "../../../services/ranges";
import { useOutsideClick } from "../../../services/hookHelpers";

import "./style.scss";

const localeMap = {
  ru: ru,
  en: enGB,
  de: de,
  it: it,
  uk: ua,
};

const DATE_FORMAT = "DD.MM.YYYY";

/**
 * This regular expression allows only
 * string dates like `31-12-20`
 */
const DATE_REGEX = /^[\d-]*$/;

const CalendarIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 3.33331H4.16667C3.24619 3.33331 2.5 4.07951 2.5 4.99998V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V4.99998C17.5 4.07951 16.7538 3.33331 15.8333 3.33331Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 1.66669V5.00002"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66663 1.66669V5.00002"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 8.33331H17.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DateRangeWrapper = ({
  value,
  onChange,
  vocabulary,
  dateFormat,
  firstDayOfWeek,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [internalValue, setInternalValue] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { lang } = vocabulary;
  const customLocale = localeMap[lang.short];

  customLocale.options.weekStartsOn = firstDayOfWeek;

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 10,
        },
      },
    ],
    placement: "top-end",
  });

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setShowDatepicker(false));

  const valueChangeHandler = (item) => {
    setInternalValue([item.selection]);
  };

  /**
   * update text inputs on seleting the date in range picker
   */
  useEffect(() => {
    const { startDate: intStartDate, endDate: intEndDate } = internalValue[0];

    setStartDate(moment(intStartDate).format(DATE_FORMAT));
    setEndDate(moment(intEndDate).format(DATE_FORMAT));
  }, [internalValue]);

  /**
   * Watch for the internal value to call `onChange`
   * callback if provided
   */
  useEffect(() => {
    // skip `onChange` on component initialization
    if (!isInitialized) {
      setIsInitialized(true);
      return;
    }

    // do nothing, if callback was not provided
    if (!onChange) {
      return;
    }

    // do nothing if both external and internal values
    // are the same
    if (compareRanges(internalValue[0], value)) {
      return;
    }

    const { startDate: intStartDate, endDate: intEndDate } = internalValue[0];

    // else, call `onChange`
    onChange({
      startDate: intStartDate,
      endDate: intEndDate,
    });
  }, [internalValue]);

  /**
   * Watch for the external value to update
   * internal value
   */
  useEffect(() => {
    // do nothing if both external and internal values
    // are the same
    if (compareRanges(internalValue[0], value)) {
      return;
    }

    // else, update internal value
    updateRange({ ...value, key: "selection" });
  }, [value]);

  /**
   * handle input date change
   * @param {'startDate' | 'endDate'} key
   * @param {string} dateString
   * @returns {void}
   */
  const inputChangeHandler = (key, dateString) => {
    // select an appropriate method to update the date
    switch (key) {
      case "startDate": {
        setStartDate(dateString);
        break;
      }

      case "endDate": {
        setEndDate(dateString);
        break;
      }

      default:
        break;
    }

    // return if the string date is not OK
    if (!DATE_REGEX.test(dateString)) {
      return;
    }

    // convert string date to Moment.js instance
    const mDate = moment(dateString, DATE_FORMAT);

    // return if the date is not valid (e.g. user entered `99-13-21`)
    if (!mDate.isValid()) {
      return;
    }

    // create new range
    const newRange = {
      ...internalValue[0],
      [key]: mDate.toDate(),
    };

    updateRange(newRange);
  };

  const updateRange = (newRange) => {
    if (!checkRangeValidity(newRange)) {
      return;
    }

    // update the range
    setInternalValue([newRange]);
    return true;
  };

  return (
    <div className="date-range" ref={wrapperRef}>
      <div className="date-range__controls" ref={setReferenceElement}>
        <div className="date-range__controls-row">
          <div className="date-range__controls-col">
            <div className="date-range__input-wrap">
              <InputMask
                className="date-range__input"
                onChange={(e) =>
                  inputChangeHandler("startDate", e.target.value)
                }
                mask={DATE_FORMAT}
                formatChars={{ D: "[0-9]", M: "[0-9]", Y: "[0-9]" }}
                value={startDate}
              />
              <button
                type="button"
                className="date-range__datepicker-toggle"
                onClick={() => setShowDatepicker(!showDatepicker)}
              >
                <CalendarIcon className="date-range__input-icon" />
              </button>
            </div>
          </div>
          <div className="date-range__controls-col">
            <div className="date-range__input-wrap">
              <InputMask
                className="date-range__input"
                onChange={(e) => inputChangeHandler("endDate", e.target.value)}
                mask={DATE_FORMAT}
                formatChars={{ D: "[0-9]", M: "[0-9]", Y: "[0-9]" }}
                value={endDate}
              />
              <button
                type="button"
                className="date-range__datepicker-toggle"
                onClick={() => setShowDatepicker(!showDatepicker)}
              >
                <CalendarIcon className="date-range__input-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDatepicker ? (
        <div
          className="date-range__range-picker"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <DateRange
            locale={customLocale}
            dateDisplayFormat={dateFormat}
            onChange={valueChangeHandler}
            moveRangeOnFirstSelection={false}
            ranges={internalValue}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  dateFormat: state.userReducer.dateFormat,
  firstDayOfWeek: state.userReducer.firstDayOfWeek,
});

export default connect(mapStateToProps)(DateRangeWrapper);
