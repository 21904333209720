import io from "socket.io-client";
import moment from "moment";

import { AppConfig } from "../config";
import { getTokenFromLocalStorage } from "../services/tokenStorageService";
import { logoutByUnauthorized } from "../services/authentication";
import { store } from "../redux/store";
import {
  setCurrentTimerAction,
  setServerClientTimediffAction,
  setNotificationsAction,
  getTimeCountAction,
} from "../redux/actions/MainPageAction";
import {
  decodeTimeEntryIssue,
  encodeTimeEntryIssue,
} from "../services/timeEntryService";

const baseURL = AppConfig.apiURL;

let socket = null;

const socketEmit = (event, data = {}, ack = () => {}) => {
  socket.emit(
    event,
    {
      token: `Bearer ${getTokenFromLocalStorage()}`,
      ...data,
    },
    (res) => {
      ack(res);
    },
  );
};

const setCurrentTimer = (data) => {
  const { currentTimer } = store.getState().mainPageReducer;

  if (data) {
    const currentTimer = {
      id: data.id,
      billable: data.billable,
      timeStart: Number(moment(data.startDatetime)),
      issue: data.issue,
      project: data.project,
    };

    store.dispatch(setServerClientTimediffAction(data.time.timeISO));
    store.dispatch(setCurrentTimerAction(currentTimer));
  } else if (currentTimer) {
    store.dispatch(setCurrentTimerAction(null));
  }
};
export const setNotification = (data) => {
  store.dispatch(setNotificationsAction(data));
};

export const startTimerSocket = ({ issue, projectId, billable }, callback) => {
  socketEmit(
    "start-timer-v2",
    {
      issue: encodeTimeEntryIssue(issue.trim()),
      projectId,
      billable,
    },
    callback,
  );
};

export const stopTimerSocket = (callback) => {
  store.dispatch(getTimeCountAction());
  socketEmit("stop-timer-v2", null, callback);
};

export const updateTimerSocket = ({ issue, projectId, billable }, callback) => {
  const data = {
    issue: encodeTimeEntryIssue(issue.trim()),
    projectId,
    billable,
  };

  socketEmit("update-timer-v2", data, callback);
};

export const initSocket = () => {
  if (!socket) {
    socket = io(baseURL);
    socket.on("switch-team", (res) => {
      if (document.hidden) {
        window.location.reload();
      }
    });
    socket.on("user-unauthorized", () => logoutByUnauthorized());
    socket.on("check-timer-v2", (res) => setCurrentTimer(res));

    socket.on("connect", () =>
      socketEmit("join-v2", null, () => socketEmit("check-timer-v2")),
    );
    socket.on("new-notification", (res) => setNotification(res));
  } else if (socket.disconnected) {
    socket.open();
  }
};

export const closeSocket = () => {
  if (socket && socket.connected) {
    socketEmit("leave-v2");
    socket.close();
  }
};
