import React, { Component } from "react";
import { Link } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import CustomTippy from "../../components/Unstructured/Tooltip/CustomTippy"
import classNames from "classnames";

import { showNotificationAction } from "../../redux/actions/NotificationActions";
import InvoicesManagerModal from "../../components/Unstructured/InvoicesManagerModal";
import InvoicesManagerIconsModal from "../../components/Unstructured/InvoicesManagerIconsModal";
import InvoicesManagerIconDeleteModal from "../../components/Unstructured/InvoicesManagerIconDeleteModal";

// Config
import { AppConfig } from "../../config";

// Services
import {
  spaceAndFixNumber,
  fixNumberHundredths,
  internationalFormatNum,
} from "../../services/numberHelpers";
import { downloadPDF } from "../../services/downloadPDF";

// Styles
import "./style.scss";

// Components
import {
  CopyIcon,
  DeleteIcon,
  SaveIcon,
  SendIcon,
  SaveInvoice,
  CopyLinkIcon,
} from "../../components/Unstructured/InvoicePageComponents/AllInvoicesList";
import DetailedInvoiceProjectsTable from "../../components/Unstructured/DetailedInvoiceProjectsTable";
import CurrencySelect from "../../components/Unstructured/CurrencySelect";
import CalendarSelect from "../../components/Unstructured/CalendarSelect";
import PersonSelect from "../../components/Unstructured/PersonSelect";
import ImagePicker from "../../components/Unstructured/ImagePicker";
import DiscountInvoiceModal from "../../components/Unstructured/DiscountInvoiceModal";
import { downloadTemporaryPDF } from "../../api/invoice";
import { fillFormDataWithObject } from "../../redux/actions/InvoicesActions";
import ModalPortal from "../../components/Unstructured/ModalPortal";
import TextareaAutosizeForMobile from "../../components/Unstructured/TextareaAutosizeForMobileInvoicesManager";
import { Helmet } from "react-helmet";

export const calculateTaxSum = ({ amount, rate, tax, hours }) =>
  (((amount || hours) * rate) / 100) * tax;
export const calculateSubtotal = ({ amount, rate, tax, hours }) =>
  (amount || hours) * rate + calculateTaxSum({ amount, rate, tax, hours }) || 0;
export const calculateSubtotalWithoutTax = ({ amount, rate, tax, hours }) =>
  spaceAndFixNumber((amount || hours) * rate);
export const calculateSubtotals = (projects) =>
  projects.reduce(
    (sum, { amount, rate, hours }) => sum + (amount || hours) * rate,
    0,
  ) || 0;
export const calculateTaxesSum = (projects) =>
  projects.reduce((sum, project) => sum + calculateTaxSum(project), 0) || 0;
export const subtotalWithDiscount = (subtotal, discount = 0) =>
  (subtotal / 100) * discount;
export const calculateTotal = (projects, discount = 0) => {
  const subtotal = calculateSubtotals(projects);

  return (
    subtotal -
    subtotalWithDiscount(subtotal, discount) +
    calculateTaxesSum(projects)
  );
};
export const calculateSubtotalsWithTax = (projects) =>
  projects.reduce((sum, project) => sum + calculateSubtotal(project), 0);
const parseUsersData = (users) => users.data.map((user) => user.user[0]);

class InvoicesManager extends Component {
  state = {
    isInitialFetching: true,
    invoice: {
      invoiceNumber: "",
      reference: "",

      number: "",
      invoiceDate: new Date(),
      dueDate: new Date(),
      timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      discount: 0,
      currency: "usd",
      sender: {
        city: "",
        company_name: "",
        country: "",
        email: "",
        id: "",
        language: "",
        phone: "",
        state: "",
        username: "",
        zip: "",
      },

      client: {
        city: "",
        company_name: "",
        country: "",
        email: "",
        id: "",
        language: "",
        phone: "",
        state: "",
        username: "",
        zip: "",
      },
      recipient: null,
      image: null,
      projects: [],
      comment: "",
      removeFile: false,
    },
    errors: {
      sender: false,
      recipient: false,
      projects: false,
      hours: false,
    },
    sendInvoiceModalData: false,
    deleteInvoiceModal: false,
    linkCopied: false,
    discountModalIsOpen: false,
    resetDetailedInvoice: false,
    addPaymentModal: false,
    editConfirmationWindowFlag: false,
    invoicesManagerModal: false,
    invoicesManagerIconsModal: false,
    invoicesManagerIconDeleteModal: false,
    titleIconModal: "",
    themes: localStorage.setItem("themes", "light"),
    mobileTexarea: false,
  };

  addPaymentModalHandler = () => {
    this.setState({ addPaymentModal: !this.state.addPaymentModal });
  };

  handleInputChange = (name, e) => {
    const value = e.target.value;
    const { invoice } = this.state;

    this.setState({ invoice: { ...invoice, [name]: value } });
  };

  handleUpdateProject = (project) => {
    const { invoice } = this.state;

    invoice.projects.forEach((p, i) => {
      if (p.id === project.id) {
        invoice.projects[i] = project;
      }
    });

    this.setState({ invoice });

    return new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
  };
  handleAddProject = (project) => {
    const projects = [...this.state.invoice.projects, project];

    this.setState((prevState) => ({
      invoice: { ...prevState.invoice, projects },
    }));
    return new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
  };

  handleRemoveProject = (id) => {
    const { invoice } = this.state;

    invoice.projects = invoice.projects.filter((project) => project.id !== id);
    this.setState({ invoice });
  };

  onChangeCurrency = (currency) => {
    const invoice = { ...this.state.invoice };
    const initialInvoice = { ...this.initialInvoice };

    invoice.currency = currency;
    initialInvoice.currency = currency;

    this.setState({ invoice });
  };

  handleDateChange = (name, value) => {
    const { invoice } = this.state;

    invoice[name] = value;
    this.setState({ invoice });
  };

  handlePersonChange = (name, value) => {
    const { invoice } = this.state;

    invoice[name] = value;
    invoice.sender.id = "2";
    this.setState({ invoice });
  };

  handleFileLoad = (image) => {
    const { invoice } = this.state;

    this.setState({ invoice: { ...invoice, image } });
    this.setState({ loadedImage: undefined });
  };

  handleFileDelete = () => {
    const { invoice } = this.state;

    this.setState({ invoice: { ...invoice, image: null, removeFile: true } });
  };

  validateProjects = (projectList) =>
    Boolean(projectList.length) &&
    projectList.every((project) => project.project_name && project.hours);

  closeDiscountModal = () => {
    this.setState({ discountModalIsOpen: false });
  };

  saveDiscount = (discount) => {
    if (discount || discount === 0) {
      const { invoice } = this.state;

      invoice.discount = discount;

      this.setState({ invoice });
    }
    this.closeDiscountModal();
  };

  cancelHandler = () => {
    this.setState({
      invoicesManagerModal: false,
    });
  };

  cancelHandlerInvoicesIconsModal = () => {
    this.setState({
      invoicesManagerIconsModal: false,
    });
  };

  cancelHandlerInvoicesIconDeleteModal = () => {
    this.setState({
      invoicesManagerIconDeleteModal: false,
    });
  };

  openInvoicesManagerModal = () => {
    this.setState({
      invoicesManagerModal: true,
    });
  };

  openInvoicesManagerIconDeleteModal = () => {
    this.setState({
      invoicesManagerIconDeleteModal: true,
    });
  };

  openInvoicesManagerIconsModal = (title) => {
    this.setState({
      invoicesManagerIconsModal: true,
      titleIconModal: title,
    });
  };

  cancelMobileTextarea = () => {
    this.setState({
      mobileTexarea: false,
    });
  };

  deleteInvoice = () => {
    const { invoice } = this.state;

    this.setState({
      invoice: {
        ...invoice,

        invoiceNumber: "",
        reference: "",
        number: "",
        invoiceDate: new Date(),
        dueDate: new Date(),
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
        discount: 0,
        currency: "usd",
        sender: {
          city: "",
          company_name: "",
          country: "",
          email: "",
          id: "",
          language: "",
          phone: "",
          state: "",
          username: "",
          zip: "",
        },
        client: {
          city: "",
          company_name: "",
          country: "",
          email: "",
          id: "",
          language: "",
          phone: "",
          state: "",
          username: "",
          zip: "",
        },
        recipient: null,
        image: null,
        removeFile: true,
        projects: [],
        comment: "",
      },
      invoicesManagerIconDeleteModal: false,
    });
    this.setState({ loadedImage: null });
  };

  downloadPDF = async () => {
    const { invoice } = this.state;

    try {
      let requestBody = invoice;
      let formData = invoice.image;

      if (formData instanceof FormData) {
        requestBody = fillFormDataWithObject(formData, requestBody);
      }
      const responce = await downloadTemporaryPDF(requestBody);

      downloadPDF(responce.data, `invoice-${invoice.invoiceNumber}.pdf`);

      this.deleteInvoice();

      this.cancelHandler();
    } catch (error) {
      console.log(error);
      showNotificationAction({
        type: "error",
        text: error.message,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({
        mobileTexarea: false,
      });
    }
  }

  render() {
    const { vocabulary, isMobile } = this.props;
    const {
      v_invoice,
      v_tax,
      v_subtotal,
      v_invoice_summary,
      v_total,
      v_comments,
      v_save,
      v_cancel,
      v_invoice_number,
      v_invoice_date,
      v_invoice_due,
      v_from,
      v_to,
      v_add_client,
      v_select_logo_file,
      v_enter_number,
      v_download,
      v_delete,
      v_edit,
      v_clone,
      v_send_by_email,
      v_link_copied,
      v_copy_link,
      v_add_a_discount,
      v_discount,
      v_invoice_reference,
      v_edit_confirmation_window,
      v_confirm,
    } = vocabulary;
    const {
      isInitialFetching,
      discountModalIsOpen,
      invoice,
      errors,
      linkCopied,
      resetDetailedInvoice,
      invoicesManagerModal,
      invoicesManagerIconsModal,
      titleIconModal,
      invoicesManagerIconDeleteModal,
    } = this.state;

    return (
      <>
      <Helmet>
        <title>Free Invoice Generator – Create Professional Invoices Online | Wobbly</title>
        <link rel="canonical" href="https://time.wobbly.me/free-invoice-generator" />
        <link rel="description" content="Wobbly Time's Free Invoice Generator enables you to create professional, customized invoices with just a few clicks. Perfect for freelancers, small business owners, or anyone in need of a reliable invoicing solution." />
      </Helmet>
      <div
        className="invoices-page-detailed-wrapper invoices-manager-wrapper"
        data-theme={"light"}
      >
        <div className="invoices-manager-logo">
          <Link to="/">
            <i className="invoices-manager-logo__small-light" />
          </Link>
        </div>
        <div
          className={classNames("invoices-page-detailed-wrapper__header", {})}
        >
          <div className="invoices-page-detailed__title-free">{v_invoice}</div>
        </div>

        <div className="invoices-page-detailed-form-wrappers">
          <div className="invoices-page-detaileds">
            <div className="invoices-page-detailed__top">
              <div className="invoices-page-detailed__main-data">
                <div className="invoices-page-detailed__left">
                  <div
                    className={classNames(
                      "invoices-page-detailed__logo",
                      {
                        "invoices-page-detailed__logo--empty": !invoice.image,
                      },
                      {
                        "invoices-page-detailed__logo--empty__light":
                          localStorage.getItem("themes") === "light",
                      },
                    )}
                  >
                    <ImagePicker
                      onFileLoaded={this.handleFileLoad}
                      onDeleteImage={this.handleFileDelete}
                      placeholder={v_select_logo_file}
                      imageState={this.state.loadedImage}
                      imageUrl={
                        invoice.image && typeof invoice.image === "string"
                          ? `${AppConfig.apiURL}${invoice.image}`
                          : null
                      }
                    />
                  </div>
                  <div className="invoices-page-detailed__main-data-form">
                    <div className="input-wrapper">
                      <div>
                        <label>{`${v_invoice_number}:`}</label>
                        <div className="invoice-number">
                          <input
                            value={invoice.invoiceNumber}
                            onChange={(e) =>
                              e.target.value.length <= 15
                                ? this.handleInputChange("invoiceNumber", e)
                                : null
                            }
                            className="invoices-page-detailed__input"
                            type="text"
                            placeholder={v_enter_number}
                            disabled={this.isViewMode}
                          />
                        </div>
                      </div>
                      <div className="input-wrapper__second-input">
                        <label>{`${v_invoice_date}:`}</label>
                        <div className="invoices-page-detailed__calendar-select">
                          <CalendarSelect
                            onChangeDate={(date) =>
                              this.handleDateChange("invoiceDate", date)
                            }
                            date={invoice.invoiceDate}
                            disabled={this.isViewMode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <div>
                        <label>{`${v_invoice_reference}:`}</label>
                        <div className="invoice-reference">
                          <textarea
                            value={invoice.reference}
                            onChange={(e) =>
                              this.handleInputChange("reference", e)
                            }
                            className="invoices-page-detailed__input"
                            type="text"
                            disabled={this.isViewMode}
                            placeholder={v_invoice_reference}
                            maxLength={400}
                          />
                        </div>
                      </div>
                      <div className="input-wrapper__second-input">
                        <label>{`${v_invoice_due}:`}</label>
                        <div className="invoices-page-detailed__calendar-select">
                          <CalendarSelect
                            onChangeDate={(date) =>
                              this.handleDateChange("dueDate", date)
                            }
                            date={invoice.dueDate}
                            disabled={this.isViewMode}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoices-page-detailed__personal-information">
                <div className="invoices-page-detailed__personal-information-card">
                  <div className="invoices-page-detailed__subtitle">
                    {v_from}
                  </div>
                  <PersonSelect
                    invoice={invoice}
                    userSender={invoice.sender}
                    onChange={(person) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        errors: {
                          ...prevState.errors,
                          sender: false,
                        },
                      }));
                      this.handlePersonChange("sender", person);
                    }}
                  />
                </div>
                <div className="invoices-page-detailed__personal-information-card">
                  <div className="invoices-page-detailed__subtitle">{v_to}</div>
                  <PersonSelect
                    invoice={invoice}
                    userSender={invoice.client}
                    onChange={(person) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        errors: {
                          ...prevState.errors,
                          sender: false,
                        },
                      }));
                      this.handlePersonChange("client", person);
                    }}
                    placeholder={
                      invoice.client.company_name.length === 0 ? "aaa" : ""
                    }
                  />
                </div>
              </div>
            </div>
            <DetailedInvoiceProjectsTable
              reset={resetDetailedInvoice}
              mode={"update"}
              vocabulary={vocabulary}
              projects={invoice.projects}
              currency={invoice.currency}
              updateProject={this.handleUpdateProject}
              addProject={this.handleAddProject}
              removeProject={this.handleRemoveProject}
              onChangeInput={() => {
                this.setState((prevState) => ({
                  ...prevState,
                  errors: {
                    ...prevState.errors,
                    projects: false,
                  },
                }));
              }}
            />
            <div className="invoices-page-detailed__wrapper">
              <div className="invoices-page-detailed__wrapper-summary">
                <div className="invoices-page-detailed__subtitle">
                  {v_invoice_summary}
                </div>
                <div className="invoices-page-detailed__summary-table">
                  <div className="invoices-page-detailed__summary-table-row">
                    <span className="invoices-page-detailed__summary-title">
                      {v_subtotal}
                    </span>
                    <div className="invoices-page-detailed__summary-price">
                      <div>{invoice.currency.toUpperCase()}</div>
                      <span>
                        {internationalFormatNum(
                          fixNumberHundredths(
                            spaceAndFixNumber(
                              calculateSubtotals(invoice.projects),
                            ),
                          ),
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="invoices-page-detailed__summary-table-line">
                    <hr />
                  </div>
                  <div className="invoices-page-detailed__summary-table-row">
                    <button
                      className="invoices-page-detailed__summary-discount"
                      onClick={() => {
                        if (this.isViewMode) {
                          return;
                        }
                        this.setState({
                          discountModalIsOpen: true,
                        });
                      }}
                    >
                      {invoice.discount
                        ? `${invoice.discount}% ${v_discount}`
                        : v_add_a_discount}
                    </button>
                    {invoice.discount || this.isViewMode ? (
                      <div className="invoices-page-detailed__summary-price">
                        {invoice.currency.toUpperCase()}
                        <span>
                          -
                          {internationalFormatNum(
                            fixNumberHundredths(
                              spaceAndFixNumber(
                                subtotalWithDiscount(
                                  calculateSubtotals(invoice.projects),
                                  invoice.discount,
                                ),
                              ),
                            ),
                          )}
                        </span>
                      </div>
                    ) : null}

                    {invoice.discount ? (
                      <button
                        className={classNames(
                          "invoices-page-detailed__tool-button",
                          "invoices-page-detailed__tool-discount",
                          {
                            disabled: !this.isViewMode,
                          },
                        )}
                        onClick={() => {
                          if (this.isViewMode) {
                            return;
                          }
                          this.saveDiscount(0);
                        }}
                      >
                        {!this.isViewMode && (
                          <DeleteIcon
                            className={classNames(
                              "invoices-page-detailed__icon-button",
                              {
                                disabled: !this.isViewMode,
                              },
                            )}
                          />
                        )}
                      </button>
                    ) : null}
                    {discountModalIsOpen && (
                      <DiscountInvoiceModal
                        saveDiscount={this.saveDiscount}
                        closeModal={this.closeDiscountModal}
                        initDiscount={invoice.discount}
                      />
                    )}
                  </div>

                  <div className="invoices-page-detailed__summary-table-row">
                    <span className="invoices-page-detailed__summary-title">
                      {v_tax}
                    </span>
                    <div className="invoices-page-detailed__summary-price">
                      {invoice.currency.toUpperCase()}
                      <span>
                        {internationalFormatNum(
                          fixNumberHundredths(
                            spaceAndFixNumber(
                              calculateTaxesSum(invoice.projects),
                            ),
                          ),
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="invoices-page-detailed__summary-table-row">
                    <div className="invoices-page-detailed__summary-total">
                      <span className="invoices-page-detailed__summary-title">
                        {v_total}
                      </span>
                      <CurrencySelect
                        selectedCurrency={invoice.currency}
                        onChange={this.onChangeCurrency}
                      />
                    </div>

                    <div className="invoices-page-detailed__summary-price">
                      {invoice.currency.toUpperCase()}
                      <span>
                        {internationalFormatNum(
                          fixNumberHundredths(
                            spaceAndFixNumber(
                              calculateTotal(
                                invoice.projects,
                                invoice.discount,
                              ),
                            ),
                          ),
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="invoices-page-detailed__comments"
              onClick={() =>
                isMobile
                  ? this.setState({ mobileTexarea: !this.state.mobileTexarea })
                  : null
              }
            >
              <div className="invoices-page-detailed__subtitle">
                {v_comments}
              </div>

              <TextareaAutosize
                value={invoice.comment || ""}
                onChange={(e) => this.handleInputChange("comment", e)}
                className="invoices-page-detailed__input invoices-page-detailed__textarea"
              />
            </div>

            <div className="invoices-page-detailed__actions">
              <button
                onClick={this.openInvoicesManagerModal}
                className="invoices-page-detailed__action-button-manager"
              >
                {v_save}
              </button>
              <button
                onClick={this.openInvoicesManagerModal}
                className="invoices-page-detailed__action-button-manager"
              >
                {v_download}
              </button>
            </div>
          </div>
          <div className="invoices-page-detailed__tools">
            <div className="invoices-page-detailed__tools-container">
              <CustomTippy content={v_download}>
                <button
                  className={classNames("invoices-page-detailed__tool-button")}
                >
                  <SaveIcon
                    className={classNames(
                      "invoices-page-detailed__icon-button",
                      {},
                    )}
                    onClick={this.openInvoicesManagerModal}
                  />
                </button>
              </CustomTippy>
              <CustomTippy content={v_save}>
                <div className="invoices-page-detailed__tool-button">
                  <SaveInvoice
                    className="invoices-page-detailed__icon-button"
                    onClick={this.openInvoicesManagerModal}
                  />
                </div>
              </CustomTippy>
              <CustomTippy content={v_clone}>
                <button
                  className={classNames("invoices-page-detailed__tool-button")}
                  onClick={() => this.openInvoicesManagerIconsModal("clone")}
                >
                  <CopyIcon
                    className={classNames(
                      "invoices-page-detailed__icon-button",
                    )}
                  />
                </button>
              </CustomTippy>
              <CustomTippy content={v_send_by_email}>
                <button
                  className={classNames("invoices-page-detailed__tool-button")}
                >
                  <SendIcon
                    className={classNames(
                      "invoices-page-detailed__icon-button",
                    )}
                    onClick={() =>
                      this.openInvoicesManagerIconsModal("send by email")
                    }
                  />
                </button>
              </CustomTippy>
              <CustomTippy content={v_copy_link}>
                <button
                  className={classNames("invoices-page-detailed__tool-button")}
                  onClick={() =>
                    this.openInvoicesManagerIconsModal("copy link")
                  }
                >
                  <CopyLinkIcon
                    className={classNames(
                      "invoices-page-detailed__icon-button icon-fill",
                    )}
                  />
                </button>
              </CustomTippy>
              <CustomTippy content={v_delete}>
                <button
                  className={classNames("invoices-page-detailed__tool-button")}
                  onClick={this.openInvoicesManagerIconDeleteModal}
                >
                  <DeleteIcon
                    className={classNames(
                      "invoices-page-detailed__icon-button",
                    )}
                  />
                </button>
              </CustomTippy>
            </div>
          </div>
        </div>

        {invoicesManagerModal && (
          <ModalPortal>
            <InvoicesManagerModal
              cancelHandler={this.cancelHandler}
              vocabulary={vocabulary}
              downloadPDF={this.downloadPDF}
            />
          </ModalPortal>
        )}

        {invoicesManagerIconsModal && (
          <ModalPortal>
            <InvoicesManagerIconsModal
              cancelHandler={this.cancelHandlerInvoicesIconsModal}
              vocabulary={vocabulary}
              title={titleIconModal}
            />
          </ModalPortal>
        )}

        {invoicesManagerIconDeleteModal && (
          <ModalPortal>
            <InvoicesManagerIconDeleteModal
              cancelHandler={this.cancelHandlerInvoicesIconDeleteModal}
              vocabulary={vocabulary}
              deleteInvoice={this.deleteInvoice}
            />
          </ModalPortal>
        )}
        {isMobile && this.state.mobileTexarea && (
          <ModalPortal>
            <TextareaAutosizeForMobile
              value={invoice.comment}
              cancelHandler={this.cancelMobileTextarea}
              handleInputChange={(e) => this.handleInputChange("comment", e)}
            />
          </ModalPortal>
        )}
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  defaultUserSender: state.userReducer.user,
  currentTeamDetailedData: state.teamReducer.currentTeamDetailedData,
  vocabulary: state.languageReducer.vocabulary,
  isMobile: state.responsiveReducer.isMobile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesManager);
