import React from "react";

export const VisaSVG = () => (
  <svg
    width="46"
    height="27"
    viewBox="0 0 46 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.98419C0 1.78379 1.79086 0 4 0H42C44.2091 0 46 1.78378 46 3.98419V23.0158C46 25.2162 44.2091 27 42 27H4C1.79086 27 0 25.2162 0 23.0158V3.98419Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86181 8.9964L9.86225 8.99914H8.19531V9.28134V9.28221C9.49617 9.32355 9.88459 9.93537 10.0284 10.6476L10.7954 18.2502H13.3193L18.7119 8.9964H16.188L12.9469 14.5585L12.3857 8.9964H9.86181Z"
      fill="#0061B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0433 8.99304H22.493L19.4887 18.2559H17.0391L20.0433 8.99304Z"
      fill="#0061B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.5462 8.99304L28.8086 18.0714H31.4664L32.1577 16.6853H35.2428L35.3486 18.0714H37.7983L37.1052 8.99304C35.9189 8.99304 34.7325 8.99304 33.5462 8.99304ZM34.8334 11.3227L35.1265 15.161H32.9183L34.8334 11.3227Z"
      fill="#0061B2"
    />
    <path
      d="M23.0938 11.1131C23.4614 9.64426 24.6681 8.64148 26.7221 8.64148C28.6462 8.64148 30.0731 9.84262 30.0731 9.84262L28.6172 11.3672C28.6172 11.3672 27.541 10.5355 26.907 10.5355C26.0688 10.5355 25.6903 10.8415 25.6128 11.344C25.4584 12.346 29.0305 12.9593 28.9175 15.294C28.842 16.8531 27.2277 18.3662 25.3818 18.3662C22.6137 18.3662 21.2451 17.2574 21.2451 17.2574L22.4931 15.4787C22.4931 15.4787 23.7439 16.4802 24.9197 16.4257C25.7128 16.389 26.3242 16.0852 26.2832 15.548C26.19 14.3304 22.4284 13.7715 23.0938 11.1131Z"
      fill="#0061B2"
    />
  </svg>
);

export const MastercardSVG = () => (
  <svg
    width="46"
    height="27"
    viewBox="0 0 46 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.98419C0 1.78379 1.79086 0 4 0H42C44.2091 0 46 1.78378 46 3.98419V23.0158C46 25.2162 44.2091 27 42 27H4C1.79086 27 0 25.2162 0 23.0158V3.98419Z"
      fill="white"
    />
    <path
      d="M26.1477 19.8854H19.0391V7.06961H26.1477V19.8854Z"
      fill="#FF5F00"
    />
    <path
      d="M19.4923 13.4727C19.4923 10.871 20.7064 8.55432 22.5972 7.0617C21.2178 5.96815 19.4716 5.32031 17.5757 5.32031C13.0862 5.32031 9.44922 8.96894 9.44922 13.4727C9.44922 17.9765 13.0862 21.6251 17.5757 21.6251C19.4716 21.6251 21.2178 20.9721 22.5972 19.8837C20.7064 18.3911 19.4923 16.0744 19.4923 13.4727Z"
      fill="#EB001B"
    />
    <path
      d="M35.7378 13.4727C35.7378 17.9765 32.1008 21.6251 27.6114 21.6251C25.7154 21.6251 23.9744 20.9721 22.5898 19.8837C24.4807 18.3911 25.6947 16.0744 25.6947 13.4727C25.6947 10.871 24.4807 8.55432 22.5898 7.0617C23.9744 5.96815 25.7154 5.32031 27.6114 5.32031C32.1008 5.32031 35.7378 8.97412 35.7378 13.4727Z"
      fill="#F79E1B"
    />
  </svg>
);

export const PayPalSVG = () => (
  <svg
    width="46"
    height="27"
    viewBox="0 0 46 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.98419C0 1.78379 1.79086 0 4 0H42C44.2091 0 46 1.78378 46 3.98419V23.0158C46 25.2162 44.2091 27 42 27H4C1.79086 27 0 25.2162 0 23.0158V3.98419Z"
      fill="white"
    />
    <path
      d="M29.4309 11.3607C29.3178 12.2653 28.7505 12.2653 28.2021 12.2653H27.8899L28.1089 10.5766C28.1223 10.4745 28.1942 10.3994 28.2791 10.3994H28.4224C28.7959 10.3994 29.1487 10.3994 29.3307 10.6584C29.4391 10.8133 29.472 11.0432 29.4309 11.3607ZM29.1921 9.00061H27.1227C26.9811 9.00061 26.8606 9.12611 26.8383 9.29629L26.0021 15.7574C25.9874 15.8721 26.0517 15.9796 26.1461 15.9976C26.1548 15.9992 26.1636 16 26.1724 16H27.2343C27.3333 16 27.4176 15.9123 27.433 15.7933L27.6705 13.9609C27.6925 13.7909 27.8129 13.6652 27.9544 13.6652H28.6091C29.9725 13.6652 30.7591 12.8624 30.9646 11.2701C31.0571 10.5743 30.9683 10.027 30.7006 9.64401C30.4064 9.22304 29.8847 9 29.1924 9"
      fill="#009CDE"
    />
    <path
      d="M11.9518 11.3233C11.8215 12.2136 11.1681 12.2136 10.536 12.2136H10.1764L10.4287 10.5515C10.4441 10.4511 10.5269 10.3771 10.6248 10.3771H10.7898C11.22 10.3771 11.6263 10.3771 11.8359 10.632C11.9612 10.7844 11.999 11.0108 11.9518 11.3233ZM11.6767 9.0004H9.29317C9.13027 9.0004 8.99149 9.12392 8.96582 9.29142L8.00248 15.6505C7.9853 15.7632 8.05923 15.8691 8.16751 15.8869C8.17774 15.8885 8.18816 15.8895 8.19858 15.8895H9.33679C9.4997 15.8895 9.63828 15.766 9.66376 15.5985L9.92414 13.883C9.94942 13.7155 10.0882 13.592 10.2511 13.592H11.0052C12.5756 13.592 13.4816 12.8015 13.7182 11.2343C13.8248 10.5495 13.7225 10.0108 13.4142 9.63384C13.0753 9.21952 12.4744 9 11.6771 9L11.6767 9.0004ZM17.2105 13.6064C17.0999 14.285 16.5826 14.7405 15.9223 14.7405C15.5912 14.7405 15.326 14.6297 15.1558 14.4198C14.9867 14.2119 14.9234 13.9155 14.9769 13.5859C15.0796 12.9131 15.6057 12.4432 16.256 12.4432C16.5803 12.4432 16.843 12.555 17.0169 12.7667C17.1917 12.98 17.2604 13.278 17.2105 13.6064ZM18.8011 11.2946H17.6598C17.5617 11.2944 17.4784 11.3687 17.4631 11.4695L17.4133 11.8015L17.3334 11.6812C17.0863 11.308 16.5353 11.1829 15.9852 11.1829C14.7242 11.1829 13.6472 12.1775 13.4376 13.5715C13.3285 14.2676 13.4833 14.9323 13.8626 15.3963C14.2108 15.8225 14.7076 16 15.3 16C16.3166 16 16.8802 15.3208 16.8802 15.3208L16.8292 15.6507C16.8121 15.7634 16.886 15.8693 16.9943 15.8871C17.0047 15.8887 17.0151 15.8897 17.0255 15.8897H18.0532C18.2161 15.8897 18.355 15.7666 18.3805 15.5987L18.9976 11.5336C19.0146 11.4207 18.9404 11.3149 18.8318 11.2972C18.8215 11.2956 18.8111 11.2948 18.8009 11.2948"
      fill="#003087"
    />
    <path
      d="M35.0814 13.5155C34.9629 14.2199 34.4083 14.6927 33.7004 14.6927C33.3455 14.6927 33.0612 14.5777 32.8787 14.3598C32.6974 14.144 32.6295 13.8364 32.6868 13.4943C32.7969 12.7959 33.3606 12.3081 34.0582 12.3081C34.4058 12.3081 34.6874 12.4242 34.8739 12.6439C35.0614 12.8653 35.1352 13.1747 35.0814 13.5155ZM36.7867 11.1159H35.5632C35.458 11.1157 35.3687 11.1928 35.3523 11.2975L35.2989 11.6421L35.2133 11.5172C34.9484 11.1299 34.3576 11 33.7678 11C32.4164 11 31.2615 12.0323 31.0366 13.4793C30.9197 14.2018 31.086 14.8918 31.4927 15.3734C31.866 15.8157 32.3986 16 33.0337 16C34.1238 16 34.728 15.295 34.728 15.295L34.6734 15.6375C34.6545 15.754 34.7332 15.8639 34.8488 15.8826C34.8602 15.8845 34.8716 15.8853 34.8832 15.8853H35.9847C36.1593 15.8853 36.3081 15.7576 36.3358 15.5833L36.9974 11.3638C37.0156 11.2466 36.9361 11.1368 36.8196 11.1184C36.8087 11.1167 36.7975 11.1159 36.7865 11.1159"
      fill="#009CDE"
    />
    <path
      d="M25.7843 11.0005H24.5364C24.4171 11.0005 24.3057 11.0644 24.2389 11.1711L22.5176 13.9081L21.7879 11.2779C21.7421 11.1131 21.6019 11.0002 21.4426 11H20.2162C20.0968 11 20 11.1047 20 11.2337C20 11.2593 20.004 11.2847 20.0115 11.3089L21.3853 15.6633L20.0932 17.6319C20.0244 17.7373 20.0477 17.883 20.1453 17.9574C20.1816 17.985 20.2252 18 20.2697 18H21.5165C21.634 18 21.7446 17.9377 21.8122 17.8329L25.9613 11.3667C26.0293 11.2611 26.0054 11.1158 25.9074 11.0422C25.8715 11.0152 25.8284 11.0005 25.7843 11.0005Z"
      fill="#003087"
    />
    <path
      d="M37.7919 9.17794L37.002 15.7574C36.9882 15.8721 37.0481 15.9796 37.1358 15.9976C37.1441 15.9992 37.1523 16 37.1606 16H37.9549C38.0864 16 38.1984 15.8745 38.2192 15.7043L38.998 9.24283C39.0119 9.12835 38.9522 9.02081 38.8648 9.00265C38.8563 9.00102 38.8479 9 38.8395 9H37.9508C37.8718 8.9998 37.8043 9.07509 37.7923 9.17733"
      fill="#009CDE"
    />
  </svg>
);

export const AmericanExpSVG = () => (
  <svg
    width="46"
    height="27"
    viewBox="0 0 46 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.98419C0 1.78379 1.79086 0 4 0H42C44.2091 0 46 1.78378 46 3.98419V23.0158C46 25.2162 44.2091 27 42 27H4C1.79086 27 0 25.2162 0 23.0158V3.98419Z"
      fill="white"
    />
    <path
      d="M23.6683 11.9865V11.129C23.6683 11.129 23.6196 10.4579 22.8759 10.4579H21.7189V11.9865H20.5312V7.58021H23.4152C23.4152 7.58021 24.9207 7.40904 24.9207 8.8699C24.9207 9.63083 24.2553 9.8986 24.2553 9.8986C24.2553 9.8986 24.8334 10.1528 24.8334 10.8976V11.9857L23.6683 11.9865ZM21.7189 9.42153H22.947C23.3036 9.42153 23.5971 9.23511 23.5971 9.00378C23.5971 8.77245 23.3036 8.58603 22.947 8.58603H21.7189V9.42153Z"
      fill="url(#paint0_radial)"
    />
    <path
      d="M37.6797 11.9898L35.7942 9.04517V11.9898H34.7723H34.5814H33.4658L33.0453 11.0653H30.8744L30.4621 11.9898H29.369H29.1709H28.6C28.6 11.9898 27 11.7737 27 9.91033C27 7.46483 28.8143 7.56143 28.8773 7.54703L30.3432 7.58431V8.57573L29.1394 8.59013C29.1394 8.59013 28.3551 8.59013 28.2588 9.55189C28.248 9.66205 28.2426 9.76374 28.2435 9.85779C28.248 11.3475 29.6175 10.8848 29.6616 10.8712L31.1347 7.58346H32.7977L34.5814 11.5796V7.58346H36.2615L38.1236 10.484V7.58346H39.3202V11.9889H37.6797V11.9898ZM31.3814 9.93999H32.5375L31.9675 8.62742L31.3814 9.93999Z"
      fill="url(#paint1_radial)"
    />
    <path
      d="M14.3459 11.98V8.93799L12.8567 11.9792H11.8824L10.3851 8.95324V11.98H9.34689H9.18842H8.03951L7.62802 11.0556H5.45715L5.03756 11.98H3.73828L5.71106 7.57373H7.38311L9.18932 11.6216V7.57373H11.0667L12.374 10.3099L13.6742 7.57373H15.5516V11.9792H14.3459V11.98ZM5.96408 9.93025H7.1211L6.54304 8.61768L5.96408 9.93025Z"
      fill="url(#paint2_radial)"
    />
    <path
      d="M16.2061 11.9792V7.57373H19.9139V8.60158H17.4027V9.28032H19.8509V10.2794H17.4027V11.025H19.9139V11.9792H16.2061Z"
      fill="url(#paint3_radial)"
    />
    <path
      d="M25.3906 11.9909V7.57019H26.5864V11.9909H25.3906Z"
      fill="url(#paint4_radial)"
    />
    <path
      d="M28.2591 19.4477V18.5902C28.2591 18.5902 28.2114 17.9199 27.4667 17.9199H26.3106V19.4477H25.1221V15.0414H28.0061C28.0061 15.0414 29.5115 14.8702 29.5115 16.3311C29.5115 17.0912 28.8461 17.3598 28.8461 17.3598C28.8461 17.3598 29.417 17.6132 29.417 18.358V19.4469L28.2591 19.4477ZM26.3097 16.8836H27.5379C27.8935 16.8836 28.187 16.6972 28.187 16.4658C28.187 16.2345 27.8935 16.0481 27.5379 16.0481H26.3097V16.8836Z"
      fill="url(#paint5_radial)"
    />
    <path
      d="M20.349 19.4511H19.3108L18.052 18.1462L16.7834 19.4511H16.063H12.3633V15.0372H16.063H16.6888L18.052 16.4387L19.4224 15.044H20.3337V15.0363H23.2167C23.2167 15.0363 24.7222 14.8796 24.7222 16.3328C24.7222 17.6301 24.2225 18.0335 22.6855 18.0335H21.5213V19.4503L20.349 19.4511ZM18.8597 17.2819L20.3327 18.8173V15.7524L18.8597 17.2819ZM13.5599 18.497H16.063L17.2354 17.2819L16.063 16.0735H13.5599V16.7514H16V17.7513H13.5599V18.497ZM21.5213 16.8853H22.7485C23.1051 16.8853 23.3986 16.6989 23.3986 16.4675C23.3986 16.2371 23.1051 16.0498 22.7485 16.0498H21.5213V16.8853Z"
      fill="url(#paint6_radial)"
    />
    <path
      d="M34.2511 19.4309V18.4166H36.1059C36.1059 18.4166 36.7713 18.4912 36.7713 18.0675C36.7713 17.6709 35.7655 17.7014 35.7655 17.7014C35.7655 17.7014 34.125 17.8362 34.125 16.3897C34.125 14.9509 35.6062 15.0322 35.6062 15.0322H37.8887V16.0609H36.051C36.051 16.0609 35.4162 15.9415 35.4162 16.3745C35.4162 16.7388 36.2797 16.6863 36.2797 16.6863C36.2797 16.6863 38.094 16.56 38.094 17.887C38.094 19.3097 36.9253 19.436 36.5111 19.436C35.7584 19.4431 35.0041 19.4309 34.2511 19.4309Z"
      fill="url(#paint7_radial)"
    />
    <path
      d="M30 19.4348V15.0352H33.707V16.0572H31.1957V16.7351H33.6439V17.735H31.1957V18.4806H33.707V19.4348H30Z"
      fill="url(#paint8_radial)"
    />
    <path
      d="M38.4454 19.4309V18.4166H40.2921C40.2921 18.4166 40.9656 18.4912 40.9656 18.0675C40.9656 17.6709 39.9599 17.7014 39.9599 17.7014C39.9599 17.7014 38.3193 17.8362 38.3193 16.3897C38.3193 14.9509 39.8005 15.0322 39.8005 15.0322H42.0749V16.0609H40.2444C40.2444 16.0609 39.6105 15.9415 39.6105 16.3745C39.6105 16.7388 40.4749 16.6863 40.4749 16.6863C40.4749 16.6863 42.2883 16.56 42.2883 17.887C42.2883 19.3097 41.1196 19.436 40.7054 19.436C39.9528 19.443 39.1984 19.4309 38.4454 19.4309Z"
      fill="url(#paint9_radial)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.05467 -0.82987) scale(46.1077 43.3918)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.04485 -0.826657) scale(46.1046 43.3889)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint2_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.05864 -0.835725) scale(46.105 43.3892)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint3_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.05418 -0.836572) scale(46.1084 43.3924)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint4_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.03382 -0.839901) scale(46.1061 43.3899)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint5_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.05888 -0.838392) scale(46.1046 43.3887)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint6_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.07196 -0.829487) scale(46.1072 43.3913)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint7_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.06305 -0.84036) scale(46.1043 43.3885)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint8_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.05652 -0.844879) scale(46.1066 43.3907)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
      <radialGradient
        id="paint9_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.06618 -0.84036) scale(46.103 43.3874)"
      >
        <stop stopColor="#00B1E7" />
        <stop offset="0.427" stopColor="#0098CD" />
        <stop offset="0.5244" stopColor="#008DBE" />
        <stop offset="0.8403" stopColor="#006C91" />
        <stop offset="1" stopColor="#005F80" />
      </radialGradient>
    </defs>
  </svg>
);
