import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import cn from "classnames";

import ProjectsContainer from "../../../components/Unstructured/ProjectsContainer";
import ReportsDecimalModal from "./ReportsDecimalModal";

import { Loading } from "../../../components/Unstructured/Loading";

export class UnitedReportsComponents extends Component {
  state = {
    exportModalFlag: false,
    openModalDecimal: false,
  };

  closeModalDecimal = (value) => {
    this.setState({ openModalDecimal: value });
  };

  openModalDecimal = () => {
    this.setState({ openModalDecimal: true });
  };

  closeOnClickOutside = (e) => {
    if (!document.getElementById("exportBlock").contains(e.target)) {
      this.setState({ exportModalFlag: false });
    }
  };

  exportHandler = (e) => {
    e.stopPropagation();
    this.setState({ exportModalFlag: !this.state.exportModalFlag });
    window.addEventListener("click", this.closeOnClickOutside);
  };

  componentDidUpdate() {
    if (!this.state.exportModalFlag) {
      window.removeEventListener("click", this.closeOnClickOutside);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeOnClickOutside);
  }

  render() {
    const { exportModalFlag, openModalDecimal } = this.state;
    const {
      v_export,
      v_detailed_export,
      v_simple_export,
      v_total_time,
      v_total_billable,
      v_total_not_billable,
      durationTimeFormat,
      toggleBar,
      toggleChar,
      totalUpChartTime,
      getTimeDurationByGivenTimestamp,
      reportProjects,
      currentTeam,
      data,
      height,
      isMobile,
      lineChartOption,
      selectionRange,
      usersArr,
      userProjects,
      v_decimal_report,
      isFetching,
    } = this.props;

    return (
      <Loading flag={isFetching} mode="parentSize" withLogo={false}>
        {toggleBar && toggleChar && (
          <div className="total_time_container">
            <div
              className={cn("total_time_wrap", {
                total_time_wrap_mobile: isMobile,
              })}
            >
              <div className="total_time_block">
                <i className="checkedRadio" />
                <span className="total_time_name">{v_total_time}</span>
                <span className="total_time_time">
                  {getTimeDurationByGivenTimestamp(
                    totalUpChartTime.totalTime,
                    durationTimeFormat,
                  )}
                </span>
              </div>
              <div className="total_time_block">
                <i className="checkedRadio" />
                <span className="total_time_name">{v_total_billable}</span>
                <span className="total_time_time">
                  {getTimeDurationByGivenTimestamp(
                    totalUpChartTime.totalBillableTime,
                    durationTimeFormat,
                  )}
                </span>
              </div>
              <div className="total_time_block">
                <i className="checkedRadio" />
                <span className="total_time_name">{v_total_not_billable}</span>
                <span className="total_time_time">
                  {getTimeDurationByGivenTimestamp(
                    totalUpChartTime.totalTime -
                      totalUpChartTime.totalBillableTime,
                    durationTimeFormat,
                  )}
                </span>
              </div>
            </div>
            <div
              className={cn("export_button", {
                "export_button-disabled":
                  currentTeam.data.userPlan?.name === "Free",
              })}
              id="exportBlock"
              onClick={(e) => this.exportHandler(e)}
            >
              {v_export}
              <i
                className={cn(
                  `arrow_down ${
                    exportModalFlag &&
                    currentTeam.data.userPlan?.name !== "Free"
                      ? "arrow_down_up"
                      : ""
                  }`,
                  {
                    arrow_down__light_themes:
                      localStorage.getItem("themes") === "light",
                  },
                )}
              />
              {exportModalFlag && currentTeam.data.userPlan?.name !== "Free" && (
                <div className="export__dropdown">
                  <div
                    className="export__item"
                    onClick={() => this.props.export(true)}
                  >
                    {v_detailed_export}
                  </div>
                  <div
                    className="export__item"
                    onClick={() => this.props.export(false)}
                  >
                    {v_simple_export}
                  </div>
                  <div
                    className="export__item"
                    onClick={() => this.openModalDecimal()}
                  >
                    {v_decimal_report}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {toggleBar && toggleChar && (
          <div className="line_chart_container">
            <Bar
              ref={(Bar) => (this.barChart = Bar)}
              data={data}
              height={height}
              options={lineChartOption(durationTimeFormat)}
            />
          </div>
        )}
        {toggleBar && toggleChar && (
          <div className="projects_chart_container">
            <ProjectsContainer
              isCrossTeam={this.props.isCrossTeam}
              newArrUserTeams={this.props.newArrUserTeams}
              newArrUserTeamsForPeople={this.props.newArrUserTeamsForPeople}
              selectionRange={selectionRange}
              usersArr={usersArr}
              reportProjects={reportProjects}
              userProjects={userProjects}
            />
          </div>
        )}
        {openModalDecimal && (
          <ReportsDecimalModal
            exportDecimal={this.props.exportDecimal}
            closeModalDecimal={this.closeModalDecimal}
            openModalDecimal={openModalDecimal}
          />
        )}
      </Loading>
    );
  }
}
