import {
  BOARD_GET_BOARD,
  BOARD_ADD_LANE,
  BOARD_MOVE_LANE,
  BOARD_DELETE_LANE,
  BOARD_CHANGE_LANE_TITLE,
  BOARD_ADD_CARD,
  BOARD_DELETE_CARD,
  BOARD_MOVE_CARD,
  BOARD_CHANGE_CARD_TEXT,
  BOARD_GET_CANDIDATE_INFO,
  BOARD_CREATE_CANDIDATE_INFO,
  BOARD_EDIT_CANDIDATE_INFO,
  RESET_CANDIDATE_INFO,
  BOARD_CREATE_DIRECTION,
  BOARD_GET_DIRECTION,
  BOARD_REMOVE_DIRECTION,
  BOARD_GET_VACANCY,
  BOARD_GET_APPLICATION,
  BOARD_SEARCH_DIRECTION,
  BOARD_SEARCH_CARD,
  BOARD_SEARCH_VACANCY,
  BOARD_SEARCH_CANDIDATES,
  BOARD_GET_CANDIDATES,
} from "../actions/PepoplePageActions";

const initialState = {
  lanes: [],
  candidates: null,
  directions: null,
  vacancies: null,
  applications: null,
};

export const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOARD_GET_BOARD: {
      return { ...state, lanes: action.payload };
    }

    case BOARD_ADD_LANE: {
      return {
        ...state,
      };
    }

    case BOARD_CHANGE_LANE_TITLE: {
      return {
        ...state,
      };
    }

    case BOARD_MOVE_LANE: {
      return {
        ...state,
      };
    }

    case BOARD_DELETE_LANE: {
      return {
        ...state,
      };
    }

    case BOARD_ADD_CARD: {
      return {
        ...state,
      };
    }

    case BOARD_CHANGE_CARD_TEXT: {
      return {
        ...state,
      };
    }

    case BOARD_MOVE_CARD: {
      return {
        ...state,
      };
    }

    case BOARD_DELETE_CARD: {
      return {
        ...state,
      };
    }

    case BOARD_SEARCH_CARD: {
      return { ...state, lanes: action.payload };
    }

    case BOARD_GET_CANDIDATE_INFO: {
      return {
        ...state,
        candidates: action.payload,
      };
    }

    case BOARD_CREATE_CANDIDATE_INFO: {
      return {
        ...state,
      };
    }

    case BOARD_EDIT_CANDIDATE_INFO: {
      return {
        ...state,
      };
    }

    case RESET_CANDIDATE_INFO: {
      return {
        ...state,
        candidates: null,
      };
    }

    case BOARD_CREATE_DIRECTION: {
      return { ...state };
    }

    case BOARD_GET_DIRECTION: {
      return { ...state, directions: action.payload };
    }

    case BOARD_SEARCH_DIRECTION: {
      return { ...state, directions: action.payload };
    }

    case BOARD_REMOVE_DIRECTION: {
      return { ...state };
    }

    case BOARD_GET_VACANCY: {
      return { ...state, vacancies: action.payload };
    }

    case BOARD_SEARCH_VACANCY: {
      return { ...state, vacancies: action.payload };
    }

    case BOARD_GET_APPLICATION: {
      return { ...state, applications: action.payload };
    }

    case BOARD_SEARCH_CANDIDATES: {
      return { ...state, candidates: action.payload };
    }

    case BOARD_GET_CANDIDATES: {
      return { ...state, candidates: action.payload };
    }

    default:
      return state;
  }
};
