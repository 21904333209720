import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import cn from "classnames";
import "./style.scss";

const ThankYouPage = ({ history }) => {
  const redirectFrom = localStorage.getItem("redirectFrom");
  useEffect(() => {
    if (redirectFrom && redirectFrom === "/register") {
      setTimeout(() => history.push("/user-settings"), 1000);
    } else {
      history.push("/");
    }
    localStorage.removeItem("redirectFrom");
  }, []);

  return redirectFrom && redirectFrom === "/register" ? (
    <div className="wrapper_thanks">
      <i
        className={cn("page_title", {
          page_title__light: localStorage.getItem("themes") === "light",
        })}
      />
      <div className="header">Thank You for registartion!</div>
    </div>
  ) : (
    <></>
  );
};

export default withRouter(connect(null, null)(ThankYouPage));
