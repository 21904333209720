import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";

import moment from "moment";

import "./style.scss";

class ReportsDecimalModal extends Component {
  state = {
    value: "5",
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  saveDecimalValue = () => {
    return (
      this.props.exportDecimal(false, this.state.value),
      this.props.closeModalDecimal(false)
    );
  };
  render() {
    const { vocabulary, closeModalDecimal, openModalDelete } = this.props;
    const {
      v_round_to,
      v_report_round,
      v_minutes,
      v_confirm,
      v_decimal_report,
    } = vocabulary;

    return (
      <div className="decimal-modal">
        <div className="decimal-modal__background" />

        <div className="decimal-modal__container">
          <div className="decimal-modal__container-header">
            <div className="decimal-modal__container-header-title">
              {v_report_round}
            </div>
            <i
              className="decimal-modal__container-header-close"
              onClick={() => closeModalDecimal(false)}
            />
          </div>
          <div className="decimal-modal__container-content">
            <span className="decimal-modal__container-content-title">
              {v_round_to}
            </span>
            <RadioGroup onChange={this.handleChange} value={this.state.value}>
              <FormControlLabel
                value={"1"}
                control={<Radio color="primary" />}
                label={`${"1"} ${v_minutes}`}
              />
              <FormControlLabel
                value={"5"}
                control={<Radio color="primary" />}
                label={`${"5"} ${v_minutes}`}
              />
              <FormControlLabel
                value={"10"}
                control={<Radio color="primary" />}
                label={`${"10"} ${v_minutes}`}
              />

              <FormControlLabel
                value={"15"}
                control={<Radio color="primary" />}
                label={`${"15"} ${v_minutes}`}
              />

              <FormControlLabel
                value={"30"}
                control={<Radio color="primary" />}
                label={`${"30"} ${v_minutes}`}
              />
              <FormControlLabel
                value={"60"}
                control={<Radio color="primary" />}
                label={`${"60"} ${v_minutes}`}
              />
            </RadioGroup>
          </div>
          <div className="decimal-modal__container-btn">
            <div>
              <span onClick={(e) => this.saveDecimalValue()}>{v_confirm}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsDecimalModal);
