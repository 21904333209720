import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./style.scss";

const UpgradePlanModal = ({ isOpen, changeStatus, children }) => {
  const handleClose = () => {
    changeStatus(false);
  };

  return (
    <Modal
      disableAutoFocus
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="upgrade-modal"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className="upgrade-modal__paper">
          <div className="upgrade-modal__header">
            <i className="upgrade-modal__close-icon" onClick={handleClose} />
          </div>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default UpgradePlanModal;
