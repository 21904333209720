import React, { Component } from "react";

import classNames from "classnames";
import { AppConfig } from "../../../config";

import "./style.scss";
import { CheckIcon } from "../../Atoms/SvgIcons";

export default class ResourcePlanningAssigneeItem extends Component {
  toggleItem = (e) => {
    e.stopPropagation();

    this.props.onSelect(e);
  };

  render() {
    const { avatar, name, isSelected } = this.props;

    return (
      <button
        className={classNames("assignee-item", {
          "assignee-item--selected": isSelected,
        })}
        onClick={this.toggleItem}
      >
        <div className="assignee-item__avatar">
          <div className="assignee-item__img-container">
            {avatar === `${AppConfig.apiURL}/${null}` ? (
              <img className={classNames( "assignee-item__img-none", {"assignee-item__img-none__white": localStorage.getItem("themes")==="light"})} />
            ) : (
              <img className="assignee-item__img" src={avatar} />
            )}
          </div>
          <div className="assignee-item__icon-container">
            <CheckIcon color="#00b173" />
          </div>
        </div>
        <div className="assignee-item__name">{name}</div>
      </button>
    );
  }
}
