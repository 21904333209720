import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";

import {
  DatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/de";
import ruLocale from "date-fns/locale/ru";
import itLocale from "date-fns/locale/it";
import uaLocale from "date-fns/locale/uk";

// Actions
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import { scrollToAction } from "../../../redux/actions/ResponsiveActions";

import "./style.scss";

const CloseIcon = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5023 0.132198C18.326 -0.0440659 18.0375 -0.0440659 17.8612 0.132198L11.3205 6.67289C11.1443 6.84916 10.8557 6.84916 10.6795 6.67289L4.13891 0.132198C3.96265 -0.0440659 3.67414 -0.0440659 3.49787 0.132198L0.132198 3.49775C-0.0440659 3.67401 -0.0440659 3.96252 0.132198 4.13879L6.67289 10.6795C6.84916 10.8557 6.84916 11.1443 6.67289 11.3205L0.132198 17.8612C-0.0440659 18.0375 -0.0440659 18.326 0.132198 18.5023L3.49775 21.8678C3.67401 22.0441 3.96252 22.0441 4.13879 21.8678L10.6795 15.3271C10.8557 15.1508 11.1443 15.1508 11.3205 15.3271L17.8611 21.8677C18.0374 22.0439 18.3259 22.0439 18.5021 21.8677L21.8677 18.5021C22.0439 18.3259 22.0439 18.0374 21.8677 17.8611L15.3271 11.3205C15.1508 11.1443 15.1508 10.8557 15.3271 10.6795L21.8677 4.13891C22.0439 3.96265 22.0439 3.67414 21.8677 3.49787L18.5023 0.132198Z"
      fill="#828282"
    />
  </svg>
);
const localeMap = {
  en: enLocale,
  ru: ruLocale,
  de: deLocale,
  it: itLocale,
  uk: uaLocale,
};

const muiTheme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },
    MuiTypography: {
      root: {
        fontSize: "1.7rem !important",
      },
    },
    MuiInputAdornment: {
      root: {
        display: "none",
      },
    },
    MuiInput: {
      root: {
        fontSize: "1.7rem !important",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiPickersDay: {
      current: {
        color: "#27ae60",
      },
      daySelected: {
        backgroundColor: "#27ae60",
        "&:hover": {
          backgroundColor: "#27ae60",
        },
      },
    },
  },
});

const checDates = (a, b) => {
  const first = `${moment(a).format()}`;
  const second = `${moment(b).format()}`;
  const result = first.localeCompare(second)

  return (result === 0)
}

class CalendarPopup extends Component {
  constructor(props) {
    super(props);

    this.editTaskPopupRef = React.createRef();

    this.state = {
      startDateTime: null,
      endDateTime: null,
      date: null,
      isChangedTime: false,
      isChangedDate: false,
      initialRender: true,
      color: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { startDateTime, endDateTime } = props;
    const { initialRender } = state;

    if (initialRender) {
      return {
        startDateTime: moment(startDateTime).toDate(),
        endDateTime: moment(endDateTime).toDate(),
        date: moment(startDateTime).toDate(),
        initialRender: false,
      };
    }
    return null;
  }

  changeHandlerStartTime = (startTime) => {
    this.setState({
      isChangedTime: true,
      startDateTime: startTime,
    });

    this.setState({
      color: checDates(startTime, this.props.startDateTime)
    });
  };

  changeHandlerEndTime = (endTime) => {
    this.setState({
      isChangedTime: true,
      endDateTime: endTime,
    });

    this.setState({
      color: checDates(endTime, this.props.endDateTime),
    });
  };

  changeHandlerDate = (date) => {
    this.setState({
      isChangedDate: true,
      date,
    });

    this.setState({
      color: checDates(date, this.props.startDateTime)
    });
  };

  closeDatepicker = () => {
    this.setState(
      {
        startDateTime: null,
        endDateTime: null,
        date: null,
        isChangedTime: false,
        isChangedDate: false,
      },
      () => this.props.onCloseHendle(),
    );
  };

  addDatepicker = () => {
    this.props.onCloseHendle();
  }

  componentDidMount() {
    const { createRefCallback, scrollToAction } = this.props;

    createRefCallback(this.editTaskPopupRef);
    const height =
      window.innerHeight || window.document.documentElement.clientHeight;
    const boundingClientRect =
      this.editTaskPopupRef.current.getBoundingClientRect();
    const { bottom } = boundingClientRect;

    if (bottom > height) {
      const diff = bottom - height;

      scrollToAction(diff);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { createRefCallback } = this.props;

    createRefCallback(this.editTaskPopupRef);
  }

  componentWillUnmount() {
    const { updateTask, vocabulary, showNotificationAction } = this.props;
    const { v_a_time_start_error, v_a_time_end_error } = vocabulary;
    const { startDateTime, endDateTime, date, isChangedTime, isChangedDate } =
      this.state;

    if (isChangedTime || isChangedDate) {
      if (moment(startDateTime).isValid() && moment(endDateTime).isValid()) {
        const startDay = moment(date).format("YYYY-MM-DD");
        let endDay = moment(date).format("YYYY-MM-DD");
        const startTime = isChangedTime
          ? moment(`${startDay} ${moment(startDateTime).format("HH:mm")}`)
          : moment(`${startDay} ${moment(startDateTime).format("HH:mm:ss")}`);
        let endTime = isChangedTime
          ? moment(`${endDay} ${moment(endDateTime).format("HH:mm")}`)
          : moment(`${endDay} ${moment(endDateTime).format("HH:mm:ss")}`);

        if (isChangedTime && Number(startTime) >= Number(endTime)) {
          endDay = moment(date).add(1, "days").format("YYYY-MM-DD");
          endTime = moment(`${endDay} ${moment(endDateTime).format("HH:mm")}`);
        }
        updateTask({
          startDateTime: startTime,
          endDateTime: endTime,
        });
      } else if (!moment(startDateTime).isValid()) {
        showNotificationAction({ text: v_a_time_start_error, type: "warning" });
      } else {
        showNotificationAction({ text: v_a_time_end_error, type: "warning" });
      }
    }
  }

  render() {
    const { startDateTime, endDateTime, date } = this.state;
    const { timeFormat, vocabulary, firstDayOfWeek } = this.props;
    const { lang, v_time_start, v_time_end } = vocabulary;

    const customLocale = localeMap[lang.short];

    customLocale.options.weekStartsOn = firstDayOfWeek;

    return (
      <div ref={this.editTaskPopupRef} className="edit-task-popup">
        <ThemeProvider theme={muiTheme}>
          <div className="edit-task-popup_set-time">
            <div className="edit-task-popup-Newheader">
              Data Picker
              <div className="edit-task-popup-Newheader-container">
                  <button
                    onClick={this.addDatepicker}
                    className="edit-task-popup-Newheader-button"
                  >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width="25px" height="25px"><linearGradient id="I9GV0SozQFknxHSR6DCx5a" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#21ad64"/>
                        <stop offset="1" stopColor="#088242"/></linearGradient>
                        <path className={`${this.state.color ? "edit-task-popup-Newheader-active" : "edit-task-popup-Newheader-Notactive"}`} d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/>
                        <path d="M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z" opacity=".05"/>
                        <path d="M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z" opacity=".07"/>
                        <path fill="#fff" d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z"/>
                        </svg>
                  </button>
                  <CloseIcon
                    className="start-edit-task-modal__close-icon"
                    onClick={this.closeDatepicker}
                  />
              </div>
            </div>
            <div className="start-edit-task-modal-time">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
              <div className="edit-task-popup_set-time-start">
                <div className="edit-task-popup_set-time-label">
                  {v_time_start}
                </div>
                <KeyboardTimePicker
                  disableToolbar
                  ampm={timeFormat === "12"}
                  value={startDateTime}
                  onChange={this.changeHandlerStartTime}
                />
              </div>
              <div className="edit-task-popup_set-time-end">
                <div className="edit-task-popup_set-time-label">
                  {v_time_end}
                </div>
                <KeyboardTimePicker
                  disableToolbar
                  ampm={timeFormat === "12"}
                  value={endDateTime}
                  onChange={this.changeHandlerEndTime}
                />
              </div>
            </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="edit-task-popup_calendar">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={customLocale}>
              <DatePicker
                autoOk
                disableToolbar={true}
                allowKeyboardControl={false}
                variant="static"
                openTo="date"
                value={date}
                onChange={this.changeHandlerDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  timeFormat: state.userReducer.timeFormat,
  firstDayOfWeek: state.userReducer.firstDayOfWeek,
});

const mapDispatchToProps = {
  showNotificationAction,
  scrollToAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPopup);
