import React, { Component } from "react";
import { connect } from "react-redux";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createMuiTheme, Checkbox } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

// Services
import {
  ROLES,
  checkIsAdminByRole,
  checkIsOwnerByRole,
} from "../../../services/authentication";
import { apiCall } from "../../../services/apiService";

// Components
import TechnologyComponent from "../TechnologyComponent";

// Actions
import { changeUserData } from "../../../redux/actions/UserActions";
import { getCurrentTeamDetailedDataAction } from "../../../redux/actions/TeamActions";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

// Queries

// Config
import { AppConfig } from "../../../config";

// Styles
import cn from "classnames";
import "./style.scss";

const materialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
      },
    },
    MuiButtonBase: {
      root: {
        padding: "3px !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingLeft: "5px",
      },
    },
  },
});

const USER_STATUS = {
  ACTIVE: "ACTIVE",
  NOT_ACTIVE: "NOT_ACTIVE",
};

class EditTeamModal extends Component {
  state = {
    id: null,
    value: ROLES.ROLE_MEMBER,
    isOwner: false,
    isAdmin: false,
    isHR: false,
    isPM: false,
    valueStatus: USER_STATUS.NOT_ACTIVE,
    valueDeleteMember: false,
    userTechnologies: [],
    maxLength: 100,
    availableSymbols: 100 - this.props.editedUser.user.username.length,
  };

  handleChangeInputLength = (event) => {
    const targetValue = event.target.value;
    const newAvailableSymbols = this.state.maxLength - targetValue.length;

    if (newAvailableSymbols < 0) {
      return;
    }

    this.setState({ availableSymbols: newAvailableSymbols });

    return newAvailableSymbols;
  };

  closeModal() {
    this.props.teamPageAction("TOGGLE_EDIT_USER_MODAL", {
      editUserModal: false,
    });
  }

  addUser = () => {
    const {
      vocabulary,
      changeUserData,
      getCurrentTeamDetailedDataAction,
      showNotificationAction,
    } = this.props;

    if (this.state.valueDeleteMember) {
      apiCall(
        `${AppConfig.apiURL}user/${this.state.id}/team/remove-from-team`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        },
      ).then(
        (result) => {
          if (result.message) {
            showNotificationAction({
              text: vocabulary[result.message],
              type: "success",
            });
          }
          this.closeModal();
          getCurrentTeamDetailedDataAction();
        },
        (err) => {
          if (err instanceof Response) {
            err.text().then((errorMessage) => {
              const textError = JSON.parse(errorMessage).message;

              showNotificationAction({
                text: vocabulary[textError],
                type: "error",
              });
            });
          } else {
            console.log(err);
          }
        },
      );
    } else {
      apiCall(`${AppConfig.apiURL}user/${this.state.id}/team`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email.value,
          username: this.name.value,
          isActive: this.state.valueStatus === USER_STATUS.ACTIVE,
          roleName: this.state.isOwner
            ? this.props.editedUser.role_collaboration.title
            : this.state.value,
          technologies: this.state.userTechnologies.map((item) => item.id),
          cv: this.cv.value,
        }),
      }).then(
        (result) => {
          if (result.message) {
            showNotificationAction({
              text: vocabulary[result.message],
              type: "success",
            });
          } else {
            changeUserData(result);
          }
          this.closeModal();
          getCurrentTeamDetailedDataAction();
        },
        (err) => {
          if (err instanceof Response) {
            err.text().then((errorMessage) => {
              const textError = JSON.parse(errorMessage).message;

              showNotificationAction({
                text: vocabulary[textError],
                type: "error",
              });
            });
          } else {
            console.log(err);
          }
        },
      );
    }
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleChangeStatus = (event) => {
    this.setState({ valueStatus: event.target.value });
  };

  handleChangeDeleteMember = (event) => {
    this.setState({ valueDeleteMember: event.target.checked });
  };

  componentDidMount() {
    const currentUser = this.props.editedUser.user || {};
    const { id, username, email, user_technology, cv } = currentUser;

    const isOwner = id === this.props.owner_id;
    const role = isOwner
      ? ROLES.ROLE_OWNER
      : this.props.editedUser.role_collaboration.title;
    const isActive = this.props.editedUser.is_active;
    if (
      this.props.editedUser.role_collaboration.title === ROLES.ROLE_ADMIN &&
      this.props.editedUser.user.id === this.props.user.id
    ) {
      this.setState({
        isAdmin: true,
      });
    } else if (
      this.props.editedUser.role_collaboration.title ===
        ROLES.ROLE_HR_MANAGER &&
      this.props.editedUser.user.id === this.props.user.id
    ) {
      this.setState({
        isHR: true,
      });
    } else if (
      this.props.editedUser.role_collaboration.title ===
        ROLES.ROLE_PROJECT_MANAGER &&
      this.props.editedUser.user.id === this.props.user.id
    ) {
      this.setState({
        isPM: true,
      });
    }
    this.setState({
      id,
      value: role,
      isOwner: isOwner,
      valueStatus: isActive ? USER_STATUS.ACTIVE : USER_STATUS.NOT_ACTIVE,
      userTechnologies: user_technology
        ? user_technology.map((item) => item.technology)
        : [],
    });
    this.email.value = email;
    this.name.value = username;
    this.cv.value = cv || "";
  }

  render() {
    const { vocabulary, isTeamOwner, currentTeam } = this.props;
    const checkAdminsThemselves =
      this.state.isOwner ||
      this.state.isAdmin ||
      this.state.isHR ||
      this.state.isPM;
    const {
      v_name,
      v_team_role,
      v_team_access,
      v_save,
      v_active,
      v_not_active,
      v_delete_member,
      v_tags,
      v_cv,
    } = vocabulary;

    const disabledSubscriptionCheck =
      currentTeam?.name === "Free";

    const cursorStyle = disabledSubscriptionCheck
      ? { cursor: "not-allowed" }
      : { cursor: "pointer" };

    return (
      <div className="edit_team_modal_wrapper">
        <div className="edit_team_modal_data">
          <i onClick={(e) => this.closeModal()} />
          <div className="edit_team_modal_input_container">
            <div className="edit_team_modal_input_title">{v_name}</div>
            <input
              maxLength="100"
              type="text"
              ref={(input) => {
                this.name = input;
              }}
              onChange={this.handleChangeInputLength}
              className="edit_team_modal_input"
            />
            <div className="team-input-max-length-name">
              {this.state.availableSymbols}
            </div>
          </div>
          <div className="edit_team_modal_input_container">
            <div className="edit_team_modal_input_title">E-mail</div>
            <input
              type="text"
              ref={(input) => {
                this.email = input;
              }}
              className="edit_team_modal_input"
              disabled={true}
            />
          </div>
          <div className="edit_team_modal_input_container">
            <div
              className={cn("edit_team_modal_input_title", {
                "edit_team_modal_input_title--disabled":
                  disabledSubscriptionCheck,
              })}
            >
              {v_team_role}
            </div>
            <ThemeProvider theme={materialTheme}>
              <RadioGroup onChange={this.handleChange} value={this.state.value}>
                {(checkIsOwnerByRole(this.props.currentTeamRole) ||
                  checkIsAdminByRole(this.props.currentTeamRole)) && (
                  <FormControlLabel
                    value={ROLES.ROLE_ADMIN}
                    control={<Radio color="primary" />}
                    label="Admin"
                    disabled={this.state.isOwner || disabledSubscriptionCheck}
                    style={cursorStyle}
                  />
                )}
                <FormControlLabel
                  value={ROLES.ROLE_PROJECT_MANAGER}
                  control={<Radio color="primary" />}
                  label="Project manager"
                  disabled={
                    this.state.isOwner ||
                    this.state.isAdmin ||
                    this.state.isHR ||
                    disabledSubscriptionCheck
                  }
                  style={cursorStyle}
                />
                <FormControlLabel
                  value={ROLES.ROLE_HR_MANAGER}
                  control={<Radio color="primary" />}
                  label="HR manager"
                  disabled={
                    this.state.isOwner ||
                    this.state.isAdmin ||
                    this.state.isPM ||
                    disabledSubscriptionCheck
                  }
                  style={cursorStyle}
                />
                <FormControlLabel
                  value={ROLES.ROLE_RECRUITER}
                  control={<Radio color="primary" />}
                  label="Recruiter"
                  disabled={checkAdminsThemselves || disabledSubscriptionCheck}
                  style={cursorStyle}
                />
                <FormControlLabel
                  value={ROLES.ROLE_MEMBER}
                  control={<Radio color="primary" />}
                  label="Member"
                  disabled={checkAdminsThemselves || disabledSubscriptionCheck}
                  style={cursorStyle}
                />
                <FormControlLabel
                  value={ROLES.ROLE_INVOICES_MANAGER}
                  control={<Radio color="primary" />}
                  label="Invoices manager"
                  disabled={checkAdminsThemselves || disabledSubscriptionCheck}
                  style={cursorStyle}
                />
                {this.state.isOwner && (
                  <FormControlLabel
                    value={ROLES.ROLE_OWNER}
                    control={<Radio color="primary" />}
                    label="Owner"
                    disabled={disabledSubscriptionCheck}
                    style={cursorStyle}
                  />
                )}
              </RadioGroup>
            </ThemeProvider>
          </div>
          <div className="edit_team_modal_input_container">
            <div className="edit_team_modal_input_title">{v_team_access}</div>
            <ThemeProvider theme={materialTheme}>
              <RadioGroup
                onChange={this.handleChangeStatus}
                value={this.state.valueStatus}
              >
                <FormControlLabel
                  value={USER_STATUS.ACTIVE}
                  control={<Radio color="primary" />}
                  label={v_active}
                />
                <FormControlLabel
                  value={USER_STATUS.NOT_ACTIVE}
                  control={<Radio color="primary" />}
                  label={v_not_active}
                  disabled={checkAdminsThemselves}
                />
              </RadioGroup>
            </ThemeProvider>
            <div className="edit-team-modal__technology">
              <div
                className={cn("edit-team-modal__technology-title", {
                  "edit_team_modal_input_title--disabled":
                    disabledSubscriptionCheck,
                })}
              >
                {v_tags}
              </div>
              <TechnologyComponent
                userTechnologies={this.state.userTechnologies}
                setUserTechnologies={(techArr) => {
                  this.setState({ userTechnologies: techArr });
                }}
                showNotificationAction={this.props.showNotificationAction}
                vocabulary={vocabulary}
                themeLight={true}
                disabled={disabledSubscriptionCheck}
              />
            </div>
          </div>
          <div className="edit_team_modal_input_container_cv">
            <div className="edit_team_modal_input_title">{v_cv}</div>
            <textarea
              ref={(input) => {
                this.cv = input;
              }}
              className="edit_team_modal_textarea"
              placeholder={v_cv}
            ></textarea>
          </div>
          <div className="delete_team_modal_input_container">
            {!checkAdminsThemselves && (
              <ThemeProvider theme={materialTheme}>
                <FormControlLabel
                  value={this.state.valueDeleteMember}
                  control={<Checkbox color="primary" />}
                  label={v_delete_member}
                  onChange={this.handleChangeDeleteMember}
                />
              </ThemeProvider>
            )}
          </div>
          <button className="save_button" onClick={(e) => this.addUser()}>
            {v_save}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
  owner_id: store.teamReducer.currentTeam.data.owner_id,
  user: store.userReducer.user,
  currentTeamRole: store.teamReducer.currentTeam.data.role,
  currentTeam: store.teamReducer.currentTeam.data.userPlan,
});

const mapDispatchToProps = {
  changeUserData,
  getCurrentTeamDetailedDataAction,
  showNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamModal);
