import React from "react";

const CatIcon = () => (
  <svg width="163" height="175" viewBox="0 0 163 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M94.293 147.458C94.293 147.458 103.89 156.275 122.625 155.427C130.552 155.089 139.084 149.492 149.713 135.013C160.413 120.498 162.014 96.0834 138.267 85.7074" stroke="#333A4E" stroke-width="10.873" stroke-linecap="round"/>
    <path d="M84.7604 9.24409C85.4371 4.4435 79.8248 1.49983 76.6983 5.17355L60.7648 17.6199L74.04 55.5267C74.04 55.5267 79.7257 44.9885 84.7604 9.24409Z" fill="#202331"/>
    <path d="M43.1546 11.9614L32.6965 4.23483C29.613 0.532895 23.635 3.47656 24.6064 8.2199L25.8354 36.9522L43.1546 11.9614Z" fill="#202331"/>
    <path d="M101.494 167.544C105.819 156.178 108.231 143.089 108.107 129.176C107.725 86.492 83.1366 51.9377 53.492 52.3736C23.8978 52.8095 0 87.4864 0 130.201C0 143.756 2.40723 156.501 6.63835 167.6C8.10847 171.455 10.9497 173.809 14.0047 173.809H94.1052C97.1759 173.809 100.02 171.421 101.494 167.544Z" fill="#33394E"/>
    <path d="M101.494 167.544C105.819 156.178 108.231 143.089 108.107 129.176C107.725 86.492 83.1366 51.9377 53.492 52.3736C23.8978 52.8095 0 87.4864 0 130.201C0 143.756 2.40723 156.501 6.63835 167.6C8.10847 171.455 10.9497 173.809 14.0047 173.809H94.1052C97.1759 173.809 100.02 171.421 101.494 167.544Z" fill="#282D3C"/>
    <path d="M45.4724 173.863C25.8325 173.863 9.76172 157.603 9.76172 137.73V47.9085C9.76172 28.0357 25.8325 11.776 45.4724 11.776C65.1124 11.776 81.18 28.0357 81.18 47.9085V150.625C81.18 167.798 65.8356 155.441 65.8356 162.626C65.8356 178.535 51.6941 172.859 45.4724 173.863Z" fill="#33394E"/>
    <path d="M27.7189 173.761C29.7786 172.424 31.0931 170.402 31.0931 168.137C31.0931 164.112 26.9437 160.847 21.8258 160.847C16.7079 160.847 12.5586 164.112 12.5586 168.137C12.5586 170.149 13.9973 172.542 15.6749 173.861L27.7189 173.761Z" fill="#33394E"/>
    <path d="M60.4859 173.829C60.4859 173.829 65.8868 171.04 63.3585 165.227C62.5063 163.119 60.3711 162.32 60.3711 162.32L64.3632 143.639C64.5268 142.875 65.5786 142.808 65.8208 143.551C67.0771 147.392 69.6117 154.469 72.7029 159.506C73.0095 160.005 72.6997 160.642 72.118 160.669C70.3318 160.751 67.3318 161.326 66.6903 164.182C66.0488 167.034 66.8978 172.886 69.8853 173.816L60.4859 173.829Z" fill="#B9C1D7"/>
    <path d="M51.9007 92.8248C52.9086 90.3557 53.3724 87.6892 52.9888 85.045C51.5847 75.3448 47.0517 52.7753 34.948 49.509C19.4637 45.3312 17.6571 71.4438 20.2373 88.9397C22.8175 106.436 27.2074 97.2971 29.014 126.803C30.8206 156.311 31.775 167.306 30.5014 170.574C29.7514 172.499 27.7231 173.807 27.7231 173.807L49.4149 173.885C49.4149 173.885 45.2703 170.674 45.0124 162.84C44.7703 155.496 44.3033 111.43 51.9007 92.8248Z" fill="#D9DEEC"/>
    <path d="M42.6673 25.7988C39.2679 23.7576 34.9472 25.5426 37.5997 27.2418C40.2538 28.9409 46.9786 28.3857 42.6673 25.7988Z" fill="#020100"/>
  </svg>

);

export default CatIcon;

