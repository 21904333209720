import React, { useEffect, useRef, useState } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import Alert from "../../../Atoms/Alert";
import PlanDetails from "../../PlanDetails";
import BillingInfo from "../../BillingInfo";
import PaymentInfo from "../../PaymentInfo";
import PaymentInfoModal from "../../PaymentInfoModal";
import ModalPortal from "../../ModalPortal";
import BillingInfoEditModal from "../BillingInfoEditModal";
import BillingModalWrapper from "../BillingModalWrapper";

import { updatePaymentMethodAction } from "../../../../redux/actions/SubscriptionPageAction";

import "./style.scss";

import { vocabularyInterpolation } from "../../../../services/vocabulary";
import { values } from "lodash";

const Overview = ({
  vocabulary,
  plansData,
  customerData,
  updatePaymentMethodAction,
  userData,
  isFetching,
}) => {
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);
  const [showBillingInfoModal, setShowBillingInfoModal] = useState(false);
  const [billingData, setBillingData] = useState(null);

  const { v_subscription } = vocabulary;

  useEffect(() => {
    if (customerData && customerData.default_payment_method) {
      const {
        default_payment_method: {
          billing_details: {
            name,
            email,
            phone,
            address: { country, line1, postal_code },
          },
        },
      } = customerData;

      setBillingData({
        name: name || "",
        country: country || "",
        emailAddress: email || "",
        address: line1 || "",
        phoneNumber: phone || "",
        postalCode: postal_code || "",
      });
    } else {
      const {
        username: userName,
        email: userEmail,
        phone: userPhone,
        country: userCountry,
        city: userCity,
        zip: userZip,
      } = userData;

      setBillingData({
        name: userName || "",
        country: userCountry || "",
        emailAddress: userEmail || "",
        address: userCity || "",
        phoneNumber: userPhone || "",
        postalCode: userZip || "",
      });
    }
  }, [customerData, userData]);

  const updatePaymentData = () => {};

  const updatePaymentInfo = async (formValues) => {
    if (customerData && customerData.default_payment_method) {
      updatePaymentMethodAction(formValues, () =>
        setShowBillingInfoModal(false),
      );
    } else {
      setBillingData(formValues);
      setShowBillingInfoModal(false);
      setShowPaymentInfoModal(true);
    }
  };

  return (
    <div className="overview">
      {showPaymentInfoModal && (
        <ModalPortal>
          <PaymentInfoModal
            paymentDetails={billingData}
            handleSubmit={updatePaymentData}
            isProcessing={isFetching}
            handleClose={() => setShowPaymentInfoModal(false)}
            changePaymentMethod
          />
        </ModalPortal>
      )}
      {showBillingInfoModal && (
        <ModalPortal>
          <BillingModalWrapper>
            <BillingInfoEditModal
              handleClose={() => setShowBillingInfoModal(false)}
              formValues={billingData}
              handleSubmit={updatePaymentInfo}
              submitTitle={v_subscription.overview.updateBillingInfo}
              isProcessing={isFetching}
            />
          </BillingModalWrapper>
        </ModalPortal>
      )}
      {/* {customerData && customerData.status === 'trialing' && (
        <Alert type='success' classes='overview__block'>
          <span>
            {vocabularyInterpolation(v_subscription.overview.trialWarning, {
              planName: plansData.filter((product) => product.id === customerData.plan.product)[0]
                .name,
            })}
          </span>{' '}
          <span
            className='overview__add-payment-info-link'
            onClick={() => setShowPaymentInfoModal(true)}
          >
            {v_subscription.overview.trialAddPaymentInfo}
          </span>
        </Alert>
      )}*/}
      <PlanDetails className="overview__block" />
      <div className="overview__row">
        <div className="overview__col">
          <BillingInfo
            className="overview__col-content"
            onAddBillingInfo={() => setShowBillingInfoModal(true)}
            paymentData={customerData.default_payment_method}
            isFetching={false}
          />
        </div>
        <div className="overview__col">
          <PaymentInfo
            className="overview__col-content"
            onAddPaymentInfo={() => setShowPaymentInfoModal(true)}
            paymentMethod={customerData.default_payment_method}
            isFetching={false}
          />
        </div>
      </div>
    </div>
  );
};

Overview.propTypes = {
  vocabulary: Proptypes.object,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
  plansData: store.subscriptionPageReducer.plansData,
  isFetching: store.subscriptionPageReducer.isFetching,
  customerData: store.subscriptionPageReducer.customerData,
  userData: store.userReducer.user,
});

const mapDispatchToProps = {
  updatePaymentMethodAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
