import React, { useState, useRef } from "react";
import classNames from "classnames";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";
import "./style.scss";
import { EditIcon, CopyIcon, MoreIcon } from "../../Atoms/SvgIcons";
import { useOutsideClick } from "../../../services/hookHelpers";
import InvoiceActionsDropdown from "../InvoicePageComponents/InvoiceActionsDropdown";
import InvoiceCopyLinkModal from "../InvoicePageComponents/InvoiceCopyLinkModal";
import { downloadPDF } from "../../../services/downloadPDF";
import { downloadInvoicePDF } from "../../../api/invoice";

const InvoiceInstruments = ({
  vocabulary,
  invoice,
  history,
  editConfirmationModalHandler,
  partialPaymentModalHandler,
  copyInvoice,
  setCurrentInvoice,
  confirmationModalHandler,
  showNotificationAction,
  toggleSendInvoiceModal,
  openCloseModal,
  onDropdownShow,
  onDropdownHide,
  isMobile,
}) => {
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showNotif, setShowNotif] = useState(false);

  const wrapperRef = useRef(null);

  const { v_edit, v_clone, v_show_more, v_link_copied } = vocabulary;

  const handleCloseActionsMenu = () => {
    if (onDropdownHide) {
      onDropdownHide();
    }
    setShowActionsMenu(false);
  };

  const handleShowActionsMenu = () => {
    if (onDropdownShow) {
      onDropdownShow();
    }
    setShowActionsMenu(true);
  };

  const checkInvoceStatus = (invoice) => {
    if (invoice.status === "awaiting" || invoice.status === "reviewed") {
      editConfirmationModalHandler(invoice);
    } else {
      history.push(`/invoices/update/${invoice.id}`);
    }
  };

  const copyToClipBoard = (invoice) => {
    const el = document.createElement("textarea");

    el.value = `${window.location.origin}/invoice/${invoice && invoice.id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setShowNotif(true);
    setTimeout(() => {
      setShowNotif(false);
    }, 2000);
  };

  useOutsideClick(wrapperRef, handleCloseActionsMenu);

  return (
    <>
      <div className="invoice-instruments">
        {!isMobile && (
          <>
            <CustomTippy
              content={v_edit}
            >
              <div>
                <EditIcon
                  className="all-invoices-list-item__icon-button"
                  onClick={(e) => checkInvoceStatus(invoice)}
                />
              </div>
            </CustomTippy>
            <CustomTippy
              content={v_clone}
            >
              <div>
                <CopyIcon
                  className="all-invoices-list-item__icon-button"
                  onClick={() => copyInvoice(invoice)}
                />
              </div>
            </CustomTippy>
          </>
        )}
        <CustomTippy
          content={v_show_more}
          disabled={showActionsMenu}
        >
          <div className="all-invoices-list-item__more-icon" ref={wrapperRef}>
            <MoreIcon
              className={classNames(
                "all-invoices-list-item__icon-button icon-stroke",
                {
                  "icon-stroke--active": showActionsMenu,
                  "icon-stroke--rotated": isMobile,
                },
              )}
              onClick={() => {
                if (!showActionsMenu) {
                  handleShowActionsMenu();
                } else {
                  handleCloseActionsMenu();
                }
              }}
            />

            {showActionsMenu && (
              <InvoiceActionsDropdown
                isMobile={isMobile}
                vocabulary={vocabulary}
                showNotif={showNotif}
                editHandler={() => {
                  handleCloseActionsMenu();
                  checkInvoceStatus(invoice);
                }}
                copyHandler={() => {
                  handleCloseActionsMenu();
                  copyInvoice(invoice);
                }}
                downloadHandler={async () => {
                  handleCloseActionsMenu();
                  try {
                    const responce = await downloadInvoicePDF(invoice.id);

                    downloadPDF(
                      responce.data,
                      `invoice-${invoice.invoice_number}.pdf`,
                    );
                  } catch (error) {
                    console.log(error);
                    showNotificationAction({
                      type: "error",
                      text: error.message,
                    });
                  }
                }}
                sendHandler={() => {
                  toggleSendInvoiceModal(invoice);
                  handleCloseActionsMenu();
                }}
                deleteHandler={() => {
                  openCloseModal(invoice.id);
                  handleCloseActionsMenu();
                }}
                shareHandler={() => {
                  copyToClipBoard(invoice)
                }}
                confirmed={invoice.status === "paid"}
                confirmPayment={() => {
                  setShowActionsMenu(false);
                  confirmationModalHandler();
                  setCurrentInvoice(invoice);
                }}
                addPartialPaymentHandler={() => {
                  setShowActionsMenu(false);
                  partialPaymentModalHandler();
                  setCurrentInvoice(invoice);
                }}
              />
            )}
          </div>
        </CustomTippy>
      </div>
    </>
  );
};

export default InvoiceInstruments;
