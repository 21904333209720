import {
  GET_SUBSCRIPTION_DATA_REQUEST,
  GET_SUBSCRIPTION_DATA_SUCCESS,
  GET_SUBSCRIPTION_DATA_FAIL,
  GET_STRIPE_CUSTOMER_DATA_SUCCESS,
  GET_STRIPE_CUSTOMER_DATA_REQUEST,
  GET_STRIPE_CUSTOMER_DATA_ERROR,
  GET_PAYMENT_HISTORY_REQUEST,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_FAIL,
  CHANGE_SUBSCRIPTION_LOADER,
  CHANGE_UPGRADE_MODAL_STATUS,
  CHANGE_UPGRADE_MODAL_CONTENT,
  HANDLE_SUBMIT_COUPON,
  HANDLE_RESET_COUPON,
  CHANGE_PRODUCT_ID,
  CHANGE_PRODUCT_ID_FOR_UPGRADE,
} from "../actions/SubscriptionPageAction";
import { RESET_ALL } from "../actions/UserActions";

const initialState = {
  isInitialFetching: true,
  isFetching: false,
  customerData: null,
  plansData: null,
  products: null,
  discount: 0,
  couponId: null,
  productId: null,
  productIdForUpgrade: null,
  error: null,
  paymentHistory: [],
  hasMoreHistory: true,
  isUpgradeModalOpen: false,
  upgradeModalContent: {
    title: "",
    description: "",
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SUBSCRIPTION_DATA_REQUEST: {
      return {
        ...state,
        error: null,
        isInitialFetching: true,
      };
    }

    case GET_SUBSCRIPTION_DATA_SUCCESS: {
      return {
        ...state,
        isInitialFetching: false,
        plansData: payload.plansData,
      };
    }

    case GET_SUBSCRIPTION_DATA_FAIL: {
      return {
        ...state,
        isInitialFetching: false,
        error: payload,
      };
    }

    case GET_STRIPE_CUSTOMER_DATA_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case GET_STRIPE_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state,
        customerData: payload,
        isFetching: false,
      };
    }

    case GET_STRIPE_CUSTOMER_DATA_ERROR: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case GET_PAYMENT_HISTORY_REQUEST: {
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    }

    case GET_PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        paymentHistory: payload.history,
        hasMoreHistory: payload.hasMoreHistory,
        isFetching: false,
      };
    }

    case GET_PAYMENT_HISTORY_FAIL: {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    }

    case CHANGE_SUBSCRIPTION_LOADER: {
      return {
        ...state,
        isFetching: payload,
      };
    }

    case CHANGE_UPGRADE_MODAL_STATUS: {
      return {
        ...state,
        isUpgradeModalOpen: payload,
      };
    }

    case CHANGE_UPGRADE_MODAL_CONTENT: {
      return {
        ...state,
        upgradeModalContent: payload,
      };
    }

    case CHANGE_PRODUCT_ID: {
      return {
        ...state,
        productId: payload,
      };
    }

    case CHANGE_PRODUCT_ID_FOR_UPGRADE: {
      return {
        ...state,
        productIdForUpgrade: payload,
      };
    }

    case HANDLE_SUBMIT_COUPON: {
      return {
        ...state,
        discount: payload.discount,
        products: payload.products,
        couponId: payload.couponId,
      };
    }

    case HANDLE_RESET_COUPON: {
      return {
        ...state,
        discount: payload,
        couponId: null,
        productId: null,
        productIdForUpgrade: null,
      };
    }

    case RESET_ALL:
      return initialState;

    default: {
      return state;
    }
  }
};
