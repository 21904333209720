import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import AnimateHeight from "react-animate-height";
import {
  VisaSVG,
  MastercardSVG,
  AmericanExpSVG,
} from "../../Subscriprions/CardBrands";
import "./style.scss";

const cardBrandMap = {
  visa: <VisaSVG />,
  mastercard: <MastercardSVG />,
  amex: <AmericanExpSVG />,
};

const ExpandIcon = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1.5L6 6.5L1 1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DoneIcon = () => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.48649 1.12811L4 6.50006L1.5 4.00006"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 12.5L17.5 15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333L2.5 12.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83329 8.33331L9.99996 12.5L14.1666 8.33331"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12.5L10 2.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ViewIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.833374 9.99998C0.833374 9.99998 4.16671 3.33331 10 3.33331C15.8334 3.33331 19.1667 9.99998 19.1667 9.99998C19.1667 9.99998 15.8334 16.6666 10 16.6666C4.16671 16.6666 0.833374 9.99998 0.833374 9.99998Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CardVisaIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.000244141 2.06588C-0.000244141 0.924926 0.934118 0 2.08671 0H21.9128C23.0654 0 23.9998 0.924924 23.9998 2.06588V11.9341C23.9998 13.0751 23.0654 14 21.9128 14H2.08671C0.934118 14 -0.000244141 13.0751 -0.000244141 11.9341V2.06588Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.14463 4.66492L5.14485 4.66634H4.27515V4.81267V4.81311C4.95386 4.83455 5.15651 5.15179 5.23155 5.52109L5.63174 9.46319H6.94855L9.76204 4.66492H8.44523L6.75425 7.54895L6.46144 4.66492H5.14463Z"
      fill="#0061B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4568 4.66309H11.7349L10.1675 9.46607H8.8894L10.4568 4.66309Z"
      fill="#0061B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5013 4.66309L15.0295 9.37037H16.4162L16.7769 8.65164H18.3865L18.4417 9.37037H19.7198L19.3582 4.66309C18.7393 4.66309 18.1203 4.66309 17.5013 4.66309ZM18.1729 5.87107L18.3259 7.86128H17.1737L18.1729 5.87107Z"
      fill="#0061B2"
    />
    <path
      d="M12.0483 5.76239C12.2401 5.00079 12.8696 4.48083 13.9413 4.48083C14.9451 4.48083 15.6897 5.10365 15.6897 5.10365L14.93 5.89416C14.93 5.89416 14.3686 5.46294 14.0378 5.46294C13.6004 5.46294 13.403 5.62159 13.3625 5.88213C13.282 6.40172 15.1457 6.7197 15.0867 7.93029C15.0473 8.73873 14.2051 9.52326 13.242 9.52326C11.7978 9.52326 11.0837 8.94833 11.0837 8.94833L11.7349 8.02605C11.7349 8.02605 12.3875 8.54534 13.0009 8.51711C13.4147 8.49806 13.7337 8.34052 13.7123 8.06198C13.6637 7.43065 11.7011 7.14083 12.0483 5.76239Z"
      fill="#0061B2"
    />
  </svg>
);

const BillingHistoryRow = ({ vocabulary, data }) => {
  // show / hide details flag
  const [expand, setExpand] = useState(false);

  // is details shown / hidden
  // this variable is `true` while the row is animation
  const [expanded, setExpanded] = useState(false);
  const { lang } = vocabulary;

  return (
    <>
      <tr
        className={`billing-history-row ${
          expanded ? "billing-history-row--expanded" : null
        }`}
      >
        <td className="billing-history-row__cell billing-history-row__cell--no-padding billing-history-row__cell--bg-success billing-history-row__cell--width-6" />
        <td className="billing-history-row__cell billing-history-row__cell--no-padding billing-history-row__cell--relative billing-history-row__cell--width-41">
          <button
            className="billing-history-row__button"
            onClick={() => setExpand(!expand)}
          >
            <ExpandIcon
              className={`billing-history-row__expand-icon ${
                expand ? "billing-history-row__expand-icon--active" : null
              }`}
            />
          </button>
        </td>
        <td className="billing-history-row__cell billing-history-row__cell--width-69 billing-history-row__cell--pl-16">
          <div className="billing-history-row__success-icon">
            <DoneIcon />
          </div>
        </td>
        <td className="billing-history-row__cell">
          {moment.unix(data.invoice.created).locale(lang.short).format("ll")}
        </td>
        <td className="billing-history-row__cell">{data.invoice.number}</td>
        <td className="billing-history-row__cell">{`EUR ${
          data.invoice.amount_paid / 100
        }`}</td>
        <td className="billing-history-row__cell billing-history-row__cell--no-padding billing-history-row__cell--pl-3 billing-history-row__cell--width-89">
          <button className="billing-history-row__button">
            <a
              href={data.invoice.invoice_pdf}
              download
              target="_blank"
              rel="noreferrer"
            >
              <DownloadIcon />
            </a>
          </button>
          <button className="billing-history-row__button">
            <a
              href={data.invoice.hosted_invoice_url}
              target="_blank"
              noopener
              noreferrer
              rel="noreferrer"
            >
              <ViewIcon />
            </a>
          </button>
        </td>
      </tr>
      <tr
        className={`billing-history-row billing-history-row--details ${
          expand || expanded ? "" : "billing-history-row--hidden"
        }`}
      >
        <td
          className="billing-history-row__cell billing-history-row__cell--no-padding"
          colSpan="4"
        />
        <td
          className="billing-history-row__cell billing-history-row__cell--no-padding"
          colSpan="3"
        >
          <AnimateHeight
            duration={300}
            height={expand ? "auto" : 0}
            onAnimationStart={() => setExpanded(true)}
            onAnimationEnd={() => setExpanded(expand)}
          >
            <div className="billing-history-row__accordeon-wrap">
              <div className="billing-history-row__card">
                <div className="billing-history-row__card-holder">
                  {data.charges.data[0].billing_details.name}
                </div>
                <div className="billing-history-row__card-number">
                  {`**** **** **** ${data.charges.data[0].payment_method_details.card.last4}`}
                  <div className="billing-history-row__card-icon">
                    {cardBrandMap[
                      data.charges.data[0].payment_method_details.card.brand
                    ]
                      ? cardBrandMap[
                          data.charges.data[0].payment_method_details.card.brand
                        ]
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </AnimateHeight>
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(BillingHistoryRow);
