import React, { useEffect, useRef, useState } from "react";

import cn from "classnames";
import {
  DropdownContainer,
  DropdownMenu,
  DropdownItem,
  SearchBox,
  SearchInput,
  DropdownInput,
  DropdownTags,
  DropdownTagItem,
  DropdownTagClose,
  DropdownSelectedValue,
  DropdownTools,
  DropdownTool,
  ArrowIcon,
  Options,
  CheckboxInput,
} from "./My.styled";

import CloseIcon from "../../../assets/images/ComponentIcons/CloseIcon";
import SearchIcon from "../../../assets/images/ComponentIcons/SearchIcon";
import get from "lodash/get";

const DropdownWithSearch = ({
  placeHolder,
  options,
  isMulti,
  available = true,
  isSearchable,
  onChange,
  value,
  getDirectionHandler,
  directionData,
  pageCount,
  setDirectionArrayOptions,
  scroll,
  directionDropdown,
  applicationDropdown,
  applicationArrayOptions,
  applicationsDataFromRedux,
  handleGetApplicationForDropdown,
  handleGetVacanciesForDropdown,
  vacanciesDataFromRedux,
  vacancyDropdown,
  clearValueInDropdown,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    value || (isMulti ? [] : null),
  );
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();
  const menuRef = useRef();
  const lastItemRef = useRef(null);
  useEffect(() => {
    if (clearValueInDropdown) {
      setSelectedValue([]);
    }
  }, [clearValueInDropdown]);
  useEffect(() => {
    if (scroll) {
      const optionsContainer = menuRef.current;
      const lastItem = lastItemRef.current;

      const handleScroll = () => {
        const optionsContainerRect = optionsContainer.getBoundingClientRect();
        const lastItemRect = lastItem.getBoundingClientRect();

        if (lastItemRect.bottom <= optionsContainerRect.bottom) {
          if (directionDropdown) {
            const nextPageNumber = pageCount?.currentPage + 1;
            if (nextPageNumber > pageCount?.totalPages) {
              return false;
            }
            getDirectionHandler(nextPageNumber);
          }
          if (applicationDropdown) {
            const nextPageNumberApplication =
              applicationsDataFromRedux?.currentPage + 1;
            if (
              nextPageNumberApplication > applicationsDataFromRedux?.totalPages
            ) {
              return false;
            }
            handleGetApplicationForDropdown({
              page: nextPageNumberApplication,
              emtyVacancy: true,
            });
          }
          if (vacancyDropdown) {
            const nextPageNumberVacancy =
              vacanciesDataFromRedux?.currentPage + 1;
            if (nextPageNumberVacancy > vacanciesDataFromRedux?.totalPages) {
              return false;
            }
            handleGetVacanciesForDropdown({
              page: nextPageNumberVacancy,
              statusVacancy: ["OPEN", "ONHOLD"],
            });
          }
        }
      };

      optionsContainer.addEventListener("scroll", handleScroll);

      return () => {
        optionsContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [options]);

  useEffect(() => {
    // defaultValue
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (showMenu) {
      setSearchValue("");
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (
        (inputRef.current &&
          !inputRef.current.contains(e.target) &&
          !menuRef.current.contains(e.target)) ||
        (inputRef.current &&
          !inputRef.current.contains(e.target) &&
          searchRef.current &&
          !searchRef.current.contains(e.target) &&
          !isMulti) ||
        (!isSearchable &&
          inputRef.current &&
          !inputRef.current.contains(e.target))
      ) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);
  const handleInputClick = (e) => {
    if (available) {
      setShowMenu(!showMenu);
    }
  };

  const getDisplay = () => {
    if (
      !selectedValue ||
      selectedValue.length === 0 ||
      selectedValue?.value === ""
    ) {
      return placeHolder;
    }

    if (isMulti) {
      return (
        <DropdownTags>
          {selectedValue.map((option) => (
            <DropdownTagItem key={option.value}>
              {option.label}
              <DropdownTagClose onClick={(e) => onTagRemove(e, option)}>
                <CloseIcon />
              </DropdownTagClose>
            </DropdownTagItem>
          ))}
        </DropdownTags>
      );
    }
    if (typeof selectedValue === "string") {
      return selectedValue;
    }
    return selectedValue.label;
  };

  const removeOption = (option) =>
    selectedValue.filter((o) => o.value !== option.value);

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);

    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option) => {
    let newValue;

    if (isMulti) {
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }
    if (typeof selectedValue === "string") {
      return selectedValue === option.label;
    }
    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0,
    );
  };

  return (
    <DropdownContainer className="input-dropdown">
      <DropdownInput ref={inputRef} onClick={handleInputClick}>
        <DropdownSelectedValue className="dropdown-selected-value">
          {getDisplay()}
        </DropdownSelectedValue>
        <DropdownTools className="dropdown-tools">
          <DropdownTool className="dropdown-tool">
            <ArrowIcon
              className={cn(
                "arrow_down",
                { arrow_up: showMenu },
                {
                  arrow_down__light_themes:
                    localStorage.getItem("themes") === "light",
                },
              )}
            />
          </DropdownTool>
        </DropdownTools>
      </DropdownInput>

      <DropdownMenu
        ref={menuRef}
        showMenu={showMenu}
        className={showMenu ? "open" : ""}
      >
        {isSearchable && (
          <SearchBox>
            <SearchIcon />
            <SearchInput
              onChange={onSearch}
              value={searchValue}
              ref={searchRef}
            />
          </SearchBox>
        )}
        <Options>
          {getOptions().map((option, index) => (
            <>
              {scroll ? (
                <DropdownItem
                  onClick={() => onItemClick(option)}
                  key={option.value}
                  ref={index === options.length - 1 ? lastItemRef : null}
                  // className={isSelected(option) ? 'selected' : ''}
                >
                  <CheckboxInput type="checkbox" checked={isSelected(option)} />
                  {option.label}
                </DropdownItem>
              ) : (
                <DropdownItem
                  onClick={() => onItemClick(option)}
                  key={option.value}

                  // className={isSelected(option) ? 'selected' : ''}
                >
                  <CheckboxInput type="checkbox" checked={isSelected(option)} />
                  {option.label}
                </DropdownItem>
              )}
            </>
          ))}
        </Options>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default DropdownWithSearch;
