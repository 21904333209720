export default function reportsPageAction(actionType, action) {
  if (actionType === "SET_LINE_GRAPH") {
    return {
      type: "SET_LINE_GRAPH",
      payload: action,
    };
  } else if (actionType === "SET_SUMMARY_DOUGHNUT_GRAPH") {
    return {
      type: "SET_SUMMARY_DOUGHNUT_GRAPH",
      payload: action,
    };
  } else if (actionType === "SET_REAL_TIME_DOUGHNUT_GRAPH") {
    return {
      type: "SET_REAL_TIME_DOUGHNUT_GRAPH",
      payload: action,
    };
  } else if (actionType === "SET_DATA_FROM_SERVER") {
    return {
      type: "SET_DATA_FROM_SERVER",
      payload: action,
    };
  } else if (actionType === "SET_PROJECTS") {
    return {
      type: "SET_PROJECTS",
      payload: action,
    };
  } else if (actionType === "SET_USER_PROJECTS") {
    return {
      type: "SET_USER_PROJECTS",
      payload: action,
    };
  } else if (actionType === "SET_TIME") {
    return {
      type: "SET_TIME",
      payload: action,
    };
  } else if (actionType === "SET_SELECTED_USERS") {
    return {
      type: "SET_SELECTED_USERS",
      payload: action,
    };
  } else if (actionType === "SET_SELECTED_PROJECTS") {
    return {
      type: "SET_SELECTED_PROJECTS",
      payload: action,
    };
  } else if (actionType === "SET_COMBINED_OPTION") {
    return {
      type: "SET_COMBINED_OPTION",
      payload: action,
    };
  } else if (actionType === "SET_SELECTED_PROJECTS_CROSS_TEAM") {
    return {
      type: "SET_SELECTED_PROJECTS_CROSS_TEAM",
      payload: action,
    };
  } else if (actionType === "SET_IS_DISABLED_FILTER") {
    return {
      type: "SET_IS_DISABLED_FILTER",
      payload: action,
    };
  } else if (actionType === "SELECT_NONE_ACTIVE") {
    return {
      type: "SELECT_NONE_ACTIVE",
      payload: action,
    };
  } else if (actionType === "SET_SELECTED_TEAM") {
    return {
      type: "SET_SELECTED_TEAM",
      payload: action,
    };
  } else if (actionType === "SET_CROSS_USERS_SELECTED") {
    return {
      type: "SET_CROSS_USERS_SELECTED",
      payload: action,
    };
  } else if (actionType === "SET_CROSS_REPORT_DATA") {
    return {
      type: "SET_CROSS_REPORT_DATA",
      payload: action,
    };
  } else if (actionType === "SET_TOTAL_TIME") {
    return {
      type: "SET_TOTAL_TIME",
      payload: action,
    };
  } else if (actionType === "SET_SELECTION_RANGE") {
    return {
      type: "SET_SELECTION_RANGE",
      payload: action,
    };
  } else if (actionType === "SET_START_DATE") {
    return {
      type: "SET_START_DATE",
      payload: action,
    };
  } else if (actionType === "SET_END_DATE") {
    return {
      type: "SET_END_DATE",
      payload: action,
    };
  } else if (actionType === "SET_USER_TEAMS_ARRAY") {
    return {
      type: "SET_USER_TEAMS_ARRAY",
      payload: action,
    };
  } else if (actionType === "SET_USER_TEAMS_PEOPLE_ARRAY") {
    return {
      type: "SET_USER_TEAMS_PEOPLE_ARRAY",
      payload: action,
    };
  } else if (actionType === "SET_LAST_FETCHED_SUMMARY") {
    return {
      type: "SET_LAST_FETCHED_SUMMARY",
      payload: action,
    };
  } else if (actionType === "SET_LAST_FETCHED_REAL_TIME") {
    return {
      type: "SET_LAST_FETCHED_REAL_TIME",
      payload: action,
    };
  } else if (actionType === "SET_USER_ACTIVITIES") {
    return {
      type: "SET_USER_ACTIVITIES",
      payload: action,
    };
  } else if (actionType === "UPDATE_FILTER") {
    return {
      type: "UPDATE_FILTER",
      payload: action,
    };
  } else if (actionType === "SET_FILTER_USERS") {
    return {
      type: "SET_FILTER_USERS",
      payload: action,
    };
  } else if (actionType === "SET_INIT_FILTERS") {
    return {
      type: "SET_INIT_FILTERS",
      payload: action,
    };
  } else if (actionType === "SET_IS_INITIAL_FETCH") {
    return {
      type: "SET_IS_INITIAL_FETCH",
      payload: action,
    };
  }
}
