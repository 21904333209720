import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as Moment from "moment";
import {
  WrapperModal,
  ModalOverlayRulesModal,
  CloseIconModal,
  HeaderWrapper,
  TitleWrapper,
  TitleText,
  TitleSubText,
  BodyWrapper,
  MainInfoWrapper,
  ColumnInfoBlock,
  MainRowInfoWrapper,
  ColumnInfoWrapper,
  ColumnInfoTitle,
  ColumnInfoSubTitle,
  TitleFordescription,
  StepVacancyBlock,
  CountOfStepWrapper,
  CountOfStepNumber,
  CountOfStepText,
  LinkBlock,
  LinkText,
  CopyLinkImage,
  DataLinkBlock,
  BlockForButton,
  ExitButton,
  EditButton,
  ApplicationItem,
} from "./My.styled";
import CustomScrollbar from "../../CustomScrollbar";
import closeIcon from "../../../../assets/images/icons/close_gray.svg";
import copyLinkImage from "../../../../assets/images/icons/copyLink.svg";
import { showNotificationAction } from "../../../../redux/actions/NotificationActions";

const ViewVacanciesModal = ({
  openViewModal,
  handleCloseViewModal,
  data,
  handleOpenEditModal,
  vocabulary,
}) => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseViewModal();
      }
    };

    if (openViewModal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("click", handleClickOutside);
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openViewModal, handleCloseViewModal]);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    // <ModalBg>
    <ModalOverlayRulesModal isClosing={openViewModal}>
      <WrapperModal ref={modalRef}>
        <HeaderWrapper>
          <TitleWrapper>
            <TitleText>{vocabulary.v_hr_people.header.vacancies}</TitleText>
            <TitleSubText>
              {vocabulary.v_hr_people.vacancies.preview}
            </TitleSubText>
            <EditButton onClick={() => handleOpenEditModal(data)}>
              {vocabulary.v_edit}
            </EditButton>
          </TitleWrapper>
          <CloseIconModal onClick={handleCloseViewModal} src={closeIcon} />
        </HeaderWrapper>

        <BodyWrapper>
          <CustomScrollbar>
            <MainInfoWrapper>
              <CountOfStepWrapper>
                <CountOfStepNumber>1</CountOfStepNumber>
                <CountOfStepText>
                  {vocabulary.v_hr_people.vacancies.vacancy_data}
                </CountOfStepText>
              </CountOfStepWrapper>
              <StepVacancyBlock>
                <MainRowInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.job_application}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.order?.length > 0
                          ? data?.order?.map((item, index) => (
                              <ApplicationItem key={item.id}>
                                {" "}
                                {item?.title}
                                {index < data?.order?.length - 1 && ", "}
                              </ApplicationItem>
                            ))
                          : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.vacancy_name}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.title ? data?.title : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.company}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.company ? data?.company : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                </MainRowInfoWrapper>

                <MainRowInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>{vocabulary.v_status}</ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.status
                          ? capitalizeFirstLetter(data?.status)
                          : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.priority}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {" "}
                        {data?.priority
                          ? capitalizeFirstLetter(data?.priority)
                          : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacanciesHeaderTable.deadline}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {" "}
                        {data?.deadline
                          ? Moment(data?.deadline).format("DD.MM.YYYY")
                          : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                </MainRowInfoWrapper>

                <MainRowInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.direction}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.board?.title ? data?.board?.title : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.experience}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.experience ? data?.experience : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.level}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.level ? capitalizeFirstLetter(data?.level) : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                </MainRowInfoWrapper>
                <ColumnInfoBlock>
                  <ColumnInfoTitle>
                    {vocabulary.v_hr_people.vacancies.job_description}
                  </ColumnInfoTitle>
                  <ColumnInfoSubTitle>
                    {data?.description ? data?.description : "-"}
                  </ColumnInfoSubTitle>
                </ColumnInfoBlock>

                <MainRowInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>PM</ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.pm ? data?.pm : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.number_of_positions}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.position_count ? data?.position_count : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                  <ColumnInfoWrapper>
                    <ColumnInfoBlock>
                      <ColumnInfoTitle>
                        {vocabulary.v_hr_people.vacancies.type_of_employment}
                      </ColumnInfoTitle>
                      <ColumnInfoSubTitle>
                        {data?.employment ? data?.employment : "-"}
                      </ColumnInfoSubTitle>
                    </ColumnInfoBlock>
                  </ColumnInfoWrapper>
                </MainRowInfoWrapper>
              </StepVacancyBlock>
            </MainInfoWrapper>

            <MainInfoWrapper>
              <CountOfStepWrapper>
                <CountOfStepNumber>2</CountOfStepNumber>
                <CountOfStepText>
                  {vocabulary.v_hr_people.vacancies.expected_salary}
                </CountOfStepText>
              </CountOfStepWrapper>
              <ColumnInfoBlock>
                <ColumnInfoTitle>
                  {vocabulary.v_hr_people.vacancies.salary_range}
                </ColumnInfoTitle>
                <ColumnInfoSubTitle>
                  {data?.minSalary ? data?.minSalary : "-"}{" "}
                  {data?.minSalary ? data?.currency : ""} -{" "}
                  {data?.maxSalary ? data?.maxSalary : "-"}{" "}
                  {data?.maxSalary ? data?.currency : ""}
                </ColumnInfoSubTitle>
              </ColumnInfoBlock>
            </MainInfoWrapper>

            <MainInfoWrapper>
              <CountOfStepWrapper>
                <CountOfStepNumber>3</CountOfStepNumber>
                <CountOfStepText>
                  {vocabulary.v_hr_people.vacancies.job_location}
                </CountOfStepText>
              </CountOfStepWrapper>

              <MainRowInfoWrapper>
                <ColumnInfoWrapper>
                  <ColumnInfoBlock>
                    <ColumnInfoTitle>{vocabulary.v_country}</ColumnInfoTitle>
                    <ColumnInfoSubTitle>
                      {data?.country ? data?.country : "-"}
                    </ColumnInfoSubTitle>
                  </ColumnInfoBlock>
                </ColumnInfoWrapper>
                <ColumnInfoWrapper>
                  <ColumnInfoBlock>
                    <ColumnInfoTitle>{vocabulary.v_city}</ColumnInfoTitle>
                    <ColumnInfoSubTitle>
                      {data?.city ? data?.city : "-"}
                    </ColumnInfoSubTitle>
                  </ColumnInfoBlock>
                </ColumnInfoWrapper>
                <ColumnInfoWrapper>
                  <ColumnInfoBlock>
                    <ColumnInfoTitle>
                      {vocabulary.v_hr_people.vacancies.job_type}
                    </ColumnInfoTitle>
                    <ColumnInfoSubTitle>
                      {data?.jobType ? data?.jobType : "-"}
                    </ColumnInfoSubTitle>
                  </ColumnInfoBlock>
                </ColumnInfoWrapper>
              </MainRowInfoWrapper>
            </MainInfoWrapper>

            <MainInfoWrapper>
              <CountOfStepWrapper>
                <CountOfStepNumber>4</CountOfStepNumber>
                <CountOfStepText>
                  {vocabulary.v_hr_people.vacancies.foreign_languages}
                </CountOfStepText>
              </CountOfStepWrapper>
              <ColumnInfoBlock>
                <ColumnInfoTitle>{vocabulary.v_language}</ColumnInfoTitle>
                <ColumnInfoSubTitle>
                  {data?.language ? data?.language : "-"}
                </ColumnInfoSubTitle>
              </ColumnInfoBlock>
            </MainInfoWrapper>

            <MainInfoWrapper>
              <CountOfStepWrapper>
                <CountOfStepNumber>5</CountOfStepNumber>
                <CountOfStepText>
                  {vocabulary.v_hr_people.vacancies.additional_information}
                </CountOfStepText>
              </CountOfStepWrapper>
              <StepVacancyBlock>
                <ColumnInfoBlock>
                  <ColumnInfoTitle>
                    {vocabulary.v_hr_people.vacancies.link_to_job}
                  </ColumnInfoTitle>
                  <DataLinkBlock>
                    {data?.vacancy_link?.length > 0
                      ? data?.vacancy_link?.map((item, index) => (
                          <LinkBlock>
                            <LinkText>{item.link}</LinkText>
                            <CopyLinkImage
                              onClick={() => {
                                navigator.clipboard.writeText(item.link);
                                dispatch(
                                  showNotificationAction({
                                    text: `${vocabulary.v_hr_people.vacancies.link_copied}`,
                                    type: "success",
                                  }),
                                );
                              }}
                              src={copyLinkImage}
                            />
                          </LinkBlock>
                        ))
                      : "-"}
                  </DataLinkBlock>
                </ColumnInfoBlock>

                <ColumnInfoBlock>
                  <ColumnInfoTitle>
                    {vocabulary.v_hr_people.vacancies.responsibilities}
                  </ColumnInfoTitle>
                  <ColumnInfoSubTitle>
                    {data?.duties ? data?.duties : "-"}
                  </ColumnInfoSubTitle>
                </ColumnInfoBlock>

                <ColumnInfoBlock>
                  <ColumnInfoTitle>
                    {vocabulary.v_hr_people.vacancies.requirements}
                  </ColumnInfoTitle>
                  <ColumnInfoSubTitle>
                    {data?.requirement ? data?.requirement : "-"}
                  </ColumnInfoSubTitle>
                </ColumnInfoBlock>

                <ColumnInfoBlock>
                  <ColumnInfoTitle>
                    {vocabulary.v_hr_people.vacancies.nice_to_have}
                  </ColumnInfoTitle>
                  <ColumnInfoSubTitle>
                    {data?.advantage ? data?.advantage : "-"}
                  </ColumnInfoSubTitle>
                </ColumnInfoBlock>

                <ColumnInfoBlock>
                  <ColumnInfoTitle>
                    {vocabulary.v_hr_people.vacancies.working_conditions}
                  </ColumnInfoTitle>
                  <ColumnInfoSubTitle>
                    {data?.conditions ? data?.conditions : "-"}
                  </ColumnInfoSubTitle>
                </ColumnInfoBlock>
              </StepVacancyBlock>
            </MainInfoWrapper>
            <BlockForButton>
              <ExitButton onClick={handleCloseViewModal}>
                {vocabulary.v_hr_people.vacancies.exit}
              </ExitButton>
            </BlockForButton>
          </CustomScrollbar>
        </BodyWrapper>
      </WrapperModal>
    </ModalOverlayRulesModal>
  );
};

export default ViewVacanciesModal;
