import { instance } from "../configAPI";

export const getInitialBillwerkData = () =>
  instance({
    url: "payment/user-data",
    method: "GET",
  });

export const createPaymentOrder = (data: any) =>
  instance({
    url: "payment/order",
    method: "POST",
    data,
  });

export const getStripeCustomerData = () =>
  instance({
    url: "payment/user-data",
    method: "GET",
  });

export const getStripePlansData = () =>
  instance({
    url: "payment/plans",
    method: "GET",
  });

export const updateSubscription = ({
  paymentMethodId,
  priceId,
  couponId,
  productId,
}: any) =>
  instance({
    url: "payment/update-subscription",
    method: "POST",
    data: {
      paymentMethodId: paymentMethodId,
      priceId: priceId,
      productId: productId,
      ...(couponId && { couponId }),
    },
  });

export const finalizeSubscription = (subscriptionId: any) =>
  instance({
    url: "payment/finalize-subscription",
    method: "POST",
    data: {
      subscriptionId,
    },
  });

export const updatePaymentMethodDetails = ({
  paymentMethodId,
  paymentDetails,
}: any) =>
  instance({
    url: "payment/update-payment-details",
    method: "POST",
    data: {
      paymentDetails,
      paymentMethodId,
    },
  });

export const updatePaymentMethod = ({ paymentMethodId }: any) =>
  instance({
    url: "payment/update-payment-method",
    method: "POST",
    data: {
      paymentMethodId,
    },
  });

export const getPaymentHistoryData = (params = {}) =>
  instance({
    url: "payment/payment-history",
    method: "GET",
    params,
  });
export const getPaymentTrialPeriod = ({ priceId, couponId, productId }: any) =>
  instance({
    url: `/payment/start-trial`,
    method: "POST",
    data: {
      priceId,
      couponId,
      productId,
    },
  });
