export const getProjectColor = (color) => {
  switch (color) {
    case "red":
      return "#f04747";
      break;
    case "green":
      return "#00b173";
      break;
    case "blue":
      return "#05a5ff";
      break;
    case "yellow":
      return "#f1c40f";
      break;
    case "violet":
      return "#9147f0";
      break;
    case "pink":
      return "#ff51a4";
      break;
    case "orange":
      return "#ff7a00";
      break;
    case "grey":
      return "var(--bg-avatar-client)";
      break;
  }
};
