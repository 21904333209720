import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// Actions
import {
  getInvoicesList,
  addInvoice,
  changePage,
  editInvoicePaymentStatus,
  deleteInvoiceById,
  getInvoicesCountsByStatusAction,
  resetInvoicesParams,
  changeInitialLoaderAction,
  addSearchValueInvoice,
  addSearchStatusInvoice,
  addSearchPageInvoice,
  addInvoiceError,
} from "../../redux/actions/InvoicesActions";
import { showNotificationAction } from "../../redux/actions/NotificationActions";

// Styles
import "./style.scss";

// Components
import { Loading } from "../../components/Unstructured/Loading";
import CustomScrollbar from "../../components/Unstructured/CustomScrollbar";
import LastInvoicesList from "../../components/Unstructured/InvoicePageComponents/LastInvoicesList";
import AllInvoicesList from "../../components/Unstructured/InvoicePageComponents/AllInvoicesList";
import SendInvoiceModal from "../../components/Unstructured/InvoicePageComponents/SendInvoiceModal";
import TotalInvoiceCounersComponent from "../../components/Unstructured/InvoicePageComponents/TotalInvoiceCounersComponent";
import blankInvoice from "../../assets/images/invoice_picture.png";
import PageHeader from "../../components/Unstructured/PageHeader/index";
import SearchComponent from "../../components/Unstructured/SearchComponent";
import { checkAccessByRole, ROLES } from "../../services/authentication";
import DeleteInvoiceModal from "../../components/Unstructured/DeleteInvoiceModal/index";
import FilterByStatus from "../../components/Unstructured/FilterByStatus";
import AddPaymentModal from "../../components/Unstructured/InvoicePageComponents/AddPaymentModal";
import { BlankListComponent } from "../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import { NoActiveInvoices } from "../../components/Unstructured/NoActiveInvoices";
// Functions
import { calculateTotal } from "../InvoicesPageDetailed";
import ConfirmationModal from "../../components/Unstructured/ConfirmationModal";
import InvoiceStatusModal from "../../components/Unstructured/InvoiceStatusModal";

class InvoicesPage extends Component {
  state = {
    sendInvoiceModalData: null,
    copiedInvoice: false,
    searchValue:
      this.props.location.propsSearch ||
      this.props.searchValue.searchValueInvoice ||
      "",
    searchPage: !isNaN(+this.props.match.params.pageType)
      ? +this.props.match.params.pageType - 1
      : this.props.page,
    confirmationWindowFlag: false,
    editConfirmationWindowFlag: false,
    statusWindowFlag: false,
    currentInvoice: null,
    modalOpeningId: false,
    filterStatus:
      this.props.location.propsSearchStatus ||
      this.props.searchStatus.searchStatusInvoice ||
      localStorage.getItem("statusLocalStorage") ||
      "all",
    resetStatus: "all",
    partialPaymentModal: false,
    lastFourInvoices: [],
  };

  componentDidMount() {
    if (
      this.props.userRole &&
      checkAccessByRole(this.props.userRole, [
        ROLES.ROLE_OWNER,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_INVOICES_MANAGER,
      ])
    ) {
      const { history, searchValue } = this.props;

      if (history.action === "POP" && searchValue?.searchValueInvoice) {
        this.setSearchValue(searchValue.searchValueInvoice);
      }

      this.props.getInvoicesList({
        page: this.state.searchPage,
        limit: this.props.limit,
        isInitialFetching: true,
        searchValue:
          (history.action === "POP" && searchValue?.searchValueInvoice) ||
          this.state.searchValue,
        status: this.state.filterStatus,
      });
      this.props.getInvoicesCountsByStatusAction();
    } else {
      this.props.changeInitialLoaderAction(false);
    }

    if (this.props.location.propsSearch) {
      this.props.changePage(0);
      this.props.getInvoicesList({
        page: this.state.searchPage,
        limit: this.props.limit,
        searchValue: this.state.searchValue,
        status: this.state.filterStatus,
      });
    }

    if (this.props.location.propsSearchStatus) {
      this.props.changePage(0);
      this.props.getInvoicesList({
        page: this.state.searchPage,
        limit: this.props.limit,
        searchValue: this.state.searchValue,
        status: this.state.filterStatus,
      });
    }
    if (this.props.location.pathname === "/invoices") {
      const { statusLocalStorage } = this.state;
      localStorage.setItem("statusLocalStorage", "all");
      this.props.getInvoicesList({
        page: 0,
        limit: this.props.limit,
        searchValue: this.state.searchValue,
        status: this.state.resetStatus,
      });
      this.props.history.push("invoices/");

      if (this.state.filterStatus === undefined) {
        this.setState({ filterStatus: "all" });
      } else if (
        this.state.filterStatus !== "all" &&
        this.props.searchStatus.searchStatusInvoice === undefined
      ) {
        this.setState({ filterStatus: "all" });
      } else if (
        this.props.searchPage.searchPageInvoice !== undefined &&
        this.state.filterStatus !== "all"
      ) {
        this.setState({ filterStatus: "all" });
        this.props.searchStatus.searchStatusInvoice = "all";
        this.props.getInvoicesList({
          page: 0,
          limit: this.props.limit,
          searchValue: this.state.searchValue,
          status: this.state.resetStatus,
        });
      }
    }
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userRole !== this.props.userRole) {
      if (
        checkAccessByRole(this.props.userRole, [
          ROLES.ROLE_OWNER,
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_INVOICES_MANAGER,
        ])
      ) {
        this.props.getInvoicesList({
          page: 0,
          limit: this.props.limit,
          isInitialFetching: true,
          status: this.state.filterStatus,
        });
        this.props.getInvoicesCountsByStatusAction();
      } else {
        this.props.changeInitialLoaderAction(false);
      }
    }
    // Не зрозуміла частина коду, яка ніби не впливає на логіку,
    // окрім як вносить баг у повторне дублювання інвойса в списку.

    // if (
    //   prevProps.isFetching !== this.props.isFetching &&
    //   !this.props.isFetching
    // ) {
    //   if (this.state.copiedInvoice) {
    //     this.props.getInvoicesList({
    //       page: this.props.page,
    //       limit: this.props.limit,
    //       searchValue: this.state.searchValue,
    //       status: this.state.filterStatus,
    //     });
    //     this.setState({ copiedInvoice: false }, () => {
    //       this.props.history.push(
    //         `/invoices/update/${this.props.copiedInvoiceId}`,
    //       );
    //     });
    //   }
    // }

    if (prevState.searchValue !== this.state.searchValue) {
      this.props.changePage(0);
      this.props.getInvoicesList({
        page: 0,
        limit: this.props.limit,
        searchValue: this.state.searchValue,
        status: this.state.filterStatus,
      });
    }

    if (
      this.state.lastFourInvoices !== this.props.invoices &&
      this.props.invoices.length !== 0
    ) {
      this.setState({ lastFourInvoices: this.props.invoices });
    }
    if (prevProps.invoices !== this.props.invoices) {
      this.setState({ lastFourInvoices: this.props.invoices });
    }
  }

  openCloseModal = (value) => {
    this.setState({ modalOpeningId: value });
  };

  closeStatusModal = () => {
    this.setState({ statusWindowFlag: false });
  };

  getInvocesListWithCounts = () => {
    this.props.getInvoicesList({
      page: this.props.page,
      limit: this.props.limit,
      searchValue: this.state.searchValue,
      status: this.state.filterStatus,
    });
    this.props.getInvoicesCountsByStatusAction();
  };

  deleteInvoice = async(id) => {
    this.openCloseModal(false);
    await this.props.deleteInvoiceById(id);
    this.getInvocesListWithCounts();
  };

  setSearchValue = (value) => {
    this.setState({ searchValue: value });

    this.props.addSearchValueInvoice({
      searchValueInvoice: value,
    });
  };

  handleSearch = () => {
    this.props.changePage(0);
    this.props.getInvoicesList({
      page: 0,
      limit: this.props.limit,
      searchValue: this.state.searchValue,
      status: this.state.filterStatus,
    });
  };

  handleReset = () => {
    this.setSearchValue("");
    this.props.changePage(0);
    this.props.getInvoicesList({
      page: 0,
      limit: this.props.limit,
      status: this.state.filterStatus,
    });
  };

  toggleSendInvoiceModal = (sendInvoiceModalData = null) => {
    this.setState({ sendInvoiceModalData });
  };

  changePage = (page) => {
    this.props.changePage(page.selected);
    this.props.getInvoicesList({
      page: page.selected,
      limit: this.props.limit,
      searchValue: this.state.searchValue,
      status: this.state.filterStatus,
    });

    this.props.addSearchPageInvoice({
      searchPageInvoice: page.selected,
    });
    this.props.history.push(`/invoices/${page.selected + 1}`);
  };

  prepareBodyRequest = (oldObject) => {
    const dateDue = new Date();

    dateDue.setDate(dateDue.getDate() + 1);
    const newObject = {};

    newObject.vendorId = this.props.defaultUserSender.id;
    newObject.sender = oldObject.invoice_vendor;
    newObject.recipient = oldObject.to;
    newObject.dateFrom = new Date();
    newObject.dateDue = dateDue;
    newObject.timezoneOffset = oldObject.timezone_offset;
    newObject.currency = oldObject.currency;
    newObject.comment = oldObject.comment;
    newObject.projects = oldObject.projects;
    newObject.image = oldObject.logo;
    newObject.discount = oldObject.discount;
    newObject.reference = oldObject.reference;
    return newObject;
  };

  copyInvoice = async (oldInvoice) => {
    const newInvoice = this.prepareBodyRequest(oldInvoice);
    await this.props.addInvoice(newInvoice, true);
    if (
      this.props.error ===
      "Upgrade your plan to have an unlimited number of invoices."
    ) {
      await this.props.showNotificationAction({
        text: "Upgrade your plan to have an unlimited number of invoices.",
        type: "error",
      });
    }
    this.props.addInvoiceError(null);
    this.setState({ copiedInvoice: true });
    this.getInvocesListWithCounts();
  };

  confirmationModalHandler = () => {
    this.setState({
      confirmationWindowFlag: !this.state.confirmationWindowFlag,
    });
  };

  statusModalHandler = () => {
    this.setState({
      statusWindowFlag: !this.state.statusWindowFlag,
    });
  };

  editConfirmationModalToggler = () => {
    this.setState({
      editConfirmationWindowFlag: !this.state.editConfirmationWindowFlag,
    });
  };

  editConfirmationModalHandler = (invoice) => {
    this.setCurrentInvoice(invoice);
    this.editConfirmationModalToggler();
  };

  confirmEditHandler = () => {
    this.editConfirmationModalToggler();
    this.props.history.push(`/invoices/update/${this.state.currentInvoice.id}`);
  };

  cancelEditHandler = () => {
    this.editConfirmationModalToggler();
    this.setCurrentInvoice(null);
  };

  setCurrentInvoice = (invoice) => {
    this.setState({ currentInvoice: invoice });
  };

  confirmPaymentHandler = async (invoiceId, isPaid) => {
    if (this.state.confirmationWindowFlag) {
      this.cancelConfirmationHandler();
    }
    await this.props.editInvoicePaymentStatus(invoiceId, isPaid);
    this.getInvocesListWithCounts();
  };

  cancelConfirmationHandler = () => {
    this.confirmationModalHandler();
    this.setCurrentInvoice(null);
  };

  setFilterStatus = (status) => {
    this.setState({ filterStatus: status });
    this.props.changePage(0);
    this.props.getInvoicesList({
      page: 0,
      limit: this.props.limit,
      searchValue: this.state.searchValue,
      status,
    });

    this.props.addSearchStatusInvoice({
      searchStatusInvoice: status,
    });

    this.props.history.push(`/invoices/1`);
    const { statusLocalStorage } = this.state;
    localStorage.setItem("statusLocalStorage", status);
  };

  partialPaymentModalHandler = () => {
    this.setState({ partialPaymentModal: !this.state.partialPaymentModal });
  };

  render() {
    const {
      vocabulary,
      isMobile,
      invoices,
      page,
      pageCount,
      grandTotal,
      isFetching,
      history,
      isInitialFetching,
      userRole,
      totalSumm,
    } = this.props;
    const {
      v_invoices,
      v_cat_not_invoices,
      v_add_new_invoice,
      v_confirm,
      v_cancel,
      v_payment_confirmation_window_confirm,
      v_payment_confirmation_window_cancel,
      v_page_access_denied,
      v_page_access_request_owner,
      v_edit_confirmation_window,
      v_filter_all_invoices,
      v_filter_draft,
      v_filter_awaiting,
      v_filter_reviewed,
      v_filter_paid,
      v_filter_overdue,
      v_no_active_invoices,
      v_no_active_invoices_text,
      v_create_new_invoice,
    } = vocabulary;

    const {
      sendInvoiceModalData,
      searchValue,
      confirmationWindowFlag,
      editConfirmationWindowFlag,
      currentInvoice,
      modalOpeningId,
      filterStatus,
      lastFourInvoices,
      statusWindowFlag,
    } = this.state;

    return (
      <Loading flag={isInitialFetching} mode="parentSize" withLogo={false}>
        <CustomScrollbar disableTimeEntriesFetch>
          <div
            className={classNames("wrapper-invoices-page", {
              "wrapper-invoices-page--blured":
                userRole &&
                !checkAccessByRole(userRole, [
                  ROLES.ROLE_OWNER,
                  ROLES.ROLE_ADMIN,
                  ROLES.ROLE_INVOICES_MANAGER,
                ]),
              "wrapper-invoices-page--not-invoices":
                invoices && invoices.length === 0,
            })}
          >
            <Loading flag={isFetching} mode="overlay" withLogo={false}>
              <div
                className={classNames("invoices-page-top", {
                  "invoices-page-top--mobile": isMobile,
                })}
              >
                <PageHeader title={v_invoices}>
                  {userRole &&
                    checkAccessByRole(userRole, [
                      ROLES.ROLE_OWNER,
                      ROLES.ROLE_ADMIN,
                      ROLES.ROLE_INVOICES_MANAGER,
                    ]) && (
                      <>
                        <div className="invoices-page-top__search-input">
                          <SearchComponent
                            value={searchValue}
                            setValue={this.setSearchValue}
                            handleReset={this.handleReset}
                            handleSearch={this.handleSearch}
                          />
                        </div>
                        <Link
                          to="/invoices/create"
                          className="header-wrapper__child-button"
                        >
                          {v_add_new_invoice}
                        </Link>
                      </>
                    )}
                </PageHeader>

                <div className="invoices-page-top__totals">
                  <TotalInvoiceCounersComponent
                    totalSumm={totalSumm}
                    vocabulary={vocabulary}
                    grandTotal={grandTotal}
                  />
                </div>

                <div className="invoices-page-top__last-invoices">
                  <LastInvoicesList
                    isMobile={isMobile}
                    history={history}
                    copyInvoice={this.copyInvoice}
                    openCloseModal={this.openCloseModal}
                    invoicesNumber={invoices.length}
                    invoices={
                      invoices.length
                        ? invoices.slice(0, 4)
                        : lastFourInvoices.slice(0, 4)
                    }
                    vocabulary={vocabulary}
                    toggleSendInvoiceModal={this.toggleSendInvoiceModal}
                    confirmationModalHandler={this.confirmationModalHandler}
                    editConfirmationModalHandler={
                      this.editConfirmationModalHandler
                    }
                    setCurrentInvoice={this.setCurrentInvoice}
                    partialPaymentModalHandler={this.partialPaymentModalHandler}
                  />
                </div>
              </div>
              <FilterByStatus
                status={filterStatus}
                onClick={this.setFilterStatus}
                items={[
                  {
                    id: "all",
                    text: v_filter_all_invoices,
                  },
                  {
                    id: "draft",
                    text: v_filter_draft,
                  },
                  {
                    id: "awaiting",
                    text: v_filter_awaiting,
                  },
                  {
                    id: "reviewed",
                    text: v_filter_reviewed,
                  },
                  {
                    id: "paid",
                    text: v_filter_paid,
                  },
                  {
                    id: "overdue",
                    text: v_filter_overdue,
                  },
                ]}
              />
              {Boolean(invoices.length) && (
                <div className="invoices-page-bottom">
                  <div className="invoices-page-bottom__all-invoices">
                    <AllInvoicesList
                      toggleSendInvoiceModal={this.toggleSendInvoiceModal}
                      invoices={invoices}
                      vocabulary={vocabulary}
                      history={history}
                      copyInvoice={this.copyInvoice}
                      isMobile={isMobile}
                      page={page}
                      pageCount={pageCount}
                      openCloseModal={this.openCloseModal}
                      changePage={this.changePage}
                      grandTotal={grandTotal}
                      confirmationModalHandler={this.confirmationModalHandler}
                      editConfirmationModalHandler={
                        this.editConfirmationModalHandler
                      }
                      statusModalHandler={this.statusModalHandler}
                      setCurrentInvoice={this.setCurrentInvoice}
                      partialPaymentModalHandler={
                        this.partialPaymentModalHandler
                      }
                    />
                  </div>
                </div>
              )}
              {invoices && invoices.length === 0 && (
                <div className="not-invoices">
                  <NoActiveInvoices
                    title={v_no_active_invoices}
                    text={v_no_active_invoices_text}
                    linkText={v_create_new_invoice}
                  />
                </div>
                // <BlankListComponent
                //   subtext={v_cat_not_invoices}
                //   position={{ top: "100%" }}
                // />
              )}
            </Loading>
          </div>
          {userRole &&
            !checkAccessByRole(userRole, [
              ROLES.ROLE_OWNER,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_INVOICES_MANAGER,
            ]) && (
              <div className="invoices-page__access-blocked">
                <div className="invoices-page__access-blocked-text-block">
                  <span className="invoices-page__access-blocked-text">
                    {v_page_access_denied}
                  </span>
                  <span className="invoices-page__access-blocked-text">
                    {v_page_access_request_owner}
                  </span>
                </div>
              </div>
            )}
        </CustomScrollbar>
        {confirmationWindowFlag && (
          <ConfirmationModal
            vocabulary={vocabulary}
            confirmHandler={() =>
              this.confirmPaymentHandler(
                currentInvoice.id,
                !currentInvoice.payment_status,
              )
            }
            cancelHandler={this.confirmationModalHandler}
          >
            {currentInvoice.payment_status
              ? v_payment_confirmation_window_cancel
              : v_payment_confirmation_window_confirm}
          </ConfirmationModal>
        )}
        {editConfirmationWindowFlag && (
          <ConfirmationModal
            vocabulary={vocabulary}
            confirmHandler={this.confirmEditHandler}
            cancelHandler={this.cancelEditHandler}
          >
            {v_edit_confirmation_window}
          </ConfirmationModal>
        )}
        {statusWindowFlag && (
          <InvoiceStatusModal
            invoice={this.state.currentInvoice}
            vocabulary={vocabulary}
            closeModal={this.closeStatusModal}
            getInvoices={this.getInvocesListWithCounts}
            confirmHandler={() =>
              this.confirmPaymentHandler(
                currentInvoice.id,
                !currentInvoice.payment_status,
              )
            }
            cancelHandler={this.confirmationModalHandler}
          />
        )}
        {sendInvoiceModalData && (
          <SendInvoiceModal
            closeModal={this.toggleSendInvoiceModal}
            vocabulary={vocabulary}
            invoice={sendInvoiceModalData}
            getInvoices={this.getInvocesListWithCounts}
          />
        )}
        {modalOpeningId && (
          <DeleteInvoiceModal
            deleteInvoice={this.deleteInvoice}
            openCloseModal={this.openCloseModal}
            modalOpeningId={modalOpeningId}
          />
        )}
        {this.state.partialPaymentModal && (
          <AddPaymentModal
            addPaymentModalHandler={this.partialPaymentModalHandler}
            confirmPaymentHandler={() =>
              this.confirmPaymentHandler(this.state.currentInvoice.id, true)
            }
            invoice={this.state.currentInvoice}
            total={calculateTotal(
              this.state.currentInvoice.projects,
              this.state.currentInvoice.discount,
            )}
          />
        )}
      </Loading>
    );
  }
}

const mapStateToProps = ({ invoicesReducer, userReducer, teamReducer }) => ({
  searchValue: invoicesReducer.searchValueInvoice,
  searchStatus: invoicesReducer.searchStatusInvoice,
  searchPage: invoicesReducer.searchPageInvoice,
  invoices: invoicesReducer.invoices,
  page: invoicesReducer.page,
  limit: invoicesReducer.limit,
  pageCount: invoicesReducer.pageCount,
  totalSumm: invoicesReducer.totalSumm,
  grandTotal: invoicesReducer.grandTotal,
  isFetching: invoicesReducer.isFetching,
  isInitialFetching: invoicesReducer.isInitialFetching,
  senderId: invoicesReducer.senderId,
  defaultUserSender: userReducer.user,
  copiedInvoiceId: invoicesReducer.copiedInvoiceId,
  userRole: teamReducer.currentTeam.data.role,
  error: invoicesReducer.error,
});

const mapDispatchToProps = {
  addSearchValueInvoice,
  addSearchStatusInvoice,
  addSearchPageInvoice,
  getInvoicesList,
  addInvoice,
  changePage,
  editInvoicePaymentStatus,
  deleteInvoiceById,
  getInvoicesCountsByStatusAction,
  resetInvoicesParams,
  changeInitialLoaderAction,
  showNotificationAction,
  addInvoiceError,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoicesPage),
);
