import React from "react";
import cn from "classnames";

import "./style.scss";

if (localStorage.getItem("themes") === null) {
  localStorage.setItem("themes", "dark");
}

const SwitcherThemeIcon = ({ period, handleChange, themes }) => (
  <div className="preriod-switcher-themes" onClick={handleChange}>
    {localStorage.getItem("themes") === "light" ? (
      <span>
        {" "}
        <svg className="preriod-switcher-themes__moon" />{" "}
      </span>
    ) : (
      <span>
        {" "}
        <svg className="preriod-switcher-themes__sun" />{" "}
      </span>
    )}
  </div>
);

export default SwitcherThemeIcon;
