import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import cn from "classnames";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import classNames from "classnames";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomTippy from "../../components/Unstructured/Tooltip/CustomTippy";

// Actions
import {
  toggleModal,
  changeUserData,
  deleteUserAction,
} from "../../redux/actions/UserActions";
import { showNotificationAction } from "../../redux/actions/NotificationActions";
import { setLanguage } from "../../redux/actions/LanguageActions";

// Components
import ChangePasswordModal from "../../components/Unstructured/ChangePasswordModal";
import SwitchLanguage from "../../components/Unstructured/SwitchLanguage";
import Input from "../../components/Unstructured/BaseComponents/Input";
import Avatar from "../../components/Unstructured/AvatarEditor";
import SelectDateFormat from "../../components/Unstructured/SelectDateFormat";
import SelectTimeFormat from "../../components/Unstructured/SelectTimeFormat";
import SelectFirstDayOfWeek from "../../components/Unstructured/SelectFirstDayOfWeek";
import SelectDurationTimeFormat from "../../components/Unstructured/SelectDurationTimeFormat";
import SocialConnect from "../../components/Unstructured/SocialConnect";
import SwitchJiraType from "../../components/Unstructured/SwitchJiraType";
import TechnologyComponent from "../../components/Unstructured/TechnologyComponent";
import CustomScrollbar from "../../components/Unstructured/CustomScrollbar";
import CountriesDropdown from "../../components/Unstructured/CountriesDropdown";
import SwitcherTheme from "../../components/Unstructured/SwitcherTheme";
import DatepickerSelect from "../../components/Unstructured/DatepickerSelect";
import UserDeleteModal from "../../components/Unstructured/DeleteUserModal";
// Services

// Config
import { AppConfig } from "../../config";

// Styles
import "./style.scss";

import countries from "../../components/Unstructured/CountriesDropdown/countriesFlat.json";
import { requestChangeUserData, userCheckPassword } from "../../api/user";
import { verifyJiraToken } from "../../api/sync";

const OpenJiraMenuIfValidationFails = (props) => {
  const { formik, open, onSubmissionError } = props;
  const { jiraUrl, jiraUserName, jiraPassword } = formik.errors;
  const effect = () => {
    if ((jiraUrl || jiraUserName || jiraPassword) && !open) {
      onSubmissionError();
    }
  };

  React.useEffect(effect, [formik.submitCount, formik.errors]);
  return null;
};

class UserSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rotateArrowLoop: false,
      userSetJiraSync: false,
      language: props.vocabulary.lang,
      phone: {
        value: "",
      },
      zip: {
        value: "",
      },
      state: {
        value: "",
      },
      city: {
        value: "",
      },
      country: {
        value: "",
      },
      inputs: {
        jiraType: {
          value: "",
        },
        syncJiraStatus: {
          checked: false,
          type: "checkbox",
          name: "syncJiraStatus",
        },
      },
      userTechnologies: [],
      isOpenCountriesDropdown: false,
      themes: localStorage.getItem("themes"),
      birthday: this.props.userReducer.user.birthday || null,
      userDeleteModal: false,
      maxLength: 100,
      availableSymbols: 100 - this.props.userReducer.user.username.length,
      newav: 0,
    };
  }
  handleChangeInput = (event) => {
    const targetValue = event.target.value;
    const newAvailableSymbols = this.state.maxLength - targetValue.length;

    if (newAvailableSymbols < 0) {
      return;
    }

    this.setState({ availableSymbols: newAvailableSymbols });

    return newAvailableSymbols;
  };

  checkValidPhone = (phone, code) => {
    const phoneWithoutPlus = phone.charAt(0) === "+" ? phone.substr(1) : phone;
    const phoneWithoutCode = phoneWithoutPlus.replace(code, "");

    if (phoneWithoutCode.length) {
      const editedPhone = `+${code} ${phoneWithoutCode}`;

      return editedPhone;
    }

    return `+${code}`;
  };

  changeUserSetting = async ({ userName: username, zip, ...rest }) => {
    const {
      vocabulary,
      changeUserData,
      userReducer,
      showNotificationAction,
      setLanguage,
    } = this.props;
    const { v_a_data_updated_ok, lang } = vocabulary;

    const { phone, userTechnologies, language } = this.state;

    const { id } = userReducer.user;
    const userData = {
      ...rest,
      username,
      phone: (phone.value || "").trim().indexOf(" ") > -1 ? phone.value : "",
      language: language.short,
      technologies: userTechnologies.map((item) => item.id),
      zip: String(zip),
      birthday: this.state.birthday,
    };

    try {
      const result = await requestChangeUserData(userData, id);

      if (result.data) {
        changeUserData(result.data);
        showNotificationAction({ text: v_a_data_updated_ok, type: "success" });
        this.setDataToForm();
        this.setState({ userSetJiraSync: false });
        setLanguage(language.short);
      }
    } catch (error) {
      this.setDataToForm();
      if (error.response && error.response.data.message) {
        const errorMsg = error.response.data.message;

        showNotificationAction({ text: vocabulary[errorMsg], type: "error" });
      } else {
        console.log(error);
      }
    }
  };

  onSubmitHandler = (values) => {
    const { vocabulary, showNotificationAction } = this.props;
    const { inputs, userSetJiraSync } = this.state;
    const { syncJiraStatus, jiraType } = inputs;
    const { jiraUserName, jiraPassword, jiraUrl } = values;
    const userData = Object.keys(values).reduce((acc, curr) => {
      if (
        curr === "jiraUserName" ||
        curr === "jiraPassword" ||
        curr === "jiraUrl"
      ) {
        return acc;
      }
      return { ...acc, [curr]: values[curr] };
    }, {});

    if (userSetJiraSync) {
      if (syncJiraStatus.checked) {
        userData.tokenJira = "";
        try {
          userData.tokenJira = btoa(`${jiraUserName}:${jiraPassword}`);
        } catch (e) {
          showNotificationAction({
            text: vocabulary["ERROR.TIMER.JIRA_SYNC_FAILED"],
            type: "error",
          });
          return false;
        }

        userData.urlJira = jiraUrl;
        userData.typeJira = jiraType.value;
        userData.loginJira = jiraUserName;
      } else {
        userData.tokenJira = "";
        userData.urlJira = "";
        userData.typeJira = "";
        userData.loginJira = "";
      }
    }
    if (userData.tokenJira && jiraPassword === "") {
      delete userData.tokenJira;
    }

    this.changeUserSetting(userData);
  };

  changeSyncJiraStatus = (event) => {
    const { name, checked } = event.target;

    this.setState((prevState) => ({
      userSetJiraSync: true,
      inputs: {
        ...prevState.inputs,
        [name]: {
          ...prevState.inputs[name],
          checked,
        },
      },
    }));
  };

  selectedJiraType = (value) => {
    this.setState((prevState) => ({
      inputs: {
        ...prevState.inputs,
        jiraType: {
          value,
        },
      },
    }));
  };

  verifyJiraAction = async (formik) => {
    const { jiraUserName, jiraPassword, jiraUrl } = formik.values;

    await formik.validateForm();
    this.verifyJiraAuth(jiraUserName, jiraPassword, jiraUrl);
  };

  verifyJiraAuth = async (jiraUserName, jiraPassword, jiraUrl) => {
    this.setState({ rotateArrowLoop: true });
    const { vocabulary, showNotificationAction } = this.props;

    let tokenJira = "";

    try {
      tokenJira = btoa(`${jiraUserName}:${jiraPassword}`);
      const result = await verifyJiraToken({
        token: tokenJira,
        urlJira: jiraUrl,
      });

      this.setState({ rotateArrowLoop: false });
      showNotificationAction({
        text: vocabulary[result.data.message],
        type: "success",
      });
      return true;
    } catch (error) {
      this.setState({ rotateArrowLoop: false });
      if (error.response && error.response.data.message) {
        const errorMsg = error.response.data.message;

        showNotificationAction({ text: vocabulary[errorMsg], type: "error" });
        return false;
      } else {
        console.log(error);
        showNotificationAction({
          text: vocabulary["ERROR.TIMER.JIRA_SYNC_FAILED"],
          type: "error",
        });
        return false;
      }
    }
  };

  switchVisibilityJiraForm = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      userSetJiraSync: !prevState.userSetJiraSync,
    }));
  };

  closeDropdown = (e) => {
    const { isOpenCountriesDropdown } = this.state;

    if (isOpenCountriesDropdown && !e.target.closest(".flag-input-container")) {
      this.setState({ isOpenCountriesDropdown: false });
    }
  };

  componentDidMount() {
    this.setDataToForm();
    document.addEventListener("mousedown", this.closeDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeDropdown);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.userReducer.user.avatar !== nextProps.userReducer.user.avatar
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { userReducer } = this.props;

    if (!_.isEqual(prevProps.userReducer.user, userReducer.user)) {
      this.setDataToForm();
    }
  }

  getCountry() {
    const { vocabulary } = this.props;

    switch (vocabulary.lang.short) {
      case "ru":
        return "RU";

      case "de":
        return "DE";

      case "it":
        return "IT";

      case "uk":
        return "UA";

      default:
        break;
    }
  }

  openUserDeleteModal = () => {
    this.setState({ userDeleteModal: true });
  };
  closeUserDeleteModal = () => {
    this.setState({ userDeleteModal: false });
  };
  deleteUser = () => {
    this.props.deleteUserAction();
  };
  deleteUserSubmitForm = async ({ password }) => {
    const { vocabulary, showNotificationAction } = this.props;
    const { v_user_deleted, v_a_confirm_password_error } = vocabulary;

    try {
      await userCheckPassword({
        password,
      });

      this.deleteUser();
      this.closeUserDeleteModal();
    } catch (error) {
      if (error.response && error.response.data.message) {
        const errorMsg = error.response.data.message;
        if (errorMsg === "ERROR.USER.CURRENT_PASSWORD_WRONG") {
          showNotificationAction({
            text: v_a_confirm_password_error,
            type: "error",
          });
        }
      } else {
        console.log(error);
      }
    }
  };

  // changeThemes = () => {
  //   if (localStorage.getItem("themes") === "dark") {
  //     localStorage.setItem("themes", "light");
  //   } else {
  //     localStorage.setItem("themes", "dark");
  //   }
  //   this.setState({
  //     themes: localStorage.getItem("themes"),
  //   });
  // };

  render() {
    const { vocabulary, isMobile, userReducer, lang, showNotificationAction, currentTeam } =
      this.props;
    const {
      v_my_profile,
      v_save,
      v_change_password,
      v_phone,
      v_jira_synchronization,
      v_password,
      v_type,
      v_enter_to,
      v_to_get_token,
      v_login,
      v_show,
      v_hide,
      v_verify,
      v_country,
      v_state,
      v_city,
      v_address_lowC,
      v_zip,
      e_mail,
      full_name,
      v_company_name,
      v_tags,
      v_tags_tooltip,
      v_physical_address,
      v_error_company_name,
      v_time_date,
      v_add_your_password,
      v_search,
      v_empty,
      v_cv,
      select_country,
      v_resource_planning_page,
      v_delete_user,
      v_email_error,
    } = vocabulary;

    const {
      inputs,
      phone,
      userSetJiraSync,
      rotateArrowLoop,
      isOpenCountriesDropdown,
      userDeleteModal,
    } = this.state;
    const { syncJiraStatus, jiraType } = inputs;
    const { checked } = syncJiraStatus;

    const { user } = this.props.userReducer;
    const {
      email,
      username,
      companyName,
      country,
      state,
      city,
      zip,
      cv,
      urlJira,
      loginJira,
      birthday,
    } = user;

    const disabledSubscriptionCheck = currentTeam.data.userPlan?.name === "Free";

    return (
      <div
        className={classNames("user-settings", {
          "wrapper_user_setting_page--mobile": isMobile,
        })}
        data-theme={this.props.themes}
      >
        {Object.prototype.toString.call(userReducer.changePasswordModal) ===
          "[object Boolean]" &&
          userReducer.changePasswordModal && <ChangePasswordModal />}
        <div className="data_container">
          <CustomScrollbar disableTimeEntriesFetch>
            <div className="header_user_setting">
              <div>{v_my_profile}</div>
              <button onClick={(e) => this.openChangePasswordModal()}>
                {v_change_password}
              </button>
            </div>
            <div className="body_user_setting">
              <div className="column column-avatar-social">
                <Avatar />
                {AppConfig.socialAuth.active && <SocialConnect />}
                {/* <div>
                  <SwitcherTheme
                    themes={this.state.themes}
                    handleChange={this.changeThemes}
                  />
                </div> */}
              </div>
              <Formik
                // enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  email: email || "",
                  userName: username || "",
                  companyName: companyName || "",
                  country: country || this.getCountry(),
                  state: state || "",
                  city: city || "",
                  zip: zip || "",
                  cv: cv || "",
                  jiraUrl: urlJira,
                  jiraUserName: loginJira,
                  jiraPassword: "",
                  birthday: birthday || null,
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .max(72, "v_email_error")
                    .email("v_a_incorect_email")
                    .required("v_v_required"),
                  userName: Yup.string().required("v_v_required"),
                  // .matches(
                  //   /^(?! )(?!.* $)(?!(?:.* ){10}).*$/,
                  //   "no_valid_user_name",
                  // ),
                  companyName: Yup.string().required("v_v_required"),
                  jiraUrl:
                    checked &&
                    Yup.string()
                      .url("v_v_incorect_url")
                      .required("v_v_required"),
                  jiraUserName:
                    checked && Yup.string().required("v_v_required"),
                  zip: Yup.string().matches(
                    /^[a-zA-Z0-9-\]\[\s]{0,11}$/, //eslint-disable-line
                    "no_valid_zip",
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  this.onSubmitHandler(values);
                  setSubmitting(false);
                }}
              >
                {(formik) => (
                  <form
                    autoComplete="off"
                    className="column column-inputs"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <OpenJiraMenuIfValidationFails
                      formik={formik}
                      open={userSetJiraSync}
                      onSubmissionError={(e) =>
                        this.setState({ userSetJiraSync: true })
                      }
                    />
                    <input
                      autoComplete="new-password"
                      className="fakecredentials"
                      type="text"
                      name="fakeusernameremembered"
                    />
                    <input
                      autoComplete="new-password"
                      className="fakecredentials"
                      type="email"
                      name="fakeuseremailremembered"
                    />
                    <input
                      autoComplete="new-password"
                      className="fakecredentials"
                      type="password"
                      name="fakepasswordremembered"
                    />
                    <div className="first-inputs-wrapper">
                      <Input
                        config={{
                          id: "userName",
                          name: "userName",
                          type: "text",
                          onChange: (e) => {
                            this.handleChangeInput(e);
                            formik.handleChange(e);
                          },
                          onBlur: formik.handleBlur,
                          value: formik.values.userName
                            .replace("  ", " ")
                            .trimStart(),
                          // .replace(/^\s+|\s+$/gm, ""),
                        }}
                        errorMsg={formik.errors.userName}
                        label={`${full_name}*`}
                        withValidation
                        dark
                        maxLength
                      />
                      <div className="input-max-length-name">
                        {this.state.availableSymbols}
                      </div>

                      <Input
                        config={{
                          id: "email",
                          name: "email",
                          type: "email",
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                          value: formik.values.email,
                        }}
                        errorMsg={formik.errors.email}
                        label={`${e_mail}*`}
                        withValidation
                        dark
                      />
                    </div>
                    <div className="second-inputs-wrapper">
                      <div className="input_container_phone">
                        <span className="input_title">{v_phone}</span>
                        <ReactPhoneInput
                          defaultCountry="de"
                          countryCodeEditable={false}
                          autoFormat={false}
                          placeholder=""
                          inputExtraProps={{ value: phone.value }}
                          value={phone.value}
                          onChange={(value, data) => {
                            this.setState({
                              phone: {
                                value: this.checkValidPhone(
                                  value,
                                  data.dialCode,
                                ),
                              },
                            });
                          }}
                        />
                      </div>

                      <Input
                        config={{
                          id: "companyName",
                          name: "companyName",
                          type: "text",
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                          value: formik.values.companyName,
                        }}
                        label={`${v_company_name}*`}
                        errorMsg={
                          formik.values.companyName === ""
                            ? formik.errors.companyName &&
                              showNotificationAction({
                                text: v_error_company_name,
                                type: "warning",
                              })
                            : ""
                        }
                        withValidation
                        dark
                      />
                    </div>
                    <div className="third-input-container">
                      <SwitchLanguage
                        dropdown
                        language={this.state.language}
                        onChangeLanguage={(value) =>
                          this.setState({ language: value })
                        }
                      />
                      <div className="third-input-container__calendar-birthday">
                        <span className="third-input-container__calendar-birthday__title">
                          {v_resource_planning_page.v_birthday_date}
                        </span>
                        <DatepickerSelect
                          disableToolbar={false}
                          date={formik.values.birthday}
                          onChangeDate={(date) => {
                            this.setState({ birthday: date });
                          }}
                          placeholder={v_resource_planning_page.v_birthday_date}
                          dropdownTitle={
                            v_resource_planning_page.v_birthday_date
                          }
                        />
                      </div>
                    </div>
                    <div className="user-settings__select-location">
                      <div className="user-settings__select-location-title">
                        {v_physical_address}
                      </div>
                      <div className="user-settings__select-location-address">
                        <div
                          className="flag-input-container"
                          onClick={(e) => {
                            e.persist();
                            setTimeout(() => {
                              const flagInput = document.querySelectorAll(
                                ".filterBox input[type=text]",
                              )[0];

                              if (flagInput) {
                                flagInput.focus();
                              }
                            }, 700);
                          }}
                        >
                          <div className="flag-input-container-title">{`${v_country}`}</div>
                          <div
                            className="flag-input-container-select"
                            onClick={() =>
                              this.setState((prevState) => ({
                                ...prevState,
                                isOpenCountriesDropdown:
                                  !prevState.isOpenCountriesDropdown,
                              }))
                            }
                          >
                            <div className="flag-input-container-selected">
                              {countries[formik.values.country] ? (
                                <>
                                  <img
                                    className="flag-input-container-selected-flag"
                                    src={countries[formik.values.country].flag}
                                    alt=""
                                  />
                                  <span className="flag-input-container-selected-text">
                                    {
                                      countries[formik.values.country].name
                                        .common
                                    }
                                  </span>
                                </>
                              ) : (
                                <span className="flag-input-container-selected-text-empty">
                                  {select_country}
                                </span>
                              )}
                            </div>
                            <div
                              className={classNames(
                                cn("flag-input-container-select-arrow", {
                                  "flag-input-container-select-arrow__light":
                                    localStorage.getItem("themes") === "light",
                                }),
                                {
                                  rotated: isOpenCountriesDropdown,
                                },
                              )}
                            />
                          </div>
                          {isOpenCountriesDropdown && (
                            <div className="flag-input-container-select-dropdown">
                              <CountriesDropdown
                                inputPlaceholder={`${v_search}...`}
                                epmtyText={v_empty}
                                onSelect={(item) => {
                                  formik.values.country = item.code;
                                  this.setState({
                                    isOpenCountriesDropdown: false,
                                  });
                                }}
                                theme="dark"
                              />
                            </div>
                          )}
                          <div
                            className="wrapper-base-input__error-message"
                            style={{ height: "1rem" }}
                          />
                        </div>
                        <Input
                          config={{
                            id: "state",
                            name: "state",
                            type: "state",
                            onChange: formik.handleChange,
                            onBlur: formik.handleBlur,
                            value: formik.values.state,
                          }}
                          label={v_state}
                          withValidation
                          dark
                        />
                      </div>
                      <div className="user-settings__select-location-address">
                        <Input
                          config={{
                            id: "city",
                            name: "city",
                            type: "text",
                            onChange: formik.handleChange,
                            onBlur: formik.handleBlur,
                            value: formik.values.city,
                          }}
                          label={`${v_city}, ${v_address_lowC}`}
                          withValidation
                          dark
                        />
                        <Input
                          config={{
                            id: "zip",
                            name: "zip",
                            type: "string",
                            onChange: formik.handleChange,
                            onBlur: formik.handleBlur,
                            value: formik.values.zip,
                          }}
                          label={v_zip}
                          errorMsg={formik.errors.zip}
                          withValidation
                          dark
                        />
                      </div>
                    </div>

                    <div className="user-settings__date-time-format-block">
                      <div className="user-settings__date-time-format-block-title">
                        {v_time_date}
                      </div>
                      <div className="user-settings__date-time-format-block--row">
                        <SelectDateFormat />
                        <SelectFirstDayOfWeek />
                      </div>
                      <div className="user-settings__date-time-format-block--row">
                        <SelectTimeFormat />
                        <SelectDurationTimeFormat />
                      </div>
                    </div>
                    <div className="user-settings__technology">
                      <div
                        className={cn("user-settings__technology-title", {
                          "user-settings__technology-title--disabled":
                            disabledSubscriptionCheck,
                        })}
                      >
                        {v_tags}
                        <CustomTippy content={v_tags_tooltip} arrow={false}>
                          <span
                            className={cn("user-settings__technology-info", {
                              "user-settings__technology-info__light":
                                localStorage.getItem("themes") === "light",
                            })}
                          />
                        </CustomTippy>
                      </div>
                      <TechnologyComponent
                        userTechnologies={this.state.userTechnologies}
                        setUserTechnologies={(techArr) =>
                          this.setState({ userTechnologies: techArr })
                        }
                        disabled={disabledSubscriptionCheck}
                        vocabulary={vocabulary}
                        showNotificationAction={
                          this.props.showNotificationAction
                        }
                      />
                    </div>
                    {/* <div className="user-settings__technology">
                      <Input
                        config={{
                          id: "cv",
                          name: "cv",
                          type: "text",
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                          value: formik.values.cv,
                        }}
                        label={v_cv}
                        withValidation
                        dark
                      />
                    </div> */}
                    <div className="wrapper-jira-sync">
                      <div className="jira-sync-labels-wrapper">
                        <label className="input_container input_checkbox_jira">
                          <Checkbox
                            name={syncJiraStatus.name}
                            checked={syncJiraStatus.checked}
                            color="primary"
                            style={{
                              backgroundColor: "transparent",
                              width: "24px",
                            }}
                            onChange={(event) => {
                              this.changeSyncJiraStatus(event);
                              formik.setValues({
                                ...formik.values,
                                jiraPassword: "",
                                jiraUserName: loginJira || "",
                                jiraUrl: urlJira || "",
                              });
                            }}
                          />
                          <span className="input_title">
                            {v_jira_synchronization}
                          </span>
                        </label>
                        <label>
                          {checked && (
                            <span
                              className="jira_sync_visibility_btn"
                              onClick={(event) => {
                                this.switchVisibilityJiraForm(event);
                              }}
                            >
                              {userSetJiraSync ? v_hide : v_show}
                            </span>
                          )}
                        </label>
                      </div>
                      {checked && userSetJiraSync && (
                        <>
                          <div className="fourth-inputs-wrapper">
                            <SwitchJiraType
                              dropdown
                              onSelect={this.selectedJiraType}
                              selectedType={jiraType.value}
                              v_type={v_type}
                            />
                            <Input
                              config={{
                                id: "jiraUrl",
                                name: "jiraUrl",
                                type: "text",
                                onChange: formik.handleChange,
                                onBlur: formik.handleBlur,
                                value: formik.values.jiraUrl,
                              }}
                              errorMsg={formik.errors.jiraUrl}
                              label={"Jira url"}
                              withValidation
                              dark
                            />
                          </div>
                          <div className="fifth-inputs-wrapper">
                            <Input
                              name="jiraUserInput"
                              config={{
                                id: "jiraUserName",
                                name: "jiraUserName",
                                type: "text",
                                onChange: (e) => {
                                  e.preventDefault();
                                  formik.handleChange(e);
                                },
                                onBlur: formik.handleBlur,
                                value: formik.values.jiraUserName,
                              }}
                              errorMsg={formik.errors.jiraUserName}
                              label={v_login}
                              withValidation
                              dark
                            />

                            <label className="input_container">
                              <span className="input_title">
                                {v_password}
                                {formik.values.jiraPassword && (
                                  <i
                                    onClick={(event) => {
                                      event.preventDefault();
                                      this.verifyJiraAction(formik);
                                    }}
                                    className={classNames("verify-arrow-loop", {
                                      "verify-arrow-loop--rotate-arrow":
                                        rotateArrowLoop,
                                    })}
                                    title={v_verify}
                                  />
                                )}
                              </span>

                              <Input
                                config={{
                                  id: "jiraPassword",
                                  name: "jiraPassword",
                                  type: "password",
                                  onChange: formik.handleChange,
                                  onBlur: formik.handleBlur,
                                  onFocus: (event) => {
                                    event.preventDefault();
                                  },
                                  value: formik.values.jiraPassword,
                                  placeholder: v_add_your_password,
                                }}
                                errorMsg={formik.errors.jiraPassword}
                                withValidation
                                dark
                              />
                              {jiraType.value === "cloud" && (
                                <span className="input_subtitle">
                                  ({v_enter_to}{" "}
                                  <a
                                    href="https://id.atlassian.com/manage/api-tokens"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    https://id.atlassian.com/manage/api-tokens
                                  </a>{" "}
                                  {v_to_get_token})
                                </span>
                              )}
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="buttons-block">
                      <div className="save_user">
                        <button className="save_btn" type="submit">
                          {v_save}
                        </button>
                      </div>
                      <div
                        className="delete_user"
                        onClick={() => this.openUserDeleteModal()}
                      >
                        <div className="delete_user__btn">{v_delete_user}</div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </CustomScrollbar>
        </div>
        {userDeleteModal && (
          <UserDeleteModal
            vocabulary={vocabulary}
            cancelDeleteUserModal={this.closeUserDeleteModal}
            deleteUser={this.deleteUser}
            deleteUserSubmitForm={this.deleteUserSubmitForm}
          />
        )}
      </div>
    );
  }

  openChangePasswordModal() {
    this.props.toggleModal(true);
  }

  setDataToForm = () => {
    const { user } = this.props.userReducer;
    const { tokenJira, phone, typeJira, userTechnologies } = user;

    this.setState((prevState) => ({
      phone: {
        value: phone ? phone : "+49",
      },
      inputs: {
        ...prevState.inputs,
        jiraType: {
          value: typeJira || "",
        },
        syncJiraStatus: {
          ...prevState.inputs.syncJiraStatus,
          checked: Boolean(tokenJira),
        },
      },
      userTechnologies: userTechnologies.map((item) => item.technology),
    }));
  };
}

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  isMobile: state.responsiveReducer.isMobile,
  lang: state.languageReducer.selectedLang,
  currentTeam: state.teamReducer.currentTeam
});

const mapDispatchToProps = {
  deleteUserAction,
  toggleModal,
  changeUserData,
  showNotificationAction,
  setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSetting);
