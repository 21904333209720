import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

// Actions
import { scrollToAction } from "../../../redux/actions/ResponsiveActions";

// Styles
import "./style.scss";
import { ChevronDownIcon, FolderIcon } from "../../Atoms/SvgIcons";
import { getProjectColor } from "../../../services/replaceProjectsColor";

class ProjectListSelect extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();
    this.input = React.createRef();

    this.state = {
      projectsList: null,
      isOpen: false,
      inputValue: "",
    };

    this.emptyProject = {
      id: "project-palceholder-id",
      name: props.vocabulary.v_a_select_project,
      projectColor: {
        name: "gray",
      },
      user_project: [],
      client: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.projectsList === null) {
      const { projectsList, selectedUser, initialValue, onChange } = props;

      let filteredProjectsList = projectsList;
      if (selectedUser) {
        filteredProjectsList = filteredProjectsList.filter((project) => {
          let userInProject = false;
          project.user_project.forEach((user) => {
            if (user.user.id === selectedUser) {
              userInProject = true;
            }
          });
          return userInProject;
        });
      }

      if (initialValue) {
        const initialProject = filteredProjectsList.find(
          (p) => p.id === initialValue,
        );
        if (initialProject) {
          onChange(initialProject);
        } else {
          onChange(
            filteredProjectsList.length ? filteredProjectsList[0] : null,
          );
        }
      } else {
        onChange(filteredProjectsList.length ? filteredProjectsList[0] : null);
      }

      return {
        projectsList: filteredProjectsList,
      };
    }
    return null;
  }

  getProjectData = (key) => {
    const { projectsList, selectedProject, listItem } = this.props;
    if (!selectedProject) {
      return "";
    }
    const filteredProjectsList = projectsList.filter(
      (project) => project.id === selectedProject?.id,
    );

    if (key === "color") {
      if (filteredProjectsList.length > 0) {
        return filteredProjectsList[0].projectColor.name;
      }
      if (filteredProjectsList.length === 0 && listItem) {
        return selectedProject.projectColor.name;
      }
      if (projectsList.length === 0) {
        return this.emptyProject.projectColor.name;
      }
      return projectsList[0].projectColor.name;
    }
    if (key === "name") {
      if (filteredProjectsList.length > 0) {
        return filteredProjectsList[0].name;
      }
      if (filteredProjectsList.length === 0 && listItem) {
        return selectedProject.name;
      }
      if (projectsList.length === 0) {
        return this.emptyProject.name;
      }
      return projectsList[0].name;
    }
    return filteredProjectsList[0];
  };

  openDropdown = (event) => {
    const { onChangeVisibility, disabled } = this.props;

    if (disabled) {
      return;
    }
    this.setState(
      {
        isOpen: true,
      },
      () => onChangeVisibility(true),
    );
    document.addEventListener("click", this.closeDropdown);
  };

  closeDropdown = (event) => {
    if (event.target.classList.length) {
      const { onChangeVisibility } = this.props;

      document.removeEventListener("click", this.closeDropdown);
      this.setState(
        {
          isOpen: false,
        },
        () => onChangeVisibility(false),
      );
    }
  };

  onChangeInput = (event) => {
    const value = event.target.value;

    this.setState({
      inputValue: value.trim().toLowerCase(),
    });
  };

  filterList = () => {
    const { projectsList, selectedUser, selectedProject, onChange } =
      this.props;
    const { inputValue } = this.state;

    let filteredProjectsList = projectsList;
    if (selectedUser) {
      filteredProjectsList = filteredProjectsList.filter((project) => {
        let userInProject = false;
        project.user_project.forEach((user) => {
          if (user.user.id === selectedUser) {
            userInProject = true;
          }
        });
        return userInProject;
      });
    }

    if (inputValue) {
      filteredProjectsList = filteredProjectsList.filter(
        (project) => project.name.toLowerCase().indexOf(inputValue) !== -1,
      );
    }

    this.setState({
      projectsList: filteredProjectsList,
    });

    if (
      selectedProject &&
      !filteredProjectsList.find((p) => p.id === selectedProject.id)
    ) {
      onChange(filteredProjectsList.length ? filteredProjectsList[0] : null);
    } else if (!selectedProject) {
      onChange(filteredProjectsList.length ? filteredProjectsList[0] : null);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, inputValue } = this.state;
    const { scrollToAction, withFolder, selectedUser } = this.props;

    if (!prevState.isOpen && isOpen) {
      this.input.current.focus();
      if (!withFolder) {
        const height =
          window.innerHeight || window.document.documentElement.clientHeight;
        const boundingClientRect =
          this.dropdown.current.getBoundingClientRect();
        const { bottom } = boundingClientRect;

        if (bottom > height) {
          const diff = bottom - height;

          scrollToAction(diff);
        }
      }
    }
    if (prevState.isOpen && !isOpen) {
      this.setState({
        inputValue: "",
      });
    }

    if (
      prevState.inputValue !== inputValue ||
      prevProps.selectedUser !== selectedUser
    ) {
      this.filterList();
    }
  }

  componentWillUnmount() {
    const { onChangeVisibility } = this.props;

    onChangeVisibility(false);
    document.removeEventListener("click", this.closeDropdown);
  }

  render() {
    const { vocabulary, onChange, listItem, isMobile, disabled } = this.props;
    const { v_find, v_projects } = vocabulary;
    const { isOpen, projectsList, inputValue } = this.state;

    return (
      <div className="project-list-select">
        <div className="project-list-select__label">{v_projects}</div>
        <div
          className={classNames("project-list-select__control", {
            "project-list-select__control--list-item": listItem,
            "project-list-select__control--mobile": isMobile,
            "project-list-select__control--disabled": disabled,
          })}
        >
          <div
            className="project-list-select__selected-project"
            onClick={this.openDropdown}
          >
            <div className="project-list-select__container">
              <div className="project-list-select__icon-container">
                <FolderIcon
                  color={getProjectColor(this.getProjectData("color"))}
                />
              </div>
              <span className="project-list-select__project-name">
                {this.getProjectData("name")}
              </span>
            </div>

            <ChevronDownIcon
              className={classNames("project-list-select__chevron-icon", {
                "project-list-select__chevron-icon--open": isOpen,
              })}
              color="#fff"
            />
          </div>
          {isOpen && (
            <div
              ref={this.dropdown}
              className={classNames("project-list-select__dropdown")}
            >
              <div className="project-list-select__dropdown-list-input">
                <input
                  ref={this.input}
                  value={inputValue}
                  onChange={this.onChangeInput}
                  type="text"
                  placeholder={`${v_find}...`}
                />
              </div>
              <div className="project-list-select__dropdown-list">
                {projectsList.map((project) => {
                  const { id, name, projectColor } = project;

                  return (
                    <div
                      key={id}
                      className="project-list-select__dropdown-list-item"
                      onClick={(event) => onChange(project)}
                    >
                      <div className="project-list-select__icon-container">
                        <FolderIcon
                          color={getProjectColor(projectColor.name)}
                        />
                      </div>
                      <span className="project-list-select__dropdown-list-item-project-name">
                        {name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProjectListSelect.defaultProps = {
  onChangeVisibility: () => {},
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  projectsList: state.projectReducer.projectsList,
  isMobile: state.responsiveReducer.isMobile,
});

const mapDispatchToProps = {
  scrollToAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListSelect);
