import classnames from "classnames";
import { ArrowIcon } from "../../Atoms/SvgIcons";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./style.scss";

export const RecruitmentFilterDropdown = ({ title, selectors }) => {
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentSelector, setCurrentSelector] = useState("");

  const dropdown = useRef();
  const priorities = useRef();

  const openDropdown = () => {
    setIsOpen((prevState) => !prevState);
    setExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpen &&
        expanded &&
        dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !priorities.current.contains(e.target)
      ) {
        setIsOpen(false);
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen, expanded]);

  const selectedFilter = (title) => {
    setCurrentSelector(title);
    setIsOpen((prevState) => !prevState);
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div
        ref={priorities}
        className="header-dropdown"
        onClick={() => openDropdown()}
      >
        <div className="header-dropdown__title-dir pointer">
          {title}
          {currentSelector && <span>: {currentSelector}</span>}
        </div>
        <div
          className={classnames("header-dropdown__icon", {
            "header-dropdown__icon--rotated": expanded,
          })}
        >
          <ArrowIcon
            className="header-dropdown__icon__img"
            color="var(--project-icon-path-color)"
          />
        </div>
      </div>
      <div ref={dropdown} className="header-dropdown__selectors-dir">
        {isOpen &&
          selectors.map(({id,title, selected }, index) => (
            <Link
              to={{
                pathname: `/recruitment/directions/${encodeURIComponent(
                  title,
                )}/board`,
                prevPage: true,
                state: {
                  id: id,
                  title: title,
                  items: selectors,
                },
              }}
              style={{ textDecoration: "none" }}
              key={title + index}
            >
              <div
                key={index + title}
                onClick={() => selectedFilter(title, selected)}
                className="header-dropdown__box-dir"
              >
                {title}
              </div>
            </Link>
          ))}
      </div>
    </>
  );
};
