import React, { useCallback, useEffect, useState } from "react";
import CustomTippy from "../../../Unstructured/Tooltip/CustomTippy";
import { followCursor } from "tippy.js";

import { connect } from "react-redux";
import _ from "lodash";

import Switcher from "./Switcher";
import Coupon from "./Coupon";
import { apiCall } from "../../../../services/apiService";
import { AppConfig } from "../../../../config";

import cn from "classnames";

import "./style.scss";
import moment from "moment";
import { PERIODS } from "../../../../constants/constants";
import { submitCoupon } from "../../../../redux/actions/SubscriptionPageAction";
import { getPaymentTrialPeriod } from "../../../../api/payment";
import { getUserDataAction } from "../../../../redux/actions/UserActions";
import { getCurrentTeamAction } from "../../../../redux/actions/TeamActions";
import { showNotificationAction } from "../../../../redux/actions/NotificationActions";

const PERIODS_SWITCH_MAP = {
  [PERIODS.MONTH]: false,
  [PERIODS.YEAR]: true,
};

const PERIODS_SWITCH_REVERSE_MAP = {
  false: PERIODS.MONTH,
  true: PERIODS.YEAR,
};

const AllPlans = ({
  vocabulary,
  handleSetBilling,
  period,
  setPeriod,
  plans,
  products,
  user,
  trialAvailable,
  isTrialing,
  subscriptionData,
  discount,
  submitCoupon,
  currentTeam,
  getUserDataAction,
  getCurrentTeamAction,
  customerData,
  showNotificationAction,
  couponIdForStore,
  productId,
}) => {
  const [internalPeriod, setInternalPeriod] = useState(null);

  const {
    v_subscription,
    v_plan_details,
    lang,
    v_last_trial_day,
    v_extra,
    v_info,
    v_choose,
  } = vocabulary;

  const handleSwitcher = () => {
    setPeriod(PERIODS_SWITCH_REVERSE_MAP[!internalPeriod]);
  };

  useEffect(() => setInternalPeriod(PERIODS_SWITCH_MAP[period]), [period]);

  const renderButtonText = (data) => {
    if (data.current) {
      if (currentTeam.data.trial) {
        return v_subscription.plan.btnUpgrade;
      }
    } else if (trialAvailable) {
      return "Start trial";
    } else if (currentTeam.data.trial === null) {
      return v_subscription.plan.btnUpgrade;
    } else if (data.upgradable) {
      if (customerData.default_payment_method) {
        return v_subscription.plan.btnUpgrade;
      } else {
        return v_subscription.plan.btnUpgradeNow;
      }
    }
    return v_subscription.plan.btnDowngarde;
  };

  const startTrialPeriod = async ({ priceId, couponId, productId }) => {
    try {
      await getPaymentTrialPeriod({ priceId, couponId, productId });
      getUserDataAction();
      getCurrentTeamAction();
    } catch (error) {
      console.log(error);
    }
  };

  const userProducts = (user, plans) => {
    if (user.coupon.applies_to === null) {
      const plansId = plans.map((prodId) => {
        return prodId.externalPlanData.id;
      });

      return plansId;
    } else {
      return user.coupon.applies_to;
    }
  };
  const priceDivider = PERIODS.MONTH === period ? 100 : 1200;

  return (
    <div className="all-subs-plans">
      <div className="all-subs-plans__header">
        <div>
          <div className="header-title">{v_choose}</div>
          {currentTeam.data.trial ? (
            <div className="all-subs-plans__header-title">
              <div className="all-subs-plans__header-title__trial-text">
                {moment(currentTeam.data.trial).diff(moment(), "days") === 0 &&
                  v_last_trial_day}
              </div>

              {moment(currentTeam.data.trial).diff(moment(), "days") !== 0 && (
                <span>
                  {`${moment(currentTeam.data.trial).diff(moment(), "days")}`}{" "}
                  {v_subscription.switcher.trial}
                </span>
              )}
            </div>
          ) : trialAvailable ? (
            <div className="preriod-switcher__free-trial">
              <span>14{v_subscription.switcher.trial}</span>
            </div>
          ) : null}
        </div>
        {/* <Switcher
          selected={internalPeriod}
          onSelect={handleSwitcher}
          trans={v_subscription.switcher}
        /> */}
      </div>
      <div className="all-subs-plans__body">
        {plans &&
          Boolean(plans.length) &&
          plans.map((plan) => {
            const { data, externalPlanData } = plan;

            return (
              <div
                key={plan.externalPlanData.id}
                className="all-subs-plans__plan"
              >
                <div
                  className={cn(
                    `all-subs-plans__plan-card`,
                    `${data.class}-plan`,
                    {
                      // "current-plan": data.current,
                      // [`${data.class}-bg`]: data.current,
                    },
                  )}
                >
                  {/* {data.current === true ? (
                    <div
                      className={`all-subs-plans__plan-best-true ${data.class}-color`}
                    >
                      {data.best}
                    </div>
                  ) : ( */}
                  <div
                    className={`all-subs-plans__plan-best ${data.class}-color`}
                  >
                    {data.best}
                  </div>
                  {/* )} */}
                  <div
                    className={`all-subs-plans__plan-title ${data.class}-color ${data.class}-gap`}
                  >
                    {data.title}
                    <div
                      className={cn(`${data.class}-text-color`, {
                        "current-plan-attribute": data.current,
                      })}
                    >
                      {data.current === true && v_subscription.plan.btnCurrent}
                    </div>
                  </div>
                  {user.coupon === null ? (
                    <div className="subscription-price">
                      <div
                        className="subscription-price-before"
                        style={{
                          fontSize:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "24px"
                              : "35px",
                          color:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "#b9bbbe"
                              : "var(--text)",
                          lineHeight:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "48px"
                              : "",
                        }}
                      >
                        €
                      </div>
                      <div
                        className={`all-subs-plans__plan-price ${data.class}-color`}
                        style={{
                          textDecoration:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "line-through #b9bbbe"
                              : "none",
                          fontSize:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "24px"
                              : "35px",
                          color:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "#b9bbbe"
                              : "var(--text)",
                          lineHeight:
                            discount &&
                            productId === externalPlanData.id &&
                            _.includes(products, externalPlanData.id) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "48px"
                              : "",
                        }}
                      >
                        {data.priceM}
                        {/* {externalPlanData.prices?.nickname /
                          priceDivider} */}
                      </div>
                      {!!externalPlanData.pricePeriod?.nickname &&
                        !!discount &&
                        productId === externalPlanData.id &&
                        _.includes(products, externalPlanData.id) && (
                          <div className={`all-subs-plans__plan-price-new`}>
                            <div className="subscription-price-before">€</div>
                            {(externalPlanData.pricePeriod?.nickname -
                              (externalPlanData.pricePeriod?.nickname *
                                discount) /
                                100) /
                              priceDivider}
                          </div>
                        )}
                      <div className="subscription-price-after">
                        {externalPlanData.pricePeriod?.nickname === null
                          ? ""
                          : internalPeriod
                          ? `/${v_subscription.plan.yr}`
                          : `/${v_subscription.plan.mo}`}
                      </div>
                    </div>
                  ) : (
                    <div className="subscription-price">
                      <div
                        className="subscription-price-before"
                        style={{
                          fontSize:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "24px"
                              : "35px",
                          color:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "#b9bbbe"
                              : "var(--text)",
                          lineHeight:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "48px"
                              : "",
                        }}
                      >
                        €
                      </div>
                      <div
                        className={`all-subs-plans__plan-price ${data.class}-color`}
                        style={{
                          textDecoration:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "line-through #b9bbbe"
                              : "none",
                          fontSize:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "24px"
                              : "35px",
                          color:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "#b9bbbe"
                              : "var(--text)",
                          lineHeight:
                            user.coupon &&
                            _.includes(
                              userProducts(user, plans),
                              externalPlanData.id,
                            ) &&
                            externalPlanData.pricePeriod?.nickname
                              ? "48px"
                              : "",
                        }}
                      >
                        {data.priceM}
                        {/* {externalPlanData.prices?.nickname /
                          priceDivider} */}
                      </div>
                      {!!externalPlanData.pricePeriod?.nickname &&
                        !!user.coupon &&
                        _.includes(
                          userProducts(user, plans),
                          externalPlanData.id,
                        ) && (
                          <div className={`all-subs-plans__plan-price-new`}>
                            <div className="subscription-price-before">€</div>
                            {(externalPlanData.pricePeriod?.nickname -
                              (externalPlanData.pricePeriod?.nickname *
                                user.coupon.percent_off) /
                                100) /
                              priceDivider}
                          </div>
                        )}
                      <div className="subscription-price-after">
                        {externalPlanData.pricePeriod?.nickname === null
                          ? ""
                          : internalPeriod
                          ? `/${v_subscription.plan.yr}`
                          : `/${v_subscription.plan.mo}`}
                      </div>
                    </div>
                  )}
                  <span className="all-subs-plans__subtitle">
                    {"Core features"}
                  </span>
                  <ul className="all-subs-plans__plan-description">
                    {data.description.map((desc, index) => (
                      <li key={index}>
                        <svg
                          className="all-subs-plans__check-icon"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3332 4L5.99984 11.3333L2.6665 8"
                            // stroke={data.current ? "#b9bbbe" : data.color}
                            stroke={data.color}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {desc}

                        {/* Tooltips may be in future */}

                        {/* {desc === v_subscription.free.features && (
                          <div className="extra-features">
                            <CustomTippy
                              content={
                                <ul className="tooltip-extra__list2">
                                  <li>{v_info.timeTracking}</li>
                                  <li>{v_info.resourcePlanning}</li>
                                  <li>{v_info.reporting}</li>
                                  <li>{v_info.realTimeReports}</li>
                                  <li>{v_info.invoicing}</li>
                                </ul>
                              }
                              plugins={[followCursor]}
                              followCursor={true}
                              arrow={false}
                            >
                              <button className="extra-features__ii">i</button>
                            </CustomTippy>
                          </div>
                        )}
                        {desc === v_subscription.free.roles && (
                          <div className="extra-features">
                            <CustomTippy
                              content={
                                <ul className="tooltip-extra__list2">
                                  <li>{v_info.owner}</li>
                                  <li>{v_info.admin}</li>
                                  <li>{v_info.member}</li>
                                  <li>{v_info.invoiceManager}</li>
                                </ul>
                              }
                              plugins={[followCursor]}
                              followCursor={true}
                              arrow={false}
                            >
                              <button className="extra-features__ii">i</button>
                            </CustomTippy>
                          </div>
                        )} */}
                      </li>
                    ))}
                  </ul>
                  <span className="all-subs-plans__subtitle">
                    {"Additional features"}
                  </span>
                  <ul className="all-subs-plans__plan-description">
                    {data.additional.map((desc, index) => (
                      <li key={index}>
                        <svg
                          className="all-subs-plans__check-icon"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3332 4L5.99984 11.3333L2.6665 8"
                            stroke={data.color}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {desc}
                      </li>
                    ))}
                  </ul>
                  <div className="border-line"></div>
                  {plan.externalPlanData.name !== "Free" ? (
                    <Coupon plan={plan} />
                  ) : null}
                  <div className="card-footer">
                    <div className="alert">
                      {currentTeam.data.trial &&
                        data.current &&
                        externalPlanData.pricePeriod?.nickname !== 0 && (
                          <p className="text-information-current-subscription">
                            {v_plan_details.subscriptionExpiredTrial}
                            {moment(currentTeam.data.trial)
                              .locale(lang.short)
                              .format("L")}
                          </p>
                        )}
                      {!currentTeam.data.trial &&
                        data.current &&
                        externalPlanData.pricePeriod?.nickname !== 0 && (
                          <p className="text-information-current-subscription">
                            {v_plan_details.subscriptionExpired}
                            {moment
                              .unix(subscriptionData.current_period_end)
                              .locale(lang.short)
                              .format("L")}
                          </p>
                        )}
                    </div>
                    {trialAvailable && submitCoupon && data.current !== true ? (
                      <button
                        onClick={() =>
                          startTrialPeriod({
                            priceId: plan.externalPlanData.pricePeriod.id,
                            couponId: couponIdForStore,
                            productId: productId,
                          })
                        }
                        className={cn(
                          `all-subs-plans__plan-button`,
                          `all-subs-plans__plan-button ${data.class}-bg`,
                          // {
                          //   "all-subs-plans__plan-button-active": data.current,
                          // },
                        )}
                      >
                        {"Start trial"}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          handleSetBilling(plan);
                        }}
                        disabled={data.current && !currentTeam.data.trial}
                        className={cn(
                          `all-subs-plans__plan-button`,
                          `all-subs-plans__plan-button ${data.class}-bg`,
                          {
                            "all-subs-plans__plan-button-active":
                              data.current && !currentTeam.data.trial,
                          },
                        )}
                      >
                        {renderButtonText(data)}
                      </button>
                    )}
                    {/* <div
                      className={cn({
                        "all-subs-plans__plan-button-active-current":
                          data.current,
                      })}
                    >
                      {data.current === true && renderButtonText(data)}
                    </div> */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="extra-features">
        <p> </p>
      </div>
      {/* <div className="extra-features">
        <button className="extra-features__button">
          {v_subscription.extraFeatures}
          <CustomTippy
          content={<>
                <p className="tooltip-extra__price">{v_extra.price}</p>
                  <ul className="tooltip-extra__list">
                    <li>{v_extra.feature1}</li>
                    <li>{v_extra.feature2}</li>
                    <li>{v_extra.feature3}</li>
                  </ul>
                <p className="tooltip-extra__star">{v_extra.star}</p>
                </>}
          plugins={[followCursor]}
          followCursor={true}
          arrow={false}
                >
          <button
            className="extra-features__i"
          >
            i
          </button>
          </CustomTippy>
        </button>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  subscriptionData: state.subscriptionPageReducer.customerData,
  discount: state.subscriptionPageReducer.discount,
  products: state.subscriptionPageReducer.products,
  productId: state.subscriptionPageReducer.productId,
  currentTeam: state.teamReducer.currentTeam,
  customerData: state.subscriptionPageReducer.customerData,
  couponIdForStore: state.subscriptionPageReducer.couponId,
  user: state.userReducer.user,
});
const mapDispatchToProps = {
  submitCoupon,
  showNotificationAction,
  getUserDataAction,
  getCurrentTeamAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPlans);
