import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

// Services
import { apiCall } from "../../../services/apiService";

// Components

// Actions
import {
  getCurrentTeamAction,
  getUserTeamsAction,
  getCurrentTeamDetailedDataAction,
} from "../../../redux/actions/TeamActions";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import { switchMenu } from "../../../redux/actions/ResponsiveActions";
import reportsPageAction from "../../../redux/actions/ReportsPageAction";

// Queries

// Config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";

class CreateTeamModal extends Component {
  addTeam() {
    const {
      vocabulary,
      getUserTeamsAction,
      getCurrentTeamAction,
      getCurrentTeamDetailedDataAction,
      history,
      showNotificationAction,
      reportsPageAction,
    } = this.props;
    const {
      v_a_team_name_empty_error,
      v_a_team_existed,
      v_a_team_create_error,
    } = vocabulary;
    const createTeam = (this.createTeamInput.value || "").trim();

    if (createTeam.length > 0) {
      // this.props.createTeamRequest(createTeam);
      apiCall(`${AppConfig.apiURL}team/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          teamName: createTeam,
        }),
      }).then(
        (res) => {
          getUserTeamsAction();
          getCurrentTeamAction();
          getCurrentTeamDetailedDataAction();
          history.push("/team");
        },
        (err) => {
          if (err instanceof Response) {
            err.text().then((error) => {
              const errorTeamMessage = JSON.parse(error);

              if (
                errorTeamMessage.message === "Team with this name already exist"
              ) {
                showNotificationAction({
                  text: v_a_team_existed,
                  type: "warning",
                });
              } else {
                showNotificationAction({
                  text: v_a_team_create_error,
                  type: "error",
                });
              }
            });
          } else {
            console.log(err);
          }
        },
      );

      reportsPageAction("SET_SELECTION_RANGE", moment().format('DD.MM.YYYY'));

      reportsPageAction(
        "SET_START_DATE",
        moment().format('DD.MM.YYYY'),
      );

      reportsPageAction(
        "SET_END_DATE",
        moment().format('DD.MM.YYYY'),
      );

      reportsPageAction("SET_PROJECTS", {
        data: [],
      });

      this.closeModal();
    } else if (!createTeam.trim().length) {
      showNotificationAction({
        text: v_a_team_name_empty_error,
        type: "warning",
      });
    }
  }

  getWrapper() {
    return document.querySelector(".wrapper");
  }

  addZIndexToWrapper() {
    const wrapper = this.getWrapper();

    if (wrapper) {
      wrapper.classList.add("wrapper_z-index-1");
    }
  }

  removeZIndexFromWrapper() {
    const wrapper = this.getWrapper();

    if (wrapper) {
      wrapper.classList.remove("wrapper_z-index-1");
    }
  }

  closeModal() {
    this.removeZIndexFromWrapper();
    this.props.changeAddTeamModalStatus(false);
    this.props.closeTeamList();
    this.props.isMobile && this.props.switchMenu();
  }

  render() {
    const { vocabulary } = this.props;
    const { v_team_name, v_create_team } = vocabulary;

    this.addZIndexToWrapper();

    return (
      <div className="wrapper_create_team_modal" data-theme={localStorage.getItem("themes")}>
        <div
          className="create_team_modal_background"
          onClick={(e) => this.closeModal()}
        />
        <div className="create_team_modal_container">
          <div className="create_team_modal_header">
            {v_create_team}
            <i
              className="create_team_modal_header_close"
              onClick={(e) => this.closeModal()}
            />
          </div>
          <div className="create_team_modal_data">
            <div className="create_team_modal_data_input_container">
              <input
                maxLength="30"
                autoFocus
                type="text"
                ref={(input) => {
                  this.createTeamInput = input;
                }}
                placeholder={`${v_team_name}...`}
              />
            </div>
          </div>
          <div className="create_team_modal_button_container">
            <button
              className="create_team_modal_button_container_button"
              onClick={(e) => this.addTeam()}
            >
              {v_create_team}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
  getUserTeamsAction,
  getCurrentTeamAction,
  getCurrentTeamDetailedDataAction,
  showNotificationAction,
  switchMenu,
  reportsPageAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateTeamModal),
);
