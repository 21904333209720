import React, { useEffect, useState } from "react";

import { AppConfig } from "../../../config";
import classNames from "classnames";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";

import "./style.scss";

export default function ResourcePlanningGroupItem({
  group,
  openUserModal,
  positionDetails,
}) {
  const [showUserInfoPopup, setShowUserInfoPopup] = useState(false);

  const endDate = group.timer_v2[0]?.end_datetime;
  const nowDate = new Date().toISOString().split("T")[0];

  const handleMouseEnter = () => {
    if (positionDetails) {
      setShowUserInfoPopup(true);
    }
  };
  const handleMouseLeave = () => {
    setShowUserInfoPopup(false);
  };

  return (
    <div className="rp-group-item" onClick={() => openUserModal(group)}>
      <div
        className="rp-group-item__container"
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <CustomTippy
          content={
            <ul>
              <li className="popupUserinfo__name">
                <span>{group.username}</span>
              </li>
              <li className="popupUserinfo__tags">
                {group.user_technology &&
                  group.user_technology.map((tag) => (
                    <span
                      key={tag.technology.id}
                      className={classNames(
                        localStorage.getItem("themes") === "light"
                          ? "popupUserinfo__tags__tag-light"
                          : "popupUserinfo__tags__tag-dark",
                      )}
                    >
                      {tag.technology.title}
                    </span>
                  ))}
              </li>
            </ul>
          }
          arrow={false}
          disabled={!showUserInfoPopup}
        >
          <div className="rp-group-item__container-avatar">
            {!group.avatar ? (
              <div
                className={classNames("rp-group-item__avatar", {
                  "rp-group-item__avatar__white":
                    localStorage.getItem("themes") === "light",
                })}
              />
            ) : (
              <div
                className="rp-group-item__avatar rp-group-item__avatar__cover"
                style={{
                  backgroundImage: `url("${AppConfig.apiURL}/${group.avatar}")`,
                }}
              />
            )}
            {group.timer_current_v2 !== null ? (
              <div className="rp-group-item__icon-online" />
            ) : group.timer_current_v2 === null && endDate !== undefined ? (
              <span>
                {new Date(endDate).toISOString().split("T")[0] === nowDate ? (
                  <div
                    className="rp-group-item__icon-online"
                    style={{ backgroundColor: "orange" }}
                  />
                ) : (
                  <div
                    className="rp-group-item__icon-online"
                    style={{ backgroundColor: "red" }}
                  />
                )}
              </span>
            ) : (
              <div
                className="rp-group-item__icon-online"
                style={{ backgroundColor: "red" }}
              />
            )}
          </div>
        </CustomTippy>
        <div className="rp-group-item__content">
          <div className="rp-group-item__name">{group.username}</div>
          {group.user_technology && Boolean(group.user_technology.length) && (
            <div className="rp-group-item__tags">
              {group.user_technology.map((technology, index) => (
                <div
                  className="rp-group-item__tag"
                  key={technology.technology.id}
                >
                  {technology.technology.title}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
