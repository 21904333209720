import React, { Component } from "react";
import { connect } from "react-redux";

import { ChevronDownIcon } from "../../Atoms/SvgIcons";

import { updateShowBy } from "../../../redux/actions/ResourcePlanningActions";

import "./style.scss";
import {
  SHOW_BY_DAY,
  SHOW_BY_MONTH,
  SHOW_BY_WEEK,
  SHOW_BY_YEAR,
} from "../../../constants/timeline";

class ResourcePlanningShowBy extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();

    this.state = {
      isOpenDropdown: false,
    };

    this.showBy("week");
  }

  openDropdown = () => {
    this.setState({ isOpenDropdown: true }, () =>
      document.addEventListener("click", this.closeDropdown),
    );
  };

  closeDropdown = (e) => {
    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.setState(
        {
          isOpenDropdown: false,
        },
        () => document.removeEventListener("click", this.closeDropdown),
      );
    }
  };

  showBy = (value) => {
    this.props.updateShowBy(value);
    this.setState({ isOpenDropdown: false });
  };

  translateShowBy = () => {
    const { vocabulary, showBy } = this.props;
    const { v_day, v_week, v_month, v_year } = vocabulary;
    let result = v_day;

    switch (showBy) {
      case SHOW_BY_DAY:
        result = v_day;
        break;

      case SHOW_BY_WEEK:
        result = v_week;
        break;

      case SHOW_BY_MONTH:
        result = v_month;
        break;

      case SHOW_BY_YEAR:
        result = v_year;
        break;

      default: {
        result = v_day;
        break;
      }
    }

    return <> {result}</>;
  };

  render() {
    const { isOpenDropdown } = this.state;
    const { vocabulary } = this.props;
    const { v_show_by, v_day, v_week, v_month, v_year } = vocabulary;

    return (
      <div className="rp-show-by">
        <button className="rp-show-by__button" onClick={this.openDropdown}>
          <div className="rp-show-by__title">
            {v_show_by}
            <span className="rp-show-by__value">{this.translateShowBy()}</span>
          </div>
          <ChevronDownIcon className="rp-show-by__icon" color="white" />
        </button>
        {isOpenDropdown && (
          <div
            className="rp-show-by__dropdown"
            ref={(div) => (this.dropdown = div)}
          >
            <button
              className="rp-show-by__item"
              onClick={() => this.showBy(SHOW_BY_DAY)}
            >
              {v_day}
            </button>
            <button
              className="rp-show-by__item"
              onClick={() => this.showBy(SHOW_BY_WEEK)}
            >
              {v_week}
            </button>
            <button
              className="rp-show-by__item"
              onClick={() => this.showBy(SHOW_BY_MONTH)}
            >
              {v_month}
            </button>
            <button
              className="rp-show-by__item"
              onClick={() => this.showBy(SHOW_BY_YEAR)}
            >
              {v_year}
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  showBy: state.resourcePlanningReducer.showBy,
});

const mapDispatchToProps = {
  updateShowBy,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcePlanningShowBy);
