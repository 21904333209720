export const tippyStatus = ({vocabulary, status}) => {
     const { v_paid_tip, v_overdue_tip, v_awaiting, v_draft_tip, v_reviewed } =
       vocabulary;
  switch (status) {
    case "paid":
      return v_paid_tip;
      break;
    case "overdue":
      return v_overdue_tip;
      break;
    case "awaiting":
      return v_awaiting;
      break;
    case "draft":
      return v_draft_tip;
      break;
    case "reviewed":
      return v_reviewed;
      break;
  }
};
