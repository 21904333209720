import React, { ReactElement } from "react";
import "./style.scss";
import Spinner from "../Spinner";

export interface IButton {
  type: "primary";
  children: JSX.Element | string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  width?: string;
  submit?: boolean;
}

const Button = ({
  children,
  onClick = () => null,
  type,
  isLoading = false,
  width = "160px",
  disabled = false,
  submit = false,
}: IButton): ReactElement => (
  <div
    style={{
      width: width,
    }}
    className="button-container"
  >
    {isLoading && <Spinner size={36} />}

    {!isLoading && (
      <button
        type={submit ? "submit" : "button"}
        disabled={disabled}
        className={`button button__${type}`}
        onClick={onClick}
      >
        {children}
      </button>
    )}
  </div>
);

export default Button;
