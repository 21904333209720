import {
  GET_USER_TEAMS_REQUEST,
  GET_USER_TEAMS_REQUEST_SUCCESS,
  GET_USER_TEAMS_REQUEST_FAIL,
  GET_CURRENT_TEAM_REQUEST,
  GET_CURRENT_TEAM_REQUEST_SUCCESS,
  GET_CURRENT_TEAM_REQUEST_FAIL,
  GET_CURRENT_TEAM_DETAILED_DATA_REQUEST,
  GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_SUCCESS,
  GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_FAIL,
  ADD_USER_TO_CURRENT_TEAM_REQUEST,
  ADD_USER_TO_CURRENT_TEAM_SUCCESS,
  ADD_USER_TO_CURRENT_TEAM_ERROR,
  SWITCH_TEAM_REQUEST,
  SWITCH_TEAM_REQUEST_SUCCESS,
  SWITCH_TEAM_REQUEST_FAIL,
  GET_TEAM_USERS_STATUS_REQUEST,
  GET_TEAM_USERS_STATUS_REQUEST_SUCCESS,
  GET_TEAM_USERS_STATUS_REQUEST_FAIL,
  GET_USERS_ROLES_REQUEST,
  GET_USERS_ROLES_REQUEST_SUCCESS,
  GET_USERS_ROLES_REQUEST_ERROR,
  GET_NEW_USER_TEAMS_REQUEST,
  GET_NEW_USER_TEAMS_REQUEST_SUCCESS,
  GET_NEW_USER_TEAMS_REQUEST_FAIL,
} from "../actions/TeamActions";

const sortUserTeams = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

const sortTeamDetailedData = (a, b) => {
  if (a.user.username.toLowerCase() < b.user.username.toLowerCase()) {
    return -1;
  }
  if (a.user.username.toLowerCase() > b.user.username.toLowerCase()) {
    return 1;
  }
  return 0;
};
const sortUserStatus = (a, b) => {
  if (a.username.toLowerCase() < b.username.toLowerCase()) {
    return -1;
  }
  if (a.username.toLowerCase() > b.username.toLowerCase()) {
    return 1;
  }
  return 0;
};

const initialState = {
  TeamUserStatus: null,
  usersRoles: [],
  userTeams: {
    data: [
      {
        id: "",
        name: "",
        owner_id: "",
      },
    ],
    error: null,
    isFetching: false,
    isInitialFetching: true,
  },
  newUserTeams: [],

  currentTeam: {
    data: {
      id: "",
      name: "",
      role: "",
      owner_id: "",
    },
    error: null,
    isFetching: false,
    isInitialFetching: true,
  },
  currentTeamDetailedData: {
    data: [
      {
        user: {
          email: "",
          id: "",
          username: "",
          user_technology: [],
        },

        role_collaboration: {
          title: "",
        },
        is_active: false,
      },
    ],
    error: null,
    isModalLoading: false,
    isFetching: false,
    isInitialFetching: true,
  },
  switchTeam: {
    error: null,
    isFetching: false,
  },
};

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    // GET_USER_TEAMS
    case GET_USER_TEAMS_REQUEST: {
      return {
        ...state,
        userTeams: {
          ...state.userTeams,
          isFetching: true,
        },
      };
    }

    case GET_USER_TEAMS_REQUEST_SUCCESS: {
      return {
        ...state,
        userTeams: {
          ...state.userTeams,
          data: payload.sort(sortUserTeams),
          error: null,
          isFetching: false,
          isInitialFetching: false,
        },
      };
    }

    case GET_USER_TEAMS_REQUEST_FAIL: {
      return {
        ...state,
        userTeams: {
          ...state.userTeams,
          error,
          isFetching: false,
          isInitialFetching: false,
        },
      };
    }
    // newUserTeam
    case GET_NEW_USER_TEAMS_REQUEST: {
      return {
        ...state,
        newUserTeams: payload,
      };
    }

    case GET_NEW_USER_TEAMS_REQUEST_SUCCESS: {
      return {
        ...state,
        newUserTeams: payload,
      };
    }

    case GET_NEW_USER_TEAMS_REQUEST_FAIL: {
      return {
        ...state,
        newUserTeams: payload,
      };
    }

    // GET_CURRENT_TEAM
    case GET_CURRENT_TEAM_REQUEST: {
      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          isFetching: true,
        },
      };
    }

    case GET_CURRENT_TEAM_REQUEST_SUCCESS: {
      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          data: payload,
          error: null,
          isFetching: false,
          isInitialFetching: false,
        },
      };
    }

    case GET_CURRENT_TEAM_REQUEST_FAIL: {
      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          error,
          isFetching: false,
          isInitialFetching: false,
        },
      };
    }

    // GET_CURRENT_TEAM_DETAILED_DATA
    case GET_CURRENT_TEAM_DETAILED_DATA_REQUEST: {
      return {
        ...state,
        currentTeamDetailedData: {
          ...state.currentTeamDetailedData,
          isFetching: true,
        },
      };
    }

    case GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        currentTeamDetailedData: {
          ...state.currentTeamDetailedData,
          data: payload.sort(sortTeamDetailedData),
          error: null,
          isFetching: false,
          isInitialFetching: false,
        },
      };
    }

    case GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_FAIL: {
      return {
        ...state,
        currentTeamDetailedData: {
          ...state.currentTeamDetailedData,
          error,
          isFetching: false,
          isInitialFetching: false,
        },
      };
    }

    case GET_TEAM_USERS_STATUS_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_TEAM_USERS_STATUS_REQUEST_SUCCESS: {
      return {
        ...state,
        TeamUserStatus: payload.sort(sortUserStatus),
      };
    }
    case GET_TEAM_USERS_STATUS_REQUEST_FAIL: {
      return {
        ...state,
      };
    }

    case ADD_USER_TO_CURRENT_TEAM_REQUEST: {
      return {
        ...state,
        currentTeamDetailedData: {
          ...state.currentTeamDetailedData,
          isModalLoading: true,
        },
      };
    }

    case ADD_USER_TO_CURRENT_TEAM_SUCCESS: {
      return {
        ...state,
        currentTeamDetailedData: {
          ...state.currentTeamDetailedData,
          isModalLoading: false,
        },
      };
    }

    case ADD_USER_TO_CURRENT_TEAM_ERROR: {
      return {
        ...state,
        currentTeamDetailedData: {
          ...state.currentTeamDetailedData,
          isModalLoading: false,
        },
      };
    }

    // TEAM_SWITCH
    case SWITCH_TEAM_REQUEST: {
      return {
        ...state,
        switchTeam: {
          ...state.switchTeam,
          isFetching: true,
        },
      };
    }

    case SWITCH_TEAM_REQUEST_SUCCESS: {
      return {
        ...state,
        switchTeam: {
          ...state.switchTeam,
          error: null,
          isFetching: false,
        },
      };
    }

    case SWITCH_TEAM_REQUEST_FAIL: {
      return {
        ...state,
        switchTeam: {
          ...state.switchTeam,
          error,
          isFetching: false,
        },
      };
    }

    // RESET_FOR_ALL_REDUCERS
    case "RESET_ALL": {
      return initialState;
    }

    case GET_USERS_ROLES_REQUEST:
      return {
        ...state,
      };
    case GET_USERS_ROLES_REQUEST_SUCCESS:
      return {
        ...state,
        usersRoles: payload,
      };
    case GET_USERS_ROLES_REQUEST_ERROR:
      return {
        ...state,
      };

    default: {
      return state;
    }
  }
};
