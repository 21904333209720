import { instance } from "../configAPI";
import { TAddPartialPaymentRequest, TGetPartialPaymentsRequest } from "./types";

export const addPartialPaymentApi = ({
  invoiceId,
  sum,
  date,
  comments,
}: TAddPartialPaymentRequest): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}/payment`,
    method: "POST",
    data: {
      sum: sum,
      date: date,
      comments: comments,
    },
  });

export const getPartialPayments = ({
  invoiceId,
}: TGetPartialPaymentsRequest): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}/payment-list`,
    method: "GET",
  });

export const createInvoice = (data: any): Promise<any> =>
  instance({
    url: "/invoice/add",
    method: "POST",
    data,
  });

export const getInvoices = (params: any): Promise<any> =>
  instance({
    url: "/invoice/list",
    method: "GET",
    params,
  });

export const getInvoicesTotal = (params: any): Promise<any> =>
  instance({
    url: "/invoice/total",
    method: "GET",
    params,
  });

export const getInvoicesCountsByStatus = (params: any): Promise<any> =>
  instance({
    url: "/invoice/total-by-status",
    method: "GET",
    params,
  });

export const getInvoiceViewData = (id: any): Promise<any> =>
  instance({
    url: `/invoice/${id}`,
    method: "GET",
  });

export const getInvoiceById = (invoiceId: any): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}`,
    method: "GET",
  });
export const sendInvoiceLetter = (invoiceId: any, data: any): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}/send-invoice`,
    method: "POST",
    data,
  });

export const changeInvoice = ({ invoiceId, data }: any): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}`,
    method: "PATCH",
    data,
  });

export const deleteInvoice = (invoiceId: any): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}`,
    method: "DELETE",
  });

export const changeInvoiceStatus = ({
  invoiceId,
  paymentStatus,
}: any): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}/payment-status`,
    method: "PATCH",
    data: {
      paymentStatus,
    },
  });

export const changeInvoiceStatusModal = ({
  invoiceId,
  status,
}: any): Promise<any> =>
  instance({
    url: `/invoice/${invoiceId}/status`,
    method: "PATCH",
    data: {
      status,
    },
  });

export const downloadInvoicePDF = (id: any): Promise<any> =>
  instance({
    url: `/invoice/${id}/pdf`,
    responseType: "blob",
    method: "GET",
  });

export const downloadTemporaryPDF = (data: any): Promise<any> =>
  instance({
    url: `/invoice/invoice-manager/pdf`,
    responseType: "blob",
    method: "POST",
    data,
  });
