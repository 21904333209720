import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

// dependencies
import classNames from "classnames";

import { getDateInString } from "../../services/timeService";

// Components
import { Loading } from "../../components/Unstructured/Loading";
import TaskListItem from "../../components/Unstructured/TaskListItem";
import AddTask from "../../components/Unstructured/AddTask";
import StartTaskMobile from "../../components/Unstructured/StartTaskMobile";
import CustomScrollbar from "../../components/Unstructured/CustomScrollbar";
import { BlankListComponent } from "../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import VersionOverlay from "../../components/Unstructured/VersionOverlay";

// Actions
import projectsPageAction, {
  getProjectsListActions,
} from "../../redux/actions/ProjectsActions";
import { showNotificationAction } from "../../redux/actions/NotificationActions";
import {
  getTimeEntriesListAction,
  restorePaginationAction,
  syncAllTasks,
} from "../../redux/actions/MainPageAction";

// Styles
import "./style.scss";
import PageHeader from "../../components/Unstructured/PageHeader";
import TimerSearchComponent from "../../components/Unstructured/TimerSearchComponent";
import { SUBSCRIPTIONS } from "../../constants/constants";

export const checkYourPlane = (propsPlane) => {
  const userPlanId = propsPlane;

  let userMaxReportDays = null;

  for (const key in SUBSCRIPTIONS) {
    if (SUBSCRIPTIONS[key].id === userPlanId) {
      userMaxReportDays = SUBSCRIPTIONS[key].maxReportDays;
    }
  }

  return userMaxReportDays;
};
class MainPage extends Component {
  state = {
    isInitialFetching: true,
  };

  async componentDidMount() {
    const { getTimeEntriesListAction, getProjectsListActions } = this.props;

    await getTimeEntriesListAction();
    await getProjectsListActions({
      withTimerList: false,
      withUserInfo: true,
      filterStatus: "active",
    });
    this.setState({
      isInitialFetching: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTimer, getTimeEntriesListAction } = this.props;

    // getting a new task list when stopping or starting
    // a new task without stopping the last task
    if (
      (prevProps.currentTimer && !currentTimer) ||
      (prevProps.currentTimer &&
        currentTimer &&
        prevProps.currentTimer.id !== currentTimer.id)
    ) {
      getTimeEntriesListAction();
    }
  }

  componentWillUnmount() {
    const { restorePaginationAction, projectsPageAction } = this.props;

    restorePaginationAction();
    projectsPageAction("RESET_PROJECTS_PAGE", {});
  }

  splitTimersByDay = (timers = []) => {
    const formattedLogsDates = [];
    const formattedLogsDatesValues = [];

    for (let i = 0; i < timers.length; i++) {
      const date = moment(timers[i].startDatetime).format("YYYY-MM-DD");
      let index = formattedLogsDates.indexOf(date);

      if (index === -1) {
        formattedLogsDates.push(date);
        index = formattedLogsDates.length - 1;
      }

      if (typeof formattedLogsDatesValues[index] === "undefined") {
        formattedLogsDatesValues[index] = [];
      }

      formattedLogsDatesValues[index].push(timers[i]);
    }
    return formattedLogsDatesValues;
  };

  renderDayDateString = (date) => {
    const { dateFormat, vocabulary } = this.props;
    const { lang } = vocabulary;
    const toUpperCaseFirstLetter = (date) => {
      const day = moment(date).locale(lang.short).format("dddd");

      return day[0].toUpperCase() + day.slice(1);
    };

    return `${toUpperCaseFirstLetter(date)}, ${moment(date).format(
      dateFormat,
    )}`;
  };

  renderTotalTimeByDay = (timers) => {
    const { durationTimeFormat } = this.props;
    let totalTime = 0;

    for (let i = 0; i < timers.length; i++) {
      totalTime +=
        Number(moment(timers[i].endDatetime)) -
        Number(moment(timers[i].startDatetime));
    }

    return getDateInString(totalTime, durationTimeFormat);
  };

  render() {
    const {
      isMobile,
      vocabulary,
      timeEntriesList,
      currentTimer,
      isFetchingTimeEntriesList,
      pagination,
      isFetchingSearch,
      isSearchMode,
    } = this.props;
    const { v_total_time, v_timer, v_result } = vocabulary;
    const { isInitialFetching } = this.state;

    return (
      <Loading flag={isInitialFetching} mode="parentSize" withLogo={false}>
        <div
          className={classNames("main-page", {
            "main-page--mobile": isMobile,
          })}
        >
          <PageHeader title={v_timer} disabledTitle={isMobile}>
            <TimerSearchComponent
              userMaxReportDays={checkYourPlane(
                this.props.currentTeam.data.productId,
              )}
              userPlane={this.props.currentTeam.data.userPlan}
              userRole={this.props.currentTeam.data.role}
            />
          </PageHeader>
          <AddTask />
          <Loading
            mode="overlay"
            withLogo={false}
            flag={isFetchingSearch}
            width="100%"
            height="100%"
          >
            {isSearchMode && (
              <div className="main-page__results-title">{v_result}</div>
            )}
            <CustomScrollbar>
              <div className="main-page__list">
                {timeEntriesList && timeEntriesList.length === 0 && (
                  <BlankListComponent
                    text={this.props.vocabulary.v_no_entries}
                    subtext={this.props.vocabulary.v_no_entries_sub}
                    // position={{ bottom: "-20%" }}
                  />
                )}
                {this.splitTimersByDay(timeEntriesList).map(
                  (day, index, arr) => (
                    <div
                      className={classNames("main-page__day", {
                        "main-page__day--last-child": index === arr.length - 1,
                      })}
                      key={index}
                    >
                      <div className="main-page__day-header">
                        <div className="main-page__day-date">
                          {this.renderDayDateString(day[0].startDatetime)}
                        </div>
                        <div className="main-page__day-date-all-time">
                          {v_total_time}: {this.renderTotalTimeByDay(day)}
                        </div>
                      </div>
                      {day.map((task) => (
                        <TaskListItem key={task.id} task={task} />
                      ))}
                    </div>
                  ),
                )}
                {isFetchingTimeEntriesList && (
                  <Loading
                    mode="overlay"
                    withLogo={false}
                    flag={isFetchingTimeEntriesList}
                    width="100%"
                    height="50px"
                  >
                    <div className="main-page__lazy-load-spinner" />
                  </Loading>
                )}
                {isMobile && !currentTimer && pagination.disabled && (
                  <div className="main-page__empty-block" />
                )}
              </div>
            </CustomScrollbar>
          </Loading>
          <StartTaskMobile />
          {!isMobile && <VersionOverlay />}
        </div>
      </Loading>
    );
  }
}

const mapStateToProps = (state) => ({
  timeEntriesList: state.mainPageReducer.timeEntriesList,
  isMobile: state.responsiveReducer.isMobile,
  user: state.userReducer.user,
  dateFormat: state.userReducer.dateFormat,
  durationTimeFormat: state.userReducer.durationTimeFormat,
  currentTimer: state.mainPageReducer.currentTimer,
  pagination: state.mainPageReducer.pagination,
  isFetchingTimeEntriesList: state.mainPageReducer.isFetchingTimeEntriesList,
  isFetchingSearch: state.mainPageReducer.isFetchingSearch,
  isSearchMode: state.mainPageReducer.isSearchMode,
  currentTeam: state.teamReducer.currentTeam,
  customerData: state.subscriptionPageReducer.customerData,
});

const mapDispatchToProps = {
  getTimeEntriesListAction,
  getProjectsListActions,
  restorePaginationAction,
  showNotificationAction,
  projectsPageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
