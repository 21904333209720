import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import cn from "classnames";

// Services

// Components
import Input from "../../components/Unstructured/BaseComponents/Input";
import SwitchLanguageLogin from "../../components/Unstructured/SwitchLanguageLogin";

// Actions
import { showNotificationAction } from "../../redux/actions/NotificationActions";

// Queries

// Config

// Styles
import "./style.scss";
import { resetPassword } from "../../api/user";

class ForgotPassword extends Component {
  state = {
    isLoading: false,
  };

  submitForm = async (email) => {
    const { history, vocabulary, showNotificationAction } = this.props;
    const { v_check_email, v_check_email_error } = vocabulary;

    this.setState({ isLoading: true });
    try {
      await resetPassword(email.toLowerCase());
      showNotificationAction({ text: v_check_email, type: "success" });
      history.push("/login");
    } catch (error) {
      showNotificationAction({ text: v_check_email_error, type: "error" });
      history.push("/login");
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { vocabulary } = this.props;
    const { v_send, v_enter_email, v_email, v_email_error } = vocabulary;

    return (
      <div className="forgot_password_modal_wrapper">
        <div className="fixed_right_corner">
          <SwitchLanguageLogin dropdown />
        </div>
        <i
          className={cn("page_title", {
            page_title__light: localStorage.getItem("themes") === "light",
          })}
        />
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .max(72, "v_email_error")
              .email("v_a_incorect_email")
              .required("v_empty_email"),
          })}
          onSubmit={(value, { setSubmitting }) => {
            this.submitForm(value.email);
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <div className="forgot_password_form_container">
              <form
                className="add_to_team_modal_data"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <Input
                  config={{
                    id: "email",
                    name: "email",
                    type: "email",
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    value: formik.values.email,
                    placeholder: `${v_enter_email}...`,
                  }}
                  errorMsg={formik.errors.email}
                  label={v_email}
                  withValidation
                />
                <button type="submit" disabled={this.state.isLoading}>
                  {v_send}
                </button>
              </form>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}
const mapDispatchToProps = {
  showNotificationAction,
};

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));
