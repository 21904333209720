import React, { Component } from "react";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";
import { followCursor } from "tippy.js";
import ResourcePlanningItemPopup from "../ResourcePlanningItemPopup";
import "./style.scss";
import classNames from "classnames";
import { getProjectColor } from "../../../../src/services/replaceProjectsColor";

class ResourcePlanningItem extends Component {
  constructor(props) {
    super(props);
  }

  handleItemClick = (e) => {
    const { item, openPlanModal } = this.props;

    openPlanModal(item);
  };

  getProjectNameById = () => {
    const { item } = this.props;

    return item.itemProps.projectName || "";
  };

  render() {
    const { item, itemContext, getItemProps, getResizeProps, lang } =
      this.props;
    const { width } = itemContext;
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor =
      (itemContext.selected || itemContext.dragging || itemContext.resizing
        ? "#00B173"
        : getProjectColor(item.bgColor)) ||
      getProjectColor(item.itemProps.projectColor) ||
      "var(--color-calendar-icon)";
    const borderColor =
      itemContext.resizing || itemContext.dragging || itemContext.selected
        ? "#00B173"
        : getProjectColor(item.color) ||
          getProjectColor(item.itemProps.projectColor);
    const borderWidth = itemContext.selected ? 10 : 1;

    return (
      <CustomTippy
        content={<ResourcePlanningItemPopup item={item} lang={lang} />}
        arrow={false}
        plugins={[followCursor]}
        // followCursor={"initial"}
        followCursor={true}
        offset={[35, 10]}
        interactive={false}
      >
        <div
          {...getItemProps({
            style: {
              backgroundColor,
              color: item.color,
              borderColor,
              borderRadius: 8,
              borderLeftWidth: 0,
              borderRightWidth: borderWidth,
              transition: "all 0.2s",
              borderStyle: width ? "none" : "solid",
            },
          })}
          title={``}
          onDoubleClick={this.handleItemClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
          <div
            className={classNames("rp-item__container", {
              "rp-item__withWhiteBox": width > 200,
            })}
          >
            {width > 200 && (
              <>
                <div className="rp-item__header">
                  <div
                    className={classNames("rp-item__title", {
                      "rp-item__title-exists": item.title,
                    })}
                    style={{ maxWidth: width - width / 2 }}
                  >
                    {item.title}
                  </div>
                  <div className="rp-item__load">
                    <div className="rp-item__clock" />
                    {" Load:"}
                    {item.itemProps.load_hours}
                    {"h"}
                  </div>
                </div>
                <div
                  className="rp-item__additional"
                  style={{ maxWidth: width - width / 2 }}
                >
                  {this.getProjectNameById()}
                </div>
              </>
            )}
          </div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      </CustomTippy>
    );
  }
}

export default ResourcePlanningItem;
