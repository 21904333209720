import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getTimeDurationByGivenTimestamp } from "../../../../services/timeService";

import _ from "lodash";
import {
  ISelectionRange,
  IStatsByProjects,
  IUserProjectsPeople,
} from "../../../../types/reports";
import { getDateForLink } from "../../../../services/reportsService";
import { ICurrentTeam } from "../../../../types/teams";

interface IProps {
  projects: IStatsByProjects[];
  usersByProjects: IUserProjectsPeople[];
  currentTeam: ICurrentTeam;
  selectionRange: ISelectionRange;
  durationTimeFormat: string;
}

const ReportsProjectsList = ({
  projects,
  usersByProjects,
  selectionRange,
  currentTeam,
  durationTimeFormat,
}: IProps): ReactElement => {
  const getUsers = (projectName: string): string => {
    const currentUserArr: string[] = [];

    usersByProjects.forEach((item: IUserProjectsPeople) => {
      if (
        item.projects.find(
          (project: IStatsByProjects) => project.name === projectName,
        )
      ) {
        currentUserArr.push(item.email);
      }
    });

    return currentUserArr.join(",");
  };

  const sortedProjects: IStatsByProjects[] = _.orderBy(
    projects,
    ({ duration }) => duration,
    ["desc"],
  );

  return (
    <div className="projects_data">
      {sortedProjects.map((project, index) => (
        <div className="projects_user" key={project.name + index}>
          <Link
            to={{
              pathname: `/reports/detailed/projects/${encodeURIComponent(
                project.name,
              )}/team/${getUsers(project.name) || "all"}/from/${getDateForLink(
                selectionRange.startDate,
              )}/to/${getDateForLink(selectionRange.endDate)}/teamId/${
                currentTeam.data.id
              }`,
              prevPageReport: true,
              projectName: project.name,
            }}
            style={{ textDecoration: "none" }}
          >
            <div className="projects_user_data">
              <div className="project-name">{project.name}</div>
              <div className="time">
                {getTimeDurationByGivenTimestamp(
                  project.duration,
                  durationTimeFormat,
                )}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
  currentTeam: store.teamReducer.currentTeam,
  durationTimeFormat: store.userReducer.durationTimeFormat,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsProjectsList);
