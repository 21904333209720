import { instance } from "../configAPI";
import { getDataStorageDateByPlan } from "../../services/timeService";

export const syncTaskWithJira = (id: any) =>
  instance({
    url: `/sync/jira/issue/${id}/worklog`,
    method: "POST",
  });

export const verifyJiraToken = ({ token, urlJira }: any) =>
  instance({
    url: "/sync/jira/my-permissions",
    method: "GET",
    params: {
      token,
      urlJira,
    },
  });

export const syncAllTasksWithJira = () =>
  instance({
    url: `/sync/jira/worklog?dateFrom=${getDataStorageDateByPlan(-30)}`, // change to needed start date, by donut plane
    method: "POST",
  });

// For sync all button

export const syncAllJiraTasks = () =>
  instance({
    url: `/sync/jira/issues/worklog`,
    method: "POST",
  });
