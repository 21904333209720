import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Modal from "../../Atoms/Modal";
import DateRangeSelect from "../../../components/Unstructured/DateRangeSelect";
import InputWithMaxLength from "../InputWithMaxLength";
import { PlusIcon } from "../../Atoms/SvgIcons";
import ProjectListSelect from "../ProjectListSelect";
import ResourcePlanningAssigneeSelect from "../ResourcePlanningAssigneeSelect";
import ResourcePlanningPlanModalReadonly from "../ResourcePlanningPlanModalReadonly";
import ResourcePlanningPlanModalDeletePlan from "../ResourcePlanningPlanModalDeletePlan";
import ResourcePlanningPlanHistoryModal from "../ResourcePlanningPlanHistoryModal";
import ResourcePlanningPlanModalEditPlanConfirm from "../ResourcePlanningPlanModalEditPlanConfirm";
import { Formik } from "formik";
import { Loading } from "../Loading";

import * as Yup from "yup";

import {
  createPlanAction,
  editPlanAction,
  deletePlanAction,
  getWorkload,
} from "../../../redux/actions/ResourcePlanningActions";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

import moment from "moment";
import classNames from "classnames";

import { STATUS_ERROR, STATUS_SUCCESS } from "../../../constants/status";

import "./style.scss";

class ResourcePlanningPlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      isEdit: props.isEdit,
      isDisabledHours: false,
      isDisabledRate: false,
      isConfirming: false,
      openModalDelete: false,
      openHistory: false,
      formValues: null,
      isReadonly: props.isReadonly,
      initialFormValues: {
        userId: this.props.clientId || "",
        projectId: "",
        startDate:
          moment(this.props.time).startOf("day").toDate() ||
          moment().startOf("day").toDate(),
        endDate:
          moment(this.props.time).endOf("day").toDate() ||
          moment().endOf("day").toDate(),
        name: "",
        description: "",
        loadHours: "",
        loadRate: "",
      },
      validationSchema: Yup.object({
        userId: Yup.string().required("v_v_required"),
        projectId: Yup.string().required("v_v_required"),
        startDate: Yup.date().required("v_v_required"),
        endDate: Yup.date().required("v_v_required"),
        name: Yup.string().max(60),
        description: Yup.string().max(300),
        loadHours: Yup.number().lessThan(10001, "Whoa, this is too much!"),
        loadRate: Yup.number().lessThan(101, "Max load - 100%"),
      }),
      selectedUser: this.props.clientId || "",
      availableSymbols: 300,
    };
  }

  handleAmountChange = (setFieldValue, state) => (event) => {
    const { value } = event.target;
    const cleanedValue = value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*)\./g, "$1");

    setFieldValue(`${state}`, Number(cleanedValue));

    if (state === "loadHours") {
      // setFieldValue(`loadRate`, "");
      this.setState({ isDisabledRate: true });
      this.setState({ isDisabledHours: false });
    } else if (state === "loadRate") {
      // setFieldValue(`loadHours`, "");
      this.setState({ isDisabledHours: true });
      this.setState({ isDisabledRate: false });
    }

    if (state === "loadHours" && cleanedValue < 1) {
      this.setState({ isDisabledRate: false });
    } else if (state === "loadRate" && cleanedValue < 1) {
      this.setState({ isDisabledHours: false });
    }
  };

  createPlan = async (values) => {
    const startDate = moment(values.startDate);
    const endDate = moment(values.endDate);

    const {
      v_success_created_plan,
      v_error_start_date,
      v_error_set_wright_date,
      v_error_user_is_not_exist_in_project,
      v_error_user_does_not_exist_in_this_project,
      v_error_user_not_admin_owner,
    } = this.props.vocabulary.v_resource_planning_page;

    const startDateInMilliseconds = values.startDate.getTime();
    const endDateInMilliseconds = values.endDate.getTime();

    if (startDateInMilliseconds > endDateInMilliseconds) {
      this.props.showNotificationAction({
        text: v_error_start_date,
        type: "error",
      });
      return false;
    } else if (startDateInMilliseconds === endDateInMilliseconds) {
      this.props.showNotificationAction({
        text: v_error_set_wright_date,
        type: "error",
      });
    }

    await this.props.createPlanAction({
      userId: values.userId,
      projectId: values.projectId,
      duration: moment.duration(endDate.diff(startDate)).asMilliseconds(),
      startDate: startDate.toDate().toISOString(),
      endDate: endDate.toDate().toISOString(),
      name: values.name,
      description: values.description,
      loadHours: values.loadHours || 0,
      loadRate: values.loadRate || 0,
    });

    await this.props.getWorkload();

    const status = this.props.planModalStatus;

    if (status === STATUS_SUCCESS) {
      this.props.showNotificationAction({
        text: v_success_created_plan,
        type: "success",
      });
      this.closeModal();
    }

    if (status === STATUS_ERROR) {
      const error = this.props.planModalError;

      if (error.response.data.message === "user is not exist in this project") {
        this.props.showNotificationAction({
          text: v_error_user_is_not_exist_in_project,
          type: "error",
        });
      } else if (error.response.data.message === "ERROR.USER.NOT.ADMIN/OWNER") {
        this.props.showNotificationAction({
          text: v_error_user_not_admin_owner,
          type: "error",
        });
      } else if (
        error.response.data.message ===
        "ERROR.TIMER_PLANNING.CAN_NOT_CREATE_PLAN_IF_START_DATE_BIGGER_END_DATE"
      ) {
        this.props.showNotificationAction({
          text: v_error_start_date,
          type: "error",
        });
      } else if (error.response.data.message === "ERROR.CHECK_REQUEST_PARAMS") {
        this.props.showNotificationAction({
          text: v_error_set_wright_date,
          type: "error",
        });
      } else if (
        error.response.data.message === "User does not exist in this project"
      ) {
        this.props.showNotificationAction({
          text: v_error_user_does_not_exist_in_this_project,
          type: "error",
        });
      } else {
        this.props.showNotificationAction({
          text: error?.message,
          type: "error",
        });
      }
    }
  };

  closeModal = () => {
    this.props.onModalClose();
  };

  setEditPlan = () => {
    const { itemProps: data } = this.props.data;
    const startDate = moment(data.start_date);
    const endDate = moment(data.end_date);

    this.setState({
      isEdit: true,
      isReadonly: false,
      initialFormValues: {
        userId: data.user_id,
        projectId: data.project_id,
        startDate: startDate,
        endDate: endDate,
        name: data.name,
        description: data.description,
        loadHours: data.load_hours,
        loadRate: "",
      },
    });
  };

  editPlan = async (values) => {
    const { itemProps: data } = this.props.data;
    const { initialFormValues } = this.state;
    const body = {};

    for (const key in values) {
      if (values[key] !== initialFormValues[key]) {
        body[key] = values[key];
      }
    }

    if ("startDate" in body) {
      body.startDate = moment(body.startDate).toDate().toISOString();
    }

    if ("endDate" in body) {
      body.endDate = moment(body.endDate).toDate().toISOString();
    }

    const prevStartDate = moment(data.start_date).toDate().toISOString();
    const prevEndDate = moment(data.end_date).toDate().toISOString();
    const newStartDate = moment(values.startDate).toDate().toISOString();
    const newEndDate = moment(values.endDate).toDate().toISOString();
    const checkPrevEndDate = moment(data.end_date)
      .seconds(0)
      .milliseconds(0)
      .toDate()
      .toISOString();
    const checkNewEndDate = moment(values.endDate)
      .seconds(0)
      .milliseconds(0)
      .toDate()
      .toISOString();

    if (
      prevStartDate !== newStartDate ||
      checkPrevEndDate !== checkNewEndDate
    ) {
      body.startDate = newStartDate;
      body.endDate = newEndDate;
      body.duration = moment
        .duration(moment(newEndDate).diff(moment(newStartDate)))
        .asMilliseconds();
    } else if (
      prevStartDate === newStartDate &&
      checkPrevEndDate === checkNewEndDate
    ) {
      delete body["startDate"];
      delete body["endDate"];
    }

    const {
      v_success_edited_plan,
      v_error_start_date,
      v_error_user_is_not_exist_in_project,
      v_error_user_does_not_exist_in_this_project,
      v_error_user_not_admin_owner,
      v_error_you_can_not_change_holidays,
    } = this.props.vocabulary.v_resource_planning_page;

    const startDateInMilliseconds = (
      values.startDate._d || values.startDate
    ).getTime();
    const endDateInMilliseconds = (
      values.endDate._d || values.endDate
    ).getTime();

    if (startDateInMilliseconds > endDateInMilliseconds) {
      this.props.showNotificationAction({
        text: v_error_start_date,
        type: "error",
      });
      return false;
    }

    if (Object.keys(body).length === 0) {
      this.closeModal();
      return;
    }

    await this.props.editPlanAction(body, data.id);
    await this.props.getWorkload();

    const status = this.props.planModalStatus;

    if (status === STATUS_SUCCESS) {
      this.props.showNotificationAction({
        text: v_success_edited_plan,
        type: "success",
      });
      this.closeModal();
    }

    if (status === STATUS_ERROR) {
      const error = this.props.planModalError;

      if (error.response.data.message === "user is not exist in this project") {
        this.props.showNotificationAction({
          text: v_error_user_is_not_exist_in_project,
          type: "error",
        });
      } else if (error.response.data.message === "ERROR.USER.NOT.ADMIN/OWNER") {
        this.props.showNotificationAction({
          text: v_error_user_not_admin_owner,
          type: "error",
        });
      } else if (
        error.response.data.message ===
        "You cannot change the date of public holidays"
      ) {
        this.props.showNotificationAction({
          text: v_error_you_can_not_change_holidays,
          type: "error",
        });
      } else if (
        error.response.data.message === "User does not exist in this project"
      ) {
        this.props.showNotificationAction({
          text: v_error_user_does_not_exist_in_this_project,
          type: "error",
        });
      } else {
        this.props.showNotificationAction({
          text: error?.message,
          type: "error",
        });
      }
    }
  };

  deletePlan = async (values) => {
    const { itemProps: data } = this.props.data;
    const { initialFormValues } = this.state;
    const body = {};
    const { v_error_delete_plan_in_the_past } =
      this.props.vocabulary.v_resource_planning_page;

    for (const key in values) {
      if (values[key] !== initialFormValues[key]) {
        body[key] = values[key];
      }
    }

    await this.props.deletePlanAction(body, data.id);
    await this.props.getWorkload();

    const status = this.props.planModalStatus;
    const { v_success_deleted_plan } =
      this.props.vocabulary.v_resource_planning_page;

    if (status === STATUS_SUCCESS) {
      this.props.showNotificationAction({
        text: v_success_deleted_plan,
        type: "success",
      });
      const prevStartDate = 0;
      const prevEndDate = 0;
      const newStartDate = 0;
      const newEndDate = 0;

      if (prevStartDate !== newStartDate || prevEndDate !== newEndDate) {
        body.startDate = newStartDate;
        body.endDate = newEndDate;
        body.duration = moment
          .duration(moment(newEndDate).diff(moment(newStartDate)))
          .asMilliseconds();
      }
      this.closeModal();
    }

    if (status === STATUS_ERROR) {
      const error = this.props.planModalError;

      if (
        error.response.data.message ===
        "ERROR.TIMER_PLANNING.CAN_NOT_DELETE_PLAN_IN_THE_PAST"
      ) {
        this.props.showNotificationAction({
          text: v_error_delete_plan_in_the_past,
          type: "error",
        });
      } else {
        console.log(error);
      }
    }
  };

  onConfirmation = (values) => {
    this.setState({ formValues: values });
    this.setState({ isConfirming: true });
  };

  handleConfirm = () => {
    this.editPlan(this.state.formValues);
  };

  closeConfirmModal = () => {
    this.setState({ isConfirming: false });
  };

  closeModalDelete = (value) => {
    this.setState({ openModalDelete: value });
  };

  openModalDelete = () => {
    this.setState({ openModalDelete: true });
  };

  openHistoryModal = () => {
    this.setState({ openHistory: true });
  };

  closeHistoryModal = () => {
    this.setState({ openHistory: false });
  };

  render() {
    const {
      isEdit,
      isDisabledHours,
      isDisabledRate,
      isConfirming,
      openModalDelete,
      openHistory,
      data,
      isReadonly,
      initialFormValues,
      validationSchema,
      availableSymbols,
    } = this.state;
    const {
      vocabulary,
      open,
      currentTeamDetailedData,
      projectsList,
      userRole,
      user,
      timeFormat,
    } = this.props;
    const {
      v_resource_planning_page,
      v_save_changes,
      v_plan_info,
      v_from,
      v_description,
      v_cancel,
    } = vocabulary;
    const {
      v_edit_plan_title,
      v_add_plan_title,
      v_type_task_name,
      v_dates,
      v_to,
      v_select_or_add,
      v_type_description,
    } = v_resource_planning_page;

    const modalTitle = isEdit
      ? v_edit_plan_title
      : isReadonly
      ? v_plan_info
      : v_add_plan_title;

    const initialValues = { ...initialFormValues };

    return (
      <>
        <Modal
          open={open}
          title={modalTitle}
          onClose={this.closeModal}
          classes={{
            modal: "plan-modal",
            container: "plan-modal__container",
            wrapper: "plan-modal__wrapper",
          }}
        >
          <div className="plan-modal__body">
            {isReadonly ? (
              <ResourcePlanningPlanModalReadonly
                data={data}
                vocabulary={vocabulary}
                userRole={userRole}
                user={user}
                timeFormat={timeFormat}
                currentTeamDetailedData={currentTeamDetailedData}
                projectsList={projectsList}
                editPlan={this.setEditPlan}
                openModalDelete={this.openModalDelete}
                openHistoryModal={this.openHistoryModal}
              />
            ) : (
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  isEdit
                    ? this.onConfirmation(values)
                    : this.createPlan(values);
                  setSubmitting(false);
                }}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit} noValidate>
                    <InputWithMaxLength
                      name="name"
                      id="name"
                      placeholder={v_type_task_name}
                      value={formik.values.name}
                      errorMsg={formik.errors.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      disabled={isEdit && !initialFormValues.projectId}
                      withValidation
                    />

                    <div className="plan-modal__row plan-modal__input-container">
                      <ProjectListSelect
                        initialValue={initialFormValues.projectId}
                        selectedProject={this.state.selectedProject}
                        selectedUser={this.state.selectedUser}
                        onChange={(value) => {
                          formik.values.projectId = value ? value.id : null;
                          this.setState({ selectedProject: value });
                        }}
                        disabled={isEdit && !initialFormValues.projectId}
                      />
                      <div className="plan-modal__dates-container">
                        <div className="plan-modal__label">{v_dates}</div>
                        <DateRangeSelect
                          startDate={initialValues.startDate}
                          endDate={initialValues.endDate}
                          onChangeDate={({ startDate, endDate }) => {
                            formik.values.startDate =
                              startDate || initialValues.startDate;

                            formik.values.endDate =
                              endDate || initialValues.endDate;
                          }}
                          startErrorMsg={formik.errors.startDate}
                          endErrorMsg={formik.errors.endDate}
                          withValidation
                          startPlaceholder={v_from}
                          endPlaceholder={v_to}
                          startDropdownTitle={v_from}
                          endDropdownTitle={v_to}
                          disabled={isEdit && !initialFormValues.projectId}
                        />
                      </div>
                    </div>

                    <div className="plan-modal__row">
                      <div className="plan-modal__input-container">
                        <div className="plan-modal__label">
                          {"Set the load in hours: (optional)"}
                        </div>
                        <div className="plan-modal__input">
                          <input
                            id={"hours"}
                            type={"text"}
                            class={"input"}
                            className={classNames("plan-modal__textarea", {
                              "plan-modal__textarea--error":
                                formik.errors.loadHours,
                              "plan-modal__input-disabled": isDisabledHours,
                            })}
                            name={"hours"}
                            placeholder={"Load in hours"}
                            value={formik.values.loadHours}
                            onChange={this.handleAmountChange(
                              formik.setFieldValue,
                              "loadHours",
                            )}
                            onBlur={formik.handleBlur}
                            hasError={formik.errors.loadHours}
                            withValidation
                            disabled={formik.values.loadRate}
                          />
                          <div className="plan-modal__error">
                            {formik.errors.loadHours}
                          </div>
                        </div>
                      </div>
                      <div className="plan-modal__input-container">
                        <div className="plan-modal__label">
                          {"Set the load in % (optional)"}
                        </div>
                        <div className="plan-modal__input">
                          <input
                            id={"%"}
                            type={"text"}
                            class={"input"}
                            className={classNames("plan-modal__textarea", {
                              "plan-modal__textarea--error":
                                formik.errors.loadRate,
                              "plan-modal__input-disabled": isDisabledRate,
                            })}
                            name={"%"}
                            placeholder={"Load in %"}
                            value={formik.values.loadRate}
                            onChange={this.handleAmountChange(
                              formik.setFieldValue,
                              "loadRate",
                            )}
                            onBlur={formik.handleBlur}
                            hasError={formik.errors.loadRate}
                            withValidation
                            disabled={formik.values.loadHours}
                          />
                          <div className="plan-modal__error">
                            {formik.errors.loadRate}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="plan-modal__input-container plan-modal__assignee-select">
                      <div className="plan-modal__label">{v_select_or_add}</div>
                      <ResourcePlanningAssigneeSelect
                        withValidation
                        initialValue={initialFormValues.userId}
                        errorMsg={formik.errors.userId}
                        placeholder={v_select_or_add}
                        onChange={(userId) => {
                          formik.values.userId = userId;
                          this.setState({ selectedUser: userId });
                        }}
                        disabled={isEdit && !initialFormValues.projectId}
                      />
                    </div>
                    <div className="plan-modal__input-container">
                      <div className="plan-modal__label">{v_description}</div>
                      <div className="plan-modal__input">
                        <textarea
                          name="description"
                          id="description"
                          value={formik.values.description}
                          className={classNames("plan-modal__textarea", {
                            "plan-modal__textarea--error":
                              formik.errors.description,
                            "plan-modal__textarea--disabled":
                              isEdit && !initialFormValues.projectId,
                          })}
                          placeholder={v_type_description}
                          rows="9"
                          maxLength="300"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={isEdit && !initialFormValues.projectId}
                        />
                        {formik.errors.description && (
                          <div className="plan-modal__error">
                            {formik.errors.description &&
                              vocabulary[formik.errors.description]}
                          </div>
                        )}
                        <div className="plan-modal__available-symbols">
                          {availableSymbols - formik.values.description.length}
                        </div>
                      </div>
                    </div>
                    <div className="plan-modal__footer">
                      <button
                        className="plan-modal__btn plan-modal__btn--cancel"
                        onClick={this.closeModal}
                      >
                        {v_cancel}
                      </button>
                      {this.props.isFetching && (
                        <Loading
                          mode="overlay"
                          flag={this.props.isFetching}
                          withLogo={false}
                          circle
                          width="3.6rem"
                          height="3.6rem"
                        />
                      )}
                      {!this.props.isFetching && (
                        <button
                          className="plan-modal__btn plan-modal__btn--success"
                          type="submit"
                        >
                          {isEdit ? (
                            <span>{v_save_changes}</span>
                          ) : (
                            <>
                              <PlusIcon color="#fff" />
                              <span>{v_add_plan_title}</span>
                            </>
                          )}
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </Modal>
        {isEdit && isConfirming ? (
          <ResourcePlanningPlanModalEditPlanConfirm
            closeModal={this.closeConfirmModal}
            handleConfirm={this.handleConfirm}
            vocabulary={vocabulary}
          />
        ) : null}
        {openModalDelete && (
          <ResourcePlanningPlanModalDeletePlan
            vocabulary={vocabulary}
            closeModalDelete={this.closeModalDelete}
            deletePlan={this.deletePlan}
          />
        )}
        {openHistory && (
          <ResourcePlanningPlanHistoryModal
            vocabulary={vocabulary}
            closeHistoryModal={this.closeHistoryModal}
            data={data}
            timeFormat={timeFormat}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  planModalStatus: state.resourcePlanningReducer.planModalStatus,
  planModalError: state.resourcePlanningReducer.error,
  projectsList: state.projectReducer.projectsList,
  currentTeamDetailedData: state.teamReducer.currentTeamDetailedData,
  isFetching: state.resourcePlanningReducer.isFetching,
  themeState: state.resourcePlanningReducer.themeState,
  userRole: state.teamReducer.currentTeam.data.role,
  user: state.userReducer.user,
  timeFormat: state.userReducer.timeFormat,
});

const mapDispatchToProps = {
  createPlanAction,
  showNotificationAction,
  editPlanAction,
  deletePlanAction,
  getWorkload,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcePlanningPlanModal),
);
