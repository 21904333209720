import React, { Component } from "react";

import AccordionComponent from "../AccordionComponent";
import { FolderIcon } from "../../Atoms/SvgIcons";

import Checkbox from "@material-ui/core/Checkbox";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { getProjectColor } from "../../../services/replaceProjectsColor";

import "./style.scss";

const materialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
      },
    },
    MuiButtonBase: {
      root: {
        padding: 0,
      },
    },
  },
});

class ResourcePlanningFilterItem extends Component {
  constructor(props) {
    super(props);

    this.accordionRef = React.createRef();

    this.state = {
      formatedData: props.data.slice(0, 5),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.length !== prevProps.data.length) {
      this.setState({
        formatedData: this.props.data.slice(0, 5),
      });
    }
  }

  showAllItems = (e) => {
    this.setState({ formatedData: this.props.data });
  };

  updateCheckbox = (id) => {
    this.props.update(id);
  };

  openAccordion = () => {
    if (this.accordionRef?.current) {
      this.accordionRef.current.setState({ isOpen: true });
    }
  };

  beginningListSelectedCheckbox = (newArrTopCheckbox, item) => {
    const x = newArrTopCheckbox.findIndex((v) => v === item);

    if (x !== -1) {
      newArrTopCheckbox.splice(x, 1);
    }
    newArrTopCheckbox.unshift(item);
    return newArrTopCheckbox;
  };

  topBeginningListSelectedCheckbox = (newArrTopCheckbox, item) => {
    const x = newArrTopCheckbox.findIndex((v) => v === item);

    if (x !== -1) {
      newArrTopCheckbox.splice(x, 1);
    }
    newArrTopCheckbox.unshift(item);
    return newArrTopCheckbox;
  };

  render() {
    const {
      isShowOnInit = false,
      disabled = false,
      isProject = false,
      showAllButtonTitle,
      title,
      data,
      selectedValues,
    } = this.props;
    const { formatedData } = this.state;

    return (
      <>
        {!!formatedData.length && (
          <AccordionComponent
            ref={this.accordionRef}
            isShowOnInit={isShowOnInit}
            disabled={disabled}
            title={title}
          >
            <div className="rp-filter-item">
              {formatedData.map((item, i) => (
                <div className="rp-filter-item__item" key={i}>
                  <ThemeProvider theme={materialTheme}>
                    <Checkbox
                      color="#00B173"
                      value={item.id}
                      checked={selectedValues.includes(item.id)}
                      onChange={(_) => {
                        this.updateCheckbox(item.id);
                        this.beginningListSelectedCheckbox(
                          this.props.data,
                          item,
                        );
                        this.topBeginningListSelectedCheckbox(
                          formatedData,
                          item,
                        );
                      }}
                    />
                  </ThemeProvider>
                  <div className="rp-filter-item__value-container">
                    {isProject ? (
                      <div className="rp-filter-item__icon-container">
                        <FolderIcon
                          className="rp-filter-item__folder-icon"
                          color={getProjectColor(item?.projectColor?.name)}
                        />
                      </div>
                    ) : null}

                    <span className="rp-filter-item__value">{item.title}</span>
                  </div>
                </div>
              ))}
              {data.length > 5 && formatedData.length !== data.length && (
                <button
                  className="rp-filter-item__secondary-btn"
                  type="button"
                  onClick={this.showAllItems}
                >
                  {showAllButtonTitle}
                </button>
              )}
            </div>
          </AccordionComponent>
        )}
      </>
    );
  }
}

export default ResourcePlanningFilterItem;
