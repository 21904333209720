/* eslint-disable */
import React from "react";
import error_cat from "../../assets/images/error_cat.svg";
import "./ErrorPage.scss";
import { Link, NavLink, withRouter } from "react-router-dom";

export const ErrorPage = ({ position }) => (
  <div className="error-wrapper" style={position}>
    <p className={"error-text"}> 404</p>
    <img alt="error-cats" src={error_cat} className={"error-cats"} />
    <div className="box-error-text">
      <h1>Page not found</h1>
      <p>
        Our cat tried to find it, but could not.
        <br />
        Maybe this page doesn't exist.
      </p>
      <Link className="error-back-button" to="/timer">
        Go Home
      </Link>
    </div>
  </div>
);
