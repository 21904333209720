import React, { Component, ReactElement } from "react";
import moment from "moment";
import countries from "../../../CountriesDropdown/countriesFlat.json";
import {
  CountOfStepWrapper,
  CountOfStepNumber,
  CountOfStepText,
} from "../CardModalForm/My.styled";
import { AppConfig } from "../../../../../config";
import "./style.scss";

const InfoItem = ({ title, sub, value }) =>
  (typeof value === "string" && value) ||
  (typeof value === "object" && moment(value).isValid()) ||
  typeof value === "number" ? (
    <div className="rp-user-modal-info__info">
      <div className="rp-user-modal-info__label" title={title}>
        {title}
      </div>
      <div className="rp-user-modal-info__sub" title={sub}>
        {sub}
      </div>
      <div className="rp-user-modal-info__value">{value}</div>
    </div>
  ) : null;

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("MMM D, YYYY") : "";
};

const CardModalInfo = ({ candidates, vocabulary }) => {
  const {
    v_hr_people,
    v_name,
    v_surname,
    v_email,
    v_billing_info_details,
    v_country,
    v_city,
    v_resource_planning_page,
  } = vocabulary;

  return (
    <div className="rp-user-modal-info">
      <CountOfStepWrapper>
        <CountOfStepNumber>1</CountOfStepNumber>
        <CountOfStepText>
          {v_hr_people.candidates_modal.personal}
        </CountOfStepText>
      </CountOfStepWrapper>
      <div className="rp-user-modal-info__info-container">
        <InfoItem title={v_name} sub={``} value={candidates["first_name"]} />
        <InfoItem title={v_surname} sub={``} value={candidates["last_name"]} />
        <InfoItem title={v_email} sub={``} value={candidates.email} />
        <InfoItem
          title={v_billing_info_details.phoneNumber}
          sub={``}
          value={candidates.phone}
        />
        <InfoItem
          title={v_country}
          sub={``}
          value={countries[candidates.country]?.name.common}
        />
        <InfoItem title={v_city} sub={``} value={candidates.city} />
        <InfoItem
          title={v_resource_planning_page.v_birthday_date}
          sub={``}
          value={formatDate(candidates.birthday)}
        />
        {candidates.jobType && (
          <InfoItem
            title={v_hr_people.candidates_modal.jobType}
            sub={``}
            value={
              candidates.jobType?.charAt(0).toUpperCase() +
              candidates.jobType?.slice(1)
            }
          />
        )}
      </div>
      <div className="rp-user-modal-info__separator"></div>
      <CountOfStepWrapper>
        <CountOfStepNumber>2</CountOfStepNumber>
        <CountOfStepText>{v_hr_people.candidates_modal.social}</CountOfStepText>
      </CountOfStepWrapper>
      <div className="rp-user-modal-info__info-container">
        {candidates.messenger && (
          <InfoItem
            title={"Messenger"}
            sub={``}
            value={
              <a
                className="link"
                target="_blank"
                href={`${candidates.messenger}`}
              >{`${candidates.messenger}`}</a>
            }
          />
        )}
        {candidates.linkedin && (
          <InfoItem
            title={"LinkedIn"}
            sub={``}
            value={
              <a
                className="link"
                target="_blank"
                href={`${candidates.linkedin}`}
              >{`${candidates.linkedin}`}</a>
            }
          />
        )}
        {candidates.github && (
          <InfoItem
            title={"GitHub"}
            sub={``}
            value={
              <a
                className="link"
                target="_blank"
                href={`${candidates.github}`}
              >{`${candidates.github}`}</a>
            }
          />
        )}
        {candidates["candidate_link"].length !== 0 && (
          <InfoItem
            title={v_hr_people.candidates_modal.other}
            sub={``}
            value={
              <ul>
                {candidates["candidate_link"].map((item) => (
                  <li key={item}>
                    <a className="link" href={item.link} target="_blank">
                      {item.link}
                    </a>
                  </li>
                ))}
              </ul>
            }
          />
        )}
      </div>
      <div className="rp-user-modal-info__separator"></div>
      <CountOfStepWrapper>
        <CountOfStepNumber>3</CountOfStepNumber>
        <CountOfStepText>
          {v_hr_people.candidates_modal.start_work}
        </CountOfStepText>
      </CountOfStepWrapper>
      <div className="rp-user-modal-info__info-container">
        <InfoItem
          title={v_hr_people.candidates_modal.desire}
          sub={``}
          value={formatDate(candidates["desired_date"])}
        />
        <InfoItem
          title={v_hr_people.candidates_modal.start}
          sub={``}
          value={formatDate(candidates["start_work"])}
        />
      </div>
      <div className="rp-user-modal-info__separator"></div>
      <CountOfStepWrapper>
        <CountOfStepNumber>4</CountOfStepNumber>
        <CountOfStepText>{v_hr_people.candidates_modal.about}</CountOfStepText>
      </CountOfStepWrapper>
      <div className="rp-user-modal-info__info-container">
        <InfoItem
          title={v_hr_people.candidates_modal.experience}
          sub={``}
          value={candidates.experience}
        />
        {candidates["cv_file"] && (
          <InfoItem
            title={v_hr_people.candidates_modal.cv}
            sub={``}
            value={
              <a
                className="link"
                target="_blank"
                href={`${AppConfig.apiURL}${candidates["cv_file"]}`}
              >{`${candidates["cv_file"].split("/").pop()}`}</a>
            }
          />
        )}
        {candidates["cv_client"] && (
          <InfoItem
            title={v_hr_people.candidates_modal.cvClient}
            sub={``}
            value={
              <a
                className="link"
                target="_blank"
                href={`${AppConfig.apiURL}${candidates["cv_client"]}`}
              >{`${candidates["cv_client"].split("/").pop()}`}</a>
            }
          />
        )}
        {candidates.board && <InfoItem
          title={v_hr_people.candidates_modal.direction}
          sub={``}
          value={candidates.board.title}
        />}
        {candidates.vacancy && <InfoItem
          title={v_hr_people.candidates_modal.vacancy}
          sub={``}
          value={candidates.vacancy.title}
        />}
        <InfoItem
          title={v_hr_people.candidates_modal.level}
          sub={``}
          value={candidates.level}
        />
        {candidates.minSalary || candidates.maxSalary ? (
          <InfoItem
            title={v_hr_people.candidates_modal.salary}
            sub={``}
            value={`${candidates.minSalary || "0"} ${"-"} ${
              candidates.maxSalary || "0"
            } ${" "} ${candidates.currency || "USD"}`}
          />
        ) : null}
      </div>
      <div className="rp-user-modal-info__separator"></div>
      <CountOfStepWrapper>
        <CountOfStepNumber>5</CountOfStepNumber>
        <CountOfStepText>
          {v_hr_people.candidates_modal.booking}
        </CountOfStepText>
      </CountOfStepWrapper>
      <div className="rp-user-modal-info__info-last-container">
        <InfoItem
          title={v_hr_people.candidates_modal.hrInt}
          sub={``}
          value={formatDate(candidates["interview_date"])}
        />
        <InfoItem
          title={v_hr_people.candidates_modal.techInt}
          sub={``}
          value={formatDate(candidates["tech_interview_date"])}
        />
        <InfoItem title={" "} sub={``} value={" "} />
      </div>
      <div className="rp-user-modal-info__info-last-container">
        <InfoItem
          title={v_hr_people.candidates_modal.feedHr}
          sub={``}
          value={candidates["feedback_hr"]}
        />
      </div>
      <div className="rp-user-modal-info__info-last-container">
        <InfoItem
          title={v_hr_people.candidates_modal.feedTech}
          sub={``}
          value={candidates["feedback_tech"]}
        />
      </div>
    </div>
  );
};
export default CardModalInfo;
