import React from "react";

const MediumPriority = () => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.00012H15" stroke="#F3AD26" strokeWidth="2" strokeLinecap="round"/>
    <path d="M1 8.00012H15" stroke="#F3AD26" strokeWidth="2" strokeLinecap="round"/>
  </svg>

);

export default MediumPriority;

