import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import { Doughnut } from "react-chartjs-2";
import { AppConfig } from "../../../config";
import cn from "classnames";
import { Loading } from "../Loading";
import "./style.scss";
import {
  convertDateToISOString, convertDateToShiftedISOString,
  getTimeDurationByGivenTimestamp,
  getTimestamp,
} from "../../../services/timeService";
import { ROLES } from "../../../services/authentication";
import reportsPageAction from "../../../redux/actions/ReportsPageAction";
import { BlankListComponent } from "../../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import ReportsSearchBar from "../../../components/Unstructured/reportsSearchBar";
import CustomTippy from "../../../components/Unstructured/Tooltip/CustomTippy";
import { followCursor } from "tippy.js";

import { apiCall } from "../../../services/apiService";
import { getDoughnutGraphParseFunction } from "../../../services/parseFunctions";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { ChevronDownIcon } from "../../Atoms/SvgIcons";
import { getProjectColor } from "../../../../src/services/replaceProjectsColor";

import { getUserActivities } from "../../../api/timer";

import { REPORTS_MAX_CACHE_TIME } from "../../../constants/reports";

class RealTimeReport extends Component {
  state = {
    filterModalFlag: false,
    stateFilter: "All",
    isInitialFetching: true,
    isFetching: false,
    isAdmin: false,
    datesValue: null,
  };

  closeOnClickOutside = (e) => {
    if (!document.getElementById("filterBlock").contains(e.target)) {
      this.setState({ filterModalFlag: false });
    }
  };

  filterModalHandler = (e) => {
    e.stopPropagation();
    this.setState({ filterModalFlag: !this.state.filterModalFlag });
    window.addEventListener("click", this.closeOnClickOutside);
  };

  filterHandler = (value) => {
    this.setState({ stateFilter: value });

    if (this.props.isCrossTeam) {
      this.applySearchCrossTeam(value);
    } else {
      this.getUserActivitiesAction(value);
    }
  };

  async getUserActivitiesAction(filterValue = this.state.stateFilter) {
    this.setState({ isFetching: true });
    try {
      const projectNames = this.props.selectedProjects;
      const usersEmails = this.props.selectedUsers;

      const { data } = await getUserActivities({
        projectNames,
        usersEmails,
        statusFilter: filterValue,
      });

      this.props.reportsPageAction("SET_USER_ACTIVITIES", data);

      this.drawDoughnut();

      this.setState({ isFetching: false });
      this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);
    } catch (error) {
      this.setState({ isFetching: false });
      this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);
    }
  }

  applySearchRealTime = async () => {
    await this.getUserActivitiesAction();
  };

  componentDidUpdate(prevProps) {
    if (!this.state.filterModalFlag) {
      window.removeEventListener("click", this.closeOnClickOutside);
    }

    if (
      prevProps.currentTeam?.data?.role !== this.props.currentTeam?.data?.role
    ) {
      this.checkRole();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeOnClickOutside);
  }

  componentDidMount() {
    this.props.reportsPageAction("SET_REAL_TIME_DOUGHNUT_GRAPH", null);
    this.checkRole();
    this.getUserActivitiesAction();

    const isData = !this.props.isCrossTeam
      ? Boolean(this.props.userActivities?.length)
      : Boolean(this.props.crossReportData?.length);

    if (
      this.props.lastFetchedRealTime + REPORTS_MAX_CACHE_TIME <
        getTimestamp() ||
      !isData
    ) {
      this.triggerSearch(true);
    } else {
      this.drawDoughnut();
      this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);
    }
  }

  doughnutOptions = (durationTimeFormat) => ({
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data.labels[tooltipItem.index];
          const duration = getTimeDurationByGivenTimestamp(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
            durationTimeFormat,
          );

          return `${label}: ${duration}`;
        },
      },
      backgroundColor: "#292b2f",
      borderColor: "#fafafc",
      borderWidth: 1,
    },
  });

  drawDoughnut = () => {
    const newObjectChart = { ...this.props.realTimeDoughnutChart };

    newObjectChart.labels = [];
    newObjectChart.datasets[0].data = [];
    const getDataCrossTeamDoughnut = getDoughnutGraphParseFunction(
      newObjectChart,
      this.props.isCrossTeam
        ? this.props.crossReportData
        : this.props.userActivities,
    );

    this.props.reportsPageAction("SET_REAL_TIME_DOUGHNUT_GRAPH", {
      data: getDataCrossTeamDoughnut,
    });

    this.calculateDatesValue();
  };

  applySearchCrossTeam(value) {
    if (this.abortData) {
      this.abortData.abort();
    }
    if (this.abortList) {
      this.abortList.abort();
    }
    this.abortData = new AbortController();
    this.abortList = new AbortController();

    this.setState({ isFetching: true });

    let selectedProjects = null;
    let inputTeamData = null;
    let inputUserDataCrosteam = this.props.selectedUsersCross || null;

    if (this.props.selectedUsersCross && this.props.selectedUsersCross.length) {
      inputUserDataCrosteam = this.props.selectedUsersCross;
    }

    // if (
    //   !checkIsAdminByRole(this.props.currentTeam.data.role) &&
    //   !checkIsOwnerByRole(this.props.currentTeam.data.role)
    // ) {
    // } else if (
    //   this.props.isCrossTeam &&
    //   this.props.selectedUsersCross &&
    //   this.props.selectedUsersCross.length
    // ) {
    //   inputUserDataCrosteam = this.props.selectedUsersCross;
    // } else if (
    //   !this.props.isCrossTeam &&
    //   this.props.selectedTeams &&
    //   this.props.selectedTeams.length
    // ) {
    //   inputTeamData = this.props.selectedTeams;
    // }

    if (
      this.props.selectedProjectsCross &&
      this.props.selectedProjectsCross.length
    ) {
      selectedProjects = this.props.selectedProjectsCross;
    }

    if (this.props.selectedTeams && this.props.selectedTeams.length) {
      inputTeamData = this.props.selectedTeams;
    }

    const data = {
      active: value,
      userEmails: inputUserDataCrosteam,
      projectIds: selectedProjects,
      teamIds: inputTeamData,
    };

    apiCall(`${AppConfig.apiURL}project/teams-activities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: this.abortData.signal,
      body: JSON.stringify(data),
    }).then(
      (result) => {
        const data = result;

        this.props.reportsPageAction("SET_CROSS_REPORT_DATA", data);

        this.drawDoughnut();

        this.setState({
          isFetching: false,
        });
        this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);
      },
      (err) => {
        if (err.code !== DOMException.ABORT_ERR) {
          this.setState({
            isInitialFetching: false,
            isFetching: false,
          });
          this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);
        }

        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  checkRole() {
    const isAdmin = [
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_OWNER,
      ROLES.ROLE_HR_MANAGER,
      ROLES.ROLE_PROJECT_MANAGER,
      ROLES.ROLE_INVOICES_MANAGER,
    ].includes(this.props.currentTeam?.data?.role);

    this.setState({ isAdmin });
  }

  calculateDatesValue() {
    if (this.props.realTimeDoughnutChart?.datasets?.[0]?.data?.length) {
      const nextValue = JSON.parse(
        JSON.stringify(
          this.props.realTimeDoughnutChart.datasets[0].data.reduce(
            (a, b) => a + b,
          ),
        ),
      );

      this.setState({
        datesValue: nextValue,
      });
    } else {
      this.setState({
        datesValue: null,
      });
    }
  }

  triggerSearch(isInit = false) {
    this.props.isCrossTeam
      ? this.applySearchCrossTeam(this.state.stateFilter)
      : this.applySearchRealTime();

    if (!isInit) {
      this.props.reportsPageAction("SET_LAST_FETCHED_SUMMARY", 0);
      this.props.reportsPageAction(
        "SET_LAST_FETCHED_REAL_TIME",
        getTimestamp(),
      );
    } else {
      this.props.reportsPageAction(
        "SET_LAST_FETCHED_REAL_TIME",
        getTimestamp(),
      );
    }
  }

  render() {
    const { filterModalFlag, isAdmin, datesValue } = this.state;
    const {
      vocabulary,
      durationTimeFormat,
      userActivities,
      realTimeDoughnutChart,
      crossReportData,
    } = this.props;
    const { lang, v_no_recorded_team_time, v_not_active_user, v_plan_details } =
      vocabulary;

    return (
      <div>
        {isAdmin && (
          <ReportsSearchBar
            adminOrOwner={isAdmin}
            applySearch={(isInit) => this.triggerSearch(isInit)}
            inputClientData={this.props.clientsList}
            startDate={convertDateToISOString(this.props.selectionRange.startDate)}
          />
        )}
        <div className="real-time_container_wrapper">
          <div className="real-time_container_filter">
            <div className="real-time_container_filter_button">
              <div className="block_count_users">
                <span className="block_count_users__text">
                  {v_plan_details.users}
                  {": "}
                </span>
                <span className="block_count_users__count">
                  {this.props.isCrossTeam
                    ? crossReportData.length
                    : userActivities.length}
                </span>
              </div>
              <div
                className="filter_button"
                id="filterBlock"
                onClick={(e) => this.filterModalHandler(e)}
              >
                {`Filter:  ${this.state.stateFilter}`}
                <i
                  className={cn(
                    `arrow_down ${filterModalFlag ? "arrow_down_up" : ""}`,
                    {
                      arrow_down__light_themes:
                        localStorage.getItem("themes") === "light",
                    },
                  )}
                />
                {filterModalFlag && (
                  <div className="filter__dropdown">
                    <div
                      className="filter__item"
                      onClick={(e) => this.filterHandler("All")}
                    >
                      {"All"}
                    </div>
                    <div
                      className="filter__item"
                      onClick={(e) => this.filterHandler("Online")}
                    >
                      {"Online"}
                    </div>
                    <div
                      className="filter__item"
                      onClick={(e) => this.filterHandler("Recent")}
                    >
                      {"Recent"}
                    </div>
                    <div
                      className="filter__item"
                      onClick={(e) => this.filterHandler("Offline")}
                    >
                      {"Offline"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="real-time_global_container">
            <div
              className={cn("real-time_container_real-time", {
                "real-time_container_real-time--full":
                  (this.props.isCrossTeam && crossReportData.length === 0) ||
                  (!this.props.isCrossTeam && userActivities.length === 0),
              })}
            >
              <Loading
                flag={this.state.isFetching}
                mode="parentSize"
                withLogo={false}
              >
                <div>
                  {(this.props.isCrossTeam && crossReportData.length === 0) ||
                  (!this.props.isCrossTeam && userActivities.length === 0) ? (
                    <BlankListComponent
                      subtext={this.props.vocabulary.v_no_report}
                    />
                  ) : this.props.isCrossTeam ? (
                    <>
                      {crossReportData.map((cross) => (
                        <div className="cross_team" key={cross.id}>
                          <div className="cross_team_user">
                            <div className="cross_team_user__block">
                              <div className="cross_team_user__avatar">
                                {!cross.avatar ? (
                                  <div className="avatar-small" />
                                ) : (
                                  <div
                                    className="avatar-small avatar-cover"
                                    style={{
                                      backgroundImage: `url(${AppConfig.apiURL}${cross.avatar})`,
                                    }}
                                  />
                                )}
                              </div>

                              <div className="cross_team-info">
                                <div className="cross_team-user">
                                  <div>
                                    <div className="cross_team-user__name">
                                      {cross.username}
                                    </div>
                                    {cross.user_technology.length > 3 ? (
                                      <ScrollingCarousel
                                        className={"reports_carousel"}
                                        rightIcon={
                                          <ChevronDownIcon
                                            className="cross_team-info-tags__icon cross_team-info-tags__icon--right"
                                            color="white"
                                          />
                                        }
                                        leftIcon={
                                          <ChevronDownIcon
                                            className="cross_team-info-tags__icon cross_team-info-tags__icon--left"
                                            color="white"
                                          />
                                        }
                                      >
                                        {cross.user_technology.map((item) => (
                                          <div
                                            key={item.technology.id}
                                            className="cross_team-info-tags"
                                          >
                                            <span className="cross_team-info-tags__tag">
                                              {item.technology.title}
                                            </span>
                                          </div>
                                        ))}
                                      </ScrollingCarousel>
                                    ) : (
                                      <div className="cross_team-info-tags">
                                        {cross.user_technology.map((item) => (
                                          <span
                                            key={item.technology.id}
                                            className="cross_team-info-tags__tag"
                                            style={{ width: "auto" }}
                                          >
                                            {item.technology.title}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                  <div className="cross_team-user__status">
                                    {cross.timer_current_v2 !== null && (
                                      <span className="cross_team_user__timer-info__active">
                                        Online
                                      </span>
                                    )}
                                    {cross.timer_v2.length !== 0 ? (
                                      <>
                                        <div>
                                          {cross.timer_current_v2 === null &&
                                          cross.timer_v2.length !== 0
                                            ? getTimeDurationByGivenTimestamp(
                                                moment(
                                                  cross.timer_v2[0]
                                                    .end_datetime,
                                                ).valueOf() -
                                                  moment(
                                                    cross.timer_v2[0]
                                                      .start_datetime,
                                                  ).valueOf(),
                                                durationTimeFormat,
                                              )
                                            : null}
                                        </div>
                                        <div className="cross_team_user__timer-info__last-active">
                                          {cross.timer_current_v2 === null &&
                                          cross.timer_v2.length !== 0
                                            ? moment(
                                                cross.timer_v2[0].end_datetime,
                                              )
                                                .locale(lang.short)
                                                .fromNow()
                                            : null}
                                        </div>
                                      </>
                                    ) : cross.timer_v2.length === 0 &&
                                      cross.timer_current_v2 === null ? (
                                      <span className="cross_team_user__timer-info__no-active">
                                        {v_not_active_user}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="cross_team_user__task-info">
                              <div className="cross_team-user__project">
                                {cross.timer_current_v2 !== null ? (
                                  <>
                                    <div
                                      className="cross_team_user__task-info__circle"
                                      style={{
                                        background: getProjectColor(
                                          cross.timer_current_v2.project_v2
                                            .project_color.name,
                                        ),
                                      }}
                                    ></div>
                                    <span className="cross_team-user__project__name">
                                      {cross.timer_current_v2.project_v2.name}
                                    </span>
                                  </>
                                ) : cross.timer_current_v2 === null &&
                                  cross.timer_v2.length !== 0 ? (
                                  <>
                                    <span
                                      className="cross_team_user__task-info__circle"
                                      style={{
                                        background: getProjectColor(
                                          cross.timer_v2[0].project_v2
                                            .project_color.name,
                                        ),
                                      }}
                                    ></span>
                                    <span className="cross_team-user__project__name">
                                      {cross.timer_v2[0].project_v2.name}
                                    </span>
                                  </>
                                ) : null}
                              </div>
                              {cross.timer_current_v2 === null &&
                                cross.timer_v2.length === 0 && (
                                  <span>{v_no_recorded_team_time}</span>
                                )}
                              <div className="cross_team_user__task-info__issue">
                                <CustomTippy
                                  content={
                                    cross.timer_current_v2 !== null
                                      ? cross.timer_current_v2.issue
                                      : cross.timer_current_v2 === null &&
                                        cross.timer_v2.length !== 0
                                      ? cross.timer_v2[0].issue
                                      : null
                                  }
                                  plugins={[followCursor]}
                                  followCursor={true}
                                  arrow={false}
                                >
                                  <div className="issue_popup">
                                    {cross.timer_current_v2 !== null
                                      ? cross.timer_current_v2.issue
                                      : cross.timer_current_v2 === null &&
                                        cross.timer_v2.length !== 0
                                      ? cross.timer_v2[0].issue
                                      : null}
                                  </div>
                                </CustomTippy>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div>
                      <div className="projects_data">
                        {userActivities.map((project, index) => (
                          <div className="cross_team" key={project.id}>
                            <div className="cross_team_user">
                              <div className="cross_team_user__block">
                                <div className="cross_team_user__avatar">
                                  {!project.avatar ? (
                                    <div className="avatar-small" />
                                  ) : (
                                    <div
                                      className="avatar-small avatar-cover"
                                      style={{
                                        backgroundImage: `url(${AppConfig.apiURL}${project.avatar})`,
                                      }}
                                    />
                                  )}
                                </div>

                                <div className="cross_team-info">
                                  <div className="cross_team-user">
                                    <div>
                                      <div className="cross_team-user__name">
                                        {project.username}
                                      </div>
                                      {project.user_technology.length > 3 ? (
                                        <ScrollingCarousel
                                          className={"reports_carousel"}
                                          rightIcon={
                                            <ChevronDownIcon
                                              className="cross_team-info-tags__icon cross_team-info-tags__icon--right"
                                              color="white"
                                            />
                                          }
                                          leftIcon={
                                            <ChevronDownIcon
                                              className="cross_team-info-tags__icon cross_team-info-tags__icon--left"
                                              color="white"
                                            />
                                          }
                                        >
                                          {project.user_technology.map(
                                            (item) => (
                                              <div
                                                key={item.technology.id}
                                                className="cross_team-info-tags"
                                              >
                                                <span className="cross_team-info-tags__tag">
                                                  {item.technology.title}
                                                </span>
                                              </div>
                                            ),
                                          )}
                                        </ScrollingCarousel>
                                      ) : (
                                        <div className="cross_team-info-tags">
                                          {project.user_technology.map(
                                            (item) => (
                                              <span
                                                key={item.technology.id}
                                                className="cross_team-info-tags__tag"
                                                style={{ width: "auto" }}
                                              >
                                                {item.technology.title}
                                              </span>
                                            ),
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="cross_team-user__status">
                                      {project.timer_current_v2 !== null && (
                                        <span className="cross_team_user__timer-info__active">
                                          Online
                                        </span>
                                      )}
                                      {project.timer_v2.length !== 0 ? (
                                        <>
                                          <div>
                                            {project.timer_current_v2 ===
                                              null &&
                                            project.timer_v2.length !== 0
                                              ? getTimeDurationByGivenTimestamp(
                                                  moment(
                                                    project.timer_v2[0]
                                                      .end_datetime,
                                                  ).valueOf() -
                                                    moment(
                                                      project.timer_v2[0]
                                                        .start_datetime,
                                                    ).valueOf(),
                                                  durationTimeFormat,
                                                )
                                              : null}
                                          </div>
                                          <div className="cross_team_user__timer-info__last-active">
                                            {project.timer_current_v2 ===
                                              null &&
                                            project.timer_v2.length !== 0
                                              ? moment(
                                                  project.timer_v2[0]
                                                    .end_datetime,
                                                )
                                                  .locale(lang.short)
                                                  .fromNow()
                                              : null}
                                          </div>
                                        </>
                                      ) : project.timer_v2.length === 0 &&
                                        project.timer_current_v2 === null ? (
                                        <span className="cross_team_user__timer-info__no-active">
                                          {v_not_active_user}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="cross_team_user__task-info">
                                <div className="cross_team-user__project">
                                  {project.timer_current_v2 !== null ? (
                                    <>
                                      <div
                                        className="cross_team_user__task-info__circle"
                                        style={{
                                          background: getProjectColor(
                                            project.timer_current_v2.project_v2
                                              .project_color.name,
                                          ),
                                        }}
                                      ></div>
                                      <span className="cross_team-user__project__name">
                                        {
                                          project.timer_current_v2.project_v2
                                            .name
                                        }
                                      </span>
                                    </>
                                  ) : project.timer_current_v2 === null &&
                                    project.timer_v2.length !== 0 ? (
                                    <>
                                      <span
                                        className="cross_team_user__task-info__circle"
                                        style={{
                                          background: getProjectColor(
                                            project.timer_v2[0].project_v2
                                              .project_color.name,
                                          ),
                                        }}
                                      ></span>
                                      <span className="cross_team-user__project__name">
                                        {project.timer_v2[0].project_v2.name}
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                                {project.timer_current_v2 === null &&
                                  project.timer_v2.length === 0 && (
                                    <span>{v_no_recorded_team_time}</span>
                                  )}
                                <div className="cross_team_user__task-info__issue">
                                  <CustomTippy
                                    content={
                                      project.timer_current_v2 !== null
                                        ? project.timer_current_v2.issue
                                        : project.timer_current_v2 === null &&
                                          project.timer_v2.length !== 0
                                        ? project.timer_v2[0].issue
                                        : null
                                    }
                                    plugins={[followCursor]}
                                    followCursor={true}
                                    arrow={false}
                                  >
                                    <div className="issue_popup">
                                      {project.timer_current_v2 !== null
                                        ? project.timer_current_v2.issue
                                        : project.timer_current_v2 === null &&
                                          project.timer_v2.length !== 0
                                        ? project.timer_v2[0].issue
                                        : null}
                                    </div>
                                  </CustomTippy>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Loading>
            </div>

            {((this.props.isCrossTeam && crossReportData.length > 0) ||
              (!this.props.isCrossTeam && userActivities.length > 0)) &&
            !this.state.isFetching &&
            typeof datesValue === "number" &&
            datesValue > 0 ? (
              <div className="chart">
                <div className="time-ancor">
                  <div className="total_time_tasks">
                    {this.doughnutOptions(durationTimeFormat) &&
                      getTimeDurationByGivenTimestamp(
                        datesValue,
                        durationTimeFormat,
                      )}
                  </div>
                  <Doughnut
                    data={realTimeDoughnutChart}
                    options={this.doughnutOptions(durationTimeFormat)}
                    width={303}
                    height={303}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  durationTimeFormat: state.userReducer.durationTimeFormat,
  realTimeDoughnutChart: state.reportsPageReducer.realTimeDoughnutChart,
  currentTeam: state.teamReducer.currentTeam,
  selectedUsers: state.reportsPageReducer.selectedUsers,
  selectedProjects: state.reportsPageReducer.selectedProjects,
  clientsList: state.clientsReducer.clientsList,
  dateFormat: state.userReducer.dateFormat,
  timeRange: state.reportsPageReducer.timeRange,
  isCrossTeam: state.reportsPageReducer.isCrossTeam,
  selectedProjectsCross: state.reportsPageReducer.selectedProjectsCross,
  selectedTeams: state.reportsPageReducer.selectedTeams,
  user: state.userReducer.user,
  selectedUsersCross: state.reportsPageReducer.selectedUsersCross,
  crossReportData: state.reportsPageReducer.crossReportData,
  lastFetchedRealTime: state.reportsPageReducer.lastFetchedRealTime,
  userActivities: state.reportsPageReducer.userActivities,
  filter: state.reportsPageReducer.filter,
  selectionRange: state.reportsPageReducer.selectionRange,
});
const mapDispatchToProps = {
  reportsPageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeReport);
