import React, { useCallback, useEffect, useState } from "react";
import Board from "react-trello";
import CardModal from "../CardModal/index";
import get from "lodash/get";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getBoard,
  addLane,
  moveLane,
  deleteLane,
  changeLaneTitle,
  addCard,
  moveCard,
  deleteCard,
  changeCardText,
  resetCandidateInfo,
  searchCard,
  getCardData,
} from "../../../../redux/actions/PepoplePageActions";
import "./style.scss";
import cn from "classnames";
import {
  GlobalStyle,
  LaneHeader,
  LaneTitle,
  LaneFooter,
  LaneSection,
  ScrollableLane,
  AddCardLink,
  BoardWrapper,
  Header,
  Section,
  Title,
  MyCardWrapper,
  MovableCardWrapper,
  CardTitle,
  CardHeader,
  CardRightContent,
  Detail,
  NewLaneSection,
  NewLaneButtons,
  CardForm,
  InlineInput,
  CustomPopoverContainer,
  CustomPopoverContent,
  RightContent,
  Footer,
  TagSpan,
} from "./Base";
import {
  DeleteWrapper,
  GenDelButton,
  DelButton,
  MenuButton,
  LaneMenuHeader,
  LaneMenuContent,
  LaneMenuItem,
  LaneMenuTitle,
  DeleteIcon,
  ExpandCollapseBase,
  CollapseBtn,
  ExpandBtn,
  AddButton,
  CancelButton,
  AddLaneLink,
} from "./Elements";
import { LoaderDiv, LoadingBar } from "./loader";
import MyNewCardFrom from "./NewForms/NewCardForm";
import MyNewLaneForm from "./NewForms/NewLaneForm";
import MyNewLaneSection from "./NewForms/NewLaneSection";
import RecruitmentSearchComponent from "../RecruitmentSearchComponent";
import { RecruitmentFilterDropdown } from "../../RecruitmentFilterDropdown";

const HRBoard = ({
  title,
  vocabulary,
  board,
  getBoard,
  addLane,
  changeLaneTitle,
  moveLane,
  deleteLane,
  addCard,
  changeCardText,
  moveCard,
  deleteCard,
  resetCandidateInfo,
  searchCardHandler,
  getCardData,
  vacanciesDataFromRedux,
  candidates,
}) => {
  // const [board, setBoard] = useState({ lanes: [] });
  const [openCardModal, setOpenCardModal] = useState(true);
  const [searchedValue, setSearchedValue] = useState("");
  const [initialCandidate, setInitialCandidate] = useState();
  const [vacanciesData, setVacanciesData] = useState([]);
  const { v_hr_people, v_back, v_search } = vocabulary;
  let { state } = useLocation();
  const boardId = state.id;
  const components = {
    GlobalStyle: GlobalStyle, // global style created with method `createGlobalStyle` of `styled-components`
    // LaneHeader: LaneHeader, //this style make element invisible, need investigation
    // AddCardLink: AddCardLink, //this style make element invisible, need investigation
    LaneTitle: LaneTitle,
    ScrollableLane: ScrollableLane,
    BoardWrapper: BoardWrapper,
    Header: Header,
    Section: Section,
    // LaneFooter: LaneFooter, //this style make element invisible, need investigation
    Title: Title,
    CardWrapper: MyCardWrapper,
    CardRightContent: CardRightContent,
    MovableCardWrapper: MovableCardWrapper,
    CardTitle: CardTitle,
    CardHeader: CardHeader,
    LaneSection: LaneSection,
    NewLaneButtons: NewLaneButtons,
    CardForm: CardForm,
    InlineInput: InlineInput,
    CustomPopoverContaine: CustomPopoverContainer,
    CustomPopoverContent: CustomPopoverContent,
    RightContent: RightContent,
    Detail: Detail,
    Footer: Footer,
    TagSpan: TagSpan,
    DeleteWrapper: DeleteWrapper,
    GenDelButton: GenDelButton,
    DelButton: DelButton,
    MenuButton: MenuButton,
    LaneMenuHeader: LaneMenuHeader,
    LaneMenuContent: LaneMenuContent,
    LaneMenuItem: LaneMenuItem,
    LaneMenuTitle: LaneMenuTitle,
    DeleteIcon: DeleteIcon,
    ExpandCollapseBase: ExpandCollapseBase,
    CollapseBtn: CollapseBtn,
    ExpandBtn: ExpandBtn,
    AddButton: AddButton,
    CancelButton: CancelButton,
    AddLaneLink: AddLaneLink,
    LoaderDiv: LoaderDiv,
    LoadingBar: LoadingBar,
    NewCardForm: MyNewCardFrom,
    NewLaneForm: MyNewLaneForm,
    NewLaneSection: MyNewLaneSection,
  };

  const onCardClick = async (cardId, metadata, laneId) => {
    const response = await getCardData(cardId);
    await setInitialCandidate(response);
    setOpenCardModal(!openCardModal);
  };

  const closeCardModal = () => {
    setOpenCardModal(!openCardModal);
    resetCandidateInfo();
  };

  const onDataChange = (newData) => {
    // setBoard(newData);
  };

  let eventBus = undefined;
  const setEventBus = (handle) => {
    eventBus = handle;
  };

  useEffect(() => {
    if (searchedValue) {
      handleCard({ typeConfirm: "search" });
    } else {
      handleCard({ typeConfirm: "board" });
    }
  }, []);

  const handleSetSearchedValue = async (value) => {
    if (value === null) {
      // user clicked clear value
      setSearchedValue("");
      await handleCard({ typeConfirm: "board" });
    } else {
      setSearchedValue(value);
    }
  };

  const handleCard = async ({ typeConfirm = "search" }) => {
    switch (typeConfirm) {
      case "search":
        if (searchedValue) {
          await searchCardHandler({
            boardId: boardId,
            searchedValue: searchedValue,
          });
        } else {
          await getBoard({ boardId });
        }
        break;

      default:
        getBoard({ boardId });
        break;
    }
  };

  useEffect(() => {
    getBoard({ boardId });
  }, [boardId]);

  useEffect(() => {
    const vacancyData = get(vacanciesDataFromRedux, "vacancies", []);
    setVacanciesData(vacancyData);
  }, [vacanciesDataFromRedux]);

  return (
    <div className="board_wrapper">
      <div
        className={cn("board_wrapper__header", {
          board_wrapper__header__blackdrop: !openCardModal,
        })}
      >
        <div className="board_wrapper__header__title">{state.title}</div>
        <div className="board_wrapper__header__back-btn">
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            to={{
              pathname: "/recruitment/directions",
            }}
          >
            <div
              className={cn("heade_link", {
                heade_link__light: localStorage.getItem("themes") === "light",
              })}
            ></div>
            <div className="heade_link__title">{v_back}</div>
          </Link>
        </div>
      </div>
      <div
        className={cn("board_wrapper__menu", {
          board_wrapper__menu__blackdrop: !openCardModal,
        })}
      >
        <div className="board_wrapper__menu__dir-drop">
          <RecruitmentFilterDropdown
            title={v_hr_people.header.board}
            selectors={state.items}
          />
        </div>
        <div className="board_wrapper__menu__filtr">
          <div className="board_wrapper__menu__filtr__name">
            <RecruitmentSearchComponent
              handleSearch={handleSetSearchedValue}
              handleConfirmSearch={handleCard}
              placeholder={v_hr_people.directions.search_by_name_placeholder}
            />
            <button className="filtr__name__btn" onClick={handleCard}>
              {v_search}
            </button>
          </div>
          <div className="board_wrapper__menu__filtr__vacancies">
            {"DROPDOWN VACANCIES < >"}
          </div>
        </div>
      </div>
      <div className="board_wrapper__board">
        <Board
          components={components}
          data={board}
          draggable={true}
          collapsibleLanes={true}
          editable={true}
          canAddLanes={true}
          editLaneTitle={true}
          onCardAdd={(params, laneId) => addCard(params, laneId, boardId)}
          onCardUpdate={changeCardText}
          handleLaneDragEnd={moveLane}
          onDataChange={onDataChange}
          onCardClick={onCardClick}
          onCardDelete={(params) => deleteCard(params, boardId)}
          onCardMoveAcrossLanes={moveCard}
          onLaneAdd={(params) => addLane(params, boardId)}
          onLaneDelete={(params) => deleteLane(params, boardId)}
          onLaneUpdate={(params, data) =>
            changeLaneTitle(params, data, boardId)
          }
          eventBusHandle={setEventBus}
          // onBeforeCardDelete={onBeforeCardDelete}
        />
      </div>
      {!openCardModal && (
        <CardModal
          closeCardModal={closeCardModal}
          boardId={boardId}
          candidates={initialCandidate}
          selectors={state.items}
          vacanciesData={vacanciesData}
          vacanciesDataFromRedux={vacanciesDataFromRedux}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  board: state.boardReducer,
  candidates: state.boardReducer.candidates,
  vacanciesDataFromRedux: state.boardReducer.vacancies,
});
const mapDispatchToProps = {
  getBoard,
  addLane,
  moveLane,
  deleteLane,
  changeLaneTitle,
  addCard,
  moveCard,
  deleteCard,
  searchCardHandler: searchCard,
  changeCardText,
  resetCandidateInfo,
  getCardData,
};

export default connect(mapStateToProps, mapDispatchToProps)(HRBoard);
