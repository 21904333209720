import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import List from "../ListComponent";
import RecruitmentTableHeader from "../../RecruitmentTableHeader";
import RecruitmentSearchComponent from "../RecruitmentSearchComponent";
import { NoInfo } from "../../NoInfo";
import classNames from "classnames";
import {
  archiveApplication,
  getSearchApplication,
  postApplication,
  removeApplication,
  updateApplication,
} from "../../../../redux/actions/PepoplePageActions";

import {
  ApplicationsBody,
  AppHeaderWrapper,
  DirectionsSearch,
  NewAppContainer,
  AddDirBtn,
  MenuWrapper,
  ApplicationsListWrapper,
  IconButtonPlus,
  Table,
  ArchiveWrapper,
} from "./My.styled";
import CreateNewApplication from "../CreateNewApplication";
import Pagination from "../../../Atoms/Pagination";
import get from "lodash/get";
// import Switcher from "../../Subscriprions/AllPlans/Switcher";
import Switcher from "../VacanciesSwitcher";
import Modal from "../../../Atoms/Modal";
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
} from "../CreateNewApplication/My.styled";
import VacanciesSwitcher from "../VacanciesSwitcher";
import {
  StyledAddVacancies,
  StyledVacanciesButton,
  VacanciesHeadeRightContent,
  VacanciesHeaderWrapper,
  VacanciesSearchBlock,
} from "../VacanciesComponent/My.styled";

const defaultArchivedState = {
  isOpen: false,
  id: null,
};

const Applications = ({
  vocabulary,
  directionDataFromRedux,
  getApplicationHandler,
  postApplicationHandler,
  updateApplicationHandler,
  archiveApplicationHandler,
  removeApplicationHandler,
}) => {
  const [dir, setDir] = useState("application");
  const [archived, setArchived] = useState(false);
  const [pageDirection, setPageDirection] = useState(1);
  const [directionData, setDirectionData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [priority, setPriority] = useState("");
  const [isArchiveModal, setArchiveModal] = useState(defaultArchivedState);

  const {
    v_hr_people,
    v_search,
    v_filter_active,
    v_filter_archived,
    v_cancel,
    v_delete,
  } = vocabulary;

  useEffect(() => {
    handleGetDirections({ page: pageDirection });
  }, []);

  useEffect(() => {
    const boardsData = get(directionDataFromRedux, "orders", []);

    setDirectionData(boardsData);
  }, [directionDataFromRedux]);

  useEffect(() => {
    if (!searchedValue) {
      handleGetDirections({ page: pageDirection });
    }
  }, [searchedValue]);

  useEffect(() => {
    handleGetDirections({
      page: pageDirection,
      archived: archived,
      priority: priority.toLowerCase(),
    });
  }, [pageDirection, archived, priority]);

  const handleGetDirections = async ({ typeConfirm = false, page = false }) => {
    const additionalParams = {
      ...(searchedValue && { searchedValue }),
      ...(priority && { priority: priority.toLowerCase() }),
    };

    const passedPage = page ? page : pageDirection;

    const data = await getApplicationHandler({
      page: passedPage,
      archived: archived,
      ...additionalParams,
    });

    if (data?.currentPage) {
      setPageDirection(data.currentPage);
    }
    // getApplicationHandler({ page: 1 });
  };

  const [tableHeaderDataList, setTableHeaderDataList] = useState([
    {
      title: "",
      id: 0,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: true,
    },
    {
      title: v_hr_people.headerTable.name_applications,
      id: 1,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.location,
      id: 2,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.priorities,
      id: 3,
      isSelector: true,
      isFilter: false,
      onFilterHendler: (value) => setPriority(value.toUpperCase()),
      selector: [
        {
          titleSelector: v_hr_people.prioritiesSelectors.high,
          selected: false,
        },
        {
          titleSelector: v_hr_people.prioritiesSelectors.medium,
          selected: false,
        },
        {
          titleSelector: v_hr_people.prioritiesSelectors.low,
          selected: false,
        },
      ],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.date_of_creation,
      id: 4,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.deadline,
      id: 5,
      isSelector: false,
      isFilter: true,
      selector: [],
      editButton: false,
    },
  ]);
  const createApplicationRef = useRef(null);

  const handleSetSearchedValue = async (value) => {
    if (value === null) {
      // user clicked clear value
      await setSearchedValue("");
    } else {
      setSearchedValue(value);
    }
  };

  const handleClickIcon = (elem, type) => {
    if (type === "edit") {
      createApplicationRef.current.handleOpenCreateModal(elem);
    }
    if (type === "archive") {
      setArchiveModal((prevState) => ({
        ...prevState,
        id: elem.id,
        isOpen: true,
      }));
    }
  };

  const handleSwitcher = (value) => {
    setArchived(!archived);
  };

  const handleArchive = async () => {
    await archiveApplicationHandler(isArchiveModal.id);
    await handleGetDirections({ page: pageDirection });
    setArchiveModal((prevState) => ({
      defaultArchivedState,
    }));
  };

  return (
    <ApplicationsBody>
      <VacanciesHeaderWrapper>
        <VacanciesSearchBlock>
          <RecruitmentSearchComponent
            handleSearch={handleSetSearchedValue}
            handleConfirmSearch={handleGetDirections}
          />
          <StyledVacanciesButton
            className="add-dir__btn"
            onClick={() => handleGetDirections({ page: 1 })}
          >
            {v_search}
          </StyledVacanciesButton>
        </VacanciesSearchBlock>
        <VacanciesHeadeRightContent>
          <VacanciesSwitcher
            period={!archived}
            handleSwitcher={handleSwitcher}
            trans={v_hr_people.switcherForVacancies}
          />
          <StyledAddVacancies>
            <StyledVacanciesButton
              onClick={() =>
                createApplicationRef.current.handleOpenCreateModal()
              }
            >
              <IconButtonPlus />
              {v_hr_people.applications.new_applications}
            </StyledVacanciesButton>
          </StyledAddVacancies>
        </VacanciesHeadeRightContent>
      </VacanciesHeaderWrapper>
      <MenuWrapper>
        <Table>
          <RecruitmentTableHeader tableHeaderDataList={tableHeaderDataList} />
          {directionData && Boolean(directionData.length) && (
            <List
              items={directionData}
              dir={dir}
              handleClickIcon={handleClickIcon}
            />
          )}
        </Table>
      </MenuWrapper>
      <ApplicationsListWrapper
        className={classNames("applications-list-wrapper", {
          "applications-list-wrapper__no-applications":
            directionData && directionData.length === 0,
        })}
      >
        {directionData && directionData.length === 0 && (
          <div className="no-applications">
            <NoInfo
              text={v_hr_people.applications.no_applications_text}
              btnText={v_hr_people.applications.new_applications}
              buttonHandler={() =>
                createApplicationRef.current.handleOpenCreateModal()
              }
            />
          </div>
        )}
      </ApplicationsListWrapper>
      <CreateNewApplication
        handleCreate={postApplicationHandler}
        handleUpdate={updateApplicationHandler}
        handleGetDirections={handleGetDirections}
        removeApplicationHandler={removeApplicationHandler}
        ref={(ref) => {
          createApplicationRef.current = ref;
        }}
        vocabulary={vocabulary}
      />
      {directionData.length >= 1 ? (
        <Pagination
          currentPage={directionDataFromRedux.currentPage}
          generalCount={directionDataFromRedux.totalPages}
          setCurrentPage={setPageDirection}
        />
      ) : null}
      <Modal
        open={isArchiveModal.isOpen}
        title="Are you sure you want to archive the application?"
        onClose={() => setArchiveModal(false)}
        classes={{
          header: "recruitment-modal__header_archive",
          title: "recruitment-modal__title_archive",
          wrapper: "recruitment-modal_archive",
          container: "recruitment-modal__container_archive",
        }}
      >
        <ButtonContainer>
          <CancelButton type="button" onClick={() => setArchiveModal(false)}>
            {v_cancel}
          </CancelButton>
          <DeleteButton type="submit" onClick={handleArchive}>
            {v_filter_archived}
          </DeleteButton>
        </ButtonContainer>
      </Modal>
    </ApplicationsBody>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  directionDataFromRedux: state.boardReducer.applications,
});

const mapDispatchToProps = {
  getApplicationHandler: getSearchApplication,
  postApplicationHandler: postApplication,
  updateApplicationHandler: updateApplication,
  archiveApplicationHandler: archiveApplication,
  removeApplicationHandler: removeApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
