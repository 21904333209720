import React, { ReactElement, useEffect, useState } from "react";
import "./style.scss";
import FileType from "file-type/browser";

export interface IAvatarLoader {
  imageUrl: string;
  onImageChange: (file: any) => void;
  onValidationError: () => void;
}

const AvatarLoader = ({
  imageUrl,
  onImageChange,
  onValidationError,
}: IAvatarLoader): ReactElement => {
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    setSelectedImage(imageUrl);
  }, [imageUrl]);

  const onChange = async (event: any) => {
    const img = event.target.files[0];
    const type = await FileType.fromBlob(img);

    if (
      type &&
      (type.mime === "image/jpeg" || type.mime === "image/png") &&
      img.size < 1000000
    ) {
      setSelectedImage(window.URL.createObjectURL(img));
      onImageChange(img);
    } else {
      onValidationError();
    }
  };

  return (
    <div className="avatar-loader">
      <div className="avatar-loader__image-wrap">
        {selectedImage ? (
          <img src={selectedImage} className="avatar-loader__image" />
        ) : (
          <svg
            className="avatar-loader__image"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="34" cy="34" r="34" fill="#C4C4C4" />
            <path
              d="M33.9998 34C38.1742 34 41.5553 30.6189 41.5553 26.4445C41.5553 22.27 38.1742 18.8889 33.9998 18.8889C29.8253 18.8889 26.4442 22.27 26.4442 26.4445C26.4442 30.6189 29.8253 34 33.9998 34ZM33.9998 37.7778C28.9564 37.7778 18.8887 40.3089 18.8887 45.3334V49.1111H49.1109V45.3334C49.1109 40.3089 39.0431 37.7778 33.9998 37.7778Z"
              fill="#333333"
            />
          </svg>
        )}
      </div>
      <label htmlFor="load-button" className="avatar-loader__button">
        <span className="avatar-loader__button-title">
          {imageUrl ? "+ Add new photo" : "+ Add photo"}
        </span>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          id="load-button"
          className="avatar-loader__button-load"
          onChange={onChange}
        />
      </label>
    </div>
  );
};

export default AvatarLoader;
