import * as Yup from "yup";
export const validationSchema = Yup.object({
  title: Yup.string()
    .min(6, 'Min length 6')
    .required('This field is required'),
  priority: Yup.string()
    .required('This field is required'),
  deadline: Yup.string()
    .required('This field is required'),
});

export const optionsCurrency = [
  { value: null, label: 'None' },
  { value: "usd", label: "USD - $" },
  { value: "eur", label: "EUR - €" },
  { value: "gbp", label: "GBP - £" },
  { value: "pln", label: "PLN - ZŁ" },
  { value: "uah", label: "UAH - ₴" },
];

export const priorityOptions = [
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
]

export const levelOptions = [
  { value: null, label: 'Please select' },
  { value: "Trainee", label: "Internship / Trainee" },
  { value: "junior", label: "Junior" },
  { value: "middle", label: "Middle" },
  { value: "senior", label: "Senior" },
]

export const expirienceOptions = [
  { value: null, label: 'Please select' },
  { value: "noExperience", label: "No experience" },
  { value: "<1year", label: "<1 year" },
  { value: "1-3years", label: "1-3 years" },
  { value: "3-5years", label: "3-5 years" },
  { value: "5-10years", label: "5-10 years" },
  { value: "10+years", label: "10+years" },
]

export const employmentOptions = [
  { value: null, label: 'Please select' },
  { value: "office", label: "Office" },
  { value: "remote", label: "Remote" },
  { value: "office&remote", label: "Office & remote (hybrid)" },
]

export const directionsOptions = [
  { value: null, label: 'Please select' },
  { value: "development", label: "Development" },
  { value: "design", label: "Design" },
  { value: "management", label: "Management" },
  { value: "another", label: "Another" },
]
