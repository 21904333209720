import styled from "styled-components/macro";
import PlusImage from "../../../../assets/images/icons/Plan-plus.svg";


export const ApplicationsBody = styled.div `
  flex: 1 1;
`;

export const AppHeaderWrapper = styled.div `
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const DirectionsSearch = styled.div `
  display: flex;
  align-items: center;
  & > :first-child {
    & > :first-child  {
      height: 44px;

    }
  }
`;

export const NewAppContainer = styled.div `
  display: flex;
  gap: 20px;
`;

export const AddDirBtn = styled.button `
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  color: #fff;
  background-color: #00b173;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-top: 20px;
  padding: 12.5px 24px;
  transition: font 0.3s ease-in-out;
  white-space: nowrap;
  height: 44px;

  :active {
    box-shadow: inset 0 0 13px 0 rgba(0, 0, 0, 0.75);
    background: var(--bg-card-plan);
    color: var(--text);
  }
  
  &.archive_btn {
    background-color: #52565f;
    color: #fff;
  }
`;


export const MenuWrapper = styled.div `
  margin-top: 50px;
`;

export const ArchiveWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-right: 10px;
  .preriod-switcher__runner{
    border-radius: 8px;
  }
  .preriod-switcher__conteiner{
    border-radius: 8px;
  }
  //padding: 12.5px 24px;
`;

export const ApplicationsListWrapper = styled.div `
  &__no-applications {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 30%;
    left: calc(50% - 140px);
  }
`;

export const IconButtonPlus = styled.i `
  display: block;
  height: 20px;
  width: 20px;
  margin-right: 6px;
  background: url(${PlusImage}) no-repeat center;
`;


export const Table = styled.table `
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;
