/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import InputMask from "react-input-mask";

import classNames from "classnames";
import PageHeader from "../../PageHeader";
import { IDictionary } from "../../../../types/dictionary";
import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { CalendarIcon } from "../../../Atoms/SvgIcons";
import { DateRangePicker } from "react-date-range";
import { Link, useHistory } from "react-router-dom";
import { createMuiTheme, withStyles } from "@material-ui/core";
import { REPORTS_TYPES } from "../../../../constants/reports";
import moment from "moment";

import { checkStaticRangeByPlan } from "../../../../services/reportsService";

import { addDays } from "date-fns";

import cn from "classnames";

import CustomTippy from "../../../Unstructured/Tooltip/CustomTippy";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  inputRanges,
  staticRanges,
} from "../../../../pages/ReportsPage/ranges";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
} from "../../../../services/authentication";
import { ICurrentTeam } from "../../../../types/teams";
import { ISelectionRange } from "../../../../types/reports";
import "./style.scss";

const materialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
      },
    },
  },
});

const CustomTabs = withStyles({
  indicator: {
    height: "3px",
    backgroundColor: " #00B173",
  },
  "@media (max-width: 768px)": {
    flexContainer: {
      flexDirection: "column",
    },
    indicator: {
      backgroundColor: "transparent",
    },
  },
})(Tabs);

// @ts-ignore
const CustomTab = withStyles(() => ({
  wrapper: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
  },
  root: {
    minWidth: "auto",
    width: "auto",
    padding: "10px 0 0 0",
    margin: "0 10px",
    textTransform: "none",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "16px",
    color: "var(--text)",
    opacity: 1,
    "&:hover": {
      color: "#00B173",
    },
    "&$selected": {
      color: "#00B173",
    },
    "&:focus": {
      color: "#00B173",
    },
  },
  "@media (max-width: 768px)": {
    root: {
      maxWidth: "100%",
      padding: "0",
    },
  },
  selected: {},
}))((props: any) => <Tab disableRipple {...props} />);

interface IProps {
  vocabulary: IDictionary<any>;
  isCrossTeam: boolean;
  isMobile: boolean;
  isAdmin: boolean;
  currentTab: string;
  dateFormat: string;
  currentTeam: ICurrentTeam;
  customLocale: any;
  firstDayOfWeek: string;
  maxReportDays: number;
  startDateValue: string;
  endDateValue: string;
  focusedRange: number[];
  selectionRange: ISelectionRange;
  onChangeCrossTeam: (isCrossTeam: boolean) => void;
  onSelect: (ranges: any, inputSearch: boolean) => void;
  setFocusedRange: (value: number[]) => void;
  setStartDateValue: (value: string) => void;
  setEndDateValue: (value: string) => void;
}

const ReportsHeader = ({
  vocabulary,
  isCrossTeam = false,
  isMobile = false,
  isAdmin = false,
  currentTab = REPORTS_TYPES.SUMMARY,
  dateFormat,
  onChangeCrossTeam,
  currentTeam,
  customLocale,
  firstDayOfWeek,
  maxReportDays,
  onSelect,
  startDateValue,
  endDateValue,
  setStartDateValue,
  setEndDateValue,
  setFocusedRange,
  selectionRange,
}: IProps): ReactElement => {
  const {
    v_report_header,
    v_summary,
    v_real_time,
    v_days_up_to_today,
    v_days_starting_today,
    v_today,
    v_yesterday,
    v_thisWeek,
    v_lastWeek,
    v_thisMonth,
    v_lastMonth,
    v_this_year,
    v_last_year,
    v_upgrade_label,
    v_tooltip_upgrade,
  } = vocabulary;

  const TABS = [
    {
      title: v_summary,
      id: REPORTS_TYPES.SUMMARY,
      link: "/reports/summary",
      admin: true,
    },
    {
      title: v_real_time,
      id: REPORTS_TYPES.REAL_TIME,
      link: "/reports/real-time",
      admin: isAdmin,
    },
  ];

  const [isDateSelect, setIsDateSelect] = useState(false);
  const datePickerSelect: RefObject<HTMLDivElement> = useRef(null);

  const history = useHistory();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        datePickerSelect.current &&
        !datePickerSelect.current.contains(event.target)
      ) {
        setIsDateSelect(false);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleCrossTeam = (event: any) => {
    const { checked } = event.target;

    onChangeCrossTeam(checked);
  };

  const openCalendar = () => {
    setIsDateSelect(true);
  };

  const toggleCalendar = () => {
    setIsDateSelect((state) => !state);
  };

  const handleSelect = (ranges: any, inputSearch = false) => {
    onSelect(ranges, inputSearch);
  };

  const handleInputSearch = () => {
    const startDateFormatted = startDateValue.replace(/\D+/g, "");
    const endDateFormatted = endDateValue.replace(/\D+/g, "");

    if (
      startDateFormatted &&
      endDateFormatted &&
      endDateFormatted.length === 8 &&
      startDateFormatted.length === 8 &&
      // @ts-ignore
      moment(startDateValue, dateFormat)._isValid &&
      // @ts-ignore
      moment(endDateValue, dateFormat)._isValid
    ) {
      // @ts-ignore
      const newStartDate = new Date(moment(startDateValue, dateFormat));

      // @ts-ignore
      const newEndDate = new Date(moment(endDateValue, dateFormat));

      if (newStartDate <= newEndDate) {
        handleSelect(
          {
            selection: {
              ...selectionRange,
              startDate: newStartDate,
              endDate: newEndDate,
            },
          },
          true,
        );
      }
    }
  };

  const getMinDateByPlane = (userPlan: any) => {
    if (userPlan && userPlan.name === "Free") {
      return addDays(new Date(), -userPlan.maxReportDays);
    }
    return false;
  };

  const getStaticRanges = () =>
    staticRanges({
      today: {
        label: v_today,
        disabled: checkStaticRangeByPlan(
          "today",
          currentTeam.data.userPlan.name,
        ),
      },
      yesterday: {
        label: v_yesterday,
        disabled: checkStaticRangeByPlan(
          "yesterday",
          currentTeam.data.userPlan.name,
        ),
      },
      thisWeek: {
        label: v_thisWeek,
        disabled: checkStaticRangeByPlan(
          "thisWeek",
          currentTeam.data.userPlan.name,
        ),
      },
      lastWeek: {
        label: v_lastWeek,
        disabled: checkStaticRangeByPlan(
          "lastWeek",
          currentTeam.data.userPlan.name,
        ),
      },
      thisMonth: {
        label: v_thisMonth,
        disabled: checkStaticRangeByPlan(
          "thisMonth",
          currentTeam.data.userPlan.name,
        ),
      },
      lastMonth: {
        label: v_lastMonth,
        disabled: checkStaticRangeByPlan(
          "lastMonth",
          currentTeam.data.userPlan.name,
        ),
      },
      thisYear: {
        label: v_this_year,
        disabled: checkStaticRangeByPlan(
          "thisYear",
          currentTeam.data.userPlan.name,
        ),
      },
      lastYear: {
        label: v_last_year,
        disabled: checkStaticRangeByPlan(
          "lastYear",
          currentTeam.data.userPlan.name,
        ),
      },
      // @ts-ignore
      firstDayOfWeek,
    });

  const disabled =
    currentTeam.data.userPlan?.name === "Free" ||
    currentTeam.data.userPlan?.name === "Starter";

  return (
    <PageHeader title={v_report_header}>
      <div className="header_block">
        <div
          className={classNames("header_plug", {
            "header_plug-mobile": isMobile,
          })}
        >
          <div
            className={classNames("wrapper_reports_header_block", {
              "wrapper_reports_header_block-admin": isAdmin,
            })}
          >
            <div className="subscription-page__tabs">
              <CustomTabs value={TABS.findIndex((i) => i.id === currentTab)}>
                {TABS.map((tab) => (
                  <CustomTab
                    key={tab.id}
                    label={tab.title}
                    disabled={!tab.admin}
                    onClick={() => history.replace(tab.link)}
                    icon={
                      disabled &&
                      tab.id === REPORTS_TYPES.REAL_TIME && (
                        <CustomTippy
                          content={
                            <div>
                              {v_tooltip_upgrade}
                              <p>{v_upgrade_label}</p>
                            </div>
                          }
                          className="RP-tippy"
                          placement="bottom"
                        >
                          <i className="lock-icon" />
                        </CustomTippy>
                      )
                    }
                  />
                ))}
              </CustomTabs>
            </div>
            <div
              className={cn("real-time-elements", {
                "real-time-elements-mobile": isMobile,
              })}
            >
              {isAdmin && (
                <div
                  className={cn("real-time-checkbox", {
                    "real-time-checkbox-disabled": disabled,
                  })}
                >
                  <ThemeProvider theme={materialTheme}>
                    <Checkbox
                      checked={isCrossTeam}
                      color="primary"
                      style={{
                        backgroundColor: "transparent",
                      }}
                      onChange={(event) => {
                        toggleCrossTeam(event);
                      }}
                      disabled={disabled}
                    />
                  </ThemeProvider>{" "}
                  {!isMobile ? (
                    <span>Cross-team Reports</span>
                  ) : (
                    <span>Cross-team</span>
                  )}
                </div>
              )}
            </div>
          </div>
          {isMobile && disabled && currentTab === REPORTS_TYPES.REAL_TIME && (
            <Link
              className="header-wrapper__child-button-denide_tablet"
              to="/subscription/plans"
            >
              {v_upgrade_label}
            </Link>
          )}
        </div>
        {!isMobile && disabled && currentTab === REPORTS_TYPES.REAL_TIME && (
          <Link
            className="header-wrapper__child-button-denide"
            to="/subscription/plans"
          >
            {v_upgrade_label}
          </Link>
        )}
        {currentTab === REPORTS_TYPES.SUMMARY && (
          <div className="selects_container" ref={datePickerSelect}>
            <div className="select_header">
              {!isMobile ? (
                <>
                  <span onClick={(e) => openCalendar()}>
                    <InputMask
                      className="select_input"
                      onChange={(e) => {
                        setStartDateValue(e.target.value);
                        setFocusedRange([0, 0]);
                      }}
                      // @ts-ignore
                      formatChars={{
                        d: "[0-9]",
                        m: "[0-9]",
                        y: "[0-9]",
                      }}
                      mask={dateFormat.toLowerCase()}
                      value={startDateValue}
                      alwaysShowMask={false}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          handleInputSearch();
                        } else {
                          return;
                        }
                      }}
                    />
                    {"-"}
                    <InputMask
                      className="select_input"
                      onChange={(e) => {
                        setEndDateValue(e.target.value);
                        setFocusedRange([0, 0]);
                      }}
                      // @ts-ignore
                      formatChars={{
                        d: "[0-9]",
                        m: "[0-9]",
                        y: "[0-9]",
                      }}
                      mask={dateFormat.toLowerCase()}
                      value={endDateValue}
                      alwaysShowMask={false}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          handleInputSearch();
                        } else {
                          return;
                        }
                      }}
                    />
                  </span>
                  <i
                    className={cn(
                      `arrow_down ${isDateSelect ? "arrow_down_up" : ""}`,
                      {
                        arrow_down__light_themes:
                          localStorage.getItem("themes") === "light",
                      },
                    )}
                    onClick={(e) => toggleCalendar()}
                  />
                </>
              ) : (
                <div
                  className="calendar-icon"
                  onClick={(e) => toggleCalendar()}
                >
                  <CalendarIcon
                    className={""}
                    color="var(--color-calendar-icon)"
                  />
                </div>
              )}
            </div>
            {isDateSelect && currentTeam.data.userPlan && (
              <div className="select_body">
                <DateRangePicker
                  locale={customLocale}
                  dateDisplayFormat={dateFormat}
                  ranges={[
                    {
                      startDate: selectionRange.startDate as unknown as Date,
                      endDate: selectionRange.endDate as unknown as Date,
                      key: "selection",
                    },
                  ]}
                  // @ts-ignore
                  minDate={getMinDateByPlane(currentTeam.data.userPlan)}
                  staticRanges={getStaticRanges()}
                  inputRanges={inputRanges(
                    v_days_up_to_today,
                    v_days_starting_today,
                    firstDayOfWeek,
                    maxReportDays,
                  )}
                  onChange={handleSelect}
                  // This code was used for detecting first/last date select (click) on calendar, remove it for if this feature will needed
                  // but its still doesnt work correct (select side ranges)
                  // focusedRange={this.state.focusedRange}
                  // onRangeFocusChange={dateRange => this.setState({ focusedRange: dateRange })}
                />
                {currentTeam.data.userPlan.name === "Free" &&
                  (checkIsAdminByRole(currentTeam.data.role) ||
                    checkIsOwnerByRole(currentTeam.data.role)) && (
                    <div className="upgrade-notification">
                      <Link
                        className="upgrade-notification__text"
                        to="/subscription/plans"
                      >
                        Upgrade to view more data history
                      </Link>
                      <Link
                        className="upgrade-notification__button"
                        to="/subscription/plans"
                      >
                        Upgrade
                      </Link>
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
      {isMobile && disabled && currentTab === REPORTS_TYPES.REAL_TIME && (
        <Link
          className="header-wrapper__child-button-denide_mobile"
          to="/subscription/plans"
        >
          {v_upgrade_label}
        </Link>
      )}
    </PageHeader>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsHeader);
