import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { updateFilterValues } from "../../../../redux/actions/ResourcePlanningActions";

import { FilterIconFill, SearchIcon, CrossIcon } from "../../../Atoms/SvgIcons";
import ResourcePlanningFilterItem from "../../ResourcePlanningFilterItem";

import "../style.scss";

class ResourcePlanningFilterProjects extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();
    this.searchInput = React.createRef();
    this.projectsToggle = React.createRef();

    const projectsForView = props.projectsList
      .filter((project) => project.isActive)
      .map((project) => ({
        title: project.name,
        id: project.id,
        projectColor: project.projectColor,
      }));

    this.state = {
      isOpenDropdown: false,
      searchValue: "",
      allProjects: projectsForView,
      projectsForView,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpenDropdown } = this.state;

    if (!prevState.isOpenDropdown && isOpenDropdown) {
      this.searchInput.current.focus();
    }

    if (prevState.isOpenDropdown && !isOpenDropdown) {
      this.setState({
        searchValue: "",
        usersId: this.props.filterValues?.usersId || [],
      });
    }

    if (prevState.searchValue !== this.state.searchValue) {
      if (this.state.searchValue) {
        const projects = this.state.allProjects.filter((project) =>
          project.title
            .toLowerCase()
            .trim()
            .includes(this.state.searchValue.toLowerCase().trim()),
        );

        if (projects.length) {
          this.projectsToggle.current.openAccordion();
        }

        this.setState({
          projectsForView: projects,
        });
      } else {
        this.setState((state) => ({
          projectsForView: state.allProjects,
        }));
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closeDropdown);
    this.clearFilters();
  }

  openDropdown = () => {
    this.setState(
      {
        isOpenDropdown: true,
      },
      () => document.addEventListener("click", this.closeDropdown),
    );
  };

  closeDropdown = (e) => {
    if (e.target.classList[0]?.includes("rp-filter-item")) {
      return;
    }

    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.setState(
        {
          isOpenDropdown: false,
        },
        () => document.removeEventListener("click", this.closeDropdown),
      );
    }
  };

  clearSearch = () => {
    this.setState({
      searchValue: "",
    });
  };

  updateSearchValue = (e) => {
    const value = e.target.value;

    this.setState({
      searchValue: value,
    });
  };

  updateFilter = async (id) => {
    const key = "projectsId";

    const selectedValues = this.props.filterValues?.[key] || [];
    const body = {};

    if (selectedValues.includes(id)) {
      const value = selectedValues.filter((i) => i !== id);

      body[key] = value;
      this.props.updateFilterValues(body);
    } else {
      const value = [...selectedValues, id];

      body[key] = value;
      this.props.updateFilterValues(body);
    }
  };

  clearFilters = () => {
    this.props.updateFilterValues({ projectsId: [] });
  };

  render() {
    const { vocabulary, filterValues } = this.props;
    const projectsId = filterValues?.projectsId || [];
    const { isOpenDropdown, searchValue, projectsForView } = this.state;
    const { v_resource_planning_page, v_search, v_projects } = vocabulary;
    const { v_show_all_projects, v_filter_no_results } =
      v_resource_planning_page;

    const showFilterNumber = projectsId.length > 0

    return (
      <div className="rp-filter">
        <button
          className={classNames("rp-filter__btn", {
            "rp-filter__btn--active": isOpenDropdown,
          })}
          type="button"
          onClick={this.openDropdown}
        >
          <FilterIconFill color="white" />
          <span className="rp-filter__btn-name">{v_projects}</span>
          {showFilterNumber && <span
              className={classNames("rp-filter__btn-count", {
                "rp-filter__btn-count--active": !!projectsId.length,
              })}
          >
            {projectsId.length}
          </span>}
        </button>
        {!!projectsId.length && (
          <button
            className="rp-filter__clear-filter-btn"
            onClick={this.clearFilters}
          >
            <CrossIcon className="rp-filter__cross-icon" color="#F04747" />
          </button>
        )}
        {isOpenDropdown && (
          <div
            className="rp-filter__dropdown"
            ref={(div) => (this.dropdown = div)}
          >
            <div className="rp-filter__search">
              <SearchIcon className="rp-filter__search-icon" color="white" />
              <input
                ref={this.searchInput}
                className="rp-filter__search-input"
                type="text"
                value={searchValue}
                placeholder={`${v_search}...`}
                onChange={this.updateSearchValue}
              />
              {!!searchValue.length && (
                <button
                  className="rp-filter__clear-btn"
                  type="button"
                  onClick={this.clearSearch}
                >
                  <CrossIcon className="rp-filter__clear-icon" color="white" />
                </button>
              )}
            </div>
            <ResourcePlanningFilterItem
              ref={this.projectsToggle}
              isShowOnInit={true}
              disabled={false}
              isProject={true}
              title={v_projects}
              data={projectsForView}
              showAllButtonTitle={v_show_all_projects}
              selectedValues={projectsId}
              update={(id) => this.updateFilter(id)}
            />

            {!projectsForView.length && (
              <div className="rp-filter__no-results">{v_filter_no_results}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  projectsList: state.projectReducer.projectsList,
  currentTeamDetailedData: state.teamReducer.currentTeamDetailedData,
  filterValues: state.resourcePlanningReducer.filterValues,
});

const mapDispatchToProps = {
  updateFilterValues,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcePlanningFilterProjects),
);
