import styled, { css } from "styled-components/macro";
import { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
export const ModalCloseButton = styled.div`
  position: absolute;
  border: none;
  color: #333;
  font-size: 16px;
  right: 16px;
  top: 14px;
  cursor: pointer;
  margin-top: 10px;
`;

export const ModalBg = styled.div`
  position: fixed;
  // top: 80px;
  // left: 0;
  // width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  max-width: 767px;
  width: calc(100% - 32px);
`;

export const WrapperModal = styled.div`
  font-family: "Exo2", sans-serif;
  // top: 80px;
  cursor: default;
  height: 100%;
  max-height: 640px;
  display: flex;
  position: absolute;
  padding: 30px;
  padding-right: 20px;
  border-radius: 5px;
  background: var(--bg-modal-box);
  flex-direction: column;
  backdrop-filter: blur(25px);
  z-index: 10;
  max-width: 600px;
  width: calc(100% - 32px);
  margin-right: auto;
  margin-left: auto;
  left: 0px;
  right: 0px;
  @media (min-width: 320px) and (max-width: 480px) {
    top: 50px;
    max-height: 550px;
  }
`;
export const HeaderWrapper =styled.div`
  align-items: center;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const TitleWrapper=styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const TitleText=styled.span`
  color: var(--text);
  
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`
export const TitleSubText=styled.div`
  color: var(--text);
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #52565F;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`

export const ModalOverlayRulesModal = styled.div<{ isClosing: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  z-index: 9;
`;

export const BodyWrapper=styled.div`
  width:100%;
  height: 100%;
`

export const MainInfoWrapper=styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 1px solid #52565F;
  margin-bottom: 30px;
`
export const MainRowInfoWrapper=styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

export const ColumnInfoWrapper=styled.div`
  width: 30%;
  
`
export const ColumnInfoBlock=styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const DataLinkBlock=styled.div`
  color: #FFF;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const LinkBlock=styled.div`
  display: flex;
  gap: 5px;
  padding: 2px 10px;
  align-items: center;
  border-radius: 100px;
  background: #52565F;
  max-width: 100%
`

export const LinkText=styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #FFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CopyLinkImage=styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`



export const ColumnInfoTitle=styled.span`
  color: #B9BBBE;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`

export const ColumnInfoSubTitle=styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #FFF;
`

export const ApplicationItem=styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TitleFordescription=styled.div`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

export const StepVacancyBlock=styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const CountOfStepWrapper=styled.div`
  display: flex;
  font-size: 14px;
  color: #FFF;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`
export const CountOfStepNumber=styled.div`
  display:  flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background: #52565F;
  border-radius: 4px;
`
export const CountOfStepText=styled.span`
  font-style: normal;
  font-weight: 700;
`
export const BlockForButton=styled.div`
  display: flex;
  justify-content: end;
  padding-right: 10px;
`
export const ExitButton=styled.div`
  height: 44px;
  padding: 14px 24px;
  align-items: center;
  border-radius: 8px;
  background:  #00B173;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #FFF;
  cursor: pointer;
`

export const EditButton=styled.span`
  color: #00B173;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
`

export const CloseIconModal=styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
`