import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

import { setTokenToLocalStorage } from "../../../services/tokenStorageService";
import { getUtcOffsetInMilliseconds } from "../../../services/timeService";
import { useLocation } from "react-router-dom";
import "./style.scss";
import { loginWithGoggle } from "../../../api/user";
import { GOOGLE_AUTH_USER_INFO_URL, GOOGLE_AUTH_HASH } from "../../../constants/auth";

const GoogleButton = ({ setHaveToken, showNotificationAction }) => {
  const vocabulary = useSelector((state) => state.languageReducer.vocabulary);
  const dispatch = useDispatch();
  const { hash } = useLocation()
  const googleLogin = useGoogleLogin({ 
    onSuccess: (response) => handleGoogleResponse(response),
    onError: (error) => console.log(error)
  });

  useEffect(() => {
    if (hash && hash.substring(1, hash.length) === GOOGLE_AUTH_HASH) {
      triggerGoogle()
    }
  }, [])

  const handleGoogleResponse = async (response) => {
    const headers = new Headers();

    headers.append("Authorization", `${response.token_type} ${response.access_token}`);

    const requestOptions = {
      method: 'GET',
      headers,
      redirect: 'follow'
    };

    await fetch(GOOGLE_AUTH_USER_INFO_URL, requestOptions)
      .then((response) => response.json())
      .then(({ name, email }) => login({ name, email }))
      .catch((error) => console.log(error));
  }

  const triggerGoogle = (e) => {
    if ('google' in window) {
      googleLogin()
    } else {
      setTimeout(() => {
        triggerGoogle()
      }, 300)
    }
  }
  
  const handleGoogleButton = async (response) => {
    if (response.status !== "unknown") {
      const { name, credential } = response;
      const resultDecoding = jwtDecode(credential);

      await login({ name, email: resultDecoding.email })
    }
  }

  const login = async ({ name, email }) => {
    const { lang } = vocabulary;

    try {
      const response = await loginWithGoggle({
        email,
        name,
        language: lang.short,
        timezoneOffset: getUtcOffsetInMilliseconds(),
      });
      const { token } = response.data;

      setTokenToLocalStorage(token);
      document.cookie = "isAuthWobbly=true; path=/; domain=.wobbly.me;";
      setHaveToken();
    } catch (error) {
      const textError = error.response.data.message;

      dispatch(showNotificationAction({ text: vocabulary[textError], type: "error" }));
    }
  }

  return (
    <GoogleLogin
      type="icon"
      size="large"
      onSuccess={handleGoogleButton}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  )
}

export default GoogleButton;
