import React, { useState, useRef } from "react";
import classNames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { deleteInvoiceById } from "../../../redux/actions/InvoicesActions";
import {
  spaceAndFixNumber,
  fixNumberHundredths,
  internationalFormatNum,
} from "../../../services/numberHelpers";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

// Styles
import "./style.scss";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../../services/hookHelpers";
import StatusIcon from "../StatusIcon";
import InvoiceInstruments from "../InvoiceInstruments";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";
import { tippyStatus } from "../../Unstructured/StatusIcon/TippyStatus";

const prevent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const InvoiceList = ({
  vocabulary,
  invoice,
  dateFormat,
  isMobile,
  history,
  openCloseModal,
  copyInvoice,
  toggleSendInvoiceModal,
  showNotificationAction,
  confirmationModalHandler,
  editConfirmationModalHandler,
  statusModalHandler,
  setCurrentInvoice,
  partialPaymentModalHandler,
}) => {
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showNotif, setShowNotif] = useState(false);

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setShowActionsMenu(false));

  // const toggleOpenMenu = () => {
  //     setOpenMenu(!openMenu);
  // };

  const handleShowActionsMenu = (show) => {
    setShowActionsMenu(show);
  };

  const styleSpan = {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "50px",
  };
  const switchMenu = window.innerWidth < 1200;

  const { v_edit, v_clone, v_show_more, v_link_copied, v_outstanding } =
    vocabulary;

  const copyToClipBoard = (invoice) => {
    const el = document.createElement("textarea");

    el.value = `${window.location.origin}/invoice/${invoice && invoice.id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setShowNotif(true);
    setTimeout(() => {
      setShowNotif(false);
    }, 2000);
  };

  const checkInvoceStatus = (invoice) => {
    if (invoice.status === "awaiting" || invoice.status === "reviewed") {
      editConfirmationModalHandler(invoice);
    } else {
      history.push(`/invoices/update/${invoice.id}`);
    }
  };

  const amountTooltipContent = ({ currency, outstanding, paid }) => {
    const { v_paid, v_outstanding, v_paid_full, v_paid_zero } = vocabulary;

    if (outstanding && paid) {
      return (
        <div className="amount-tooltip">
          <div className="amount-tooltip__titles">
            <div className="amount-tooltip__title">{v_paid}</div>
            <div className="amount-tooltip__title">{v_outstanding}</div>
          </div>
          <div className="amount-tooltip__amounts">
            <div className="amount-tooltip__amount">
              {currency}{" "}
              {internationalFormatNum(
                fixNumberHundredths(spaceAndFixNumber(paid)),
              )}
            </div>
            <div className="amount-tooltip__amount">
              {currency}{" "}
              {internationalFormatNum(
                fixNumberHundredths(spaceAndFixNumber(outstanding)),
              )}
            </div>
          </div>
        </div>
      );
    } else if (outstanding === 0) {
      return (
        <div className="amount-tooltip">
          <div className="amount-tooltip__paid">
            <span className="amount-tooltip__title">{v_paid_full}</span>
          </div>
        </div>
      );
    } else if (paid === 0) {
      return (
        <div className="amount-tooltip">
          <div className="amount-tooltip__paid">
            <span className="amount-tooltip__title">{v_paid_zero}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Link
      to={`/invoices/view/${invoice.id}`}
      key={invoice.id}
      className={classNames("all-invoices-list-item", {
        "all-invoices-list-item--confirmed": invoice.status === "paid",
        "all-invoices-list-item--overdue": invoice.status === "overdue",
        "all-invoices-list-item--draft": invoice.status === "draft",
        "all-invoices-list-item--awaiting": invoice.status === "awaiting",
        "all-invoices-list-item--reviewed": invoice.status === "reviewed",
      })}
    >
      <div className="all-invoices-list-item__block">
        <div className="all-invoices-list-item__wrapper">
          <div className="all-invoices-list-item__number">{`#${invoice.invoice_number}`}</div>
          <div className="all-invoices-list-item__name">
            <div>{invoice.to.company_name || invoice.to.name}</div>
            <span>{invoice.reference}</span>
          </div>
        </div>
      </div>
      <div className="all-invoices-list-item__block">
        <CustomTippy
          content={amountTooltipContent({
            currency: invoice.currency,
            outstanding: invoice.outstanding,
            paid: invoice.paid,
          })}
          disabled={isMobile}
        >
          <div className="all-invoices-list-item__price">
            <div className="all-invoices-list-item__sum">
              <div className="all-invoices-list-item__price-currency">
                {invoice.currency}
              </div>{" "}
              <div className="all-invoices-list-item__price-value">
                {internationalFormatNum(
                  fixNumberHundredths(spaceAndFixNumber(invoice.total)),
                )}
              </div>
            </div>
            <div className="all-invoices-list-item__price-outstanding">
              <span className="all-invoices-list-item__value-outstanding">
                {v_outstanding}
                {" : "}
              </span>
              <span className="all-invoices-list-item__value-outstanding">
                {internationalFormatNum(
                  fixNumberHundredths(spaceAndFixNumber(invoice.outstanding)),
                )}
              </span>
            </div>
          </div>
        </CustomTippy>
        <div className="all-invoices-list-item__date-wrapper">
          <div className="all-invoices-list-item__date">
            {moment(invoice.invoice_date).format(dateFormat)}
          </div>
          <div className="all-invoices-list-item__date">
            {moment(invoice.due_date).format(dateFormat)}
          </div>
        </div>
      </div>
      <div
        className="all-invoices-list-item__status-button"
        onClick={(e) => prevent(e)}
      >
        <CustomTippy
          content={tippyStatus({ vocabulary, status: invoice.status })}
          disabled={isMobile}
        >
          <div className="all-invoices-list-item__status-button-container">
            <StatusIcon
              className={invoice.status}
              vocabulary={vocabulary}
              onClick={(e) => {
                statusModalHandler();
                setCurrentInvoice(invoice);
              }}
            />
          </div>
        </CustomTippy>
      </div>

      <div className="all-invoices-list-item__instruments" onClick={prevent}>
        <InvoiceInstruments
          vocabulary={vocabulary}
          invoice={invoice}
          history={history}
          isMobile={isMobile}
          editConfirmationModalHandler={editConfirmationModalHandler}
          copyInvoice={copyInvoice}
          setCurrentInvoice={setCurrentInvoice}
          confirmationModalHandler={confirmationModalHandler}
          showNotificationAction={showNotificationAction}
          toggleSendInvoiceModal={toggleSendInvoiceModal}
          openCloseModal={openCloseModal}
          partialPaymentModalHandler={partialPaymentModalHandler}
        />
      </div>
    </Link>
  );
};

const mapStateToProps = ({
  invoicesReducer,
  userReducer,
  languageReducer,
}) => ({
  isFetching: invoicesReducer.isFetching,
  dateFormat: userReducer.dateFormat,
  vocabulary: languageReducer.vocabulary,
});

const mapDispatchToProps = {
  deleteInvoiceById,
  showNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
