import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

import Modal from "../../../Atoms/Modal";
import { Formik, useFormikContext } from "formik";
import {
  ButtonContainer,
  CancelButton,
  CountryPhoneContainer,
  DeleteButton,
  DropdownContainer,
  StyledForm,
  Input,
  LabelContainer,
  LabelPhoneInput,
  SalaryContainer,
  SalaryWrapper,
  SaveButton,
  TextLabel,
  DateContainer,
  LanguageWrapper,
  StyledSVG,
  LinesContainer,
  ErrorText,
  CountOfStepWrapper,
  CountOfStepNumber,
  CountOfStepText,
  DropdownContainerRow,
  RequiredfieldStar,
  DropdownContainerRowWrapper,
  SingleDropdownWrapper,
  TextareaBlock,
  SymbolsCountForDescription,
  SectionLine,
  JobTypeButton,
  JobTypeBlock,
  CurrencyBlock,
  TitleSubText,
  ButtonContainerForFirstStep,
} from "./My.styled";
import { uuid } from "uuidv4";

import DropdownWithSearch from "../../../Atoms/DropdownWithSearch";

import "./style.scss";
import { getDirection } from "../../../../redux/actions/PepoplePageActions";
import DateRangeSelect from "../../DateRangeSelect";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TechnologyComponent from "../../TechnologyComponent";
import LineIcon from "../../../../assets/images/ComponentIcons/LineIcon";
import {
  validationSchema,
  optionsCurrency,
  priorityOptions,
  levelOptions,
  expirienceOptions,
  employmentOptions,
  directionsOptions,
  statusOptions,
  jobType,
} from "./utils";
import AutoExpandingTextarea from "../AutoExpandingTextarea";
import LinkToVacanciesComponent from "../LinkToVacancies";
import CurrencySelect from "../../CurrencySelect";
import DatepickerSelect from "../../DatepickerSelect";

const BackArrowSvg = ({}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57 5.92999L3.5 12L9.57 18.07"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5019 12H3.67188"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const defaultDirectionState = {
  isOpen: false,
  typeDirection: "edit", // create or edit
  directionId: null,
};

const CreateVacanciesModal = forwardRef(
  (
    {
      vocabulary,
      directionData,
      pageDirection,
      handleGetDirections,
      handleCreate,
      handleUpdate,
      getDirectionHandlerCall,
      pageCount,
      applicationData,
      applicationsDataFromRedux,
      handleGetApplicationForDropdown,
      handleGetVacancies,
      isEdit,
      setDataForEditModal,
      dataForEditModal,
    },
    ref,
  ) => {
    const [directionModal, setDirectionModal] = useState({
      ...defaultDirectionState,
    });
    const destructivizationArray = (arr) => {
      return arr.map((item) => ({
        value: item.id,
        label: item.title || item.link,
      }));
    };

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const initialValues = {
      status:
        (dataForEditModal?.status &&
          capitalizeFirstLetter(dataForEditModal?.status)) ||
        "",
      company: dataForEditModal?.company || "",
      name_vacancy: dataForEditModal?.title || "",
      applicationArrayId: [],
      applicationArrayValues: [],
      applicationArray:
        (dataForEditModal?.order &&
          destructivizationArray(dataForEditModal?.order)) ||
        [],
      level:
        (dataForEditModal?.level &&
          capitalizeFirstLetter(dataForEditModal?.level)) ||
        "",
      experience: dataForEditModal?.experience || "",
      pm: dataForEditModal?.pm || "",
      minSalary: dataForEditModal?.minSalary || "",
      maxSalary: dataForEditModal?.maxSalary || "",
      deadline:
        (dataForEditModal?.deadline &&
          moment(dataForEditModal?.deadline).toDate()) ||
        null,
      priority:
        (dataForEditModal?.priority &&
          capitalizeFirstLetter(dataForEditModal?.priority)) ||
        "",
      country: {
        countryCode: "",
        name: dataForEditModal?.country || "",
      },
      city: dataForEditModal?.city || "",
      employment: dataForEditModal?.employment || "",
      positionCount: dataForEditModal?.positionCount || "",
      languagesDirection: dataForEditModal?.language || "",
      requirement: dataForEditModal?.requirement || "",
      description: dataForEditModal?.description || "",
      jobType: dataForEditModal?.jobType || "remote",
      linkJob: dataForEditModal?.vacancy_link || [],
      linkJobName: [],
      responsibilities: dataForEditModal?.duties || "",
      niceToHave: dataForEditModal?.advantage || "",
      workingConditions: dataForEditModal?.conditions || "",
      directionId: {
        label: dataForEditModal?.board?.title || "",
        value: dataForEditModal?.board?.id || "",
      },
      directionValue: dataForEditModal?.board?.title || "",
      currency: dataForEditModal?.currency || "USD",
    };
    const [typeForm, setTypeForm] = useState("first");
    const [selectedJobType, setSelectedJobType] = useState(
      dataForEditModal?.jobType || "remote",
    );
    const [arra, setArra] = useState(null);
    const [directionArrayOptions, setDirectionArrayOptions] = useState([]);
    const [applicationArrayOptions, setApplicationArrayOptions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("usd");
    const [clearValueInDropdown, setClearValueInDropdown] = useState(false);
    const [dateForCalendar, setDateForCalendar] = useState(null);

    const formikRef = useRef(null);
    const {
      v_hr_people,
      v_save,
      v_cancel,
      v_back,
      v_delete,
      v_from,
      v_to,
      v_subscription,
    } = vocabulary;
    const { billingModal } = v_subscription;

    const directionArray = (inputArray) => {
      return inputArray.map((item) => ({
        value: item.id,
        label: item.title,
      }));
    };

    useEffect(() => {
      if (directionModal.isOpen) {
        setDirectionArrayOptions((prevOptions) => {
          const newOptions = directionArray(directionData);
          const uniqueOptions = prevOptions.concat(
            newOptions.filter(
              (newOption) =>
                !prevOptions.some(
                  (prevOption) => prevOption.value === newOption.value,
                ),
            ),
          );
          return uniqueOptions;
        });
      }
    }, [directionData, directionModal.isOpen]);

    useEffect(() => {
      if (directionModal.isOpen) {
        setApplicationArrayOptions((prevOptions) => {
          const newOptionsForApplication = directionArray(applicationData);
          const uniqueOptionsForApplication = prevOptions.concat(
            newOptionsForApplication.filter(
              (newOption) =>
                !prevOptions.some(
                  (prevOption) => prevOption.value === newOption.value,
                ),
            ),
          );
          return uniqueOptionsForApplication;
        });
      }
    }, [applicationData, directionModal.isOpen]);

    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };

    useEffect(() => {
      const { isOpen } = directionModal;

      // add defaults values to form if edit
      if (!isOpen) {
        handleUnmountForm();
      }
    }, [directionModal]);

    const handleUnmountForm = () => {
      if (formikRef.current) {
        setTypeForm("first");
        formikRef.current.resetForm();
      }
    };

    const handleJobTypeClick = (setFieldValue, selectedJobType) => {
      setFieldValue("jobType", selectedJobType);
      setSelectedJobType(selectedJobType);
    };
    const handleCurrencyChange = (currency) => {
      setSelectedCurrency(currency);
      if (formikRef.current) {
        formikRef.current.setFieldValue("currency", currency);
      }
    };

    const handleOnSubmit = async (values) => {
      const {
        name_vacancy,
        level,
        experience,
        deadline,
        priority,
        minSalary,
        maxSalary,
        country,
        city,
        employment,
        positionCount,
        pm,
        // project,
        languagesDirection,
        requirement,
        description,
        // comment,
        jobType,
        linkJob,
        linkJobName,
        responsibilities,
        niceToHave,
        workingConditions,
        directionId,
        directionValue,
        applicationArrayId,
        currency,
        company,
        status,
        applicationArray,
      } = values;

      const body = {
        orderIds: applicationArrayId,
        title: name_vacancy,
        status: status.replace(/\s/g, "").toUpperCase(),
        company: company,
        priority: priority.toUpperCase(),
        deadline: deadline ? moment(deadline).toISOString() : null,
        boardId: directionId.value,
        experience: experience,
        level: level.toUpperCase(),
        description: description,
        pm: pm,
        positionCount: positionCount,
        employment: employment,
        minSalary: minSalary ? minSalary : 0,
        maxSalary: maxSalary ? maxSalary : 0,
        currency: currency,
        country: country.name,
        city: city,
        jobType: jobType,
        language: languagesDirection,
        links: linkJobName,
        duties: responsibilities,
        requirement: requirement,
        advantage: niceToHave,
        conditions: workingConditions,
      };

      if (!isEdit) {
        await handleCreate(body);
      } else {
        body.vacancyId = dataForEditModal?.id;
        await handleUpdate(body);
      }

      await handleGetVacancies({ page: 1 });
      handleUnmountForm();
      handleOnDelete();
    };

    const handleOnDelete = async () => {
      setClearValueInDropdown(true);
      await handleUnmountForm();
      setDirectionModal((prevState) => ({
        ...defaultDirectionState,
      }));
      setDataForEditModal([]);
    };

    const handleOnOpen = async () => {
      setClearValueInDropdown(false);
      setDirectionModal((prevState) => ({
        ...prevState,
        isOpen: true,
      }));
    };

    useImperativeHandle(ref, () => ({
      handleOpenCreateModal: () => {
        handleOnOpen();
      },
    }));

    const handleAmountChange = (setFieldValue, state) => (event) => {
      const { value } = event.target;
      const cleanedValue = value
        .replace(/[^0-9]/g, "")
        .replace(/(\..*)\./g, "$1");

      setFieldValue(`${state}`, Number(cleanedValue));
    };

    const ErrorMessage = (error) => <ErrorText>{error}</ErrorText>;

    const renderTypeForms = useCallback(
      (formik) => {
        switch (typeForm) {
          case "first":
            // const date = moment(formik.values?.deadline);

            return (
              <div>
                <CountOfStepWrapper>
                  <CountOfStepNumber>1</CountOfStepNumber>
                  <CountOfStepText>
                    {vocabulary.v_hr_people.vacancies.vacancy_data}
                  </CountOfStepText>
                </CountOfStepWrapper>

                <DropdownContainer style={{ marginTop: "11px" }}>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.job_application}
                  </TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={true}
                    placeHolder={
                      vocabulary.v_hr_people.vacancies.select_job_application
                    }
                    options={applicationArrayOptions}
                    onChange={(selectedOptions) => {
                      const selectedId = selectedOptions.map(
                        (option) => option.value,
                      );
                      // const selectedValues = selectedOptions.map(
                      //   (option) => option.label,
                      // );
                      formik.setFieldValue("applicationArrayId", selectedId);
                      // formik.setFieldValue(
                      //   "applicationArrayValues",
                      //   selectedValues,
                      // );
                      formik.setFieldValue(
                        "applicationArray",
                        selectedOptions.map((item) => ({
                          value: item.value,
                          label: item.label,
                        })),
                      );
                    }}
                    value={formik.values.applicationArray}
                    onKeyDown={handleKeyDown}
                    oudsidesStyles
                    applicationDropdown
                    applicationArrayOptions={applicationArrayOptions}
                    applicationsDataFromRedux={applicationsDataFromRedux}
                    handleGetApplicationForDropdown={
                      handleGetApplicationForDropdown
                    }
                    scroll
                    clearValueInDropdown={clearValueInDropdown}
                  />
                </DropdownContainer>
                <DropdownContainerRow>
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.vacancy_name}{" "}
                      <RequiredfieldStar>*</RequiredfieldStar>
                    </TextLabel>
                    <Input
                      type="text"
                      value={formik.values.name_vacancy}
                      onChange={formik.handleChange("name_vacancy")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={vocabulary.v_hr_people.vacancies.type_here}
                      hasError={formik.errors.name_vacancy}
                    />
                    {ErrorMessage(formik.errors.name_vacancy)}
                  </LabelContainer>
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.company}{" "}
                      <RequiredfieldStar>*</RequiredfieldStar>
                    </TextLabel>
                    <Input
                      type="text"
                      value={formik.values.company}
                      onChange={formik.handleChange("company")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={vocabulary.v_hr_people.vacancies.type_here}
                      hasError={formik.errors.company}
                    />
                    {ErrorMessage(formik.errors.company)}
                  </LabelContainer>
                </DropdownContainerRow>
                <DropdownContainerRow>
                  <DropdownContainerRowWrapper>
                    <TextLabel>
                      {vocabulary.v_status}{" "}
                      <RequiredfieldStar>*</RequiredfieldStar>
                    </TextLabel>
                    <DropdownWithSearch
                      isSearchable={false}
                      isMulti={false}
                      placeHolder={
                        vocabulary.v_hr_people.vacancies.choose_status
                      }
                      options={statusOptions}
                      onChange={({ value, label }) =>
                        formik.setFieldValue("status", label)
                      }
                      value={formik.values.status}
                      onKeyDown={handleKeyDown}
                      oudsidesStyles
                      clearValueInDropdown={clearValueInDropdown}
                    />
                    {ErrorMessage(formik.errors.status)}
                  </DropdownContainerRowWrapper>
                  <DropdownContainerRowWrapper>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.priority}{" "}
                      <RequiredfieldStar>*</RequiredfieldStar>
                    </TextLabel>
                    <DropdownWithSearch
                      isSearchable={false}
                      isMulti={false}
                      placeHolder={
                        vocabulary.v_hr_people.vacancies.choose_priority
                      }
                      options={priorityOptions}
                      onChange={({ value, label }) =>
                        formik.setFieldValue("priority", label)
                      }
                      value={formik.values.priority}
                      onKeyDown={handleKeyDown}
                      oudsidesStyles
                      clearValueInDropdown={clearValueInDropdown}
                    />
                    {ErrorMessage(formik.errors.priority)}
                  </DropdownContainerRowWrapper>
                </DropdownContainerRow>
                <SingleDropdownWrapper>
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacanciesHeaderTable.deadline}
                    </TextLabel>
                    <DateContainer className="container_for_calendar">
                      {/* <TextLabel>
                        {date?.format("YYYY-MM-DD") !== "Invalid date" ? date?.format("YYYY-MM-DD") : "Deadline"}
                      </TextLabel>
                      <DateRangeSelect
                        onChangeDate={(date) => {
                          formik.setFieldValue("deadline", date);
                          console.log("ad",date);
                          console.log("date", moment(date).toDate().toISOString());
                        }}
                        isDayCalendar={true}
                      /> */}

                      <DatepickerSelect
                        disableToolbar={false}
                        date={formik.values.deadline}
                        onChangeDate={(date) => {
                          formik.setFieldValue("deadline", date);
                        }}
                        placeholder={
                          vocabulary.v_hr_people.vacanciesHeaderTable.deadline
                        }
                        dropdownTitle={
                          vocabulary.v_hr_people.vacanciesHeaderTable.deadline
                        }
                      />
                    </DateContainer>
                  </LabelContainer>
                </SingleDropdownWrapper>
                <DropdownContainerRow>
                  <DropdownContainerRowWrapper>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.direction}{" "}
                      <RequiredfieldStar>*</RequiredfieldStar>
                    </TextLabel>
                    <DropdownWithSearch
                      isSearchable={false}
                      isMulti={false}
                      placeHolder={vocabulary.v_hr_people.vacancies.direction}
                      options={directionArrayOptions}
                      onChange={({ value, label }) => {
                        // formik.setFieldValue("directionId", value);
                        formik.setFieldValue("directionValue", label);
                        formik.setFieldValue("directionId", {
                          label: label,
                          value: value,
                        });
                      }}
                      value={formik.values.directionId}
                      onKeyDown={handleKeyDown}
                      oudsidesStyles
                      getDirectionHandler={getDirectionHandlerCall}
                      directionData={directionData}
                      pageCount={pageCount}
                      setDirectionArrayOptions={setDirectionArrayOptions}
                      directionDropdown
                      scroll
                      clearValueInDropdown={clearValueInDropdown}
                    />

                    {ErrorMessage(formik.errors.directionValue)}
                  </DropdownContainerRowWrapper>
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.experience}
                    </TextLabel>
                    <Input
                      type="text"
                      value={formik.values.experience}
                      onChange={formik.handleChange("experience")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={vocabulary.v_hr_people.vacancies.type_here}
                      hasError={formik.errors.experience}
                    />
                  </LabelContainer>
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.level}
                    </TextLabel>
                    <DropdownWithSearch
                      isSearchable={false}
                      isMulti={false}
                      placeHolder={vocabulary.v_hr_people.vacancies.level}
                      options={levelOptions}
                      onChange={({ value, label }) =>
                        formik.setFieldValue("level", label)
                      }
                      value={formik.values.level}
                      onKeyDown={handleKeyDown}
                      oudsidesStyles
                      clearValueInDropdown={clearValueInDropdown}
                    />
                    {/* <Input
                      type="text"
                      value={formik.values.Level}
                      onChange={formik.handleChange("Level")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={"Type here..."}
                      hasError={formik.errors.Level}
                    /> */}
                  </LabelContainer>
                </DropdownContainerRow>

                <ButtonContainerForFirstStep>
                  <SaveButton
                    type="button"
                    onClick={async () => {
                      const errors = await formik.validateForm();
                      if (
                        !errors.priority &&
                        !errors.name_vacancy &&
                        !errors.company &&
                        !errors.status &&
                        !errors.directionValue
                      ) {
                        setTypeForm("second");
                      }
                    }}
                  >
                    {vocabulary.v_subscription.billingModalDowngrade.button}
                  </SaveButton>
                </ButtonContainerForFirstStep>
              </div>
            );

          case "second":
            // const date = moment(formik.values?.deadline).format("YYYY-MM-DD");
            const availableSymbols = 1000;
            return (
              <div>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.job_description}
                  </TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      value={formik.values.description}
                      onChange={formik.handleChange("description")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                      maxLength="1000"
                    />
                    <SymbolsCountForDescription>
                      {availableSymbols - formik.values.description.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                </LabelContainer>
                <DropdownContainerRow>
                  <LabelContainer>
                    <TextLabel>{"PM"}</TextLabel>
                    <Input
                      type="text"
                      value={formik.values.pm}
                      onChange={formik.handleChange("pm")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                    />
                  </LabelContainer>{" "}
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.number_of_positions}
                    </TextLabel>
                    <Input
                      type="text"
                      value={formik.values.positionCount}
                      onChange={formik.handleChange("positionCount")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={vocabulary.v_hr_people.vacancies.type_here}
                    />
                  </LabelContainer>{" "}
                  <LabelContainer>
                    <TextLabel>
                      {vocabulary.v_hr_people.vacancies.type_of_employment}
                    </TextLabel>
                    <Input
                      type="text"
                      value={formik.values.employment}
                      onChange={formik.handleChange("employment")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={vocabulary.v_hr_people.vacancies.type_here}
                    />
                  </LabelContainer>
                </DropdownContainerRow>
                <SectionLine />
                <CountOfStepWrapper>
                  <CountOfStepNumber>2</CountOfStepNumber>
                  <CountOfStepText>
                    {vocabulary.v_hr_people.vacancies.expected_salary}
                  </CountOfStepText>
                </CountOfStepWrapper>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.expected_salary}
                  </TextLabel>
                  <SalaryContainer>
                    <SalaryWrapper>
                      <Input
                        type="text"
                        value={formik.values.minSalary}
                        onChange={handleAmountChange(
                          formik.setFieldValue,
                          "minSalary",
                        )}
                        // onChange={formik.handleChange("minSalary")}
                        onBlur={formik.handleBlur}
                        // onKeyDown={handleKeyDown}
                        placeholder={vocabulary.v_from}
                      />
                    </SalaryWrapper>
                    <SalaryWrapper>
                      <Input
                        type="text"
                        value={formik.values.maxSalary}
                        onChange={handleAmountChange(
                          formik.setFieldValue,
                          "maxSalary",
                        )}
                        // onChange={formik.handleChange("maxSalary")}
                        onBlur={formik.handleBlur}
                        // onKeyDown={handleKeyDown}
                        placeholder={vocabulary.v_resource_planning_page.v_to}
                      />
                    </SalaryWrapper>
                    <SalaryWrapper>
                      <CurrencyBlock>
                        <CurrencySelect
                          selectedCurrency={formik.values.currency}
                          onChange={handleCurrencyChange}
                        />
                      </CurrencyBlock>
                    </SalaryWrapper>
                  </SalaryContainer>
                </LabelContainer>
                <SectionLine />
                <CountOfStepWrapper>
                  <CountOfStepNumber>3</CountOfStepNumber>
                  <CountOfStepText>
                    {vocabulary.v_hr_people.vacancies.job_location}
                  </CountOfStepText>
                </CountOfStepWrapper>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.location_optional}
                  </TextLabel>

                  <SalaryContainer>
                    <CountryPhoneContainer>
                      <PhoneInput
                        enableSearch={true}
                        countryCodeEditable={false}
                        autoFormat={false}
                        placeholder=""
                        dropdownClass="custom-dropdown"
                        onChange={(value, data) => {
                          formik.setFieldValue("country", {
                            countryCode: data?.countryCode,
                            name: data?.name,
                          });
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <LabelPhoneInput>
                        <p>
                          {formik.values.country.name
                            ? formik.values.country.name
                            : `${vocabulary.select_country}`}
                        </p>
                      </LabelPhoneInput>
                    </CountryPhoneContainer>

                    <Input
                      type="text"
                      value={formik.values.city}
                      onChange={formik.handleChange("city")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={vocabulary.v_city}
                    />
                  </SalaryContainer>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.job_type}
                  </TextLabel>
                  <JobTypeBlock>
                    {jobType.map((item) => (
                      <JobTypeButton
                        key={item.value}
                        onClick={() =>
                          handleJobTypeClick(formik.setFieldValue, item.value)
                        }
                        style={{
                          background:
                            formik.values.jobType === item.value
                              ? "#52565F"
                              : "none",
                        }}
                      >
                        {item.label}
                      </JobTypeButton>
                    ))}
                  </JobTypeBlock>
                </LabelContainer>
                <SectionLine />
                <CountOfStepWrapper>
                  <CountOfStepNumber>4</CountOfStepNumber>
                  <CountOfStepText>
                    {vocabulary.v_hr_people.vacancies.foreign_languages}
                  </CountOfStepText>
                </CountOfStepWrapper>
                <LabelContainer>
                  <TextLabel>{vocabulary.v_language}</TextLabel>
                  <AutoExpandingTextarea
                    value={formik.values.languagesDirection}
                    onChange={formik.handleChange("languagesDirection")}
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    placeholder={
                      vocabulary.v_hr_people.vacancies.type_text_here
                    }
                    maxLength="200"
                  />
                </LabelContainer>

                <ButtonContainer>
                  <CancelButton
                    type="button"
                    onClick={() => setTypeForm("first")}
                  >
                    <BackArrowSvg /> {v_back}
                  </CancelButton>
                  <SaveButton
                    type="button"
                    onClick={async () => {
                      const errors = await formik.validateForm();

                      if (!errors.priority) {
                        setTypeForm("third");
                      }
                    }}
                  >
                    {vocabulary.v_subscription.billingModalDowngrade.button}
                  </SaveButton>
                </ButtonContainer>
              </div>
            );

          case "third":
            const availableSymbolsForThirdPage = 1000;

            return (
              <div>
                <CountOfStepWrapper>
                  <CountOfStepNumber>5</CountOfStepNumber>
                  <CountOfStepText>
                    {vocabulary.v_hr_people.vacancies.additional_information}
                  </CountOfStepText>
                </CountOfStepWrapper>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.link_to_job}
                  </TextLabel>
                  <LanguageWrapper>
                    <LinkToVacanciesComponent
                      userTechnologies={formik.values.linkJob}
                      setUserTechnologies={(techArr) => {
                        formik.setFieldValue("linkJob", techArr);
                        const selectedIdLinkJob = techArr.map(
                          (option) => option.link,
                        );

                        formik.setFieldValue("linkJobName", selectedIdLinkJob);
                      }}
                      showNotificationAction={false}
                      vocabulary={vocabulary}
                      themeLight={true}
                      title={"Language skills"}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                    />
                  </LanguageWrapper>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.responsibilities}
                  </TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      value={formik.values.responsibilities}
                      onChange={formik.handleChange("responsibilities")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                      maxLength="1000"
                    />
                    <SymbolsCountForDescription>
                      {availableSymbolsForThirdPage -
                        formik.values.responsibilities.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.requirements}
                  </TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      value={formik.values.requirement}
                      onChange={formik.handleChange("requirement")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                      maxLength="1000"
                    />
                    <SymbolsCountForDescription>
                      {availableSymbolsForThirdPage -
                        formik.values.requirement.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.nice_to_have}
                  </TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      value={formik.values.niceToHave}
                      onChange={formik.handleChange("niceToHave")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                      maxLength="1000"
                    />
                    <SymbolsCountForDescription>
                      {availableSymbolsForThirdPage -
                        formik.values.niceToHave.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>
                    {vocabulary.v_hr_people.vacancies.working_conditions}
                  </TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      value={formik.values.workingConditions}
                      onChange={formik.handleChange("workingConditions")}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        vocabulary.v_hr_people.vacancies.type_text_here
                      }
                      maxLength="1000"
                    />
                    <SymbolsCountForDescription>
                      {availableSymbolsForThirdPage -
                        formik.values.workingConditions.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                </LabelContainer>

                <ButtonContainer>
                  <CancelButton
                    type="button"
                    onClick={() => setTypeForm("second")}
                  >
                    <BackArrowSvg />
                    {v_back}
                  </CancelButton>
                  <SaveButton type="button" onClick={formik.handleSubmit}>
                    {v_save}
                  </SaveButton>
                </ButtonContainer>
              </div>
            );

          default:
            return null;
        }
      },
      [
        typeForm,
        selectedJobType,
        directionArrayOptions,
        selectedCurrency,
        applicationArrayOptions,
        clearValueInDropdown,
        initialValues,
      ],
    );

    const renderLines = useCallback(
      () => (
        <LinesContainer>
          <StyledSVG active={typeForm === "first"}>
            <LineIcon active={typeForm === "first"} />
          </StyledSVG>
          <StyledSVG active={typeForm === "second"}>
            <LineIcon active={typeForm === "second"} />
          </StyledSVG>
          <StyledSVG active={typeForm === "third"}>
            <LineIcon active={typeForm === "third"} />
          </StyledSVG>
        </LinesContainer>
      ),
      [typeForm],
    );

    const renderButtons = () => (
      <ButtonContainer>
        <CancelButton type="button" onClick={handleOnDelete}>
          <BackArrowSvg />
          {v_cancel}
        </CancelButton>
        <SaveButton type="submit">{v_save}</SaveButton>
      </ButtonContainer>
    );

    const renderDirectionModal = useCallback(
      () => (
        <Modal
          open={directionModal.isOpen}
          title={vocabulary.v_hr_people.header.vacancies}
          onClose={handleOnDelete}
          headerAdditionalElements={renderLines()}
          headerStatusInModal={
            isEdit ? <TitleSubText>edit</TitleSubText> : null
          }
          classes={{
            header: "recruitment-modal__header",
            title: "recruitment-modal__title",
            wrapper: "recruitment-modal",
            container: "recruitment-modal__container",
          }}
        >
          <>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              enableReinitialize={true}
            >
              {(formik) => {
                formikRef.current = formik;

                return (
                  <>
                    <StyledForm onSubmit={formik.handleSubmit}>
                      {renderTypeForms(formik)}
                    </StyledForm>
                  </>
                );
              }}
            </Formik>
          </>
        </Modal>
      ),
      [
        initialValues,
        directionModal,
        typeForm,
        selectedJobType,
        directionArrayOptions,
        applicationArrayOptions,
      ],
    );
    return <>{renderDirectionModal()}</>;
  },
);

export default CreateVacanciesModal;
