import React, { Component } from "react";
import { connect } from "react-redux";

import classNames from "classnames";

// Config

// Actions
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import { getTimeEntriesListAction } from "../../../redux/actions/MainPageAction";

// Styles
import "./style.scss";
import { syncTaskWithJira } from "../../../api/sync";

class JiraIcon extends Component {
  state = {
    rotateJiraIcon: false,
  };

  syncTaskWithJira = async (event) => {
    const {
      vocabulary,
      task,
      disableClick,
      showNotificationAction,
      getTimeEntriesListAction,
    } = this.props;
    const { id } = task;

    if (!disableClick) {
      this.setState({ rotateJiraIcon: true });
      try {
        await syncTaskWithJira(id);
        await getTimeEntriesListAction();
      } catch (error) {
        if (error.response.data.message) {
          showNotificationAction({
            text: vocabulary[error.response.data.message],
            type: "error",
          });
        }
      } finally {
        this.setState({ rotateJiraIcon: false });
      }
    }
  };

  render() {
    const { isSync, user, currentTeam } = this.props;
    const { tokenJira } = user;

    const { rotateJiraIcon } = this.state;

    const disabled =
      currentTeam.data.userPlan?.name === "Free" ||
      currentTeam.data.userPlan?.name === "Starter";

    return tokenJira ? (
      <div
        className={classNames("wrapper-jira-icon", {
          "wrapper-jira-icon--rotate-jira": rotateJiraIcon,
          "wrapper-jira-icon--disabled": disabled,
        })}
        onClick={this.syncTaskWithJira}
        disabled={disabled}
      >
        <i
          className={classNames("jira-icon", { "jira-icon-is-sync": isSync })}
        />
      </div>
    ) : null;
  }
}

const mapStatetToProps = (state) => ({
  user: state.userReducer.user,
  vocabulary: state.languageReducer.vocabulary,
  currentTeam: state.teamReducer.currentTeam,
});

const mapDispatchToProps = {
  showNotificationAction,
  getTimeEntriesListAction,
};

export default connect(mapStatetToProps, mapDispatchToProps)(JiraIcon);
