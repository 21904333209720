import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as Moment from "moment";
import CustomTippy from "../../../Unstructured/Tooltip/CustomTippy";

import { useHistory } from "react-router-dom";

import LowPriority from "../../../../assets/images/ComponentIcons/LowPriority";
import MediumPriority from "../../../../assets/images/ComponentIcons/MediumPriority";
import HighPriority from "../../../../assets/images/ComponentIcons/HighPriority";

import cn from "classnames";

import {
  TableRow,
  TableCell,
  TableCellContent,
  EditIcon,
  ArchiveIcon,
  TimerIcon,
  ButtonEdit,
} from "./My.styled";

const List = ({
  vocabulary,
  items,
  dir,
  handleClickIcon,
  handleOpenViewModal,
}) => {
  const { v_hr_people, v_edit } = vocabulary;
  const history = useHistory();

  const handleIconClick = (event, id, type) => {
    event.stopPropagation();
    if (dir === "application") {
      handleClickIcon(id, type);
    } else {
      handleClickIcon({ id });
    }
  };

  const handleLinkClick = (title, items, id) => {
    const encodedTitle = encodeURIComponent(title);
    const pathname = `/recruitment/directions/${encodedTitle}/board`;

    const state = {
      title: title,
      items: items,
      id: id,
    };

    history.push({
      pathname: pathname,
      prevPage: true,
      state: state,
    });
  };
  const displayStatus = (status) => {
    switch (status) {
      case "OPEN":
        return "Open";

      case "ONHOLD":
        return "On Hold";

      case "CLOSED":
        return "Closed";

      case "REJECTED":
        return "Rejected";
      default:
        return "";
    }
  };

  const switchImagePriorityByType = useCallback(
    (priority) => {
      switch (priority) {
        case "LOW":
          return <LowPriority />;

        case "MEDIUM":
          return <MediumPriority />;

        case "HIGH":
          return <HighPriority />;

        case "NONE":
          return <LowPriority />;

        default:
          return <LowPriority />;
      }
    },
    [items],
  );

  const renderListItems = useCallback(() => {
    switch (dir) {
      case "application":
        return (
          <tbody>
            {" "}
            {items.map((element, index) => {
              const { id, title, priority, created_at, country, deadline } =
                element;

              return (
                <TableRow key={id}>
                  <TableCell>
                    <ButtonEdit
                      onClick={(event) =>
                        handleIconClick(event, element, "edit")
                      }
                    >
                      <CustomTippy content={v_edit}>
                        <EditIcon />
                      </CustomTippy>
                    </ButtonEdit>
                  </TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>{country}</TableCell>
                  <TableCell>
                    <div>{switchImagePriorityByType(priority)}</div>
                  </TableCell>
                  <TableCell>
                    {Moment(created_at).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell>
                    <TableCellContent
                      className={cn("deadline", {
                        deadline__yellow:
                          Moment(deadline).diff(Moment(), "days") <= 13,
                        deadline__red:
                          Moment(deadline).diff(Moment(), "days") <= 6,
                      })}
                    >
                      <div>
                        <TimerIcon
                          className={cn("timer", {
                            timer__yellow:
                              Moment(deadline).diff(Moment(), "days") <= 13,
                            timer__red:
                              Moment(deadline).diff(Moment(), "days") <= 6,
                          })}
                        />
                        {deadline ? Moment(deadline).format("DD.MM.YYYY") : ""}
                      </div>
                    </TableCellContent>
                  </TableCell>
                  <TableCell>
                    <ButtonEdit
                      onClick={(event) =>
                        handleIconClick(event, element, "archive")
                      }
                    >
                      <CustomTippy content={v_hr_people.applications.archive}>
                        <ArchiveIcon />
                      </CustomTippy>
                    </ButtonEdit>
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        );

      case "vacancies":
        return (
          <tbody>
            {" "}
            {items.map((item, index) => (
              <TableRow
                key={item?.id}
                onClick={() => handleOpenViewModal(item)}
              >
                <TableCell>{item?.title}</TableCell>
                <TableCell>{displayStatus(item?.status)}</TableCell>
                <TableCell>
                  <div>{switchImagePriorityByType(item?.priority)}</div>
                </TableCell>
                <TableCell>
                  {item?.candidatesCount ? item?.candidatesCount : "-"}
                </TableCell>
                <TableCell>
                  {Moment(item?.updated_at).format("DD.MM.YYYY")}
                </TableCell>
                <TableCell>
                  {Moment(item?.created_at).format("DD.MM.YYYY")}
                </TableCell>
                <TableCell>
                  <TableCellContent
                    className={cn("deadline", {
                      deadline__yellow:
                        Moment(item?.deadline).diff(Moment(), "days") <= 13,
                      deadline__red:
                        Moment(item?.deadline).diff(Moment(), "days") <= 6,
                    })}
                  >
                    <div>
                      <TimerIcon
                        className={cn("timer", {
                          timer__yellow:
                            Moment(item?.deadline).diff(Moment(), "days") <= 13,
                          timer__red:
                            Moment(item?.deadline).diff(Moment(), "days") <= 6,
                        })}
                      />
                      {item?.deadline
                        ? Moment(item?.deadline).format("DD.MM.YYYY")
                        : "-"}
                    </div>
                  </TableCellContent>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        );

      default:
        return (
          <tbody>
            {" "}
            {items.map(
              (
                {
                  id,
                  title,
                  candidateCount,
                  vacancyCount,
                  updated_at,
                  deadline,
                },
                index,
              ) => (
                <TableRow
                  key={id}
                  onClick={() => handleLinkClick(title, items, id)}
                >
                  <TableCell>
                    <ButtonEdit onClick={(event) => handleIconClick(event, id)}>
                      <CustomTippy content={v_edit}>
                        <EditIcon />
                      </CustomTippy>
                    </ButtonEdit>
                  </TableCell>

                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <div>{switchImagePriorityByType()}</div>
                  </TableCell>
                  <TableCell>{vacancyCount ? vacancyCount : "-"}</TableCell>
                  <TableCell>{candidateCount ? candidateCount : "-"}</TableCell>
                  <TableCell>
                    {Moment(updated_at).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell>
                    <TableCellContent
                      className={cn("deadline", {
                        deadline__yellow:
                          Moment(deadline).diff(Moment(), "days") <= 13,
                        deadline__red:
                          Moment(deadline).diff(Moment(), "days") <= 6,
                      })}
                    >
                      <div>
                        <TimerIcon
                          className={cn("timer", {
                            timer__yellow:
                              Moment(deadline).diff(Moment(), "days") <= 13,
                            timer__red:
                              Moment(deadline).diff(Moment(), "days") <= 6,
                          })}
                        />
                        {deadline ? Moment(deadline).format("DD.MM.YYYY") : "-"}
                      </div>
                    </TableCellContent>
                  </TableCell>
                </TableRow>
              ),
            )}
          </tbody>
        );
    }
  }, [dir, items]);

  return <>{renderListItems()}</>;
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps, null)(List);
