import styled from "styled-components/macro";

export const TableRow = styled.tr`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background-color: var(--bg-resource-planning-item-user);
  color: var(--text);

  &:hover {
    background-color: var(--bg-tag);
  }
`;
export const TableRow2 = styled.tr`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--text);
`;

export const TableCell = styled.td`
  padding: 10px;
  width:200px;
  &:first-child {
    width: 40px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const TableCell2 = styled(TableCell)`
  vertical-align: top;
`;

