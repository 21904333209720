import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import "./style.scss";

const colors = {
  Free: "#8492a5",
  Starter: `#27ae60`,
  Business: `#299bc1`,
};

const CongratsStep = ({
  vocabulary,
  selectedPlan,
  handleClose,
  handleExplore,
  handleCloseCongrats,
  customerData,
  currentTeam
}) => {
  const { v_subscription } = vocabulary;
  const { billingModal, billingModalDowngrade } = v_subscription;

  const newPlanePrice = selectedPlan.externalPlanData.pricePeriod?.nickname;
  const oldPlanePrice = customerData.plan?.nickname;

  const currentPlan = currentTeam.data.plan.plan_name;

  const upgradeChecks = () => {
    return newPlanePrice === oldPlanePrice || newPlanePrice > oldPlanePrice;
  };

  const resultUpgradeChecks = upgradeChecks();
  const timeEndPlanUser = moment
    .unix(customerData.current_period_end)
    .format("l")
    .replaceAll("/", ".");

  const userPlanColor = colors[currentPlan];
  const userNewPlanColor = colors[selectedPlan.data.title];

  return (
    <>
      {resultUpgradeChecks ? (
        <div className="subscription-billing-info-congrats">
          <div className="subscription-billing-info-congrats__body-header">
            <i
              className="subscription-billing-info-congrats__body-header-close"
              onClick={handleCloseCongrats}
            />
          </div>
          <div className="subscription-billing-info-congrats__body">
            <i className={`${selectedPlan.data.class}-img`} />
            <h2>{billingModal.congratsStep.congrats}</h2>
            <p> {billingModal.congratsStep.info}</p>
            <ul className="all-subs-plans__plan-description">
              {selectedPlan.data.description.map((desc, index) => (
                <li className="congratulation-item" key={index}>
                  {desc}
                </li>
              ))}
            </ul>
            <button
              className="subscription-billing-info-congrats__body-btn"
              onClick={handleExplore}
            >
              {billingModal.startExploring}
            </button>
          </div>
        </div>
      ) : (
        <div className="subscription-billing-info-downgrade">
          <div className="subscription-billing-info-downgrade__body-header">
            <div className="subscription-billing-info-downgrade__body-header-title">
              {billingModalDowngrade.title}
            </div>
            <div
              className="subscription-billing-info-downgrade__body-header-close"
              onClick={handleCloseCongrats}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 5L5 15"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 5L15 15"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="subscription-billing-info-downgrade__body">
            <div
              className="subscription-billing-info-downgrade__body-title"
              style={{ color: `${userNewPlanColor}` }}
            >
              {`${selectedPlan.data.title} Plan`}
            </div>
            <div className="subscription-billing-info-downgrade__body-text">
              <span>{`${billingModalDowngrade.currentPlan} `}</span>
              <span
                style={{ color: `${userPlanColor}` }}
              >{`${currentPlan} `}</span>
              {`- ${billingModalDowngrade.pait} `}
              <span>{`${timeEndPlanUser} `}</span>
              <span>{`${currentPlan} ${billingModalDowngrade.text}`}</span>.
            </div>
            <div className="subscription-billing-info-downgrade__body-button">
              <button
                className="subscription-billing-info-congrats__body-btn"
                onClick={handleCloseCongrats}
              >
                {billingModalDowngrade.button}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentTeam: state.teamReducer.currentTeam,
  vocabulary: state.languageReducer.vocabulary,
  lang: state.languageReducer.selectedLang,
});

export default connect(mapStateToProps)(CongratsStep);
