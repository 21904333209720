import React from "react";

const LowPriority = () => (
  <svg width="16" height="3" viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.50012H15" stroke="#F3AD26" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default LowPriority;

