import React, { Component } from "react";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import classNames from "classnames";

// Services
import { getTimeDurationByGivenTimestamp } from "../../../services/timeService";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole, checkIsInvoicesManagerByRole,
} from "../../../../src/services/authentication";

// Styles
import "./style.scss";
import ReportsProjectsList from "../Reports/ReportsProjectsList";
import ReportsTeamsList from "../Reports/ReportsTeamsList";
import ReportsProjectsByUsers from "../Reports/ReportsProjectsByUsers";
import ReportsProjectsByTeams from "../Reports/ReportsProjectsByTeams";

class ProjectsContainer extends Component {
  state = {
    isProjects: true,
    isActivities: false,
  };

  toogleActivities = () => {
    this.setState({
      isActivities: true,
    });
  };

  toogleProjects = () => {
    (checkIsAdminByRole(this.props.currentTeam.data.role) ||
      checkIsOwnerByRole(this.props.currentTeam.data.role) ||
      checkIsHRManagerByRole(this.props.currentTeam.data.role) ||
      checkIsProjectManagerByRole(this.props.currentTeam.data.role) ||
      checkIsInvoicesManagerByRole(this.props.currentTeam.data.role)) &&
      this.setState({
        isProjects: !this.state.isProjects,
        isActivities: false,
      });
  };

  doughnutOptions = (durationTimeFormat) => ({
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data.labels[tooltipItem.index];
          const duration = getTimeDurationByGivenTimestamp(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
            durationTimeFormat,
          );

          return `${label}: ${duration}`;
        },
      },
      backgroundColor: "#292b2f",
      borderColor: "#fafafc",
      borderWidth: 1,
    },
  });

  render() {
    const { isProjects, isActivities } = this.state;
    const {
      vocabulary,
      durationTimeFormat,
      summaryDoughnutChart,
      isCrossTeam,
      newArrUserTeams,
      newArrUserTeamsForPeople,
    } = this.props;
    const { v_people, v_projects, v_teams } = vocabulary;
    let datesValue = "00:00:00";

    if (summaryDoughnutChart.datasets[0].data.length) {
      datesValue = JSON.parse(
        JSON.stringify(
          summaryDoughnutChart.datasets[0].data.reduce((a, b) => a + b),
        ),
      );
    }

    return (
      <div className="projects_container_wrapper">
        <div className="projects_container_projects">
          <div className="projects_container_buttons">
            <div
              className={classNames("projects_container_button", {
                "projects_container_button--active":
                  isProjects && !isActivities,
              })}
              style={
                checkIsAdminByRole(this.props.currentTeam.data.role) ||
                checkIsOwnerByRole(this.props.currentTeam.data.role) ||
                checkIsHRManagerByRole(this.props.currentTeam.data.role) ||
                checkIsProjectManagerByRole(this.props.currentTeam.data.role) ||
                checkIsInvoicesManagerByRole(this.props.currentTeam.data.role)
                  ? { cursor: "pointer" }
                  : { cursor: "unset" }
              }
              onClick={this.toogleProjects}
            >
              {isCrossTeam ? v_teams : v_projects}
            </div>
            {(checkIsAdminByRole(this.props.currentTeam.data.role) ||
              checkIsOwnerByRole(this.props.currentTeam.data.role) ||
              checkIsHRManagerByRole(this.props.currentTeam.data.role) ||
              checkIsProjectManagerByRole(this.props.currentTeam.data.role) ||
              checkIsInvoicesManagerByRole(this.props.currentTeam.data.role)
            ) && (
              <div
                className={classNames("projects_container_button", {
                  "projects_container_button--active":
                    !isProjects && !isActivities,
                })}
                style={{
                  cursor:
                    checkIsAdminByRole(this.props.currentTeam.data.role) ||
                    checkIsOwnerByRole(this.props.currentTeam.data.role) ||
                    checkIsHRManagerByRole(this.props.currentTeam.data.role) ||
                    checkIsProjectManagerByRole(this.props.currentTeam.data.role) ||
                    checkIsInvoicesManagerByRole(this.props.currentTeam.data.role)
                      ? "pointer"
                      : "unset",
                }}
                onClick={this.toogleProjects}
              >
                {v_people}
              </div>
            )}
          </div>
          {isProjects && !isActivities && (
            <>
              {isCrossTeam ? (
                <ReportsTeamsList usersByTeams={newArrUserTeams} />
              ) : (
                <ReportsProjectsList
                  usersByProjects={this.props.userProjects}
                  projects={this.props.reportProjects}
                  selectionRange={this.props.selectionRange}
                />
              )}
            </>
          )}
          {!isProjects && !isActivities && (
            <>
              {isCrossTeam ? (
                <ReportsProjectsByTeams
                  users={newArrUserTeamsForPeople}
                  selectionRange={this.props.selectionRange}
                />
              ) : (
                <ReportsProjectsByUsers
                  userProjects={this.props.userProjects}
                  selectionRange={this.props.selectionRange}
                />
              )}
            </>
          )}
        </div>
        <div className="chart">
          <div className="total_time_tasks">
            {typeof datesValue === "number"
              ? getTimeDurationByGivenTimestamp(datesValue, durationTimeFormat)
              : ""}
          </div>
          <Doughnut
            data={summaryDoughnutChart}
            options={this.doughnutOptions(durationTimeFormat)}
            width={303}
            height={303}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  durationTimeFormat: state.userReducer.durationTimeFormat,
  currentTeam: state.teamReducer.currentTeam,
  summaryDoughnutChart: state.reportsPageReducer.summaryDoughnutChart,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
