import { getDateTimestamp } from "./timeService";
import { decodeTimeEntryIssue } from "./timeEntryService";

export function getProjectListParseFunction(array) {
  return array.map((project) => {
    const {
      is_active: isActive,
      project_color: projectColor,
      user_projects: userProjects,
      ...rest
    } = project;
    let { timer } = project;

    if (timer) {
      timer = timer.map((item) => {
        const { start_datetime: startDatetime, end_datetime: endDatetime } =
          item;

        return {
          startDatetime,
          endDatetime,
        };
      });

      return {
        ...rest,
        isActive,
        projectColor,
        timer,
        userProjects: userProjects || [],
      };
    } else {
      return {
        ...rest,
        isActive,
        projectColor,
        userProjects: userProjects || [],
      };
    }
  });
}

export function getProjectsV2ProjectPageAdminParseFunction(data) {
  const dataParsed = {
    projectV2: [],
  };
  const { project_v2: projectV2 } = data;

  for (let i = 0; i < projectV2.length; i++) {
    const project = projectV2[i];

    const { id, name, timer } = project;

    let totalTime = 0; // in ms

    for (let i = 0; i < timer.length; i++) {
      const timeEntry = timer[i];
      const { start_datetime: startDatetime, end_datetime: endDatetime } =
        timeEntry;

      totalTime +=
        getDateTimestamp(endDatetime) - getDateTimestamp(startDatetime);
    }

    dataParsed.projectV2.push({
      id,
      name,
      totalTime,
    });
  }

  return dataParsed;
}

export function addUserRoleToProjects(data) {
  return data.map((item) => {
    const { userProjects } = item;

    if (userProjects.length) {
      userProjects.forEach((user) => {
        user.user.role = user.user.user_teams[0].role_collaboration.title;
      });
    }
    return { ...item, userProjects };
  });
}

export function getTodayTimeEntriesParseFunction(data) {
  const dataParsed = {
    timerV2: [],
  };
  const { timer_v2 } = data;

  for (let i = 0; i < timer_v2.length; i++) {
    const item = timer_v2[i];
    const {
      id,
      billable,
      start_datetime: startDatetime,
      end_datetime: endDatetime,
      issue,
      project,
      sync_jira_status: syncJiraStatus,
      is_future,
    } = item;
    const {
      name: projectName,
      id: projectId,
      project_color: projectColor,
    } = project;
    const { name: projectColorName } = projectColor;

    dataParsed.timerV2.push({
      id,
      startDatetime,
      endDatetime,
      isFuture: is_future,
      issue: issue,
      billable,
      project: {
        name: projectName,
        id: projectId,
        projectColor: {
          name: projectColorName,
        },
      },
      syncJiraStatus,
    });
  }

  return dataParsed;
}

export function getDoughnutGraphParseFunction(chartObject, dataFromServer) {
  const newObjectChart = { ...chartObject };
  const labels = [];
  const dataTime = [];

  for (let i = 0; i < dataFromServer.length; i++) {
    if (dataFromServer[i].timer_current_v2 === null && dataFromServer[i].timer_v2.length !== 0) {
      const duration = new Date(dataFromServer[i].timer_v2[0].end_datetime).getTime() -
        new Date(dataFromServer[i].timer_v2[0].start_datetime).getTime();
      if (duration) {
        labels.push(dataFromServer[i].username);
        dataTime.push(duration);
      }
    }
  }

  newObjectChart.labels = labels;
  newObjectChart.datasets[0].data = dataTime;

  return newObjectChart;
}
