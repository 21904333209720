import React, { useEffect } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import countries from "../../../locales/countries";

import "./style.scss";

const PAYMENT_BEARER_TYPES = {
  CREDIT_CARD: "CreditCard",
  BANK_ACCOUNT: "BankAccount",
};

/**
 * The details of payment bearer are rendered by
 * this map.
 *
 * Key is key in payment bearer, value is a translation
 * key for the label
 *
 * E.g., if data is:
 * ```
 * {
 *     PaymentBearer:
 *     CardType: "Mastercard"
 *     Country: "DE"
 *     ExpiryMonth: 2
 *     ExpiryYear: 2028
 *     Holder: "John Doe"
 *     Last4: "1234"
 *     MaskedCardPan: "1234XXXXXX1234"
 *     Type: "CreditCard"
 * }
 *
 * We will took keys by `PAYMENT_BEARER_TYPES.CREDIT_CARD`
 * For example, the translation key for `ExpiryMonth` is
 * `'expiryMonth'` - therefore, the translation will be taken
 * by `v_payment_method_details.labels['expiryMonth']`
 * ```
 */
const PAYMENT_BEARER_FIELDS_MAP = {
  [PAYMENT_BEARER_TYPES.CREDIT_CARD]: {
    Country: "country",
    Holder: "cardHolder",
    Last4: "masked",
    ExpiryMonth: "expiryMonth",
    ExpiryYear: "expityYear",
  },
  [PAYMENT_BEARER_TYPES.BANK_ACCOUNT]: {
    Country: "country",
    Holder: "holder",
    BIC: "bic",
    IBAN: "iban",
  },
};

const PaymentMethodDetails = ({
  vocabulary,
  lang,
  paymentMethod,
  dark,
  small,
}) => {
  const { v_payment_method_details } = vocabulary;

  return (
    <div
      className={`payment-method-details ${
        dark ? "payment-method-details--dark" : ""
      } ${small ? "payment-method-details--small" : ""}`}
    >
      <ul className="payment-method-details__list">
        <li className="payment-method-details__item">
          <div className="payment-method-details__label payment-method-details__details-label">
            County
          </div>
          <div className="payment-method-details__value">
            {countries[lang][paymentMethod.country]}
          </div>
        </li>
        <li className="payment-method-details__item">
          <div className="payment-method-details__label payment-method-details__details-label">
            Card number
          </div>
          <div className="payment-method-details__value">{`•••• ${paymentMethod.last4}`}</div>
        </li>
        <li className="payment-method-details__item">
          <div className="payment-method-details__label payment-method-details__details-label">
            Expiration Date
          </div>
          <div className="payment-method-details__value">{`${paymentMethod.exp_month} / ${paymentMethod.exp_year}`}</div>
        </li>
      </ul>
    </div>
  );
};

PaymentMethodDetails.propTypes = {
  vocabulary: Proptypes.object,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
  lang: store.languageReducer.selectedLang,
});

export default connect(mapStateToProps)(PaymentMethodDetails);
