import styled from "styled-components/macro";

export const StyledSVG = styled.div`
  padding: 0 2px;
  svg {
    width: ${(props) => (props.active ? "50px" : "25px")};
    transition: fill 0.3s ease, width 0.3s ease; /* плавний перехід */
  }
  rect {
    width: ${(props) => (props.active ? "50px" : "25px")};
    fill: ${(props) => (props.active ? "#00B173" : "#52565F")};
    transition: fill 0.3s ease, width 0.3s ease; /* плавний перехід */
  }
`;

export const LinesContainer = styled.div`
  display: flex;
  position: absolute;
  right: 88px;
  top: 50px;
`;

export const HeaderWrapper = styled.div`
  align-items: center;

  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const TitleText = styled.span`
  color: var(--text);

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`;
export const TitleSubText = styled.div`
  color: var(--text);
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #52565f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const EditButton = styled.span`
  color: #00b173;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  &:hover {
      color: grey;
  }
`;
