import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { apiCall } from "../../../../../services/apiService";
import { AppConfig } from "../../../../../config";
import {
  submitCoupon,
  setProductId,
} from "../../../../../redux/actions/SubscriptionPageAction";
import moment from "moment";
import { showNotificationAction } from "../../../../../redux/actions/NotificationActions";

import "./style.scss";

const Coupon = ({
  plan,
  setProductId,
  vocabulary,
  submitCoupon,
  showNotificationAction,
}) => {
  const { v_coupon_doesnt_exist, v_coupon_expired, v_coupon_used_up } =
    vocabulary;
  const [couponId, setCouponId] = useState("");
  const handleSubmitCoupon = useCallback(() => {
    if (!couponId) return;

    apiCall(`${AppConfig.apiURL}subscription/coupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ couponId }),
    })
      .then(
        ({
          percent_off,
          applies_to,
          max_redemptions,
          times_redeemed,
          redeem_by,
        }) => {
          if (max_redemptions === times_redeemed) {
            showNotificationAction({
              text: v_coupon_used_up,
              type: "error",
            });

            return;
          }

          if (redeem_by && moment().isAfter(redeem_by * 1000)) {
            showNotificationAction({
              text: v_coupon_expired,
              type: "error",
            });

            return;
          }
          setProductId(plan.externalPlanData.id);

          submitCoupon({
            discount: percent_off,
            products: applies_to.products,
            couponId,
          });
        },
      )
      .catch((err) => {
        err.json().then(({ error }) => {
          let text = "";

          switch (error) {
            case "Coupon with this ID does not exist":
              text = v_coupon_doesnt_exist;
              break;

            default:
              break;
          }

          showNotificationAction({
            text,
            type: "error",
          });
        });
      })
      .finally(() => setCouponId(""));
  }, [couponId, submitCoupon, setProductId]);
  return (
    <div className="coupon-block">
      <div className="coupon-block__info">Your promo code :</div>
      <div className="one-line">
        <input
          className="coupon-block__input"
          placeholder="Enter your promo code"
          value={couponId}
          onChange={(e) => setCouponId(e.target.value)}
        ></input>
        <button onClick={handleSubmitCoupon} className="coupon-block__btn">
          Apply
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.responsiveReducer.isMobile,
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
  submitCoupon,
  showNotificationAction,
  setProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
