import * as Yup from "yup";
export const validationSchema = Yup.object({
  // title: Yup.string().min(6, "Min length 6").required("This field is required"),
  priority: Yup.string().required("This field is required"),
  description: Yup.string().max(1000),
  name_vacancy: Yup.string().required("This field is required"),
  company: Yup.string().required("This field is required"),
  status: Yup.string().required("This field is required"),
  directionValue: Yup.string().required("This field is required"),
});

export const optionsCurrency = [
  { value: null, label: "None" },
  { value: "usd", label: "USD - $" },
  { value: "eur", label: "EUR - €" },
  { value: "gbp", label: "GBP - £" },
  { value: "pln", label: "PLN - ZŁ" },
  { value: "uah", label: "UAH - ₴" },
];

export const priorityOptions = [
  { value: "NONE", label: "None" },
  { value: "LOW", label: "Low" },
  { value: "MEDIUM", label: "Medium" },
  { value: "HIGH", label: "High" },
];

export const levelOptions = [
  { value: "TRAINEE", label: "Internship / Trainee" },
  { value: "JUNIOR", label: "Junior" },
  { value: "MIDDLE", label: "Middle" },
  { value: "SENIOR", label: "Senior" },
];

export const expirienceOptions = [
  { value: "noExperience", label: "No experience" },
  { value: "<1year", label: "<1 year" },
  { value: "1-3years", label: "1-3 years" },
  { value: "3-5years", label: "3-5 years" },
  { value: "5-10years", label: "5-10 years" },
  { value: "10+years", label: "10+years" },
];

export const employmentOptions = [
  { value: "office", label: "Office" },
  { value: "remote", label: "Remote" },
  { value: "office&remote", label: "Office & remote (hybrid)" },
];

export const directionsOptions = [
  { value: "development", label: "Development" },
  { value: "design", label: "Design" },
  { value: "management", label: "Management" },
  { value: "another", label: "Another" },
];

export const statusOptions = [
  { value: "OPEN", label: "Open" },
  { value: "CLOSED", label: "Closed" },
  { value: "REJECTED", label: "Rejected" },
  { value: "ONHOLD", label: "On hold" },
];

export const jobType = [
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" },
  { value: "office", label: "Office" },
];

