import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PageHeader from "../../components/Unstructured/PageHeader/index";
import ResourcePlanningPlanModal from "../../components/Unstructured/ResourcePlanningPlanModal";
import ResourcePlanningUserModal from "../../components/Unstructured/ResourcePlanningUserModal";

import _ from "lodash";
import moment from "moment";

import "./style.scss";
import SearchComponentResourcePlanning from "../../components/Unstructured/ResourcePlanningSearchComponent/index.tsx";
import {
  getCurrentTeamDetailedDataAction,
  getTeamUsersStatusAction,
} from "../../redux/actions/TeamActions";
import { getProjectsListActions } from "../../redux/actions/ProjectsActions";
import {
  getPlansByTeamAction,
  getWorkload,
} from "../../redux/actions/ResourcePlanningActions";
import ResourcePlanningLibrary from "../../components/Unstructured/ResourcePlanningLibrary";
import { Loading } from "../../components/Unstructured/Loading";
import ResourcePlanningTimelineHeader from "../../components/Unstructured/ResourcePlanningTimelineHeader";

class ResourcePlanningPage extends Component {
  state = {
    isInitialFetching: true,
    isOpenPlanModal: false,
    isOpenUserModal: false,
    currentUserData: null,
    isReadonlyPlanModal: false,
    isEditPlanModal: false,
    currentPlanInfo: null,
    memberId: null,
  };

  async componentDidMount() {
    await this.props.getCurrentTeamDetailedDataAction();
    await this.props.getTeamUsersStatusAction();
    await this.props.getProjectsListActions({
      withTimerList: false,
      withUserInfo: true,
    });
    await this.props.getPlansByTeamAction();
    
    await this.props.getWorkload();

    this.setState({
      isInitialFetching: false,
    });
  }

  componentWillUnmount() {}

  openPlanModal = () => {
    this.setState({
      isOpenPlanModal: true,
    });
  };

  closePlanModal = () => {
    this.setState({
      isOpenPlanModal: false,
      isReadonlyPlanModal: false,
      isEditPlanModal: false,
      currentPlanInfo: null,
    });
  };

  openPlanInfoModal = (item) => {
    this.setState({
      isOpenPlanModal: true,
      isReadonlyPlanModal: true,
      isEditPlanModal: false,
      currentPlanInfo: item,
    });
  };

  openUserModal = async (user) => {
    const { id } = user;

    this.setState({
      isOpenUserModal: true,
      userId: id,
    });
  };

  closeUserModal = () => {
    this.setState({
      isOpenUserModal: false,
      currentUserData: null,
    });
  };

  searchByMember = (id) => {
    this.setState({ memberId: id });
  };

  render() {
    const {
      searchValue,
      isMobile,
      vocabulary,
      currentTeamDetailedData,
      TeamUserStatus,
      currentTeam,
    } = this.props;
    const { v_resource_planning, v_upgrade_label, v_see_plans, v_rp_plug } =
      vocabulary;
    const {
      isInitialFetching,
      isOpenPlanModal,
      isOpenUserModal,
      isReadonlyPlanModal,
      isEditPlanModal,
      currentPlanInfo,
      memberId,
      userId,
    } = this.state;
    const denide =
      currentTeam.data.userPlan?.name !== "Business";

      return (
        <Loading flag={isInitialFetching} mode="parentSize" withLogo={false}>
          <div
            className={classNames("wrapper_resource-planning_page", {
              "wrapper_resource-planning_page--mobile": isMobile,
            })}
            data-theme={localStorage.getItem("themes")}
          >
            <div className="data_container_resource-planning_page">
              <div
                className={classNames(
                  "data_container_resource-planning_page_top",
                  {
                    "data_container_resource-planning_page_top--denide": denide,
                  },
                )}
              >
                <PageHeader title={v_resource_planning} denide={denide}>
                  {/* <div className="invoices-page-top__search-input">
                  <SearchComponentResourcePlanning
                    value={searchValue}
                    selectMember={this.searchByMember}
                  />
                </div> */}
                  {denide && (
                    <Link
                      className="header-wrapper__child-button-denide"
                      to="/subscription/plans"
                    >
                      {v_upgrade_label}
                    </Link>
                  )}
                </PageHeader>

                {!denide && (
                  <div className="planning-block">
                    <ResourcePlanningTimelineHeader />
                    <ResourcePlanningLibrary
                      themes={localStorage.getItem("themes")}
                      memberId={memberId}
                      currentTeamDetailedData={currentTeamDetailedData}
                      TeamUserStatus={TeamUserStatus}
                      openUserModal={this.openUserModal}
                      openPlanInfoModal={this.openPlanInfoModal}
                    />
                  </div>
                )}
              </div>
              {denide && (
                <div className="planning-block-denide">
                  <div className="planning-block-denide-top">
                    <span>{v_rp_plug.title}</span>
                    <p>{v_rp_plug.description}</p>
                    <Link
                      className="header-wrapper__child-button"
                      to="/subscription/plans"
                    >
                      {v_see_plans}
                    </Link>
                  </div>
                  <div className="planning-block-denide-main">
                    <i className="plug-icon" />
                  </div>
                </div>
              )}
            </div>

            {isOpenPlanModal && (
              <ResourcePlanningPlanModal
                open={true}
                isEdit={isEditPlanModal}
                isReadonly={isReadonlyPlanModal}
                onModalClose={this.closePlanModal}
                data={currentPlanInfo}
              />
            )}
            {isOpenUserModal && (
              <ResourcePlanningUserModal
                themes={localStorage.getItem("themes")}
                open={true}
                onModalClose={this.closeUserModal}
                userId={userId}
              />
            )}
          </div>
        </Loading>
      );
  }
}

const mapStateToProps = (store) => ({
  currentTeamDetailedData: store.teamReducer.currentTeamDetailedData,
  TeamUserStatus: store.teamReducer.TeamUserStatus,
  currentTeam: store.teamReducer.currentTeam,
});

const mapDispatchToProps = {
  getCurrentTeamDetailedDataAction,
  getTeamUsersStatusAction,
  getProjectsListActions,
  getPlansByTeamAction,
  getWorkload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcePlanningPage);
