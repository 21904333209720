export default {
  lang: { short: "de", long: "Deutsch" },

  "ERROR.USER.THIS_FACEBOOK_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Dieser Facebook-Account ist bereits mit einem anderen Account verbunden",
  "ERROR.USER.THIS_SOCIAL_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Dieses soziale Konto ist bereits verbunden",
  "ERROR.USER.THIS_EMAIL_ALREADY_CONNECTED_TO_ANOTHER_FB_ACCOUNT":
    "Konto mit dieser E-Mail bereits mit einem anderen Facebook-Konto verbunden",
  "ERROR.USER.ACCOUNT_ALREADY_EXISTED": "Benutzerkonto existiert bereits.",
  "ERROR.TIMER.JIRA_WORKLOG_1_MIN_THRESHHOLD":
    "Die Mindestzeit für das Hinzufügen von Worklog in Jira beträgt 1 Minute.",
  "ERROR.TIMER.JIRA_ADD_WORKLOG_FAILED":
    'Beim Hinzufügen des Arbeitsprotokolls ins Jira ist ein Fehler aufgetreten. Stellen Sie sicher, dass der Zugriff auf Jira korrekt ist und der Aufgabenname dem Format "JIRA-1-Kommentar" entspricht. ',
  "ERROR.TIMER.JIRA_WORKLOG_ALREADY_ADDED":
    "Die aktuelle Aufgabe wurde bereits zu Jiras Arbeitsprotokoll hinzugefügt.",
  "Request path contains unescaped characters":
    "Überprüfen Sie die Korrektheit der Aufgabennummer",
  "Request failed with status code 404":
    "Die Aufgabe mit dieser Nummer wurde in Jira nicht gefunden",
  "Request failed with status code 401":
    "Falsche Synchronisationsdaten mit Jira 'überprüfen Sie die Richtigkeit im Benutzerprofil'",

  "TIMER.JIRA_SYNC_SUCCESS":
    "Testverbindung mit Jira wurde erfolgreich hergestellt.",
  "ERROR.TIMER.JIRA_SYNC_FAILED":
    "Eine Testverbindung mit Jira wurde nicht hergestellt. Stellen Sie sicher, dass die eingegebenen Daten korrekt sind. ",

  "SUCCESS.USER.RESET_EMAIL_CHECK":
    "Überprüfen Sie Ihre Mailbox, um das Passwort zurückzusetzen.",
  "SUCCESS.USER.RESET_PASSWORD":
    "Sie haben das Passwort erfolgreich zurückgesetzt.",
  "SUCCESS.USER.PASSWORD_CHANGED":
    "Sie haben das Passwort erfolgreich geändert.",
  "SUCCESS.USER.UPDATE_USER": "Benutzer wurde erfolgreich aktualisiert.",
  "ERROR.CHECK_REQUEST_PARAMS": "Es ist ein interner Fehler aufgetreten.",
  "ERROR.INTERNAL":
    "Es ist etwas schief gelaufen. Bitte versuchen Sie es später erneut.",
  "ERROR.NOT_AUTHORIZED": "Nicht autorisierte Anfrage.",
  "ERROR.PROJECT.UPDATE_FAILED":
    "Beim Aktualisieren des Projekts ist ein Fehler aufgetreten.",
  "ERROR.TEAM.CREATE_FAILED": "Team konnte nicht erstellt werden.",
  "ERROR.TEAM.SWITCH_FAILED":
    "Beim Wechseln des Teams ist ein Fehler aufgetreten.",
  "ERROR.TEAM.RENAME_FAILED":
    "Beim Umbenennen des Teams ist ein Fehler aufgetreten.",
  "ERROR.TIMER.UPDATE_FAILED":
    "Beim Aktualisieren des Timers ist ein Fehler aufgetreten.",
  "ERROR.TIMER.DELETE_FAILED":
    "Beim Löschen des Timers ist ein Fehler aufgetreten",
  "ERROR.USER.NOT_MEMBER": "Der Benutzer ist gehört noch nicht zu einem Team.",
  "ERROR.USER.RESET_EMAIL_FAILED":
    "Beim Senden einer E-Mail zum Zurücksetzen des Passworts ist ein Fehler aufgetreten.",
  "ERROR.USER.CURRENT_PASSWORD_WRONG": "Das aktuelle Passwort ist falsch.",
  "ERROR.USER.CHANGE_PASSWORD_FAILED":
    "Beim Ändern des Passworts ist ein Fehler aufgetreten.",
  "ERROR.USER.EMAIL_PASSWORD_WRONG": "Ungültige E-Mail-Adresse oder Passwort.",
  "ERROR.USER.CREATE_INVITE_FAILED":
    "Beim Erstellen der Benutzereinladung ist ein Fehler aufgetreten.",
  "ERROR.USER.ALREADY_TEAMMATE": "Der Benutzer ist bereits Mitglied des Teams.",
  "ERROR.USER.EMAIL_EXISTS": "Diese E-Mail Adresse ist bereits vorhanden.",
  "ERROR.USER.CREATE_USER_FAILED":
    "Beim Anlegen des Benutzers ist ein Fehler aufgetreten.",
  "ERROR.USER.UPDATE_USER_PERMISSIONS_DENIED":
    "Sie haben keine Berechtigung, die Benutzerdaten zu aktualisieren.",
  "ERROR.USER.UPDATE_USER_FAILED":
    "Beim Aktualisieren der Benutzerdaten ist ein Fehler aufgetreten.",
  "ERROR.USER.UPDATE_USER_EMAIL_EXISTS":
    "Diese Emailadresse existiert bereits.",
  "ERROR.USER.UPDATE_USER_ROLE_FAILED":
    "Sie habe nicht die notwendige Berechtigung, um die Rolle in Ihrem Team zu ändern.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ROLE_FAILED":
    "Sie habe nicht die notwendige Berechtigung, um die Rolle des Teambesitzers zu ändern.",
  "ERROR.USER.UPDATE_ACTIVE_STATUS_FAILED":
    "Sie können Ihr Konto in Ihrem eigenen Team nicht inaktiv setzen.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ACTIVE_STATUS_FAILED":
    "Sie können das Team-Besitzerkonto nicht inaktiv setzen.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Der Benutzer wurde erfolgreich aus dem Team gelöscht.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_SUCCEED":
    "Der Benutzer wurde erfolgreich aus dem Team gelöscht.",
  "ERROR.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Beim Löschen aus dem Team ist ein Fehler aufgetreten.",

  v_a_avatar_upload_error:
    "Ladefehler! Die Download-Datei ist kein Bild oder ihre Größe überschreitet 1 MB.",
  v_a_data_updated_ok: "Ihre Daten wurden aktualisiert",
  v_a_change_password_great_ok:
    "Großartig! Sie können sich nun mit dem neuen Passwort einloggen! ",
  v_a_change_password_ok: "Sie haben das Passwort erfolgreich geändert!",
  v_a_password_same_error: "Passwörter stimmen nicht überein!",
  v_a_invite_sent: "Einladung gesendet!",
  v_a_invite_sent_error:
    "Beim Senden der Einladung an den Benutzer ist ein Fehler aufgetreten!",
  v_a_invite_sent_user_error:
    "Etwas ist schief gelaufen... Benutzer aus 'Verweigert' entfernen und erneut einladen",
  v_a_project_created: "Projekt erstellt",
  v_a_project_updated: "Projekt aktualisiert",
  v_a_project_existed: "Projekt existiert bereits",
  v_a_project_created_error: "Projekt kann nicht angelegt werden",
  v_a_project_no_valid_name: "Projektname darf '%' nicht enthalten",
  v_a_project_created_synchronization_with_jira_error:
    "Das Jira-Projekt ist bereits mit dem Projekt verbunden",
  v_a_team_name_empty_error: "Der Name des Teams kann nicht leer sein!",
  v_a_project_edit_error: "Das Projekt kann nicht bearbeitet werden",
  v_a_time_start_error: "Falsche Startzeit, bitte überprüfen!",
  v_a_time_end_error: "Falsches Zeitende, bitte überprüfen!",
  v_a_time_already_busy:
    "Die Aufgabe darf sich zeitlich nicht mit einer anderen Aufgabe überschneiden",
  v_a_time_future_time_error:
    "Falsche Endzeit. Sie können die Zeit in der Zukunft nicht auswählen",
  v_a_time_already_busy_current_task:
    "Diese Zeit ist bereits durch die aktuelle Aufgabe belegt",
  v_a_task_name_error:
    "Bitte geben Sie vor dem Speichern einen Aufgabennamen ein",
  v_a_team_existed: "Das Team existiert bereits",
  v_a_team_rename_error: "Das Team kann nicht umbenannt werden",
  v_a_team_create_error: "Das Team kann nicht erstellt werden",
  v_a_task_name_before: "Bitte geben Sie erst einen Aufgabennamen ein",
  v_a_project_before: "Bitte wählen Sie vorher das Projekt aus",
  v_a_select_project: "Projekt auswählen",
  v_a_starting: "starten",
  v_a_stopping: "stoppen",
  v_a_time_tracking: "Zeiterfassung",
  v_a_account_create: "Konto erstellt",
  v_a_confirm_password_error: "Ungültige Passwortbestätigung",
  v_a_project_name_error: "Projektname darf nicht leer sein",
  v_a_task_delete: "Möchten Sie diesen Eintrag wirklich löschen?",
  v_a_incorect_email: "Ungültige E-Mail-Adresse",
  v_a_error_authorization: "Etwas ist schief gelaufen. Versuche es später",
  v_a_incorect_password: "Bitte geben Sie mindestens 6 Zeichen ein",
  v_a_client_existed: "Client ist bereits vorhanden",
  v_a_client_name_empty_error: "Der Kundenname darf nicht leer sein!",

  v_set_default: "wählen",
  v_default: "standard",
  v_help: "Hilfe",
  v_upload_image: "Bild hochladen",
  v_delete_image: "Bild löschen",
  v_phone: "Telefon",
  v_more_option: "Weitere Optionen",
  v_active_team: "Aktives Team",
  v_set: "Einstellen",
  v_team_is_active: "aktives Team",
  v_ok: "Speichern",
  v_cancel: "Abbrechen",
  v_language: "Sprache",
  v_cofirm_new_password: "Neues Passwort bestätigen",
  v_new_password: "Neues Passwort ",
  v_current_password: "Aktuelles Passwort",
  v_save_changes: "Speichern",
  v_new_team: "Neues Team",
  v_your_name: "Name",
  v_my_profile: "Profil",
  v_profile_settings: "Einstellungen",
  v_change_password: "Passwort ändern",
  v_neues_kennwort: "Neues Kennwort",
  v_send: "Senden",
  v_check_email:
    "Wenn Sie sich mit dieser E-Mail Adresse bei Woobly bereits registriert haben, erhalten Sie in Kürze eine E-Mail mit der Anleitung zum Zurücksetzen des Passworts",
  v_check_email_error: "Ihre E-Mail wurde nicht gesendet!",
  v_enter_email: "E-Mail-Adresse eingeben",
  v_create_team: "Ein Team erstellen",
  v_search_project: "Nach einem Projekt suchen",
  v_search_client: "Kunden suchen",
  v_start_timer: "Timer starten",
  v_edit_task: "Ändern",
  v_delete_task: "Löschen",
  v_add_confirm_password: "Passwort bestätigen",
  v_registration_terms_and_policy:
    "Durch die Registrierung stimmen Sie den Bedingungen und Datenschutzbestimmungen zu",
  v_login: "Benutzername",
  v_log_in: "Login",
  v_email: "E-Mail",
  v_email_error: "Zu lange E-Mail, nur 72 Symbole verfügbar",
  v_register: "Registrieren",
  v_password: "Passwort",
  v_unsubscribe: "Abonnement kündigen",
  v_sure: "Möchten Sie sich wirklich von dieser Mailingliste abmelden?",
  v_success_unsubscribe: "Benutzer erfolgreich abgemeldet",
  v_error_already_unsubscribe: "Benutzer hat sich bereits abgemeldet",
  v_sure_reports: "Möchten Sie Berichte wirklich abbestellen?",
  v_success_unsubscribe_reports:
    "Der Benutzer hat sich erfolgreich von Berichten abgemeldet",
  v_error_already_unsubscribe_reports:
    "Der Benutzer hat sich bereits von Berichten abgemeldet",
  v_enter: "Login",
  v_incorrect_password_or_email:
    "Deine E-Mail oder dein Passwort ist falsch. Versuchen Sie es nochmal.",
  v_cofirm_password: "Passwortbestätigung",
  v_forgot_your_password: "Passwort wiederherstellen",
  v_dont_have_an_account_yet: "Es gibt noch kein Benutzer-Konto",
  v_already_have_an_account: "Sie haben bereits ein Konto",
  v_sign_up: "Registrieren",
  v_timer: "Timer",
  v_reports: "Report",
  v_report_header: "Report:",
  v_summary: "Zusammenfassung",
  v_projects: "Projekte",
  v_project: "Projekt",
  v_people: "Menschen",
  v_description: "Beschreibung",
  v_teams: "Mannschaften",
  v_bench: "Bank",
  v_team: "Team",
  v_add_team: "Neues Team",
  v_add_your_password: "Passwort eingeben",
  v_my_team: "Mein Team",
  v_total_time: "Gesamtzeit",
  v_task_name: "Aufgabenname",
  v_time_start: "Startzeit",
  v_time_end: "Endzeit",
  v_change: "Ändern",
  v_last_change: "Letzte Änderung",
  v_create_new_project: "Neues Projekt erstellen",
  v_apply: "Übernehmen",
  v_edit_project: "Bearbeiten",
  v_time: "Zeit",
  v_create_project: "Projekt erstellen",
  v_log_out: "Beenden",
  v_add_user: "Anfrage senden",
  v_invite_to_team: "Zum Team einladen",
  v_team_role: "Rolle",
  v_edit_user: "Benutzer bearbeiten",
  v_name: "Name",
  v_surname: "Nachname",
  v_edit_team_name: "Teamnamen ändern",
  v_rename_team: "Name ändern",
  v_team_roles: "Rollen",
  v_summary_report: "General Report",
  v_total: "Gesamt",
  v_total_time: "Verfolgte Gesamtzeit",
  v_total_billable: "Bezahlt",
  v_total_not_billable: "Unbezahlt",
  v_export: "Export",
  v_archivate: "Archivieren",
  v_unarchivate: "Unarchivieren",
  v_detailed_proj: "Detailliert",
  v_detailed_export: "Ausführlicher Bericht",
  v_simple_export: "Kurzbericht",
  v_user: "Benutzer",
  v_select_all: "Alles auswählen",
  v_select_none: "Auswahl entfernen",
  v_project_name: "Projektname",
  v_issue: "Aufgabe",
  v_sum_tasks: "Aufgaben",
  v_sum_time: "Gesamtzeit",
  v_user_name: "Benutzername",
  v_add_your_login: "Benutzername eingeben",
  v_add_your_email: "E-Mail eingeben",
  v_add_your_task_name: "Aufgabenname eingeben",
  v_find: "Suche",
  v_back: "Zurück",
  v_team_name: "Teamname",
  v_team_access: "Zugriff",
  v_connection_problem: "Internetverbindung verloren",
  v_connection_restored: "Verbindung wiederhergestellt",
  v_all_role: "Alles rollen",
  v_all_access: "Alles zugriff",
  v_all: "Alles",
  v_active: "Gewährt",
  v_not_active: "Verweigert",
  v_today: "Heute",
  v_yesterday: "Gestern",
  v_thisWeek: "Diese Woche",
  v_lastWeek: "Letzte Woche",
  v_thisMonth: "Dieser Monat",
  v_lastMonth: "Letzter Monat",
  v_this_year: "Dieses Jahr",
  v_last_year: "Letztes Jahr",
  v_days_up_to_today: "Tage bis heute",
  v_days_starting_today: "Tage nach heute",
  v_jira_synchronization: "Jira synchronisation",
  v_type: "art",
  v_chart_label_total: "Gesamtstunden nach Datum",
  v_enter_to: "Einloggen in",
  v_to_get_token: "um das API-Token zu erhalten",
  v_login_with: "Einloggen mit",
  v_or: "oder",
  v_or_continue_with: "oder weiter mit",
  v_date_format: "Datumsformat",
  v_first_day_of_week: "Erster Tag der Woche",
  v_time_format: "Zeitformat",
  v_duration_display_format: "Anzeigeformat für die Dauer",
  v_switch_team_to_the: "Team gewechselt zumÎ",
  v_show: "zeigen",
  v_hide: "verstecken",
  v_clients: "Kunden",
  v_client: "Client",
  v_add_new_client: "Neuen Client hinzufügen",
  v_client_name: "Kundenname",
  v_add_client: "Client hinzufügen",
  v_edit_client_name: "Bearbeiten Sie den Client-Namen",
  v_edit_client: "Bearbeiten",
  v_select_client: "Wählen Sie den Client aus",
  v_sync_with_jira_project: "Mit dem Jira-Projekt synchronisieren",
  v_sync_with_jira_project_exist:
    'Das Jira-Projekt ist bereits mit dem Projekt "{{projectName}}" verbunden',
  v_jira_synchronization_problem:
    "Die Tasks aus Jira war nicht synchronisiert. Vergewissern Sie sich, dass verbindung korrekt ist",
  v_jira_synchronization_ok: "Die Tasks war aus Jira synchronisiert",
  v_add_project_name: "Projektnamen hinzufügen",
  v_edit_project_name: "Ändern Sie den Projektnamen",
  v_verify: "Überprüfen",
  v_classic: "Klassisch",
  v_improved: "Verbessert",
  v_decimal: "Dezimal",
  v_short_day: "TT",
  v_short_month: "MM",
  v_short_year: "JJJJ",
  v_empty: "Ist leer",
  v_empty_email: "E-Mail ist erforderlich",
  v_empty_password: "Ein Passwort ist erforderlich",
  v_cofirm_password_required: "Passwortbestätigung ist erforderlich",
  v_add_old_password: "Aktuelles Passwort hinzufügen",
  v_add_new_password: "Gib dein neues Passwort ein",
  v_jira_synchronization_confirm: "Möchten Sie synchronisieren?",
  v_add_member: "Mitglied hinzufügen",
  v_member_dots: "Mitglied...",
  v_combine: "Aufgaben kombinieren",
  v_delete_user: "Benutzer löschen",
  v_delete_user_text:
    "Der Benutzer wird endgültig gelöscht. Alle Befehle und Eingaben gehen verloren. Sind Sie sicher, dass Sie löschen möchten?",
  v_user_deleted: "Benutzer gelöscht",

  v_v_required: "Pflichtfeld",
  v_v_incorect_url: "Falsche URL",
  v_no_report: "Kein Bericht zu zeigen!",
  v_no_entries: "Keine aktuellen Einträge zu zeigen.",
  v_no_entries_sub: "Es ist lange her, dass Sie Ihre Aufgaben verfolgt haben!",
  v_no_projects: "Das aktuelle Team hat keine Projekte für Sie",
  v_no_projects_sub:
    "Bitten Sie Ihren Teamadministrator, Sie zum Projekt hinzuzufügen",
  v_no_clients: "Keine Kunden zu zeigen!",

  v_enter_number: "Nummer",

  v_invoices: "Rechnungen",
  v_cat_not_invoices: "Sie haben noch keine Rechnungen mit diesem Status",
  v_add_new_invoice: "Neue Rechnung",
  v_all_invoices: "Alle Rechnungen",
  v_invoice: "Rechnung",
  v_confirm_payment: "Bestätige Zahlung",
  v_cancel_confirm: "Bestätigung abbrechen",
  v_send_invoice: "Rechnung senden",
  v_from: "Von",
  v_to: "Zu",
  v_amount: "QTY",
  v_rate: "Bewert",
  v_tax: "MwSt",
  v_subtotal: "Zwischensum",
  v_enter_project: "Text",
  v_pic_tax: "MwSt",
  v_payment_confirmation_window_confirm:
    "Möchten Sie die Zahlung wirklich bestätigen?",
  v_payment_confirmation_window_cancel:
    "Möchten Sie die Zahlung wirklich stornieren?",
  v_edit_confirmation_window:
    "Die Rechnung wurde bereits an den Kunden gesendet. Möchten Sie sie wirklich bearbeiten?",
  v_client_no_email: "Die E-Mail-Adresse des Kunden ist nicht ausgefüllt",
  v_letter_send: "Rechnung wurde gesendet",
  v_add_part_of_payment: "Zahlung hinzufügen",
  v_added_payments: "Zahlungen hinzugefügt",
  v_date: "Datum",
  v_currency: "Währung",
  v_outstanding: "Hervorragend",
  v_add_a_payment: "Fügen Sie eine Zahlung hinzu",
  v_sum: "Summe",
  v_add_payment: "Zahlung hinzufügen",
  v_no_zero_error: "Kann nicht Null sein",
  v_no_negative: "Kann nicht negativ sein",
  v_not_less: "Die Summe sollte kleiner oder gleich ausstehend sein",
  v_no_payments: 'Keine Zahlungen. Klicken Sie zum Hinzufügen auf "+"',
  v_no_active_invoices: "Keine aktiven Rechnungen",
  v_no_active_invoices_text:
    "Es wurden keine aktiven Rechnungen im System gefunden. Dies könnte darauf hindeuten, dass alle Rechnungen bezahlt wurden oder dass überhaupt keine Rechnungen vorhanden sind.",
  v_create_new_invoice: "Neue Rechnung Erstellen",

  v_invoice_summary: "Rechnungszusammenfassung",
  v_invoice_reference: "Referenz",
  v_comments: "Bemerkungen",
  v_select_currency: "Währung Wählen",
  v_invoice_number: "Rechnung Nr",
  v_invoice_date: "Rechnungsdatum",
  v_invoice_due: "Fällig",
  v_edit: "Bearbeiten",
  v_clone: "Vervielfältigen",
  v_delete: "Löschen",
  v_download: "Herunterladen",
  v_pic_date: "Bild Datum",
  v_address: "Adresse",
  v_add_sender: "Absender hinzufügen",
  v_send_by_email: "Per E-Mail gesendet",
  v_choose_recipient: "Empfänger auswählen",
  v_select_logo_file:
    "Ziehen Sie Ihr Logo hierher oder wählen Sie eine Datei aus",
  v_send_invoice_placeholder: "platzhalter",
  v_no_overdues: "Keine Überfälligkeiten",
  v_no_total: "Keine ausstehenden Summen",
  v_no_drafts: "Keine Entwürfe",
  v_last_invoices: "Letzte Rechnungen",
  v_clear_filters: "Filter löschen",
  v_filter_all_projects: "Alle Projekte",
  v_filter_all_clients: "Alle Kunden",
  v_filter_all_invoices: "Alle Rechnungen",
  v_filter_active: "Aktiv",
  v_filter_archived: "Archiviert",
  v_filter_draft: "Entwurf",
  v_filter_awaiting: "Warten",
  v_filter_reviewed: "Bewertet",
  v_filter_paid: "Bezahlt",
  v_filter_overdue: "Überfällig",
  v_grand_total: "Gesamtsumme übrig:",
  v_changing_status: "Ändern des Status am",

  v_will_generate: "Wird nach dem erstellen generiert",
  v_save: "Sparen",
  v_overdue: "Überfällig",
  v_total_outstanding: "Absolut aussergewöhnlich",
  v_draft: "Entwurf",
  v_paid: "Bezahlt",
  v_paid_full: "Voll bezahlt",
  v_paid_zero: "Keine Zahlungen",
  v_reviewed: "Überprüft. Status ändern?",
  v_overdue_tip: "Überfällig. Status ändern?",
  v_draft_tip: "Entwurf. Status ändern?",
  v_paid_tip: "Bezahlt. Status ändern?",

  v_delete_member: "Mitglied löschen",
  v_clone_invoice: "Ihre Rechnung wurde geklont",
  v_send_payment: "Rechnung senden",
  v_no_invoices: "Keine Rechnungen zu zeigen",
  v_owner: "Inhaber",
  v_admin: "Administratorin",
  v_manager: "Rechnungsmanager",
  v_hr_manager: "Personalleiter",
  v_proj_manager: "Projektleiter",
  v_recruiter: "Anwerber",
  v_member: "Mitglied",
  v_roles: "Rollen",
  v_country: "Land",
  v_city: "Die Stadt",
  v_state: "Zustand",
  v_zip: "PLZ",
  v_address_lowC: "adresse",
  e_mail: "E-Mail",
  v_billing_code_error: "Ungültige Postleitzahl",

  v_enter_text: "Text eingeben",
  v_technologies: "Technologie",
  v_add_technology: "Hinzufügen",
  v_company_name: "Name der Firma",

  client_was_deleted: "Client wurde gelöscht",
  client_was_edited: "Client wurde bearbeitet",
  client_was_created: "Client wurde erstellt",
  client_dont_was_deleted:
    "Sie können den Client nicht löschen, weil es hat feste Konten. Bitte löschen Sie die damit verbundenen Konten und versuchen Sie es erneut!",

  v_select_logo_file_bigger:
    "Ziehen Sie das Foto hierher oder wählen Sie eine Datei aus",
  v_zip_code: "Zip",
  v_enter_country: "Land eingeben",

  v_street: "Straße",
  v_enter_city: "Stadt betreten",
  v_enter_language: "Sprache wählen",
  v_delete_client: "Client löschen",
  v_enter_state: "Bereich eingeben",
  company_name: "Name der Firma",
  full_name: "Vollständiger name",

  select_country: "Land auswählen",

  v_edit_client_title: "Client bearbeiten",
  no_valid_number: "Telefonnummer ist ungültig",
  no_valid_zip: "Ungültige Postleitzahl",
  no_valid_user_name:
    "Das Feld Vollständiger name darf keine Leerzeichen enthalten",
  no_valid_number_mask: "+XXXXXXXXXXXX",
  v_fill_fields: "Bitte alle notwendigen Felder ausfüllen",
  v_fill_fields_company_name:
    "Bitte geben Sie den Firmennamen in das Absenderfeld ein",
  v_tags: "Stichworte",
  v_tags_tooltip: "Fähigkeiten, technologien usw.",
  v_cv: "Benutzer-Lebenslauf",
  v_edit_sender: "Absender bearbeiten",
  v_copy: "Kopieren",
  v_delete_invoice: "Rechnung Löschen",
  v_prompt: "Möchten Sie die Rechnung wirklich löschen?",
  v_confirm: "Bestätigen",
  v_invoice_number_exist: "Rechnungsnummer vorhanden",
  v_awaiting: "Warten. Status ändern?",
  v_price: "Summe",
  v_status: "Status",
  v_physical_address: "Physikalische Adresse",
  v_error_company_name: "Füllen Sie das Feld aus - Firmenname",
  v_time_date: "Zeit und datum",
  v_err_technology_exist: "Tag vorhanden",

  v_search: "Suche",
  v_reset: "Zurücksetzen",
  v_sync_all: "Alle synchronisieren",
  v_sync_success: "Erfolgreiche Synchronisierung",
  v_result: "Ergebnisse",

  v_support: "Unterstützung",
  v_tell_us: "Erzählen Sie uns von Ihrem Problem",
  v_type_message: "Type message ...",
  v_min: "Muss mindestens 8 Zeichen lang sein",
  v_max: "Muss weniger als 500 Zeichen lang sein",
  v_message_error: "Fehler: Der Buchstabe kann nicht leer sein",
  v_message_sent: "Nachricht erfolgreich gesendet",

  v_copy_and_share: "Link kopieren und freigeben",
  v_show_more: "Zeig mehr",
  v_copy_the_link: "Link kopieren",
  v_copy_link: "Link kopieren",
  v_done: "Erledigt",
  v_link_copied: "Link kopiert",
  v_add_a_discount: "Fügen Sie einen Rabatt hinzu",
  v_add_discount: "Fügen Sie einen Rabatt hinzu",
  v_discount: "Rabatt",
  v_discount_subtotal: "der Rechnungssumme",
  v_round_to: "Runden auf:",
  v_report_round: "Dezimalrundung des Berichts",
  v_decimal_report: "Dezimaler Bericht",
  v_minutes: "minuten",

  v_info: {
    timeTracking: "Zeiterfassung",
    resourcePlanning: "Ressourcenplanung",
    reporting: "Berichterstattung",
    realTimeReports: "Echtzeitberichte",
    invoicing: "Fakturierung",
    owner: "Eigentümer",
    admin: "Administrator",
    member: "Mitglied",
    invoiceManager: "Rechnungsmanager",
  },

  v_extra: {
    price: "€5/mo",
    feature1: "+1 kunde",
    feature2: "+1 mannschaft",
    feature3: "+2 projekte",
    star: "*einmalige gelegenheit",
  },

  v_choose: "Wählen Sie Ihren Plan",

  v_coupon_doesnt_exist: "Dieser Gutschein existiert nicht",
  v_coupon_expired:
    "Dieser Gutschein ist abgelaufen und kann nicht angewendet werden",
  v_coupon_used_up:
    "Dieser Coupon ist aufgebraucht und kann nicht eingelöst werden",

  v_rp_history: {
    historyS: "Geschichte",
    historyL: "Geschichte",
    header: {
      when: "Datum ändern",
      who: "Herausgeber",
      type: "Art der Änderung",
    },
    table: {
      created: "Erstellt",
      updated: "Aktualisiert",
      from: "Von: ",
      to: "To: ",
      plan: "Name des Plans",
      period: "Zeitraum des Plans",
      project: "Projektname",
      user: "Rechtsnachfolger",
      description: "Beschreibung",
    },
  },

  v_hr_people: {
    leftBar: "Rekrutierung",
    header: {
      title: "Rekrutierung",
      board: "Wegbeschreibung",
      applications: "Anwendungen",
      vacancies: "Offene Stellen",
      candidates: "Kandidaten",
    },
    candidates_modal: {
      preview: "preview",
      draft: "entwurf",
      edit: "bearbeiten",
      personal: "Persönliche Informationen",
      social: "Soziale Netzwerke",
      start_work: "Verfügbarkeit der Arbeitsaufnahme",
      about: "Über den Bewerber",
      booking: "Buchungsgespräch und Rückmeldungen",
      jobType: "Art der Beschäftigung",
      other: "Sonstiges",
      desire: "Wunschtermin",
      start: "Arbeitsbeginn",
      experience: "Erfahrung",
      cv: "Lebenslauf",
      cvClient: "Lebenslauf für Kunde",
      level: "Niveau",
      salary: "Gehalt",
      hrInt: "Datum des Vorstellungsgesprächs",
      techInt: "Datum des technischen Interviews",
      feedHr: "Rückmeldung HR",
      feedTech: "Feedback TechRückmeldung Tech",
      namePlace: "Name eingeben...",
      surnamePlace: "Nachname eingeben...",
      emailPlace: "Geben Sie E-Mail ein...",
      countryPlace: "Land wählen",
      birthdayPlace: "Wählen Sie das Datum Ihres Geburtstags",
      otherPlace: "Andere Links, usw.",
      desirePlace: "Wunschdatum Arbeit",
      startPlace: "Datum Arbeitsbeginn",
      cvPlace: "Lebenslauf anhängen",
      direction: "Richtung",
      vacancy: "Stellenausschreibung",
      noVacancies: "Keine freien Stellen",
      minSalPlace: "Von",
      maxSalPlace: "Bis",
      hrIntPlace: "Personalgespräch",
      techIntPlace: "Technisches Gespräch",
      feedHrPlace: "Rückmeldung HR eingeben...",
      feedTechPlace: "Typische Rückmeldung tech...",
    },
    directions: {
      no_directions_text: "Es gibt noch keine Richtungen",
      new_direction: "Neue Richtung",
      search_by_name_placeholder: "Suche nach Namen...",
    },
    candidates: {
      new_candidates: "Neue Kandidaten",
      no_candidates_text: "Es gibt noch keine Kandidaten",
      headerTable: {
        name: "Name",
        job_type: "Stellenart",
        country: "Land",
        city: "Stadt",
        level: "Ebene",
        direction: "Richtung",
        salary: "Gehalt:",
        latest_update: "Letzte Aktualisierung",
        creation_date: "Erstellungsdatum",
        junior: "Junior",
        middle: "Mittel",
        senior: "Senior",
        trainee: "Praktikant",
        remote: "Fern",
        hybrid: "Hybrid",
        office: "Büro",
      },
    },
    switcherForVacancies: {
      active: "Active",
      archive: "Archive",
    },
    vacancies: {
      no_vacancies_text: "Es gibt noch keine offenen Stellen",
      new_vacancies: "Neue Stellenangebote",
      vacancies_created: "Freie Stelle geschaffen",
      vacancies_edited: "Stellenausschreibung bearbeitet",
      link_copied: "Link kopiert",
      vacancy_data: "Stellendaten",
      job_application: "Bewerbung",
      select_job_application: "Wählen Sie eine Bewerbung für die Stelle aus",
      vacancy_name: "Stellenbezeichnung",
      company: "Unternehmen",
      type_here: "Hier eingeben...",
      priority: "Priorität",
      choose_status: "Status auswählen",
      choose_priority: "Priorität auswählen",
      direction: "Richtung",
      experience: "Erfahrung",
      level: "Stufe",
      job_description: "Stellenbeschreibung",
      type_text_here: "Hier Text eingeben...",
      number_of_positions: "Anzahl der Positionen",
      type_of_employment: "Art der Anstellung",
      expected_salary: "Erwartetes Gehalt",
      job_location: "Arbeitsort",
      location_optional: "Ort (optional)",
      job_type: "Art der Stelle",
      remote: "Fernarbeit",
      hybrid: "Hybrid",
      office: "Büro",
      foreign_languages: "Fremdsprachen",
      additional_information: "Zusätzliche Informationen",
      link_to_job: "Link zu Stellenangeboten (auf verschiedenen Plattformen)",
      requirements: "Anforderungen",
      nice_to_have: "Ein pluspunkt wäre",
      working_conditions: "Arbeitsbedingungen",
      preview: "Vorschau",
      responsibilities: "Verantwortlichkeiten",
      salary_range: "Gehaltsspanne",
      exit: "Ausgang",
    },
    vacanciesHeaderTable: {
      name: "Name der Stelle",
      status: "Status",
      priorities: "Prioritäten",
      number_of_candidates: "Anzahl der Kandidaten",
      latest_update: "Neuestes Update",
      deadline: "Frist",
      created: "Erstellt",
    },
    new_direction_modal: {
      edit: "Richtungsänderung bearbeiten",
      create: "Richtung erstellen",
      name_dir: "Name der Richtung",
      name_placeholder: "Tippen Sie, um eine Richtung hinzuzufügen...",
      vacancies: "Angehängte freie Stellen",
      vacancies_placeholder: "Auswählen oder hinzufügen",
    },
    applications: {
      no_applications_text: "Es liegen noch keine Bewerbungen vor",
      new_applications: "Neue Anwendungen",
      archive: "Archiv",
      name: "Name",
      typeName: "Name eingeben...",
      direction: "Richtung",
      typeDirection: "Richtung eingeben...",
      level: "Ebene",
      typeLevel: "Ebene eingeben...",
      experience: "Erfahrung",
      typeExperience: "Erfahrung eingeben...",
      salary: "Gehalt",
      from: "Von",
      to: "Bis",
      deadline: "Frist",
      invalidDate: "Ungültiges Datum",
      applicationPriority: "Bewerbungspriorität...",
      country: "Land",
      searchCountry: "Land suchen...",
      selectCountry: "Land auswählen",
      city: "Stadt",
      typeEmployment: "Beschäftigungsart",
      selectEmployment: "Beschäftigungsart auswählen",
      numberPositions: "Anzahl der Positionen",
      typeNumberPositions: "Anzahl der Positionen eingeben",
      project: "Projekt",
      typeProject: "Projektname eingeben",
      languageSkills: "Sprachkenntnisse",
      enterLanguagesSkills: "Sprachkenntnisse eingeben",
      requirements: "Anforderungen",
      enterRequirements: "Anforderungen eingeben",
      comment: "Kommentar",
      enterComment: "Kommentar eingeben",
      successful: "Erfolgreich",
      youHaveSuccessful: "Sie haben erfolgreich eine Anwendung erstellt",
    },
    headerTable: {
      name: "Name",
      vacancies_available: "Offene Stellen",
      priorities: "Prioritäten",
      number_of_candidates: "Anzahl der Bewerber",
      latest_update: "Letzte Aktualisierung",
      deadline: "Deadline",
      name_applications: "Nennen Sie Anwendungen",
      location: "Standort",
      date_of_creation: "Erstelldatum",
    },
    prioritiesSelectors: {
      high: "Hohe",
      medium: "Mittel",
      low: "Niedrig",
    },
    deadlineSelectors: {
      newest: "Neuestes",
      oldest: "Älteste",
    },
    statusSelectors: {
      open: "Offen",
      closed: "Geschlossen",
      rejected: "Abgelehnt",
      onHold: "In der Warteschleife",
    },
  },

  v_subscription: {
    extraFeatures: "Zusatzfunktionen kaufen",
    leftBar: "Abonnementen",
    header: {
      all: "Alle Pläne",
      overview: "Überblick",
      invAndPaym: "Rechnungen und Zahlungen",
    },
    switcher: {
      trial: "-tägige kostenlose testversion inklusive",
      month: "Monat",
      year: "Jährlich",
      discount: "Sparen Sie bis zu 20 %",
    },
    plan: {
      btnCurrent: "Derzeitiger Plan",
      btnUpgrade: "Upgraden",
      btnDowngarde: "Herabstufung",
      mo: "mo pro Benutzer",
      yr: "mo jährlich abgerechnet",
      btnUpgradeNow: "Jetzt upgraden",
    },
    free: {
      users: "Unbegrenzte Benutzer",
      features: "Alle Funktionen enthalten",
      notifications: "Automatische Benachrichtigungen enthalten",
      roles: "Rollenverwaltung inklusive",
      projects: "2 Projekte",
      history: "35 Tage Datenhistorie",
      clients: "1 Kunde",
      teams: "1 Mannschaft",
      wobbly: "Wobbly Time App enthalten",
      integrations: "Integrationen enthalten",
      storage: "Datenspeicher 1 GB",
    },
    basic: {
      users: "Unbegrenzte Benutzer",
      features: "Alle Funktionen enthalten",
      notifications: "Automatische Benachrichtigungen enthalten",
      roles: "Rollenverwaltung inklusive",
      projects: "10 Projekte",
      history: "365 Tage Datenhistorie",
      clients: "10 Kunde",
      teams: "10 Mannschaft",
      wobbly: "Wobbly Time App enthalten",
      integrations: "Integrationen enthalten",
      storage: "Unbegrenzter Datenspeicher",
    },
    donut: {
      teams: "15 Teams included",
      tracks: "Unbegrenzte Zeiterfassung",
      projects: "15 Aktive Projekte",
      history: "365 Tage Datenhistorie",
      clients: "15 Kunden",
      invoices: "Rechnungsstellung inklusive",
      integrations: "Integrationen inklusive",
      storage: "10 GB Datenspeicher",
    },
    pro: {
      teams: "Unbegrenzte teams",
      users: "Unbegrenzte Benutzer",
      features: "Alle Funktionen enthalten",
      notifications: "Automatische Benachrichtigungen enthalten",
      roles: "Rollenverwaltung inklusive",
      projects: "Unbegrenzte projekte",
      history: "Unbegrenzter Datenverlauf",
      clients: "Unbegrenzte kunden",
      wobbly: "Wobbly Time App enthalten",
      integrations: "Integrationen enthalten",
      storage: "Unbegrenzter Datenspeicher",
    },
    billingModalDowngrade: {
      title: "Abonnementübersicht",
      currentPlan: "Ihr aktueller Plan",
      pait: "bezahlt um",
      text: "Plan wird nach Ablauf Ihres aktuellen Abonnements auf einen neuen Plan umgestellt",
      button: "Fortfahren",
    },
    billingModal: {
      next: "Nächster",
      startExploring: "Beginnen Sie mit der Erkundung",
      trialHasNotExpired:
        "Sie haben die 'Probezeit' noch nicht vollständig aufgebraucht, sie geht verloren, wenn Sie fortfahren. Möchten Sie jetzt einen Kauf tätigen?",
      secondStep: {
        title: "Abonnementübersicht",
        plan: "Planen",
        firstCharge: "Erste Ladung auf",
        billingInfo: "Rechnungsinformation",
        changeBilling: "Wechseln",
        paymentInfo: "Zahlungsinfo",
        changePayment: "Wechseln",
      },
      congratsStep: {
        congrats: "Herzliche Glückwünsche!",
        info: "Sie können jetzt alle Funktionen verwenden, einschließlich",
      },
      noData: "Bitte geben Sie die Zahlungsdaten ein",
    },
    billingInfoEditModal: {
      title: "Rechnungsinformation",
    },
    overview: {
      trialWarning:
        "Bitte fügen Sie die Zahlungsdetails hinzu, um {{planName}} nach dem Testzeitraum weiter zu verwenden.",
      trialAddPaymentInfo: "Zahlungsinformationen hinzufügen",
      updateBillingInfo: "Aktualisieren",
    },
    invoicesAndPayments: {
      title: "Abrechnungsverlauf",
    },
  },
  v_plan_details: {
    subscriptionExpired:
      "Ihr Plan wird nach Ablauf Ihres aktuellen Abonnements auf 'Free' geändert   ",
    subscriptionExpiredTrial:
      "Ihr Plan wird nach Ablauf Ihres aktuellen Testabonnements auf 'Free' geändert   ",
    status: "Abonnementstatus",
    subscriptionRenewingOn: "Ihr Abonnement wird erneuert am",
    subscriptionRenewingOnFree: "Free",
    currentPlan: "Aktueller Plan",
    costUserMonth: "Kosten Benutzer/Monat",
    users: "Benutzer",
    daysLeftOnTrial: "Tage vor Gericht",
  },
  v_billing_info: {
    title: "Abrechnungsinfo",
    changeBillingInfo: "Aktualisieren",
  },
  v_billing_info_details: {
    country: "Land",
    city: "Stadt",
    postalCode: "Postleitzahl",
    emailAddress: "E-mail",
    street: "Straße",
    houseNumber: "Hausnummer",
    firstName: "Vorname",
    lastName: "Nachname",
    companyName: "Firmenname",
    phoneNumber: "Telefonnummer",
  },
  v_payment_info: {
    title: "Spielinfo",
    addPaymentInfo: "Information hinzufügen",
    changePaymentInfo: "Info ändern",
  },
  v_payment_method_details: {
    cardDeclined: "Ihre Karte wird nicht unterstützt!",
    paymentMethod: "Zahlungsmethode",
    noPaymentMethod: "Keine Zahlungsmethode verfügbar",
    labels: {
      country: "Land",
      expiryMonth: "Ablaufmonat",
      expityYear: "Ablaufjahr",
      cardHolder: "Karteninhaber",
      masked: "Karte PAN",
      holder: "Halter(in)",
      bic: "BIC",
      iban: "IBAN",
    },
    methods: {
      CreditCard: "Kreditkarte",
      BankAccount: "Bankkonto",
    },
  },
  v_billing_history_table: {
    date: "Datum",
    description: "Beschreibung",
    amount: "Menge",
    invoice: "Rechnung",
  },
  v_payment_info_modal: {
    next: "Nächster",
  },
  v_payment_info_state: {
    updateSuccess: "Zahlungsinformationen wurden erfolgreich aktualisiert",
    updateError: "Fehler beim Aktualisieren der Zahlungsinformationen",
  },
  v_billing_info_form: {
    country: { label: "Land", placeholder: "Land auswählen" },
    city: { label: "Stadt", placeholder: "Stadt betreten" },
    postalCode: {
      label: "Postleitzahl",
      placeholder: "Geben Sie die Postleitzahl ein",
    },
    emailAddress: { label: "E-mail", placeholder: "Email eingeben" },
    street: { label: "Straße", placeholder: "Straße betreten" },
    houseNumber: { label: "Hausnummer", placeholder: "Hausnummer eingeben" },
    firstName: { label: "Vorname", placeholder: "Vorname eingeben" },
    lastName: { label: "Nachname", placeholder: "Nachname eingeben" },
    companyName: { label: "Firmenname", placeholder: "Firmenname eingeben" },
    phoneNumber: {
      label: "Telefonnummer",
      placeholder: "Telefonnummer eingeben",
    },
  },
  v_billing_info_state: {
    updateSuccess: "Rechnungsinformationen wurden erfolgreich aktualisiert",
    updateError: "Fehler beim Aktualisieren der Abrechnungsinformationen",
  },
  v_upgrade_pay_state: {
    updateSuccess: "Das Abonnement wurde erfolgreich aktualisiert",
    updateError: "Fehler beim Abonnement-Upgrade",
  },
  v_page_access_denied:
    "Sie haben nicht genügend Rechte, um diese Seite anzuzeigen",
  v_page_access_request_owner:
    "Bitten Sie den Teambesitzer, Ihnen Zugriff zu gewähren",
  v_page_invoice_deleted: "Diese Rechnung wurde gelöscht",
  v_project_not_available: "Das Projekt für diese Aufgabe ist nicht verfügbar",
  v_upgrade_projects_title:
    "Aktualisieren Sie, um aktivere Projekte zu erstellen",
  v_upgrade_projects_description:
    "Leider haben Sie die maximale Anzahl aktiver Projekte in Ihrem Plan erreicht.",
  v_upgrade_teams_title: "Aktualisieren Sie, um mehr Teams zu erstellen",
  v_upgrade_teams_description:
    "Leider haben Sie die maximale Anzahl von Teams in Ihrem Plan erreicht.",
  v_upgrade_button: "Upgrade-Plan",
  v_upgrade_sidebar_title_trial_banner: "Sie verwenden die Testversion",
  v_upgrade_sidebar_title_trial_banner_coupon: "Letzter Tag der Testversion",
  v_upgrade_sidebar_text_trial_banner: "Tage übrig",
  v_upgrade_sidebar_text_trial_banner_few_days: "Tag übrig",
  v_last_trial_day: "Letzter Probetag",
  v_last_trial_day_with_coupon: "Erweitern Sie Ihren Plan mit einem Coupon",
  v_upgrade_sidebar_title: "Upgrade-Plan für mehr Funktionen.",
  v_upgrade_sidebar_button: "Verbessern",
  v_apply_sidebar_button: "Code anwenden",
  v_upgrade_clients_title: "Aktualisieren Sie, um mehr Kunden zu erstellen",
  v_upgrade_clients_description:
    "Leider haben Sie die maximale Anzahl von Kunden in Ihrem Plan erreicht.",
  v_upgrade_admin_note:
    "Wenn Sie Ihren Plan aktualisieren möchten, wenden Sie sich bitte an Ihren Teambesitzer.",
  v_no_results: "Geen resultaten om te laten zien",
  v_project_members: "Projectleden",
  v_upgrade_label: "Aktualisieren Sie Ihren Plan",
  v_resource_planning: "Ressourcenplanung",
  v_collapse: "Aufrollen",
  v_expand: "Entfalten",
  v_add_plan: "Tarif Hinzufügen",
  v_delete_plan: "Plan Löschen",
  v_delete_plan_confirm: "Möchten Sie Plan wirklich löschen?",
  v_edit_plan_confirm: "Sind Sie sicher, dass Sie Plan bearbeiten wollen?",
  v_no_recorded_team_time: "Keine aufgezeichnete Zeit im Team",
  v_not_active_user: "Nicht aktiv",
  v_real_time_report: "Echtzeit-Bericht",
  v_rt_reports_plug: {
    title:
      "Verwalten Sie die Zeitpläne Ihrer Mitarbeiter in Echtzeit mit einer Arbeitsplanungssoftware.",
    description:
      "Gewinnen Sie Einblicke in den Projektstatus und die Arbeitsbelastung - Echtzeitberichte für das effiziente Aufgaben- und Projektmanagement.",
  },
  v_rp_plug: {
    title: "Vereinfachen Sie die Teamplanung mit Ressourcenplanung",
    description:
      "Verfolgen Sie die Gesamtarbeitslast für eine effektive Ressourcenzuweisung. Benötigen Sie mehr Funktionen?",
  },
  v_see_plans: "Siehe Pläne",
  v_tooltip_upgrade:
    "Diese Funktion ist verfügbar, wenn Sie Ihr Paket aktualisieren",
  v_real_time: "Echtzeit",
  v_activity_view: "5-Tage-Aktivitätsansicht",
  v_resource_planning_page: {
    v_edit_plan_title: "Plan bearbeiten",
    v_add_plan_title: "Tarif hinzufügen",
    v_teams: "Mannschaften",
    v_members: "Teilnehmer",
    v_type_task_name: "Geben Sie den Aufgabennamen ein...",
    v_projects: "Projekte",
    v_dates: "Termine",
    v_from: "Von",
    v_to: "Bis",
    v_select_or_add: "Auswählen oder Hinzufügen",
    v_description: "Beschreibung",
    v_type_description: "Geben Sie ein, um eine Beschreibung hinzuzufügen...",
    v_cancel: "Abbrechen",
    v_show_all_projects: "Alle Projekte anzeigen",
    v_show_all_members: "Alle Mitglieder anzeigen",
    v_show_all_tags: "Alle Tags anzeigen",
    v_birthday_date: "Geburtstag",
    v_join_date: "Join date",
    v_vacation_reset: "Urlaub zurückgesetzt",
    v_country: "Land",
    v_local_time: "Ortszeit",
    v_workload: "Arbeitsbelastung",
    v_day_off: "Freier Tag",
    v_total_left: "Gesamt/Links",
    v_sick_leave: "Krankenstand",
    v_vacation: "Urlaub",
    v_day_off_with_work_off: "Unbezahlter Urlaub",
    v_tags: "Stichworte",
    v_success_created_plan: "Plan erstellt",
    v_success_edited_plan: "Plan bearbeitet",
    v_success_deleted_plan: "Plan gelöscht",
    v_filter_no_results: "Keine treffer gefunden",
    v_error_start_date: "Die Startzeit des Plans ist größer als sein Ende",
    v_error_set_wright_date:
      "Falscher Datumsbereich, richtigen Datumsbereich festlegen",
    v_last_seen: "Zuletzt gesehen",
    v_user_profile_error: "Etwas ist schief gelaufen",
    v_success_edited_profile: "Profil wurde bearbeitet",
    v_user_profile: "Benutzerprofil",
    v_edit_user_profile: "Profil Bearbeiten",
    v_error_user_is_not_exist_in_project:
      "Benutzer wurde diesem Projekt nicht hinzugefügt",
    v_error_user_does_not_exist_in_this_project:
      "Der Benutzer ist in diesem Projekt nicht vorhanden",
    v_error_user_not_admin_owner:
      "Nur Owner/Admin kann einen Plan für einen anderen Benutzer erstellen",
    v_error_you_can_not_change_holidays:
      "Sie können das Datum von Feiertagen nicht ändern",
    v_error_delete_plan_in_the_past:
      "Plan in der Vergangenheit kann nicht gelöscht werden",
  },
  v_left_bar: {
    time: "Zeit",
    manage: "verwalten",
    invoice: "Rechnung",
    people: "Menschen",
    other: "andere",
  },
  v_days: "tage",
  v_members: "Teilnehmer",
  v_filters: "Filter",
  v_clear_all: "Clear all",
  v_show_by: "Zeigen Sie vorbei:",
  v_day: "Tag",
  v_week: "Woche",
  v_month: "Monat",
  v_year: "Jahr",
  v_assignee: "Ernennung",
  v_plan_info: "Planen Sie Informationen",
  v_all_members: "Alle Mitglieder",
  v_select_projects: "Projekte auswählen...",
  v_notifications: "Notifikationen",
  v_not_notifications: "Sie haben keine Benachrichtigungen",
  v_release_notes: {
    v_title: "Release Notes",
    v_whats_new: "What's New:",
    v_improved: "Improved/Fixed:",
    v_version: "Version",
    v_versions: {
      "1.7.26": {
        v_new: [
          "Work on bugs/improvements.",
          "Subscription payment model has been changed, now the amount depends on the number of users in the team.",
          "The functionality and benefits of subscriptions have been changed.",
          "Added more detailed notifications in case of synchronization error with Jira.",
          "Added the ability to control Workload on the 'Resource Planning' page. View available load and view and edit actual load.",
          "Bench functionality has been added. Possibility to filter out users with no plans on the 'Resource Planning' page.",
        ],
        v_improved: [
          "Fixed a visual error on the login page when the sidebar was visible.",
        ],
      },
      "1.6.25": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Team' and 'Reports'",
          "New sidebar design implemented",
          "Added the possibility to see the total time for billable and not billable tracks on the 'Reports' page",
          "Added the possibility of inviting several users to the Team at a time on the 'Team' page",
        ],
        v_improved: [
          "Fixed bug with creating a plan for the wrong date that was selected and inability to edit plan daterange on the 'Resource Planning' page",
          "Visual improvements on the 'Resource Planning' page",
          "Fixed a bug with updating the status of an invoice when it is assigned the 'Awaiting' status on the 'Invoices' page",
          "Visual improvements on the 'Invoices' page",
        ],
      },
      "1.6.24": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Teams', 'User Profile', 'Reports', 'Projects', 'Clients' and 'Timer'",
        ],
        v_improved: [
          "Track no longer stops when switching Teams",
          "Fixed a bug when Owner could not remove Admin from the project on the 'Projects' page",
          "Removed the field of synch with Jira when creating and editing a project on the 'Projects' page",
          "Visual and functional improvements on the 'Projects' page",
          "Fixed a bug when Invoice Manager saw the report only by itself on the 'Reports' page",
          "Fixed visual bug with incorrect highlighting of daterange in the calendar on the 'Reports' page",
          "Fixed a bug with incorrect display of birth date calendar on the 'User Profile' page",
          "Fixed a bug where user tags were lost when changing role on the 'Team' page",
          "Visually improved user info window on the 'Resource Planning' page",
          "Improved display of the tooltip with the plan description when there are few users on the 'Resource Planning' page",
          "Improved work of the tooltip with plan description on the 'Resource Planning' page",
          "Changed the order of action for copying a link to an invoice on the 'Invoices' page",
          "Fixed bug with infinite loading of the list of Teams when viewing a sent invoice on the 'Invoices' page",
          "Visual improvements when filling out an invoice on the 'Invoices' page",
        ],
      },
      "1.6.23": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the possibility to purchase a subscription, for which the trial is valid",
        ],
        v_improved: [
          "Fixed a bug where user's name in filters was duplicated on the 'Report' page",
          "Fixed a bug where PM, HR manager and Invoice managers could not use cross-timed reports on the 'Report' page",
          "Fixed a bug where the color of the tooltip did not change when switching the theme on the 'Resource Planning' page",
          "Fixed a bug where PM and HR manager could not invite new users to the team on the 'Team' page",
        ],
      },
      "1.6.22": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to see reports on users with denied access",
        ],
        v_improved: [
          "Improved filter logic on the 'Reports' page",
          "Fixed several bugs on the 'Reports' page",
          "Reports are updated immediately after a Team change",
          "Fixed several bugs on the 'Project' page",
          "Fixed search on 'Client' and 'Project' pages",
          "Improvements have been made to the change history on the 'Resource Planning' page",
        ],
      },
      "1.6.21": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to set tracks for the future in 'Timer' page",
          "Added automatic trimming of the estimate when copying a Jira-synchronized task",
          "Added history of plan changes in 'Resource Planning' page",
        ],
        v_improved: [
          "Improved work of the reports page",
          "Fixed a bug where the report was exported incorrectly when cross-team was enabled in 'Reports' page",
          "Several visual improvemets in sidebar",
          "Several visual improvemets in 'Resource Planning' page",
        ],
      },
      "1.6.20": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Roles', 'Resource Planning', 'Timer', 'Projects' and 'E-mail'",
        ],
        v_improved: [
          "Added mass synch with Jira in 'Timer' page",
          "Role-based accesses have been updated",
          "Fixed minor errors with roles",
          "Fixed bug with drag and drop plan by Recruiter and Memebers in 'Resource Planning' page",
          "Fixed a bug where the recruiter and the memeber only saw the plans of the projects to which they were added 'Resource Planning' page",
          "Fixed minor errors in 'Resource Planning' page",
          "Added weekly report emails for new roles",
          "Weekly reports updated, time period added",
          "Fixed a bug with deleting a user from the project in 'Projects' page",
        ],
      },
      "1.6.19": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Team', ''Resource Planning' and 'Invoices'",
          "Added connection with support",
        ],
        v_improved: [
          "User roles have been updated, added new roles in 'Team' page",
          "Added tooltips for collapsed sidebar",
          "Corrected the display of holidays in 'Resource Planning' page",
          "Visual bugs fixed in 'Reports' page",
          "Visual bugs fixed in 'Invoices' page",
        ],
      },
      "1.6.18": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Register page', 'Projects' and 'Invoices'",
          "Added the ability to log in with Google",
        ],
        v_improved: [
          "Several visual improvemets in 'Register page'",
          "Several visual improvemets in 'Reports' page",
          "Fixed a bug when large reports hang up in 'Repors' page",
          "Updated weekly e-mail reports on time tracked",
          "Fixed a bug when a user was not deleted from the project  in 'Projects' page",
          "Several visual improvemets in 'Free invoice generator' page",
          "Several visual improvemets in 'Invoices' page",
        ],
      },
      "1.6.17": {
        v_new: [
          "Work on bugs/improvements in the 'Subscriptions', 'Reports', 'Clients', 'Email' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Several visual improvemets in 'Subscriptions' page",
          "Several visual improvemets in 'Reports' page",
          "Several visual improvemets in 'Clients' page",
          "Several visual improvemets in 'Resource Planning' page",
          "New weekly e-mail reports on time tracked",
        ],
      },
      "1.6.16": {
        v_new: [
          "Work on bugs/improvements in the 'Invoice' and 'Resource Planning'",
        ],
        v_improved: [
          "Several minor improvements in the 'Resource Planning' section",
          "Added ability to change invoice status in the 'Invoices' section",
        ],
      },
      "1.6.15": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Projects' and  'Subscriptions'",
        ],
        v_improved: [
          "Improved calendar in the Add Plan window in the 'Resource Planning' section",
          "Fixed calendar in the User Profile window for web-mobile in the 'Resource Planning' section",
          "Several minor improvements for web and web-mobile in the 'Resource Planning' section",
          "Visual and logical update for coupons in the 'Subscription' section",
          "Several minor improvements in the 'Subscription' section",
          "Fixed 'Team' filter when Cross-team is active in the 'Reports' section",
          "Fixed a bug when interacting with the report on a user not from the current team in the 'Reports' section",
          "Several minor improvements in the 'Reports' section",
          "Fixed 'Total outstanding' in the 'Invoices' section",
          "Fixed tool-tips in the 'Invoices' section",
          "Fixed Free Invoice Generator",
          "Fixed detailed report in the 'Projects' section",
        ],
      },
      "1.6.14": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Subscriptions' and E-mail sections",
        ],
        v_improved: [
          "Fixed a bug when filters were dropping when scrolling to a new month in the 'Resource Planning' section",
          "Fixed colors of projects in the calendar in the 'Resource Planning' section",
          "The selected filter is moved up in the 'Resource Planning' section",
          "Fixed an error when filters were dropping when deleting a plan in the 'Resource Planning' section",
          "Fixed project colors in filters in the 'Resource Planning' section",
          "Visual improvemetns of 'Resource Planning' page in the 'Resource Planning' section",
          "Fixed photo picker in invoices in the 'Invoices' section",
          "Visual improvemetns of 'Invoices' page for web-mobile",
          "Fixed several minor visual/performance bugs in the 'Invoices' section",
          "Coupons are now saved from the beginning to the end of the trial in the 'Subscriptions' section",
          "The full amount is now visible when you buy a year's subscription in the 'Subscriptions' section",
          "Fixed a bug when users' E-mails were displayed instead of their names in real-time reports when cross-team reports is active in the 'Reports' section",
          "A filter by team has been added to the cross-team reports in the 'Reports' section",
          "Improved web-mobile version in the 'Reports' section",
          "Improved Real-Time Reports page in the 'Reports' section",
          "Fixed several minor visual/performance bugs in the 'Reports' section",
          "Fixed an error that prevented emails from being sent correctly in the 'E-mails' section",
          "Visual improvements/performance in other sections",
        ],
      },
      "1.6.13": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning' and E-mail sections",
        ],
        v_improved: [
          "Fixes filters for summary cross-team reports on the 'Reports'",
          "Reports now sorted by time on the 'Reports'",
          "Added count of users in selected status by online on the 'Reports'",
          "Changed URL for real-time reports on the 'Reports'",
          "Visual changes 'Add plan' window on the 'Resource Planning'",
          "Improved displaying plan name by plan duration in 'Resource Planning'",
          "The plan now has a default duration of 24 hours on the selected day in 'Resource Planning'",
          "Improved 'Join date' window in User Profile window in 'Resource Planning'",
          "Visual improvements in User Profile window in 'Resource Planning'",
          "Visual improvements 'Resource Planning' page",
          "Invoices page reworked when there no invoices",
          "Visual improvements 'Invoices' page",
          "Fractional numbers are available in the QTY and rate fields in 'Invoices'",
          "Fixed bug when trial didn't start",
          "Added errors when using invalid coupons",
          "Added possibility to unsubscribe from e-mails",
          "Several visual improvements",
        ],
      },
      "1.6.12": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "The possibility of using promotional codes has been added",
        ],
        v_improved: [
          "Visual improvements to the loaded Invoice",
          "Fixed a bug when the invoice was not created due to the time zone",
          "Now plan can be created without name in 'Resource Planning'",
          "Bug fixed, cross-team report showed the wrong time, tracked by the user in 'Reports'",
          "Added the ability to enter fractional numbers in QTY and Rate in 'Invoice'",
          "Visual improvements to filters on the 'Resource Planning'",
          "Fixed several minor visual/performance bugs",
        ],
      },
      "1.6.11": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "Added Cross-team Reports",
        ],
        v_improved: [
          "Fixed sidebar opening bug when switching to Subscriptions page",
          "Visual improvements to the running timer",
          "Fixed online filters in real-time reports 'Reports' page",
          "Shows the number of the week in the year when the show buy week on the 'Resource Planning' page",
          "Fixed header on the 'Resource Planning' page",
          "Fixed a bug where the created plan was not displayed on the 'Resource Planning' page",
          "Added the ability to start the trial period without entering a card",
          "Several visual improvements",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.10": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Fixed visual bugs in invoice creating page",
          "Fixed visual bugs on 'Free Invoice Generator' page",
          "'Grand total' replaced by 'Grand total left', displays the total amount expected to be paid on 'Invoices' page",
          "The unpaid part is displayed for each invoice now",
          "Fixed visual bugs in 'Resource Planning'",
          "Added the ability to show calendar by year in 'Resource Planning'",
          "Visual improvements to filters on the 'Resource Planning' page",
          "Visual improvements to filters on the 'Reports' page",
          "Real-time Reports show tags instead of roles",
          "Fixed translation in 'Subscriptions'",
          "Fixed timer display when sidebar is hidden",
          "Improved emails",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.9": {
        v_new: [
          "New Subscriptions",
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Increased the number of characters displayed in the task name in 'Reports'",
          "Fixed location of 'summary / real-time' buttons in 'Reports'",
          "Added the ability to hide to hide side bar",
          "Now the header in the 'Resource Planning' is hidden when scrolling down",
          "Filters in 'Resource Planning' are moved to separate buttons",
          "Improved display of plan names in 'Resource Planning'",
          "When hovering on the plan in 'Resource Planning', you can see the start date, end date and duration of the plan",
          "Added the ability to hide usernames in 'Resource Planning'",
          "Fixed slider in 'Resource Planning'",
          "The space is automatically removed in the username if it is in front of the name in 'User profile'",
          "User CV is not displayed when editing a user through 'My team'",
          "Fixed bugs when moving and creating plans in 'Resource Planning'",
          "Week numbering bug fixed in 'Resource Planning'",
          "Fixed a bug with the username when adding it to the team in Notifications",
          "Notifications are now displayed from newest to oldest",
          "Fixed several minor visual bugs",
        ],
      },
      "1.5.9": {
        v_new: [
          "Work on bugs/improvements in the Resource Planning section.",
          "Release versions removed from notification bell. Useful app notifications will now be displayed there.",
        ],
        v_improved: [
          "Added an error when dragging a plan to a user who is not in the project, but to whom the plan was transferred in 'Resource Planning'",
          "Fixed pop-up window when hovering over the plan with the cursor. The window is now displayed under the cursor.",
          "Fixed display of users with a long name in the 'Resource Planning' section",
          "Improved readability of texts in the 'Resource Planning' section on user plans",
          "Changed the limit on the number of characters in the username in the 'Profile Settings' of the user. The user can enter 100 characters",
          "Fixed display of username with long name in user plan under Resource Planning",
          "Added sticky header in resource scheduling",
        ],
      },
      "1.5.8": {
        v_new: [
          "Several new messages have been added to the e-mail, which will now be sent and will help the user working with Wobbly.",
          "Added weekly e-mail reports for owner/admin about members' time tracked in favorites projects",
          "Added weekly e-mail reports for members on time tracked",
        ],
        v_improved: [
          "Members now have the ability to switch Billable/Non-billable in Reports for their tracked tasks",
          "Owner and Admin now have the ability to switch Billable/Non-billable in Reports for the tracked tasks of all users",
          "Only Owner and Admin now can edit Member's User profile in Resource planning",
          "Added the ability to select a year when setting the date of birth in Profile settings",
          "Added the ability to delete a plan in the past in Resource planning",
        ],
      },
      "1.5.7": {
        v_new: [
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed error editing User Profile card by Admin and Owner. The edit button was missing for these roles.",
          "Fixed filters in the 'Resource Planning' section. The filter did not display any results.",
          "Fixed display of user statuses in the 'Resource planning' section. The user's online status was displayed if the user's timer was active in another team.",
          "Fixed navigation arrows for switching dates in the Resource Planning section. The arrows moved by dates within two weeks/months.",
          "Improved visual in Resource Planning section.",
          "Improved work of filters in the 'Reports' section, 'Real-time' page.",
          "Fixed a bug in the 'Reports' section, 'Summary' page. A report with numerous projects and users was not displayed.",
          "Improved/simplified the creation of a per-user plan in the Resource Planning section. When creating a plan for a team member, only those projects on which the user is added are displayed.",
          "Fixed the result of displaying users in the 'Reports' section, 'Real-time' page. Users with status 'Recent' was not displayed in the filter if it was with an active timer in another team.",
        ],
      },
      "1.5.6": {
        v_new: [
          "Added the ability to delete a user account.",
          "Slightly redesigned interaction with the 'Reports' section. 'Reports' are divided into 'Summary' and 'Real-time' pages.",
          "Added some new error messages that the user might encounter.",
        ],
        v_improved: [
          "Improved interaction with the calendar when creating/editing a plan in the 'Resource Planning' section.",
          "Fixed login error messages. Some errors were not displayed.",
          "Fixed time division in the 'Timer' section after 00.00. Tasks that were stopped manually after 00.00 were saved in one time period.",
          "Fixed a bug with displaying project colors in the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.4.5": {
        v_new: [
          "Added 'Real-time report' to the 'Reports' section. Now Owner and Admin can view which task the user is currently recording or the last recorded task for five days.",
          "Added several new colors for projects.",
        ],
        v_improved: [
          "Fixed a bug with changing teams. Now, when changing the team on one browser, the user's team will change on the other browser.",
          "Improved the speed of the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.3.4": {
        v_new: ["Bug fixes, visual fixes, application speed improvements."],
        v_improved: [
          "Fixed a few minor visual bugs.",
          "The light and dark switch has been moved to the app logo from the “Profile Settings” section. Also, now visually changed from a switch to a button.",
          "Improved speed of processing requests and responses in the Timer, Reports, and Projects sections.",
          "Improved the visual components of the “Light” and “Dark” themes.",
          "Added the ability to download a 1-minute rounded report in 'Decimal report'.",
          "Fixed “Billable /Non-billable” task merge bug when viewing project/people reports in detail. Now, tasks with the same name on different “Billable/Non-Billable” statuses are not merged.",
        ],
      },
      "1.3.3": {
        v_new: [
          "Added the ability to record the time as Billable/Non billable in the 'Timer' section.",
          "Added Online status for users. In the 'Projects' section, when viewing users on projects, you can see the green status Online which displayed if the user is currently recording a task in the timer.",
          "Added 'Decimal rounding of reports' in the 'Reports' section. Now the user can upload reports in the new format.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed display of usernames in the left menu field.",
          "Fixed editing task time in the 'Timer' section. Tasks from different projects could overlap in time.",
          "Fixed a bug with displaying a client in a project if the client was deleted from the 'Clients' section. The client that was deleted is no longer displayed in the project.",
        ],
      },
      "1.2.2": {
        v_new: [
          "Added a new ability to add projects to favorites, for quick access to projects.",
          "Added display of the total recorded time per month for all teams under the user profile.",
        ],
        v_improved: [
          "User profile. Added a notification if the user missed a required field.",
          "Invoices. Recent invoices now show the company name instead of the customer's name.",
          "The task in the timer can be started without a name.",
          "Added several error messages that users may encounter.",
          "Now users can see other members of the shared project.",
        ],
      },
      "1.1.1": {
        v_new: ["Added new Wobbly light theme in profile settings"],
        v_improved: [
          "Improved search for invoices by: Client name, Company name, Invoice number, Description, Reference. Now the invoice can be found by all these fields",
          "Fixed a bug with changing logo in invoices.",
          "Fixed bug with pulling archived clients when increase/decrease a subscription.",
          "Fixed errors when downloading a detailed/summary report. Reports could come encrypted or be incomplete.",
          "Fixed a bug when combining task names in the Reports section.",
          "Fixed search in the Projects section.",
          "Added several error messages that users may encounter.",
          "Fixed bugs in the Timer section. Editing tasks with overlapping times on different teams.",
          "Fixed errors when purchasing a subscription by a user with the Admin role. The subscription now applies to all team members.",
          "Fixed client removal. When deleting a client, the invoices that were sent to him now do not disappear",
        ],
      },
      "1.0": {
        v_new: ["Added release notes"],
        v_improved: [
          "Fixed display special characters in the project name by code when viewing detailed reports in the 'Reports' section.",
          "We fixed a bug where the partial search in the 'Projects' section did not display all matches.",
          "Fixed monitor 'Total hours by date' in the 'Reports' section. Fixed visual display by dates.",
          "Fixed search by invoice numbers and page transitions. Now the search is not reset when viewing the invoice.",
          "Added the ability to search for an invoice by Description and Reference.",
          "Added error notification when creating/editing a project with the same name.",
          "Fixed a bug related to special characters in the description of tasks in the 'Timer' section.",
          "Fixed a bug with inviting a new user to the team",
          "Added a few new error messages that the user may experience.",
          "Fixed a bug in the Timer section. Recording time in advance and editing time with the intersection of tasks by time.",
        ],
      },
    },
  },
};
