import React, { ReactElement, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { createMuiTheme } from "@material-ui/core";
import { sortByKey } from "../../../../services/reportsService";

const materialTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
      },
    },
  },
});

interface IOption {
  name: string;
  value: string;
  data?: any;
}

interface IProps {
  isCrossTeam?: boolean;
  vocabulary: any;
  onSelectAll?: () => void;
  onSelectNone?: () => void;
  onToggleOption: (option: IOption) => void;
  options: IOption[];
  selectedOptions: IOption[];
  label: string;
}

const ReportsFilterDropdown = ({
  isCrossTeam = false,
  vocabulary,
  onSelectAll,
  onSelectNone,
  options,
  selectedOptions = [],
  onToggleOption,
  label = "Label",
}: IProps): ReactElement => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);

  const dropdownRef = useRef<any>();
  const inputRef = useRef<any>();

  useEffect(() => {
    sortBySelected();
  }, [selectedOptions.length]);

  const openDropdown = () => {
    if (isOpenDropdown) {
      setIsOpenDropdown(false);
      document.removeEventListener("click", closeDropdown);
    } else {
      setIsOpenDropdown(true);
      sortBySelected();
      document.addEventListener("click", closeDropdown);
    }
  };

  const closeDropdown = (e) => {
    if (!dropdownRef.current?.contains(e.target)) {
      setIsOpenDropdown(false);
      document.removeEventListener("click", closeDropdown);
    }
  };

  const selectAll = () => {
    onSelectAll && onSelectAll();
  };

  const selectNone = () => {
    onSelectNone && onSelectNone();
  };

  const clearSearch = () => {
    inputRef.current.value = "";

    sortBySelected();
  };

  const getCheckedData = (valueSearch: string) =>
    selectedOptions.some((item: IOption) => item.value === valueSearch);

  const searchValues = (searchText = "") => {
    if (searchText.length > 0) {
      searchText = searchText.toLowerCase().trim();
      const filteredArr = options.filter((option) => {
        const values = [option.value, option.name];

        return values.join().toLowerCase().indexOf(searchText) > -1
          ? option
          : undefined;
      });

      setFilteredOptions(filteredArr);
    } else {
      sortBySelected();
    }
  };

  const sortBySelected = () => {
    const allOptions = [...options];
    const result: IOption[] = [...selectedOptions];

    let unselectedOptions = allOptions.filter((option: IOption) => {
      const isSelected = Boolean(
        result.find((res) => res.value === option.value),
      );

      return !isSelected;
    });

    unselectedOptions = unselectedOptions.sort((a, b) =>
      sortByKey(a, b, "name"),
    );

    result.push(...unselectedOptions);

    setFilteredOptions(result);
  };

  const toggleOption = (option: IOption) => {
    onToggleOption && onToggleOption(option);
  };

  const { v_select_none, v_select_all, v_find } = vocabulary;

  return (
    <div
      className={cn("reports_search_bar_search_field_container select", {
        "cross-team-select": isCrossTeam,
      })}
    >
      <div className="reports_search_select_wrapper">
        <div
          className="reports_search_select_header"
          onClick={() => openDropdown()}
        >
          <div>
            {label}
            :&nbsp;
            {selectedOptions.length === 0
              ? v_select_none
              : selectedOptions.length === options.length
              ? v_select_all
              : selectedOptions.map((item, index) => (
                  <span key={item.value + index}>
                    {index === 0 ? item.name : `, ${item.name}`}
                  </span>
                ))}
          </div>
          <i
            className={cn(`arrow_down ${isOpenDropdown ? "arrow_up" : ""}`, {
              arrow_down__light_themes:
                localStorage.getItem("themes") === "light",
            })}
          />
        </div>
      </div>
      {isOpenDropdown && (
        <div className="select_body" ref={dropdownRef}>
          <div className="search_menu_select">
            <input
              type="text"
              onKeyUp={(e: any) => searchValues(e.target.value)}
              ref={inputRef}
              placeholder={`${v_find}...`}
              onClick={(e) => e.stopPropagation()}
            />
            <div onClick={(_) => selectAll()}>{v_select_all}</div>
            <div onClick={(_) => selectNone()}>{v_select_none}</div>
            <i
              className={cn("small_clear", {
                small_clear__light: localStorage.getItem("themes") === "light",
              })}
              onClick={(_) => clearSearch()}
            />
          </div>
          <div className="select_items_container">
            {filteredOptions.map((item, index) => (
              <div className="select_users_item" key={item.value + index}>
                <label>
                  <ThemeProvider theme={materialTheme}>
                    <Checkbox
                      color={"primary"}
                      value={item.value || ""}
                      checked={getCheckedData(item.value)}
                      onChange={() => {
                        toggleOption(item);
                      }}
                    />
                  </ThemeProvider>
                  <span className="select_users_item_username">
                    {item.name}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  vocabulary: store.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsFilterDropdown);
