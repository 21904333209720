export function encodeTimeEntryIssue(issue) {
  const encodedIssue = issue ? issue : "";

  return encodedIssue;
}

export function decodeTimeEntryIssue(issue) {
  let decodedIssue = issue;

  if (issue) {
    try {
      decodedIssue = decodeURIComponent(issue);
    } catch (error) {
      console.error(error);
    }
  }

  return decodedIssue;
}
