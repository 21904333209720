import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CardForm,
  CardHeader,
  CardRightContent,
  CardTitle,
  MyCardWrapper,
  Detail,
} from "../Base";
import { AddButton, CancelButton } from "../Elements";
import EditableLabel from "react-trello/dist/widgets/EditableLabel";

class NewCardForm extends Component {
  updateTitleValidation = (value) => {
    this.setState({ isEmpty: value });
  };

  updateField = (field, value) => {
    this.setState({ [field]: value });
  };

  handleAdd = () => {
    if (!this.state.title) {
      this.updateTitleValidation(true);
    } else {
      this.updateTitleValidation(false);
      this.props.onAdd(this.state);
    }
  };

  render() {
    const { onCancel, t } = this.props;
    return (
      <CardForm>
        <MyCardWrapper>
          <CardHeader>
            <CardTitle>
              <div
                style={{
                  outline: this.state?.isEmpty ? "solid 2px red" : "none",
                }}
              >
                <EditableLabel
                  placeholder={t("placeholder.title")}
                  onChange={(val) => this.updateField("title", val)}
                  autoFocus
                />
              </div>
            </CardTitle>
            <CardRightContent>
              <EditableLabel
                placeholder={t("placeholder.label")}
                onChange={(val) => this.updateField("label", val)}
              />
            </CardRightContent>
          </CardHeader>
          <Detail>
            <EditableLabel
              placeholder={t("placeholder.description")}
              onChange={(val) => this.updateField("description", val)}
            />
          </Detail>
        </MyCardWrapper>
        <AddButton onClick={this.handleAdd}>{t("button.Add card")}</AddButton>
        <CancelButton onClick={onCancel}>{t("button.Cancel")}</CancelButton>
      </CardForm>
    );
  }
}

NewCardForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

NewCardForm.defaultProps = {};

export default NewCardForm;
