import React, { Component } from "react";
import { connect } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

// Components
import { SocialConnectButton } from "../SocialConnectButton";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { setSocialConnect } from "../../../api/user";

// Actions
import { checkUserDataAction } from "../../../redux/actions/UserActions";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";

// app config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";

class SocialConnect extends Component {
  state = {
    isFetchingFacebook: false,
    isFetchingGoogle: false,
  };

  setValueToState = (key, value) =>
    this.setState({
      [key]: value,
    });

  responseFacebook = async (response) => {
    const { showNotificationAction, vocabulary, checkUserDataAction } =
      this.props;

    if (response.status !== "unknown") {
      const { user } = this.props;
      const { id } = response;

      try {
        await setSocialConnect(user.id, {
          socialId: id,
          socialName: "facebook",
        });
        await checkUserDataAction();
      } catch (error) {
        const textError = error.response.data.message;

        showNotificationAction({ text: vocabulary[textError], type: "error" });
      } finally {
        this.setState({
          isFetchingFacebook: false,
        });
      }
    }
    this.setState({
      isFetchingFacebook: false,
    });
  };

  responseGoogle = async (response) => {
    const { showNotificationAction, vocabulary, checkUserDataAction } = this.props;

    if (response.status !== "unknown") {
      const { user } = this.props;
      const { credential } = response;
      const resultDecoding = jwtDecode(credential);

      try {
        await setSocialConnect(user.id, {
          socialId: resultDecoding.email,
          socialName: "google",
        });
        await checkUserDataAction();
      } catch (error) {
        const textError = error.response.data.message;

        showNotificationAction({ text: vocabulary[textError], type: "error" });
      } finally {
        this.setState({
          isFetchingGoogle: false,
        });
      }
    }
    this.setState({
      isFetchingGoogle: false,
    });
  };

  render() {
    const { isFetchingFacebook, isFetchingGoogle } = this.state;

    const { social, checkUserDataAction } = this.props;
    const { facebookId, googleId } = social;

    const socialButtons = [
      {
        type: "facebook",
        textButton: "Facebook",
        disabled: false,
        connected: Boolean(facebookId),
      },
      {
        type: "google",
        textButton: "Google",
        disabled: false,
        connected: Boolean(googleId),
      },
    ];

    return (
      <div className="social-connect-wrapper">
        {socialButtons.map((button) => {
          const { type, disabled, textButton, connected } = button;
          const { user } = this.props;

          if (type === "facebook") {
            return (
              <FacebookLogin
                key={type}
                appId={AppConfig.socialAuth.fbAppId}
                autoLoad={false}
                fields="name,email"
                callback={this.responseFacebook}
                render={(renderProps) => (
                  <SocialConnectButton
                    onClick={async (event) => {
                      if (isFetchingFacebook) {
                        return;
                      }
                      this.setState({ isFetchingFacebook: true });
                      if (facebookId) {
                        await setSocialConnect(user.id, {
                          socialId: null,
                          socialName: "facebook",
                        });
                        await checkUserDataAction();
                        this.setState({ isFetchingFacebook: false });
                        return;
                      }
                      renderProps.onClick(event);
                    }}
                    connected={connected}
                    type={type}
                    isFetching={isFetchingFacebook || renderProps.isDisabled}
                    disabled={disabled}
                    textButton={textButton}
                  />
                )}
                disableMobileRedirect={true}
              />
            );
          } else if (type === "google") {
            return (
              !googleId ?
                <GoogleLogin
                  size="medium"
                  text="signin"
                  onSuccess={this.responseGoogle} />
                : <SocialConnectButton
                    onClick={async () => {
                      if (isFetchingGoogle) {
                        return;
                      }
                      this.setState({ isFetchingGoogle: true });
                      if (googleId) {
                        await setSocialConnect(user.id, {
                          socialId: null,
                          socialName: "google",
                        });
                        await checkUserDataAction();
                        this.setState({ isFetchingGoogle: false });
                      }
                    }}
                    key={type}
                    connected={connected}
                    type={type}
                    disabled={disabled}
                    textButton={textButton}
                />
            )
            }
            return (
              <SocialConnectButton
                key={type}
                connected={connected}
                type={type}
                disabled={disabled}
                textButton={textButton}
              />
            );
          })}
        </div>
      );
            }
}

const mapStateToProps = (state) => ({
  social: state.userReducer.user.social,
  vocabulary: state.languageReducer.vocabulary,
  user: state.userReducer.user,
});

const mapDispatchToProps = {
  checkUserDataAction,
  showNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialConnect);
