import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

// dependencies
import classNames from "classnames";

// actions
import { switchMenu } from "../../../redux/actions/ResponsiveActions";

// components
import LeftBar from "../LeftBar";
import Header from "../Header";
import ModalInfo from "../ModalInfo";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
// styles
import "./style.scss";
import UpgradePlanModal from "../UpgradePlanModal";
import { changeUpgradeModalStatusAction } from "../../../redux/actions/SubscriptionPageAction";
import { checkIsOwnerByRole } from "../../../services/authentication";
import SwitcherTheme from "../../../components/Unstructured/SwitcherTheme";

class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: this.props.themes,
      isCollapsed: JSON.parse(localStorage.getItem("isCollapsed") || true),
    };
  }
  teamSwitched = () => {
    const { currentTeam, vocabulary, showNotificationAction } = this.props;
    const { v_switch_team_to_the } = vocabulary;

    showNotificationAction({
      type: "team-switched",
      text: `${v_switch_team_to_the} ${currentTeam.data.name}`,
    });
  };

  componentDidUpdate(prevProps) {
    const { currentTeam } = this.props;

    if (
      prevProps.currentTeam.data.name &&
      currentTeam.data.name !== prevProps.currentTeam.data.name
    ) {
      this.teamSwitched();
    }
  }

  changeStatus = (isOpen) => {
    this.props.changeUpgradeModalStatusAction({ isOpen });
  };

  changeThemes = () => {
    if (localStorage.getItem("themes") === "dark") {
      localStorage.setItem("themes", "light");
    } else {
      localStorage.setItem("themes", "dark");
    }
    this.setState({
      themes: localStorage.getItem("themes"),
    });
  };

  toggleSidebar = () => {
    const isCollapsed = !this.state.isCollapsed;
    this.setState({ isCollapsed });
    localStorage.setItem("isCollapsed", JSON.stringify(isCollapsed));
  };

  render() {
    const {
      content: Content,
      switchMenu,
      isShowMenu,
      isMobile,
      vocabulary,
      hideSidebar,
      hideHeader,
      viewport,
      notificationId,
      user,
      authPage,
      isUpgradeModalOpen,
      upgradeModalContent,
    } = this.props;
    const { v_upgrade_button } = vocabulary;
    const { width, height } = viewport;
    const { isCollapsed } = this.state;

    return (
      <div
        className="wrapper-page-template"
        style={{
          width: width,
          height: !authPage ? height : null,
          minHeight: authPage ? height : null,
        }}
        data-theme={localStorage.getItem("themes")}
      >
        {isMobile && !hideHeader && (
          <header className="header">
            <Header
              changeThemes={this.changeThemes}
              themes={this.state.themes}
            />
          </header>
        )}
        {notificationId.length ? <ModalInfo /> : null}
        <div className="wrapper-main-content">
          {!hideSidebar && user && (
            <aside
              className={classNames("aside", {
                "aside--hide": !isShowMenu && isMobile,
                "aside--show": isShowMenu && isMobile,
                "aside--collapsed": isCollapsed && !isMobile,
                "wrapper-position-add-team--collapsed":
                  isCollapsed && !isMobile,
                "wrapper_user_menu--collapsed": isCollapsed && !isMobile,
                "upgrade-banner--collapsed": isCollapsed && !isMobile,
              })}
            >
              <LeftBar
                changeThemes={this.changeThemes}
                toggleSidebar={this.toggleSidebar}
                themes={this.state.themes}
                isCollapsed={this.state.isCollapsed}
                isShowMenu={isShowMenu}
                switchMenu={switchMenu}
                isMobile={isMobile}
                vocabulary={vocabulary}
              />
            </aside>
          )}
          <main
            className={classNames("main", {
              "main-authorization": hideSidebar,
            })}
          >
            <Content
              isMobile={isMobile}
              vocabulary={vocabulary}
              themes={this.state.themes}
            />
          </main>
          <>
            <UpgradePlanModal
              isOpen={isUpgradeModalOpen}
              changeStatus={this.changeStatus}
            >
              <>
                <div className="upgrade-modal__image" />
                <h2 className="upgrade-modal__title">
                  {upgradeModalContent.title}
                </h2>
                <p className="upgrade-modal__description">
                  {upgradeModalContent.description}
                </p>
                {checkIsOwnerByRole(this.props.currentTeam.data.role) && (
                  <Link
                    className="upgrade-modal__button"
                    to="/subscription/plans"
                    onClick={() => this.changeStatus(false)}
                  >
                    {v_upgrade_button}
                  </Link>
                )}
              </>
            </UpgradePlanModal>
          </>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewport: state.responsiveReducer.viewport,
  isShowMenu: state.responsiveReducer.isShowMenu,
  isMobile: state.responsiveReducer.isMobile,
  vocabulary: state.languageReducer.vocabulary,
  notificationId: state.notificationReducer.notificationId,
  currentTeam: state.teamReducer.currentTeam,
  user: state.userReducer.user,
  isUpgradeModalOpen: state.subscriptionPageReducer.isUpgradeModalOpen,
  upgradeModalContent: state.subscriptionPageReducer.upgradeModalContent,
});

const mapDispatchToProps = {
  switchMenu,
  showNotificationAction,
  changeUpgradeModalStatusAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageTemplate),
);
