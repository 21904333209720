import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import EditProjectModal from "../EditProjectModal/index";

// Actions
import { getProjectsListActions } from "../../../redux/actions/ProjectsActions";
import { getTeamUsersStatusAction } from "../../../redux/actions/TeamActions";

import { withRouter } from "react-router-dom";
// Queries

// Config
// Services
import { decodeTimeEntryIssue } from "../../../services/timeEntryService";

// Styles
import "./style.scss";
import ProjectsDataRow from "../ProjectsDataRow";
import Scrollbars from "react-custom-scrollbars";
import Spinner from "../Spinner";

class ProjectData extends Component {
  async componentDidMount() {
    await this.props.getTeamUsersStatusAction();
  }

  setEdiItem(item) {
    this.props.projectsPageAction("SET_EDIT_PROJECT", { tableData: item });
    this.props.projectsPageAction("TOGGLE_EDIT_PROJECT_MODAL", {
      tableData: true,
    });
  }

  getClientFullName(client, listView = true) {
    const { company_name, name } = client;

    // returns 'Company (Client Name)' for list visualization, or 'company clientname' for search
    if (listView) {
      return company_name ? `${company_name}${name ? ` (${name})` : ""}` : name;
    } else {
      return (
        company_name ? `${company_name}${name ? ` ${name}` : ""}` : name
      ).toLowerCase();
    }
  }

  handleScrollFrame = (values) => {
    const { listFetching, isListEnded, getProjectsListActions, page } =
      this.props;
    const { top } = values;

    if (top > 0.7 && !listFetching && !isListEnded) {
      getProjectsListActions({
        page: page + 1,
        withPagination: true,
        withTimerList: false,
      });
    }
  };

  render() {
    const {
      currentTeam,
      vocabulary,
      durationTimeFormat,
      listFetching,
      user,
      TeamUserStatus,
    } = this.props;
    const { v_project_name, v_client } = vocabulary;

    const filterArrey = this.props.tableInfo.filter((ele) =>
      ele.name.toLocaleLowerCase().includes(this.props.searchValue),
    );

    return (
      <div className="project-data">
        {this.props.editProjectModal && (
          <EditProjectModal
            currentUser={this.props.user}
            editedProject={this.props.editedProject}
            projectsPageAction={this.props.projectsPageAction}
          />
        )}
        <Scrollbars
          autoHide
          hideTracksWhenNotNeeded
          onScrollFrame={this.handleScrollFrame}
        >
          <table className="project-data__table">
            <thead className="project-data__header">
              <tr>
                <th className="table-header-row project-data__toggle" />
                <th className="table-header-row project-data__name">
                  {v_project_name}
                </th>
                <th className="table-header-row project-data__color" />
                <th className="table-header-row project-data__client">
                  {v_client}
                </th>
                <th className="table-header-row project-data__buttons" />
              </tr>
            </thead>
            <tbody className="project-data__body">
              {filterArrey.map((item) => (
                <ProjectsDataRow
                  TeamUserStatus={TeamUserStatus}
                  key={item.id}
                  projectData={item}
                  vocabulary={vocabulary}
                  setEditItem={(item) => this.setEdiItem(item)}
                  currentTeam={currentTeam}
                  durationTimeFormat={durationTimeFormat}
                  expandedStatus={this.props.tableInfo.length === 1}
                  changeProjectActiveStatus={
                    this.props.changeProjectActiveStatus
                  }
                  currentUser={this.props.user}
                  changeProjectFavouriteStatus={
                    this.props.changeProjectFavouriteStatus
                  }
                  searchValue={this.props.searchValue}
                />
              ))}
            </tbody>
          </table>
          {listFetching && (
            <div style={{ position: "relative", paddingTop: "10px" }}>
              <Spinner mode="inline" withLogo={false} />
            </div>
          )}
        </Scrollbars>
      </div>
    );
  }
}

ProjectData.propTypes = {
  tableInfo: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  currentTeam: state.teamReducer.currentTeam,
  vocabulary: state.languageReducer.vocabulary,
  durationTimeFormat: state.userReducer.durationTimeFormat,
  isListEnded: state.projectReducer.pagination.isListEnded,
  listFetching: state.projectReducer.pagination.listFetching,
  page: state.projectReducer.pagination.page,
  user: state.userReducer.user,
  TeamUserStatus: state.teamReducer.TeamUserStatus,
});

const mapDispatchToProps = {
  getProjectsListActions,
  getTeamUsersStatusAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectData),
);
