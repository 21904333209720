import React from "react";
import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import cn from "classnames";
import "./style.scss";

export default function Tooltip(props) {
  const {
    content,
    placement = "right",
    disabled = false,
    hideOnClick = false,
    trigger = "mouseenter",
    plugins,
    followCursor = false,
    interactive = false,
    className = "",
    children,
    arrow = true,
    offset = [0,10],
  } = props;
  const theme =
    localStorage.getItem("themes") === "light"
      ? "tippy-style-light"
      : "tippy-style-dark";

  return (
    <Tippy
      content={content}
      placement={placement}
      disabled={disabled}
      hideOnClick={hideOnClick}
      trigger={trigger}
      plugins={plugins}
      followCursor={followCursor}
      interactive={interactive}
      className={cn(theme, className)}
      arrow={arrow}
      offset={offset}
      zIndex={9999}
    >
      {children}
    </Tippy>
  );
}
