import React from "react";
import { NewLaneSection } from "../Base";
import { AddLaneLink } from "../Elements";

export default ({ t, onClick }) => (
  <NewLaneSection>
    <AddLaneLink t={t} onClick={onClick}>
      {t("Add another lane")}
    </AddLaneLink>
  </NewLaneSection>
);
