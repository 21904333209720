import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import _ from "lodash";

import { initSocket } from "../../../api/configSocket";

// Actions
import {
  setTimerTickAction,
  getTimeEntriesListAction,
} from "../../../redux/actions/MainPageAction";
import {
  getUserDataAction,
  checkUserDataAction,
} from "../../../redux/actions/UserActions";
import {
  getUserTeamsAction,
  getCurrentTeamAction,
} from "../../../redux/actions/TeamActions";

// Services
import {
  checkAppVersion,
  logoutByUnauthorized,
} from "../../../services/authentication";
import { updatePageTitle } from "../../../services/pageTitleService";

import { Loading } from "../Loading";

class PrivateRoute extends Component {
  componentDidMount() {
    const { getUserDataAction,
      checkUserDataAction,
      getUserTeamsAction,
      getCurrentTeamAction, } = this.props;

    if (!checkAppVersion()) {
      return logoutByUnauthorized();
    }
    getUserDataAction();
    checkUserDataAction();
    getUserTeamsAction();
    getCurrentTeamAction();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      checkUserDataAction,
      getUserTeamsAction,
      getCurrentTeamAction,
      user,
      currentTimer,
      setTimerTickAction,
    } = this.props;

    if (!checkAppVersion()) {
      return logoutByUnauthorized();
    }

    if (!prevProps.user && user) {
      initSocket();
    }

    if (!_.isEqual(prevProps.currentTimer, currentTimer)) {
      if (currentTimer) {
        // set timerTick
        if (!this.setInterval) {
          this.setInterval = setInterval(() => {
            setTimerTickAction();
          }, 1000);
        }
      } else {
        // reset timerTick
        updatePageTitle(null);
        clearInterval(this.setInterval);
        this.setInterval = null;
        setTimerTickAction("reset");
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      checkUserDataAction();
      getUserTeamsAction();
      getCurrentTeamAction();
    }
  }

  componentWillUnmount() {
    updatePageTitle(null);
    clearInterval(this.setInterval);
  }

  render() {
    const { render, user, isFetching, isInitialFetching, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (isInitialFetching || isFetching) {
            return <Loading flag={true} />;
          } else {
            return render();
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  isFetching: state.userReducer.isFetching,
  isInitialFetching: state.userReducer.isInitialFetching,
  currentTimer: state.mainPageReducer.currentTimer,
});

const mapDispatchToProps = {
  getUserDataAction,
  checkUserDataAction,
  getUserTeamsAction,
  getCurrentTeamAction,
  setTimerTickAction,
  getTimeEntriesListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
