import React from "react";
import { connect } from "react-redux";

import BillingInfoForm from "../../BillingInfoForm";

import "./style.scss";

const BillingInfoEditModal = ({
  handleClose,
  formValues,
  handleSubmit,
  submitTitle,
  vocabulary,
  lang,
  isProcessing,
}) => {
  const { v_subscription } = vocabulary;
  const { billingInfoEditModal } = v_subscription;

  return (
    <div className="billing-info-edit-modal" 
    data-theme={localStorage.getItem("themes")}>
      <div className="billing-info-edit-modal__header">
        {billingInfoEditModal.title}
        <i className="billing-info-edit-modal__close" onClick={handleClose} />
      </div>
      <div className="billing-info-edit-modal__form">
        <BillingInfoForm
          formValues={formValues}
          vocabulary={vocabulary}
          lang={lang}
          saveFormValues={handleSubmit}
          submitTitle={submitTitle}
          isProcessing={isProcessing}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  lang: state.languageReducer.selectedLang,
});

export default connect(mapStateToProps)(BillingInfoEditModal);
