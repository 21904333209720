import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";

// Services
import { apiCall } from "../../../services/apiService";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
} from "../../../services/authentication";
import { decodeTimeEntryIssue } from "../../../services/timeEntryService";

// Components
import ClientsDropdown from "../ClientsDropdown";
import ProjectsDropdown from "../ProjectsDropdown";
import UsersSelectComponent from "../UsersSelectComponent";

// Actions
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import { getClientsAction } from "../../../redux/actions/ClientsActions";
import {
  getRelationProjectsListAction,
  getProjectsListActions,
  changeProjectAction,
} from "../../../redux/actions/ProjectsActions";

// Queries

// Config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";
import { getCurrentTeamDetailedDataAction } from "../../../redux/actions/TeamActions";
import { Loading } from "../Loading";

class EditProjectModal extends Component {
  state = {
    projectName: "",
    selectedValue: {
      id: "a642f337-9082-4f64-8ace-1d0e99fa7258",
      name: "green",
    },
    listOpen: false,
    selectValue: [],
    projectId: "",
    selectedClient: null,
    selectedProject: null,
    selectedUsers: [],
    relationProjectsList: null,
    projectsList: [],
    showUsersSelect: false,
    selectedUser: [],
  };

  componentDidMount() {
    apiCall(`${AppConfig.apiURL}project-color/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (result) => {
        const data = result.data;

        this.setState({ selectValue: data.project_color });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );

    apiCall(`${AppConfig.apiURL}project/${this.props.editedProject.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (result) => {
        const data = result.data;
        const { id, client, jira_project_id, project_color, name } =
          data.project_v2[0];

        this.setState({
          projectId: id,
          selectedClient: client,
          selectedProject: jira_project_id,
        });
        this.setItem({
          id: project_color.id,
          name: project_color.name,
        });
        this.setState({ projectName: name });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
    this.props.getClientsAction();
    this.props.getCurrentTeamDetailedDataAction();
    document.addEventListener("mousedown", this.closeList);
    this.props.userReducer.user.tokenJira &&
      this.props.getRelationProjectsListAction();
    this.setState({
      selectedUsers: this.filterUsersByRole(
        this.props.editedProject.user_project,
      ),
      selectedUser: this.props.editedProject.user_project.map(
        (item) => item.user,
      ),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeList);
  }

  componentDidUpdate(prevProps, prevState) {
    const { relationProjectsList, projectsList, OneOfNameProjectsList } =
      this.props;

    if (prevProps.relationProjectsList !== relationProjectsList) {
      this.setState({ relationProjectsList });
    }
  }

  setItem(value) {
    this.setState({
      selectedValue: value,
    });
  }

  toggleSelect() {
    this.setState({
      listOpen: !this.state.listOpen,
    });
  }

  changeProject() {
    const { vocabulary, showNotificationAction } = this.props;
    const {
      selectedClient,
      selectedProject,
      selectedUsers,
      OneOfNameProjectsList,
      selectedUser,
    } = this.state;

    if (!this.state.projectName.trim().length) {
      showNotificationAction({
        text: vocabulary.v_a_project_name_error,
        type: "warning",
      });
      return false;
    }

    let selectedJiraProjectId = "";

    if (this.props.userReducer.user.tokenJira) {
      selectedJiraProjectId = selectedProject;
    } else {
      selectedJiraProjectId = null;
    }

    this.props.changeProjectAction({
      id: this.state.projectId,
      name: this.state.projectName,
      projectColorId: this.state.selectedValue.id,
      clientId: selectedClient ? selectedClient.id : null,
      jiraProjectId: selectedJiraProjectId ? selectedJiraProjectId : null,
      users: selectedUser.map((item) => item.id),
    });
  }

  closeModal = () => {
    this.props.projectsPageAction("TOGGLE_EDIT_PROJECT_MODAL", {
      tableData: false,
    });
  };

  clientSelect = (data) => {
    this.setState({ selectedClient: data ? data : null });
  };

  projectSelect = (data) => {
    this.setState({ selectedProject: data ? data : null });
  };

  filterUsersByRole = (users) =>
    users.filter(
      (item) =>
        !checkIsAdminByRole(item.role) && !checkIsOwnerByRole(item.role),
    );

  closeList = (e) => {
    const { listOpen } = this.state;

    if (
      listOpen &&
      !e.target.closest(".edit_projects_modal_data_select_container")
    ) {
      this.setState({ listOpen: false });
    }
  };

  parseTeamUsers = (teamData) =>
    teamData
      .filter(
        (item) =>
          // !checkIsOwnerByRole(item.role_collaboration.title) &&
          // !checkIsAdminByRole(item.role_collaboration.title) &&
          item.is_active,
      )
      .map((teamUser) => ({
        ...teamUser.user,
        role: teamUser.role_collaboration.title,
      }));

  onProjectNameChange = (value) => {
    if (value.length <= 50) {
      this.setState({ projectName: value.replace("  ", " ").trimStart() });
    }
    return null;
  };

  render() {
    const { vocabulary, teamUsers } = this.props;
    const {
      selectedClient,
      relationProjectsList,
      projectsList,
      OneOfNameProjectsList,
      selectedProject,
      selectedUsers,
      showUsersSelect,
      selectedUser,
    } = this.state;
    const {
      v_edit_project,
      v_project_name,
      v_edit_project_name,
      v_save,
      v_add_member,
      v_member_dots,
    } = vocabulary;

    const selectItems = this.state.selectValue.map((value) => {
      const { id, name } = value;

      return (
        <div key={id} className={"item"} onClick={(e) => this.setItem(value)}>
          <div className={`circle ${name}`} />
        </div>
      );
    });
    const noValidationName = this.state.projectName.includes("%");

    return (
      <div className="wrapper_edit_projects_modal">
        <div className="edit_projects_modal_background" />
        <div className="edit_projects_modal_container">
          <div className="edit_projects_modal_header">
            <div className="edit_projects_modal_header_title">
              {v_edit_project}
            </div>
            <i
              className="edit_projects_modal_header_close"
              onClick={(e) => this.closeModal()}
            />
          </div>
          <div className="edit_projects_modal_data">
            <div
              className="edit_projects_modal_data_input_container"
              data-label={v_edit_project_name}
            >
              <input
                type="text"
                className={cn("edit_project_input", {
                  edit_project_input__error: noValidationName === true,
                })}
                value={this.state.projectName}
                onChange={(e) => this.onProjectNameChange(e.target.value)}
                placeholder={`${v_project_name}...`}
              />
              <div
                className="edit_projects_modal_data_select_container"
                onClick={(e) => this.toggleSelect()}
              >
                <div className="select_main">
                  <div className={`circle ${this.state.selectedValue.name}`} />
                </div>
                <i
                  className={`vector ${this.state.listOpen ? "vector_up" : ""}`}
                />
                {this.state.listOpen && (
                  <div className="select_list">{selectItems}</div>
                )}
              </div>
              {noValidationName && (
                <div className="no-valid-project-name">
                  {vocabulary.v_a_project_no_valid_name}
                </div>
              )}
              <ClientsDropdown
                clientSelect={this.clientSelect}
                editedClient={selectedClient}
                clientsList={this.props.clientsList?.filter(
                  (item) => item.is_active,
                )}
                vocabulary={vocabulary}
              />
              <div
                className="edit-project__users-select-wrapper"
                data-label={v_add_member}
              >
                <div
                  className="edit-project__users-selected"
                  onClick={() =>
                    this.setState((prev) => ({
                      showUsersSelect: !prev.showUsersSelect,
                    }))
                  }
                >
                  {selectedUsers.length ? (
                    <span className="edit-project__users-selected-text">
                      {selectedUser.map((item) => item.username).join(", ")}
                    </span>
                  ) : (
                    <span className="edit-project__placeholder">
                      {v_member_dots}
                    </span>
                  )}
                  <i
                    className={`edit-project__vector ${
                      showUsersSelect ? "edit-project__vector--up" : ""
                    }`}
                  />
                </div>
                {showUsersSelect && (
                  <div className="edit-project__dropdown-container">
                    <UsersSelectComponent
                      users={this.parseTeamUsers(teamUsers)}
                      currentUser={this.props.currentUser}
                      selectedUsers={selectedUser}
                      vocabulary={vocabulary}
                      toggleSelect={(selectedItems) =>
                        this.setState({ selectedUser: selectedItems })
                      }
                      closePopup={() =>
                        this.setState({ showUsersSelect: false })
                      }
                    />
                  </div>
                )}
              </div>
              {/*WOB-3448 remove field for sync with Jira*/}
              {/*{this.props.userReducer.user.tokenJira && (*/}
              {/*  <ProjectsDropdown*/}
              {/*    relationProjectsList={relationProjectsList}*/}
              {/*    projectSelect={this.projectSelect}*/}
              {/*    vocabulary={vocabulary}*/}
              {/*    selectedProject={selectedProject}*/}
              {/*  />*/}
              {/*)}*/}
            </div>
          </div>
          <div className="edit_projects_modal_button_container">
            {this.props.isFetchingProject && (
              <div className="edit_projects_modal_button_container__loader-logo">
                <button disabled={this.props.isFetchingProject}>
                  <Loading
                    mode="overlay"
                    flag={this.props.isFetchingProject}
                    withLogo={false}
                    circle
                    width="3.6rem"
                    height="3.6rem"
                  />
                </button>
              </div>
            )}
            {!this.props.isFetchingProject && (
              <button
                className="edit_projects_modal_button_container_button"
                onClick={(e) => this.changeProject()}
                disabled={noValidationName === true}
              >
                {v_save}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  clientsList: state.clientsReducer.clientsList,
  relationProjectsList: state.projectReducer.relationProjectsList,
  projectsList: state.projectReducer.projectsList,
  userReducer: state.userReducer,
  teamUsers: state.teamReducer.currentTeamDetailedData.data,
  isFetchingProject: state.projectReducer.isFetchingProject,
});

const mapDispatchToProps = {
  showNotificationAction,
  getClientsAction,
  getRelationProjectsListAction,
  getProjectsListActions,
  getCurrentTeamDetailedDataAction,
  changeProjectAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectModal);
