import moment from "moment";
import { getTodayTimeEntriesParseFunction } from "../../services/parseFunctions";
import { updatePageTitle } from "../../services/pageTitleService";
import { getTimeDiff } from "../../services/timeService";
import { store } from "../store";
import {
  getTimeEntriesList,
  billableStatusApi,
  reportsBillableStatusApi,
  getNotificationsApi,
} from "../../api/timer";
import { getTimeCount } from "../../api/timer";
import { syncAllTasksWithJira } from "../../api/sync";
import { getProjectsListActions } from "./ProjectsActions";
import { showNotificationAction } from "./NotificationActions";

// Types constants
export const SET_CURRENT_TIMER = "SET_CURRENT_TIMER";
export const RESET_CURRENT_TIMER = "RESET_CURRENT_TIMER";
export const SET_SERVER_CLIENT_TIMEDIFF = "SET_SERVER_CLIENT_TIMEDIFF";
export const GET_TIME_ENTRIES_LIST = "GET_TIME_ENTRIES_LIST";
export const SET_TIMER_TICK = "SET_TIMER_TICK";
export const INC_PAGINATION = "INC_PAGINATION";
export const GET_TIME_ENTRIES_LIST_PAGINATION =
  "GET_TIME_ENTRIES_LIST_PAGINATION";
export const DISABLE_PAGINATION = "DISABLE_PAGINATION";
export const RESTORE_PAGINATION = "RESTORE_PAGINATION";
export const START_SEARCH_MODE = "START_SEARCH_MODE";
export const END_SEARCH_MODE = "END_SEARCH_MODE";
export const SYNC_ALL_TASKS_REQUEST = "SYNC_ALL_TASKS_REQUEST";
export const SYNC_ALL_TASKS_SUCCESS = "SYNC_ALL_TASKS_SUCCESS";
export const SYNC_ALL_TASKS_ERROR = "SYNC_ALL_TASKS_ERROR";
export const GET_TIME_COUNT_REQUEST = "GET_TIME_COUNT_REQUEST";

export const GET_USER_ACTIVITIES_REQUEST = "GET_USER_ACTIVITIES_REQUEST";
export const GET_USER_ACTIVITIES_SUCCESS = "GET_USER_ACTIVITIES_SUCCESS";
export const GET_USER_ACTIVITIES_ERROR = "GET_USER_ACTIVITIES_ERROR";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
// Action creators

const syncAllTasksRequest = () => ({
  type: SYNC_ALL_TASKS_REQUEST,
});

const syncAllTasksSuccess = () => ({
  type: SYNC_ALL_TASKS_SUCCESS,
});

const syncAllTasksError = () => ({
  type: SYNC_ALL_TASKS_ERROR,
});

const setTimeEntriesListAction = (payload) => ({
  type: GET_TIME_ENTRIES_LIST,
  payload,
});

export const startSearchMode = (payload) => ({
  type: START_SEARCH_MODE,
  payload,
});

export const endSearchMode = () => ({
  type: END_SEARCH_MODE,
});

export const setCurrentTimerAction = (payload) => ({
  type: SET_CURRENT_TIMER,
  payload,
});

export const setServerClientTimediffAction = (time) => ({
  type: SET_SERVER_CLIENT_TIMEDIFF,
  payload: Number(moment(time)) - Number(moment()),
});

export const incPaginationAction = () => ({
  type: INC_PAGINATION,
});

export const restorePaginationAction = () => ({
  type: RESTORE_PAGINATION,
});

const setTimeEntriesListPaginationAction = (payload) => ({
  type: GET_TIME_ENTRIES_LIST_PAGINATION,
  payload,
});

const disablePaginationAction = () => ({
  type: DISABLE_PAGINATION,
});

export const getTimeCountRequest = (payload) => ({
  type: GET_TIME_COUNT_REQUEST,
  payload,
});

export const setNotificationsAction = (data) => ({
  type: SET_NOTIFICATIONS,
  payload: data,
});

// ------------------------------------------------------------------------//
export const getNotificationsSuccess = (payload) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getTimeEntriesListAction = (signal) => async (dispatch) => {
  const { searchValue, searchDateRange, isSearchMode, pagination } =
    store.getState().mainPageReducer;
  const { page, limit, disabled } = pagination;
  let res = null;

  if (disabled) {
    res = await getTimeEntriesList(
      isSearchMode
        ? { page: 1, limit: page * limit, ...searchDateRange, searchValue }
        : { page: 1, limit: page * limit },
    );
  } else if (page === 1) {
    res = await getTimeEntriesList(
      isSearchMode
        ? { page, limit, ...searchDateRange, searchValue, cancelToken: signal }
        : { page, limit, cancelToken: signal },
    );
  } else {
    res = await getTimeEntriesList(
      isSearchMode
        ? {
            page: 1,
            limit: page * limit,
            ...searchDateRange,
            searchValue,
          }
        : {
            page: 1,
            limit: page * limit,
          },
    );
  }
  if (res) {
    const parsedList = getTodayTimeEntriesParseFunction(res.data.data);
    const { timerV2 } = parsedList;

    dispatch(setTimeEntriesListAction(timerV2));
  }
};

export const setTimerTickAction = (key) => {
  if (key === "reset") {
    return {
      type: SET_TIMER_TICK,
      payload: null,
    };
  } else {
    const { mainPageReducer } = store.getState();
    const { currentTimer } = mainPageReducer;
    const duration = getTimeDiff(currentTimer.timeStart, true);
    const durationForTitle = getTimeDiff(
      currentTimer.timeStart,
      true,
      "classic",
    );

    updatePageTitle(
      durationForTitle,
      currentTimer.issue,
      currentTimer.project.name,
    );
    return {
      type: SET_TIMER_TICK,
      payload: duration,
    };
  }
};

export const getTimeEntriesListPaginationAction = () => async (dispatch) => {
  const { searchValue, searchDateRange, isSearchMode, pagination } =
    store.getState().mainPageReducer;
  const { page, limit } = pagination;

  let res = [];

  if (isSearchMode) {
    res = await getTimeEntriesList({
      page,
      limit,
      ...searchDateRange,
      searchValue,
    });
  } else {
    res = await getTimeEntriesList({ page, limit });
  }

  if (res.data.data.timer_v2.length) {
    const parsedList = getTodayTimeEntriesParseFunction(res.data.data);
    const { timerV2 } = parsedList;

    dispatch(setTimeEntriesListPaginationAction(timerV2));
  } else {
    dispatch(disablePaginationAction());
  }
};

export const syncAllTasks = () => async (dispatch, getState) => {
  dispatch(syncAllTasksRequest());
  const vocabulary = getState().languageReducer.vocabulary;

  try {
    await syncAllTasksWithJira();
    dispatch(getTimeEntriesListAction());
    dispatch(
      getProjectsListActions({
        withTimerList: false,
        withUserInfo: false,
      }),
    );
    dispatch(
      showNotificationAction({
        text: `${vocabulary.v_jira_synchronization_ok}`,
        type: "success",
      }),
    );
    dispatch(syncAllTasksSuccess());
  } catch (e) {
    console.log(e.message);
    dispatch(
      showNotificationAction({
        text: `${vocabulary.v_jira_synchronization_problem}`,
        type: "error",
      }),
    );
    dispatch(syncAllTasksError());
  }
};

export const getTimeCountAction = () => async (dispatch, getState) => {
  try {
    const startDate = getState().mainPageReducer.searchDateRange.startDateTime;
    const endDate = getState().mainPageReducer.searchDateRange.endDateTime;

    const { data } = await getTimeCount({ startDate, endDate });

    dispatch(getTimeCountRequest(data));
  } catch (error) {
    console.log(error);
  }
};

export const billableStatusAction = (id, status) => async (dispatch) => {
  try {
    await billableStatusApi(id, status);
  } catch (error) {
    console.log(error);
  }
};

export const reportsBillableStatusAction =
  (userId, billable, startDatetime, endDatetime) => async (dispatch) => {
    try {
      await reportsBillableStatusApi(
        userId,
        billable,
        startDatetime,
        endDatetime,
      );
    } catch (error) {
      console.log(error);
      dispatch(
        showNotificationAction({
          text: "Error",
          type: "error",
        }),
      );
    }
  };

export const getNotificationsAction = () => async (dispatch, getState) => {
  try {
    const { data } = await getNotificationsApi();

    dispatch(getNotificationsSuccess(data));
  } catch (error) {
    console.log(error);
  }
};
