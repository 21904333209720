import { clientsMethods } from "./ClientsActions";

import { getProjectListParseFunction } from "../../services/parseFunctions";
import {
  addProjectApi,
  changeProjectActiveStatusApi,
  changeProjectFavouriteApi,
  changeProjectApi,
  deleteUserFromProjectApi,
  getProjectColorListApi,
  getProjectsListApi,
  getRelationProjectsListApi,
} from "../../api/project";
import { showNotificationAction } from "./NotificationActions";

// --------------- Action creators ---------------- //

export default function projectsPageAction(actionType, action) {
  if (actionType === "CREATE_PROJECT") {
    return {
      type: "CREATE_PROJECT",
      payload: action,
    };
  } else if (actionType === "TOGGLE_PROJECT_MODAL") {
    return {
      type: "TOGGLE_PROJECT_MODAL",
      payload: action,
    };
  } else if (actionType === "CHANGE_ARR") {
    return {
      type: "CHANGE_ARR",
      payload: action,
    };
  } else if (actionType === "SET_EDIT_PROJECT") {
    return {
      type: "SET_EDIT_PROJECT",
      payload: action,
    };
  } else if (actionType === "TOGGLE_EDIT_PROJECT_MODAL") {
    return {
      type: "TOGGLE_EDIT_PROJECT_MODAL",
      payload: action,
    };
  } else if (actionType === "RESET_PROJECTS_PAGE") {
    return {
      type: "RESET_PROJECTS_PAGE",
      payload: action,
    };
  }
}

const setProjectsList = (payload) => ({
  type: "SET_PROJECTS_LIST",
  payload,
});

const getProjectsListRequest = () => ({
  type: "GET_PROJECTS_LIST",
});

const setRelationProjectsList = (payload) => ({
  type: "SET_RELATION_PROJECTS_LIST",
  payload,
});

const deleteUserFromProjectRequest = () => ({
  type: "DELETE_USER_FROM_PROJECT_REQUEST",
});

const deleteUserFromProjectSuccess = () => ({
  type: "DELETE_USER_FROM_PROJECT_SUCCESS",
});

const deleteUserFromProjectError = (payload) => ({
  type: "DELETE_USER_FROM_PROJECT_ERROR",
  payload,
});

const setProjectsPage = (payload) => ({
  type: "SET_PROJECTS_PAGE",
  payload,
});

const setProjectsListEnded = (payload) => ({
  type: "SET_PROJECTS_LIST_ENDED",
  payload,
});

export const changeProjectsSearchValue = (payload) => ({
  type: "CHANGE_PROJECTS_SEARCH_VALUE",
  payload,
});

export const changeProjectsFilterStatus = (payload) => ({
  type: "CHANGE_PROJECTS_FILTER_STATUS",
  payload,
});

export const getProjectColorsSuccess = (payload) => ({
  type: "PROJECT_COLORS_SUCCESS",
  payload,
});

export const addProjectRequest = () => ({
  type: "PROJECT_ADD_REQUEST",
});
export const addProjectSuccess = () => ({
  type: "PROJECT_ADD_SUCCESS",
});
export const addProjectError = () => ({
  type: "PROJECT_ADD_ERROR",
});
// --------------- Action logic ---------------- //

export const resetProjectsParamsAction = () => (dispatch) => {
  dispatch(changeProjectsSearchValue(""));
  dispatch(changeProjectsFilterStatus("all"));
  dispatch(setProjectsPage(1));
  dispatch(setProjectsListEnded(false));
};

export const getProjectsListActions =
  ({
    withTimerList = true,
    withUserInfo = true,
    withPagination = false,
    filterStatus,
    searchValue,
    page,
  } = {}) =>
  async (dispatch, getState) => {
    dispatch(
      setProjectsPage(
        clientsMethods.checkOnValue(
          page,
          getState().projectReducer.pagination.page,
        ),
      ),
    );

    dispatch(
      changeProjectsFilterStatus(
        clientsMethods.checkOnValue(
          filterStatus,
          getState().projectReducer.filterStatus,
        ),
      ),
    );

    dispatch(
      changeProjectsSearchValue(
        clientsMethods.checkSearchValue(
          searchValue,
          getState().projectReducer.searchValue,
        ),
      ),
    );

    dispatch(getProjectsListRequest());

    try {
      const {
        projectReducer: { pagination, projectsList, searchValue },
      } = getState();

      const { data } = await getProjectsListApi({
        withTimerList,
        withUserInfo,
        page: withPagination ? (page ? page : pagination.page) : null,
        limit: withPagination ? pagination.limit : null,
        isActive: clientsMethods.statusValueToBool(
          clientsMethods.checkOnValue(
            filterStatus,
            getState().projectReducer.filterStatus,
          ),
        ),
        searchValue: clientsMethods.checkSearchValue(
          searchValue,
          getState().projectReducer.searchValue,
        ),
      });

      const formattedList = getProjectListParseFunction(data.data);

      dispatch(
        setProjectsList({
          projectsList:
            withPagination && page !== 1 && projectsList
              ? [...projectsList, ...formattedList]
              : formattedList,
          total: data.total,
        }),
      );

      if (withPagination && !data.hasMore) {
        dispatch(setProjectsListEnded(true));
      } else {
        dispatch(setProjectsListEnded(false));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setProjectsList({
          projectsList: [],
          total: null,
        }),
      );
    }
  };

export const getRelationProjectsListAction = () => async (dispatch) => {
  try {
    const { data } = await getRelationProjectsListApi();

    dispatch(setRelationProjectsList(data));
  } catch (error) {
    dispatch(setRelationProjectsList([]));
  }
};

export const deleteUserFromProjectAction =
  (userId, projectId) => async (dispatch) => {
    dispatch(deleteUserFromProjectRequest());
    try {
      await deleteUserFromProjectApi(userId, projectId);
      dispatch(deleteUserFromProjectSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deleteUserFromProjectError(error));
    }
  };

export const changeProjectActiveStatusAction =
  (projectId, status) => async (dispatch) => {
    try {
      await changeProjectActiveStatusApi(projectId, status);
    } catch (error) {
      console.log(error);
    }
  };

export const changeProjectFavouriteAction =
  (projectId, status) => async (dispatch) => {
    try {
      await changeProjectFavouriteApi(projectId, status);
    } catch (error) {
      console.log(error);
    }
  };

export const addProjectAction = (projectData) => async (dispatch, getState) => {
  const { vocabulary } = getState().languageReducer;
  dispatch(addProjectRequest());
  try {
    await addProjectApi(projectData);
    dispatch(addProjectSuccess());
    dispatch(
      getProjectsListActions({
        page: 1,
        withPagination: true,
        withTimerList: false,
      }),
    );
    dispatch(projectsPageAction("TOGGLE_PROJECT_MODAL", { toggle: false }));
    dispatch(
      showNotificationAction({
        text: vocabulary.v_a_project_created,
        type: "success",
      }),
    );
  } catch (error) {
    dispatch(addProjectError());
    console.log(error.response.data.message);
    if (
      error.response.data.message === "Project with this name already exist"
    ) {
      dispatch(
        showNotificationAction({
          text: vocabulary.v_a_project_existed,
          type: "error",
        }),
      );
    } else {
      dispatch(
        showNotificationAction({
          text: vocabulary.v_a_project_created_error,
          type: "error",
        }),
      );
    }
  }
};

export const changeProjectAction =
  (projectData) => async (dispatch, getState) => {
    const { vocabulary } = getState().languageReducer;
    dispatch(addProjectRequest());

    try {
      await changeProjectApi(projectData);
      dispatch(addProjectSuccess());

      dispatch(
        getProjectsListActions({
          page: 1,
          withPagination: true,
          withTimerList: false,
        }),
      );
      dispatch(
        projectsPageAction("TOGGLE_EDIT_PROJECT_MODAL", {
          tableData: false,
        }),
      );
      dispatch(
        showNotificationAction({
          text: vocabulary.v_a_project_updated,
          type: "success",
        }),
      );
    } catch (error) {
      dispatch(addProjectError());
      console.log(error);
      if (
        error.response.data.message === "Project with this name already exist"
      ) {
        dispatch(
          showNotificationAction({
            text: vocabulary.v_a_project_existed,
            type: "error",
          }),
        );
      } else if (
        error.response.data.message.includes(
          "Jira project is already connected to the project",
        )
      ) {
        dispatch(
          showNotificationAction({
            text: `${vocabulary.v_a_project_created_synchronization_with_jira_error} ${error.response.data.project}`,
            type: "error",
          }),
        );
      } else {
        dispatch(
          showNotificationAction({
            text: vocabulary.v_a_project_edit_error,
            type: "error",
          }),
        );
      }
    }
  };
export const getProjectColorsAction = () => async (dispatch, getState) => {
  try {
    const { data } = await getProjectColorListApi();

    dispatch(getProjectColorsSuccess(data.data.project_color));
  } catch (error) {
    console.log(error);
  }
};
