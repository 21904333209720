export default {
  en: {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Plurinational State of",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Democratic Republic of the Congo",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    PF: "French Polynesia",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Republic of Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Republic of Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RO: "Romania",
    RU: "Russia",
    RW: "Rwanda",
    KN: "Saint Kitts and Nevis",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syria",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VI: "Virgin Islands",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  ru: {
    AF: "Афганистан",
    AL: "Албания",
    DZ: "Алжир",
    AS: "Американское Самоа",
    AD: "Андорра",
    AO: "Ангола",
    AI: "Ангилья",
    AG: "Антигуа и Барбуда",
    AR: "Аргентина",
    AM: "Армения",
    AW: "Аруба",
    AU: "Австралия",
    AT: "Австрия",
    AZ: "Азербайджан",
    BS: "Багамы",
    BH: "Бахрейн",
    BD: "Бангладеш",
    BB: "Барбадос",
    BY: "Беларусь",
    BE: "Бельгия",
    BZ: "Белиз",
    BJ: "Бенин",
    BM: "Бермудские острова",
    BT: "Бутан",
    BO: "Боливия, Многонациональное Государство",
    BA: "Босния и Герцеговина",
    BW: "Ботсвана",
    BR: "Бразилия",
    IO: "Британская территория Индийского океана",
    BG: "Болгария",
    BF: "Буркина-Фасо",
    BI: "Бурунди",
    KH: "Камбоджа",
    CM: "Камерун",
    CA: "Канада",
    CV: "Кабо-Верде",
    KY: "Каймановы острова",
    CF: "Центрально-Африканская Республика",
    TD: "Чад",
    CL: "Чили",
    CN: "Китай",
    CO: "Колумбия",
    KM: "Коморские острова",
    CG: "Конго",
    CD: "Демократическая Республика Конго",
    CK: "Острова Кука",
    CR: "Коста-Рика",
    CI: "Кот-д'Ивуар",
    HR: "Хорватия",
    CU: "Куба",
    CW: "Кюрасао",
    CY: "Кипр",
    CZ: "Чехия",
    DK: "Дания",
    DJ: "Джибути",
    DM: "Доминика",
    DO: "Доминиканская Респблика",
    EC: "Эквадор",
    EG: "Египет",
    SV: "Эль Сальвадор",
    GQ: "Экваториальная Гвинея",
    ER: "Эритрея",
    EE: "Эстония",
    ET: "Эфиопия",
    FK: "Фолклендские (Мальвинские) острова",
    FO: "Фарерские острова",
    FJ: "Фиджи",
    FI: "Финляндия",
    FR: "Франция",
    PF: "Французская Полинезия",
    GA: "Габон",
    GM: "Гамбия",
    GE: "Грузия",
    DE: "Германия",
    GH: "Гана",
    GI: "Гибралтар",
    GR: "Греция",
    GL: "Гренландия",
    GD: "Гренада",
    GU: "Гуам",
    GT: "Гватемала",
    GG: "Гернси",
    GN: "Гвинея",
    GW: "Гвинея-Бисау",
    HT: "Гаити",
    HN: "Гондурас",
    HK: "Гонконг",
    HU: "Венгрия",
    IS: "Исландия",
    IN: "Индия",
    ID: "Индонезия",
    IR: "Иран, Исламская Республика",
    IQ: "Ирак",
    IE: "Ирландия",
    IM: "Остров Мэн",
    IL: "Израиль",
    IT: "Италия",
    JM: "Ямайка",
    JP: "Япония",
    JE: "Джерси",
    JO: "Иордания",
    KZ: "Казахстан",
    KE: "Кения",
    KI: "Кирибати",
    KP: "Северная Корея",
    KR: "Южная Корея",
    KW: "Кувейт",
    KG: "Кыргызстан",
    LA: "Лаосская Народно-Демократическая Республика",
    LV: "Латвия",
    LB: "Ливан",
    LS: "Лесото",
    LR: "Либерия",
    LY: "Ливия",
    LI: "Лихтенштейн",
    LT: "Литва",
    LU: "Люксембург",
    MO: "Макао",
    MK: "Республика Македония",
    MG: "Мадагаскар",
    MW: "Малави",
    MY: "Малайзия",
    MV: "Мальдивы",
    ML: "Мали",
    MT: "Мальта",
    MH: "Маршалловы острова",
    MQ: "Мартиника",
    MR: "Мавритания",
    MU: "Маврикий",
    MX: "Мексика",
    FM: "Микронезия, Федеративные Штаты",
    MD: "Республика Молдова",
    MC: "Монако",
    MN: "Монголия",
    ME: "Черногория",
    MS: "Монтсеррат",
    MA: "Марокко",
    MZ: "Мозамбик",
    MM: "Мьянма",
    NA: "Намибия",
    NR: "Науру",
    NP: "Непал",
    NL: "Нидерланды",
    NZ: "Новая Зеландия",
    NI: "Никарагуа",
    NE: "Нигер",
    NG: "Нигерия",
    NU: "Ниуэ",
    NF: "Остров Норфолк",
    MP: "Северные Марианские острова",
    NO: "Норвегия",
    OM: "Оман",
    PK: "Пакистан",
    PW: "Палау",
    PS: "Палестинская территория",
    PA: "Панама",
    PG: "Папуа - Новая Гвинея",
    PY: "Парагвай",
    PE: "Перу",
    PH: "Филиппины",
    PN: "Питкэрн",
    PL: "Польша",
    PT: "Португалия",
    PR: "Пуэрто-Рико",
    QA: "Катар",
    RO: "Румыния",
    RU: "Россия",
    RW: "Руанда",
    KN: "Сент-Китс и Невис",
    WS: "Самоа",
    SM: "Сан-Марино",
    ST: "Сан-Томе и Принсипи",
    SA: "Саудовская Аравия",
    SN: "Сенегал",
    RS: "Сербия",
    SC: "Сейшелы",
    SL: "Сьерра-Леоне",
    SG: "Сингапур",
    SX: "Синт-Мартен",
    SK: "Словакия",
    SI: "Словения",
    SB: "Соломоновы острова",
    SO: "Сомали",
    ZA: "Южная Африка",
    SS: "Южный Судан",
    ES: "Испания",
    LK: "Шри-Ланка",
    SD: "Судан",
    SR: "Суринам",
    SZ: "Свазиленд",
    SE: "Швеция",
    CH: "Швейцария",
    SY: "Сирия",
    TW: "Тайвань, провинция Китая",
    TJ: "Таджикистан",
    TZ: "Танзания",
    TH: "Таиланд",
    TG: "Идти",
    TK: "Токелау",
    TO: "Тонга",
    TT: "Тринидад и Тобаго",
    TN: "Тунис",
    TR: "Индюк",
    TM: "Туркменистан",
    TC: "Острова Теркс и Кайкос",
    TV: "Тувалу",
    UG: "Уганда",
    UA: "Украина",
    AE: "Объединенные Арабские Эмираты",
    GB: "Соединенное Королевство",
    US: "Соединенные Штаты",
    UY: "Уругвай",
    UZ: "Узбекистан",
    VU: "Вануату",
    VE: "Венесуэла, Боливарианская Республика",
    VN: "Вьетнам",
    VI: "Виргинские острова",
    YE: "Йемен",
    ZM: "Замбия",
    ZW: "Зимбабве",
  },
  de: {
    AF: "Afghanistan",
    AL: "Albanien",
    DZ: "Algerien",
    AS: "Amerikanischen Samoa-Inseln",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua und Barbuda",
    AR: "Argentinien",
    AM: "Armenien",
    AW: "Aruba",
    AU: "Australien",
    AT: "Österreich",
    AZ: "Aserbaidschan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesch",
    BB: "Barbados",
    BY: "Weißrussland",
    BE: "Belgien",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien, Plurinationaler Staat",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BR: "Brasilien",
    IO: "Britisches Territorium des Indischen Ozeans",
    BG: "Bulgarien",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    KY: "Cayman Inseln",
    CF: "Zentralafrikanische Republik",
    TD: "Tschad",
    CL: "Chile",
    CN: "China",
    CO: "Kolumbien",
    KM: "Komoren",
    CG: "Kongo",
    CD: "Demokratische Republik Kongo",
    CK: "Cookinseln",
    CR: "Costa Rica",
    CI: "Elfenbeinküste",
    HR: "Kroatien",
    CU: "Kuba",
    CW: "Curacao",
    CY: "Zypern",
    CZ: "Tschechien",
    DK: "Dänemark",
    DJ: "Dschibuti",
    DM: "Dominica",
    DO: "Dominikanische Republik",
    EC: "Ecuador",
    EG: "Ägypten",
    SV: "El Salvador",
    GQ: "Äquatorialguinea",
    ER: "Eritrea",
    EE: "Estland",
    ET: "Äthiopien",
    FK: "Falklandinseln (Malvinas)",
    FO: "Färöer Inseln",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    PF: "Französisch Polynesien",
    GA: "Gabun",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Deutschland",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Griechenland",
    GL: "Grönland",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Ungarn",
    IS: "Island",
    IN: "Indien",
    ID: "Indonesien",
    IR: "Iran, Islamische Republik",
    IQ: "Irak",
    IE: "Irland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italien",
    JM: "Jamaika",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kasachstan",
    KE: "Kenia",
    KI: "Kiribati",
    KP: "Nord Korea",
    KR: "Südkorea",
    KW: "Kuwait",
    KG: "Kirgisistan",
    LA: "Demokratische Volksrepublik Laos",
    LV: "Lettland",
    LB: "Libanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MO: "Macao",
    MK: "Republik Mazedonien",
    MG: "Madagaskar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshallinseln",
    MQ: "Martinique",
    MR: "Mauretanien",
    MU: "Mauritius",
    MX: "Mexiko",
    FM: "Mikronesien, Föderierte Staaten von",
    MD: "Republik Moldawien",
    MC: "Monaco",
    MN: "Mongolei",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Marokko",
    MZ: "Mosambik",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Niederlande",
    NZ: "Neuseeland",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolkinsel",
    MP: "Nördliche Marianneninseln",
    NO: "Norwegen",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palästinensisches Gebiet",
    PA: "Panama",
    PG: "Papua Neu-Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippinen",
    PN: "Pitcairn",
    PL: "Polen",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Katar",
    RO: "Rumänien",
    RU: "Russland",
    RW: "Ruanda",
    KN: "St. Kitts und Nevis",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome und Principe",
    SA: "Saudi Arabien",
    SN: "Senegal",
    RS: "Serbien",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SX: "Sint Maarten",
    SK: "Slowakei",
    SI: "Slowenien",
    SB: "Salomon-Inseln",
    SO: "Somalia",
    ZA: "Südafrika",
    SS: "Südsudan",
    ES: "Spanien",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SZ: "Swasiland",
    SE: "Schweden",
    CH: "Schweiz",
    SY: "Syrien",
    TW: "Taiwan, Provinz Chinas",
    TJ: "Tadschikistan",
    TZ: "Tansania",
    TH: "Thailand",
    TG: "Gehen",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad und Tobago",
    TN: "Tunesien",
    TR: "Truthahn",
    TM: "Turkmenistan",
    TC: "Turks- und Caicosinseln",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "Vereinigte Arabische Emirate",
    GB: "Großbritannien",
    US: "Vereinigte Staaten",
    UY: "Uruguay",
    UZ: "Usbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarische Republik",
    VN: "Vietnam",
    VI: "Jungferninseln",
    YE: "Jemen",
    ZM: "Sambia",
    ZW: "Zimbabwe",
  },
  it: {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "Samoa americane",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua e Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaigian",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Bielorussia",
    BE: "Belgio",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Stato plurinazionale di",
    BA: "Bosnia Erzegovina",
    BW: "Botswana",
    BR: "Brasile",
    IO: "Territorio britannico dell Oceano Indiano",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambogia",
    CM: "Camerun",
    CA: "Canada",
    CV: "Capo Verde",
    KY: "Isole Cayman",
    CF: "Repubblica Centrafricana",
    TD: "Ciad",
    CL: "Cile",
    CN: "Cina",
    CO: "Colombia",
    KM: "Comore",
    CG: "Congo",
    CD: "Repubblica Democratica del Congo",
    CK: "Isole Cook",
    CR: "Costa Rica",
    CI: "Costa d'Avorio",
    HR: "Croazia",
    CU: "Cuba",
    CW: "Curacao",
    CY: "Cipro",
    CZ: "Repubblica Ceca",
    DK: "Danimarca",
    DJ: "Gibuti",
    DM: "Dominica",
    DO: "Repubblica Dominicana",
    EC: "Ecuador",
    EG: "Egitto",
    SV: "El Salvador",
    GQ: "Guinea Equatoriale",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Etiopia",
    FK: "Isole Falkland (Malvinas)",
    FO: "Isole Faroe",
    FJ: "Figi",
    FI: "Finlandia",
    FR: "Francia",
    PF: "Polinesia francese",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germania",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GL: "Groenlandia",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Ungheria",
    IS: "Islanda",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Repubblica Islamica del",
    IQ: "Iraq",
    IE: "Irlanda",
    IM: "Isola di Man",
    IL: "Israele",
    IT: "Italia",
    JM: "Giamaica",
    JP: "Giappone",
    JE: "Jersey",
    JO: "Giordania",
    KZ: "Kazakistan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Corea del nord",
    KR: "Corea del Sud",
    KW: "Kuwait",
    KG: "Kirghizistan",
    LA: "Repubblica democratica popolare del Laos",
    LV: "Lettonia",
    LB: "Libano",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Lussemburgo",
    MO: "Macao",
    MK: "Repubblica di Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldive",
    ML: "Mali",
    MT: "Malta",
    MH: "Isole Marshall",
    MQ: "Martinica",
    MR: "Mauritania",
    MU: "Maurizio",
    MX: "Messico",
    FM: "Micronesia, Stati federati di",
    MD: "Repubblica di Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Marocco",
    MZ: "Mozambico",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Olanda",
    NZ: "Nuova Zelanda",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Isola Norfolk",
    MP: "Isole Marianne settentrionali",
    NO: "Norvegia",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Territorio palestinese",
    PA: "Panama",
    PG: "Papua Nuova Guinea",
    PY: "Paraguay",
    PE: "Perù",
    PH: "Filippine",
    PN: "Pitcairn",
    PL: "Polonia",
    PT: "Portogallo",
    PR: "Porto Rico",
    QA: "Qatar",
    RO: "Romania",
    RU: "Russia",
    RW: "Ruanda",
    KN: "Saint Kitts e Nevis",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome e Principe",
    SA: "Arabia Saudita",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten",
    SK: "Slovacchia",
    SI: "Slovenia",
    SB: "Isole Salomone",
    SO: "Somalia",
    ZA: "Sud Africa",
    SS: "Sudan del Sud",
    ES: "Spagna",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SZ: "Swaziland",
    SE: "Svezia",
    CH: "Svizzera",
    SY: "Siria",
    TW: "Taiwan, provincia della Cina",
    TJ: "Tagikistan",
    TZ: "Tanzania",
    TH: "Tailandia",
    TG: "Andare",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad e Tobago",
    TN: "Tunisia",
    TR: "Tacchino",
    TM: "Turkmenistan",
    TC: "Isole Turks e Caicos",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ucraina",
    AE: "Emirati Arabi Uniti",
    GB: "Regno Unito",
    US: "Stati Uniti",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Repubblica Bolivariana di",
    VN: "Viet Nam",
    VI: "Isole Vergini",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  uk: {
    AF: "Афганістан",
    AL: "Албанія",
    DZ: "Алжир",
    AS: "Американське Самоа",
    AD: "Андорра",
    AO: "Ангола",
    AI: "Ангілья",
    AG: "Антигуа та Барбуда",
    AR: "Аргентина",
    AM: "Вірменія",
    AW: "Аруба",
    AU: "Австралія",
    AT: "Австрія",
    AZ: "Азербайджан",
    BS: "Багами",
    BH: "Бахрейн",
    BD: "Бангладеш",
    BB: "Барбадос",
    BY: "Білорусь",
    BE: "Бельгія",
    BZ: "Беліз",
    BJ: "Бенін",
    BM: "Бермудські острови",
    BT: "Бутан",
    BO: "Болівія, багатонаціональний штат",
    BA: "Боснія і Герцеговина",
    BW: "Ботсвана",
    BR: "Бразилія",
    IO: "Британська територія в Індійському океані",
    BG: "Болгарія",
    BF: "Буркіна-Фасо",
    BI: "Бурунді",
    KH: "Камбоджа",
    CM: "Камерун",
    CA: "Канада",
    CV: "Кабо-Верде",
    KY: "Кайманові острови",
    CF: "Центральноафриканська Республіка",
    TD: "Чад",
    CL: "Чилі",
    CN: "Китай",
    CO: "Колумбія",
    KM: "Коморські острови",
    CG: "Конго",
    CD: "Демократична Республіка Конго",
    CK: "Острови Кука",
    CR: "Коста-Ріка",
    CI: "Кот-д'Івуар",
    HR: "Хорватія",
    CU: "Куба",
    CW: "Кюрасао",
    CY: "Кіпр",
    CZ: "Чеська Республіка",
    DK: "Данія",
    DJ: "Джибуті",
    DM: "Домініка",
    DO: "Домініканська республіка",
    EC: "Еквадор",
    EG: "Єгипет",
    SV: "Сальвадор",
    GQ: "Екваторіальна Гвінея",
    ER: "Еритрея",
    EE: "Естонія",
    ET: "Ефіопія",
    FK: "Фолклендські острови (Мальвінські острови)",
    FO: "Фарерські острови",
    FJ: "Фіджі",
    FI: "Фінляндія",
    FR: "Франція",
    PF: "Французька Полінезія",
    GA: "Габон",
    GM: "Гамбія",
    GE: "Грузія",
    DE: "Німеччина",
    GH: "Гана",
    GI: "Гібралтар",
    GR: "Греція",
    GL: "Гренландія",
    GD: "Гренада",
    GU: "Гуам",
    GT: "Гватемала",
    GG: "Гернсі",
    GN: "Гвінея",
    GW: "Гвінея-Бісау",
    HT: "Гаїті",
    HN: "Гондурас",
    HK: "Гонконг",
    HU: "Угорщина",
    IS: "Ісландія",
    IN: "Індія",
    ID: "Індонезія",
    IR: "Іран, Ісламська Республіка",
    IQ: "Ірак",
    IE: "Ірландія",
    IM: "Острів Мен",
    IL: "Ізраїль",
    IT: "Італія",
    JM: "Ямайка",
    JP: "Японія",
    JE: "Джерсі",
    JO: "Йорданія",
    KZ: "Казахстан",
    KE: "Кенія",
    KI: "Кірібаті",
    KP: "Північна Корея",
    KR: "Південна Корея",
    KW: "Кувейт",
    KG: "Киргизстан",
    LA: "Лаоська Народно-Демократична Республіка",
    LV: "Латвія",
    LB: "Ліван",
    LS: "Лесото",
    LR: "Ліберія",
    LY: "Лівія",
    LI: "Ліхтенштейн",
    LT: "Литва",
    LU: "Люксембург",
    MO: "Макао",
    MK: "Республіка Македонія",
    MG: "Мадагаскар",
    MW: "Малаві",
    MY: "Малайзія",
    MV: "Мальдіви",
    ML: "Малі",
    MT: "Мальта",
    MH: "Маршаллові острови",
    MQ: "Мартініка",
    MR: "Мавританія",
    MU: "Маврикій",
    MX: "Мексика",
    FM: "Мікронезія, Федеративні Штати",
    MD: "Республіка Молдова",
    MC: "Монако",
    MN: "Монголія",
    ME: "Чорногорія",
    MS: "Монтсеррат",
    MA: "Марокко",
    MZ: "Мозамбік",
    MM: "М'янма",
    NA: "Намібія",
    NR: "Науру",
    NP: "Непал",
    NL: "Нідерланди",
    NZ: "Нова Зеландія",
    NI: "Нікарагуа",
    NE: "Нігер",
    NG: "Нігерія",
    NU: "Ніуе",
    NF: "Острів Норфолк",
    MP: "Північні Маріанські острови",
    NO: "Норвегія",
    OM: "Оман",
    PK: "Пакистан",
    PW: "Палау",
    PS: "Палестинська територія",
    PA: "Панама",
    PG: "Папуа-Нова Гвінея",
    PY: "Парагвай",
    PE: "Перу",
    PH: "Філіппіни",
    PN: "Піткерн",
    PL: "Польща",
    PT: "Португалія",
    PR: "Пуерто-Рико",
    QA: "Катар",
    RO: "Румунія",
    RU: "Росія",
    RW: "Руанда",
    KN: "Сент-Кітс і Невіс",
    WS: "Самоа",
    SM: "Сан-Марино",
    ST: "Сан-Томе і Принсіпі",
    SA: "Саудівська Аравія",
    SN: "Сенегал",
    RS: "Сербія",
    SC: "Сейшели",
    SL: "Сьєрра-Леоне",
    SG: "Сінгапур",
    SX: "Сінт Маартен",
    SK: "Словаччина",
    SI: "Словенія",
    SB: "Соломонові острови",
    SO: "Сомалі",
    ZA: "Південна Африка",
    SS: "Південний Судан",
    ES: "Іспанія",
    LK: "Шрі Ланка",
    SD: "Судан",
    SR: "Суринам",
    SZ: "Свазіленд",
    SE: "Швеція",
    CH: "Швейцарія",
    SY: "Сирія",
    TW: "Тайвань, провінція Китаю",
    TJ: "Таджикистан",
    TZ: "Танзанія",
    TH: "Таїланд",
    TG: "Йти",
    TK: "Токелау",
    TO: "Тонга",
    TT: "Тринідад і Тобаго",
    TN: "Туніс",
    TR: "Туреччина",
    TM: "Туркменістан",
    TC: "Острови Теркс і Кайкос",
    TV: "Тувалу",
    UG: "Уганда",
    UA: "Україна",
    AE: "Об'єднані Арабські Емірати",
    GB: "Об'єднане Королівство",
    US: "Сполучені Штати",
    UY: "Уругвай",
    UZ: "Узбекистан",
    VU: "Вануату",
    VE: "Венесуела, Боліваріанська Республіка",
    VN: "В'єтнам",
    VI: "Віргінські острови",
    YE: "Ємен",
    ZM: "Замбія",
    ZW: "Зімбабве",
  },
};
