import { ROLES } from "../../services/authentication";
import { showNotificationAction } from "./NotificationActions";
import teamPageAction from "./TeamPageAction";
import { getUserTeams, userInvite } from "../../api/user";
import {
  getCurrentTeam,
  getCurrentTeamDetailedData,
  getTeamUsersStatus,
  switchTeam,
  getUsersRoles,
} from "../../api/team";
import { SUBSCRIPTIONS } from "../../constants/constants";

const formatUserTeamsData = (responseData) =>
  responseData.data.user_team.map((item) => item.team);

// const newFormatUserTeamsData = (responseData) =>
//   responseData.data.user_team.map((item) => item);

const formatCurrentTeamData = (responseData) => {
  const {
    team,
    role_collaboration,
    plan,
    product_id,
    subscription_end,
    trial_period_end,
    subscription_plan,
    subscription_id,
  } = responseData.data.user_team[0];

  const userPlan = SUBSCRIPTIONS.find((item) => plan.plan_name === item.name);

  return {
    id: team.id,
    name: team.name,
    role: role_collaboration.title,
    owner_id: team.owner_id,
    plan: plan,
    userPlan: userPlan,
    productId: product_id,
    subscriptionId: subscription_id,
    subscriptionPlan: subscription_plan,
    subscriptionEnd: subscription_end,
    trial: trial_period_end,
  };
};

export const GET_USER_TEAMS_REQUEST = "GET_USER_TEAMS_REQUEST";
export const GET_USER_TEAMS_REQUEST_SUCCESS = "GET_USER_TEAMS_REQUEST_SUCCESS";
export const GET_USER_TEAMS_REQUEST_FAIL = "GET_USER_TEAMS_REQUEST_FAIL";

export const GET_NEW_USER_TEAMS_REQUEST = "GET_NEW_USER_TEAMS_REQUEST";
export const GET_NEW_USER_TEAMS_REQUEST_SUCCESS =
  "GET_NEW_USER_TEAMS_REQUEST_SUCCESS";
export const GET_NEW_USER_TEAMS_REQUEST_FAIL =
  "GET_NEW_USER_TEAMS_REQUEST_FAIL";

export const GET_CURRENT_TEAM_REQUEST = "GET_CURRENT_TEAM_REQUEST";
export const GET_CURRENT_TEAM_REQUEST_SUCCESS =
  "GET_CURRENT_TEAM_REQUEST_SUCCESS";
export const GET_CURRENT_TEAM_REQUEST_FAIL = "GET_CURRENT_TEAM_REQUEST_FAIL";

export const GET_TEAM_USERS_STATUS_REQUEST = "GET_TEAM_USERS_STATUS_REQUEST";
export const GET_TEAM_USERS_STATUS_REQUEST_SUCCESS =
  "GET_TEAM_USERS_STATUS_REQUEST_SUCCESS";
export const GET_TEAM_USERS_STATUS_REQUEST_FAIL =
  "GET_TEAM_USERS_STATUS_REQUEST_FAIL";

export const GET_CURRENT_TEAM_DETAILED_DATA_REQUEST =
  "GET_CURRENT_TEAM_DETAILED_DATA_REQUEST";
export const GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_SUCCESS =
  "GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_SUCCESS";
export const GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_FAIL =
  "GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_FAIL";

export const ADD_USER_TO_CURRENT_TEAM_REQUEST =
  "ADD_USER_TO_CURRENT_TEAM_REQUEST";
export const ADD_USER_TO_CURRENT_TEAM_SUCCESS =
  "ADD_USER_TO_CURRENT_TEAM_SUCCESS";
export const ADD_USER_TO_CURRENT_TEAM_ERROR = "ADD_USER_TO_CURRENT_TEAM_ERROR";

export const SWITCH_TEAM_REQUEST = "TEAM_SWITCH_REQUEST";
export const SWITCH_TEAM_REQUEST_SUCCESS = "TEAM_SWITCH_REQUEST_SUCCESS";
export const SWITCH_TEAM_REQUEST_FAIL = "TEAM_SWITCH_REQUEST_FAIL";

export const GET_USERS_ROLES_REQUEST = "GET_USERS_ROLES_REQUEST";
export const GET_USERS_ROLES_REQUEST_SUCCESS =
  "GET_USERS_ROLES_REQUEST_SUCCESS";
export const GET_USERS_ROLES_REQUEST_ERROR = "GET_USERS_ROLES_REQUEST_ERROR";

export const CHANGE_DATE_ON_THE_REPORTS_CALENDAR =
  "CHANGE_DATE_ON_THE_REPORTS_CALENDAR";

//CHANGE_DATE_ON_THE_REPORTS_CALENDAR
export const changeDateOnTheReportsCalendar = (payload) => ({
  type: CHANGE_DATE_ON_THE_REPORTS_CALENDAR,
  payload,
});
//GET_USER_ROLES
const getUsersRolesRequest = () => ({
  type: GET_USERS_ROLES_REQUEST,
});

const getUsersRolesRequestSuccess = (payload) => ({
  type: GET_USERS_ROLES_REQUEST_SUCCESS,
  payload,
});

const getUsersRolesRequestError = () => ({
  type: GET_USERS_ROLES_REQUEST_ERROR,
});

// GET_USER_TEAMS_REQUEST
const getUserTeamsRequest = () => ({
  type: GET_USER_TEAMS_REQUEST,
});

const getUserTeamsRequestSuccess = (payload) => ({
  type: GET_USER_TEAMS_REQUEST_SUCCESS,
  payload,
});

const getUserTeamsRequestFail = (error) => ({
  type: GET_USER_TEAMS_REQUEST_FAIL,
  error,
});

const getNewUserTeamsRequest = () => ({
  type: GET_NEW_USER_TEAMS_REQUEST,
});

const getNewUserTeamsRequestSuccess = (payload) => ({
  type: GET_NEW_USER_TEAMS_REQUEST_SUCCESS,
  payload,
});

const getNewUserTeamsRequestFail = (error) => ({
  type: GET_NEW_USER_TEAMS_REQUEST_FAIL,
  error,
});

export const getUserTeamsAction = () => async (dispatch) => {
  dispatch(getUserTeamsRequest());
  try {
    const { data } = await getUserTeams();

    dispatch(getUserTeamsRequestSuccess(formatUserTeamsData(data)));
  } catch (error) {
    dispatch(getUserTeamsRequestFail(error));
  }
};

// GET_NEW_USER_TEAMS
export const newGetUserTeamsAction = () => async (dispatch) => {
  dispatch(getNewUserTeamsRequest());
  try {
    const { data } = await getUserTeams();

    dispatch(getNewUserTeamsRequestSuccess(data.data));
  } catch (error) {
    dispatch(getNewUserTeamsRequestFail(error));
  }
};

// GET_CURRENT_TEAM_REQUEST
const getCurrentTeamRequest = () => ({
  type: GET_CURRENT_TEAM_REQUEST,
});

const getCurrentTeamRequestSuccess = (payload) => ({
  type: GET_CURRENT_TEAM_REQUEST_SUCCESS,
  payload,
});

const getCurrentTeamRequestFail = (error) => ({
  type: GET_CURRENT_TEAM_REQUEST_FAIL,
  error,
});

export const getCurrentTeamAction = () => async (dispatch) => {
  dispatch(getCurrentTeamRequest());
  try {
    const { data } = await getCurrentTeam();

    dispatch(getCurrentTeamRequestSuccess(formatCurrentTeamData(data)));
  } catch (error) {
    dispatch(getCurrentTeamRequestFail(error));
  }
};

// GET_CURRENT_TEAM_DETAILED_DATA_REQUEST
const getCurrentTeamDetailedDataRequest = () => ({
  type: GET_CURRENT_TEAM_DETAILED_DATA_REQUEST,
});

const getCurrentTeamDetailedDataRequestSuccess = (payload) => ({
  type: GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_SUCCESS,
  payload,
});

const getCurrentTeamDetailedDataRequestFail = (error) => ({
  type: GET_CURRENT_TEAM_DETAILED_DATA_REQUEST_FAIL,
  error,
});

export const getCurrentTeamDetailedDataAction = () => async (dispatch) => {
  dispatch(getCurrentTeamDetailedDataRequest());
  try {
    const { data } = await getCurrentTeamDetailedData();

    dispatch(
      getCurrentTeamDetailedDataRequestSuccess(data.user_team),
    );
  } catch (error) {
    dispatch(getCurrentTeamDetailedDataRequestFail(error));
  }
};

// GET_TEAM_USERS_STATUS_REQUEST
const getTeamUsersStatusRequest = () => ({
  type: GET_TEAM_USERS_STATUS_REQUEST,
});

const getTeamUsersStatusRequestSuccess = (payload) => ({
  type: GET_TEAM_USERS_STATUS_REQUEST_SUCCESS,
  payload,
});

const getTeamUsersStatusRequestFail = (error) => ({
  type: GET_TEAM_USERS_STATUS_REQUEST_FAIL,
  error,
});

export const getTeamUsersStatusAction = () => async (dispatch) => {
  dispatch(getTeamUsersStatusRequest());
  try {
    const { data } = await getTeamUsersStatus();

    dispatch(getTeamUsersStatusRequestSuccess(data));
  } catch (error) {
    dispatch(getTeamUsersStatusRequestFail(error));
  }
};

export const addUserToCurrentTeamRequest = () => ({
  type: ADD_USER_TO_CURRENT_TEAM_REQUEST,
});

export const addUserToCurrentTeamSuccess = () => ({
  type: ADD_USER_TO_CURRENT_TEAM_SUCCESS,
});

export const addUserToCurrentTeamError = () => ({
  type: ADD_USER_TO_CURRENT_TEAM_ERROR,
});

// TEAM_SWITCH_REQUEST
const switchTeamRequest = () => ({
  type: SWITCH_TEAM_REQUEST,
});

const switchTeamRequestSuccess = () => ({
  type: SWITCH_TEAM_REQUEST_SUCCESS,
});

const switchTeamRequestFail = (error) => ({
  type: SWITCH_TEAM_REQUEST_FAIL,
  error,
});

export const switchTeamRequestAction = (data) => async (dispatch) => {
  dispatch(switchTeamRequest());

  try {
    await switchTeam(data);

    dispatch(getUserTeamsAction());
    dispatch(getCurrentTeamAction());
    dispatch(getCurrentTeamDetailedDataAction());
    dispatch(switchTeamRequestSuccess());
  } catch (error) {
    dispatch(switchTeamRequestFail());
  }
};

export const addUserToCurrentTeamAction =
  ({ emailsList, projectsIds }) =>
  async (dispatch, getState) => {
    const vocabulary = getState().languageReducer.vocabulary;
    const {
      v_a_invite_sent,
      v_a_invite_sent_error,
      v_a_invite_sent_user_error,
    } = vocabulary;

    dispatch(addUserToCurrentTeamRequest());

    try {
      const response = await userInvite({ emailsList, projectsIds });

      if (response.request.status === 201) {
        dispatch(
          showNotificationAction({ text: v_a_invite_sent, type: "success" }),
        );
        dispatch(
          addUserToCurrentTeamSuccess(),
        );
        dispatch(
          teamPageAction("TOGGLE_ADD_USER_MODAL", { createUserModal: false }),
        );
        dispatch(getCurrentTeamDetailedDataAction());
      } else {
        showNotificationAction({ text: v_a_invite_sent_error, type: "error" });
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        const errorMsg = error.response.data.message;

        dispatch(
          showNotificationAction({ text: vocabulary[errorMsg], type: "error" }),
        );
      } else {
        dispatch(
          showNotificationAction({
            text: v_a_invite_sent_user_error,
            type: "error",
          }),
        );
      }
      dispatch(addUserToCurrentTeamError());
    }
  };

export const getUsersRolesAction = () => async (dispatch) => {
  dispatch(getUsersRolesRequest());
  try {
    const { data } = await getUsersRoles();

    dispatch(getUsersRolesRequestSuccess(data));
  } catch (error) {
    dispatch(getUsersRolesRequestError(error));
  }
};
