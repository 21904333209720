import * as MomentInstance from "moment";
import { extendMoment } from "moment-range";
import {
  convertUTCDateToLocalISOString,
  getDateTimestamp,
} from "./timeService";
import _ from "lodash";
import { IDictionary } from "../types/dictionary";
import {
  IChartObject,
  IGetProjectsDataPayload,
  ILabelsAndTimeData,
  ILabelsAndTimePayload,
  ILabelsAndTimeResponse,
  IProjectDateTime,
  IReportProjects,
  IReportProjectsCross,
  IReportsProject,
  IReportsProjectTimer,
  IReportsProjectTimerV2,
  ISelectionRange,
  IStatsByProjects,
  ISumEntriesByDayPayload,
  IUserOfTeamDiff,
  IUserProjectsData,
  IUserProjectsPeople,
} from "../types/reports";

const moment = extendMoment(MomentInstance);

export const getFormattedDate = (date: Date): string =>
  moment(date).format("YYYY-MM-DD");

export const checkStaticRangeByPlan = (
  label: string,
  userPlan: string,
): boolean => {
  if (!userPlan) {
    return false;
  }

  if (label === "lastMonth" && userPlan === "Free") {
    return true;
  }
  if (label === "thisYear" && (userPlan === "Free")) {
    return true;
  }
  if (
    label === "lastYear" &&
    (userPlan === "Free")
  ) {
    return true;
  }
  return false;
};

export const getArrOfProjectsData = ({
  startDate,
  endDate,
  isCrossTeam,
  data,
}: IGetProjectsDataPayload): {
  statsByProjects: IStatsByProjects[];
  statsByDates: IDictionary<number>;
} => {
  const statsByProjects: IStatsByProjects[] = [];
  const statsByDates: IDictionary<number> = getDatesListBetweenStartEndDates(
    startDate,
    endDate,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dataForCalculate = data;

  dataForCalculate.forEach((project: IReportProjectsCross) => {
    const newProjectsTimer = project.timer_v2;
    let diff = 0;

    newProjectsTimer.forEach((timer: any, j: number) => {
      const timerDiff =
        getDateTimestamp(timer.end_datetime) -
        getDateTimestamp(timer.start_datetime);

      diff += timerDiff;

      const date = timer.start_datetime.split("T")[0];

      (statsByDates[date] as any) += timerDiff;
    });

    if (diff) {
      statsByProjects.push({
        name: project.name,
        duration: diff,
      });
    }
  });

  return { statsByProjects, statsByDates };
};

export const getDatesListBetweenStartEndDates = (
  startDate: string,
  endDate: string | MomentInstance.Moment,
): IDictionary<number> => {
  const dateObj: IDictionary<number> = {};
  let currentDate: MomentInstance.Moment = moment(startDate);

  endDate = moment(endDate);

  while (currentDate <= endDate) {
    dateObj[`${moment(currentDate).format("YYYY-MM-DD")}`] = 0;
    currentDate = moment(currentDate).add(1, "days");
  }

  return dateObj;
};

const getTotalTimeByMonth = (test: any): number[] => {
  const entries = Object.keys(test).map((item) => {
    const taskArr = test[item];

    if (taskArr.length) {
      return taskArr.reduce((acc: number, curr: IProjectDateTime) => {
        const { start_datetime: startDatetime, end_datetime: endDatetime } =
          curr;
        const startDatetimeLocalISO =
          convertUTCDateToLocalISOString(startDatetime);
        const endDatetimeLocalISO = convertUTCDateToLocalISOString(endDatetime);

        const diff =
          getDateTimestamp(endDatetimeLocalISO) -
          getDateTimestamp(startDatetimeLocalISO);

        return (acc += diff);
      }, 0);
    } else {
      return 0;
    }
  });

  return entries;
};

export const getSumTimeEntriesByDay = ({
  datePeriod,
  timeEntries,
  selectionRange,
}: ISumEntriesByDayPayload): IDictionary<number[]> => {
  const sumTimeEntriesByDay: IDictionary<number> = {};
  const sumBillableTimeEntriesByDay: IDictionary<number> = {};

  if (checkYearPeriod({ selectionRange })) {
    const sumTimeEntriesByMonth: IDictionary<IProjectDateTime[]> = {};
    const sumBillableTimeEntriesByMonth: IDictionary<IProjectDateTime[]> = {};
    const copyTimeEntries: IProjectDateTime[] = _.cloneDeep(timeEntries);
    const { startDate, endDate } = selectionRange;
    const range = moment.range(startDate, endDate);
    const months = Array.from(range.by("month"));

    for (let i = 0; i < months.length; i++) {
      const date = months[i].format("YYYY-MM");

      sumTimeEntriesByMonth[date] = [];
      sumBillableTimeEntriesByMonth[date] = [];
    }

    copyTimeEntries.forEach((item: IProjectDateTime) => {
      sumTimeEntriesByMonth[moment(item.start_datetime).format("YYYY-MM")] &&
        sumTimeEntriesByMonth[
          moment(item.start_datetime).format("YYYY-MM")
        ].push(item);

      item.billable &&
        sumBillableTimeEntriesByMonth[
          moment(item.start_datetime).format("YYYY-MM")
        ] &&
        sumBillableTimeEntriesByMonth[
          moment(item.start_datetime).format("YYYY-MM")
        ].push(item);
    });

    const entries = getTotalTimeByMonth(sumTimeEntriesByMonth);
    const entriesBillable = getTotalTimeByMonth(sumBillableTimeEntriesByMonth);

    return { entries, entriesBillable };
  } else {
    for (let i = 0; i < datePeriod.length; i++) {
      const date = datePeriod[i];

      sumTimeEntriesByDay[date] = 0;
      sumBillableTimeEntriesByDay[date] = 0;
    }

    if (!timeEntries) {
      return {
        entries: Object.keys(sumTimeEntriesByDay).map(
          (date) => sumTimeEntriesByDay[date],
        ),
        entriesBillable: Object.keys(sumBillableTimeEntriesByDay).map(
          (date) => sumBillableTimeEntriesByDay[date],
        ),
      };
    }

    for (let i = 0; i < timeEntries.length; i++) {
      const {
        start_datetime: startDatetime,
        end_datetime: endDatetime,
        billable,
      } = timeEntries[i];
      const startDatetimeLocalISO =
        convertUTCDateToLocalISOString(startDatetime);
      const endDatetimeLocalISO = convertUTCDateToLocalISOString(endDatetime);

      const diff =
        getDateTimestamp(endDatetimeLocalISO) -
        getDateTimestamp(startDatetimeLocalISO);

      if (diff) {
        sumTimeEntriesByDay[startDatetimeLocalISO.split("T")[0]] += diff;

        if (billable) {
          sumBillableTimeEntriesByDay[startDatetimeLocalISO.split("T")[0]] +=
            diff;
        }
      }
    }

    return {
      entries: Object.keys(sumTimeEntriesByDay).map(
        (date) => sumTimeEntriesByDay[date],
      ),
      entriesBillable: Object.keys(sumBillableTimeEntriesByDay).map(
        (date) => sumBillableTimeEntriesByDay[date],
      ),
    };
  }
};

export const checkYearPeriod = ({
  selectionRange,
}: {
  selectionRange: ISelectionRange;
}): boolean => {
  const { startDate, endDate } = selectionRange;
  const range = moment.range(startDate, endDate);
  const months = Array.from(range.by("month"));

  return months.length >= 12;
};

interface IUserTeamData {
  projects: {
    duration: number;
    name: string;
  }[];
  team: string;
  total: number;
  username: string;
}

export const getArrOfUserTeamData = (
  data: IReportProjectsCross[],
): IUserTeamData[] => {
  if (!data) return [];

  const statsByUserTeam: IUserTeamData[] = [];

  data.forEach((project: IReportProjectsCross) => {
    const diff: IUserOfTeamDiff[] = [];

    project.timer_v2.forEach((timer: IReportsProjectTimerV2) => {
      const timerDiff =
        getDateTimestamp(timer.end_datetime) -
        getDateTimestamp(timer.start_datetime);

      let diffIndex = diff.findIndex(
        (item: IUserOfTeamDiff) => item.team === project.team.name,
      );

      if (diffIndex === -1) {
        diff.push({
          username: timer.user.username,
          team: project.team.name,
          value: 0,
        });
        diffIndex = diff.length - 1;
      }
      diff[diffIndex].value += timerDiff;
    });

    if (diff.length) {
      diff.forEach((user: IUserOfTeamDiff) => {
        let index = statsByUserTeam.findIndex(
          (item: IUserTeamData) => item.team === user.team,
        );

        if (index === -1) {
          statsByUserTeam.push({
            username: user.username,
            team: user.team,
            total: 0,
            projects: [],
          });
          index = statsByUserTeam.length - 1;
        }
        statsByUserTeam[index].total += user.value;
        statsByUserTeam[index].projects.push({
          name: project.name,
          duration: user.value,
        });
      });
    }
  });

  return statsByUserTeam;
};

export const getArrOfUserTeamProjectsDataForPeople = (
  data: IReportProjectsCross[],
): IDictionary<any> => {
  const statsByUserProjects: IUserProjectsPeople[] = [];

  data.forEach((project: IReportProjectsCross) => {
    const diff: IUserOfTeamDiff[] = [];

    project.timer_v2.forEach((timer: IReportsProjectTimerV2) => {
      const timerDiff =
        getDateTimestamp(timer.end_datetime) -
        getDateTimestamp(timer.start_datetime);
      let diffIndex = diff.findIndex(
        (item: any) => item.email === timer.user.email,
      );

      if (diffIndex === -1) {
        diff.push({
          username: timer.user.username,
          email: timer.user.email,
          team: project.team.name,
          value: 0,
        });
        diffIndex = diff.length - 1;
      }
      diff[diffIndex].value += timerDiff;
    });

    if (diff.length) {
      diff.forEach((user: any) => {
        let index = statsByUserProjects.findIndex(
          (item: IUserProjectsPeople) => item.email === user.email,
        );

        if (index === -1) {
          statsByUserProjects.push({
            username: user.username,
            email: user.email,
            team: user.team,
            total: 0,
            projects: [],
          });
          index = statsByUserProjects.length - 1;
        }
        statsByUserProjects[index].total += user.value;
        statsByUserProjects[index].projects.push({
          name: project.name,
          duration: user.value,
          team: project.team.name,
          teamId: project.team.id,
        });
      });
    }
  });

  const getArrProjectsInUserTeams = statsByUserProjects.reduce(
    (result: any, userData: any) => {
      result[userData.username] = userData.projects.reduce(
        (acc: any, proj: any) => {
          acc["total"] = userData.total;
          acc["email"] = userData.email;
          acc["username"] = userData.username;
          acc[proj.team] = acc[proj.team]
            ? [
                ...acc[proj.team],
                {
                  teamId: proj.teamId,
                  name: proj.name,
                  duration: proj.duration,
                },
              ]
            : [
                {
                  teamId: proj.teamId,
                  name: proj.name,
                  duration: proj.duration,
                },
              ];
          return acc;
        },
        {},
      );

      return result;
    },
    {},
  );

  return getArrProjectsInUserTeams;
};

export const saveFile = (url: string): void => {
  const a = document.createElement("a");

  a.setAttribute("id", "file-report-button");

  document.body.appendChild(a);
  a.href = url;
  a.click();
  window.URL.revokeObjectURL(url);

  setTimeout(() => {
    a.remove();
  }, 200);
};

export const getArrOfUserProjectsData = (
  data: IReportProjects[],
): IUserProjectsData[] => {
  const statsByUserProjects: any = [];

  data.forEach((project: any) => {
    const diff: any = [];

    project.timer_v2.forEach((timer: IReportsProjectTimer) => {
      const timerDiff =
        getDateTimestamp(timer.end_datetime) -
        getDateTimestamp(timer.start_datetime);
      let diffIndex = diff.findIndex(
        (item: any) => item.email === timer.user.email,
      );

      if (diffIndex === -1) {
        diff.push({
          username: timer.user.username,
          email: timer.user.email,
          value: 0,
        });
        diffIndex = diff.length - 1;
      }
      diff[diffIndex].value += timerDiff;
    });

    if (diff.length) {
      diff.forEach((user: any) => {
        let index = statsByUserProjects.findIndex(
          (item: any) => item.email === user.email,
        );

        if (index === -1) {
          statsByUserProjects.push({
            username: user.username,
            email: user.email,
            total: 0,
            projects: [],
          });
          index = statsByUserProjects.length - 1;
        }
        statsByUserProjects[index].total += user.value;
        statsByUserProjects[index].projects.push({
          name: project.name,
          duration: user.value,
        });
      });
    }
  });

  return statsByUserProjects;
};

export const getLablesAndTime = ({
  labels,
  time,
  billableTime,
  dateFormat,
  selectionRange,
}: ILabelsAndTimePayload): ILabelsAndTimeResponse => {
  const deletedYearFromString = (str: string) =>
    str[0] === "Y" ? str.slice(5, 10) : str.slice(0, 5);

  const finishData: ILabelsAndTimeData = {
    labels: [],
    timeArr: [],
  };

  if (checkYearPeriod({ selectionRange })) {
    const toUpperCaseFirstLetter = (str: string) =>
      str[0].toUpperCase() + str.slice(1);
    const { startDate, endDate } = selectionRange;
    const range = moment.range(startDate, endDate);
    const months = Array.from(range.by("month")).map((m) =>
      toUpperCaseFirstLetter(m.format("MMMM")),
    );

    finishData.labels = months;
  } else {
    for (let i = 0; i < labels.length; i++) {
      finishData.labels.push(
        moment(labels[i]).format(`ddd ${deletedYearFromString(dateFormat)}`),
      );
    }
  }

  const totalTime = time.length ? time.reduce((a: any, b: any) => a + b) : 0;
  const totalBillableTime = billableTime.length
    ? billableTime.reduce((a: any, b: any) => a + b)
    : 0;

  finishData.timeArr = time;

  return { data: finishData, totalTime, totalBillableTime };
};

export const changeDoughnutChart = (
  chartObject: IChartObject,
  dataFromServer: IStatsByProjects[],
): IChartObject => {
  const newObjectChart = { ...chartObject };
  const labels: string[] = [];
  const dataTime: number[] = [];

  for (let i = 0; i < dataFromServer.length; i++) {
    labels.push(dataFromServer[i].name);
    dataTime.push(dataFromServer[i].duration);
  }
  newObjectChart.labels = labels;
  newObjectChart.datasets[0].data = dataTime;

  return newObjectChart;
};

export const sortByKey = (
  a: IDictionary<any>,
  b: IDictionary<any>,
  key: string,
): number => {
  const nameA = (a[key] || "").trim().toLowerCase();
  const nameB = (b[key] || "").trim().toLowerCase();

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
};

export const getDateForLink = (momentDate: MomentInstance.Moment): string =>
  moment(momentDate).format("YYYY-MM-DD");
