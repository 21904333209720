import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { ChevronDownIcon } from "../../Atoms/SvgIcons";

import { moveTimeline } from "../../../redux/actions/ResourcePlanningActions";

import "./style.scss";

import {
  SHOW_BY_DAY,
  SHOW_BY_MONTH,
  SHOW_BY_WEEK,
  SHOW_BY_YEAR,
} from "../../../constants/timeline";

class ResourcePlanningDateNavigation extends Component {
  constructor(props) {
    super(props);
  }

  handleMoveTimeline = (direction) => {
    this.props.moveTimeline(direction);
  };

  CurrentDateItem = () => {
    const { showBy, vocabulary, currentDate } = this.props;
    const { lang, v_week } = vocabulary;

    switch (showBy) {
      case SHOW_BY_DAY: {
        return (
          <div className="rp-date-navigation__text">
            {moment(currentDate).add(6, 'hours').locale(lang.short).format("DD MMMM YYYY")}
          </div>
        );
      }

      case SHOW_BY_WEEK: {
        const firstDayOfMonth = moment(currentDate).clone().startOf("month");
        const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");
        const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");
        const week = moment(currentDate).add(4, 'days').locale(lang.short).week();
        // offset
        //   ? Math.ceil((moment(currentDate).date() + offset) / 7)
        //   : Math.ceil(moment(currentDate).date() / 7);

        return (
          <div>
            <span className="rp-date-navigation__text">
              {moment(currentDate).add(3, 'days').locale(lang.short).format("MMMM YYYY")}
            </span>
            <span className="rp-date-navigation__text rp-date-navigation__text--secondary">
              &nbsp;/&nbsp;{v_week} {week}
            </span>
          </div>
        );
      }

      case SHOW_BY_MONTH: {
        return (
          <div className="rp-date-navigation__text">
            {moment(currentDate).add(13, 'days').locale(lang.short).format("MMMM YYYY")}
          </div>
        );
      }

      case SHOW_BY_YEAR: {
        return (
          <div className="rp-date-navigation__text">
            {moment(currentDate).add(4, 'months').locale(lang.short).format("YYYY")}
          </div>
        );
      }

      default: {
        return (
          <div className="rp-date-navigation__text">
            {moment(currentDate).locale(lang.short).format("DD MMMM YYYY")}
          </div>
        );
      }
    }
  };

  render() {
    return (
      <div className="rp-date-navigation">
        <button
          type="button"
          className="rp-date-navigation__arrow rp-date-navigation__arrow--left"
          onClick={() => this.handleMoveTimeline("left")}
        >
          <ChevronDownIcon className="rp-date-navigation__icon" color="white" />
        </button>
        <div className="rp-date-navigation__date">{this.CurrentDateItem()}</div>
        <button
          type="button"
          className="rp-date-navigation__arrow rp-date-navigation__arrow--right"
          onClick={() => this.handleMoveTimeline("right")}
        >
          <ChevronDownIcon className="rp-date-navigation__icon" color="white" />
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  showBy: state.resourcePlanningReducer.showBy,
  currentDate: state.resourcePlanningReducer.currentDate,
});

const mapDispatchToProps = {
  moveTimeline,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcePlanningDateNavigation);
