import { instance } from "../configAPI";
import _ from "lodash";

export const deleteTask = (id: any) =>
  instance({
    url: `/timer/${id}`,
    method: "DELETE",
  });

export const getTimeEntriesList = (params: any = {}) =>
  instance({
    url: "/timer/user-list",
    method: "GET",
    cancelToken: params.cancelToken,
    params: _.omit(params, ["cancelToken"]),
  });

// keys for data: issue, projectId, startDatetime, endDatetime, timezoneOffset
export const changeTask = (id: any, data: any) =>
  instance({
    url: `/timer/${id}`,
    method: "PATCH",
    data,
  });

export const getTimerReportsList = ({ startDate, endDate }: any) =>
  instance({
    url: "/timer/reports-list",
    method: "POST",
    data: JSON.stringify({
      startDate,
      endDate,
    }),
  });

export const getUserList = () =>
  instance({
    url: "/timer/reports-list",
    method: "POST",
  });

export const getTimeCount = ({ startDate, endDate }: any) =>
  instance({
    url: "/timer/time-count",
    method: "GET",
    params: {
      startDate,
      endDate,
    },
  });

  export const billableStatusApi=(id: any, status: any)=>
  instance({
    url: `/timer/${id}/billable-status`,
    method: "PATCH",
    data: {
      isBillable: status,
    },
  });
  
  export const getUserActivities=({projectNames, usersEmails, statusFilter}:any)=>
  instance({
    url: `/project/users-activities`,
    method: "GET",
    params: {
      projectNames,
      usersEmails,
      active: statusFilter,
    },
  });

  export const reportsBillableStatusApi=({ userId, billable, startDatetime, endDatetime }: any)=>
  instance({
    url: `/timer/reports/billable`,
    method: "PATCH",
    data: {
      userId,
      billable: billable,
      startDatetime,
      endDatetime,
    },
  });

  export const getNotificationsApi=() =>
  instance({
    url: "/notifications",
    method: "GET",
  });