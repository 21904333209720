import { useCallback, useState } from "react";

export default function useExpandController(initialState) {
  const [isExpand, setExpandState] = useState(initialState);

  const toggle = useCallback(() => {
    setExpandState((state) => !state);
  }, [setExpandState]);

  return { isExpand, toggle };
}
