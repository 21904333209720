import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { history } from "../..";
import cn from "classnames";

// Services
import {
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
} from "../../services/tokenStorageService";
import { getUtcOffsetInMilliseconds } from "../../services/timeService";

// Components
import SwitchLanguageLogin from "../../components/Unstructured/SwitchLanguageLogin";
import RegisterForm from "../../components/Unstructured/RegisterForm";

// Actions
import { resetInitialFetching } from "../../redux/actions/UserActions";
import { showNotificationAction } from "../../redux/actions/NotificationActions";

// Queries

// Config

// Styles
import "./style.scss";
import { signUp } from "../../api/user";
import { AppConfig } from "../../config";
import GoogleButton from "../../components/Unstructured/GoogleButton";
import FacebookButton from "../../components/Unstructured/FacebookButton";
import { Helmet } from "react-helmet";

class RegisterPage extends Component {
  state = {
    emailFromRedirect: null,
    haveToken: false,
    isLoading: false,
    emailFromUrl: null,
    verify: false,
  };

  setHaveToken = () =>
    this.setState({
      haveToken: true,
    });

  toLoginPage = (event) => {
    const { history } = this.props;

    history.push("/login");
  };

  submitForm = async (values) => {
    const { vocabulary, showNotificationAction } = this.props;
    const { v_a_account_create, lang } = vocabulary;

    this.setState({ isLoading: true });
    try {
      const response = await signUp({
        ...values,
        language: lang.short,
        timezoneOffset: getUtcOffsetInMilliseconds(),
      });

      // setTokenToLocalStorage(response.data.token);
      showNotificationAction({
        text: "A letter confirming the mailing address has been sent to your E-mail",
        type: "success",
      });
      this.setState({ verify: true });
    } catch (error) {
      if (error.response && error.response.data.message) {
        const errorMsg = error.response.data.message;

        showNotificationAction({ text: vocabulary[errorMsg], type: "error" });
      } else {
        console.log(error);
      }
    }
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    if (!this.props.isInitialFetching) {
      this.props.resetInitialFetching();
    }

    if (window.location.href.indexOf("email") > 0) {
      const emailInput = window.location.href.split("=");

      this.setState({ emailFromRedirect: emailInput[emailInput.length - 1] });
    }
    this.setState({
      emailFromUrl: new URLSearchParams(history.location.search).get("email"),
    });
  }

  render() {
    const { emailFromRedirect, haveToken, emailFromUrl, verify } = this.state;
    const { vocabulary } = this.props;
    const {
      v_already_have_an_account,
      v_log_in,
      v_registration_terms_and_policy,
      v_or,
    } = vocabulary;

    if (verify){
      return <Redirect to={"/login"} />;
    // if (haveToken && getTokenFromLocalStorage()) {
    //   localStorage.setItem("redirectFrom", "/register");
    //   return <Redirect to={"/thank-you-page"} />;
    // } else if (getTokenFromLocalStorage()) {
    //   return <Redirect to={"/user-settings"} />;
    } else  {
      return (
        <>
        <Helmet>
          <title>Register for Wobbly – Unlock Your Time Management Potential</title>
          <link rel="canonical" href="https://time.wobbly.me/register" />
          <link rel="description" content="Join Wobbly Time to simplify your time management, tracking, and invoicing needs. Our platform offers innovative tools tailored for freelancers, small businesses, and professionals." />
        </Helmet>
        <div
          className={classNames("register-block", {
            "register-block--mobile": true,
          })}
        >
          <div className="fixed_right_corner">
            <SwitchLanguageLogin dropdown />
          </div>
          <i
            className={cn("register-block__logo", {
              "register-block__logo__light":
                localStorage.getItem("themes") === "light",
            })}
          />
          <div className="register_container">
          <RegisterForm
            submitForm={this.submitForm}
            isLoading={this.state.isLoading}
            emailFromRedirect={emailFromRedirect}
            emailFromUrl={emailFromUrl}
          />
          {AppConfig.socialAuth.active && (
            <div>
              <div className="lines">
                <div className="line"/>
                <div className={"or"}>{v_or}</div>
                <div className="line"/>
              </div>
              <div className="social-auth">
                <GoogleButton
                  setHaveToken={this.setHaveToken}
                  login={this.login}
                />
                <FacebookButton
                  setHaveToken={this.setHaveToken}
                  login={this.login}
                />
              </div>

            </div>
          )}
          </div>
          <button
            onClick={this.toLoginPage}
            className="register-block__button register-block__button--to-login"
            type="button"
          >
            {v_already_have_an_account}? {v_log_in}
          </button>
          <a
            href="https://wobbly.me/terms.html"
            className="register-block__link register-block__link--to-conditions"
          >
            {v_registration_terms_and_policy}
          </a>
        </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  isInitialFetching: state.userReducer.isInitialFetching,
});

const mapDispatchToProps = {
  showNotificationAction,
  resetInitialFetching,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage),
);
