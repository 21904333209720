import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import "./style.scss";

const RemoveSvg = ({ ligthMode }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.41016 0.0600899C8.33004 -0.02003 8.1989 -0.02003 8.11878 0.0600899L5.14572 3.03313C5.0656 3.11325 4.93446 3.11325 4.85434 3.03313L1.88133 0.0600899C1.80121 -0.02003 1.67007 -0.02003 1.58995 0.0600899L0.0600902 1.58989C-0.0200301 1.67 -0.0200301 1.80115 0.0600902 1.88127L3.03315 4.85431C3.11327 4.93443 3.11327 5.06557 3.03315 5.14569L0.0600902 8.11873C-0.0200301 8.19885 -0.0200301 8.33 0.0600902 8.41012L1.58989 9.93991C1.67001 10.02 1.80116 10.02 1.88128 9.93991L4.85434 6.96687C4.93446 6.88675 5.0656 6.88675 5.14572 6.96687L8.11872 9.93985C8.19884 10.02 8.32999 10.02 8.41011 9.93985L9.93991 8.41006C10.02 8.32994 10.02 8.1988 9.93991 8.11868L6.96691 5.14569C6.88679 5.06557 6.88679 4.93443 6.96691 4.85431L9.93991 1.88132C10.02 1.8012 10.02 1.67006 9.93991 1.58994L8.41016 0.0600899Z"
      fill={ligthMode ? "#FFFFFF" : "#181818"}
    />
  </svg>
);

const LinkToVacanciesComponent = ({
  userTechnologies,
  setUserTechnologies,
  themeLight,
  vocabulary,
  availableTags,
  disableParam,
  showNotificationAction,
  placeholder = false,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [techList, setTechList] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [searchFullMatch, setSearchFullMatch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const wrapperRef = useRef(null);

  const debouncedSearchValue = searchInput;

  const removeExistingTechnology = (id) => {
    const updatedTechList = techList.filter((item) => item.id !== id);
    setTechList(updatedTechList);
    setUserTechnologies(updatedTechList);
  };
  useEffect(() => {
    setTechList(transformTechnologiesList(userTechnologies));
  }, [userTechnologies]);

  const transformTechnologiesList = (list) => {
    const newList = [];

    list.forEach((item) => {
      if (
        !newList.find((tech) => tech.link === item.link.toLowerCase().trim())
      ) {
        newList.push({ ...item, link: item.link.toLowerCase().trim() });
      }
    });
    return newList;
  };

  const removeUsedTechFromResult = (resArr) =>
    resArr.filter((item) => !techList.some((tech) => tech.id === item.id));

  const addTechnologyToList = (item) => {
    setTechList([...techList, item]);
    setSearchItems([]);
    setSearchInput("");
  };

  const createTechnology = () => {
    const { v_err_technology_exist } = vocabulary;

    if (searchFullMatch) {
      showNotificationAction({ text: v_err_technology_exist, type: "error" });
    } else {
      const newItem = {
        link: searchInput.toLowerCase().trim(),
        id: Date.now(), // Using a timestamp as a temporary unique ID
      };
      setTechList([...techList, newItem]);
      setSearchItems([]);
      setSearchInput("");
      setUserTechnologies([...techList, newItem]);
    }
  };

  const { v_enter_text, v_add_technology } = vocabulary;

  return (
    <div className={classnames("technology-wrapper")}>
      <div
        className={classnames("technology", {
          "technology--light": themeLight,
        })}
      >
        {techList && Boolean(techList.length) && (
          <div className={classnames("technology__list")}>
            {techList.map((technology, index) => (
              <div
                className={classnames("technology__list-tag", {
                  "technology__list-tag--light": themeLight,
                })}
                key={index}
              >
                <span className={classnames("technology__list-tag-title")}>
                  {technology.link}
                </span>
                <span
                  className={classnames("technology__list-tag-remove-icon")}
                  onClick={() => removeExistingTechnology(technology.id)}
                >
                  <RemoveSvg lightMode={themeLight} />
                </span>
              </div>
            ))}
          </div>
        )}
        <div className={classnames("technology__search")}>
          <input
            type="text"
            className={classnames("technology__search-input", {
              "technology__search-input--light": themeLight,
            })}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder={placeholder || v_enter_text}
          />
          {searchInput && Boolean(searchInput.length) && (
            <button
              className={classnames("technology__search-add")}
              onClick={() => createTechnology()}
              disabled={availableTags-disableParam===0}
            >
              {v_add_technology}
            </button>
          )}
        </div>
      </div>
      {searchItems && Boolean(searchItems.length) && (
        <div
          className={classnames("technology__suggestions", {
            "technology__suggestions--light": themeLight,
          })}
          // ref={wrapperRef}
        >
          {searchItems.map((item, index) => (
            <div
              key={index}
              className={classnames("technology__suggestions-item", {
                "technology__suggestions-item--light": themeLight,
              })}
              onClick={() => addTechnologyToList(item)}
            >
              {item.link}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LinkToVacanciesComponent;
