import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import classNames from "classnames";

// actions

// styles
import "./style.scss";

class SwitchLanguage extends Component {
  state = {
    isOpenDropdown: false,
  };

  isActive = (language) => {
    const { language: currentLanguage } = this.props;

    return currentLanguage.short === language;
  };

  closeDropdown = (event) => {
    this.setState({ isOpenDropdown: false });
    document.removeEventListener("click", this.closeDropdown);
  };

  openDropdown = (event) => {
    document.addEventListener("click", this.closeDropdown);
    this.setState({ isOpenDropdown: true });
  };

  render() {
    const {
      dropdown,
      onChangeLanguage,
      isMobile,
      vocabulary,
      languages,
      language,
    } = this.props;
    const { isOpenDropdown } = this.state;
    const { v_language } = vocabulary;

    return (
      <div
        className={classNames("wrapper-switch-language", {
          "wrapper-switch-language--dropdown": dropdown,
          "wrapper-switch-language--block": !dropdown,
          "wrapper-switch-language--mobile": isMobile,
        })}
      >
        {dropdown ? (
          <>
            <div className="wrapper-switch-language__title">{v_language}:</div>
            <div
              className="wrapper-switch-language__select"
              onClick={this.openDropdown}
            >
              <span>{language.long}</span>

              {isOpenDropdown && (
                <div className="wrapper-switch-language__list">
                  {languages.map((item) => (
                    <div
                      key={item.long}
                      className="wrapper-switch-language__list-item"
                      onClick={(event) => {
                        onChangeLanguage(item);
                      }}
                    >
                      {item.long}
                    </div>
                  ))}
                </div>
              )}
              <i
                className={cn(
                  `wrapper-switch-language__icon-arrow ${
                    isOpenDropdown
                      ? "wrapper-switch-language__icon-arrow_up"
                      : ""
                  }`,
                  {
                    "wrapper-switch-language__icon-arrow__light":
                      localStorage.getItem("themes") === "light",
                  },
                )}
              />
            </div>
          </>
        ) : (
          <ul className="wrapper-switch-language__list">
            {languages.map((item) => (
              <li
                key={item.short}
                className={classNames("wrapper-switch-language__list-item", {
                  "wrapper-switch-language__list-item--active": this.isActive(
                    item.short,
                  ),
                })}
                onClick={(event) => onChangeLanguage(item)}
              >
                {item.short}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  languages: state.languageReducer.languages,
  isMobile: state.responsiveReducer.isMobile,
});

export default connect(mapStateToProps)(SwitchLanguage);
