import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

// Actions
import { scrollToAction } from "../../../redux/actions/ResponsiveActions";

// Styles
import "./style.scss";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-GB";
// import ruLocale from "date-fns/locale/ru";
import deLocale from "date-fns/locale/de";
import itLocale from "date-fns/locale/it";
import uaLocale from "date-fns/locale/uk";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
// Локальная библиотека только длоя русского языка, убрали сокрощение месяцев
import ruLocale from "./ruLocal";

import { CalendarIcon, CrossIcon, CheckIcon } from "../../Atoms/SvgIcons";

const localeMap = {
  en: enLocale,
  ru: ruLocale,
  de: deLocale,
  it: itLocale,
  uk: uaLocale,
};

const muiTheme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },
    MuiTypography: {
      root: {
        fontSize: "1.7rem !important",
        color: "#fff",
      },
      caption: {
        color: "#fff",
      },
    },
    MuiInputAdornment: {
      root: {
        display: "none",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
      },
    },
    MuiInput: {
      root: {
        fontSize: "1.7rem !important",
        color: "#fff",
        borderColor: "#fff",
      },
      underline: {
        borderColor: "#fff !important",
        "&:before": {
          borderColor: "#fff !important",
        },
        "&:after": {
          borderColor: "#fff !important",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiPickersDay: {
      current: {
        color: "#fff",
      },
      daySelected: {
        backgroundColor: "#27ae60",
        "&:hover": {
          backgroundColor: "#27ae60",
        },
      },
      day: {
        color: "#B9BBBE",
        backgroundColor: "#52565F",
        marginBottom: "5px",
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: "8px",
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: "#40444B",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#40444B",
        color: "#fff",
      },
      dayLabel: {
        color: "#B9BBBE",
      },
    },
  },
});

class DatepickerSelect extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();

    const date = props.date;

    this.state = {
      isOpen: false,
      date: date ? moment(date).toDate() : "",
      time: date ? moment(date).toDate() : new Date(),
      isChangedDate: false,
    };
  }

  openDropdown = (event) => {
    const { disabled } = this.props;

    event.stopPropagation();

    if (
      disabled ||
      event.target.classList.contains("datepicker-select__close-button") ||
      event.target.classList.contains("datepicker-select__close-icon")
    ) {
      return;
    }
    this.setState({
      isOpen: true,
    });
  };

  handleCloseDropdown = (event) => {
    if (this.dropdown && !this.dropdown.current?.contains(event.target)) {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    if (!moment(this.state.time).isValid()) {
      const { date } = this.props;
      const prevDate = moment(date).isValid() ? moment(date).toDate() : null;

      this.setState({ time: prevDate || new Date() });
    }

    this.setState({ isOpen: false, isChangedDate: false });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;
    const { scrollToAction, withFolder, date } = this.props;

    if (!prevState.isOpen && isOpen) {
      if (!withFolder) {
        const height =
          window.innerHeight || window.document.documentElement.clientHeight;
        const boundingClientRect =
          this.dropdown.current.getBoundingClientRect();
        const { bottom } = boundingClientRect;

        if (bottom > height) {
          const diff = bottom - height;

          scrollToAction(diff);
        }
      }
      this.changeDateHandler(this.state.date);

      this.setState({ isChangedDate: false });
    }
    if (prevProps.date !== this.props.date) {
      this.setState({ date: this.props.date });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleCloseDropdown, true);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleCloseDropdown, true);
  }

  changeDateHandler = (date) => {
    const time = this.state.time;

    if (time) {
      const dateWithTime = moment(date)
        .set({ h: time.getHours(), m: time.getMinutes() })
        .toDate();

      this.props.onChangeDate(dateWithTime);

      this.setState({ date: dateWithTime, isChangedDate: true });
    } else {
      this.props.onChangeDate(date);
      this.setState({ date, time: date, isChangedDate: true });
    }
  };

  changeTimeHandler = (time) => {
    if (time === "Invalid Date" || time === null) {
      this.setState({ time: null });
      return;
    }

    let { date } = this.state;

    if (!moment(date).isValid()) {
      date = moment();
    }

    const dateWithTime = moment(date)
      .set({ h: time.getHours(), m: time.getMinutes() })
      .toDate();

    this.setState(
      {
        time: time,
        date: dateWithTime,
        isChangedDate: true,
      },
      () => {
        this.changeDateHandler(this.state.date);
      },
    );
  };

  blurTimeHandler = () => {
    const { date, time } = this.state;

    if (!date) {
      return;
    }

    if (!time) {
      return;
    }

    const dateWithTime = moment(date)
      .set({ h: time.getHours(), m: time.getMinutes() })
      .toDate();

    this.props.onChangeDate(dateWithTime);

    this.setState((state) => ({
      date: dateWithTime,
    }));
  };

  saveChanges = () => {
    const { date, isChangedDate } = this.state;

    if (isChangedDate) {
      this.changeDateHandler(date);
      this.closeDropdown();
    }
  };

  preventClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      vocabulary,
      isMobile,
      firstDayOfWeek,
      disabled,
      dateFormat,
      placeholder,
      withValidation,
      errorMsg,
      disablePast,
      timeFormat,
      dropdownTitle,
      disableToolbar = true,
    } = this.props;
    const { lang } = vocabulary;
    const { isOpen, date, time, isChangedDate } = this.state;

    const customLocale = localeMap[lang.short];

    customLocale.options.weekStartsOn = firstDayOfWeek;

    return (
      <div
        ref={(ref) => (this.container = ref)}
        className={classNames("datepicker-select", {
          "datepicker-select--mobile": isMobile,
          "datepicker-select--disabled": disabled,
          "datepicker-select--error": errorMsg && withValidation,
        })}
        onClick={this.openDropdown}
      >
        <div className="datepicker-select__selected-date">
          {!disabled && <CalendarIcon color="#fff" />}
          {moment(date).isValid() ? (
            <span className="datepicker-select__date">
              {window.location.pathname === "/resource-planning"
                ? moment(date).locale(lang.short).format("MMM DD/YYYY")
                : window.location.pathname === "/recruitment/vacancies"
                ? moment(date).locale(lang.short).format("DD.MM.YYYY")
                : moment(date).locale(lang.short).format("MMM DD/YYYY")}
            </span>
          ) : (
            <span className="datepicker-select__placeholder">
              {placeholder}
            </span>
          )}
        </div>
        {isOpen && (
          <div
            ref={this.dropdown}
            className={classNames("datepicker-select__dropdown")}
            onClick={this.preventClick}
          >
            <ThemeProvider theme={muiTheme}>
              <div className="datepicker-select__time-wrapper">
                <div className="datepicker-select__header">
                  <div>{dropdownTitle || ""}</div>
                  <div className="datepicker-select__buttons">
                    <button
                      type="button"
                      className={classNames("datepicker-select__button-save", {
                        "datepicker-select__button-save--touched":
                          isChangedDate,
                      })}
                      onClick={this.saveChanges}
                    >
                      <CheckIcon
                        className="datepicker-select__save-icon"
                        onClick={this.saveChanges}
                      />
                    </button>
                    <button
                      type="button"
                      className="datepicker-select__button"
                      onClick={this.closeDropdown}
                    >
                      <CrossIcon
                        className="datepicker-select__close-icon"
                        onClick={this.closeDropdown}
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="datepicker-select__time-inputs">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                  <div className="datepicker-select__time-input">
                    <KeyboardTimePicker
                      value={time}
                      disableToolbar
                      ampm={timeFormat === "12"}
                      onChange={this.changeTimeHandler}
                      onBlur={this.blurTimeHandler}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div> */}
              <div className="datepicker-select__calendar">
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={customLocale}
                >
                  <DatePicker
                    autoOk
                    disableToolbar={disableToolbar}
                    allowKeyboardControl={false}
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={this.changeDateHandler}
                    views={["year", "month", "date"]}
                    disablePast={disablePast}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </ThemeProvider>
          </div>
        )}
        {withValidation ? (
          <div className="datepicker-select__error-msg">
            {errorMsg ? vocabulary[errorMsg] : ""}
          </div>
        ) : null}
      </div>
    );
  }
}

DatepickerSelect.defaultProps = {
  onChangeVisibility: () => {},
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isMobile: state.responsiveReducer.isMobile,
  timeFormat: state.userReducer.timeFormat,
  dateFormat: state.userReducer.dateFormat,
  firstDayOfWeek: state.userReducer.firstDayOfWeek,
});

const mapDispatchToProps = {
  scrollToAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatepickerSelect);
