import styled from "styled-components/macro";
import { Field, Form } from "formik";
import ArrowImage from "../../../../../assets/images/icons/arrow-down.svg";
import FileAttach from "../../../../../assets/images/icons/FileAttach.svg";

export const StyledForm = styled(Form)`
  margin: 0 5%;
  margin-bottom: 15%;
  padding-bottom: 50px;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CountOfStepWrapper = styled.div`
  display: flex;
  font-size: 14px;
  color: #fff;
  gap: 10px;
  align-items: center;
`;
export const CountOfStepNumber = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background: #52565f;
  border-radius: 4px;
`;
export const CountOfStepText = styled.span`
  font-style: normal;
  font-weight: 700;
`;

export const SectionLine = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  height: 1px;
  background: #52565f;
  width: 100%;
`;

export const TextLabel = styled.p`
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const TextLabelB = styled.p`
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const RequiredfieldStar = styled.span`
  color: #eb5757;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const Input = styled(Field)`
  background-color: var(--background-box-time-day);
  color: var(--text);
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) => (hasError ? "red" : "var(--border-color-team)")};
  padding: 10px 15px;
  width: 260px;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
  ::placeholder {
    font-weight: 500;
    color: #b9bbbe;
  }
`;
export const InputSmall = styled(Field)`
  background-color: var(--background-box-time-day);
  color: var(--text);
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) => (hasError ? "red" : "var(--border-color-team)")};
  padding: 10px 15px;
  width: 160px;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
  ::placeholder {
    font-weight: 500;
    color: #b9bbbe;
  }
`;
export const InputFile = styled(Field)`
  display: none;
  background-color: var(--background-box-time-day);
  color: var(--text);
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) => (hasError ? "red" : "var(--border-color-team)")};
  padding: 10px 15px;
  width: 160px;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
  ::placeholder {
    font-weight: 500;
    color: #b9bbbe;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 44px;
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) => (hasError ? "red" : "var(--border-color-team)")};
`;

export const FileLabel = styled.label`
  background: url(${FileAttach}) no-repeat center !important;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  padding: 15px 20px !important;
`;

export const TextareaBlock = styled.div`
  position: relative;
`;

export const SymbolsCountForDescription = styled.div`
  position: absolute;
  right: 15px;
  top: 12px;
  background-color: var(--border-color-subscription);
  color: var(--color-resource-plaaning);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border-radius: 4px;
  user-select: none;
`;

export const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
`;

export const DropdownContainer = styled.div`
  margin-top: 30px;
  & > div {
  }
`;

export const SalaryContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const SalaryWrapper = styled.div`
  // margin: 0 5px;
`;

export const CurrencyBlock = styled.div`
  color: var(--text);
  border-radius: 8px;
  border: 1px solid var(--border-color-team);
  padding: 10px 15px;
  min-width: 160px;
  width: 100%;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 15px;
`;

export const Button = styled.button`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  height: 44px;
  // margin: 0 5px;
  @media (max-width: 415px) {
    padding: 14px 14px;
  }
`;

export const SaveButton = styled(Button)`
  background: #00b173;
`;

export const DeleteButton = styled(Button)`
  background: #eb5757;
`;

export const CancelButton = styled(Button)`
  display: flex;
  gap: 10px;
  background: inherit;
`;

export const LabelPhoneInput = styled.div`
  position: absolute;
  left: 15px;
  p {
    font-size: 16px;
    color: #b9bbbe;
  }
`;

export const CountryPhoneContainer = styled.div`
  margin: 0 0 10px;
  position: relative;
  display: flex;
  align-items: center;

  .react-tel-input {
    display: flex;
    border: var(--invoices-detailed-border-input);
    //box-shadow: var(--project-box-shadow);
    height: 44px;

    .selected-flag {
      z-index: 0;
      background: none !important;
    }
  }

  .form-control {
    background: var(--background-box-time-day);
    border: 1px solid #52565f;
    color: var(--user-setting-color);
    font-size: 16px;
    height: 44px;
    line-height: 22px;
    padding: 1px 0 1px 58px;
    width: 100%;
  }
  .flag-dropdown {
    background: var(--background-box-time-day);
    border: 0;
    border-radius: 4px 0 0 4px;

    &:hover .selected-flag {
      background: transparent;
      border-radius: 4px 0 0 4px;
    }
  }

  .flag-dropdown.open {
    background: none !important;
  }

  .arrow {
    background: url(${ArrowImage}) no-repeat center !important;
    border: none !important;
    height: 24px !important;
    left: 15px !important;
    margin-top: -2px !important;
    position: relative !important;
    top: -4px !important;
    transition: all 0.3s !important;
    width: 24px !important;
  }

  .arrow.up {
    border: none;
    transform: rotate(-180deg);
  }

  .selected-flag {
    //background: var(--background-box-time-day) !important;
    border: 1px solid #4d5159;
    border-radius: 5px !important;
    // width: 100%;
    width: 45px;
  }

  .country-list {
    background: var(--background-box-time-day);
    box-shadow: 2px 2px 4px var(--reports-export-modal-shadow);
    padding: 5px;
    top: 40px;
    width: 254px;
    // right: 0;
    // margin: 0 !important;
  }

  .country {
    color: var(--user-setting-dropdown-color);
    padding: 5px 10px;

    &:hover {
      background-color: var(--bg-hover-user-setting-list-item) !important;
      color: var(--user-setting-dropdown-color);
    }
    .dial-code {
      color: #b9bbbe;
    }
  }

  .search {
    top: -6px !important;
    background-color: var(--bg-hover-user-setting-list-item) !important;
  }

  .highlight {
    background-color: var(--bg-hover-invoices-list-item) !important;
    color: var(--user-setting-dropdown-color) !important;
  }
`;
export const CountryContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color-team);
  border-radius: 8px;
  color: var(--user-setting-color);
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  height: 44px;
  justify-content: space-between;
  outline: none;
  padding: 1rem;
  width: 260px;
  // width: 100%;
`;

export const DateContainer = styled.div`
  // position: relative;
  width: 260px;
  p {
    z-index: 1001;
    position: absolute;
    // margin-left: 20px;
  }

  .daterange-select__date-separator {
    display: none;
  }
  .daterange-select__selected-date {
    justify-content: flex-end;
    svg {
      position: absolute;
      right: 15px;
    }
  }
  .daterange-select__date {
    display: none;
  }
  .daterange-select__selected-date {
    background: none;
  }
  .daterange-select__button-save {
    display: none;
  }
`;

export const JobTypeBlock = styled.div`
  display: flex;
  gap: 10px;
`;

export const JobTypeButton = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  display: flex;
  padding: 10px 24px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #52565f;
  cursor: pointer;
`;
export const LanguageWrapper = styled.div`
  width: 260px;
  input {
    color: white;
  }
`;
export const AlertLabel = styled.div`
  width: 160px;
  margin-top: 5px;
  font-size: 10px;
  font-weight: 500;
  color: #00b173;
`;
export const TitleSubText = styled.div`
  color: var(--text);
  padding: 0 10px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #52565f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const FilePlaceholder = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
