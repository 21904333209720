export const TYPES_CONFIRM = {
  SEARCH: "search",
  CANDIDATES: "candidates",
};

export const TYPES_BUTTON = {
  CREATE: "create",
};

export const CURRENCY = {
  USD: 'USD'
}

export const NO_DATA = "-";

