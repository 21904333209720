import moment from "moment";

import { getCurrentDate, getTimestamp } from "../../services/timeService";
import {
  INIT_DOUGHNUT_OPTIONS,
  INIT_BAR_CHART_OPTIONS,
} from "../../constants/reports";

const initialDateFormat = "DD.MM.YYYY";
const dateFormat = localStorage.getItem("dateFormat") || initialDateFormat;

const initialState = {
  timeRange: {
    startDate: getCurrentDate(),
    endDate: getCurrentDate(),
    key: "selection",
    firstDayOfWeek: 1,
  },
  selectionRange: {
    startDate: getCurrentDate(),
    endDate: getCurrentDate(),
    key: "selection",
  },
  dataBarChart: {
    ...INIT_BAR_CHART_OPTIONS,
  },
  summaryDoughnutChart: {
    ...INIT_DOUGHNUT_OPTIONS,
  },
  realTimeDoughnutChart: {
    ...INIT_DOUGHNUT_OPTIONS,
  },
  reportProjects: [],
  userProjects: [],
  isCombineTasks: false,
  isCrossTeam: false,
  selectNoneActive: true,
  crossReportData: [],
  totalTime: { totalTime: 0, totalBillableTime: 0 },
  startDateValue: moment(getCurrentDate()).format(dateFormat),
  endDateValue: moment(getCurrentDate()).format(dateFormat),
  newArrUserTeams: [],
  newArrUserTeamsForPeople: [],
  lastFetchedSummary: 0,
  lastFetchedRealTime: 0,
  userActivities: [],
  selectedUsers: [],
  selectedProjects: [],
  selectedClients: [],
  selectedTeams: [],
  selectedUsersCross: [],
  selectedProjectsCross: [],
  isInitFilters: false,
  isDisabledFilter: false,
  isInitialFetch: true,
  filter: {
    allUsers: [],
    allProjects: [],
    allClients: [],
    allTeams: [],
    selectedUsers: [],
    selectedProjects: [],
    selectedClients: [],
    selectedTeams: [],

    allUsersCross: [],
    allProjectsCross: [],
    allClientsCross: [],
    selectedUsersCross: [],
    selectedProjectsCross: [],
    selectedClientsCross: [],
  },
};

export function reportsPageReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_DATE_ON_THE_REPORTS_CALENDAR":
      return { ...state, timeRange: action.payload.data };

    case "CHANGE_CROSS_TEAM_STATUS":
      return { ...state, isCrossTeam: action.payload };

    case "SET_LINE_GRAPH":
      return { ...state, dataBarChart: action.payload };

    case "SET_SUMMARY_DOUGHNUT_GRAPH":
      return {
        ...state,
        summaryDoughnutChart:
          action.payload === null
            ? initialState.summaryDoughnutChart
            : action?.payload?.data,
      };

    case "SET_REAL_TIME_DOUGHNUT_GRAPH":
      return {
        ...state,
        realTimeDoughnutChart:
          action.payload === null
            ? initialState.realTimeDoughnutChart
            : action?.payload?.data,
      };

    case "SET_PROJECTS":
      return { ...state, reportProjects: action.payload.data };

    case "SET_USER_PROJECTS":
      return { ...state, userProjects: action.payload.data };

    case "SET_TIME":
      return { ...state, timeRange: action.payload.data };

    case "SET_SELECTED_USERS":
      return { ...state, selectedUsers: action.payload.data };

    case "SET_SELECTED_PROJECTS":
      return { ...state, selectedProjects: action.payload.data };

    case "SET_SELECTED_PROJECTS_CROSS_TEAM":
      return { ...state, selectedProjectsCross: action.payload.data };

    case "SET_IS_DISABLED_FILTER":
      return { ...state, isDisabledFilter: action.payload.data };

    case "SELECT_NONE_ACTIVE":
      return { ...state, selectNoneActive: action.payload.data };

    case "SET_COMBINED_OPTION":
      return { ...state, isCombineTasks: action.payload };

    case "SET_SELECTED_TEAM":
      return { ...state, selectedTeams: action.payload.data };

    case "SET_CROSS_USERS_SELECTED":
      return { ...state, selectedUsersCross: action.payload.data };

    case "SET_CROSS_REPORT_DATA":
      return { ...state, crossReportData: action.payload };

    case "SET_TOTAL_TIME":
      return {
        ...state,
        totalTime: action.payload,
      };

    case "SET_SELECTION_RANGE":
      return { ...state, selectionRange: action.payload };

    case "SET_START_DATE":
      return { ...state, startDateValue: action.payload };

    case "SET_END_DATE":
      return { ...state, endDateValue: action.payload };

    case "SET_USER_TEAMS_ARRAY":
      return { ...state, newArrUserTeams: action.payload };

    case "SET_USER_TEAMS_PEOPLE_ARRAY":
      return { ...state, newArrUserTeamsForPeople: action.payload };

    case "SET_LAST_FETCHED_SUMMARY":
      return { ...state, lastFetchedSummary: action.payload };

    case "SET_LAST_FETCHED_REAL_TIME":
      return { ...state, lastFetchedRealTime: action.payload };

    case "SET_USER_ACTIVITIES":
      return { ...state, userActivities: action.payload };

    case "UPDATE_FILTER":
      return {
        ...state,
        filter: action.payload,
      };

    case "SET_INIT_FILTERS":
      return { ...state, isInitFilters: action.payload };

    case "SET_IS_INITIAL_FETCH":
      return { ...state, isInitialFetch: action.payload };

    case "RESET_ALL":
      return initialState;

    default:
      return state;
  }
}
