import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "../BaseComponents/Input";

import "./style.scss";

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || "Wrong value",
    params: {
      reference: ref.path,
    },
    test: function (value) {
      if (!value) {
        return true;
      }
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

const UserDeleteModal = ({
  vocabulary,
  cancelDeleteUserModal,
  deleteUser,
  deleteUserSubmitForm,
}) => (
  <div className="confirmation-modal-delete-user">
    <div className="confirmation-modal-delete-user__background" />
    <div className="confirmation-modal-delete-user__container">
      <i
        className="confirmation-modal-delete-user__container__close"
        onClick={cancelDeleteUserModal}
      />
      <h2 className="confirmation-modal-delete-user__text">
        {vocabulary.v_delete_user_text}
      </h2>
      <div>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .required("v_v_required")
              .min(6, "v_a_incorect_password"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            deleteUserSubmitForm({
              password: values.password,
            });
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <div className="confirmation-modal-delete-user__change-password_container">
              <form
                className="confirmation-modal-delete-user__change-password_window"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <Input
                  config={{
                    id: "password",
                    name: "password",
                    type: "password",
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    value: formik.values.password,
                    placeholder: `${vocabulary.v_password}...`,
                    // autocomplete: 'off',
                  }}
                  errorMsg={formik.errors.password}
                  label={vocabulary.v_add_confirm_password}
                  withValidation
                />

                <div className="confirmation-modal-delete-user__buttons-wrap">
                  <button
                    type="submit"
                    className="confirmation-modal-delete-user__button confirmation-modal-delete-user__button--confirm-delete"
                    // onClick={deleteUser}
                  >
                    {vocabulary.v_confirm}
                  </button>
                  <button
                    className="confirmation-modal-delete-user__button confirmation-modal-delete-user__button--cancel-delete"
                    onClick={cancelDeleteUserModal}
                  >
                    {vocabulary.v_cancel}
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  </div>
);

export default UserDeleteModal;
