import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  VacanciesBody,
  VacanciesHeaderWrapper,
  VacanciesSearchBlock,
  StyledVacanciesButton,
  VacanciesHeadeRightContent,
  StyledAddVacancies,
  IconButtonPlus,
  TableWrapper,
  Table,
  StyledVacanciesListWrapper,
  StyledNoVacancies,
} from "./My.styled.ts";
import {
  createDirection,
  getDirection,
  getVacancy,
  postVacancy,
  removeDirection,
  updateDirection,
  searchDirection,
  getSearchApplication,
  getSearchVacancy,
  editVacancy,
} from "../../../../redux/actions/PepoplePageActions";
import RecruitmentSearchComponent from "../RecruitmentSearchComponent/index.js";
import VacanciesSwitcher from "../VacanciesSwitcher/index.js";
import RecruitmentTableHeader from "../../RecruitmentTableHeader/index.js";
import List from "../ListComponent";
import { NoInfo } from "../../NoInfo/index.js";
import CreateVacanciesModal from "../CreateVacanciesModal/index.js";
import get from "lodash/get";
import ViewVacanciesModal from "../ViewVacanciesModal/index.js";
import Pagination from "../../../Atoms/Pagination";

const Vacancies = ({
  vocabulary,
  getDirectionHandler,
  getVacancyHandler,
  postVacancyHandler,
  searchDirectionHandler,
  directionDataFromRedux,
  getApplicationHandler,
  applicationsDataFromRedux,
  vacanciesDataFromRedux,
  searchVacancyHandler,
  editVacancyHandler,
}) => {
  const PERIODS = {
    ACTIVE: "ACTIVE",
    ARCHIVE: "ARCHIVE",
  };
  const { v_hr_people, v_search } = vocabulary;

  const selectorsForActiveState = [
    {
      titleSelector: v_hr_people.statusSelectors.open,
      selected: false,
    },
    {
      titleSelector: v_hr_people.statusSelectors.onHold,
      selected: false,
    },
  ];

  const selectorsForArchiveState = [
    {
      titleSelector: v_hr_people.statusSelectors.closed,
      selected: false,
    },
    {
      titleSelector: v_hr_people.statusSelectors.rejected,
      selected: false,
    },
  ];

  const [searchedValue, setSearchedValue] = useState("");
  const [internalPeriod, setInternalPeriod] = useState(null);
  const [period, setPeriod] = useState(PERIODS.ACTIVE);
  const [vacanciesData, setVacanciesData] = useState([]);
  const [dir, setDir] = useState("vacancies");
  const [pageDirection, setPageDirection] = useState(1);
  const [directionData, setDirectionData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [vacancyStatus, setVacancyStatus] = useState(["OPEN", "ONHOLD"]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [dataForViewModal, setDataForViewModal] = useState([]);
  const [dataForEditModal, setDataForEditModal] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [priority, setPriority] = useState("");
  const [selectorForStatus, setSelectorForStatus] = useState(
    selectorsForActiveState,
  );

  const createVacanciesRef = useRef(null);

  const PERIODS_SWITCH_REVERSE_MAP = {
    true: PERIODS.ACTIVE,
    false: PERIODS.ARCHIVE,
  };
  const PERIODS_SWITCH_MAP = {
    [PERIODS.ACTIVE]: true,
    [PERIODS.ARCHIVE]: false,
  };

  const [tableHeaderDataList, setTableHeaderDataList] = useState([
    {
      title: v_hr_people.vacanciesHeaderTable.name,
      id: 0,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.vacanciesHeaderTable.status,
      id: 1,
      isSelector: true,
      isFilter: false,
      onFilterHendler: (value) =>
        setVacancyStatus([value.replace(/\s/g, "").toUpperCase()]),
      selector: selectorForStatus,
      editButton: false,
    },
    {
      title: v_hr_people.vacanciesHeaderTable.priorities,
      id: 2,
      isSelector: true,
      isFilter: false,
      onFilterHendler: (value) => setPriority(value.toUpperCase()),
      selector: [
        {
          titleSelector: v_hr_people.prioritiesSelectors.high,
          selected: false,
        },
        {
          titleSelector: v_hr_people.prioritiesSelectors.medium,
          selected: false,
        },
        {
          titleSelector: v_hr_people.prioritiesSelectors.low,
          selected: false,
        },
      ],
      editButton: false,
    },
    {
      title: v_hr_people.vacanciesHeaderTable.number_of_candidates,
      id: 3,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.vacanciesHeaderTable.latest_update,
      id: 4,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.vacanciesHeaderTable.created,
      id: 5,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.vacanciesHeaderTable.deadline,
      id: 6,
      isSelector: false,
      isFilter: true,
      selector: [],
      editButton: false,
    },
  ]);
  const handleOpenViewModal = (data) => {
    setDataForViewModal(data);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setDataForViewModal([]);
    setOpenViewModal(false);
  };

  const handleOpenEditModal = (data) => {
    setDataForEditModal(data);
    setDataForViewModal([]);
    setOpenViewModal(false);
    setIsEdit(true);
    createVacanciesRef.current.handleOpenCreateModal();
  };

  const handleGetVacancies = async ({ page }) => {
    const additionalParams = {
      ...(searchedValue && { searchedValue }),
    };

    const passedPage = page ? page : pageDirection;

    const data = await searchVacancyHandler({
      page: passedPage,
      vacancyStatus: vacancyStatus,
      priority: priority ? priority : undefined,
      ...additionalParams,
    });

    if (data?.currentPage) {
      setPageDirection(data.currentPage);
    }
  };

  const handleGetApplicationForDropdown = async ({ page, emtyVacancy }) => {
    const data = await getApplicationHandler({
      page: page,
      emtyVacancy: emtyVacancy,
    });
  };

  const handleOpenCreateVacanciesModal = () => {
    setIsEdit(false);
    createVacanciesRef.current.handleOpenCreateModal();
  };

  useEffect(() => {
    setInternalPeriod(PERIODS_SWITCH_MAP[period]);
    setPriority("");
    if (period === "ARCHIVE") {
      setVacancyStatus(["CLOSED", "REJECTED"]);
      setSelectorForStatus(selectorsForArchiveState);
    } else {
      setVacancyStatus(["OPEN", "ONHOLD"]);
      setSelectorForStatus(selectorsForActiveState);
    }
  }, [period]);

  useEffect(() => {
    // Create a new array for updated selector data
    const updatedTableHeaderDataList = [...tableHeaderDataList];
    // Find the element in the tableHeaderDataList array that corresponds to the status column
    const statusColumn = updatedTableHeaderDataList.find(
      (item) => item.id === 1,
    );
    // Update the selector in the found column
    if (statusColumn) {
      statusColumn.selector = selectorForStatus;
    }
    // Set the updated array in the state
    setTableHeaderDataList(updatedTableHeaderDataList);
  }, [selectorForStatus]);

  // useEffect(() => {
  //   setDir(true);
  // }, [dir]);
  const getDirectionHandlerCall = (page) => {
    getDirectionHandler(page);
  };

  useEffect(() => {
    const boardsData = get(directionDataFromRedux, "boards", []);
    const applicationsData = get(applicationsDataFromRedux, "orders", []);
    setDirectionData(boardsData);
    setApplicationData(applicationsData);
  }, [
    directionDataFromRedux,
    applicationsDataFromRedux,
    vacanciesDataFromRedux,
  ]);

  useEffect(() => {
    const vacancyData = get(vacanciesDataFromRedux, "vacancies", []);
    setVacanciesData(vacancyData);
  }, [vacanciesDataFromRedux]);

  useEffect(() => {
    const data = getDirectionHandlerCall(1);
    handleGetVacancies({ page: pageDirection, vacancyStatus: vacancyStatus });
    handleGetApplicationForDropdown({ page: 1, emtyVacancy: true });
  }, []);

  useEffect(() => {
    if (!searchedValue) {
      handleGetVacancies({ page: pageDirection, vacancyStatus: vacancyStatus });
    }
  }, [searchedValue]);

  useEffect(() => {
    handleGetVacancies({
      page: pageDirection,
      vacancyStatus: vacancyStatus,
      priority: priority,
    });
  }, [pageDirection, vacancyStatus, priority]);

  const handleSetSearchedValue = async (value) => {
    if (value === null) {
      // user clicked clear value
      setSearchedValue("");
      // await handleDirection({ page: 1, typeConfirm: "board" });
    } else {
      setSearchedValue(value);
    }
  };

  const handleSwitcher = () => {
    setPeriod(PERIODS_SWITCH_REVERSE_MAP[!internalPeriod]);
    setPageDirection(1);
  };

  return (
    <VacanciesBody>
      <VacanciesHeaderWrapper>
        <VacanciesSearchBlock>
          <RecruitmentSearchComponent
            handleSearch={handleSetSearchedValue}
            // handleConfirmSearch={handleDirection}
            placeholder={v_hr_people.directions.search_by_name_placeholder}
          />
          <StyledVacanciesButton
            className="add-dir__btn"
            onClick={handleGetVacancies}
          >
            {v_search}
          </StyledVacanciesButton>
        </VacanciesSearchBlock>
        <VacanciesHeadeRightContent>
          <VacanciesSwitcher
            period={internalPeriod}
            handleSwitcher={handleSwitcher}
            trans={v_hr_people.switcherForVacancies}
          />
          <StyledAddVacancies>
            <StyledVacanciesButton
              // onClick={() => createVacanciesRef.current.handleOpenCreateModal()}
              onClick={handleOpenCreateVacanciesModal}
            >
              <IconButtonPlus />
              {v_hr_people.vacancies.new_vacancies}
            </StyledVacanciesButton>
          </StyledAddVacancies>
        </VacanciesHeadeRightContent>
      </VacanciesHeaderWrapper>
      <TableWrapper>
        <Table>
          <RecruitmentTableHeader
            tableHeaderDataList={tableHeaderDataList}
            period={period}
          />
          {vacanciesData && Boolean(vacanciesData.length) && (
            <List
              items={vacanciesData}
              dir={dir}
              handleOpenViewModal={handleOpenViewModal}
            />
          )}
        </Table>
      </TableWrapper>
      {vacanciesData && vacanciesData.length === 0 && (
        <StyledVacanciesListWrapper>
          <StyledNoVacancies>
            <NoInfo
              text={v_hr_people.vacancies.no_vacancies_text}
              btnText={v_hr_people.vacancies.new_vacancies}
              // buttonHandler={() =>
              //   handleModalCreateEditDirection({ type: "create" })
              // }
            />
          </StyledNoVacancies>
        </StyledVacanciesListWrapper>
      )}
      <CreateVacanciesModal
        ref={(ref) => {
          createVacanciesRef.current = ref;
        }}
        vocabulary={vocabulary}
        directionData={directionData}
        getDirectionHandlerCall={getDirectionHandler}
        pageCount={directionDataFromRedux}
        handleCreate={postVacancyHandler}
        handleUpdate={editVacancyHandler}
        applicationData={applicationData}
        applicationsDataFromRedux={applicationsDataFromRedux}
        handleGetApplicationForDropdown={handleGetApplicationForDropdown}
        handleGetVacancies={handleGetVacancies}
        isEdit={isEdit}
        setDataForEditModal={setDataForEditModal}
        dataForEditModal={dataForEditModal}
      />
      {openViewModal && (
        <ViewVacanciesModal
          vocabulary={vocabulary}
          data={dataForViewModal}
          openViewModal={openViewModal}
          handleCloseViewModal={handleCloseViewModal}
          handleOpenEditModal={handleOpenEditModal}
        />
      )}
      {vacanciesData && vacanciesData.length >= 1 ? (
        <Pagination
          currentPage={vacanciesDataFromRedux.currentPage}
          generalCount={vacanciesDataFromRedux.totalPages}
          setCurrentPage={setPageDirection}
        />
      ) : null}
    </VacanciesBody>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  directionDataFromRedux: state.boardReducer.directions,
  applicationsDataFromRedux: state.boardReducer.applications,
  vacanciesDataFromRedux: state.boardReducer.vacancies,
});
const mapDispatchToProps = {
  getApplicationHandler: getSearchApplication,
  getDirectionHandler: getDirection,
  getVacancyHandler: getVacancy,
  postVacancyHandler: postVacancy,
  searchDirectionHandler: searchDirection,
  searchVacancyHandler: getSearchVacancy,
  editVacancyHandler: editVacancy,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vacancies);
