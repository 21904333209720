import { AxiosPromise } from "axios";
import { instance } from "../configAPI";

export interface IGetDirections {
  data: IGetDirectionsBoards;
}
export interface IGetDirectionsBoards {
  boards: [
    {
      id: string;
      title: string;
      team_id: string;
    },
  ];
}

export interface IGetData {
  data: IGetBoardInfo;
}

export interface IGetBoardInfo {
  id: string;
  title: string;
  index: number;
  team_id: string;
  board_id: string;
  deleted: Boolean;
  cards: [
    {
      id: string;
      title: string;
      index: number;
      description: string;
      lane_id: string;
      archived: Boolean;
    },
  ];
}

export interface ICreateCardInfo {
  firstName: "string";
  lastName: "string";
  phone: "string";
  email: "string";
  birthday: "string";
  startWork: "string";
  messenger: "string";
  linkedin: "string";
  github: "string";
  city: "string";
  country: "string";
  minSalary: "string";
  maxSalary: "string";
  currency: "string";
  jobType: "remote" | "hybrid" | "office";
  desiredDate: "string";
  experience: "string";
  position: "string";
  level: "string";
  feedbackHr: "string";
  feedbackTech: "string";
  interviewDate: "string";
  techInterviewDate: "string";
  // cardId: "string";
  boardId: "sting";
  file: "string";
  clientFile: "string";
  links: [];
}

export interface ICreateNewLane {
  id: string;
  title: string;
  index: number;
  boardId: string;
}
export interface IEditLane {
  id: string;
  index: number;
  title: string;
  boardId: string;
}
export interface ICreateNewCard {
  id: string;
  title: string;
  index: number;
  description: string;
  lane_id: string;
}
export interface IEditCard {
  id: string;
  title: string;
  index: number;
  description: string;
  toLaneId: string;
  fromLaneId: string;
}
export interface ICreateDirection {
  title: string;
  teamId: string;
}

export interface IGetSearchDirection {
  searchValue: string;
}

export const getDirectionsBoardsAPI = (data: IGetDirections): AxiosPromise =>
  instance({
    url: "/board?page=1",
    method: "GET",
    data,
  });

export const getBoardAPI = (data: IGetData): AxiosPromise =>
  instance({
    url: "/board/lanes",
    method: "GET",
    params: { ...data },
  });

export const addLaneAPI = (data: ICreateNewLane): AxiosPromise =>
  instance({
    url: `/board/lane/`,
    method: "POST",
    data,
  });

export const deleteLaneAPI = (id: string, boardId: string): AxiosPromise =>
  instance({
    url: `/board/lane/${id}`,
    method: "DELETE",
    data: { boardId },
  });

export const editLaneAPI = ({
  id,
  title,
  index,
  boardId,
}: IEditLane): AxiosPromise =>
  instance({
    url: `/board/lane/${id}`,
    method: "PATCH",
    data: { title, index, boardId },
  });

export const addCardAPI = (data: ICreateNewCard): AxiosPromise =>
  instance({
    url: `/board/card/`,
    method: "POST",
    data,
  });

export const deleteCardAPI = (id: string): AxiosPromise =>
  instance({
    url: `/board/card/${id}`,
    method: "DELETE",
  });

export const editCardAPI = ({
  id,
  title,
  index,
  description,
  toLaneId,
  fromLaneId,
}: IEditCard): AxiosPromise =>
  instance({
    url: `/board/card/${id}`,
    method: "PATCH",
    data: { title, index, description, toLaneId, fromLaneId },
  });

export const getSearchCardApi = (params: IGetSearchDirection): AxiosPromise =>
  instance({
    url: `/board/cards/search`,
    method: "GET",
    params,
  });

export const getCandidateInfoAPI = (cardId: string): AxiosPromise =>
  instance({
    url: `/board/candidate/${cardId}`,
    method: "GET",
  });

export const createCandidateInfoAPI = (data: ICreateCardInfo): AxiosPromise =>
  instance({
    url: `/board/candidate/`,
    method: "POST",
    data,
  });

export const editCandidateInfoAPI = (
  id: string,
  data: ICreateCardInfo,
): AxiosPromise =>
  instance({
    url: `/board/candidate/form/${id}`,
    method: "PATCH",
    data,
  });

export const createDirectionApi = (data: ICreateDirection): AxiosPromise =>
  instance({
    url: `/board`,
    method: "POST",
    data,
  });

export const getDirectionApi = (page: number): AxiosPromise =>
  instance({
    url: `/board`,
    method: "GET",
    params: {
      page: page,
    },
  });

export const removeDirectionApi = (boardId: string | number): AxiosPromise =>
  instance({
    url: `/board/${boardId}`,
    method: "DELETE",
  });

export const updateDirectionApi = (params: any): AxiosPromise =>
  instance({
    url: `/board`,
    method: "PATCH",
    data: {
      ...params,
    },
  });

export const getSearchDirectionApi = (
  params: IGetSearchDirection,
): AxiosPromise =>
  instance({
    url: `/board/search`,
    method: "GET",
    params,
  });

export const getSearchCandidatesApi = (
  params: IGetSearchDirection,
): AxiosPromise =>
  instance({
    url: `board/candidates/search`,
    method: "GET",
    params,
  });

export const getCandidatesApi = (page: number): AxiosPromise =>
  instance({
    url: `board/candidates/search`,
    method: "GET",
    params: {
      searchedValue: "",
      page: page,
    },
  });

export const getVacancyApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/vacancy`,
    method: "GET",
    params: {
      ...params,
    },
  });

export const postVacancyApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/vacancy`,
    method: "POST",
    data: {
      ...params,
    },
  });

export const editVacancyApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/vacancy`,
    method: "PATCH",
    data: {
      ...params,
    },
  });

export const getSearchVacancyApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/vacancy/search`,
    method: "GET",
    params: {
      ...params,
    },
  });

export const getApplicationApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/order`,
    method: "GET",
    params: {
      ...params,
    },
  });

export const postApplicationApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/order`,
    method: "POST",
    data: {
      ...params,
    },
  });

export const removeApplicationApi = (id: string | number): AxiosPromise =>
  instance({
    url: `/board/order/${id}`,
    method: "DELETE",
  });

export const updateApplicationApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/order`,
    method: "PATCH",
    data: {
      ...params,
    },
  });

export const archiveApplicationApi = (boardId: any): AxiosPromise =>
  instance({
    url: `/board/order/archive/${boardId}`,
    method: "PATCH",
  });

export const getSearchApplicationApi = (params: any): AxiosPromise =>
  instance({
    url: `/board/order/search`,
    method: "GET",
    params: {
      ...params,
    },
  });
