import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import Panel from "../Panel";
import BillingInfoDetails from "../BillingInfoDetails";

// Styles
import "./style.scss";
import { Loading } from "../Loading";

const BillingInfo = ({
  vocabulary,
  className,
  onAddBillingInfo,
  paymentData,
  isFetching,
}) => {
  const { v_billing_info } = vocabulary;

  return (
    <Panel
      className={`billing-info ${className || ""}`}
      header={
        <div className="billing-info__header">
          <div className="billing-info__title">{v_billing_info.title}</div>
          <button
            className="billing-info__change-trigger"
            onClick={onAddBillingInfo}
          >
            {paymentData
              ? v_billing_info.changeBillingInfo
              : "Add billing info"}
          </button>
        </div>
      }
      content={
        <Loading mode="overlay" flag={false} withLogo={false}>
          {paymentData ? (
            <BillingInfoDetails data={paymentData.billing_details} />
          ) : (
            <div>No billing info</div>
          )}
        </Loading>
      }
    />
  );
};

BillingInfo.propTypes = {
  vocabulary: Proptypes.object,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(BillingInfo);
