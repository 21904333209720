import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import CardModalForm from "../CardModal/CardModalForm/index";
import CardModalInfo from "../CardModal/CardModalInfo/index.js";
import {
  getCardData,
  getSearchVacancy,
} from "../../../../redux/actions/PepoplePageActions";
import LineIcon from "../../../../assets/images/ComponentIcons/LineIcon";
import {
  StyledSVG,
  LinesContainer,
  HeaderWrapper,
  TitleWrapper,
  TitleText,
  TitleSubText,
  EditButton,
} from "./My.styled";
import "./style.scss";

const CardModal = ({
  vocabulary,
  candidates,
  getCardData,
  closeCardModal,
  cardId,
  boardId,
  selectors,
  vacanciesData,
  getVacanciesHandler,
}) => {
  const { v_hr_people } = vocabulary;
  const [isEditCard, setEditCard] = useState(false);
  const [typeForm, setTypeForm] = useState("first");
  const [vacancyStatus, setVacancyStatus] = useState(["OPEN", "ONHOLD"]);

  const handleGetVacanciesForDropdown = async ({ page, vacancyStatus }) => {
    const data = await getVacanciesHandler({
      page: page,
      vacancyStatus: vacancyStatus,
    });
  };

  useEffect(() => {
    handleGetVacanciesForDropdown({
      page: 1,
      vacancyStatus: vacancyStatus,
    });
  }, []);

  const editCardModal = () => {
    setEditCard(!isEditCard);
  };
  const cancelEdit = () => {
    setEditCard(!isEditCard);
  };
  const handleNextSlide = (type) => {
    setTypeForm(type);
  };

  const submitForm = () => {
    setEditCard(!isEditCard);
  };
  const renderLines = useCallback(
    () => (
      <LinesContainer>
        <StyledSVG active={typeForm === "first"}>
          <LineIcon active={typeForm === "first"} />
        </StyledSVG>
        <StyledSVG active={typeForm === "second"}>
          <LineIcon active={typeForm === "second"} />
        </StyledSVG>
        <StyledSVG active={typeForm === "third"}>
          <LineIcon active={typeForm === "third"} />
        </StyledSVG>
        <StyledSVG active={typeForm === "fourth"}>
          <LineIcon active={typeForm === "fourth"} />
        </StyledSVG>
      </LinesContainer>
    ),
    [typeForm],
  );
  
  return (
    <>
      <CardModalForm
        boardId={boardId}
        isEditCard={isEditCard}
        candidates={candidates}
        vacanciesData={vacanciesData}
        typeForm={typeForm}
        vocabulary={vocabulary}
        handleNextSlide={handleNextSlide}
        submitForm={submitForm}
        cancelEdit={cancelEdit}
        closeCardModal={closeCardModal}
        renderLines={renderLines}
        selectors={selectors}
        handleGetVacanciesForDropdown={handleGetVacanciesForDropdown}
      />
      {!isEditCard && candidates && (
        <div className="card-modal">
          <HeaderWrapper>
            <TitleWrapper>
              <TitleText>{v_hr_people.header.candidates}</TitleText>
              {isEditCard || !candidates ? null : (
                <TitleSubText>
                  {v_hr_people.candidates_modal.preview}
                </TitleSubText>
              )}
              {isEditCard || !candidates ? null : (
                <EditButton type="button" onClick={editCardModal}>
                  {v_hr_people.candidates_modal.edit}
                </EditButton>
              )}
            </TitleWrapper>
            <button
              className="card-modal__header__close-icon"
              onClick={closeCardModal}
            />
          </HeaderWrapper>
          <div className="card-modal__body">
            <CardModalInfo candidates={candidates} vocabulary={vocabulary} />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
  getCardData,
  getVacanciesHandler: getSearchVacancy,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardModal);
