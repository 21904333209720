import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import Panel from "../Panel";
import { Loading } from "../Loading";

import "./style.scss";
import PaymentMethodDetails from "../PaymentMethodDetails";

const PaymentInfo = ({
  vocabulary,
  className,
  onAddPaymentInfo,
  paymentMethod,
  isFetching,
}) => {
  const { v_payment_info } = vocabulary;

  // const paymentBearer = ((externalCustomerData || {}).Contract || {}).PaymentBearer;

  return (
    <Panel
      className={`payment-info ${className || ""}`}
      header={
        <div className="payment-info__header">
          <div className="payment-info__title">{v_payment_info.title}</div>
          <button
            className={`payment-info__change-trigger ${
              paymentMethod && paymentMethod.card
                ? ""
                : "payment-info__change-trigger--danger"
            }`}
            onClick={onAddPaymentInfo}
          >
            {paymentMethod && paymentMethod.card
              ? v_payment_info.changePaymentInfo
              : "Add payment info"}
          </button>
        </div>
      }
      content={
        <div className="payment-info__content">
          <Loading mode="overlay" flag={false} withLogo={false}>
            {paymentMethod && paymentMethod.card ? (
              <PaymentMethodDetails paymentMethod={paymentMethod.card} />
            ) : (
              <div>No payment method available</div>
            )}
          </Loading>
        </div>
      }
    />
  );
};

PaymentInfo.propTypes = {
  vocabulary: Proptypes.object,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(PaymentInfo);
