import React from "react";
import { connect } from "react-redux";

import BiillingHistoryRow from "./BiillingHistoryRow";

import "./style.scss";

const BillingHistoryTable = ({ vocabulary, paymentHistory }) => (
  <table className="billing-history-table">
    <thead className="billing-history-table__header">
      <tr className="billing-history-table__row">
        <th className="billing-history-table__header-cell" colSpan="3" />
        <th className="billing-history-table__header-cell">
          {vocabulary.v_billing_history_table.date}
        </th>
        <th className="billing-history-table__header-cell">
          {vocabulary.v_billing_history_table.description}
        </th>
        <th className="billing-history-table__header-cell">
          {vocabulary.v_billing_history_table.amount}
        </th>
        <th className="billing-history-table__header-cell">
          {vocabulary.v_billing_history_table.invoice}
        </th>
      </tr>
    </thead>
    <tbody className="billing-history-table__body">
      {paymentHistory.map((item) => (
        <BiillingHistoryRow key={item.id} data={item} />
      ))}
    </tbody>
  </table>
);

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(BillingHistoryTable);
