import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { changeInvoiceStatusModal } from "../../../api/invoice";
import moment from "moment";
import cn from "classnames";

const InvoiceStatusModal = ({
  vocabulary,
  invoice,
  getInvoices,
  closeModal,
  confirmHandler,
  cancelHandler,
  currentTeam,
}) => {
  const handleChangeStatus = async (status) => {
    await changeInvoiceStatusModal({
      invoiceId: invoice.id,
      status: status,
    });
    getInvoices();
    closeModal();
  };
  const [disabled, setDisabled] = useState(false);
  // useEffect(() => {
  //   if (currentTeam.data.userPlan.name === "Free") {
  //     setDisabled(true);
  //   }
  // }, [currentTeam.data.userPlan.name]);

  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal__background" />
      <div
        className={cn("confirmation-modal__container", {
          "confirmation-modal__container2": invoice.status === "paid",
        })}
      >
        <div
          className="closeStatusModal"
          onClick={() => {
            closeModal();
          }}
        ></div>
        <h2 className="confirmation-modal__text">
          <span>{vocabulary.v_changing_status}</span>
        </h2>
        {invoice.status === "paid" ? (
          <div className="confirmation-modal__buttons-wrap-exapt">
            {moment().isAfter(invoice.due_date) ? (
              <button
                className="confirmation-modal__button confirmation-modal__button--cancel"
                onClick={() => {
                  cancelHandler();
                  closeModal();
                }}
              >
                {vocabulary.v_filter_overdue}
              </button>
            ) : (
              <button
                className="confirmation-modal__button confirmation-modal__button--draft"
                onClick={() => {
                  cancelHandler();
                  handleChangeStatus("draft");
                }}
              >
                {vocabulary.v_filter_draft}
              </button>
            )}
          </div>
        ) : (
          <div className="confirmation-modal__buttons-wrap">
            <button
              className="confirmation-modal__button confirmation-modal__button--draft"
              onClick={() => handleChangeStatus("draft")}
            >
              {vocabulary.v_filter_draft}
            </button>
            <button
              className="confirmation-modal__button confirmation-modal__button--awaiting"
              onClick={() => handleChangeStatus("awaiting")}
            >
              {vocabulary.v_filter_awaiting}
            </button>
            <button
              className={cn(
                "confirmation-modal__button confirmation-modal__button--reviewed",
                {
                  "confirmation-modal__button--disabled": disabled,
                },
              )}
              onClick={() => handleChangeStatus("reviewed")}
              disabled={disabled}
            >
              {vocabulary.v_filter_reviewed}
            </button>
            <button
              className={cn(
                "confirmation-modal__button confirmation-modal__button--confirm",
                {
                  "confirmation-modal__button--disabled": disabled,
                },
              )}
              onClick={() => {
                confirmHandler();
                closeModal();
              }}
              disabled={disabled}
            >
              {vocabulary.v_filter_paid}
            </button>
            <button
              className={cn(
                "confirmation-modal__button confirmation-modal__button--cancel",
                {
                  "confirmation-modal__button--disabled": disabled,
                },
              )}
              onClick={() => handleChangeStatus("overdue")}
              disabled={disabled}
            >
              {vocabulary.v_filter_overdue}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentTeam: state.teamReducer.currentTeam,
});

const mapDispatchToProps = {
  changeInvoiceStatusModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStatusModal);
