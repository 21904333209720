import moment from "moment";

/**
 * Utility function to compare two date ranges
 * Returns `true` if ranges are the same
 * @param {object} rangeA
 * @param {object} rangeB
 * @returns {boolean}
 */
export const compareRanges = (rangeA, rangeB) => {
  const { startDate: aStartDate, endDate: aEndDate } = rangeA;
  const { startDate: bStartDate, endDate: bEndDate } = rangeB;

  if (aStartDate === bStartDate && aEndDate === bEndDate) {
    return true;
  }

  return false;
};

export const checkRangeValidity = (range) => {
  if (moment(range.startDate).isAfter(range.endDate)) {
    return false;
  }

  return true;
};
