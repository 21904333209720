import { instance } from "../configAPI";

export const setSocialConnect = (userId: any, { socialId, socialName }: any) =>
  instance({
    url: `/user/${userId}/set-social/${socialName}`,
    method: "POST",
    data: {
      socialId,
    },
  });

export const loginWithFacebook = ({
  email = "",
  id,
  name: username,
  language,
  timezoneOffset,
}: any) =>
  instance({
    url: "/user/login-fb",
    method: "POST",
    data: {
      email,
      id,
      username,
      language,
      timezoneOffset,
    },
  });

export const loginWithGoggle = ({
  email = "",
  name: username,
  language,
  timezoneOffset,
}: any) =>
  instance({
    url: "/user/login-google",
    method: "POST",
    data: {
      email,
      username,
      language,
      timezoneOffset,
    },
  });

export const getUserData = () =>
  instance({
    url: "/user",
    method: "GET",
  });

export const getUserTeams = () =>
  instance({
    url: "/user/teams",
    method: "GET",
  });

export const setAvatar = (id: any, data: any) =>
  instance({
    url: `/user/${id}/avatar`,
    method: "POST",
    data,
  });
export const deleteAvatar = (id: any) =>
  instance({
    url: `/user/${id}/avatar`,
    method: "DELETE",
  });

export const tutorialChecked = (id: any, key: any) =>
  instance({
    url: `/user/${id}`,
    method: "PATCH",
    data: {
      onboardingMobile: key,
    },
  });

export const userInvite = ({ emailsList, projectsIds }: any) =>
  instance({
    url: "/user/invite",
    method: "POST",
    data: {
      emailsList,
      projectsIds,
    },
  });

export const userChangePassword = ({ password, newPassword }: any) =>
  instance({
    url: "/user/change-password",
    method: "POST",
    data: {
      password,
      newPassword,
    },
  });

export const signIn = ({ email, password, timezoneOffset }: any) =>
  instance({
    url: "/user/login",
    method: "POST",
    data: {
      email,
      password,
      timezoneOffset,
    },
  });

export const signUp = ({ email, password, language, timezoneOffset }: any) =>
  instance({
    url: "/user/register",
    method: "POST",
    data: {
      email,
      password,
      language,
      timezoneOffset,
    },
  });

export const requestChangeUserData = (data: any, id: any) =>
  instance({
    url: `/user/${id}`,
    method: "PATCH",
    data,
  });

export const resetPassword = (email: any) =>
  instance({
    url: "/user/reset-password",
    method: "POST",
    data: {
      email,
    },
  });

export const setPassword = ({ password, token }: any) =>
  instance({
    url: "/user/set-password",
    method: "POST",
    data: {
      password,
      token,
    },
  });

export const deleteUser = () =>
  instance({
    url: `/user/delete`,
    method: "DELETE",
  });

export const userCheckPassword = ({ password }: any) =>
  instance({
    url: "/user/check-password",
    method: "POST",
    data: {
      password,
    },
  });

export const sendSupportMessage = ({ message }: any) =>
  instance({
    url: "/user/support",
    method: "POST",
    data: {
      message,
    },
  });
