import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// dependencies
import classNames from "classnames";
import _ from "lodash";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Unstructured/Spinner";

// Styles
import "./style.scss";

// Services
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole,
  checkIsInvoicesManagerByRole,
  ROLES,
} from "../../../services/authentication";

// Components
import RealTimeReport from "../../../components/Unstructured/RealTimeReport";

// Actions
import { getClientsAction } from "../../../redux/actions/ClientsActions";
import { changeCrossTeamStatusAction } from "../../../redux/actions/UserActions";

import ReportsHeader from "../../../components/Unstructured/Reports/ReportsHeader";
import { REPORTS_TYPES } from "../../../constants/reports";
import {
  convertDateToISOString,
  convertDateToShiftedISOString,
} from "../../../services/timeService";

class ReportsRealTime extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.currentTeam.data.role !== this.props.currentTeam.data.role) {
      if (
        checkIsAdminByRole(this.props.currentTeam.data.role) ||
        checkIsOwnerByRole(this.props.currentTeam.data.role) ||
        checkIsHRManagerByRole(this.props.currentTeam.data.role) ||
        checkIsProjectManagerByRole(this.props.currentTeam.data.role) ||
        checkIsInvoicesManagerByRole(this.props.currentTeam.data.role)
      ) {
        this.props.getClientsAction();
      }
    }
  }

  changeCrossTeamStatus = (isChecked) => {
    this.props.changeCrossTeamStatusAction(isChecked);
  };

  render() {
    const { isMobile, currentTeam, isInitialFetch, vocabulary } = this.props;
    const { v_rt_reports_plug, v_see_plans } = vocabulary;

    const isAdmin = [
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_OWNER,
      ROLES.ROLE_HR_MANAGER,
      ROLES.ROLE_PROJECT_MANAGER,
      ROLES.ROLE_INVOICES_MANAGER,
    ].includes(currentTeam?.data?.role);

    const denide =
      currentTeam.data.userPlan?.name === "Free" ||
      currentTeam.data.userPlan?.name === "Starter";

    return (
      <div
        className={classNames("wrapper_reports_page", {
          "wrapper_reports_page--mobile": isMobile,
        })}
      >
        {isInitialFetch && <Spinner mode={"parentSize"} withLogo={false} />}
        <div className="data_container_reports_page">
          <ReportsHeader
            isMobile={isMobile}
            isCrossTeam={this.props.isCrossTeam}
            isAdmin={isAdmin}
            currentTab={REPORTS_TYPES.REAL_TIME}
            onChangeCrossTeam={this.changeCrossTeamStatus}
            currentTeam={this.props.currentTeam}
          />

          {!denide ? (
            <div className="content_wrapper">
              <RealTimeReport />
            </div>
          ) : (
            <div className="content_wrapper-denide">
              <div className="content_wrapper-denide-top">
                <span>{v_rt_reports_plug.title}</span>
                <p>{v_rt_reports_plug.description}</p>
                <Link
                  className="header-wrapper__child-button"
                  to="/subscription/plans"
                >
                  {v_see_plans}
                </Link>
              </div>
              <div className="content_wrapper-denide-main">
                <i className="reports-plug-icon " />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
  isMobile: store.responsiveReducer.isMobile,
  currentTeam: store.teamReducer.currentTeam,
  isCrossTeam: store.reportsPageReducer.isCrossTeam,
  isInitialFetch: store.reportsPageReducer.isInitialFetch,
  selectionRange: store.reportsPageReducer.selectionRange,
});

const mapDispatchToProps = (dispatch) => ({
  getClientsAction: (params) => dispatch(getClientsAction(params)),
  changeCrossTeamStatusAction: (params) =>
    dispatch(changeCrossTeamStatusAction(params)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsRealTime),
);
