import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import List from "../ListComponent";
import RecruitmentSearchComponent from "../RecruitmentSearchComponent";
import RecruitmentTableHeader from "../../RecruitmentTableHeader";
import { NoInfo } from "../../NoInfo";
import CreateNewDirection from "../CreateNewDirection";
import Pagination from "../../../Atoms/Pagination";

import {
  createDirection,
  getDirection,
  getVacancy,
  postVacancy,
  removeDirection,
  updateDirection,
  searchDirection,
} from "../../../../redux/actions/PepoplePageActions";
import classNames from "classnames";
import get from "lodash/get";

import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledDeleteButton,
  StyledSaveButton,
  StyledDirectionsBody,
  StyledDirHeaderWrapper,
  StyledDirectionsSearch,
  StyledAddDir,
  StyledAddDirButton,
  StyledMenuWrapper,
  StyledDirectionsListWrapper,
  StyledNoDirections,
  IconButtonPlus,
  Table,
} from "./My.styled";

const Directions = ({
  vocabulary,
  currentTeam,
  directionDataFromRedux,
  getDirectionHandler,
  createDirectionHandler,
  removeDirectionHandler,
  getVacancyHandler,
  postVacancyHandler,
  updateDirectionHandler,
  searchDirectionHandler,
}) => {
  const [pageDirection, setPageDirection] = useState(1);
  const [directionData, setDirectionData] = useState([]);
  const [dir, setDir] = useState(false);

  const [searchedValue, setSearchedValue] = useState("");

  const [directionModal, setDirectionModal] = useState({
    isOpen: false,
    typeDirection: "edit", // create or edit
    directionId: null,
  });

  useEffect(() => {
    handleDirection({ page: 1, typeConfirm: "board" });
    // getVacancyHandler({ page: 1 });
  }, []);

  useEffect(() => {
    if (searchedValue) {
      handleDirection({ page: pageDirection, typeConfirm: "search" });
    } else {
      handleDirection({ page: pageDirection, typeConfirm: "board" });
    }
  }, [pageDirection]);

  useEffect(() => {
    setDir(true);
  }, [dir]);

  useEffect(() => {
    const boardsData = get(directionDataFromRedux, "boards", []);

    setDirectionData(boardsData);
  }, [directionDataFromRedux]);

  const { v_hr_people, v_search } = vocabulary;

  const [tableHeaderDataList, setTableHeaderDataList] = useState([
    {
      title: "",
      id: 0,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: true,
    },
    {
      title: v_hr_people.headerTable.name,
      id: 1,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.priorities,
      id: 2,
      isSelector: true,
      isFilter: false,
      selector: [
        {
          titleSelector: v_hr_people.prioritiesSelectors.high,
          selected: false,
        },
        {
          titleSelector: v_hr_people.prioritiesSelectors.medium,
          selected: false,
        },
        {
          titleSelector: v_hr_people.prioritiesSelectors.low,
          selected: false,
        },
      ],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.vacancies_available,
      id: 3,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.number_of_candidates,
      id: 4,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.latest_update,
      id: 5,
      isSelector: false,
      isFilter: false,
      selector: [],
      editButton: false,
    },
    {
      title: v_hr_people.headerTable.deadline,
      id: 6,
      isSelector: false,
      isFilter: true,
      selector: [],
      editButton: false,
    },
  ]);

  const handleModalCreateEditDirection = ({
    isOpen = true,
    type = "edit",
    id = null,
  }) => {
    setDirectionModal((prevState) => ({
      ...prevState,
      isOpen: isOpen,
      typeDirection: type,
      directionId: id,
    }));
  };

  const handleSetSearchedValue = async (value) => {
    if (value === null) {
      // user clicked clear value
      setSearchedValue("");
      await handleDirection({ page: 1, typeConfirm: "board" });
    } else {
      setSearchedValue(value);
    }
  };

  const handleDirection = async ({ typeConfirm = "search", page = false }) => {
    switch (typeConfirm) {
      case "search":
        if (searchedValue) {
          const data = await searchDirectionHandler({
            searchedValue: searchedValue,
            page: pageDirection,
          });
          const passedDATA = data && data.length >= 1 ? data : [];

          setDirectionData(passedDATA);
        } else {
          await getDirection(1);
        }
        break;

      default:
        const passedPage = page ? page : pageDirection;
        const data = await getDirectionHandler(passedPage);

        if (data) {
          setPageDirection(passedPage);
        }
        break;
    }
  };

  return (
    <StyledDirectionsBody className="directions-body">
      <StyledDirHeaderWrapper className="dir-header-wrapper">
        <StyledDirectionsSearch className="directions-search">
          <RecruitmentSearchComponent
            handleSearch={handleSetSearchedValue}
            handleConfirmSearch={handleDirection}
            placeholder={v_hr_people.directions.search_by_name_placeholder}
          />
          <StyledAddDirButton
            className="add-dir__btn"
            onClick={handleDirection}
          >
            {v_search}
          </StyledAddDirButton>
        </StyledDirectionsSearch>
        <StyledAddDir>
          <StyledAddDirButton
            className="add-dir__btn"
            onClick={() =>
              handleModalCreateEditDirection({
                isOpen: true,
                type: "create",
              })
            }
          >
            <IconButtonPlus />
            {v_hr_people.directions.new_direction}
          </StyledAddDirButton>
        </StyledAddDir>
      </StyledDirHeaderWrapper>
      <StyledMenuWrapper>
        <Table>
          <RecruitmentTableHeader tableHeaderDataList={tableHeaderDataList} />
          {directionData && directionData.length >= 1 && (
            <List
              items={directionData}
              dir={dir}
              handleClickIcon={handleModalCreateEditDirection}
            />
          )}
        </Table>
      </StyledMenuWrapper>
      <StyledDirectionsListWrapper
        className={classNames("directions-list-wrapper", {
          "directions-list-wrapper__no-directions":
            directionData && directionData.length === 0,
        })}
      >
        {directionData && directionData.length === 0 && (
          <StyledNoDirections className="no-directions">
            <NoInfo
              text={v_hr_people.directions.no_directions_text}
              btnText={v_hr_people.directions.new_direction}
              buttonHandler={() =>
                handleModalCreateEditDirection({ type: "create" })
              }
            />
          </StyledNoDirections>
        )}
      </StyledDirectionsListWrapper>
      <CreateNewDirection
        directionModal={directionModal}
        currentTeam={currentTeam}
        handleModalCreateEditDirection={handleModalCreateEditDirection}
        getDirectionHandler={getDirectionHandler}
        pageDirection={pageDirection}
        createDirectionHandler={createDirectionHandler}
        removeDirectionHandler={removeDirectionHandler}
        updateDirectionHandler={updateDirectionHandler}
        directionData={directionData}
      />
      {directionData.length >= 1 ? (
        <Pagination
          currentPage={directionDataFromRedux.currentPage}
          generalCount={directionDataFromRedux.totalPages}
          setCurrentPage={setPageDirection}
        />
      ) : null}
    </StyledDirectionsBody>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  currentTeam: state.teamReducer.currentTeam.data.id,
  directionDataFromRedux: state.boardReducer.directions,
});

const mapDispatchToProps = {
  getDirectionHandler: getDirection,
  createDirectionHandler: createDirection,
  removeDirectionHandler: removeDirection,
  getVacancyHandler: getVacancy,
  postVacancyHandler: postVacancy,
  updateDirectionHandler: updateDirection,
  searchDirectionHandler: searchDirection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Directions);
