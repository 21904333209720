import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import PaymentMethodDetails from "../../../PaymentMethodDetails";
import { Loading } from "../../../Loading";

import countries from "../../../../../locales/countries";

import "./style.scss";
import Button from "../../../../Atoms/Button";
import { PERIODS } from "../../../../../constants/constants";

export const SecondStep = ({
  handleClose,
  customerData,
  vocabulary,
  lang,
  selectedPlan,
  handleChangeBillingData,
  handleChangePaymentData,
  handleNext,
  period,
  isProcessing,
  products,
  discount,
  user,
  plans,
  currentTeam,
  productId,
}) => {
  const [formattedAddress, setFormattedAddress] = useState(null);

  const { v_subscription } = vocabulary;
  const { billingModal } = v_subscription;

  moment.locale(lang);

  const priceDivider = PERIODS.MONTH === period ? 100 : 100;

  const {
    default_payment_method: {
      billing_details: { name },
    },
  } = customerData;

  useEffect(() => {
    const {
      default_payment_method: {
        billing_details: {
          name,
          address: { city, country, line1, line2, postal_code },
        },
      },
    } = customerData;
    const result = [
      postal_code ? postal_code : "",
      // city ? city : '',
      line1 ? line1 : "",
      // line2 ? line2 : '',
      countries[lang][country],
    ]
      .filter((v) => Boolean(v))
      .join(", ");

    setFormattedAddress(result);
  }, [customerData]);

  const userProducts = (user, plans) => {
    if (user.coupon.applies_to === null) {
      const plansId = plans.map((prodId) => {
        return prodId.externalPlanData.id;
      });
      return plansId;
    } else {
      return user.coupon.applies_to;
    }
  };

  return (
    <div className="subscription-billing-info-second">
      <div className="subscription-billing-info-second__body-header">
        {billingModal.secondStep.title}
        <i
          className="subscription-billing-info-second__body-header-close"
          onClick={handleClose}
        />
      </div>
      <div className="subscription-billing-info-second__body">
        <div
          className={`subscription-billing-info-second__body-plan ${selectedPlan.data.class}-bd`}
        >
          <div>
            <p className="subscription-billing-info-second__body-plan-name">{`${selectedPlan.data.title} Plan`}</p>
            {selectedPlan.data.upgradable && (
              <p className="subscription-billing-info-second__body-plan-date"></p>
            )}
            {!selectedPlan.data.upgradable && (
              <p className="subscription-billing-info-second__body-plan-date">{`${
                billingModal.secondStep.firstCharge
              } ${moment
                .unix(customerData.current_period_end)
                .format("ll")}`}</p>
            )}
          </div>
          {user.coupon === null ? (
            <div>
              {!!selectedPlan.externalPlanData.totalUsersPrice &&
              !!discount &&
              productId === selectedPlan.externalPlanData.id &&
              _.includes(products, selectedPlan.externalPlanData.id) ? (
                <div className={`${selectedPlan.data.class}-color`}>
                  {`€ ${
                    (selectedPlan.externalPlanData.totalUsersPrice -
                      (selectedPlan.externalPlanData.totalUsersPrice *
                        discount) /
                        100) /
                    priceDivider
                  }`}
                </div>
              ) : (
                <div className={`${selectedPlan.data.class}-color`}>
                  {`€ ${
                    selectedPlan.externalPlanData.totalUsersPrice / priceDivider
                  }`}
                </div>
              )}
            </div>
          ) : (
            <div>
              {!!selectedPlan.externalPlanData.totalUsersPrice &&
              !!user.coupon &&
              user.coupon.applies_to === selectedPlan.externalPlanData.id &&
              _.includes(
                userProducts(user, plans),
                selectedPlan.externalPlanData.id,
              ) ? (
                <div className={`${selectedPlan.data.class}-color`}>
                  {`€ ${
                    (selectedPlan.externalPlanData.totalUsersPrice -
                      (selectedPlan.externalPlanData.totalUsersPrice *
                        user.coupon.percent_off) /
                        100) /
                    priceDivider
                  }`}
                </div>
              ) : (
                <div className={`${selectedPlan.data.class}-color`}>
                  {`€ ${
                    selectedPlan.externalPlanData.totalUsersPrice / priceDivider
                  }`}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="subscription-billing-info-second__body-billing">
          <div>
            <p className="subscription-billing-info-second__body-billing-name">
              {billingModal.secondStep.billingInfo}
            </p>
            <p
              className="subscription-billing-info-second__body-billing-link"
              onClick={() => !isProcessing && handleChangeBillingData()}
            >
              {billingModal.secondStep.changeBilling}
            </p>
          </div>
          <div>
            <p className="subscription-billing-info-second__user-info">
              {name}
            </p>
            <div className="subscription-billing-info-second__user-info">
              {formattedAddress}
            </div>
          </div>
        </div>
        {currentTeam && currentTeam.data.trial && (
          <div>
            <p className="trial-has-not-expired">
              {billingModal.trialHasNotExpired}
            </p>
          </div>
        )}
        <Button
          type="primary"
          onClick={handleNext}
          isLoading={isProcessing}
          width="100%"
        >
          {billingModal.next}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  lang: state.languageReducer.selectedLang,
  discount: state.subscriptionPageReducer.discount,
  products: state.subscriptionPageReducer.products,
  currentTeam: state.teamReducer.currentTeam,
  user: state.userReducer.user,
  productId: state.subscriptionPageReducer.productId,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
