export default {
  lang: { short: "it", long: "Italiano" },

  "ERROR.USER.THIS_FACEBOOK_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Questo account Facebook è già collegato a un altro account",
  "ERROR.USER.THIS_SOCIAL_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Questo account sociale è già collegato",
  "ERROR.USER.THIS_EMAIL_ALREADY_CONNECTED_TO_ANOTHER_FB_ACCOUNT":
    "Account con questa e-mail già collegata a un altro account Facebook",
  "ERROR.USER.ACCOUNT_ALREADY_EXISTED": "Account utente già esistente.",
  "ERROR.TIMER.JIRA_WORKLOG_1_MIN_THRESHHOLD":
    "La durata minima del compito, per aggiungere un 'worklog' a Jira, è di 1 minuto.",
  "ERROR.TIMER.JIRA_ADD_WORKLOG_FAILED":
    "L'aggiunta del 'worklog' a Jira è stata completata con un errore. Assicurati che l'accesso a Jira sia corretto e che il nome del compito sia nel formato 'JIRA-1 Commento'.",
  "ERROR.TIMER.JIRA_WORKLOG_ALREADY_ADDED":
    "Il compito è stato già abtnUpgradeggiunto al 'worklog' di Jira.",
  "Request path contains unescaped characters":
    "Verificare la correttezza del numero dell'attività",
  "Request failed with status code 404":
    "L'attività con questo numero non è stata trovata in Jira",
  "Request failed with status code 401":
    "Credenziali di sincronizzazione non corrette con Jira 'verificare la correttezza nel profilo utente'",

  "TIMER.JIRA_SYNC_SUCCESS":
    "È stata stabilita una connessione di test con Jira.",
  "ERROR.TIMER.JIRA_SYNC_FAILED":
    "Non è stato possibile stabilire una connessione di test con Jira. Assicurarsi che i dati inseriti siano corretti.",

  "SUCCESS.USER.RESET_EMAIL_CHECK":
    "Controlla la casella di posta, una mail per il reset della password è stata inviata.",
  "SUCCESS.USER.RESET_PASSWORD":
    "La tua password è stata cambiata con successo.",
  "SUCCESS.USER.PASSWORD_CHANGED":
    "La tua password è stata cambiata con successo.",
  "SUCCESS.USER.UPDATE_USER": "L'utente è stato aggiornato",
  "ERROR.CHECK_REQUEST_PARAMS":
    "Errore interno durante l'invio della richiesta al server.",
  "ERROR.INTERNAL":
    "Ci dispiace, qualcosa è andato storto! La preghiamo di riprovare più tardi.",
  "ERROR.NOT_AUTHORIZED": "Richiesta non autorizzata.",
  "ERROR.PROJECT.UPDATE_FAILED": "Errore durante il caricamento del progetto.",
  "ERROR.TEAM.CREATE_FAILED": "Impossibile creare la squadra.",
  "ERROR.TEAM.SWITCH_FAILED":
    "Si è verificato un errore durante il cambio di squadra.",
  "ERROR.TEAM.RENAME_FAILED":
    "Si è verificato un errore durante la ridenominazione della squadra.",
  "ERROR.TIMER.UPDATE_FAILED": "Errore durante l’aggiornamento del timer.",
  "ERROR.TIMER.DELETE_FAILED": "Errore durante la cancellazione del timer",
  "ERROR.USER.NOT_MEMBER": "L’utente non è membro di qualsiasi squadra.",
  "ERROR.USER.RESET_EMAIL_FAILED":
    "Errore durante l’invio della mail di reset della password.",
  "ERROR.USER.CURRENT_PASSWORD_WRONG": "la password non è corretta.",
  "ERROR.USER.CHANGE_PASSWORD_FAILED":
    "Errore durante il cambio della password.",
  "ERROR.USER.EMAIL_PASSWORD_WRONG": "Email o password errati.",
  "ERROR.USER.CREATE_INVITE_FAILED":
    "Errore durante la creazione dell’invito ad un utente.",
  "ERROR.USER.ALREADY_TEAMMATE": "Utente già nella squadra.",
  "ERROR.USER.EMAIL_EXISTS": "Email esistente.",
  "ERROR.USER.CREATE_USER_FAILED": "Errore durante la creazione dell’utente.",
  "ERROR.USER.UPDATE_USER_PERMISSIONS_DENIED":
    "Non hai i permessi per aggiornare l’utente.",
  "ERROR.USER.UPDATE_USER_FAILED":
    "Errore durante l’aggiornamento dell’utente.",
  "ERROR.USER.UPDATE_USER_EMAIL_EXISTS": "Questa email esiste già.",
  "ERROR.USER.UPDATE_USER_ROLE_FAILED":
    "Non puoi cambiare il ruolo nella tua squadra.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ROLE_FAILED":
    "Non puoi modificare il ruolo del proprietario della squadra.",
  "ERROR.USER.UPDATE_ACTIVE_STATUS_FAILED":
    "Non puoi essere in grado di impostare il tuo account come inattivo nel tuo squadra.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ACTIVE_STATUS_FAILED":
    "Non puoi essere in grado di impostare l’account del proprietario della squadra come inattivo.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_FAILED":
    "L’utente è stato cancellato con successo dalla squadra.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_SUCCEED":
    "L’utente è stato cancellato con successo dalla squadra.",
  "ERROR.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Si è verificato un errore durante l’eliminazione dalla squadra.",

  v_a_avatar_upload_error:
    "Errore di caricamento! Il file scaricato non è un`immagine o la sua dimensione supera 1 Mb.",
  v_a_data_updated_ok: "I tuoi dati sono stati aggiornati",
  v_a_change_password_great_ok: "Fantastico! Ora puoi accedere nuovamente!",
  v_a_change_password_ok: "Hai cambiato la password con successo!",
  v_a_password_same_error: "Le password non sono uguali!",
  v_a_invite_sent: "L’invito è stato trasmesso!",
  v_a_invite_sent_error:
    "Errore durante la trasmissione dell’invito all’utente!",
  v_a_invite_sent_user_error:
    "Qualcosa è andato storto... Rimuovi l'utente da 'Negato' e invita di nuovo",
  v_a_project_created: "Progetto creato",
  v_a_project_updated: "Progetto aggiornato",
  v_a_project_existed: "Progetto già esistente.",
  v_a_project_created_error: "Il progetto non può essere creato.",
  v_a_project_no_valid_name: "Il nome del progetto non può contenere '%'",
  v_a_project_created_synchronization_with_jira_error:
    "Il progetto Jira è già collegato al progetto",
  v_a_team_name_empty_error: "Il nome della squadra non può essere vuoto!",
  v_a_project_edit_error: "Il progetto non può essere modificato",
  v_a_time_start_error: "Inizio del tempo sbagliato, per favore controllo!",
  v_a_time_end_error: "Fine del tempo sbagliato, per favore controllalo!",
  v_a_time_already_busy:
    "L'attività non può sovrapporsi nel tempo con un'altra attività",
  v_a_time_future_time_error:
    "Ora di fine errata. Non puoi scegliere il tempo in futuro",
  v_a_time_already_busy_current_task:
    "Questa volta è già occupato dall'attività corrente",
  v_a_task_name_error:
    "Inserisci il nome del compito prima di salvare monitoraggio del tempo",
  v_a_team_existed: "La squadra è già esistente",
  v_a_team_rename_error: "La squadra non può essere rinominata",
  v_a_team_create_error: "La squadra non può essere creata",
  v_a_task_name_before: "Inserisci prima il nome del compito",
  v_a_project_before: "Seleziona il progetto prima",
  v_a_select_project: "Seleziona progetto",
  v_a_starting: "starting",
  v_a_stopping: "stopping",
  v_a_time_tracking: "monitoraggio del tempo",
  v_a_account_create: "L’account è stato creato",
  v_a_confirm_password_error: "Valore di conferma password sbagliato",
  v_a_project_name_error: "Il nome del progetto non può essere vuoto",
  v_a_task_delete: "Vuoi davvero cancellare questo valore del tempo?",
  v_a_incorect_email: "Email non corretta",
  v_a_error_authorization: "Qualcosa è andato storto. Prova più tardi",
  v_a_incorect_password: "Inserisci almeno 6 caratteri",
  v_a_client_existed: "Il client esiste già",
  v_a_client_name_empty_error: "Il nome del cliente non può essere vuoto!",

  v_set_default: "scegliere",
  v_default: "predefinito",
  v_help: "Aiuto",
  v_upload_image: "Carica immagine",
  v_delete_image: "Cancella immagine",
  v_phone: "Telefono",
  v_more_option: "Opzioni",
  v_active_team: "Squadra attiva",
  v_set: "imposta",
  v_team_is_active: "squadra attiva",
  v_ok: "OK",
  v_cancel: "Cancella",
  v_language: "Lingua",
  v_cofirm_new_password: "Conferma nuova password",
  v_current_password: "Password corrente",
  v_save_changes: "Salva i cambiamenti",
  v_your_name: "Il tuo nome",
  v_my_profile: "Il mio profilo",
  v_new_team: "Nuova squadra",
  v_profile_settings: "Impostazioni del profilo",
  v_change_password: "Cambia password",
  v_new_password: "Nuova password",
  v_send: "Invia",
  v_check_email:
    "Se la tua mail è registrata in Wobbly controlla la tua casella di posta e segui le istruzioni riportate nella mail",
  v_check_email_error: "La tua email non è stata inviata!",
  v_enter_email: "Inserisci la tua email",
  v_create_team: "Crea squadra",
  v_search_project: "Cerca progetto",
  v_search_client: "Cerca client",
  v_start_timer: "fai partire il timer",
  v_edit_task: "Modifica compito",
  v_delete_task: "Elimina compito",
  v_add_confirm_password: "Conferma la tua password",
  v_registration_terms_and_policy:
    "Registrandoti accetti i Termini e la politica sulla Privacy",
  v_login: "Accesso",
  v_log_in: "Accedi",
  v_email: "E-mail",
  v_email_error: "Email troppo lunga, solo 72 simboli disponibili",
  v_register: "Registrati",
  v_password: "Password",
  v_unsubscribe: "Annulla iscrizione",
  v_sure: "Vuoi davvero cancellarti da questa mailing list?",
  v_success_unsubscribe: "L'utente ha annullato l'iscrizione con successo",
  v_error_already_unsubscribe: "L'utente ha già annullato l'iscrizione",
  v_sure_reports: "Vuoi davvero annullare l'iscrizione ai rapporti?",
  v_success_unsubscribe_reports:
    "Utente annullato con successo l'iscrizione ai rapporti",
  v_error_already_unsubscribe_reports:
    "L'utente ha già annullato l'iscrizione ai rapporti",
  v_enter: "Accesso",
  v_incorrect_password_or_email:
    "La tua email o password non sono corrette. Riprova",
  v_cofirm_password: "Conferma password",
  v_forgot_your_password: "Hai dimenticato la password",
  v_dont_have_an_account_yet: "Non hai ancora un account",
  v_already_have_an_account: "Hai già un account",
  v_sign_up: "Iscriviti",
  v_timer: "Timer",
  v_reports: "Rapporti",
  v_report_header: "Rapporti:",
  v_summary: "Summary",
  v_projects: "Progetti",
  v_project: "Progetto",
  v_people: "Persone",
  v_description: "Descrizione",
  v_teams: "Squadre",
  v_bench: "Panchina",
  v_team: "Squadra",
  v_add_team: "Aggiungi squadra",
  v_add_your_password: "Aggiungi la tua password",
  v_my_team: "La mia squadra",
  v_total_time: "Tempo totale",
  v_task_name: "Nome del compito",
  v_time_start: "Data di inizio",
  v_time_end: "Data di consegna",
  v_change: "Modifica",
  v_last_change: "Ultima modifica",
  v_create_new_project: "Crea un nuovo progetto",
  v_apply: "Applica",
  v_edit_project: "Modifica progetto",
  v_time: "Tempo",
  v_create_project: "Crea progetto",
  v_log_out: "Disconnettersi",
  v_add_user: "Invia richiesta",
  v_invite_to_team: "Invita a fare squadra",
  v_team_role: "Ruolo di squadra",
  v_edit_user: "Modifica utente",
  v_name: "Nome",
  v_surname: "Cognome",
  v_edit_team_name: "Modifica nome squadra",
  v_rename_team: "Rinomina squadra",
  v_team_roles: "Ruoli di squadra",
  v_summary_report: "Riepilogo",
  v_total: "Totale",
  v_total_time: "Tempo totale tracciato",
  v_total_billable: "Pagato",
  v_total_not_billable: "Non pagato",
  v_export: "Esportare",
  v_archivate: "Archiviare",
  v_unarchivate: "Disarchiviare",
  v_detailed_proj: "Dettagliato",
  v_detailed_export: "Rapporto dettagliato",
  v_simple_export: "Relazione di sintesi",
  v_user: "Utente",
  v_select_all: "Seleziona tutto",
  v_select_none: "Non selezionare niente",
  v_project_name: "Nome del progetto",
  v_issue: "Problema",
  v_sum_tasks: "Riepilogo attività",
  v_sum_time: "Tempo di riepilogo",
  v_user_name: "Nome utente",
  v_add_your_email: "Aggiungi il tuo Email",
  v_add_your_task_name: "Aggiungi il nome dell'attività",
  v_find: "Trova",
  v_back: "Indietro",
  v_team_name: "Nome della squadra",
  v_team_access: "Accesso",
  v_connection_problem: "Connessione Internet persa",
  v_connection_restored: "Connessione a Internet ripristinata",
  v_all_role: "Tutti ruoli",
  v_all_access: "Tutti accesso",
  v_all: "Tutti",
  v_active: "Garantito",
  v_not_active: "Negato",
  v_today: "Oggi",
  v_yesterday: "Ieri",
  v_thisWeek: "Questa settimana",
  v_lastWeek: "La settimana scorsa",
  v_thisMonth: "Questo mese",
  v_lastMonth: "Lo scorso mese",
  v_this_year: "Quest'anno",
  v_last_year: "L'anno scorso",
  v_days_up_to_today: "giorni fino ad oggi",
  v_days_starting_today: "giorni da oggi",
  v_jira_synchronization: "Jira sincronizzazione",
  v_chart_label_total: "Totale ore per data",
  v_enter_to: "Accedere",
  v_to_get_token: "per ottenere il token Api",
  v_login_with: "Entra con",
  v_or: "o",
  v_or_continue_with: "o continua con",
  v_date_format: "Formato data",
  v_first_day_of_week: "Primo giorno della settimana",
  v_time_format: "Formato orario",
  v_duration_display_format: "Formato di visualizzazione della durata",
  v_switch_team_to_the: "La squadra passa a",
  v_show: "spettacolo",
  v_hide: "nascondere",
  v_clients: "Сlienti",
  v_client: "Сliente",
  v_add_new_client: "Aggiungi nuovo cliente",
  v_client_name: "Nome del cliente",
  v_add_client: "Aggiungi client",
  v_edit_client_name: "Modifica il nome del cliente",
  v_edit_client: "Modifica",
  v_select_client: "Seleziona cliente",
  v_sync_with_jira_project: "Sincronizzazione con il progetto Jira",
  v_sync_with_jira_project_exist:
    'Il progetto Jira è già collegato al progetto "{{projectName}}"',
  v_jira_synchronization_problem:
    "I compito di Jira non erano sincronizzati. Assicurati che l'accesso a Jira sia corretto.",
  v_jira_synchronization_ok: "I compito di Jira erano sincronizzati",
  v_add_project_name: "Aggiungi il nome del progetto",
  v_edit_project_name: "Modifica il nome del progetto",
  v_verify: "Verificare",
  v_classic: "Classico",
  v_improved: "Migliorata",
  v_decimal: "Decimale",
  v_short_day: "GG",
  v_short_month: "MM",
  v_short_year: "AAAA",
  v_empty: "È vuoto",
  v_empty_email: "E-mail obbligatoria",
  v_empty_password: "È richiesta una password",
  v_cofirm_password_required: "È richiesta la conferma della password",
  v_add_old_password: "Aggiungi la password corrente",
  v_add_new_password: "Inserisci la tua nuova password",
  v_jira_synchronization_confirm: "Sei sicuro di voler sincronizzare?",
  v_add_member: "Aggiungi membro",
  v_member_dots: "Membro...",
  v_combine: "Combina i compiti",
  v_delete_user: "Elimina utente",
  v_delete_user_text:
    "L'utente verrà eliminato definitivamente. Tutti i squadre e le voci andranno persi. Sei sicuro di voler eliminare?",
  v_user_deleted: "Utente eliminato",

  v_v_required: "Campo obbligatorio",
  v_v_incorect_url: "URL errato",
  v_no_report: "Nessun rapporto da mostrare!",
  v_no_entries: "Nessuna voce recente da mostrare.",
  v_no_entries_sub:
    "È passato molto tempo da quando hai seguito le tue attività!",
  v_no_projects: "Il team attuale non ha progetti per te",
  v_no_projects_sub:
    "Chiedi all'amministratore del tuo team di aggiungerti al progetto",
  v_no_clients: "Nessun cliente da mostrare!",

  v_enter_number: "Numero",

  v_invoices: "Fatture",
  v_cat_not_invoices: "Non hai ancora fatture con questo stato",
  v_add_new_invoice: "Nuova fattura",
  v_all_invoices: "Tutte le fatture",
  v_invoice: "Fattura",
  v_confirm_payment: "Conferma il pagamento",
  v_cancel_confirm: "Annulla la conferma",
  v_send_invoice: "Inviare la fattura",
  v_from: "A partire dal",
  v_to: "Per",
  v_amount: "QTY",
  v_rate: "Vota",
  v_tax: "Imposta",
  v_subtotal: "Totale parziale",
  v_enter_project: "Testo",
  v_pic_tax: "Imposta",
  v_payment_confirmation_window_confirm:
    "Sei sicuro di voler confermare il pagamento?",
  v_payment_confirmation_window_cancel:
    "Sei sicuro di voler annullare il pagamento?",
  v_edit_confirmation_window:
    "La fattura è già stata inviata al cliente, sei sicuro di volerla modificare?",
  v_client_no_email: "Email del cliente non specificata",
  v_letter_send: "La fattura è stata inviata",
  v_add_part_of_payment: "Aggiungi pagamento",
  v_added_payments: "Pagamenti aggiunti",
  v_date: "Data",
  v_currency: "Moneta",
  v_outstanding: "Eccezionale",
  v_add_a_payment: "Aggiungi un pagamento",
  v_sum: "Somma",
  v_add_payment: "Aggiungi pagamento",
  v_no_zero_error: "Non può essere zero",
  v_no_negative: "Non può essere negativo",
  v_not_less: "La somma deve essere inferiore o uguale a in sospeso",
  v_no_payments: 'Nessun pagamento. Fare clic su "+" per aggiungere',
  v_no_active_invoices: "Nessuna fattura attiva",
  v_no_active_invoices_text:
    "Non ci sono fatture attive trovate nel sistema. Ciò potrebbe indicare che tutte le fatture sono state pagate o che non ci sono fatture.",
  v_create_new_invoice: "Crea Nuova Fattura",

  v_invoice_summary: "Riepilogo Fattura",
  v_invoice_reference: "Riferimento",
  v_comments: "Commenti",
  v_select_currency: "Seleziona la Valuta",
  v_invoice_number: "Numero di fattura",
  v_invoice_date: "Data Fattura",
  v_invoice_due: "Dovuto",
  v_edit: "Modificare",
  v_clone: "Duplicare",
  v_delete: "Elimina",
  v_download: "Scarica",
  v_pic_date: "Data foto",
  v_address: "Indirizzo",
  v_add_sender: "Aggiungi mittente",
  v_send_by_email: "Inviare per email",
  v_choose_recipient: "Scegli il destinatario",
  v_select_logo_file: "Trascina qui il tuo logo o seleziona un file",
  v_send_invoice_placeholder: "segnaposto",

  v_no_overdues: "Nessun ritardo",
  v_no_total: "Nessun totale in sospeso",
  v_no_drafts: "Nessuna bozza",
  v_last_invoices: "Ultime fatture",
  v_clear_filters: "Cancella filtri",
  v_filter_all_projects: "Tutti i progetti",
  v_filter_all_clients: "Tutti i clienti",
  v_filter_all_invoices: "Tutte le fatture",
  v_filter_active: "Attivo",
  v_filter_archived: "Archiviato",
  v_filter_draft: "Bozza",
  v_filter_awaiting: "Inatteso",
  v_filter_reviewed: "Rivisto",
  v_filter_paid: "Pagato",
  v_filter_overdue: "In ritardo",
  v_grand_total: "Totale rimasto:",
  v_changing_status: "Modifica dello stato su",

  v_will_generate: "Verrà generato dopo la creazione",
  v_save: "Salvare",
  v_overdue: "In ritardo",
  v_total_outstanding: "Assolutamente stupendo",
  v_draft: "Bozza",
  v_paid: "Pagato",
  v_paid_full: "Pagato per intero",
  v_paid_zero: "Nessun pagamento",
  v_reviewed: "Rivisto. Cambiare stato?",
  v_overdue_tip: "In ritardo. Cambiare stato?",
  v_draft_tip: "Bozza. Cambiare stato?",
  v_paid_tip: "Pagato. Cambiare stato?",

  v_delete_member: "Elimina membro",
  v_clone_invoice: "La tua fattura è stata clonata",
  v_send_payment: "Inviare la fattura",
  v_no_invoices: "Nessuna fattura da mostrare",
  v_owner: "Proprietario",
  v_admin: "Amministratrice",
  v_manager: "Manager di fatture",
  v_hr_manager: "Responsabile Risorse Umane",
  v_proj_manager: "Responsabile di progetto",
  v_recruiter: "Reclutatore",
  v_member: "Membro",
  v_roles: "Ruoli",
  v_country: "Paese",
  v_city: "Città",
  v_state: "Stato",
  v_zip: "Codice postale",
  v_address_lowC: "indirizzo",
  e_mail: "E-Mail",
  v_billing_code_error: "Codice postale errato",

  v_enter_text: "Inserire il testo",
  v_technologies: "Tecnologia",
  v_add_technology: "Inserisci",
  v_company_name: "Nome della ditta",

  client_was_deleted: "Il client è stato eliminato",
  client_was_edited: "Il client è stato modificato",
  client_was_created: "Il cliente è stato creato",
  client_dont_was_deleted:
    "Non puoi eliminare il client perché ha conti fissi. Elimina gli account ad esso associati e riprova!",

  v_select_logo_file_bigger: "Trascina la foto qui o seleziona un file",
  v_zip_code: "Zip",
  v_enter_country: "Inserisci il paese",

  v_street: "Strada",
  v_enter_city: "Inserisci la città",
  v_enter_language: "Scegli la lingua",
  v_delete_client: "Elimina client",
  v_enter_state: "Entra nell'area",
  company_name: "Nome della ditta",
  full_name: "Nome/cognome",

  select_country: "Seleziona il paese",

  v_edit_client_title: "Modifica client",
  no_valid_number: "Il numero di telefono non è valido",
  no_valid_zip: "codice postale errato",
  no_valid_user_name: "Il campo Nome/Cognome non può contenere spazi",
  no_valid_number_mask: "+XXXXXXXXXXXX",
  v_fill_fields: "Si prega di compilare tutti i campi obbligatori",
  v_fill_fields_company_name:
    "Inserisci il nome dell'azienda nel campo del mittente",
  v_tags: "Tag",
  v_tags_tooltip: "Competenze, tecnologie, ecc.",
  v_cv: "Curriculum utente",
  v_edit_sender: "Modifica mittente",
  v_copy: "Сopiare",
  v_delete_invoice: "Elimina Fattura",
  v_prompt: "Sei sicuro di voler eliminare la fattura?",
  v_confirm: "Confermare",
  v_invoice_number_exist: "Il numero della fattura esiste",
  v_awaiting: "In attesa di. Cambiare stato?",
  v_price: "Somma",
  v_status: "Stato",
  v_physical_address: "Indirizzo fisico",
  v_error_company_name: "Compila il campo - Nome azienda",
  v_time_date: "Ora e data",
  v_err_technology_exist: "Il tag esiste",

  v_search: "Ricerca",
  v_reset: "Ripristina",
  v_sync_all: "Sincronizzare tutti",
  v_sync_success: "Successo della sincronizzazione",
  v_result: "Risultati",

  v_support: "Supporto",
  v_tell_us: "Parlaci del tuo problema",
  v_type_message: "Scrivi il messaggio...",
  v_min: "Deve essere di almeno 8 caratteri",
  v_max: "Deve essere inferiore a 500 caratteri",
  v_message_error: "Errore: La lettera non può essere vuota",
  v_message_sent: "Messaggio inviato con successo",

  v_copy_and_share: "Copia e condividi il link",
  v_show_more: "Mostra di più",
  v_copy_the_link: "Copia link",
  v_copy_link: "Copia link",
  v_done: "Fatto",
  v_link_copied: "Link copiato",
  v_add_a_discount: "Aggiungi uno sconto",
  v_add_discount: "Aggiungi uno sconto",
  v_discount: "Sconto",
  v_discount_subtotal: "del totale parziale della fattura",
  v_round_to: "Scritto da:",
  v_report_round: "Arrotondamento decimale del rapporto",
  v_decimal_report: "Rapporto decimale",
  v_minutes: "minuti",

  v_info: {
    timeTracking: "Rilevamento del tempo",
    resourcePlanning: "Pianificazione delle risorse",
    reporting: "Segnalazione",
    realTimeReports: "Rapporti in tempo reale",
    invoicing: "Fatturazione",
    owner: "Proprietario",
    admin: "Amministratore",
    member: "Membro",
    invoiceManager: "Gestione fatture",
  },

  v_extra: {
    price: "€5/mo",
    feature1: "+1 client",
    feature2: "+1 squadra",
    feature3: "+2 progetti",
    star: "*occasione una tantum",
  },

  v_choose: "Scegli il tuo piano",

  v_coupon_doesnt_exist: "Questo coupon non esiste",
  v_coupon_expired: "Questo coupon è scaduto e non può essere applicato",
  v_coupon_used_up: "Questo coupon è esaurito e non può essere applicato",

  v_rp_history: {
    historyS: "storia",
    historyL: "Storia",
    header: {
      when: "Data di modifica",
      who: "Editor",
      type: " Tipo di modifica",
    },
    table: {
      created: "Creato",
      updated: "Aggiornato",
      from: "Da: ",
      to: "A: ",
      plan: "Nome del piano",
      period: "Periodo del piano",
      project: "Nome del progetto",
      user: "Destinatario",
      description: "Descrizione",
    },
  },

  v_hr_people: {
    leftBar: "Reclutamento",
    header: {
      title: "Reclutamento",
      board: "Direzione",
      applications: "Applicazioni",
      vacancies: "Offerte di lavoro",
      candidates: "Candidati",
    },
    candidates_modal: {
      preview: "anteprima",
      draft: "bozza",
      edit: "modifica",
      personal: "Informazioni personali",
      social: "Reti sociali",
      start_work: "Disponibilità ad iniziare il lavoro",
      about: "Informazioni sul candidato",
      booking: "Prenotazione incontro e feedback",
      jobType: "Tipo di lavoro",
      other: "Altro",
      desire: "Data del desiderio",
      start: "Inizio lavoro",
      experience: "Esperienza",
      cv: "CV",
      cvClient: "CV per il cliente",
      level: "Livello",
      salary: "Stipendio",
      hrInt: "Data del colloquio",
      techInt: "Data del colloquio tecnico",
      feedHr: "Feedback HR",
      feedTech: "Feedback Tech",
      namePlace: "Tipo nome...",
      surnamePlace: "Scrivi cognome...",
      emailPlace: "Digitare e-mail...",
      countryPlace: "Scegliere il paese",
      birthdayPlace: "Scegliere la data di nascita",
      otherPlace: "Altri link, ecc.",
      desirePlace: "Data in cui si desidera lavorare",
      startPlace: "Data inizio lavoro",
      cvPlace: "Allega CV",
      direction: "Direzione",
      vacancy: "Posto vacante",
      noVacancies: "Nessun posto libero",
      minSalPlace: "Da",
      maxSalPlace: "A",
      hrIntPlace: "Colloquio con le risorse umane",
      techIntPlace: "Colloquio tecnico",
      feedHrPlace: "Tipo feedback HR...",
      feedTechPlace: "Tipo feedback tech...",
    },
    directions: {
      no_directions_text: "Non ci sono ancora direzioni",
      new_direction: "Nuova direzione",
      search_by_name_placeholder: "Ricerca per nome...",
    },
    candidates: {
      new_candidates: "Nuovi candidati",
      no_candidates_text: "Non ci sono ancora candidati",
      headerTable: {
        name: "Nome",
        job_type: "Tipo di lavoro",
        country: "Paese",
        city: "Città",
        level: "Livello",
        direction: "Direzione",
        salary: "Stipendio:",
        latest_update: "Ultimo aggiornamento",
        creation_date: "Data di creazione",
        junior: "Junior",
        middle: "Middle",
        senior: "Senior",
        trainee: "Apprendista",
        remote: "Remoto",
        hybrid: "Ibrido",
        office: "Ufficio",
      },
    },
    switcherForVacancies: {
      active: "Active",
      archive: "Archive",
    },
    vacancies: {
      no_vacancies_text: "Non ci sono ancora posti vacanti",
      new_vacancies: "Nuovi posti vacanti",
      vacancies_created: "Posto vacante creato",
      vacancies_edited: "Posto vacante modificato",
      link_copied: "Collegamento copiato",
      vacancy_data: "Dati sulla posizione",
      job_application: "Candidatura",
      select_job_application: "Seleziona candidatura",
      vacancy_name: "Nome posizione",
      company: "Azienda",
      type_here: "Digita qui...",
      priority: "Priorità",
      choose_status: "Scegli stato",
      choose_priority: "Scegli priorità",
      direction: "Direzione",
      experience: "Esperienza",
      level: "Livello",
      job_description: "Descrizione del lavoro",
      type_text_here: "Digita il testo qui...",
      number_of_positions: "Numero di posizioni",
      type_of_employment: "Tipo di impiego",
      expected_salary: "Gamma salariale",
      job_location: "Località della posizione",
      location_optional: "Luogo (opzionale)",
      job_type: "Tipo di lavoro",
      remote: "Da remoto",
      hybrid: "Ibrido",
      office: "Ufficio",
      foreign_languages: "Lingue straniere",
      additional_information: "Informazioni aggiuntive",
      link_to_job: "Link alle offerte di lavoro (su diverse piattaforme)",
      requirements: "Requisiti",
      nice_to_have: "Un vantaggio sarà",
      working_conditions: "Condizioni di lavoro",
      preview: "Anteprima",
      responsibilities: "Responsabilità",
      salary_range: "Fascia di reddito",
      exit: "Uscita",
    },
    vacanciesHeaderTable: {
      name: "Nome del posto vacante",
      status: "Stato",
      priorities: "Priorità",
      number_of_candidates: "Numero di candidati",
      latest_update: "Ultimo aggiornamento",
      deadline: "Scadenza",
      created: "Creata",
    },
    new_direction_modal: {
      edit: "Modifica cambio direzione",
      create: "Crea direzione",
      name_dir: "Nome della direzione",
      name_placeholder: "Tipo per aggiungere la direzione...",
      vacancies: "Posti vacanti allegati",
      vacancies_placeholder: "Seleziona o aggiungi",
    },
    applications: {
      no_applications_text: "Non ci sono ancora applicazioni",
      new_applications: "Nuove applicazioni",
      archive: "Archivio",
      name: "Nome",
      typeName: "Inserisci nome...",
      direction: "Direzione",
      typeDirection: "Inserisci direzione...",
      level: "Livello",
      typeLevel: "Inserisci livello...",
      experience: "Esperienza",
      typeExperience: "Inserisci esperienza...",
      salary: "Salario",
      from: "Da",
      to: "A",
      deadline: "Scadenza",
      invalidDate: "Data non valida",
      applicationPriority: "Priorità della domanda...",
      country: "Paese",
      searchCountry: "Cerca paese...",
      selectCountry: "Seleziona paese",
      city: "Città",
      typeEmployment: "Tipo di impiego",
      selectEmployment: "Seleziona tipo di impiego",
      numberPositions: "Numero di posizioni",
      typeNumberPositions: "Inserisci numero di posizioni",
      project: "Progetto",
      typeProject: "Inserisci nome del progetto",
      languageSkills: "Competenze linguistiche",
      enterLanguagesSkills: "Inserisci competenze linguistiche",
      requirements: "Requisiti",
      enterRequirements: "Inserisci i requisiti",
      comment: "Commento",
      enterComment: "Inserisci il commento",
      successful: "Riuscito",
      youHaveSuccessful: "Hai creato con successo un'applicazione",
    },
    headerTable: {
      name: "Nome",
      vacancies_available: "Posti vacanti disponibili",
      priorities: "Priorità",
      number_of_candidates: "Numero di candidati",
      latest_update: "Ultimo aggiornamento",
      deadline: "Scadenza",
      name_applications: "Nome applicazioni",
      location: "Posizione",
      date_of_creation: "Data di creazione",
    },
    prioritiesSelectors: {
      high: "Alta",
      medium: "Medio",
      low: "Bassa",
    },
    deadlineSelectors: {
      newest: "Il più recente",
      oldest: "Più vecchio",
    },
    statusSelectors: {
      open: "Aprire",
      closed: "Chiusa",
      rejected: "Respinto",
      onHold: "In attesa",
    },
  },

  v_subscription: {
    extraFeatures: "Аcquistare funzionalità extra",
    leftBar: "Abbonamenti",
    header: {
      all: "Tutti i piani",
      overview: "Panoramica",
      invAndPaym: "Fatture e pagamenti",
    },
    switcher: {
      trial: "-prova gratuita di  giorni inclusa",
      month: "Mese",
      year: "Annuale",
      discount: "Risparmia fino al 20%",
    },
    plan: {
      btnCurrent: "Piano attuale",
      btnUpgrade: "Aggiornamento",
      btnDowngarde: "Downgrade",

      mo: "me per utente",
      yr: "me fatturati ogni anno",
      btnUpgradeNow: "Aggiorna ora",
    },
    free: {
      users: "Utenti illimitati",
      features: "Tutte le funzioni abilitate",
      notifications: "Le notifiche automatiche sono attivate",
      roles: "La gestione dei ruoli è abilitata",
      projects: "2 Progetti",
      history: "Cronologia dati di 35 giorni",
      clients: "1 Cliente",
      teams: "1 Squadra",
      wobbly: "Wobbly Time App incluso",
      integrations: "Le integrazioni sono abilitate",
      storage: "Archiviazione dati 1 GB",
    },
    basic: {
      users: "Utenti illimitati",
      features: "Tutte le funzioni abilitate",
      notifications: "Le notifiche automatiche sono attivate",
      roles: "La gestione dei ruoli è abilitata",
      projects: "10 Progetti",
      history: "Cronologia dati di 365 giorni",
      clients: "10 Clienti",
      teams: "10 Squadre",
      wobbly: "Wobbly Time App incluso",
      integrations: "Le integrazioni sono abilitate",
      storage: "Archiviazione dati illimitata",
    },
    donut: {
      teams: "15 Squadre incluse",
      tracks: "Monitoraggio del tempo illimitato",
      projects: "15 Progetti attivi",
      history: "365 giorni di cronologia dei dati",
      clients: "15 clienti",
      invoices: "Fatturazione inclusa",
      integrations: "Integrazioni incluse",
      storage: "10 GB archivio dati",
    },
    pro: {
      users: "Utenti illimitati",
      features: "Tutte le funzioni abilitate",
      notifications: "Le notifiche automatiche sono attivate",
      roles: "La gestione dei ruoli è abilitata",
      projects: "Progetti illimitati",
      history: "Cronologia dati di giorni illimitati",
      clients: "Clienti illimitati",
      teams: "Squadre illimitate",
      wobbly: "Wobbly Time App incluso",
      integrations: "Le integrazioni sono abilitate",
      storage: "Archiviazione dati illimitata",
    },
    billingModalDowngrade: {
      title: `Panoramica dell'abbonamento`,
      currentPlan: "Il tuo piano attuale",
      pait: "pagato a",
      text: `il piano passerà a un nuovo piano dopo la scadenza dell'abbonamento corrente`,
      button: "Continua",
    },
    billingModal: {
      next: "Il prossimo",
      startExploring: "Inizia a esplorare",
      trialHasNotExpired:
        "Non hai ancora esaurito il 'tempo di prova', se continui andrà perso. Vuoi fare un acquisto ora?",
      secondStep: {
        title: "Panoramica dell'abbonamento",
        plan: "Piano",
        firstCharge: "Prima carica",
        billingInfo: "Informazioni di fatturazione",
        changeBilling: "Cambiare",
        paymentInfo: "Informazioni sul pagamento",
        changePayment: "Cambiare",
      },
      congratsStep: {
        congrats: "Congratulazioni!",
        info: "Ora puoi utilizzare tutte le funzionalità, incluso",
      },
      noData: "Si prega di compilare i dati di pagamento",
    },
    billingInfoEditModal: {
      title: "Informazioni di fatturazione",
    },
    overview: {
      trialWarning:
        "Aggiungi i dettagli di pagamento per continuare a utilizzare {{planName}} dopo il periodo di prova.",
      trialAddPaymentInfo: "Aggiungi informazioni di pagamento",
      updateBillingInfo: "Aggiornare",
    },
    invoicesAndPayments: {
      title: "Cronologia fatturazione",
    },
  },
  v_plan_details: {
    subscriptionExpired:
      "Il tuo piano verrà modificato in 'Free' dopo la scadenza dell'abbonamento attuale   ",
    subscriptionExpiredTrial:
      "Il tuo piano verrà modificato in 'Free' dopo la scadenza dell'attuale abbonamento di prova   ",
    status: "Stato dell`abbonamento",
    subscriptionRenewingOn: "Il tuo abbonamento verrà rinnovato il",
    subscriptionRenewingOnFree: "Free",
    currentPlan: "Piano attuale",
    costUserMonth: "Costo utente / mese",
    users: "Utenti",
    daysLeftOnTrial: "Giorni rimasti in prova",
  },
  v_billing_info: {
    title: "Fatturazione",
    changeBillingInfo: "Aggiornare",
  },
  v_billing_info_details: {
    country: "Nazione",
    city: "Città",
    postalCode: "Codice postale",
    emailAddress: "E-mail",
    street: "Strada",
    houseNumber: "Numero di casa",
    firstName: "Nome di battesimo",
    lastName: "Cognome",
    companyName: "Nome della ditta",
    phoneNumber: "Numero di telefono",
  },
  v_payment_info: {
    title: "Pagamento",
    addPaymentInfo: "Aggiungere informazioni",
    changePaymentInfo: "Modifica informazioni",
  },
  v_payment_method_details: {
    cardDeclined: "La tua carta non è supportata!",
    paymentMethod: "Metodo di pagamento",
    noPaymentMethod: "Nessun metodo di pagamento disponibile",
    labels: {
      country: "Country",
      expiryMonth: "Mese di scadenza",
      expityYear: "Anno di scadenza",
      cardHolder: "Titolare della carta",
      masked: "Scheda PAN",
      holder: "Titolare",
      bic: "BIC",
      iban: "IBAN",
    },
    methods: {
      CreditCard: "Carta di credito",
      BankAccount: "Conto bancario",
    },
  },
  v_billing_history_table: {
    date: "Data",
    description: "Descrizione",
    amount: "Quantità",
    invoice: "Fattura",
  },
  v_payment_info_modal: {
    next: "Il prossimo",
  },
  v_payment_info_state: {
    updateSuccess:
      "Le informazioni di pagamento sono state aggiornate correttamente",
    updateError: "Errore di aggiornamento delle informazioni di pagamento",
  },
  v_billing_info_form: {
    country: { label: "Nazione", placeholder: "Seleziona il paese" },
    city: { label: "Città", placeholder: "Entra in città" },
    postalCode: {
      label: "Codice postale",
      placeholder: "Inserire il codice postale",
    },
    emailAddress: { label: "E-mail", placeholder: "Inserisci l'email" },
    street: { label: "Strada", placeholder: "Inserisci Street" },
    houseNumber: {
      label: "Numero di casa",
      placeholder: "Immettere il numero civico",
    },
    firstName: { label: "Nome di battesimo", placeholder: "Immettere il nome" },
    lastName: { label: "Cognome", placeholder: "Inserisci il cognome" },
    companyName: {
      label: "Nome della ditta",
      placeholder: "Immettere il nome dell'azienda",
    },
    phoneNumber: {
      label: "Numero di telefono",
      placeholder: "Inserisci il numero di telefono",
    },
  },
  v_billing_info_state: {
    updateSuccess:
      "Le informazioni di fatturazione sono state aggiornate correttamente",
    updateError: "Errore di aggiornamento delle informazioni di fatturazione",
  },
  v_upgrade_pay_state: {
    updateSuccess: "L'abbonamento è stato aggiornato con successo",
    updateError: "Errore di aggiornamento dell'abbonamento",
  },
  v_page_access_denied:
    "Non disponi di diritti sufficienti per visualizzare questa pagina",
  v_page_access_request_owner:
    "Chiedi al proprietario del team di concederti l'accesso",
  v_page_invoice_deleted: "Questa fattura è stata eliminata",
  v_project_not_available: "Il progetto per questa attività non è disponibile",
  v_upgrade_projects_title: "Aggiorna per creare progetti più attivi`",
  v_upgrade_projects_description:
    "Sfortunatamente, hai raggiunto il numero massimo di progetti attivi nel tuo piano.",
  v_upgrade_teams_title: "Aggiorna per creare più squadre",
  v_upgrade_teams_description:
    "Sfortunatamente, hai raggiunto il numero massimo di squadre nel tuo piano.",
  v_upgrade_button: "Piano di aggiornamento",
  v_upgrade_sidebar_title_trial_banner: "Stai utilizzando la versione di prova",
  v_upgrade_sidebar_title_trial_banner_coupon:
    "Ultimo giorno della versione di prova",
  v_upgrade_sidebar_text_trial_banner: "giorni rimasti",
  v_upgrade_sidebar_text_trial_banner_few_days: "giorno rimasti",
  v_last_trial_day: "Ultimo giorno di prova",
  v_last_trial_day_with_coupon: "Aggiorna il tuo piano con un coupon",
  v_upgrade_sidebar_title: "Piano di aggiornamento per ulteriori funzionalità.",
  v_upgrade_sidebar_button: "Aggiorna",
  v_apply_sidebar_button: "Applicare il codice",
  v_upgrade_clients_title: "Esegui l`upgrade per creare più clienti",
  v_upgrade_clients_description:
    "Sfortunatamente, hai raggiunto il numero massimo di clienti del tuo piano.",
  v_upgrade_admin_note:
    "Se desideri aggiornare il tuo piano, contatta il proprietario del tuo team.",
  v_no_results: "Nessun risultato da mostrare",
  v_project_members: "Membri del progetto",
  v_upgrade_label: "Aggiorna il tuo piano",
  v_resource_planning: "Pianificazione delle risorse",
  v_collapse: "Arrotolare",
  v_expand: "Svelare",
  v_add_plan: "Aggiungi Piano",
  v_delete_plan: "Elimina Piano",
  v_delete_plan_confirm: "Sei sicuro di voler eliminare Plan?",
  v_edit_plan_confirm: "Sei sicuro di voler modificare Plan?",
  v_no_recorded_team_time: "Nessun tempo registrato in squadra",
  v_not_active_user: "Non attivo",
  v_real_time_report: "Rapporto in tempo reale",
  v_rt_reports_plug: {
    title:
      "Gestite gli orari dei dipendenti in tempo reale con il software di pianificazione del lavoro.",
    description:
      "Ottenere informazioni sullo stato del progetto e sul carico di lavoro - report in tempo reale per una gestione efficiente delle attività e dei progetti.",
  },
  v_rp_plug: {
    title:
      "Semplificare la programmazione del team con la pianificazione delle risorse",
    description:
      "Tracciate il carico di lavoro complessivo per un'allocazione efficace delle risorse. Avete bisogno di altre funzionalità?",
  },
  v_see_plans: "Vedere i piani",
  v_tooltip_upgrade:
    "Questa funzione è disponibile se si aggiorna il pacchetto",
  v_real_time: "Tempo reale",
  v_activity_view: "Visualizzazione attività di 5 giorni",
  v_resource_planning_page: {
    v_edit_plan_title: "Modifica piano",
    v_add_plan_title: "Aggiungi piano",
    v_teams: "Squadre",
    v_members: "Partecipanti",
    v_type_task_name: "Digita il nome dell'attività...",
    v_projects: "Progetti",
    v_dates: "Date",
    v_from: "A partire dal",
    v_to: "Per",
    v_select_or_add: "Seleziona o Aggiungi",
    v_description: "Descrizione",
    v_type_description: "Digita per aggiungere una descrizione...",
    v_cancel: "Cancella",
    v_show_all_projects: "Mostra tutti i progetti",
    v_show_all_members: "Mostra tutti i membri",
    v_show_all_tags: "Mostra tutti i teg",
    v_birthday_date: "Data di nascita",
    v_join_date: "Data di iscrizione",
    v_vacation_reset: "Ripristino delle ferie",
    v_country: "Nazione",
    v_local_time: "Ora locale",
    v_workload: "Carico di lavor",
    v_day_off: "Giorno libero",
    v_total_left: "Totale/A sinistra",
    v_sick_leave: "Congedo per malattia",
    v_vacation: "Vacanza",
    v_day_off_with_work_off: "Ferie non pagate",
    v_tags: "Tag",
    v_success_created_plan: "Piano creato",
    v_success_edited_plan: "Piano modificato",
    v_success_deleted_plan: "Piano cancellato",
    v_filter_no_results: "Nessun risultato trovato",
    v_error_start_date: "L'ora di inizio del piano è maggiore della fine",
    v_error_set_wright_date:
      "Intervallo di date errato, impostare l'intervallo di date corretto",
    v_last_seen: "Ultima visualizzazione",
    v_user_profile_error: "Qualcosa è andato storto",
    v_success_edited_profile: "Il profilo è stato modificato",
    v_user_profile: "Profilo Utente",
    v_edit_user_profile: "Modifica Profilo",
    v_error_user_is_not_exist_in_project:
      "Utente non aggiunto a questo progetto",
    v_error_user_does_not_exist_in_this_project:
      "L'utente non esiste in questo progetto",
    v_error_user_not_admin_owner:
      "Solo il Owner/Admin può creare un piano per un altro utente",
    v_error_you_can_not_change_holidays:
      "Non è possibile modificare la data dei giorni festivi",
    v_error_delete_plan_in_the_past:
      "Impossibile eliminare il piano in passato",
  },
  v_left_bar: {
    time: "tempo",
    manage: "maneggio",
    invoice: "fattura",
    people: "persone",
    other: "altro",
  },
  v_days: "giorni",
  v_members: "Partecipanti",
  v_filters: "Filtri",
  v_clear_all: "Clear all",
  v_show_by: "Mostra da:",
  v_day: "Giorno",
  v_week: "Settimana",
  v_month: "Mese",
  v_year: "Anno",
  v_assignee: "Nominato",
  v_plan_info: "Informazioni sul piano",
  v_all_members: "Tutti i membri",
  v_select_projects: "Seleziona i progetti...",
  v_notifications: "Notifiche",
  v_not_notifications: "No tienes notificaciones",
  v_release_notes: {
    v_title: "Release Notes",
    v_whats_new: "What's New:",
    v_improved: "Improved/Fixed:",
    v_version: "Version",
    v_versions: {
      "1.7.26": {
        v_new: [
          "Work on bugs/improvements.",
          "Subscription payment model has been changed, now the amount depends on the number of users in the team.",
          "The functionality and benefits of subscriptions have been changed.",
          "Added more detailed notifications in case of synchronization error with Jira.",
          "Added the ability to control Workload on the 'Resource Planning' page. View available load and view and edit actual load.",
          "Bench functionality has been added. Possibility to filter out users with no plans on the 'Resource Planning' page.",
        ],
        v_improved: [
          "Fixed a visual error on the login page when the sidebar was visible.",
        ],
      },
      "1.6.25": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Team' and 'Reports'",
          "New sidebar design implemented",
          "Added the possibility to see the total time for billable and not billable tracks on the 'Reports' page",
          "Added the possibility of inviting several users to the Team at a time on the 'Team' page",
        ],
        v_improved: [
          "Fixed bug with creating a plan for the wrong date that was selected and inability to edit plan daterange on the 'Resource Planning' page",
          "Visual improvements on the 'Resource Planning' page",
          "Fixed a bug with updating the status of an invoice when it is assigned the 'Awaiting' status on the 'Invoices' page",
          "Visual improvements on the 'Invoices' page",
        ],
      },
      "1.6.24": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Teams', 'User Profile', 'Reports', 'Projects', 'Clients' and 'Timer'",
        ],
        v_improved: [
          "Track no longer stops when switching Teams",
          "Fixed a bug when Owner could not remove Admin from the project on the 'Projects' page",
          "Removed the field of synch with Jira when creating and editing a project on the 'Projects' page",
          "Visual and functional improvements on the 'Projects' page",
          "Fixed a bug when Invoice Manager saw the report only by itself on the 'Reports' page",
          "Fixed visual bug with incorrect highlighting of daterange in the calendar on the 'Reports' page",
          "Fixed a bug with incorrect display of birth date calendar on the 'User Profile' page",
          "Fixed a bug where user tags were lost when changing role on the 'Team' page",
          "Visually improved user info window on the 'Resource Planning' page",
          "Improved display of the tooltip with the plan description when there are few users on the 'Resource Planning' page",
          "Improved work of the tooltip with plan description on the 'Resource Planning' page",
          "Changed the order of action for copying a link to an invoice on the 'Invoices' page",
          "Fixed bug with infinite loading of the list of Teams when viewing a sent invoice on the 'Invoices' page",
          "Visual improvements when filling out an invoice on the 'Invoices' page",
        ],
      },
      "1.6.23": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the possibility to purchase a subscription, for which the trial is valid",
        ],
        v_improved: [
          "Fixed a bug where user's name in filters was duplicated on the 'Report' page",
          "Fixed a bug where PM, HR manager and Invoice managers could not use cross-timed reports on the 'Report' page",
          "Fixed a bug where the color of the tooltip did not change when switching the theme on the 'Resource Planning' page",
          "Fixed a bug where PM and HR manager could not invite new users to the team on the 'Team' page",
        ],
      },
      "1.6.22": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to see reports on users with denied access",
        ],
        v_improved: [
          "Improved filter logic on the 'Reports' page",
          "Fixed several bugs on the 'Reports' page",
          "Reports are updated immediately after a Team change",
          "Fixed several bugs on the 'Project' page",
          "Fixed search on 'Client' and 'Project' pages",
          "Improvements have been made to the change history on the 'Resource Planning' page",
        ],
      },
      "1.6.21": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to set tracks for the future in 'Timer' page",
          "Added automatic trimming of the estimate when copying a Jira-synchronized task",
          "Added history of plan changes in 'Resource Planning' page",
        ],
        v_improved: [
          "Improved work of the reports page",
          "Fixed a bug where the report was exported incorrectly when cross-team was enabled in 'Reports' page",
          "Several visual improvemets in sidebar",
          "Several visual improvemets in 'Resource Planning' page",
        ],
      },
      "1.6.20": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Roles', 'Resource Planning', 'Timer', 'Projects' and 'E-mail'",
        ],
        v_improved: [
          "Added mass synch with Jira in 'Timer' page",
          "Role-based accesses have been updated",
          "Fixed minor errors with roles",
          "Fixed bug with drag and drop plan by Recruiter and Memebers in 'Resource Planning' page",
          "Fixed a bug where the recruiter and the memeber only saw the plans of the projects to which they were added 'Resource Planning' page",
          "Fixed minor errors in 'Resource Planning' page",
          "Added weekly report emails for new roles",
          "Weekly reports updated, time period added",
          "Fixed a bug with deleting a user from the project in 'Projects' page",
        ],
      },
      "1.6.19": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Team', ''Resource Planning' and 'Invoices'",
          "Added connection with support",
        ],
        v_improved: [
          "User roles have been updated, added new roles in 'Team' page",
          "Added tooltips for collapsed sidebar",
          "Corrected the display of holidays in 'Resource Planning' page",
          "Visual bugs fixed in 'Reports' page",
          "Visual bugs fixed in 'Invoices' page",
        ],
      },
      "1.6.18": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Register page', 'Projects' and 'Invoices'",
          "Added the ability to log in with Google",
        ],
        v_improved: [
          "Several visual improvemets in 'Register page'",
          "Several visual improvemets in 'Reports' page",
          "Fixed a bug when large reports hang up in 'Repors' page",
          "Updated weekly e-mail reports on time tracked",
          "Fixed a bug when a user was not deleted from the project  in 'Projects' page",
          "Several visual improvemets in 'Free invoice generator' page",
          "Several visual improvemets in 'Invoices' page",
        ],
      },
      "1.6.17": {
        v_new: [
          "Work on bugs/improvements in the 'Subscriptions', 'Reports', 'Clients', 'Email' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Several visual improvemets in 'Subscriptions' page",
          "Several visual improvemets in 'Reports' page",
          "Several visual improvemets in 'Clients' page",
          "Several visual improvemets in 'Resource Planning' page",
          "New weekly e-mail reports on time tracked",
        ],
      },
      "1.6.16": {
        v_new: [
          "Work on bugs/improvements in the 'Invoice' and 'Resource Planning'",
        ],
        v_improved: [
          "Several minor improvements in the 'Resource Planning' section",
          "Added ability to change invoice status in the 'Invoices' section",
        ],
      },
      "1.6.15": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Projects' and  'Subscriptions'",
        ],
        v_improved: [
          "Improved calendar in the Add Plan window in the 'Resource Planning' section",
          "Fixed calendar in the User Profile window for web-mobile in the 'Resource Planning' section",
          "Several minor improvements for web and web-mobile in the 'Resource Planning' section",
          "Visual and logical update for coupons in the 'Subscription' section",
          "Several minor improvements in the 'Subscription' section",
          "Fixed 'Team' filter when Cross-team is active in the 'Reports' section",
          "Fixed a bug when interacting with the report on a user not from the current team in the 'Reports' section",
          "Several minor improvements in the 'Reports' section",
          "Fixed 'Total outstanding' in the 'Invoices' section",
          "Fixed tool-tips in the 'Invoices' section",
          "Fixed Free Invoice Generator",
          "Fixed detailed report in the 'Projects' section",
        ],
      },
      "1.6.14": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Subscriptions' and E-mail sections",
        ],
        v_improved: [
          "Fixed a bug when filters were dropping when scrolling to a new month in the 'Resource Planning' section",
          "Fixed colors of projects in the calendar in the 'Resource Planning' section",
          "The selected filter is moved up in the 'Resource Planning' section",
          "Fixed an error when filters were dropping when deleting a plan in the 'Resource Planning' section",
          "Fixed project colors in filters in the 'Resource Planning' section",
          "Visual improvemetns of 'Resource Planning' page in the 'Resource Planning' section",
          "Fixed photo picker in invoices in the 'Invoices' section",
          "Visual improvemetns of 'Invoices' page for web-mobile",
          "Fixed several minor visual/performance bugs in the 'Invoices' section",
          "Coupons are now saved from the beginning to the end of the trial in the 'Subscriptions' section",
          "The full amount is now visible when you buy a year's subscription in the 'Subscriptions' section",
          "Fixed a bug when users' E-mails were displayed instead of their names in real-time reports when cross-team reports is active in the 'Reports' section",
          "A filter by team has been added to the cross-team reports in the 'Reports' section",
          "Improved web-mobile version in the 'Reports' section",
          "Improved Real-Time Reports page in the 'Reports' section",
          "Fixed several minor visual/performance bugs in the 'Reports' section",
          "Fixed an error that prevented emails from being sent correctly in the 'E-mails' section",
          "Visual improvements/performance in other sections",
        ],
      },
      "1.6.13": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning' and E-mail sections",
        ],
        v_improved: [
          "Fixes filters for summary cross-team reports on the 'Reports'",
          "Reports now sorted by time on the 'Reports'",
          "Added count of users in selected status by online on the 'Reports'",
          "Changed URL for real-time reports on the 'Reports'",
          "Visual changes 'Add plan' window on the 'Resource Planning'",
          "Improved displaying plan name by plan duration in 'Resource Planning'",
          "The plan now has a default duration of 24 hours on the selected day in 'Resource Planning'",
          "Improved 'Join date' window in User Profile window in 'Resource Planning'",
          "Visual improvements in User Profile window in 'Resource Planning'",
          "Visual improvements 'Resource Planning' page",
          "Invoices page reworked when there no invoices",
          "Visual improvements 'Invoices' page",
          "Fractional numbers are available in the QTY and rate fields in 'Invoices'",
          "Fixed bug when trial didn't start",
          "Added errors when using invalid coupons",
          "Added possibility to unsubscribe from e-mails",
          "Several visual improvements",
        ],
      },
      "1.6.12": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "The possibility of using promotional codes has been added",
        ],
        v_improved: [
          "Visual improvements to the loaded Invoice",
          "Fixed a bug when the invoice was not created due to the time zone",
          "Now plan can be created without name in 'Resource Planning'",
          "Bug fixed, cross-team report showed the wrong time, tracked by the user in 'Reports'",
          "Added the ability to enter fractional numbers in QTY and Rate in 'Invoice'",
          "Visual improvements to filters on the 'Resource Planning'",
          "Fixed several minor visual/performance bugs",
        ],
      },
      "1.6.11": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "Added Cross-team Reports",
        ],
        v_improved: [
          "Fixed sidebar opening bug when switching to Subscriptions page",
          "Visual improvements to the running timer",
          "Fixed online filters in real-time reports 'Reports' page",
          "Shows the number of the week in the year when the show buy week on the 'Resource Planning' page",
          "Fixed header on the 'Resource Planning' page",
          "Fixed a bug where the created plan was not displayed on the 'Resource Planning' page",
          "Added the ability to start the trial period without entering a card",
          "Several visual improvements",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.10": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Fixed visual bugs in invoice creating page",
          "Fixed visual bugs on 'Free Invoice Generator' page",
          "'Grand total' replaced by 'Grand total left', displays the total amount expected to be paid on 'Invoices' page",
          "The unpaid part is displayed for each invoice now",
          "Fixed visual bugs in 'Resource Planning'",
          "Added the ability to show calendar by year in 'Resource Planning'",
          "Visual improvements to filters on the 'Resource Planning' page",
          "Visual improvements to filters on the 'Reports' page",
          "Real-time Reports show tags instead of roles",
          "Fixed translation in 'Subscriptions'",
          "Fixed timer display when sidebar is hidden",
          "Improved emails",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.9": {
        v_new: [
          "New Subscriptions",
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Increased the number of characters displayed in the task name in 'Reports'",
          "Fixed location of 'summary / real-time' buttons in 'Reports'",
          "Added the ability to hide to hide side bar",
          "Now the header in the 'Resource Planning' is hidden when scrolling down",
          "Filters in 'Resource Planning' are moved to separate buttons",
          "Improved display of plan names in 'Resource Planning'",
          "When hovering on the plan in 'Resource Planning', you can see the start date, end date and duration of the plan",
          "Added the ability to hide usernames in 'Resource Planning'",
          "Fixed slider in 'Resource Planning'",
          "The space is automatically removed in the username if it is in front of the name in 'User profile'",
          "User CV is not displayed when editing a user through 'My team'",
          "Fixed bugs when moving and creating plans in 'Resource Planning'",
          "Week numbering bug fixed in 'Resource Planning'",
          "Fixed a bug with the username when adding it to the team in Notifications",
          "Notifications are now displayed from newest to oldest",
          "Fixed several minor visual bugs",
        ],
      },
      "1.5.9": {
        v_new: [
          "Work on bugs/improvements in the Resource Planning section.",
          "Release versions removed from notification bell. Useful app notifications will now be displayed there.",
        ],
        v_improved: [
          "Added an error when dragging a plan to a user who is not in the project, but to whom the plan was transferred in 'Resource Planning'",
          "Fixed pop-up window when hovering over the plan with the cursor. The window is now displayed under the cursor.",
          "Fixed display of users with a long name in the 'Resource Planning' section",
          "Improved readability of texts in the 'Resource Planning' section on user plans",
          "Changed the limit on the number of characters in the username in the 'Profile Settings' of the user. The user can enter 100 characters",
          "Fixed display of username with long name in user plan under Resource Planning",
          "Added sticky header in resource scheduling",
        ],
      },
      "1.5.8": {
        v_new: [
          "Several new messages have been added to the e-mail, which will now be sent and will help the user working with Wobbly.",
          "Added weekly e-mail reports for owner/admin about members' time tracked in favorites projects",
          "Added weekly e-mail reports for members on time tracked",
        ],
        v_improved: [
          "Members now have the ability to switch Billable/Non-billable in Reports for their tracked tasks",
          "Owner and Admin now have the ability to switch Billable/Non-billable in Reports for the tracked tasks of all users",
          "Only Owner and Admin now can edit Member's User profile in Resource planning",
          "Added the ability to select a year when setting the date of birth in Profile settings",
          "Added the ability to delete a plan in the past in Resource planning",
        ],
      },
      "1.5.7": {
        v_new: [
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed error editing User Profile card by Admin and Owner. The edit button was missing for these roles.",
          "Fixed filters in the 'Resource Planning' section. The filter did not display any results.",
          "Fixed display of user statuses in the 'Resource planning' section. The user's online status was displayed if the user's timer was active in another team.",
          "Fixed navigation arrows for switching dates in the Resource Planning section. The arrows moved by dates within two weeks/months.",
          "Improved visual in Resource Planning section.",
          "Improved work of filters in the 'Reports' section, 'Real-time' page.",
          "Fixed a bug in the 'Reports' section, 'Summary' page. A report with numerous projects and users was not displayed.",
          "Improved/simplified the creation of a per-user plan in the Resource Planning section. When creating a plan for a team member, only those projects on which the user is added are displayed.",
          "Fixed the result of displaying users in the 'Reports' section, 'Real-time' page. Users with status 'Recent' was not displayed in the filter if it was with an active timer in another team.",
        ],
      },
      "1.5.6": {
        v_new: [
          "Added the ability to delete a user account.",
          "Slightly redesigned interaction with the 'Reports' section. 'Reports' are divided into 'Summary' and 'Real-time' pages.",
          "Added some new error messages that the user might encounter.",
        ],
        v_improved: [
          "Improved interaction with the calendar when creating/editing a plan in the 'Resource Planning' section.",
          "Fixed login error messages. Some errors were not displayed.",
          "Fixed time division in the 'Timer' section after 00.00. Tasks that were stopped manually after 00.00 were saved in one time period.",
          "Fixed a bug with displaying project colors in the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.4.5": {
        v_new: [
          "Added 'Real-time report' to the 'Reports' section. Now Owner and Admin can view which task the user is currently recording or the last recorded task for five days.",
          "Added several new colors for projects.",
        ],
        v_improved: [
          "Fixed a bug with changing teams. Now, when changing the team on one browser, the user's team will change on the other browser.",
          "Improved the speed of the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.3.4": {
        v_new: ["Bug fixes, visual fixes, application speed improvements."],
        v_improved: [
          "Fixed a few minor visual bugs.",
          "The light and dark switch has been moved to the app logo from the “Profile Settings” section. Also, now visually changed from a switch to a button.",
          "Improved speed of processing requests and responses in the Timer, Reports, and Projects sections.",
          "Improved the visual components of the “Light” and “Dark” themes.",
          "Added the ability to download a 1-minute rounded report in 'Decimal report'.",
          "Fixed “Billable /Non-billable” task merge bug when viewing project/people reports in detail. Now, tasks with the same name on different “Billable/Non-Billable” statuses are not merged.",
        ],
      },
      "1.3.3": {
        v_new: [
          "Added the ability to record the time as Billable/Non billable in the 'Timer' section.",
          "Added Online status for users. In the 'Projects' section, when viewing users on projects, you can see the green status Online which displayed if the user is currently recording a task in the timer.",
          "Added 'Decimal rounding of reports' in the 'Reports' section. Now the user can upload reports in the new format.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed display of usernames in the left menu field.",
          "Fixed editing task time in the 'Timer' section. Tasks from different projects could overlap in time.",
          "Fixed a bug with displaying a client in a project if the client was deleted from the 'Clients' section. The client that was deleted is no longer displayed in the project.",
        ],
      },
      "1.2.2": {
        v_new: [
          "Added a new ability to add projects to favorites, for quick access to projects.",
          "Added display of the total recorded time per month for all teams under the user profile.",
        ],
        v_improved: [
          "User profile. Added a notification if the user missed a required field.",
          "Invoices. Recent invoices now show the company name instead of the customer's name.",
          "The task in the timer can be started without a name.",
          "Added several error messages that users may encounter.",
          "Now users can see other members of the shared project.",
        ],
      },
      "1.1.1": {
        v_new: ["Added new Wobbly light theme in profile settings"],
        v_improved: [
          "Improved search for invoices by: Client name, Company name, Invoice number, Description, Reference. Now the invoice can be found by all these fields",
          "Fixed a bug with changing logo in invoices.",
          "Fixed bug with pulling archived clients when increase/decrease a subscription.",
          "Fixed errors when downloading a detailed/summary report. Reports could come encrypted or be incomplete.",
          "Fixed a bug when combining task names in the Reports section.",
          "Fixed search in the Projects section.",
          "Added several error messages that users may encounter.",
          "Fixed bugs in the Timer section. Editing tasks with overlapping times on different teams.",
          "Fixed errors when purchasing a subscription by a user with the Admin role. The subscription now applies to all team members.",
          "Fixed client removal. When deleting a client, the invoices that were sent to him now do not disappear",
        ],
      },
      "1.0": {
        v_new: ["Added release notes"],
        v_improved: [
          "Fixed display special characters in the project name by code when viewing detailed reports in the 'Reports' section.",
          "We fixed a bug where the partial search in the 'Projects' section did not display all matches.",
          "Fixed monitor 'Total hours by date' in the 'Reports' section. Fixed visual display by dates.",
          "Fixed search by invoice numbers and page transitions. Now the search is not reset when viewing the invoice.",
          "Added the ability to search for an invoice by Description and Reference.",
          "Added error notification when creating/editing a project with the same name.",
          "Fixed a bug related to special characters in the description of tasks in the 'Timer' section.",
          "Fixed a bug with inviting a new user to the team",
          "Added a few new error messages that the user may experience.",
          "Fixed a bug in the Timer section. Recording time in advance and editing time with the intersection of tasks by time.",
        ],
      },
    },
  },
};
