import {
  getPaymentHistoryData,
  getStripeCustomerData,
  getStripePlansData,
  updatePaymentMethodDetails,
} from "../../api/payment";

export const GET_SUBSCRIPTION_DATA_REQUEST = "GET_SUBSCRIPTION_DATA_REQUEST";
export const GET_SUBSCRIPTION_DATA_SUCCESS = "GET_SUBSCRIPTION_DATA_SUCCESS";
export const GET_SUBSCRIPTION_DATA_FAIL = "GET_SUBSCRIPTION_DATA_FAIL";

export const GET_PAYMENT_HISTORY_REQUEST = "GET_PAYMENT_HISTORY_REQUEST";
export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_FAIL = "GET_PAYMENT_HISTORY_FAIL";

export const GET_STRIPE_CUSTOMER_DATA_REQUEST =
  "GET_STRIPE_CUSTOMER_DATA_REQUEST";
export const GET_STRIPE_CUSTOMER_DATA_SUCCESS =
  "GET_STRIPE_CUSTOMER_DATA_SUCCESS";
export const GET_STRIPE_CUSTOMER_DATA_ERROR = "GET_STRIPE_CUSTOMER_DATA_ERROR";

export const CHANGE_SUBSCRIPTION_LOADER = "CHANGE_SUBSCRIPTION_LOADER";
export const CHANGE_UPGRADE_MODAL_STATUS = "CHANGE_UPGRADE_MODAL_STATUS";
export const CHANGE_UPGRADE_MODAL_CONTENT = "CHANGE_UPGRADE_MODAL_CONTENT";
export const CHANGE_PRODUCT_ID = "CHANGE_PRODUCT_ID";
export const CHANGE_PRODUCT_ID_FOR_UPGRADE = "CHANGE_PRODUCT_ID_FOR_UPGRADE";

export const HANDLE_SUBMIT_COUPON = "HANDLE_SUBMIT_COUPON";
export const HANDLE_RESET_COUPON = "HANDLE_RESET_COUPON";

export const submitCoupon = (payload) => {
  return {
    type: HANDLE_SUBMIT_COUPON,
    payload,
  };
};

export const setProductId = (payload) => {
  return {
    type: CHANGE_PRODUCT_ID,
    payload,
  };
};

// setProductIdForUpgrade uses when we have new acount apply coupon for plan a, start trial,
// but then click upgrade btn on plan b and we need it for cheking conditions what to send to back
// and what price will back debit from card

export const setProductIdForUpgrade = (payload) => {
  return {
    type: CHANGE_PRODUCT_ID_FOR_UPGRADE,
    payload,
  };
};

export const resetCoupon = (payload) => {
  return {
    type: HANDLE_RESET_COUPON,
    payload,
  };
};

export const getSubscriptionDataRequest = () => ({
  type: GET_SUBSCRIPTION_DATA_REQUEST,
});

export const getSubscriptionDataSuccess = (payload) => ({
  type: GET_SUBSCRIPTION_DATA_SUCCESS,
  payload,
});

export const getSubscriptionDataError = (payload) => ({
  type: GET_SUBSCRIPTION_DATA_FAIL,
  payload,
});

export const getPaymentHistoryRequest = () => ({
  type: GET_PAYMENT_HISTORY_REQUEST,
});

export const getPaymentHistorySuccess = (payload) => ({
  type: GET_PAYMENT_HISTORY_SUCCESS,
  payload,
});

export const getPaymentHistoryFail = (payload) => ({
  type: GET_PAYMENT_HISTORY_FAIL,
  payload,
});

export const getStripeCustomerDataRequest = () => ({
  type: GET_STRIPE_CUSTOMER_DATA_REQUEST,
});

export const getStripeCustomerDataSuccess = (payload) => ({
  type: GET_STRIPE_CUSTOMER_DATA_SUCCESS,
  payload,
});

export const getStripeCustomerDataError = () => ({
  type: GET_STRIPE_CUSTOMER_DATA_ERROR,
});

export const changeSubscriptionLoader = (payload) => ({
  type: CHANGE_SUBSCRIPTION_LOADER,
  payload,
});

export const changeUpgradeModalStatus = (payload) => ({
  type: CHANGE_UPGRADE_MODAL_STATUS,
  payload,
});

export const changeUpgradeModalContent = (payload) => ({
  type: CHANGE_UPGRADE_MODAL_CONTENT,
  payload,
});

export const changeUpgradeModalStatusAction =
  ({ isOpen, content }) =>
  (dispatch) => {
    if (content) {
      dispatch(changeUpgradeModalContent(content));
    }
    dispatch(changeUpgradeModalStatus(isOpen));
  };

export const getSubscriptionDataAction = () => async (dispatch) => {
  dispatch(getSubscriptionDataRequest());
  try {
    const { data: plansData } = await getStripePlansData();
    dispatch(getSubscriptionDataSuccess({ plansData }));
  } catch (error) {
    console.log(error);
    dispatch(getSubscriptionDataError(error));
  }
};

export const getCustomerDataAction = () => async (dispatch) => {
  dispatch(getStripeCustomerDataRequest());
  try {
    const { data: customerData } = await getStripeCustomerData();

    dispatch(getStripeCustomerDataSuccess(customerData));
  } catch (error) {
    console.log("Error", error);
    dispatch(getStripeCustomerDataError());
  }
};

export const getPaymentHistoryAction =
  ({
    fromDate = null,
    toDate = null,
    limit = null,
    lastElementId = null,
  } = {}) =>
  async (dispatch) => {
    const data = {
      "created[gt]": fromDate,
      "created[lt]": toDate,
      starting_after: lastElementId,
      limit,
    };

    dispatch(getPaymentHistoryRequest());
    try {
      const { data: paymentHistory } = await getPaymentHistoryData(data);

      dispatch(
        getPaymentHistorySuccess({
          history: paymentHistory.data,
          hasMoreHistory: paymentHistory.has_more,
        }),
      );
    } catch (error) {
      console.log(error);
      dispatch(getPaymentHistoryFail(error));
    }
  };

export const updatePaymentMethodAction =
  (formValues, successCallback) => async (dispatch, getState) => {
    const paymentMethodId =
      getState().subscriptionPageReducer.customerData.default_payment_method.id;

    try {
      dispatch(getStripeCustomerDataRequest());
      await updatePaymentMethodDetails({
        paymentMethodId,
        paymentDetails: formValues,
      });
      const { data: customerData } = await getStripeCustomerData();

      dispatch(getStripeCustomerDataSuccess(customerData));
      successCallback();
    } catch (error) {
      console.log(error);
      dispatch(getStripeCustomerDataError());
    }
  };
