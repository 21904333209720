import { instance } from "../configAPI";

export const searchTechnologies = (title: any) =>
  instance({
    url: `/technology/list?title=${title}`,
    method: "GET",
  });

export const addTechnology = (title: any) =>
  instance({
    url: "/technology/add",
    method: "POST",
    data: {
      title,
    },
  });
