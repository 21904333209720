import moment from "moment";
import { getTimeDurationByGivenTimestamp } from "../../services/timeService";
import {
  SET_CURRENT_TIMER,
  SET_SERVER_CLIENT_TIMEDIFF,
  GET_TIME_ENTRIES_LIST,
  SET_TIMER_TICK,
  INC_PAGINATION,
  GET_TIME_ENTRIES_LIST_PAGINATION,
  DISABLE_PAGINATION,
  RESTORE_PAGINATION,
  START_SEARCH_MODE,
  END_SEARCH_MODE,
  SYNC_ALL_TASKS_REQUEST,
  SYNC_ALL_TASKS_SUCCESS,
  SYNC_ALL_TASKS_ERROR,
  GET_TIME_COUNT_REQUEST,
  SET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
} from "../actions/MainPageAction";

const initialState = {
  dataDoughnutChart: {
    labels: [],
    options: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return getTimeDurationByGivenTimestamp(
              Number(moment(tooltipItem.yLabel)),
            );
          },
        },
      },
    },
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#E57373",
          "#F06292",
          "#BA68C8",
          "#9575CD",
          "#7986CB",
          "#64B5F6",
          "#4FC3F7",
          "#4DD0E1",
          "#4DB6AC",
          "#81C784",
          "#AED581",
          "#DCE775",
          "#FFF176",
          "#FFD54F",
          "#FFB74D",
          "#FF8A65",
          "#A1887F",
          "#E0E0E0",
          "#90A4AE",
          "#E53935",
          "#D81B60",
          "#8E24AA",
          "#5E35B1",
          "#3949AB",
          "#1E88E5",
          "#039BE5",
          "#00ACC1",
          "#00897B",
          "#43A047",
          "#7CB342",
          "#C0CA33",
          "#FDD835",
          "#FFB300",
          "#FB8C00",
          "#F4511E",
          "#6D4C41",
          "#757575",
          "#546E7A",
          "#B71C1C",
          "#880E4F",
          "#4A148C",
          "#311B92",
          "#1A237E",
          "#0D47A1",
          "#01579B",
          "#006064",
          "#004D40",
          "#1B5E20",
          "#33691E",
          "#827717",
          "#F57F17",
          "#FF6F00",
          "#E65100",
          "#BF360C",
          "#3E2723",
          "#212121",
          "#263238",
        ],
      },
    ],
  },
  timeEntriesList: [],
  isFetchingTimeEntriesList: false,
  currentTimer: null,
  timerTick: null,
  serverClientTimediff: 0,
  pagination: {
    page: 1,
    limit: 50,
    disabled: false,
  },
  isSearchMode: false,
  searchValue: "",
  searchDateRange: {
    startDateTime: moment().startOf("month").utc().toISOString(),
    endDateTime: moment().endOf("month").utc().toISOString(),
  },
  isFetchingSearch: false,
  isSyncingTasks: false,
  time: 0,
  statusFilter: "All",
  isFetching: false,
  notificationsList: [],
  allNotificationsList: [],
};

export function mainPageReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TIME_ENTRIES_LIST:
      return { ...state, timeEntriesList: payload, isFetchingSearch: false };

    case "CHANGE_ARR":
      return { ...state, timeEntriesList: payload.timeEntriesList };

    case SET_CURRENT_TIMER:
      return { ...state, currentTimer: payload };

    case SET_SERVER_CLIENT_TIMEDIFF:
      return { ...state, serverClientTimediff: payload };

    case SET_TIMER_TICK:
      return { ...state, timerTick: payload };

    case INC_PAGINATION:
      return {
        ...state,
        isFetchingTimeEntriesList: true,
        pagination: {
          ...state.pagination,
          page: state.pagination.page + 1,
        },
      };

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notificationsList: [...state.notificationsList, payload],
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        allNotificationsList: payload,
      };

    case RESTORE_PAGINATION:
      return {
        ...state,
        isFetchingTimeEntriesList: false,
        pagination: {
          ...initialState.pagination,
        },
      };

    case DISABLE_PAGINATION:
      return {
        ...state,
        isFetchingTimeEntriesList: false,
        pagination: {
          ...state.pagination,
          disabled: true,
          page: --state.pagination.page,
        },
      };

    case GET_TIME_ENTRIES_LIST_PAGINATION:
      return {
        ...state,
        isFetchingTimeEntriesList: false,
        timeEntriesList: state.timeEntriesList.concat(payload),
      };

    case START_SEARCH_MODE:
      return {
        ...state,
        pagination: {
          ...initialState.pagination,
        },
        isSearchMode: true,
        searchValue: payload.searchValue,
        searchDateRange: payload.searchDateRange,
        isFetchingSearch: true,
      };

    case END_SEARCH_MODE:
      return {
        ...state,
        pagination: {
          ...initialState.pagination,
        },
        isSearchMode: false,
        searchValue: "",
        isFetchingSearch: false,
      };

    case SYNC_ALL_TASKS_REQUEST:
      return {
        ...state,
        isSyncingTasks: true,
      };

    case SYNC_ALL_TASKS_SUCCESS:
      return {
        ...state,
        isSyncingTasks: false,
      };

    case SYNC_ALL_TASKS_ERROR:
      return {
        ...state,
        isSyncingTasks: false,
      };

    case "RESET_ALL":
      return initialState;

    case GET_TIME_COUNT_REQUEST:
      return {
        ...state,
        time: payload,
      };

    default:
      return state;
  }
}
