import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";

import classNames from "classnames";

// Components
import { Loading } from "../../components/Unstructured/Loading";
import ClientModal from "../../components/Unstructured/ClientModal";
import { BlankListComponent } from "../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import { ClientComponent } from "../../components/Unstructured/ClientComponent/index";
import PageHeader from "../../components/Unstructured/PageHeader/index";
import FilterByStatus from "../../components/Unstructured/FilterByStatus";
import Spinner from "../../components/Unstructured/Spinner";
// Actions
import {
  getClientsAction,
  addClientAction,
  editClientThunk,
  deleteClientThunk,
  changeClientActiveStatusAction,
  resetClientsParamsAction,
} from "../../redux/actions/ClientsActions";
import { showNotificationAction } from "../../redux/actions/NotificationActions";

// Services
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole,
} from "../../services/authentication";

// Styles
import "./style.scss";
import ModalPortal from "../../components/Unstructured/ModalPortal";
import { SUBSCRIPTIONS } from "../../constants/constants";
import { changeUpgradeModalStatusAction } from "../../redux/actions/SubscriptionPageAction";
import Scrollbars from "react-custom-scrollbars";

class ClientsPage extends Component {
  state = {
    showModal: false,
    editedClient: null,
    stateSearchValue: "",
    clientsList: [],
    isOpenDropdown: false,
    isInitialFetching: true,

    disabled: false,
  };

  checkedDisable = () => {
    this.setState({ disabled: false });
  };

  componentDidMount() {
    this.props.getClientsAction({
      isPaginate: true,
      page: 1,
    });
  }

  componentWillUnmount() {
    this.props.resetClientsParamsAction();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.stateSearchValue !== this.state.stateSearchValue) {
      this.setState({ disabled: true });
    }
    if (
      prevState.stateSearchValue !== this.state.stateSearchValue &&
      this.state.stateSearchValue === ""
    ) {
      this.setState({ disabled: false });
    }

    const { currentTeam, history, clientsList } = this.props;

    if (!prevProps.currentTeam.data.id && this.props.currentTeam.data.id) {
      if (
        !checkIsAdminByRole(currentTeam.data.role) &&
        !checkIsOwnerByRole(currentTeam.data.role) &&
        !checkIsHRManagerByRole(currentTeam.data.role) &&
        !checkIsProjectManagerByRole(currentTeam.data.role)
      ) {
        history.push("/timer");
      }
    }
    if (prevProps.clientsList !== clientsList) {
      this.setState({ clientsList, isInitialFetching: false });
    }
  }

  closeModal = () => {
    this.setState({ showModal: false, editedClient: null });
  };

  editClient = (client, id, clientAvatar) => {
    const { editClientThunk } = this.props;

    const phone = client.phone
      ? `+${client.phone.replace(/[^0-9]/g, "")}`
      : null;
    const data = { ...client, phone: phone };

    editClientThunk(data, id, clientAvatar);
    this.closeModal();
  };

  deleteClient = (id) => {
    this.props.deleteClientThunk(id);
    this.closeModal();
  };

  searchClient = (searchValue) => {
    this.props.getClientsAction({
      isPaginate: true,
      page: 1,
      searchValue,
    });
  };

  addNewClient = (client, clientAvatar) => {
    const { showNotificationAction, vocabulary } = this.props;
    const { v_a_client_name_empty_error, client_was_created } = vocabulary;

    if (client.length === 0) {
      showNotificationAction({
        text: v_a_client_name_empty_error,
        type: "warning",
      });
      return;
    } else {
      const phone = client.phone
        ? `+${client.phone.replace(/[^0-9]/g, "")}`
        : null;
      const data = { ...client, phone: phone };

      this.props.addClientAction(data, clientAvatar);
      showNotificationAction({
        text: client_was_created,
        type: "success",
      });
      this.closeModal();
    }
  };

  onSearchValueChange = (e) => {
    this.setState({ stateSearchValue: e.target.value });
    this.searchClient(e.target.value);
  };

  setFilterStatus = (status) => {
    this.props.getClientsAction({
      page: 1,
      isPaginate: true,
      filterStatus: status,
    });
  };

  changeClientActiveStatus = async (clientId, isActive) => {
    await this.props.changeClientActiveStatusAction(clientId, isActive);
    this.props.getClientsAction({
      isPaginate: true,
      page: 1,
    });
  };

  resetFilters = () => {
    this.setState({ stateSearchValue: "" });
    this.props.getClientsAction({
      searchValue: "",
      filterStatus: "all",
      isPaginate: true,
      page: 1,
    });
    this.setState({ disabled: false });
  };

  findUserPlane = (planeId, planes) =>
    planes.find((item) => planeId === item.id);

  checkOnClients = () => {
    const {
      v_upgrade_clients_title,
      v_upgrade_clients_description,
      v_upgrade_admin_note,
    } = this.props.vocabulary;
    const isAdmin = checkIsAdminByRole(this.props.currentTeam.data.role);

    if (
      this.props.currentTeam.data.userPlan.maxClients &&
      this.props.totalClients >= this.props.currentTeam.data.userPlan.maxClients
    ) {
      this.props.changeUpgradeModalStatusAction({
        isOpen: true,
        content: {
          title: v_upgrade_clients_title,
          description: `${v_upgrade_clients_description} ${
            isAdmin ? v_upgrade_admin_note : ""
          }`,
        },
      });
      return;
    }

    this.setState({ showModal: true });
  };

  handleScrollFrame = (values) => {
    const { pagination, getClientsAction } = this.props;
    const { top } = values;

    if (top > 0.7 && !pagination.listFetching && !pagination.isListEnded) {
      getClientsAction({
        isPaginate: true,
        page: pagination.page + 1,
      });
    }
  };

  render() {
    const {
      showModal,
      stateSearchValue,
      editedClient,
      clientsList,
      isInitialFetching,
    } = this.state;
    const {
      vocabulary,
      isMobile,
      currentTeam,
      clientsFetching,
      filterStatus,
      pagination,
    } = this.props;
    const {
      v_clients,
      v_add_new_client,
      v_apply,
      v_clear_filters,
      v_filter_all_clients,
      v_filter_active,
      v_filter_archived,
      v_search_client,
    } = vocabulary;

    const editClient = (index) => {
      this.setState({ editedClient: clientsList[index], showModal: true });
    };

    return (
      <Loading
        flag={isInitialFetching || currentTeam.isFetching}
        mode="parentSize"
        withLogo={false}
      >
        <Loading flag={clientsFetching} mode="overlay" withLogo={false}>
          <div
            className={classNames("wrapper_clients_page", {
              "wrapper_clients_page--mobile": isMobile,
            })}
          >
            {showModal && (
              <ClientModal
                open={true}
                client={editedClient}
                onModalClose={this.closeModal}
                onAddClient={this.addNewClient}
                onEditClient={this.editClient}
                onDeleteClient={this.deleteClient}
              />
            )}
            <div className="data_container_clients_page">
              <div className="data_container_clients_page_top">
                <PageHeader title={v_clients}>
                  <button
                    className="header-wrapper__child-button"
                    onClick={this.checkOnClients}
                  >
                    {v_add_new_client}
                  </button>
                </PageHeader>
                <div className="clients_search_bar__container">
                  <div className="wrapper_clients_search_bar">
                    <div className="clients_search_bar_search_field_container">
                      <i
                        className={cn("magnifer", {
                          magnifer__light:
                            localStorage.getItem("themes") === "light",
                        })}
                      />
                      <input
                        placeholder={v_search_client}
                        onChange={this.onSearchValueChange}
                        type="text"
                        value={stateSearchValue}
                        onKeyUp={(e) =>
                          e.keyCode === 13
                            ? this.searchClient(stateSearchValue)
                            : null
                        }
                        className="clients_search_bar_search_field"
                      />
                    </div>
                    {/* WOB-3462 -Remove the Apply button
                    <div className="clients_search_bar_button_container">
                      <button
                        className="clients_search_bar_button"
                        // disabled={!stateSearchValue.length}
                        disabled={!this.state.disabled}
                        onClick={() => {
                          this.searchClient(stateSearchValue);
                          this.checkedDisable();
                        }}
                      >
                        {v_apply}
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="reset-clients">
                  <div
                    className="reset-clients__button"
                    onClick={() => {
                      this.resetFilters();
                      // this.checkedDisable();
                    }}
                  >
                    {v_clear_filters}
                  </div>
                </div>
              </div>
              <FilterByStatus
                status={filterStatus}
                onClick={this.setFilterStatus}
                items={[
                  {
                    id: "all",
                    text: v_filter_all_clients,
                  },
                  {
                    id: "active",
                    text: v_filter_active,
                  },
                  {
                    id: "archived",
                    text: v_filter_archived,
                  },
                ]}
              />
              <div className="clients_list_container">
                <div className={classNames("clients_list")}>
                  {clientsList && clientsList.length === 0 && (
                    <BlankListComponent
                      subtext={this.props.vocabulary.v_no_results}
                      // position={{ bottom: "-20%" }}
                    />
                  )}
                  {Boolean(clientsList.length) && (
                    <Scrollbars
                      autoHide
                      hideTracksWhenNotNeeded
                      onScrollFrame={this.handleScrollFrame}
                    >
                      {clientsList.map((item, index) => (
                        <ClientComponent
                          client={item}
                          vocabulary={vocabulary}
                          index={index}
                          editClient={editClient}
                          key={index}
                          isMobile={isMobile}
                          changeClientActiveStatus={
                            this.changeClientActiveStatus
                          }
                        />
                      ))}
                      {pagination.listFetching && (
                        <div
                          style={{
                            position: "relative",
                            paddingTop: "10px",
                          }}
                        >
                          <Spinner mode="inline" withLogo={false} />
                        </div>
                      )}
                    </Scrollbars>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Loading>
      </Loading>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  currentTeam: state.teamReducer.currentTeam,
  isMobile: state.responsiveReducer.isMobile,
  clientsList: state.clientsReducer.clientsList,
  searchValue: state.clientsReducer.searchValue,
  filterStatus: state.clientsReducer.filterStatus,
  clientsFetching: state.clientsReducer.isFetching,
  pagination: state.clientsReducer.pagination,
  totalClients: state.clientsReducer.total,
});

const mapDispatchToProps = {
  getClientsAction,
  addClientAction,
  editClientThunk,
  deleteClientThunk,
  showNotificationAction,
  changeClientActiveStatusAction,
  resetClientsParamsAction,
  changeUpgradeModalStatusAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsPage),
);
