import { instance } from "../configAPI";

export const getClientsList = async (params = {}) =>
  instance({
    url: "/client/list",
    method: "GET",
    params,
  });

export const addClient = (data: any) =>
  instance({
    url: "client/add",
    method: "POST",
    data,
  });

export const editClient = (data: any, id: any) =>
  instance({
    url: `client/${id}`,
    method: "PATCH",
    data,
  });

export const deleteClient = (id: any) =>
  instance({
    url: `client/${id}`,
    method: "DELETE",
  });

export const changeClientActiveStatus = (clientId: any, status: any) =>
  instance({
    url: `client/${clientId}/active-status`,
    method: "PATCH",
    data: {
      isActive: status,
    },
  });
