import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";
import cn from "classnames";
import ReactDOMServer from "react-dom/server";
import CustomTippy from "../../components/Unstructured/Tooltip/CustomTippy";
import { followCursor } from "tippy.js";

// dependencies
import classNames from "classnames";

// Services
import {
  getTimeDurationByGivenTimestamp,
  convertDateToISOString,
  convertDateToShiftedISOString,
} from "../../services/timeService";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
} from "../../services/authentication";
import { decodeTimeEntryIssue } from "../../services/timeEntryService";
import { getParametersString } from "../../services/apiService";
import { apiCall } from "../../services/apiService";

// Components
import ReportsByProjectSearchBar from "../../components/Unstructured/ReportsByProjectSearchBar";
import { ThemeProvider } from "@material-ui/styles";
import { Checkbox, FormControlLabel, createMuiTheme } from "@material-ui/core";

// Actions
import { reportsBillableStatusAction } from "../../redux/actions/MainPageAction";
// Queries

// Config
import { AppConfig } from "../../config";

// Styles
import "./style.scss";
import CustomScrollbar from "../../components/Unstructured/CustomScrollbar";
import { BlankListComponent } from "../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import reportsPageAction from "../../redux/actions/ReportsPageAction";

const checkboxTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
        color: "var(--text)",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "5px",
        "&&checked": "#fff",
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: "#fff",
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "16px",
      },
    },
  },
});

class ReportsByProjectsPage extends Component {
  state = {
    dataOfProject: [],
    totalTime: 0,
    countTasks: 0,
    userEmailsList: [],
    projectName: "",
    teamId: "",
    dateStart: "",
    endDate: "",
    reportUsers: [],
    isCombine: false,
    stateSearchValue: "",
  };

  componentDidMount() {
    let { userEmails } = this.props.match.params;
    const { projectName, dateStart, endDate, teamId } = this.props.match.params;

    userEmails = userEmails.indexOf("all") > -1 ? "" : userEmails;
    const userEmailsList = userEmails.length ? userEmails.split(",") : [];

    this.setState(
      { userEmailsList, projectName, dateStart, endDate, teamId },
      () => {
        this.applySearch(userEmailsList, "");
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.stateSearchValue !== this.state.stateSearchValue) {
      if (this.state.stateSearchValue === "") {
        // this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: false });
      } else if (
        this.state.stateSearchValue !== "" ||
        prevState.reportUsers !== this.state.reportUsers
      ) {
        this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: true });
      }
    }
  }

  getDateInPointsFormat(momentDate) {
    return momentDate.split("-").reverse().join(".");
  }

  getSlash(item) {
    return item || "-";
  }

  getTotalTime(arr) {
    let totalTime = 0;

    for (let i = 0; i < arr.length; i++) {
      totalTime += arr[i].durationTimestamp;
    }

    return totalTime;
  }

  onCombineChange = (event) => {
    this.setState({ isCombine: event.target.checked });
  };

  applySearch = (userEmailsList = [], searchValue = "") => {
    const { projectName, dateStart, endDate, isCombine, teamId } = this.state;

    const data = {
      projectName: decodeURIComponent(projectName) || "",
      startDate: convertDateToISOString(dateStart),
      endDate: convertDateToShiftedISOString(endDate, 24 * 60 * 60 * 1000),
      teamId: this.props.isCrossTeam ? teamId : this.props.currentTeam.data.id,
    };

    if (userEmailsList?.length) {
      data.userEmails = userEmailsList;
    }
    if (searchValue?.length) {
      data.searchValue = searchValue;
    } else if (!searchValue) {
      data.searchValue = "";
    }
    if (isCombine) {
      data.combined = isCombine;
    } else if (!isCombine) {
      data.combined = "";
    }

    if (this.props.location?.prevPageReport) {
      if (data.userEmails?.length === 1) {
        apiCall(
          `${AppConfig.apiURL}project/detailed/report?startDate=${data.startDate}&endDate=${data.endDate}&userEmail=${data.userEmails}&projectName=${data.projectName}&searchValue=${data.searchValue}&combined=${data.combined}&teamId=${teamId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
        ).then(
          (result) => {
            const data = result.data;
            const timer = data.project_v2.length
              ? data.project_v2[0].timer
              : [];
            const uniqueUsersArray = timer
              .filter((item, index) => {
                const _item = item.user.id;

                return (
                  index === timer.findIndex((obj) => obj.user.id === _item)
                );
              })
              .map((item) => item.user);

            this.setState({
              dataOfProject: this.props.selectNoneActive ? timer : [],
              totalTime: this.getTotalTime(timer),
              countTasks: timer.length,
              reportUsers: uniqueUsersArray,
            });
            if (this.state.isCombine) {
              this.props.reportsPageAction("SET_COMBINED_OPTION", true);
            } else {
              this.props.reportsPageAction("SET_COMBINED_OPTION", false);
            }
          },
          (err) => {
            if (err instanceof Response) {
              err.text().then((errorMessage) => console.log(errorMessage));
            } else {
              console.log(err);
            }
          },
        );
      } else {
        apiCall(`${AppConfig.apiURL}project/reports-project`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then(
          (result) => {
            const data = result.data;
            const timer = data.project_v2.length
              ? data.project_v2[0].timer
              : [];
            const uniqueUsersArray = timer
              .filter((item, index) => {
                const _item = item.user.id;

                return (
                  index === timer.findIndex((obj) => obj.user.id === _item)
                );
              })
              .map((item) => item.user);

            this.setState({
              dataOfProject: this.props.selectNoneActive ? timer : [],
              totalTime: this.getTotalTime(timer),
              countTasks: timer.length,
              reportUsers: uniqueUsersArray,
            });
            if (this.state.isCombine) {
              this.props.reportsPageAction("SET_COMBINED_OPTION", true);
            } else {
              this.props.reportsPageAction("SET_COMBINED_OPTION", false);
            }
          },
          (err) => {
            if (err instanceof Response) {
              err.text().then((errorMessage) => console.log(errorMessage));
            } else {
              console.log(err);
            }
          },
        );
      }
    } else if (this.props.location?.prevPageProgect) {
      apiCall(`${AppConfig.apiURL}project/reports-project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(
        (result) => {
          const data = result.data;
          const timer = data.project_v2.length ? data.project_v2[0].timer : [];
          const uniqueUsersArray = timer
            .filter((item, index) => {
              const _item = item.user.id;

              return index === timer.findIndex((obj) => obj.user.id === _item);
            })
            .map((item) => item.user);

          this.setState({
            dataOfProject: this.props.selectNoneActive ? timer : [],
            totalTime: this.getTotalTime(timer),
            countTasks: timer.length,
            reportUsers: uniqueUsersArray,
          });
          if (this.state.isCombine) {
            this.props.reportsPageAction("SET_COMBINED_OPTION", true);
          } else {
            this.props.reportsPageAction("SET_COMBINED_OPTION", false);
          }
        },
        (err) => {
          if (err instanceof Response) {
            err.text().then((errorMessage) => console.log(errorMessage));
          } else {
            console.log(err);
          }
        },
      );
    } else {
      apiCall(
        `${AppConfig.apiURL}project/detailed/report?startDate=${data.startDate}&endDate=${data.endDate}&userEmail=${data.userEmails}&projectName=${data.projectName}&searchValue=${data.searchValue}&combined=${data.combined}&teamId=${teamId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      ).then(
        (result) => {
          const data = result.data;
          const timer = data.project_v2.length ? data.project_v2[0].timer : [];
          const uniqueUsersArray = timer
            .filter((item, index) => {
              const _item = item.user.id;

              return index === timer.findIndex((obj) => obj.user.id === _item);
            })
            .map((item) => item.user);

          this.setState({
            dataOfProject: this.props.selectNoneActive ? timer : [],
            totalTime: this.getTotalTime(timer),
            countTasks: timer.length,
            reportUsers: uniqueUsersArray,
          });
          if (this.state.isCombine) {
            this.props.reportsPageAction("SET_COMBINED_OPTION", true);
          } else {
            this.props.reportsPageAction("SET_COMBINED_OPTION", false);
          }
        },
        (err) => {
          if (err instanceof Response) {
            err.text().then((errorMessage) => console.log(errorMessage));
          } else {
            console.log(err);
          }
        },
      );
    }
  };

  changeReportsBillableStatus = async (
    userId,
    billable,
    startDatetime,
    endDatetime,
  ) => {
    const { projectName, dateStart, endDate, userEmailsList, teamId } =
      this.state;

    await this.props.reportsBillableStatusAction({
      userId,
      billable,
      startDatetime,
      endDatetime,
    });
    const data = {
      projectName: decodeURIComponent(projectName) || "",
      startDate: convertDateToISOString(dateStart),
      endDate: convertDateToShiftedISOString(endDate, 24 * 60 * 60 * 1000),
      teamId: this.props.isCrossTeam ? teamId : this.props.currentTeam.data.id,
    };

    if (userEmailsList.length) {
      data.userEmails = userEmailsList;
    }

    apiCall(`${AppConfig.apiURL}project/reports-project`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(
      (result) => {
        const data = result.data;
        const timer = data.project_v2.length ? data.project_v2[0].timer : [];
        const uniqueUsersArray = timer
          .filter((item, index) => {
            const _item = item.user.id;

            return index === timer.findIndex((obj) => obj.user.id === _item);
          })
          .map((item) => item.user);

        this.setState({
          dataOfProject: timer,
          totalTime: this.getTotalTime(timer),
          countTasks: timer.length,
          reportUsers: uniqueUsersArray,
        });
        if (this.state.isCombine) {
          this.props.reportsPageAction("SET_COMBINED_OPTION", true);
        } else {
          this.props.reportsPageAction("SET_COMBINED_OPTION", false);
        }
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  };

  resetFilters = () => {
    let { userEmails } = this.props.match.params;
    const { projectName, dateStart, endDate, teamId } = this.props.match.params;

    userEmails = userEmails.indexOf("all") > -1 ? "" : userEmails;
    const userEmailsList = userEmails.length ? userEmails.split(",") : [];

    this.setState(
      { userEmailsList, projectName, dateStart, endDate, teamId },
      () => {
        this.applySearch(userEmailsList, "");
      },
    );
    this.applySearch(userEmailsList, "");
    this.props.reportsPageAction("SET_IS_DISABLED_FILTER", { data: false });
    this.setState({
      stateSearchValue: "",
    });
  };

  render() {
    const { isMobile, dateFormat, durationTimeFormat, vocabulary } = this.props;
    const {
      v_issue,
      v_user_name,
      v_time,
      v_sum_tasks,
      v_sum_time,
      v_combine,
      v_back,
      v_clear_filters,
    } = vocabulary;

    const projectsItems = this.state.dataOfProject.map((item, index) => (
      <div
        className="tasks_data"
        key={`projects_container_project_data${index}`}
      >
        <CustomTippy
          content={this.getSlash(item.issue)}
          plugins={[followCursor]}
          followCursor={true}
          arrow={false}
        >
          <div className="name">
            <div className="name_popup">{this.getSlash(item.issue)}</div>
          </div>
        </CustomTippy>
        <span className="billable">
          <span
            className={classNames(
              !item.billable
                ? "billable__inactive-billable"
                : item.billable
                ? "billable__active-billable"
                : null,
            )}
          >
            $
          </span>
        </span>
        <div className="username">{item.user.username}</div>
        <div className="time">
          {!this.props.isCombineTasks
            ? `${moment(item.startDatetime).format(dateFormat)} | `
            : ""}
          {getTimeDurationByGivenTimestamp(
            item.durationTimestamp,
            durationTimeFormat,
          )}
        </div>
      </div>
    ));
    const projectsItemsMobile = this.state.dataOfProject.map((item, index) => (
      <div
        className="tasks_data--mobile"
        key={`projects_container_project_data${index}`}
      >
        <div className="reports-by-project-list">
          <span className="project-list-title">{v_issue}:</span>
          <span>{this.getSlash(item.issue)}</span>
        </div>
        <div className="reports-by-project-list">
          <span className="project-list-title">{v_user_name}:</span>
          <span>{item.user.username}</span>
        </div>
        <div className="reports-by-project-list">
          <span className="project-list-title">{v_time}:</span>
          <span>
            {!this.props.isCombineTasks
              ? `${moment(item.startDatetime).format(dateFormat)} | `
              : ""}
            {getTimeDurationByGivenTimestamp(
              item.durationTimestamp,
              durationTimeFormat,
            )}
          </span>
        </div>
      </div>
    ));

    const propsSearchDate = this.props?.timeRange;
    const prevPageReport = this.props.location?.prevPageReport;
    const prevPageProgect = this.props.location?.prevPageProgect;

    return (
      <div
        className={classNames("reports_by_projects_wrapper", {
          "reports_by_projects_wrapper--mobile": isMobile,
        })}
      >
        <div className="header">
          {prevPageReport && (
            <div>
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                to={{
                  pathname: "/reports/summary",
                  propsSearchDate: prevPageReport,
                }}
              >
                <div
                  className={cn("heade_link", {
                    heade_link__light:
                      localStorage.getItem("themes") === "light",
                  })}
                ></div>
                <div className="heade_link_title">{v_back}</div>
              </Link>
            </div>
          )}

          {prevPageProgect && (
            <div>
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                to={{
                  pathname: "/projects",
                  propsSearchDate: prevPageProgect,
                }}
              >
                <div
                  className={cn("heade_link", {
                    heade_link__light:
                      localStorage.getItem("themes") === "light",
                  })}
                ></div>
                <div className="heade_link_title">{v_back}</div>
              </Link>
            </div>
          )}

          <div className="header_name">
            <span>
              {decodeTimeEntryIssue(this.props.match.params.projectName)}
              :&nbsp;
            </span>
            <span>
              {moment(this.props.match.params.dateStart).format(dateFormat)}
              {" - "}{" "}
              {moment(this.props.match.params.endDate).format(dateFormat)}
            </span>
          </div>
          <div className="header_name header_name--task">
            <div className="tasks_sum">
              {v_sum_tasks}: {this.state.countTasks}
            </div>
            <ThemeProvider theme={checkboxTheme}>
              <FormControlLabel
                label={v_combine}
                control={
                  <Checkbox
                    disableRipple
                    color={"primary"}
                    checked={this.state.isCombine}
                    onChange={this.onCombineChange}
                  />
                }
              />
            </ThemeProvider>
          </div>
          <div className="header_name header_name--time">
            {v_sum_time}:{" "}
            {getTimeDurationByGivenTimestamp(
              this.state.totalTime,
              durationTimeFormat,
            )}
          </div>
        </div>
        <ReportsByProjectSearchBar
          reportUsers={this.state.reportUsers}
          applySearch={this.applySearch}
          userEmailsList={this.state.userEmailsList}
          checked={this.state.isCombine}
          searchValue={this.state.stateSearchValue}
          reportsPageAction={this.props.reportsPageAction}
          searchValueHandler={(e) =>
            this.setState({ stateSearchValue: e.target.value })
          }
        />
        {this.state.dataOfProject.length > 0 ? (
          !isMobile ? (
            <div className="tasks_wrapper">
              <div className="tasks_header">
                <div className="name">{v_issue}</div>
                <div className="username">{v_user_name}</div>
                <div className="time">{v_time}</div>
              </div>
              <CustomScrollbar>
                <div className="tasks_data_container">{projectsItems}</div>
              </CustomScrollbar>
            </div>
          ) : (
            <div className="tasks_data_wrap">
              <CustomScrollbar>
                <div className="tasks_data_container--mobile">
                  {projectsItemsMobile}
                </div>
              </CustomScrollbar>
            </div>
          )
        ) : (
          <BlankListComponent
            subtext={this.props.vocabulary.v_no_results}
            // position={{ bottom: "-20%" }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isCombineTasks: store.reportsPageReducer.isCombineTasks,
  isMobile: store.responsiveReducer.isMobile,
  dateFormat: store.userReducer.dateFormat,
  durationTimeFormat: store.userReducer.durationTimeFormat,
  vocabulary: store.languageReducer.vocabulary,
  timeRange: store.reportsPageReducer.timeRange,
  currentTeam: store.teamReducer.currentTeam,
  isCrossTeam: store.reportsPageReducer.isCrossTeam,
  isDisabledFilter: store.reportsPageReducer.isDisabledFilter,
  selectNoneActive: store.reportsPageReducer.selectNoneActive,
});

const mapDispatchToProps = (dispatch) => ({
  reportsPageAction: (type, status) =>
    dispatch(reportsPageAction(type, status)),
  reportsBillableStatusAction: (data) =>
    dispatch(reportsBillableStatusAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsByProjectsPage),
);
