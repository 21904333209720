import React from "react";
import "./style.scss";
import { NoInvoices } from "../../Atoms/SvgIcons";
import { Link } from "react-router-dom";

export const NoActiveInvoices = ({ title, text, linkText }) => (
  <div className="no_invoices_wrapper">
    <div className="no_invoices_wrapper__header">
      <NoInvoices color="white" />
      <span>{title}</span>
    </div>
    <div className="no_invoices_wrapper__body">
      <div className="no_invoices_wrapper__body-text">
        <span>{text}</span>
      </div>
      <Link to="/invoices/create" className="no_invoices_wrapper__body-button">
        {linkText}
      </Link>
    </div>
  </div>
);
