import React, { useState } from "react";
import { connect } from "react-redux";
import { RecruitmentHeaderDropdown } from "../RecruitmentHeaderDropdown";

import {
  TableHeaderContainer,
  TableHeaderItem,
  TableHeaderTitle,
  ArrowsContainer,
  ArrowUpIcon,
  ArrowDownIcon,
  EditButtonTitle,
} from "./My.styled";

const RecruitmentTableHeader = ({ tableHeaderDataList, handlePriority = () => {}, period }) => {
  const [headerDataList, setHeaderDataList] = useState(tableHeaderDataList);

  return (
    <TableHeaderContainer>
      <tr>
        {headerDataList.map(
          (
            {
              title,
              isSelector,
              isFilter,
              selector,
              editButton,
              id,
              onFilterHendler = () => {},
            },
            index,
          ) => (
            <TableHeaderItem key={index + title}>
              {editButton && <EditButtonTitle>{""}</EditButtonTitle>}
              {!isSelector && !isFilter && (
                <TableHeaderTitle>{title}</TableHeaderTitle>
              )}
              {isSelector && (
                <RecruitmentHeaderDropdown
                  onFilterHendler={onFilterHendler}
                  title={title}
                  id={id}
                  selectors={selector}
                  period={period}
                  handlePriority={handlePriority}
                />
              )}
              {isFilter && (
                <TableHeaderTitle>
                  {title}
                  <ArrowsContainer>
                    <ArrowUpIcon />
                    <ArrowDownIcon />
                  </ArrowsContainer>
                </TableHeaderTitle>
              )}
            </TableHeaderItem>
          ),
        )}
      </tr>
    </TableHeaderContainer>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecruitmentTableHeader);
