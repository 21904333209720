import React, { useState, useRef } from "react";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";

import ReactFlagsSelect from "react-flags-select";
import CountriesDropdown from "../CountriesDropdown";
import countries from "../CountriesDropdown/countriesFlat.json";
import { useOutsideClick } from "../../../services/hookHelpers";
import cn from "classnames";
import * as Yup from "yup";

import "./style.scss";
import Button from "../../Atoms/Button";

const BillingInfoForm = ({
  formValues,
  saveFormValues,
  vocabulary,
  lang,
  submitTitle,
  isProcessing,
}) => {
  const [isOpenCountriesDropdown, setIsOpenCountriesDropdown] = useState(false);
  const wrapperRef = useRef(null);
  const {
    v_v_required,
    v_a_incorect_email,
    v_billing_info_form,
    v_search,
    v_empty,
    select_country,
    v_client_name,
    v_address_lowC,
    no_valid_zip,
  } = vocabulary;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(v_v_required),
    // lastName: Yup.string().required(v_v_required),
    emailAddress: Yup.string().email(v_a_incorect_email),
    country: Yup.string().required(v_v_required),
    postalCode: Yup.string().matches(
      /^[a-zA-Z0-9-\]\[\s]{0,11}$/, //eslint-disable-line
      no_valid_zip,
    ),
  });

  const FORM = [
    [
      {
        id: "1",
        field: true,
        name: "name",
        label: v_client_name,
        placeholder: "Enter name",
        required: true,
      },
      {
        id: "4",
        name: "country",
        label: v_billing_info_form.country.label,
        placeholder: v_billing_info_form.country.placeholder,
        component: ReactFlagsSelect,
        required: true,
      },
    ],
    [
      {
        id: "9",
        field: true,
        name: "emailAddress",
        label: v_billing_info_form.emailAddress.label,
        placeholder: v_billing_info_form.emailAddress.placeholder,
      },
      {
        id: "5",
        field: true,
        name: "address",
        label: v_address_lowC,
        placeholder: "Enter your address",
      },
    ],
    [
      {
        id: "8",
        field: true,
        name: "postalCode",
        label: v_billing_info_form.postalCode.label,
        placeholder: v_billing_info_form.postalCode.placeholder,
        type: "string",
      },
      {
        id: "10",
        field: true,
        name: "phoneNumber",
        label: v_billing_info_form.phoneNumber.label,
        placeholder: v_billing_info_form.phoneNumber.placeholder,
      },
    ],
  ];

  useOutsideClick(wrapperRef, () => setIsOpenCountriesDropdown(false));

  return (
    <div className="billing-info-form__body">
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveFormValues(values);
        }}
      >
        {(formik) => (
          <Form>
            {FORM.map((rows, index) => (
              <div key={index} className="billing-info-form__row">
                {rows.map((field) => (
                  <div key={field.id} className="billing-info-form__col">
                    <label
                      htmlFor={field.id}
                      className="billing-info-form__label"
                    >
                      {`${field.label}${field.required ? " *" : ""}`}
                    </label>
                    {field.field ? (
                      <Field
                        className={cn("billing-info-form__field", {
                          error:
                            formik.errors[field.name] &&
                            formik.touched[field.name],
                        })}
                        id={field.id}
                        name={field.name}
                        placeholder={field.placeholder}
                        type={field.type}
                        disabled={isProcessing}
                      />
                    ) : (
                      <div className="flag-input-container" ref={wrapperRef}>
                        <div
                          className="flag-input-container-select"
                          onClick={() =>
                            setIsOpenCountriesDropdown(!isOpenCountriesDropdown)
                          }
                        >
                          <div className="flag-input-container-selected">
                            {countries[formik.values.country] ? (
                              <>
                                <img
                                  className="flag-input-container-selected-flag"
                                  src={countries[formik.values.country].flag}
                                  alt=""
                                />
                                <span className="flag-input-container-selected-text">
                                  {countries[formik.values.country].name.common}
                                </span>
                              </>
                            ) : (
                              <span className="flag-input-container-selected-text-empty">
                                {select_country}
                              </span>
                            )}
                          </div>
                          <div
                            className={cn("flag-input-container-select-arrow", {
                              rotated: isOpenCountriesDropdown,
                            })}
                          />
                        </div>
                        {isOpenCountriesDropdown && (
                          <div className="flag-input-container-select-dropdown">
                            <CountriesDropdown
                              inputPlaceholder={`${v_search}...`}
                              epmtyText={v_empty}
                              onSelect={(item) => {
                                formik.values.country = item.code;
                                setIsOpenCountriesDropdown(false);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {formik.errors[field.name] && formik.touched[field.name] ? (
                      <span className="billing-info-form__error">
                        {formik.errors[field.name]}
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
            <Button submit type="primary" width="100%" isLoading={isProcessing}>
              {submitTitle}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  lang: state.languageReducer.selectedLang,
});

export default connect(mapStateToProps)(BillingInfoForm);
