import classnames from "classnames";
import { ArrowIcon } from "../../Atoms/SvgIcons";
import React, { useEffect, useRef, useState } from "react";

import "./style.scss";

export const RecruitmentHeaderDropdown = ({
  title,
  selectors,
  onFilterHendler,
  period,
  handlePriority
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentSelector, setCurrentSelector] = useState("");

  const dropdown = useRef();
  const priorities = useRef();

  const openDropdown = () => {
    setIsOpen((prevState) => !prevState);
    setExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpen &&
        expanded &&
        dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !priorities.current.contains(e.target)
      ) {
        setIsOpen(false);
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen, expanded]);

  useEffect(() => {
    setCurrentSelector("");
  }, [period]);

  const selectedFilter = (titleSelector) => {
    onFilterHendler(titleSelector);
    setCurrentSelector(titleSelector);
    setIsOpen((prevState) => !prevState);
    setExpanded((prevState) => !prevState);
    handlePriority(titleSelector)
  };

  return (
    <>
      <div
        ref={priorities}
        className="header-dropdown"
        onClick={() => openDropdown()}
      >
        <div className="header-dropdown__title pointer">
          {title}
          {currentSelector && <span>: {currentSelector}</span>}
        </div>
        <div
          className={classnames("header-dropdown__icon", {
            "header-dropdown__icon--rotated": expanded,
          })}
        >
          <ArrowIcon
            className="header-dropdown__icon__img"
            color="var(--project-icon-path-color)"
          />
        </div>
      </div>
      <div ref={dropdown} className="header-dropdown__selectors">
        {isOpen &&
          selectors.map(({ titleSelector, selected }, index) => (
            <div
              key={index + titleSelector}
              onClick={() => selectedFilter(titleSelector, selected)}
              className="header-dropdown__box"
            >
              {titleSelector}
            </div>
          ))}
      </div>
    </>
  );
};
