import React, { Component } from "react";
import { connect } from "react-redux";

// Services
import cn from "classnames";
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
} from "../../../services/authentication";
// Components
import CreateTeamModal from "../CreateTeamModal";
import CustomScrollbar from "../CustomScrollbar";
import ModalPortal from "../ModalPortal";
import { changeUpgradeModalStatusAction } from "../../../redux/actions/SubscriptionPageAction";
import { newGetUserTeamsAction } from "../../../redux/actions/TeamActions";
// Actions
import { changeAddTeamModalStatus } from "../../../redux/actions/TeamAddPageAction";

// Queries

// Config

// Styles
import "./style.scss";

class TeamAdd extends Component {
  constructor(props) {
    super(props);
    this.teamListRef = React.createRef();
    this.activeTeamRef = React.createRef();
  }

  closeOnClickOutside() {}

  componentWillUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  }

  componentDidUpdate(prevProps) {
    this.activeTeamRef.current &&
      this.activeTeamRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    if (prevProps.openTeamList !== this.props.openTeamList) {
      if (this.props.openTeamList) {
        document.addEventListener("click", this.closeDropdown);
      } else {
        document.removeEventListener("click", this.closeDropdown);
      }
    }
    if (
      this.props.userTeams.data.length !==
      this.props.newUserTeams?.user_team.length
    ) {
      this.props.newGetUserTeamsAction();
    }
  }

  closeDropdown = (e) => {
    if (
      this.teamListRef.current &&
      !this.teamListRef.current.contains(e.target)
    ) {
      this.props.closeTeamList();
      document.removeEventListener("click", this.closeDropdown);
    }
  };

  findUserPlane = (planeId, planes) =>
    planes.find((item) => planeId === item.id);

  countOwnTeams = (ownerId, teams) =>
    teams.filter((item) => item.owner_id === ownerId).length;

  addTeam = () => {
    const { changeAddTeamModalStatus, closeTeamList } = this.props;

    changeAddTeamModalStatus(true);
    closeTeamList();
  };

  checkOnTeam = () => {
    const {
      v_upgrade_teams_title,
      v_upgrade_teams_description,
      v_upgrade_admin_note,
    } = this.props.vocabulary;
    const isAdmin = checkIsAdminByRole(this.props.currentTeam.data.role);

    if (
      this.props.currentTeam.data.userPlan.maxTeams &&
      this.props.userTeams.data.length >=
        this.props.currentTeam.data.userPlan.maxTeams
    ) {
      this.props.changeUpgradeModalStatusAction({
        isOpen: true,
        content: {
          title: v_upgrade_teams_title,
          description: `${v_upgrade_teams_description} ${
            isAdmin ? v_upgrade_admin_note : ""
          }`,
        },
      });
      return;
    }
    this.addTeam();
  };

  render() {
    const {
      changeAddTeamModalStatus,
      teamAddModalToggle,
      vocabulary,
      isMobile,
      userTeams,
      currentTeam,
      handleChange,
      openTeamList,
      closeTeamList,
      newUserTeams,
    } = this.props;
    const { v_new_team, v_default, v_set_default } = vocabulary;

    return (
      <>
        {teamAddModalToggle && (
          <ModalPortal>
            <CreateTeamModal
              isMobile={isMobile}
              changeAddTeamModalStatus={changeAddTeamModalStatus}
              closeTeamList={closeTeamList}
            />
          </ModalPortal>
        )}
        {openTeamList && (
          <div
            ref={this.teamListRef}
            className={cn({
              customeTheme: !isMobile,
              customTheme_mobile: isMobile,
            })}
          >
            <div
              className="add_team_container"
              style={{ height: `${userTeams.data.length * 35}px` }}
            >
              <CustomScrollbar disableTimeEntriesFetch>
                {newUserTeams &&
                  newUserTeams?.user_team.map((team) => (
                    <div
                      ref={
                        team.team.id === currentTeam.data.id &&
                        this.activeTeamRef
                      }
                      key={team.team.id}
                      data-id={team.team.id}
                      onClick={(e) => {
                        this.props.newGetUserTeamsAction();
                        handleChange(e);
                      }}
                      className={cn("team_item", {
                        team_item_selected:
                          team.team.id === currentTeam.data.id,
                      })}
                    >
                      <div className="team_item_name">
                        {team.invite_mark && (
                          <span className="team_item__circle" />
                        )}
                        {team.team.name}
                      </div>
                      <div
                        className={cn({
                          team_selection: team.team.id !== currentTeam.data.id,
                          team_selection_default:
                            team.team.id === currentTeam.data.id,
                        })}
                      >
                        {team.team.id === currentTeam.data.id
                          ? v_default
                          : v_set_default}
                      </div>
                    </div>
                  ))}
              </CustomScrollbar>
            </div>
            <div className="add_team_btn" onClick={this.checkOnTeam}>
              {localStorage.getItem("themes") === "light" ? (
                <i className="add_team_btn__gray" />
              ) : (
                <i className="add_team_btn__white" />
              )}
              {v_new_team}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  teamAddModalToggle: store.teamAddReducer.teamAddModalToggle,
  userData: store.userReducer.user,
});

const mapDispatchToProps = {
  changeAddTeamModalStatus,
  changeUpgradeModalStatusAction,
  newGetUserTeamsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAdd);
