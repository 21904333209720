import {
  getBoardAPI,
  editLaneAPI,
  deleteLaneAPI,
  addLaneAPI,
  editCardAPI,
  deleteCardAPI,
  addCardAPI,
  getCandidateInfoAPI,
  createCandidateInfoAPI,
  editCandidateInfoAPI,
  createDirectionApi,
  getDirectionApi,
  removeDirectionApi,
  getVacancyApi,
  postVacancyApi,
  updateDirectionApi,
  getSearchDirectionApi,
  getApplicationApi,
  postApplicationApi,
  getSearchApplicationApi,
  updateApplicationApi,
  getSearchCardApi,
  getSearchVacancyApi,
  getSearchCandidatesApi,
  getCandidatesApi,
  editVacancyApi,
  removeApplicationApi,
  archiveApplicationApi,
} from "../../api/people";
import { showNotificationAction } from "./NotificationActions";

export const BOARD_GET_BOARD = "BOARD_GET_BOARD";

export const getBoardAction = (payload) => ({
  type: BOARD_GET_BOARD,
  payload,
});

export const getBoard = (params) => async (dispatch) => {
  try {
    const response = await getBoardAPI({ ...params });

    for (const lane of response.data) {
      lane.cards = lane.cards
        .filter((item) => !item.archived)
        .sort((a, b) => a.index - b.index);
    }
    dispatch(getBoardAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_ADD_LANE = "BOARD_ADD_LANE";

export const addLaneAction = (payload) => ({
  type: BOARD_ADD_LANE,
  payload,
});

export const addLane = (params, boardId) => async (dispatch, getState) => {
  try {
    const response = await addLaneAPI({
      ...params,
      index: getState().boardReducer.lanes.length,
      boardId: boardId,
    });

    dispatch(addLaneAction(response.data));
    dispatch(getBoard({ boardId }));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_MOVE_LANE = "BOARD_MOVE_LANE";

export const moveLaneAction = (payload) => ({
  type: BOARD_MOVE_LANE,
  payload,
});

export const moveLane = (params, data, payload) => async (dispatch) => {
  try {
    const response = await editLaneAPI({
      id: payload.id,
      index: data,
      title: payload.title,
      boardId: payload.board_id,
    });

    dispatch(moveLaneAction(response.data));
    dispatch(getBoard({ boardId: payload.board_id }));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_DELETE_LANE = "BOARD_DELETE_LANE";

export const deleteLaneAction = (payload) => ({
  type: BOARD_DELETE_LANE,
  payload,
});

export const deleteLane = (params, boardId) => async (dispatch) => {
  try {
    const response = await deleteLaneAPI(params, boardId);

    dispatch(deleteLaneAction(response.data));
    dispatch(getBoard({ boardId }));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_CHANGE_LANE_TITLE = "BOARD_CHANGE_LANE_TITLE";

export const changeLaneTitleAction = (payload) => ({
  type: BOARD_CHANGE_LANE_TITLE,
  payload,
});

export const changeLaneTitle =
  (params, data, boardId) => async (dispatch, getState) => {
    const lane = getState().boardReducer.lanes.find(
      (lane) => lane.id === params,
    );

    try {
      const response = await editLaneAPI({
        id: params,
        index: lane.index,
        title: data.title,
        boardId: boardId,
      });

      dispatch(changeLaneTitleAction(response.data));
      dispatch(getBoard({ boardId }));
    } catch (error) {
      console.log("error", error);
    }
  };

export const BOARD_ADD_CARD = "BOARD_ADD_CARD";

export const addCardAction = (payload) => ({
  type: BOARD_ADD_CARD,
  payload,
});

export const addCard =
  (params, laneId, boardId) => async (dispatch, getState) => {
    const lane = getState().boardReducer.lanes.find(
      (lane) => lane.id === laneId,
    );

    try {
      const response = await addCardAPI({
        ...params,
        description: params.description || "",
        laneId,
        index: lane.cards.length,
      });

      dispatch(addCardAction(response.data));
      dispatch(getBoard({ boardId }));
    } catch (error) {
      console.log("error", error);
    }
  };

export const BOARD_MOVE_CARD = "BOARD_MOVE_CARD";

export const moveCardAction = (payload) => ({
  type: BOARD_MOVE_CARD,
  payload,
});

export const moveCard =
  (params, data, payload, index) => async (dispatch, getState) => {
    const lane = getState().boardReducer.lanes.find(
      (lane) => lane.id === params,
    );
    const card = lane.cards.find((card) => card.id === payload);

    try {
      const response = await editCardAPI({
        id: payload,
        title: card.title,
        index: index,
        description: card.description || "",
        toLaneId: data,
        fromLaneId: params,
      });

      dispatch(moveCardAction(response.data));
      dispatch(getBoard({ boardId: lane.board_id }));
    } catch (error) {
      console.log("error", error);
    }
  };

export const BOARD_DELETE_CARD = "BOARD_DELETE_CARD";

export const deleteCardAction = (payload) => ({
  type: BOARD_DELETE_CARD,
  payload,
});

export const deleteCard = (params, boardId) => async (dispatch) => {
  try {
    const response = await deleteCardAPI(params);

    dispatch(deleteCardAction(response.data));
    dispatch(getBoard({ boardId }));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_CHANGE_CARD_TEXT = "BOARD_CHANGE_CARD_TEXT";

export const changeCardTextAction = (payload) => ({
  type: BOARD_CHANGE_CARD_TEXT,
  payload,
});

export const changeCardText = (params, data) => async (dispatch, getState) => {
  const lane = getState().boardReducer.lanes.find((lane) => lane.id === params);
  const card = lane.cards.find((card) => card.id === data.id);

  try {
    const response = await editCardAPI({
      id: data.id,
      title: data.title || card.title,
      index: card.index,
      description: data.description || "",
      toLaneId: params,
      fromLaneId: params,
    });

    dispatch(changeCardTextAction(response.data));
    dispatch(getBoard({ boardId: lane.board_id }));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_GET_CANDIDATE_INFO = "BOARD_GET_CANDIDATE_INFO";

export const getCandidateInfoAction = (payload) => ({
  type: BOARD_GET_CANDIDATE_INFO,
  payload,
});

export const getCardData = (params) => async (dispatch) => {
  try {
    const response = await getCandidateInfoAPI(params);

    dispatch(getCandidateInfoAction(response.data));
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_CREATE_CANDIDATE_INFO = "BOARD_CREATE_CANDIDATE_INFO";

export const createCandidateInfoAction = (payload) => ({
  type: BOARD_CREATE_CANDIDATE_INFO,
  payload,
});

export const createCandidateInfo = (params) => async (dispatch) => {
  try {
    const response = await createCandidateInfoAPI(params);

    dispatch(createCandidateInfoAction(response.data));
    dispatch(getCandidates(1));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_EDIT_CANDIDATE_INFO = "BOARD_EDIT_CANDIDATE_INFO";

export const editCandidateInfoAction = (payload) => ({
  type: BOARD_EDIT_CANDIDATE_INFO,
  payload,
});

export const editCandidateInfo = (params, data) => async (dispatch) => {
  try {
    const response = await editCandidateInfoAPI(params, data);

    dispatch(editCandidateInfoAction(response.data));
    dispatch(getCandidates(1));
  } catch (error) {
    console.log("error", error);
  }
};

export const RESET_CANDIDATE_INFO = "RESET_CANDIDATE_INFO";

export const resetCandidateInfo = () => ({
  type: RESET_CANDIDATE_INFO,
});

export const BOARD_CREATE_DIRECTION = "BOARD_CREATE_DIRECTION";

export const createDirectionAction = (payload) => ({
  type: BOARD_CREATE_DIRECTION,
  payload,
});

export const createDirection = (data) => async (dispatch) => {
  try {
    const response = await createDirectionApi(data);

    dispatch(createDirectionAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_GET_DIRECTION = "BOARD_GET_DIRECTION";

export const getDirectionAction = (payload) => ({
  type: BOARD_GET_DIRECTION,
  payload,
});

export const getDirection = (page) => async (dispatch) => {
  try {
    const response = await getDirectionApi(page);

    dispatch(getDirectionAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_REMOVE_DIRECTION = "BOARD_REMOVE_DIRECTION";

export const removeDirectionAction = () => ({
  type: BOARD_REMOVE_DIRECTION,
});

export const removeDirection = (data) => async (dispatch) => {
  try {
    const response = await removeDirectionApi(data);

    dispatch(removeDirectionAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_UPDATE_DIRECTION = "BOARD_UPDATE_DIRECTION";

export const updateDirectionAction = () => ({
  type: BOARD_UPDATE_DIRECTION,
});

export const updateDirection = (data) => async (dispatch) => {
  try {
    const response = await updateDirectionApi(data);

    dispatch(updateDirectionAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_SEARCH_DIRECTION = "BOARD_SEARCH_DIRECTION";

export const searchDirectionAction = (payload) => ({
  type: BOARD_SEARCH_DIRECTION,
  payload,
});

export const searchDirection = (data) => async (dispatch) => {
  try {
    const response = await getSearchDirectionApi(data);

    dispatch(searchDirectionAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_SEARCH_CANDIDATES = "BOARD_SEARCH_CANDIDATES";

export const searchCandidatesAction = (payload) => ({
  type: BOARD_SEARCH_CANDIDATES,
  payload,
});

export const searchCandidates = (data) => async (dispatch) => {
  try {
    const response = await getSearchCandidatesApi(data);

    dispatch(searchCandidatesAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_GET_CANDIDATES = "BOARD_GET_CANDIDATES";

export const getCandidatesAction = (payload) => ({
  type: BOARD_GET_CANDIDATES,
  payload,
});

export const getCandidates = (page) => async (dispatch) => {
  try {
    const response = await getCandidatesApi(page);

    dispatch(getCandidatesAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_SEARCH_CARD = "BOARD_SEARCH_CARD";

export const searchCardAction = (payload) => ({
  type: BOARD_SEARCH_CARD,
  payload,
});

export const searchCard = (data) => async (dispatch) => {
  try {
    const response = await getSearchCardApi(data);

    dispatch(searchCardAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_GET_VACANCY = "BOARD_GET_VACANCY";

export const getVacancyAction = (payload) => ({
  type: BOARD_GET_VACANCY,
  payload,
});

export const getVacancy = (page) => async (dispatch) => {
  try {
    const response = await getVacancyApi(page);

    dispatch(getVacancyAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_POST_VACANCY = "BOARD_POST_VACANCY";
export const BOARD_POST_VACANCY_ERROR = "BOARD_POST_VACANCY_ERROR";

export const postVacancyAction = (payload) => ({
  type: BOARD_POST_VACANCY,
  payload,
});

export const postVacancy = (page) => async (dispatch, getState) => {
  const { vocabulary } = getState().languageReducer;
  try {
    const response = await postVacancyApi(page);

    dispatch(postVacancyAction(response.data));
    dispatch(
      showNotificationAction({
        text: `${vocabulary.v_hr_people.vacancies.vacancies_created}`,
        type: "success",
      }),
    );
  } catch (error) {
    console.log("error", error);
    dispatch(
      showNotificationAction({
        text: `${error}`,
        type: "error",
      }),
    );
  }
};

export const BOARD_EDIT_VACANCY = "BOARD_EDIT_VACANCY";

export const editVacancyAction = (payload) => ({
  type: BOARD_EDIT_VACANCY,
  payload,
});

export const editVacancy = (page) => async (dispatch, getState) => {
  const { vocabulary } = getState().languageReducer;

  try {
    const response = await editVacancyApi(page);

    dispatch(editVacancyAction(response.data));
    dispatch(
      showNotificationAction({
        text: `${vocabulary.v_hr_people.vacancies.vacancies_edited}`,
        type: "success",
      }),
    );
  } catch (error) {
    console.log("error", error);
    dispatch(
      showNotificationAction({
        text: `${error}`,
        type: "error",
      }),
    );
  }
};

export const BOARD_SEARCH_VACANCY = "BOARD_SEARCH_VACANCY";

export const searchVacancyAction = (payload) => ({
  type: BOARD_SEARCH_VACANCY,
  payload,
});

export const getSearchVacancy = (params) => async (dispatch) => {
  try {
    const response = await getSearchVacancyApi(params);

    dispatch(searchVacancyAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_GET_APPLICATION = "BOARD_GET_APPLICATION";

export const getApplicationAction = (payload) => ({
  type: BOARD_GET_APPLICATION,
  payload,
});

export const getApplication = (page) => async (dispatch) => {
  try {
    const response = await getApplicationApi(page);

    dispatch(getApplicationAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_POST_APPLICATION = "BOARD_POST_APPLICATION";

export const postApplicationAction = (payload) => ({
  type: BOARD_POST_APPLICATION,
  payload,
});

export const postApplication = (page) => async (dispatch) => {
  try {
    const response = await postApplicationApi(page);

    dispatch(postApplicationAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_REMOVE_APPLICATION = "BOARD_REMOVE_APPLICATION";

export const removeApplicationAction = (payload) => ({
  type: BOARD_REMOVE_APPLICATION,
  payload,
});

export const removeApplication = (id) => async (dispatch) => {
  try {
    const response = await removeApplicationApi(id);

    dispatch(removeApplicationAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const BOARD_UPDATE_APPLICATION = "BOARD_UPDATE_APPLICATION";

export const updateApplicationAction = (payload) => ({
  type: BOARD_UPDATE_APPLICATION,
  payload,
});

export const updateApplication = (page) => async (dispatch) => {
  try {
    const response = await updateApplicationApi(page);

    dispatch(updateApplicationAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const ARCHIVE_APPLICATION = "ARCHIVE_APPLICATION";

export const archiveApplicationAction = (payload) => ({
  type: ARCHIVE_APPLICATION,
  payload,
});

export const archiveApplication = (page) => async (dispatch) => {
  try {
    const response = await archiveApplicationApi(page);

    dispatch(archiveApplicationAction(response.data));
  } catch (error) {
    console.log("error", error);
  }
};

export const getSearchApplication = (params) => async (dispatch) => {
  try {
    const response = await getSearchApplicationApi(params);

    dispatch(getApplicationAction(response.data));

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};
