import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";

import Modal from "../../../Atoms/Modal";
import { Formik } from "formik";
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  Input,
  SaveButton,
  TextLabel,
} from "./My.styled";
import "./style.scss";

const CreateNewDirection = ({
  vocabulary,
  currentTeam,
  pageDirection,
  directionModal,
  handleModalCreateEditDirection,
  updateDirectionHandler,
  createDirectionHandler,
  getDirectionHandler,
  removeDirectionHandler,
  directionData,
}) => {
  const { v_hr_people, v_save, v_cancel, v_delete } = vocabulary;
  const { isOpen, typeDirection, directionId } = directionModal;
  const formikRef = useRef(null);

  useEffect(() => {
    // add defaults values to form if edit

    if (directionId && typeDirection === "edit") {
      const currentDirection = findObjectById(
        directionData,
        directionId,
      );

      formikRef.current.setFieldValue(
        "nameDirection",
        `${currentDirection?.title || ""}`,
      );
    } else if (typeDirection === "create") {
      formikRef.current.setFieldValue("nameDirection", "");
    }
  }, [directionId, typeDirection]);

  const handleUnmountForm = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const handleOnSubmit = async (values) => {
    const { nameDirection, vacancies } = values;

    switch (typeDirection) {
      case "create":
        await createDirectionHandler({
          title: nameDirection,
          teamId: currentTeam,
        });
        await getDirectionHandler(pageDirection);
        await handleUnmountForm();
        await handleModalCreateEditDirection({ isOpen: false });
        break;

      case "edit":
        await updateDirectionHandler({
          newTitle: nameDirection,
          boardId: directionId,
        });
        await getDirectionHandler(pageDirection);
        await handleUnmountForm();
        await handleModalCreateEditDirection({ isOpen: false });

      default:
        break;
    }
  };

  const handleOnDelete = async () => {
    await removeDirectionHandler(directionId);
    await getDirectionHandler(pageDirection);
    await handleUnmountForm();
    await handleModalCreateEditDirection({ isOpen: false });
  };

  const findObjectById = (data, id) => data.find((item) => item.id === id);

  const renderButtons = useCallback(() => {
    switch (typeDirection) {
      case "edit":
        return (
          <ButtonContainer>
            <DeleteButton type="button" onClick={() => handleOnDelete()}>
              {v_delete}
            </DeleteButton>
            <CancelButton
              type="button"
              onClick={() => handleModalCreateEditDirection({ isOpen: false })}
            >
              {v_cancel}
            </CancelButton>
            <SaveButton type="submit">{v_save}</SaveButton>
          </ButtonContainer>
        );

      default:
        return (
          <ButtonContainer>
            <CancelButton
              type="button"
              onClick={() => handleModalCreateEditDirection({ isOpen: false })}
            >
              {v_cancel}
            </CancelButton>
            <SaveButton type="submit">{v_save}</SaveButton>
          </ButtonContainer>
        );
    }
  }, [typeDirection, directionId]);

  const renderHeaderTitle = useCallback(() => {
    switch (typeDirection) {
      case "edit":
        return v_hr_people.new_direction_modal.edit;

      default:
        return v_hr_people.new_direction_modal.create;
    }
  }, [typeDirection]);

  const renderDirectionModal = useCallback(
    () => (
      <Modal
        open={isOpen}
        title={renderHeaderTitle()}
        onClose={() => handleModalCreateEditDirection({ isOpen: false })}
        classes={{
          header: "recruitment-modal__header",
          title: "recruitment-modal__title",
          wrapper: "recruitment-modal",
          container: "recruitment-modal__container",
        }}
      >
        <>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              nameDirection: "",
              vacancies: "",
            }}
            onSubmit={handleOnSubmit}
          >
            {(formik) => {
              formikRef.current = formik;
              return (
                <>
                  <Form onSubmit={formik.handleSubmit}>
                      <div>
                        <TextLabel>
                          {v_hr_people.new_direction_modal.name_dir}
                        </TextLabel>
                        <Input
                          type="text"
                          value={formik.values.nameDirection}
                          onChange={formik.handleChange("nameDirection")}
                          onBlur={formik.handleBlur}
                          placeholder={
                            v_hr_people.new_direction_modal.name_placeholder
                          }
                        />
                      </div>
                    {renderButtons()}
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      </Modal>
    ),
    [directionModal],
  );

  return <>{renderDirectionModal()}</>;
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewDirection);
