import { AxiosPromise } from "axios";
import { instance } from "../configAPI";

export interface ICreatePlanBody {
  userId: number;
  projectId: number;
  duration: number;
  startDate: number;
  endDate: number;
  name: string;
  description: string;
  loadHours: number;
  loadRate: number;
}

export interface IGetPlansByTeamParams {
  startDate: string;
  endDate: string;
  tagsId?: string[];
  projectsId?: string[];
  usersId?: string[];
}

export interface IGetTotalWorkload extends IGetPlansByTeamParams {}

export interface IPostBenchList extends IGetTotalWorkload {}

export interface IGetUserInfo {
  teamId: string;
  userId: string;
}

export interface IEditUserPayload {
  userId: string;
  body: IEditUserBody;
}

export interface IEditUserBody {
  dayOff: string;
  sickLeave: string;
  vacation: string;
  workOff: number | string;
  vacationReset: Date | string | null;
  joinDate: Date | string | null;
  workload: number;
}

export const createPlan = (data: ICreatePlanBody): AxiosPromise =>
  instance({
    url: "/timer-planning/create",
    method: "POST",
    data,
  });

export const editPlan = (data: ICreatePlanBody, id: string): AxiosPromise =>
  instance({
    url: `/timer-planning/${id}`,
    method: "PATCH",
    data,
  });

export const deletePlan = (data: ICreatePlanBody, id: string): AxiosPromise =>
  instance({
    url: `/timer-planning/${id}`,
    method: "DELETE",
    data,
  });

export const getPlansByTeam = (params: IGetPlansByTeamParams): AxiosPromise =>
  instance({
    url: "/timer-planning/",
    method: "GET",
    params,
  });

export const postBenchList = ({
  startDate,
  endDate,
}: IPostBenchList): AxiosPromise =>
  instance({
    url: `/timer-planning/bench/list?startDate=${startDate}&endDate=${endDate}`,
    method: "POST",
  });

export const getPlanHistory = (planId: string): AxiosPromise =>
  instance({
    url: `/timer-planning/${planId}/history`,
    method: "GET",
  });

export const getUserInfo = ({ teamId, userId }: IGetUserInfo): AxiosPromise =>
  instance({
    url: `/timer-planning/${teamId}/user/${userId}`,
    method: "GET",
  });

export const editUserInfo = ({
  userId,
  body,
}: IEditUserPayload): AxiosPromise =>
  instance({
    url: `/timer-planning/user/${userId}`,
    method: "PATCH",
    data: body,
  });

export const getTotalWorkload = (params: IGetTotalWorkload): AxiosPromise =>
  instance({
    url: `/timer-planning/workload/total`,
    method: `GET`,
    params,
  });
