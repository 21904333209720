import moment from "moment";
import { store } from "../../../redux/store";

export default function (usersProp, plansProp) {
  // const activeUsers = usersProp.filter((u) => u.is_active);
  let groups = [];

  groups = usersProp.map((data) => {
    const { id, username, avatar, user_technology, timer_current_v2, timer_v2 } = data;

    return {
      id,
      username,
      avatar,
      user_technology,
      timer_current_v2,
      timer_v2,
    };
  });

  let items = [];
  const allProjects = store.getState()?.projectReducer?.projectsList || [];

  items = plansProp.map((data, i) => {
    const { id, start_date, end_date, user_id, name } = data;
    const projectColor =
      allProjects.find((project) => project.id === data.project_id)
        ?.projectColor?.name || "#fff";

    return {
      id,
      group: user_id,
      title: name,
      start: moment(start_date).valueOf(),
      end: moment(end_date).valueOf(),
      // canMove: startValue > new Date().getTime(),
      // canResize: 'both',
      className:
        moment(start_date).day() === 6 || moment(start_date).day() === 0
          ? "item-weekend"
          : "",
      bgColor: projectColor,
      selectedBgColor: projectColor,
      color: projectColor,
      itemProps: {
        ...data,
      },
    };
  });

  return { groups, items };
}
