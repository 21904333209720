import { instance } from "../configAPI";

export const getCurrentTeam = () =>
  instance({
    url: "/team/current",
    method: "GET",
  });

export const addTeam = ({ teamName }: any) =>
  instance({
    url: "/team/add",
    method: "POST",
    data: {
      teamName,
    },
  });

export const getCurrentTeamDetailedData = (startDate = '', endDate = '') =>
  instance({
    url: `/team/current/detailed-data?startDate=${startDate}&endDate=${endDate}`,
    method: "GET",
  });

export const switchTeam = ({ teamId }: any) =>
  instance({
    url: "/team/switch",
    method: "PATCH",
    data: {
      teamId,
    },
  });

export const renameTeam = ({ teamId, newName }: any) =>
  instance({
    url: "/team/rename",
    method: "PATCH",
    data: {
      teamId,
      newName,
    },
  });

  export const getTeamUsersStatus = () =>
  instance({
    url: "/team/users-status",
    method: "GET",
  });

  export const getUsersRoles = () =>
  instance({
    url: "/team/users-roles",
    method: "GET",
  });
