import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import PrivateRoute from "./components/Unstructured/CustomRoutes/PrivateRoute";
import RouteInvoiceView from "./components/Unstructured/RouteInvoiceView/RouteInvoiceView";

import MainPage from "./pages/MainPage";
import ProjectsPage from "./pages/ProjectsPage";
import TeamPage from "./pages/TeamPage";
import ReportsByProjectsPage from "./pages/ReportsByProjectPage";
import AuthPage from "./pages/AuthPage";
import RegisterPage from "./pages/RegisterPage";
import VerifyPage from "./pages/VerifyPage";
import ThankYouPage from "./pages/ThankYouPage";
import ForgotPassword from "./pages/ForgotPassword";
import UserSettings from "./pages/UserSettings";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ClientsPage from "./pages/ClientsPage";
import InvoicesPage from "./pages/InvoicesPage";
import InvoicesPageDetailed from "./pages/InvoicesPageDetailed";
import InvoiceViewPage from "./pages/InvoiceViewPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import PeoplePage from "./pages/PeoplePage";
import HRBoardComponent from "./components/Unstructured/People/HRBoardComponent";
import ResourcePlanningPage from "./pages/ResourcePlanning";
import InvoicesManager from "./pages/InvoicesManager";

import PageTemplate from "./components/Unstructured/PageTemplate";

// styles
import "normalize.css";
import "./base.scss";
import "./assets/fonts/icomoon/icomoon.css";

import * as responsiveActions from "./redux/actions/ResponsiveActions";
import { showNotificationAction } from "./redux/actions/NotificationActions";
import { checkAccessByRole, ROLES } from "./services/authentication";
import { ErrorPage } from "./pages/ErrorPage";
import Unsubscribe from "./pages/Unsubscribe";
import UnsubscribeReports from "./pages/Unsubscribe/UnsubscribeReports";
import ReportsSummary from "./pages/ReportsPage/ReportsSummary";
import ReportsRealTime from "./pages/ReportsPage/ReportsRealTime";

import "moment/locale/uk";
import "moment/locale/ru";
import "moment/locale/it";
import "moment/locale/en-gb";
import "moment/locale/de";

class App extends Component {
  componentDidMount() {
    this.setResponsiveReducer();
    window.addEventListener("resize", this.setResponsiveReducer);
    window.addEventListener("online", this.connectionRestore);
    window.addEventListener("offline", this.connectionLost);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setResponsiveReducer);
    window.removeEventListener("online", this.connectionRestore);
    window.removeEventListener("offline", this.connectionLost);
  }

  setResponsiveReducer = (event) => {
    const { setViewportSize, setIsMobile, isMobile } = this.props;

    setViewportSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });

    if (window.innerWidth >= 1062 && isMobile) {
      setIsMobile(false);
    }

    if (window.innerWidth < 1062 && !isMobile) {
      setIsMobile(true);
    }
  };

  connectionRestore = () => {
    const { vocabulary, showNotificationAction } = this.props;
    const { v_connection_restored } = vocabulary;

    showNotificationAction({
      type: "connection-restored",
      text: v_connection_restored,
    });
  };

  connectionLost = () => {
    const { vocabulary, showNotificationAction } = this.props;
    const { v_connection_problem } = vocabulary;

    showNotificationAction({
      type: "lost-connection",
      text: v_connection_problem,
    });
  };

  render() {
    const redirect = (to) => () => <Redirect to={to} />;
    const { userRole } = this.props;

    return (
      <Switch>
        <Route exact path="/" render={redirect("/login")} />
        <PrivateRoute
          exact
          path="/timer"
          render={() => <PageTemplate content={MainPage} />}
        />
        <PrivateRoute
          exact
          path="/reports/summary"
          render={() => <PageTemplate content={ReportsSummary} />}
        />
        <PrivateRoute
          exact
          path="/reports/real-time"
          render={() => <PageTemplate content={ReportsRealTime} />}
        />
        <PrivateRoute
          exact
          path="/projects"
          render={() => <PageTemplate content={ProjectsPage} />}
        />
        <PrivateRoute
          exact
          path="/clients"
          render={() => <PageTemplate content={ClientsPage} />}
        />
        <PrivateRoute
          exact
          path="/resource-planning"
          render={() => <PageTemplate content={ResourcePlanningPage} />}
        />
        <PrivateRoute
          exact
          path={"/subscription"}
          render={() => <Redirect to={{ pathname: "/subscription/plans" }} />}
        />
        <PrivateRoute
          exact
          path={"/subscription/plans"}
          render={() => <PageTemplate content={SubscriptionPage} />}
        />
        <PrivateRoute
          exact
          path={"/subscription/:tab/:planId?"}
          render={() => <PageTemplate content={SubscriptionPage} />}
        />
        <PrivateRoute
          exact
          path={"/recruitment"}
          render={() => (
            <Redirect to={{ pathname: "/recruitment/directions" }} />
          )}
        />
        <PrivateRoute
          exact
          path={"/recruitment/directions"}
          render={() => <PageTemplate content={PeoplePage} />}
        />
        <PrivateRoute
          exact
          path={"/recruitment/directions/:direction/board"}
          render={() => <PageTemplate content={HRBoardComponent} />}
        />
        <PrivateRoute
          exact
          path={"/recruitment/:tab"}
          render={() => <PageTemplate content={PeoplePage} />}
        />
        <PrivateRoute
          exact
          path="/team"
          render={() => <PageTemplate content={TeamPage} />}
        />
        <PrivateRoute
          exact
          path="/reports/detailed/projects/:projectName/team/:userEmails/from/:dateStart/to/:endDate/teamId/:teamId"
          render={() => <PageTemplate content={ReportsByProjectsPage} />}
        />
        <PrivateRoute
          exact
          path="/user-settings"
          render={() => <PageTemplate content={UserSettings} />}
        />
        <Route
          exact
          path="/login"
          render={() => (
            <PageTemplate authPage hideSidebar hideHeader content={AuthPage} />
          )}
        />
        <Route
          exact
          path="/register"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={RegisterPage}
            />
          )}
        />
        <Route
          exact
          path="/verify/:secretKey"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={VerifyPage}
            />
          )}
        />
        <Route
          exact
          path="/thank-you-page"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={ThankYouPage}
            />
          )}
        />
        <Route
          exact
          path="/forgot-password"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={ForgotPassword}
            />
          )}
        />
        <Route
          exact
          path="/reset-password"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={ResetPasswordPage}
            />
          )}
        />
        <Route
          exact
          path="/free-invoice-generator"
          render={() => <InvoicesManager />}
        />
        <Route
          exact
          path="/unsubscribe"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={Unsubscribe}
            />
          )}
        />
        <Route
          exact
          path="/unsubscribe-reports"
          render={() => (
            <PageTemplate
              authPage
              hideSidebar
              hideHeader
              content={UnsubscribeReports}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/invoices"
          render={() => <PageTemplate content={InvoicesPage} />}
        />
        <PrivateRoute
          exact
          path="/invoices/create"
          render={() =>
            checkAccessByRole(userRole, [
              ROLES.ROLE_OWNER,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_INVOICES_MANAGER,
            ]) ? (
              <PageTemplate content={InvoicesPageDetailed} />
            ) : (
              <Redirect to={"/invoices"} />
            )
          }
        />
        <PrivateRoute
          exact
          path="/invoices/:pageType"
          render={() => (
            <PageTemplate content={(props) => <InvoicesPage {...props} />} />
          )}
        />
        <PrivateRoute
          exact
          path="/invoices/:pageType/:invoiceId"
          render={() =>
            checkAccessByRole(userRole, [
              ROLES.ROLE_OWNER,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_INVOICES_MANAGER,
            ]) ? (
              <PageTemplate content={InvoicesPageDetailed} />
            ) : (
              <Redirect to={"/invoices"} />
            )
          }
        />
        <PrivateRoute
          exact
          path="/invoices/view/:invoiceId"
          render={() =>
            checkAccessByRole(userRole, [
              ROLES.ROLE_OWNER,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_INVOICES_MANAGER,
            ]) ? (
              <PageTemplate
                content={(props) => (
                  <InvoicesPageDetailed {...props} mode="view" />
                )}
              />
            ) : (
              <Redirect to={"/invoices"} />
            )
          }
        />
        <RouteInvoiceView
          exact
          path="/invoice/:invoiceId"
          render={() => (
            <PageTemplate
              content={(props) => <InvoiceViewPage {...props} mode="view" />}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/invoices/update/:invoiceId"
          render={() =>
            checkAccessByRole(userRole, [
              ROLES.ROLE_OWNER,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_INVOICES_MANAGER,
            ]) ? (
              <PageTemplate
                content={(props) => (
                  <InvoicesPageDetailed {...props} mode="update" />
                )}
              />
            ) : (
              <Redirect to={"/invoices"} />
            )
          }
        />
        <Route render={() => <ErrorPage />} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.responsiveReducer.isMobile,
  isOwner: state.teamReducer.currentTeam.data.owner_id,
  user: state.userReducer.user,
  vocabulary: state.languageReducer.vocabulary,
  userRole: state.teamReducer.currentTeam.data.role,
});

const mapDispatchToProps = {
  ...responsiveActions,
  showNotificationAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
