import React from "react";
import classNames from "classnames";
import { ArrowIconLeft } from "../../../Atoms/SvgIcons";
import { TableRow, TableRow2, TableCell, TableCell2 } from "./My.styled";
import moment from "moment";
import useExpandController from "../useExpandController";

export const ExpandHistory = ({
  created_at,
  updated_data,
  index,
  timeFormat,
  vocabulary,
  teamUsers,
}) => {
  const { lang, v_rp_history } = vocabulary;
  const userById = (props) => {
    const user = teamUsers.find((item) => item.user.id === props);
    return user;
  };
  const { isExpand, toggle } = useExpandController(false);
  return (
    <>
      <TableRow key={index} onClick={toggle}>
        <TableCell>
          <span className="history-expand-btn" isExpand={isExpand}>
            <ArrowIconLeft
              className={classNames(isExpand ? "arrow-up" : "arrow-down")}
              color="white"
            />
          </span>
        </TableCell>
        <TableCell>
          {moment(created_at)
            .locale(lang.short)
            .format(
              `${timeFormat === "12" ? "MMMM DD / h:mm" : "MMMM DD / HH:mm"}`,
            ) +
            moment(created_at).format(`${timeFormat === "12" ? " a" : " "}`)}
        </TableCell>
        <TableCell>
          <div className="content">
            {updated_data?.createdBy
              ? v_rp_history.table.created
              : v_rp_history.table.updated}
          </div>
        </TableCell>
        <TableCell>
          <div className="content">
            {updated_data?.createdBy
              ? updated_data.createdBy
              : updated_data.updateBy}
          </div>
        </TableCell>
      </TableRow>
      {isExpand && (
        <>
          {updated_data.nameFrom || updated_data.nameTo ? (
            <TableRow2>
              <TableCell2>{""}</TableCell2>
              <TableCell2>{v_rp_history.table.plan}</TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.nameFrom
                    ? v_rp_history.table.from + updated_data.nameFrom
                    : v_rp_history.table.from + "-"}
                </div>
              </TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.nameTo
                    ? v_rp_history.table.to + updated_data.nameTo
                    : v_rp_history.table.to + "-"}
                </div>
              </TableCell2>
            </TableRow2>
          ) : null}
          {updated_data.startDateFrom || updated_data.startDateTo ? (
            <TableRow2>
              <TableCell2>{""}</TableCell2>
              <TableCell2>{v_rp_history.table.period}</TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.startDateFrom
                    ? v_rp_history.table.from +
                      moment(updated_data.startDateFrom)
                        .locale(lang.short)
                        .format("MMMM DD")
                    : v_rp_history.table.from + "-"}
                  {"/"}
                  {updated_data.endDateFrom
                    ? moment(updated_data.endDateFrom)
                        .locale(lang.short)
                        .format("MMMM DD")
                    : "-"}
                </div>
              </TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.startDateTo
                    ? v_rp_history.table.to +
                      moment(updated_data.startDateTo)
                        .locale(lang.short)
                        .format("MMMM DD")
                    : v_rp_history.table.to + "-"}
                  {"/"}
                  {updated_data.endDateTo
                    ? moment(updated_data.endDateTo)
                        .locale(lang.short)
                        .format("MMMM DD")
                    : "-"}
                </div>
              </TableCell2>
            </TableRow2>
          ) : null}
          {updated_data.projectFrom || updated_data.projectTo ? (
            <TableRow2>
              <TableCell2>{""}</TableCell2>
              <TableCell2>{v_rp_history.table.project}</TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.projectFrom
                    ? v_rp_history.table.from + updated_data.projectFrom
                    : v_rp_history.table.from + "-"}
                </div>
              </TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.projectTo
                    ? v_rp_history.table.to + updated_data.projectTo
                    : v_rp_history.table.to + "-"}
                </div>
              </TableCell2>
            </TableRow2>
          ) : null}
          {updated_data.userFrom || updated_data.userTo ? (
            <TableRow2>
              <TableCell2>{""}</TableCell2>
              <TableCell2>{v_rp_history.table.user}</TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.userFrom
                    ? v_rp_history.table.from +
                      userById(updated_data.userFrom).user.username
                    : v_rp_history.table.from + "-"}
                </div>
              </TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.userTo
                    ? v_rp_history.table.to +
                      userById(updated_data.userTo).user.username
                    : v_rp_history.table.to + "-"}
                </div>
              </TableCell2>
            </TableRow2>
          ) : null}
          {updated_data.descriptionFrom || updated_data.descriptionTo ? (
            <TableRow2>
              <TableCell2>{""}</TableCell2>
              <TableCell2>{v_rp_history.table.description}</TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.descriptionFrom
                    ? v_rp_history.table.from + updated_data.descriptionFrom
                    : v_rp_history.table.from + "-"}
                </div>
              </TableCell2>
              <TableCell2>
                <div className="content">
                  {updated_data.descriptionTo
                    ? v_rp_history.table.to + updated_data.descriptionTo
                    : v_rp_history.table.to + "-"}
                </div>
              </TableCell2>
            </TableRow2>
          ) : null}
        </>
      )}
    </>
  );
};
