import moment from "moment";
import React, { Component } from "react";
import { CalendarIcon } from "../../Atoms/SvgIcons";
import { getProjectColor } from "../../../../src/services/replaceProjectsColor";

import "./style.scss";
import { connect } from "react-redux";

class ResourcePlanningItemPopup extends Component {
  constructor(props) {
    super(props);
  }

  getProjectNameById = () => {
    const { item } = this.props;

    return item.itemProps.projectName || "";
  };

  render() {
    const { item, lang, vocabulary } = this.props;
    const dayMilliseconds = 24 * 59 * 60 * 1000;
    const planDuration = moment(item.end) - moment(item.start);
    const { v_days } = vocabulary;
    const currentCountTimePlan = moment(planDuration - dayMilliseconds).format("DDD");

    return (
      <div className="rp-item-popup">
        <div
          className="rp-item-popup__border"
          style={{
            backgroundColor:
              getProjectColor(item.bgColor) ||
              getProjectColor(item.itemProps.projectColor),
          }}
        ></div>
        <div className="rp-item-popup__content">
          <div className="rp-item-popup__title">{item.title}</div>
          <div className="rp-item-popup__project">
            {this.getProjectNameById()}
          </div>
          <div className="rp-item-popup__additional">
            {item.itemProps.description}
          </div>
          <div className="rp-item-popup__date">
            <CalendarIcon className="calendar-popup" color="#fff" />
            <div className="rp-item-popup__date-interval">
              {moment(item.start).locale(lang.short).format("MMM DD")} -{" "}
              {moment(item.end).locale(lang.short).format("MMM DD")}
              <br />({" "}
              <span>
                {currentCountTimePlan} {v_days}
              </span>{" "}
              )
            </div>
            <div className="rp-item-popup__clock" />
            {" Load:"}
            {item.itemProps.load_hours}
            {"h"}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
})

export default connect(
  mapStateToProps,
)(ResourcePlanningItemPopup);
