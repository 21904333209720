export default {
  lang: { short: "ru", long: "Русский" },

  "ERROR.USER.THIS_FACEBOOK_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Эта учетная запись Facebook уже подключена к другой учетной записи",
  "ERROR.USER.THIS_SOCIAL_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "Этот социальный аккаунт уже подключен",
  "ERROR.USER.THIS_EMAIL_ALREADY_CONNECTED_TO_ANOTHER_FB_ACCOUNT":
    "Аккаунт с этим e-mail уже подключен к другому аккаунту Facebook",
  "ERROR.USER.ACCOUNT_ALREADY_EXISTED":
    "Учетная запись пользователя уже существует.",
  "ERROR.TIMER.JIRA_WORKLOG_1_MIN_THRESHHOLD":
    "Минимальное время для добавление ворклога в Jira составляет 1 минута.",
  "ERROR.TIMER.JIRA_ADD_WORKLOG_FAILED":
    'Добавление ворклога в Jira было завершено с ошибкой. Убедитесь, что доступы к Jira верны и название задачи соотвествует формату "JIRA-1 Comment".',
  "ERROR.TIMER.JIRA_WORKLOG_ALREADY_ADDED":
    "Текущая задача уже была добавлена в ворклог Jira.",
  "Request path contains unescaped characters":
    "Проверьте правильность номера задачи",
  "Request failed with status code 404":
    "Задача с таким номером не найдена в Jira",
  "Request failed with status code 401":
    "Неверные учетные данные для синхронизации с Jira 'проверьте правильность в профиле пользователя'",

  "TIMER.JIRA_SYNC_SUCCESS":
    "Тестовое соединение с Jira было успешно установленно.",
  "ERROR.TIMER.JIRA_SYNC_FAILED":
    "Тестовое соединение с Jira не было установлено. Убедитесь, что введенные данные верны.",

  "SUCCESS.USER.RESET_EMAIL_CHECK":
    "Проверьте почтовый ящик на наличие кода для сброса пароля.",
  "SUCCESS.USER.RESET_PASSWORD": "Вы успешно сбросили пароль.",
  "SUCCESS.USER.PASSWORD_CHANGED": "Вы успешно изменили пароль.",
  "SUCCESS.USER.UPDATE_USER": "Пользователь был успешно обновлен.",
  "ERROR.CHECK_REQUEST_PARAMS":
    "Внутренняя ошибка при отправке запроса на сервер.",
  "ERROR.INTERNAL":
    "Извините, что-то пошло не так. Пожалуйста, попробуйте позже.",
  "ERROR.NOT_AUTHORIZED": "Неавторизованный запрос.",
  "ERROR.PROJECT.UPDATE_FAILED": "Произошла ошибка при обновлении проекта.",
  "ERROR.TEAM.CREATE_FAILED": "Не удалось создать команду.",
  "ERROR.TEAM.SWITCH_FAILED": "Произошла ошибка при переключении команды.",
  "ERROR.TEAM.RENAME_FAILED": "Произошла ошибка при переименовании команды.",
  "ERROR.TIMER.UPDATE_FAILED": "Произошла ошибка при обновлении таймера.",
  "ERROR.TIMER.DELETE_FAILED": "Произошла ошибка при удалении таймера",
  "ERROR.USER.NOT_MEMBER": "Пользователь не состоит ни в одной команде.",
  "ERROR.USER.RESET_EMAIL_FAILED":
    "Произошла ошибка при отправке электронного письма для сброса пароля.",
  "ERROR.USER.CURRENT_PASSWORD_WRONG": "Текущий пароль неправильный.",
  "ERROR.USER.CHANGE_PASSWORD_FAILED": "Произошла ошибка при смене пароля.",
  "ERROR.USER.EMAIL_PASSWORD_WRONG":
    "Неверный адрес электронной почты или пароль.",
  "ERROR.USER.CREATE_INVITE_FAILED":
    "Произошла ошибка при создании приглашения пользователя.",
  "ERROR.USER.ALREADY_TEAMMATE": "Пользователь уже состоит в команде.",
  "ERROR.USER.EMAIL_EXISTS": "Электронная почта существует.",
  "ERROR.USER.CREATE_USER_FAILED":
    "Произошла ошибка при создании пользователя.",
  "ERROR.USER.UPDATE_USER_PERMISSIONS_DENIED":
    "У вас нет разрешения на обновление пользователя.",
  "ERROR.USER.UPDATE_USER_FAILED":
    "Произошла ошибка при обновлении пользователя.",
  "ERROR.USER.UPDATE_USER_EMAIL_EXISTS":
    "Этот адрес электронной почты уже существует.",
  "ERROR.USER.UPDATE_USER_ROLE_FAILED":
    "Вы не сможете изменить роль в своей команде.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ROLE_FAILED":
    "Вы не можете изменить роль владельца команды.",
  "ERROR.USER.UPDATE_ACTIVE_STATUS_FAILED":
    "Вы не можете установить свою учетную запись как неактивную в своей собственной команде.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ACTIVE_STATUS_FAILED":
    "Вы не можете установить учетную запись владельца команды как неактивную.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Пользователь был успешно удален из команды.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_SUCCEED":
    "Пользователь был успешно удален из команды.",
  "ERROR.USER.DELETE_USER_FROM_TEAM_FAILED":
    "Произошла ошибка при удалении из команды.",

  v_a_avatar_upload_error:
    "Ошибка загрузки! Загружаемый файл не является изображением или его размер превышает 1 Мб.",
  v_a_data_updated_ok: "Ваши данные были обновлены",
  v_a_change_password_great_ok: "Великолепно! Теперь вы можете войти снова!",
  v_a_change_password_ok: "Вы успешно изменили пароль!",
  v_a_password_same_error: "Пароли не совпадают!",
  v_a_invite_sent: "Приглашение отправлено!",
  v_a_invite_sent_error:
    "Произошла ошибка при отправке приглашения пользователю!",
  v_a_invite_sent_user_error:
    "Что-то пошло не так... Удалите пользователя из списка 'Запрещен' и снова пригласите",
  v_a_project_created: "Проект создан",
  v_a_project_updated: "Проект обновлен",
  v_a_project_existed: "Проект уже существует",
  v_a_project_created_error: "Проект не может быть создан",
  v_a_project_no_valid_name: "Имя проекта не может содержать '%'",
  v_a_project_created_synchronization_with_jira_error:
    "Проект Jira уже подключен к проекту",
  v_a_team_name_empty_error: "Имя команды не может быть пустым!",
  v_a_project_edit_error: "Проект не может быть отредактирован",
  v_a_time_start_error: "Неправильное время начала, пожалуйста, проверьте его!",
  v_a_time_end_error: "Неправильное время конца, пожалуйста, проверьте его!",
  v_a_time_already_busy:
    "Задача не может пересекаться по времени с другой задачей",
  v_a_time_future_time_error: "Задачу нельзя записывать на будущее время",
  v_a_time_already_busy_current_task: "Это время уже занято текущей задачей",
  v_a_task_name_error: "Пожалуйста, введите имя задачи перед сохранением",
  v_a_team_existed: "Команда уже существует",
  v_a_team_rename_error: "Команда не может быть переименована",
  v_a_team_create_error: "Команда не может быть создана",
  v_a_task_name_before: "Пожалуйста, введите название задачи перед",
  v_a_project_before: "Пожалуйста, выберите проект перед",
  v_a_select_project: "Выберите проект",
  v_a_starting: "запуском",
  v_a_stopping: "остановкой",
  v_a_time_tracking: "времени",
  v_a_account_create: "Аккаунт создан",
  v_a_confirm_password_error: "Неверное подтверждение пароля",
  v_a_project_name_error: "Название проекта не может быть пустым",
  v_a_task_delete: "Вы действительно хотите удалить эту запись?",
  v_a_incorect_email: "Неверно указан e-mail",
  v_a_error_authorization: "Что-то пошло не так. Попробуйте позже",
  v_a_incorect_password: "Введите не менее 6 символов",
  v_a_client_existed: "Клиент уже существует",
  v_a_client_name_empty_error: "Имя клиента не может быть пустым!",

  v_set_default: "выбрать",
  v_default: "по умолчанию",
  v_help: "Помощь",
  v_upload_image: "Загрузить изображение",
  v_delete_image: "Удалить изображение",
  v_phone: "Телефон",
  v_more_option: "Больше опций",
  v_active_team: "Активная команда",
  v_set: "Установить",
  v_team_is_active: "команду активной",
  v_ok: "ПРИМЕНИТЬ",
  v_cancel: "Отмена",
  v_language: "Язык",
  v_cofirm_new_password: "Подтвердите новый пароль",
  v_current_password: "Текущий пароль",
  v_save_changes: "Сохранить",
  v_your_name: "Имя",
  v_my_profile: "Профиль",
  v_profile_settings: "Настройки",
  v_new_team: "Новая команда",
  v_change_password: "Изменить пароль",
  v_new_password: "Новый пароль",
  v_send: "Отправить",
  v_check_email:
    "Если ваш email зарегистрирован в Wobbly проверьте вашу почту и следуйте инструкции в письме",
  v_check_email_error: "Ваше письмо не было отправлено!",
  v_enter_email: "Введите email",
  v_create_team: "Создать команду",
  v_search_project: "Поиск проекта",
  v_search_client: "Поиск клиента",
  v_start_timer: "Запустить таймер",
  v_edit_task: "Изменить",
  v_delete_task: "Удалить",
  v_add_confirm_password: "Подтвердите пароль",
  v_registration_terms_and_policy:
    "При регистрации вы соглашаетесь с условиями и политикой конфиденциальности",
  v_login: "Логин",
  v_log_in: "Войти",
  v_email: "Электронная почта",
  v_email_error:
    "Слишком длинная электронная почта, доступно только 72 символа",
  v_register: "Зарегистрироваться",
  v_password: "Пароль",
  v_unsubscribe: "Отписаться",
  v_sure: "Вы действительно хотите отписаться от рассылки?",
  v_success_unsubscribe: "Пользователь отписался от рассылки",
  v_error_already_unsubscribe: "Пользователь уже отписан от рассылки",
  v_sure_reports: "Вы действительно хотите отписаться от рассылки отчетов?",
  v_success_unsubscribe_reports: "Пользователь отписался от рассылки отчетов",
  v_error_already_unsubscribe_reports:
    "Пользователь уже отписан от рассылки отчетов",
  v_enter: "Вход",
  v_incorrect_password_or_email:
    "Ваш адрес электронной почты или пароль неверны. Попробуйте еще раз.",
  v_cofirm_password: "Подтверждение пароля",
  v_forgot_your_password: "Восстановить пароль",
  v_dont_have_an_account_yet: "До сих пор нет аккаунта",
  v_already_have_an_account: "Уже есть аккаунт",
  v_sign_up: "Зарегистрируйтесь",
  v_timer: "Таймер",
  v_reports: "Отчет",
  v_report_header: "Отчет:",
  v_summary: "Общий",
  v_projects: "Проекты",
  v_project: "Проект",
  v_people: "Люди",
  v_description: "Описание",
  v_teams: "Команды",
  v_bench: "Скамейка",
  v_team: "Команда",
  v_add_team: "Новая команда",
  v_add_your_password: "Введите пароль",
  v_my_team: "Моя команда",
  v_total_time: "Общее время",
  v_task_name: "Название задачи",
  v_time_start: "Время начала",
  v_time_end: "Время конца",
  v_change: "Изменить",
  v_last_change: "Последнее изменение",
  v_create_new_project: "Создать новый проект",
  v_apply: "Применить",
  v_edit_project: "Редактировать",
  v_time: "Время",
  v_create_project: "Создать проект",
  v_log_out: "Выход",
  v_add_user: "Отправить запрос",
  v_invite_to_team: "Пригласить в команду",
  v_team_role: "Роль",
  v_edit_user: "Редактировать пользователя",
  v_name: "Имя",
  v_surname: "Фамилия",
  v_edit_team_name: "Изменить имя команды",
  v_rename_team: "Изменить имя",
  v_team_roles: "Роли",
  v_summary_report: "Общий отчет",
  v_total: "Всего",
  v_total_time: "Общее отслеживаемое время",
  v_total_billable: "Оплачиваемое",
  v_total_not_billable: "Не оплачиваемое",
  v_export: "Экспорт",
  v_archivate: "Aрхивировать",
  v_unarchivate: "Разархивировать",
  v_detailed_proj: "Детально",
  v_detailed_export: "Подробный отчёт",
  v_simple_export: "Обобщенный отчет",
  v_user: "Пользователь",
  v_select_all: "Выбрать всех",
  v_select_none: "Убрать всех",
  v_project_name: "Имя проекта",
  v_issue: "Задача",
  v_sum_tasks: "Задачи",
  v_sum_time: "Общее время",
  v_user_name: "Имя пользователя",
  v_add_your_email: "Введите электронную почту",
  v_add_your_task_name: "Введите название задачи",
  v_find: "Поиск",
  v_back: "Назад",
  v_team_name: "Название команды",
  v_team_access: "Доступ",
  v_connection_problem: "Потеряно интернет соединение",
  v_connection_restored: "Соединение восстановлено",
  v_all_role: "Все роли",
  v_all_access: "Все доступы",
  v_all: "Все",
  v_active: "Разрешен",
  v_not_active: "Запрещен",
  v_today: "Сегодня",
  v_yesterday: "Вчера",
  v_thisWeek: "Текущая неделя",
  v_lastWeek: "Прошлая неделя",
  v_thisMonth: "Текущий месяц",
  v_lastMonth: "Прошлый месяц",
  v_this_year: "Текущий год",
  v_last_year: "Прошлый год",
  v_days_up_to_today: "дней до сегодня",
  v_days_starting_today: "дней от сегодня",
  v_jira_synchronization: "Jira синхронизация",
  v_type: "тип",
  v_chart_label_total: "Всего часов по дате",
  v_enter_to: "Ввойдите в",
  v_to_get_token: "для получения Api токена",
  v_login_with: "Войти через",
  v_or: "или",
  v_or_continue_with: "или продолжить с",
  v_date_format: "Формат даты",
  v_first_day_of_week: "Первый день недели",
  v_time_format: "Формат времени",
  v_duration_display_format: "Формат отображения длительности",
  v_switch_team_to_the: "Команда изменилась на",
  v_show: "показать",
  v_hide: "скрыть",
  v_clients: "Клиенты",
  v_client: "Клиент",
  v_add_new_client: "Добавить нового клиента",
  v_client_name: "Имя клиента",
  v_add_client: "Добавить клиента",
  v_edit_client_name: "Редактировать имя клиента",
  v_edit_client: "Редактировать",
  v_select_client: "Выберите клиента",
  v_sync_with_jira_project: "Синхронизация с проектом Jira",
  v_sync_with_jira_project_exist:
    "Проект Jira уже подключен к проекту {{projectName}}",
  v_jira_synchronization_problem:
    "Задачи из Jira не были синхронизированы. Убедитесь, что доступы к Jira верны",
  v_jira_synchronization_ok: "Задачи были успешно синхронизированы из Jira",
  v_add_project_name: "Добавьте название проекта",
  v_edit_project_name: "Измените имя проекта",
  v_verify: "Проверить",
  v_classic: "Классический",
  v_improved: "Улучшенный",
  v_decimal: "Десятичный",
  v_short_day: "ДД",
  v_short_month: "ММ",
  v_short_year: "ГГГГ",
  v_empty: "Пусто",
  v_empty_email: "Требуется электронная почта",
  v_empty_password: "Требуется пароль",
  v_cofirm_password_required: "Требуется подтверждение пароля",
  v_add_old_password: "Введите текущий пароль",
  v_add_new_password: "Введите новый пароль",
  v_jira_synchronization_confirm:
    "Вы действительно хотите выполнить синхронизацию?",
  v_add_member: "Добавить участника",
  v_member_dots: "Участник...",
  v_combine: "Объединить задачи",
  v_delete_user: "Удалить пользователя",
  v_delete_user_text:
    "Пользователь будет удален навсегда. Все команды и записи будут потеряны. Вы уверены, что хотите удалить?",
  v_user_deleted: "Пользователь удален",

  v_v_required: "Обязательное поле",
  v_v_incorect_url: "Неверный URL",
  v_no_report: "Нет отчета для отображения!",
  v_no_entries: "Нет записей для отображения.",
  v_no_entries_sub:
    "Прошло много времени с тех пор, как вы записывали свои задачи!",
  v_no_projects: "У текущей команды нет проектов для вас",
  v_no_projects_sub:
    "Попросите администратора вашей команды добавить вас в проект",
  v_no_clients: "Нет клиентов для отображения!",

  v_enter_number: "Число",

  v_invoices: "Счета",
  v_cat_not_invoices: "У вас еще нет счетов с таким статусом",
  v_add_new_invoice: "Новый Счет",
  v_all_invoices: "All invoices",
  v_invoice: "Счет",
  v_confirm_payment: "Подтвердить оплату",
  v_cancel_confirm: "Отменить подтверждение",
  v_send_invoice: "Отправить счет",
  v_from: "От",
  v_to: "Кому",
  v_amount: "Кол-во",
  v_rate: "Ставка",
  v_tax: "Налог",
  v_subtotal: "Итог",
  v_enter_project: "Текст",
  v_pic_tax: "Налог",
  v_payment_confirmation_window_confirm:
    "Вы уверены, что хотите подтвердить платеж?",
  v_payment_confirmation_window_cancel:
    "Вы уверены, что хотите отменить платеж?",
  v_edit_confirmation_window:
    "Счет уже был отослан клиенту, вы уверены что хотите отредактировать?",
  v_client_no_email: "Электронная почта клиента не заполнена",
  v_letter_send: "Счет отправлен",
  v_add_part_of_payment: "Добавить платеж",
  v_added_payments: "Добавлены платежи",
  v_date: "Дата",
  v_currency: "Валюта",
  v_outstanding: "Остаток",
  v_add_a_payment: "Добавить платеж",
  v_sum: "Сумма",
  v_add_payment: "Добавить платеж",
  v_no_zero_error: "Не может быть нулевым",
  v_no_negative: "Не может быть отрицательным",
  v_not_less: "Сумма должна быть меньше или равна невыплаченной",
  v_no_payments: "Платежей нет. Нажмите «+», чтобы добавить",
  v_no_active_invoices: "Нет активных счетов",
  v_no_active_invoices_text:
    "В системе не найдено активных счетов. Это может означать, что все счета были оплачены или что счетов нет вообще.",
  v_create_new_invoice: "Создать Новый Счет",

  v_invoice_summary: "Общий Счет",
  v_invoice_reference: "Сноска",
  v_comments: "Комментарии",
  v_select_currency: "Выберите Валюту",
  v_invoice_number: "Номер Счета",
  v_invoice_date: "Дата Счета",
  v_invoice_due: "Дата Платежа",
  v_edit: "Редактировать",
  v_clone: "Дублировать",
  v_delete: "Удалить",
  v_download: "Скачать",
  v_pic_date: "Pic Date",
  v_address: "Адрес",
  v_add_sender: "Добавить отправителя",
  v_send_by_email: "Отправить по email",
  v_choose_recipient: "Choose recipient",
  v_select_logo_file: "Перетяните свой логотип сюда или выберите файл",
  v_send_invoice_placeholder: "placeholder",
  v_no_overdues: "Нету просроченных",
  v_no_total: "Нету ожидающих",
  v_no_drafts: "Нету созданных",
  v_last_invoices: "Последние счета",
  v_clear_filters: "Очистить фильтры",
  v_filter_all_projects: "Все проекты",
  v_filter_all_clients: "Все клиенты",
  v_filter_all_invoices: "Все счета",
  v_filter_active: "Активные",
  v_filter_archived: "Архивные",
  v_filter_draft: "Черновые",
  v_filter_awaiting: "В ожидании",
  v_filter_reviewed: "Просмотренные",
  v_filter_paid: "Оплаченные",
  v_filter_overdue: "Просроченные",
  v_grand_total: "Общий итог остатка:",
  v_changing_status: "Сменить статус на",

  v_will_generate: "Будет сгенерировано",
  v_save: "Сохранить",
  v_overdue: "Просрочено",
  v_total_outstanding: "Общая сумма",
  v_draft: "Черновик",
  v_paid: "Оплачено",
  v_paid_full: "Оплачено полностью",
  v_paid_zero: "Нет платежей",
  v_reviewed: "Просмотрено. Изменить статус?",
  v_overdue_tip: "Просрочено. Изменить статус?",
  v_draft_tip: "Черновик. Изменить статус?",
  v_paid_tip: "Оплаченно. Изменить статус?",

  v_delete_member: "Удалить участника",
  v_clone_invoice: "Ваш счет был скопирован",
  v_send_payment: "Отправить счет",
  v_no_invoices: "Нет счетов, чтобы показать",
  v_owner: "Владелец",
  v_admin: "Администратор",
  v_manager: "Менеджер счетов",
  v_hr_manager: "HR менеджер",
  v_proj_manager: "Менеджер проекта",
  v_recruiter: "Рекрутер",
  v_member: "Участник",
  v_roles: "Роли",
  v_country: "Страна",
  v_city: "Город",
  v_state: "Область",
  v_zip: "Индекс",
  v_address_lowC: "адрес",
  e_mail: "Эл. почта",

  v_enter_text: "Введите текст",
  v_technologies: "Технологии",
  v_add_technology: "Добавить",
  v_company_name: "Название компании",

  client_was_deleted: "Клиент был удален",
  client_was_edited: "Клиент был отредактирован",
  client_was_created: "Клиент был создан",
  client_dont_was_deleted:
    "Вы не можете удалить клиента, т.к. на нем есть закрепленные счета. Пожалуйста, удалите счета связанные с ним и повторите попытку!",

  v_select_logo_file_bigger: "Перетяните фото сюда или выберите файл",
  v_zip_code: "Почтовый индекс",
  v_enter_country: "Введите страну",

  v_street: "Улица",
  v_enter_city: "Введите город",
  v_enter_language: "Выберите язык",
  v_delete_client: "Удалить клиента",
  v_enter_state: "Введите область ",
  company_name: "Название компании",
  full_name: "Имя/фамилия",

  select_country: "Выберите страну",

  v_edit_client_title: "Редактировать клиента",
  no_valid_number: "Номер телефона недействителен",
  no_valid_zip: "Неверный почтовый индекс",
  no_valid_user_name: "Поле Ім'я/прізвище не може містити пробіли",
  no_valid_number_mask: "+XXXXXXXXXXXX",
  v_fill_fields: "Пожалуйста, заполните все обязательные поля",
  v_fill_fields_company_name:
    "Пожалуйста, укажите название компании в поле отправителя",
  v_tags: "Теги",
  v_tags_tooltip: "Навыки, технологии и др.",
  v_cv: "Резюме пользователя",
  v_edit_sender: "Изменить отправителя",
  v_copy: "Копировать",
  v_delete_invoice: "Удалить Счет",
  v_prompt: "Вы уверены, что хотите удалить счет?",
  v_confirm: "Подтвердите",
  v_invoice_number_exist: "Номер счета существует",
  v_awaiting: "В ожидании.Изменить статус?",
  v_price: "Сума",
  v_status: "Статус",
  v_physical_address: "Физический адрес",
  v_error_company_name: "Заполните поле - Название компании",
  v_time_date: "Время и дата",
  v_err_technology_exist: "Тег уже добавлен",

  v_search: "Поиск",
  v_reset: "Сброс",
  v_sync_all: "Синхронизировать все",
  v_sync_success: "Синхронизация успешна",
  v_result: "Результат",

  v_support: "Поддержка",
  v_tell_us: "Расскажите нам о своей проблеме",
  v_type_message: "Введите сообщение ...",
  v_min: "Должно быть не менее 8 символов",
  v_max: "Должно быть менее 500 символов",
  v_message_error: "Ошибка: Письмо не может быть пустым",
  v_message_sent: "Сообщение отправлено успешно",

  v_copy_and_share: "Скопировать и поделиться ссылкой",
  v_show_more: "Показать больше",
  v_copy_the_link: "Скопировать Cсылку",
  v_copy_link: "Скопировать Cсылку",
  v_done: "Готово",
  v_link_copied: "Ссылка скопирована",
  v_add_a_discount: "Добавить скидку",
  v_add_discount: "Добавить скидку",
  v_discount: "Скидка",
  v_discount_subtotal: "от суммы промежуточного счета",
  v_round_to: "Округлить на:",
  v_report_round: "Десятичное округление отчета",
  v_decimal_report: "Десятичный отчет",
  v_minutes: "минут",

  v_info: {
    timeTracking: "Отслеживание времени",
    resourcePlanning: "Планирование ресурсов",
    reporting: "Отчетность",
    realTimeReports: "Отчеты в реальном времени",
    invoicing: "Выставление счетов",
    owner: "Собственник",
    admin: "Админ",
    member: "Член",
    invoiceManager: "Менеджер счетов",
  },

  v_extra: {
    price: "€5/мес",
    feature1: "+1 клиент",
    feature2: "+1 команда",
    feature3: "+2 проэкта",
    star: "*одноразовая возможность",
  },

  v_choose: "Choose your plan",

  v_coupon_doesnt_exist: "Этот купон не существует",
  v_coupon_expired: "Этот купон просрочен и не может быть применен",
  v_coupon_used_up: "Этот купон израсходован и не может быть применен",

  v_rp_history: {
    historyS: "история",
    historyL: "История",
    header: {
      when: "Дата изменения",
      who: "Редактор",
      type: "Тип изменения",
    },
    table: {
      created: "Создан",
      updated: "Обновлен",
      from: "С: ",
      to: "На: ",
      plan: "Имя плана",
      period: "Период плана",
      project: "Имя проекта",
      user: "Назначено",
      description: "Описание",
    },
  },

  v_hr_people: {
    leftBar: "Рекрутинг",
    header: {
      title: "Рекрутинг",
      board: "Направления",
      applications: "Заявки",
      vacancies: "Вакансии",
      candidates: "Кандидаты",
    },
    candidates_modal: {
      preview: "предварительный просмотр",
      draft: "черновик",
      edit: "редактировать",
      personal: "Личная информация",
      social: "Социальные сети",
      start_work: "Возможность начать работу",
      about: "О кандидате",
      booking: "Бронирование встречи и обратной связи",
      jobType: "Тип работи",
      other: "Другое",
      desire: "Желаемая дата",
      start: "Начало работы",
      experience: "Опыт",
      cv: "CV",
      cvClient: "Резюме для клиента",
      level: "Уровень",
      position: "Должность",
      salary: "Зарплата",
      hrInt: "Дата собеседования с HR",
      techInt: "Дата технического собеседования",
      feedHr: "Обратная связь от HR",
      feedTech: "Обратная связь по тех части",
      namePlace: "Введите имя...",
      surnamePlace: "Введите фамилию...",
      emailPlace: "Введите e-mail...",
      countryPlace: "Выберите страну",
      birthdayPlace: "Выберите дату своего дня рождения",
      otherPlace: "Другие ссылки и т.д.",
      desirePlace: "Желаемая дата работы",
      startPlace: "Дата начала работы",
      cvPlace: "Прикрепить резюме",
      direction: "Направление",
      vacancy: "Вакансия",
      noVacancies: "Нет вакансий",
      minSalPlace: "От",
      maxSalPlace: "Дo",
      hrIntPlace: "HR-собеседование",
      techIntPlace: "Техническое собеседование",
      feedHrPlace: "Введите отзыв от HR...",
      feedTechPlace: "Введите технический отзыв...",
    },
    directions: {
      no_directions_text: "Пока нет направлений",
      new_direction: "Новое направление",
      search_by_name_placeholder: "Поиск по имени...",
    },
    candidates: {
      new_candidates: "Новые кандидаты",
      no_candidates_text: "Кандидатов пока нет",
      headerTable: {
        name: "Имя",
        job_type: "Тип работы",
        country: "Страна",
        city: "Город",
        level: "Уровень",
        direction: "Направление",
        salary: "Зарплата:",
        latest_update: "Последнее обновление",
        creation_date: "Дата создания",
        junior: "Младший",
        middle: "Средний",
        senior: "Старший",
        trainee: "Стажер",
        remote: "Удаленный",
        hybrid: "Гибридный",
        office: "Офис",
      },
    },
    switcherForVacancies: {
      active: "Active",
      archive: "Archive",
    },
    vacancies: {
      no_vacancies_text: "Вакансий пока нет",
      new_vacancies: "Новые вакансии",
      vacancies_created: "Вакансия создана",
      vacancies_edited: "Вакансия отредактирована",
      link_copied: "Ссылка скопирована",
      vacancy_data: "Данные вакансии",
      job_application: "Заявка на вакансию",
      select_job_application: "Выберите заявку на вакансию",
      vacancy_name: "Название вакансии",
      company: "Компания",
      type_here: "Введите здесь...",
      priority: "Приоритет",
      choose_status: "Выберите статус",
      choose_priority: "Выберите приоритет",
      direction: "Направление",
      experience: "Опыт",
      level: "Уровень",
      job_description: "Описание вакансии",
      type_text_here: "Введите текст здесь...",
      number_of_positions: "Количество позиций",
      type_of_employment: "Тип занятости",
      expected_salary: "Ожидаемая зарплата",
      job_location: "Местоположение вакансии",
      location_optional: "Местоположение (опционально)",
      job_type: "Тип работы",
      remote: "Удаленная работа",
      hybrid: "Гибридная работа",
      office: "Офис",
      foreign_languages: "Иностранные языки",
      additional_information: "Дополнительная информация",
      link_to_job: "Ссылка на вакансии (на разных платформах)",
      requirements: "Требования",
      nice_to_have: "Плюсом будет",
      working_conditions: "Условия работы",
      preview: "просмотр",
      responsibilities: "Обязанности",
      salary_range: "Диапазон зарплат",
      exit: "Выход",
    },
    vacanciesHeaderTable: {
      name: "Название вакансии",
      status: "Статус",
      priorities: "Приоритеты",
      number_of_candidates: "Количество кандидатов",
      latest_update: "Последнее обновление",
      deadline: "Крайний срок",
      created: "Созданный",
    },
    new_direction_modal: {
      edit: "Редактировать направление",
      create: "Создать направление",
      name_dir: "Название направления",
      name_placeholder: "Введите, чтобы добавить направление...",
      vacancies: "Прикрепленные вакансии",
      vacancies_placeholder: "Выбрать или добавить",
    },
    applications: {
      no_applications_text: "Пока нет ни одной заявки",
      new_applications: "Новая заявка",
      archive: "Архив",
      name: "Имя",
      typeName: "Введите имя...",
      direction: "Направление",
      typeDirection: "Введите направление...",
      level: "Уровень",
      typeLevel: "Введите уровень...",
      experience: "Опыт",
      typeExperience: "Введите опыт...",
      salary: "Зарплата",
      from: "От",
      to: "До",
      deadline: "Срок",
      invalidDate: "Неверная дата",
      applicationPriority: "Приоритет заявления...",
      country: "Страна",
      searchCountry: "Искать страну...",
      selectCountry: "Выберите страну",
      city: "Город",
      typeEmployment: "Тип занятости",
      selectEmployment: "Выберите тип занятости",
      numberPositions: "Количество позиций",
      typeNumberPositions: "Введите количество позиций",
      project: "Проект",
      typeProject: "Введите название проекта",
      languageSkills: "Знание языков",
      enterLanguagesSkills: "Введите знание языков",
      requirements: "Требования",
      enterRequirements: "Введите требования",
      comment: "Комментарий",
      enterComment: "Введите комментарий",
      successful: "Успешно",
      youHaveSuccessful: "Вы успешно создали приложение",
    },
    headerTable: {
      name: "Название",
      vacancies_available: "Имеющиеся вакансии",
      priorities: "Приоритеты",
      number_of_candidates: "Количество кандидатов",
      latest_update: "Последнее обновление",
      deadline: "Крайний срок",
      name_applications: "Имя заявки",
      location: "Расположение",
      date_of_creation: "Дата создания",
    },
    prioritiesSelectors: {
      high: "Высокий",
      medium: "Средний",
      low: "Низкий",
    },
    deadlineSelectors: {
      newest: "Самый новый",
      oldest: "Самый старый",
    },
    statusSelectors: {
      open: "Открыто",
      closed: "Закрыто",
      rejected: "Отклоненно",
      onHold: "На удерживании",
    },
  },

  v_subscription: {
    extraFeatures: "Купить дополнительные функции",
    leftBar: "Подписки",
    header: {
      all: "Все планы",
      overview: "Обзор",
      invAndPaym: "Счета и платежи",
    },

    switcher: {
      trial: "-day free trial included",
      month: "Month",
      year: "Annual",
      discount: "Save up to 20%",
    },

    plan: {
      btnCurrent: "Current plan",
      btnUpgrade: "Upgrade",
      btnDowngarde: "Downgrade",
      mo: "mo per user",
      yr: "mo billed annually",
      btnUpgradeNow: "Upgrade now",
    },

    free: {
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "2 Projects",
      history: "35 days data history",
      clients: "1 Client",
      teams: "1 Team",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Data storage 1 GB",
    },

    basic: {
      teams: "10 Teams",
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "10 Projects",
      history: "365 days data history",
      clients: "10 Clients",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Unlimited data Storage",
    },
    donut: {
      teams: "15 Команд",
      tracks: "Неограниченное отслеживание времени",
      projects: "15 Активных проектов",
      history: "до 365 дней хранения истории",
      clients: "15 клиентов",
      invoices: "Счет-фактура включена",
      integrations: "Включены интеграции",
      storage: "10 GB данных",
    },

    pro: {
      teams: "Unlimited teams",
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "Unlimited projects",
      history: "Unlimited data history",
      clients: "Unlimited clients",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Unlimited data Storage",
    },
    billingModalDowngrade: {
      title: "Обзор подписки",
      currentPlan: "Ваш текущий план",
      pait: "проплачен до",
      text: "план будет переключен на новый план после истечения срока действия вашей текущей подписки",
      button: "Продолжать",
    },
    billingModal: {
      next: "Далее",
      startExploring: "Начать изучение",
      trialHasNotExpired:
        "Вы еще не полностью израсходовали 'Пробное время', оно будет потеряно, если вы продолжите. Вы хотите сделать покупку сейчас?",
      secondStep: {
        title: "Обзор подписки",
        plan: "План",
        firstCharge: "Первая оплата",
        billingInfo: "Информация для счёта",
        changeBilling: "Изменить",
        paymentInfo: "Платёжная информация",
        changePayment: "Изменить",
      },
      congratsStep: {
        congrats: "Поздравляем!",
        info: "Теперь вы можете использовать все функции, в том числе",
      },
      noData: "Пожалуйста, заполните платёжную информацию",
    },
    billingInfoEditModal: {
      title: "Платежная информация",
    },
    overview: {
      trialWarning:
        "Пожалуйста, добавьте платёжную информацю, чтобы продолжить использование {{planName}} после пробного периода.",
      trialAddPaymentInfo: "Добавить платёжную информацию",
      updateBillingInfo: "Обновить",
    },
    invoicesAndPayments: {
      title: "История платежей",
    },
  },
  v_plan_details: {
    subscriptionExpired:
      "Your plan will be changed to 'Free' after your current subscription expires   ",
    subscriptionExpiredTrial:
      "Your plan will be changed to 'Free' after your current trial subscription expires   ",
    // "Ваш тарифный план будет изменен на 'Free' после истечения срока действия текущей подписки   ",
    status: "Статус подписки",
    subscriptionRenewingOn: "Ваша подписка будет обновлена",
    subscriptionRenewingOnFree: "Free",
    currentPlan: "Текущий план",
    costUserMonth: "Цена пользователь/месяц",
    users: "Пользователи",
    daysLeftOnTrial: "Дней на пробном периоде осталось",
  },
  v_billing_info: {
    title: "Счёт",
    changeBillingInfo: "Обновить информацию",
  },
  v_billing_info_details: {
    country: "Страна",
    city: "Город",
    postalCode: "Почтовый индекс",
    emailAddress: "E-mail",
    street: "Улица",
    houseNumber: "Номер дома",
    firstName: "Имя",
    lastName: "Фамилия",
    companyName: "Название компании",
    phoneNumber: "Номер телефона",
  },
  v_payment_info: {
    title: "Оплата",
    addPaymentInfo: "Добавить информацию",
    changePaymentInfo: "Обновить информацию",
  },
  v_payment_method_details: {
    cardDeclined: "Ваша карта не поддерживается!",
    paymentMethod: "Метод оплаты",
    noPaymentMethod: "Нет доступных методов оплаты",
    labels: {
      country: "Страна",
      expiryMonth: "Месяц истечения",
      expityYear: "Год истечения",
      cardHolder: "Держатель карты",
      masked: "PAN карты",
      holder: "Держатель",
      bic: "BIC",
      iban: "IBAN",
    },
    methods: {
      CreditCard: "Кредитная карточка",
      BankAccount: "Банковский счёт",
    },
  },
  v_billing_history_table: {
    date: "Дата",
    description: "Описание",
    amount: "Сумма",
    invoice: "Счёт",
  },
  v_payment_info_modal: {
    next: "Далее",
  },
  v_payment_info_state: {
    updateSuccess: "Платёжная информация была успешно обновлена",
    updateError: "Ошибка обновления платёжной информации",
  },
  v_billing_info_form: {
    country: { label: "Страна", placeholder: "Выберите страну" },
    city: { label: "Город", placeholder: "Введите город" },
    postalCode: { label: "Почтовый индекс", placeholder: "Введите индекс" },
    emailAddress: { label: "E-mail", placeholder: "Введите email" },
    street: { label: "Улица", placeholder: "Введите улицу" },
    houseNumber: { label: "Номер дома", placeholder: "Введите номер дома" },
    firstName: { label: "Имя", placeholder: "Введите имя" },
    lastName: { label: "Фамилия", placeholder: "Введите фамилию" },
    companyName: {
      label: "Название компании",
      placeholder: "Введите название компании",
    },
    phoneNumber: {
      label: "Номер телефона",
      placeholder: "Введите номер телефона",
    },
  },
  v_billing_info_state: {
    updateSuccess: "Информация для счёта была успешно обновлена",
    updateError: "Ошибка обновления информации для счёта",
  },
  v_upgrade_pay_state: {
    updateSuccess: "Подписка успешно обновлена",
    updateError: "Ошибка обновления подписки",
  },
  v_page_access_denied: "У вас недостаточно прав для просмотра этой страницы",
  v_page_access_request_owner:
    "Попросите владельца команды предоставить вам доступ",
  v_page_invoice_deleted: "Этот счет был удален",
  v_project_not_available: "Проект для этой задачи недоступен",
  v_upgrade_projects_title: "Улучшите план чтобы создавать больше проектов",
  v_upgrade_projects_description:
    "К сожалению, вы достигли максимального количества активных проектов по вашему плану.",
  v_upgrade_teams_title: "Улучшите план чтобы создавать больше команд",
  v_upgrade_teams_description:
    "К сожалению, вы достигли максимального количества команд по вашему плану.",
  v_upgrade_button: "Улучшить подписку",
  v_upgrade_sidebar_title_trial_banner: "Вы используете пробную версию",
  v_upgrade_sidebar_title_trial_banner_coupon: "Последний день пробной версии",
  v_upgrade_sidebar_text_trial_banner: "дней осталось",
  v_upgrade_sidebar_text_trial_banner_few_days: "день остался",
  v_last_trial_day: "Последний пробный день",
  v_last_trial_day_with_coupon: "Улучшите свой план с помощью купона",
  v_upgrade_sidebar_title: "Улучшите план для дополнительных функций.",
  v_upgrade_sidebar_button: "Улучшить",
  v_apply_sidebar_button: "Применить код",
  v_upgrade_clients_title: "Улучшите план чтобы создавать больше клиентов",
  v_upgrade_clients_description:
    "К сожалению, вы достигли максимального количества клиентов по вашему плану.",
  v_upgrade_admin_note:
    "Если вы хотите обновить свой тарифный план, обратитесь к владельцу команды.",
  v_no_results: "Нет результатов, чтобы показать",
  v_project_members: "Участники проекта",
  v_upgrade_label: "Обновите свой план",
  v_resource_planning: "Планирование ресурсов",
  v_collapse: "Collapse",
  v_expand: "Expand",
  v_add_plan: "Добавить План",
  v_delete_plan: "Удалить План",
  v_delete_plan_confirm: "Вы уверены, что хотите удалить план?",
  v_edit_plan_confirm: "Вы уверены, что хотите отредактировать план?",
  v_no_recorded_team_time: "Нет записанного времени в команде",
  v_not_active_user: "Не активный",
  v_real_time_report: "Отчет в режиме реального времени",
  v_rt_reports_plug: {
    title:
      "Управляйте расписанием сотрудников в режиме реального времени с помощью программного обеспечения для составления расписания работы.",
    description:
      "Получение информации о состоянии проекта и его загруженности - отчеты в реальном времени для эффективного управления задачами и проектами.",
  },
  v_rp_plug: {
    title:
      "Упрощение планирования работы команды с помощью планирования ресурсов",
    description:
      "Отслеживание общей рабочей нагрузки для эффективного распределения ресурсов. Нужны дополнительные возможности?",
  },
  v_see_plans: "Посмотреть Планы",
  v_tooltip_upgrade: "Эта функция доступна при обновлении пакета",
  v_real_time: "В режиме реального времени",
  v_activity_view: "Просмотр активности за 5 дней",
  v_resource_planning_page: {
    v_edit_plan_title: "Изменить план",
    v_add_plan_title: "Добавить план",
    v_teams: "Команды",
    v_members: "Участники",
    v_type_task_name: "Введите название задачи...",
    v_projects: "Проекты",
    v_dates: "Даты",
    v_from: "От",
    v_to: "До",
    v_select_or_add: "Выберите или добавьте",
    v_description: "Описание",
    v_type_description: "Введите, чтобы добавить описание...",
    v_cancel: "Отмена",
    v_show_all_projects: "Показать все проекты",
    v_show_all_members: "Показать всех участников",
    v_show_all_tags: "Показать все теги",
    v_birthday_date: "Дата рождения",
    v_join_date: "Дата вступления",
    v_vacation_reset: "Сброс отпуска",
    v_country: "Страна",
    v_local_time: "Местное время",
    v_workload: "Нагрузка",
    v_day_off: "Выходной",
    v_total_left: "Всего/Осталось",

    v_sick_leave: "Больничный",
    v_vacation: "Отпуск",
    v_day_off_with_work_off: "Неоплачиваемый отпуск",
    v_tags: "Теги",
    v_success_created_plan: "План создан",
    v_success_edited_plan: "План изменен",
    v_success_deleted_plan: "План удален",
    v_filter_no_results: "Совпадений не найдено",
    v_error_start_date: "Время старта плана больше конца",
    v_error_set_wright_date:
      "Не корректный промежуток времени, задайте корректно период",
    v_last_seen: "Последнее посещение",
    v_user_profile_error: "Что-то пошло не так",
    v_success_edited_profile: "Профиль был отредактирован",
    v_user_profile: "Профиль пользователя",
    v_edit_user_profile: "Редактировать Профиль",
    v_error_user_is_not_exist_in_project:
      "Пользователь не добавлен в этот проект",
    v_error_user_does_not_exist_in_this_project:
      "Пользователь не существует в этом проекте",
    v_error_user_not_admin_owner:
      "Только Owner/Admin может создать план для другого пользователя",
    v_error_you_can_not_change_holidays:
      "Вы не можете изменить дату государственных праздников",
    v_error_delete_plan_in_the_past: "Невозможно удалить план в прошлом",
  },
  v_left_bar: {
    time: "Время",
    manage: "Управление",
    invoice: "Счета",
    people: "Люди",
    other: "Другое",
  },
  v_days: "д.",
  v_members: "Участники",
  v_filters: "Фильтры",
  v_clear_all: "Clear all",
  v_show_by: "Показать за:",
  v_day: "День",
  v_week: "Неделя",
  v_month: "Месяц",
  v_year: "Год",
  v_assignee: "Назначен",
  v_all_members: "Все участники",
  v_select_projects: "Выберите проекты...",
  v_notifications: "Уведомления",
  v_not_notifications: "У вас нет уведомлений",
  v_plan_info: "Информация о плане",
  v_release_notes: {
    v_title: "Release Notes",
    v_whats_new: "What's New:",
    v_improved: "Improved/Fixed:",
    v_version: "Version",
    v_versions: {
      "1.7.26": {
        v_new: [
          "Work on bugs/improvements.",
          "Subscription payment model has been changed, now the amount depends on the number of users in the team.",
          "The functionality and benefits of subscriptions have been changed.",
          "Added more detailed notifications in case of synchronization error with Jira.",
          "Added the ability to control Workload on the 'Resource Planning' page. View available load and view and edit actual load.",
          "Bench functionality has been added. Possibility to filter out users with no plans on the 'Resource Planning' page.",
        ],
        v_improved: [
          "Fixed a visual error on the login page when the sidebar was visible.",
        ],
      },
      "1.6.25": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Team' and 'Reports'",
          "New sidebar design implemented",
          "Added the possibility to see the total time for billable and not billable tracks on the 'Reports' page",
          "Added the possibility of inviting several users to the Team at a time on the 'Team' page",
        ],
        v_improved: [
          "Fixed bug with creating a plan for the wrong date that was selected and inability to edit plan daterange on the 'Resource Planning' page",
          "Visual improvements on the 'Resource Planning' page",
          "Fixed a bug with updating the status of an invoice when it is assigned the 'Awaiting' status on the 'Invoices' page",
          "Visual improvements on the 'Invoices' page",
        ],
      },
      "1.6.24": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Teams', 'User Profile', 'Reports', 'Projects', 'Clients' and 'Timer'",
        ],
        v_improved: [
          "Track no longer stops when switching Teams",
          "Fixed a bug when Owner could not remove Admin from the project on the 'Projects' page",
          "Removed the field of synch with Jira when creating and editing a project on the 'Projects' page",
          "Visual and functional improvements on the 'Projects' page",
          "Fixed a bug when Invoice Manager saw the report only by itself on the 'Reports' page",
          "Fixed visual bug with incorrect highlighting of daterange in the calendar on the 'Reports' page",
          "Fixed a bug with incorrect display of birth date calendar on the 'User Profile' page",
          "Fixed a bug where user tags were lost when changing role on the 'Team' page",
          "Visually improved user info window on the 'Resource Planning' page",
          "Improved display of the tooltip with the plan description when there are few users on the 'Resource Planning' page",
          "Improved work of the tooltip with plan description on the 'Resource Planning' page",
          "Changed the order of action for copying a link to an invoice on the 'Invoices' page",
          "Fixed bug with infinite loading of the list of Teams when viewing a sent invoice on the 'Invoices' page",
          "Visual improvements when filling out an invoice on the 'Invoices' page",
        ],
      },
      "1.6.23": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the possibility to purchase a subscription, for which the trial is valid",
        ],
        v_improved: [
          "Fixed a bug where user's name in filters was duplicated on the 'Report' page",
          "Fixed a bug where PM, HR manager and Invoice managers could not use cross-timed reports on the 'Report' page",
          "Fixed a bug where the color of the tooltip did not change when switching the theme on the 'Resource Planning' page",
          "Fixed a bug where PM and HR manager could not invite new users to the team on the 'Team' page",
        ],
      },
      "1.6.22": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to see reports on users with denied access",
        ],
        v_improved: [
          "Improved filter logic on the 'Reports' page",
          "Fixed several bugs on the 'Reports' page",
          "Reports are updated immediately after a Team change",
          "Fixed several bugs on the 'Project' page",
          "Fixed search on 'Client' and 'Project' pages",
          "Improvements have been made to the change history on the 'Resource Planning' page",
        ],
      },
      "1.6.21": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to set tracks for the future in 'Timer' page",
          "Added automatic trimming of the estimate when copying a Jira-synchronized task",
          "Added history of plan changes in 'Resource Planning' page",
        ],
        v_improved: [
          "Improved work of the reports page",
          "Fixed a bug where the report was exported incorrectly when cross-team was enabled in 'Reports' page",
          "Several visual improvemets in sidebar",
          "Several visual improvemets in 'Resource Planning' page",
        ],
      },
      "1.6.20": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Roles', 'Resource Planning', 'Timer', 'Projects' and 'E-mail'",
        ],
        v_improved: [
          "Added mass synch with Jira in 'Timer' page",
          "Role-based accesses have been updated",
          "Fixed minor errors with roles",
          "Fixed bug with drag and drop plan by Recruiter and Memebers in 'Resource Planning' page",
          "Fixed a bug where the recruiter and the memeber only saw the plans of the projects to which they were added 'Resource Planning' page",
          "Fixed minor errors in 'Resource Planning' page",
          "Added weekly report emails for new roles",
          "Weekly reports updated, time period added",
          "Fixed a bug with deleting a user from the project in 'Projects' page",
        ],
      },
      "1.6.19": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Team', ''Resource Planning' and 'Invoices'",
          "Added connection with support",
        ],
        v_improved: [
          "User roles have been updated, added new roles in 'Team' page",
          "Added tooltips for collapsed sidebar",
          "Corrected the display of holidays in 'Resource Planning' page",
          "Visual bugs fixed in 'Reports' page",
          "Visual bugs fixed in 'Invoices' page",
        ],
      },
      "1.6.18": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Register page', 'Projects' and 'Invoices'",
          "Added the ability to log in with Google",
        ],
        v_improved: [
          "Several visual improvemets in 'Register page'",
          "Several visual improvemets in 'Reports' page",
          "Fixed a bug when large reports hang up in 'Repors' page",
          "Updated weekly e-mail reports on time tracked",
          "Fixed a bug when a user was not deleted from the project  in 'Projects' page",
          "Several visual improvemets in 'Free invoice generator' page",
          "Several visual improvemets in 'Invoices' page",
        ],
      },
      "1.6.17": {
        v_new: [
          "Work on bugs/improvements in the 'Subscriptions', 'Reports', 'Clients', 'Email' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Several visual improvemets in 'Subscriptions' page",
          "Several visual improvemets in 'Reports' page",
          "Several visual improvemets in 'Clients' page",
          "Several visual improvemets in 'Resource Planning' page",
          "New weekly e-mail reports on time tracked",
        ],
      },
      "1.6.16": {
        v_new: [
          "Work on bugs/improvements in the 'Invoice' and 'Resource Planning'",
        ],
        v_improved: [
          "Several minor improvements in the 'Resource Planning' section",
          "Added ability to change invoice status in the 'Invoices' section",
        ],
      },
      "1.6.15": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Projects' and  'Subscriptions'",
        ],
        v_improved: [
          "Improved calendar in the Add Plan window in the 'Resource Planning' section",
          "Fixed calendar in the User Profile window for web-mobile in the 'Resource Planning' section",
          "Several minor improvements for web and web-mobile in the 'Resource Planning' section",
          "Visual and logical update for coupons in the 'Subscription' section",
          "Several minor improvements in the 'Subscription' section",
          "Fixed 'Team' filter when Cross-team is active in the 'Reports' section",
          "Fixed a bug when interacting with the report on a user not from the current team in the 'Reports' section",
          "Several minor improvements in the 'Reports' section",
          "Fixed 'Total outstanding' in the 'Invoices' section",
          "Fixed tool-tips in the 'Invoices' section",
          "Fixed Free Invoice Generator",
          "Fixed detailed report in the 'Projects' section",
        ],
      },
      "1.6.14": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Subscriptions' and E-mail sections",
        ],
        v_improved: [
          "Fixed a bug when filters were dropping when scrolling to a new month in the 'Resource Planning' section",
          "Fixed colors of projects in the calendar in the 'Resource Planning' section",
          "The selected filter is moved up in the 'Resource Planning' section",
          "Fixed an error when filters were dropping when deleting a plan in the 'Resource Planning' section",
          "Fixed project colors in filters in the 'Resource Planning' section",
          "Visual improvemetns of 'Resource Planning' page in the 'Resource Planning' section",
          "Fixed photo picker in invoices in the 'Invoices' section",
          "Visual improvemetns of 'Invoices' page for web-mobile",
          "Fixed several minor visual/performance bugs in the 'Invoices' section",
          "Coupons are now saved from the beginning to the end of the trial in the 'Subscriptions' section",
          "The full amount is now visible when you buy a year's subscription in the 'Subscriptions' section",
          "Fixed a bug when users' E-mails were displayed instead of their names in real-time reports when cross-team reports is active in the 'Reports' section",
          "A filter by team has been added to the cross-team reports in the 'Reports' section",
          "Improved web-mobile version in the 'Reports' section",
          "Improved Real-Time Reports page in the 'Reports' section",
          "Fixed several minor visual/performance bugs in the 'Reports' section",
          "Fixed an error that prevented emails from being sent correctly in the 'E-mails' section",
          "Visual improvements/performance in other sections",
        ],
      },
      "1.6.13": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning' and E-mail sections",
        ],
        v_improved: [
          "Fixes filters for summary cross-team reports on the 'Reports'",
          "Reports now sorted by time on the 'Reports'",
          "Added count of users in selected status by online on the 'Reports'",
          "Changed URL for real-time reports on the 'Reports'",
          "Visual changes 'Add plan' window on the 'Resource Planning'",
          "Improved displaying plan name by plan duration in 'Resource Planning'",
          "The plan now has a default duration of 24 hours on the selected day in 'Resource Planning'",
          "Improved 'Join date' window in User Profile window in 'Resource Planning'",
          "Visual improvements in User Profile window in 'Resource Planning'",
          "Visual improvements 'Resource Planning' page",
          "Invoices page reworked when there no invoices",
          "Visual improvements 'Invoices' page",
          "Fractional numbers are available in the QTY and rate fields in 'Invoices'",
          "Fixed bug when trial didn't start",
          "Added errors when using invalid coupons",
          "Added possibility to unsubscribe from e-mails",
          "Several visual improvements",
        ],
      },
      "1.6.12": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "The possibility of using promotional codes has been added",
        ],
        v_improved: [
          "Visual improvements to the loaded Invoice",
          "Fixed a bug when the invoice was not created due to the time zone",
          "Now plan can be created without name in 'Resource Planning'",
          "Bug fixed, cross-team report showed the wrong time, tracked by the user in 'Reports'",
          "Added the ability to enter fractional numbers in QTY and Rate in 'Invoice'",
          "Visual improvements to filters on the 'Resource Planning'",
          "Fixed several minor visual/performance bugs",
        ],
      },
      "1.6.11": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "Added Cross-team Reports",
        ],
        v_improved: [
          "Fixed sidebar opening bug when switching to Subscriptions page",
          "Visual improvements to the running timer",
          "Fixed online filters in real-time reports 'Reports' page",
          "Shows the number of the week in the year when the show buy week on the 'Resource Planning' page",
          "Fixed header on the 'Resource Planning' page",
          "Fixed a bug where the created plan was not displayed on the 'Resource Planning' page",
          "Added the ability to start the trial period without entering a card",
          "Several visual improvements",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.10": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Fixed visual bugs in invoice creating page",
          "Fixed visual bugs on 'Free Invoice Generator' page",
          "'Grand total' replaced by 'Grand total left', displays the total amount expected to be paid on 'Invoices' page",
          "The unpaid part is displayed for each invoice now",
          "Fixed visual bugs in 'Resource Planning'",
          "Added the ability to show calendar by year in 'Resource Planning'",
          "Visual improvements to filters on the 'Resource Planning' page",
          "Visual improvements to filters on the 'Reports' page",
          "Real-time Reports show tags instead of roles",
          "Fixed translation in 'Subscriptions'",
          "Fixed timer display when sidebar is hidden",
          "Improved emails",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.9": {
        v_new: [
          "New Subscriptions",
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Increased the number of characters displayed in the task name in 'Reports'",
          "Fixed location of 'summary / real-time' buttons in 'Reports'",
          "Added the ability to hide to hide side bar",
          "Now the header in the 'Resource Planning' is hidden when scrolling down",
          "Filters in 'Resource Planning' are moved to separate buttons",
          "Improved display of plan names in 'Resource Planning'",
          "When hovering on the plan in 'Resource Planning', you can see the start date, end date and duration of the plan",
          "Added the ability to hide usernames in 'Resource Planning'",
          "Fixed slider in 'Resource Planning'",
          "The space is automatically removed in the username if it is in front of the name in 'User profile'",
          "User CV is not displayed when editing a user through 'My team'",
          "Fixed bugs when moving and creating plans in 'Resource Planning'",
          "Week numbering bug fixed in 'Resource Planning'",
          "Fixed a bug with the username when adding it to the team in Notifications",
          "Notifications are now displayed from newest to oldest",
          "Fixed several minor visual bugs",
        ],
      },
      "1.5.9": {
        v_new: [
          "Work on bugs/improvements in the Resource Planning section.",
          "Release versions removed from notification bell. Useful app notifications will now be displayed there.",
        ],
        v_improved: [
          "Added an error when dragging a plan to a user who is not in the project, but to whom the plan was transferred in 'Resource Planning'",
          "Fixed pop-up window when hovering over the plan with the cursor. The window is now displayed under the cursor.",
          "Fixed display of users with a long name in the 'Resource Planning' section",
          "Improved readability of texts in the 'Resource Planning' section on user plans",
          "Changed the limit on the number of characters in the username in the 'Profile Settings' of the user. The user can enter 100 characters",
          "Fixed display of username with long name in user plan under Resource Planning",
          "Added sticky header in resource scheduling",
        ],
      },
      "1.5.8": {
        v_new: [
          "Several new messages have been added to the e-mail, which will now be sent and will help the user working with Wobbly.",
          "Added weekly e-mail reports for owner/admin about members' time tracked in favorites projects",
          "Added weekly e-mail reports for members on time tracked",
        ],
        v_improved: [
          "Members now have the ability to switch Billable/Non-billable in Reports for their tracked tasks",
          "Owner and Admin now have the ability to switch Billable/Non-billable in Reports for the tracked tasks of all users",
          "Only Owner and Admin now can edit Member's User profile in Resource planning",
          "Added the ability to select a year when setting the date of birth in Profile settings",
          "Added the ability to delete a plan in the past in Resource planning",
        ],
      },
      "1.5.7": {
        v_new: [
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed error editing User Profile card by Admin and Owner. The edit button was missing for these roles.",
          "Fixed filters in the 'Resource Planning' section. The filter did not display any results.",
          "Fixed display of user statuses in the 'Resource planning' section. The user's online status was displayed if the user's timer was active in another team.",
          "Fixed navigation arrows for switching dates in the Resource Planning section. The arrows moved by dates within two weeks/months.",
          "Improved visual in Resource Planning section.",
          "Improved work of filters in the 'Reports' section, 'Real-time' page.",
          "Fixed a bug in the 'Reports' section, 'Summary' page. A report with numerous projects and users was not displayed.",
          "Improved/simplified the creation of a per-user plan in the Resource Planning section. When creating a plan for a team member, only those projects on which the user is added are displayed.",
          "Fixed the result of displaying users in the 'Reports' section, 'Real-time' page. Users with status 'Recent' was not displayed in the filter if it was with an active timer in another team.",
        ],
      },
      "1.5.6": {
        v_new: [
          "Added the ability to delete a user account.",
          "Slightly redesigned interaction with the 'Reports' section. 'Reports' are divided into 'Summary' and 'Real-time' pages.",
          "Added some new error messages that the user might encounter.",
        ],
        v_improved: [
          "Improved interaction with the calendar when creating/editing a plan in the 'Resource Planning' section.",
          "Fixed login error messages. Some errors were not displayed.",
          "Fixed time division in the 'Timer' section after 00.00. Tasks that were stopped manually after 00.00 were saved in one time period.",
          "Fixed a bug with displaying project colors in the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.4.5": {
        v_new: [
          "Added 'Real-time report' to the 'Reports' section. Now Owner and Admin can view which task the user is currently recording or the last recorded task for five days.",
          "Added several new colors for projects.",
        ],
        v_improved: [
          "Fixed a bug with changing teams. Now, when changing the team on one browser, the user's team will change on the other browser.",
          "Improved the speed of the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.3.4": {
        v_new: ["Bug fixes, visual fixes, application speed improvements."],
        v_improved: [
          "Fixed a few minor visual bugs.",
          "The light and dark switch has been moved to the app logo from the “Profile Settings” section. Also, now visually changed from a switch to a button.",
          "Improved speed of processing requests and responses in the Timer, Reports, and Projects sections.",
          "Improved the visual components of the “Light” and “Dark” themes.",
          "Added the ability to download a 1-minute rounded report in 'Decimal report'.",
          "Fixed “Billable /Non-billable” task merge bug when viewing project/people reports in detail. Now, tasks with the same name on different “Billable/Non-Billable” statuses are not merged.",
        ],
      },
      "1.3.3": {
        v_new: [
          "Added the ability to record the time as Billable/Non billable in the 'Timer' section.",
          "Added Online status for users. In the 'Projects' section, when viewing users on projects, you can see the green status Online which displayed if the user is currently recording a task in the timer.",
          "Added 'Decimal rounding of reports' in the 'Reports' section. Now the user can upload reports in the new format.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed display of usernames in the left menu field.",
          "Fixed editing task time in the 'Timer' section. Tasks from different projects could overlap in time.",
          "Fixed a bug with displaying a client in a project if the client was deleted from the 'Clients' section. The client that was deleted is no longer displayed in the project.",
        ],
      },
      "1.2.2": {
        v_new: [
          "Added a new ability to add projects to favorites, for quick access to projects.",
          "Added display of the total recorded time per month for all teams under the user profile.",
        ],
        v_improved: [
          "User profile. Added a notification if the user missed a required field.",
          "Invoices. Recent invoices now show the company name instead of the customer's name.",
          "The task in the timer can be started without a name.",
          "Added several error messages that users may encounter.",
          "Now users can see other members of the shared project.",
        ],
      },
      "1.1.1": {
        v_new: ["Added new Wobbly light theme in profile settings"],
        v_improved: [
          "Improved search for invoices by: Client name, Company name, Invoice number, Description, Reference. Now the invoice can be found by all these fields",
          "Fixed a bug with changing logo in invoices.",
          "Fixed bug with pulling archived clients when increase/decrease a subscription.",
          "Fixed errors when downloading a detailed/summary report. Reports could come encrypted or be incomplete.",
          "Fixed a bug when combining task names in the Reports section.",
          "Fixed search in the Projects section.",
          "Added several error messages that users may encounter.",
          "Fixed bugs in the Timer section. Editing tasks with overlapping times on different teams.",
          "Fixed errors when purchasing a subscription by a user with the Admin role. The subscription now applies to all team members.",
          "Fixed client removal. When deleting a client, the invoices that were sent to him now do not disappear",
        ],
      },
      "1.0": {
        v_new: ["Added release notes"],
        v_improved: [
          "Fixed display special characters in the project name by code when viewing detailed reports in the 'Reports' section.",
          "We fixed a bug where the partial search in the 'Projects' section did not display all matches.",
          "Fixed monitor 'Total hours by date' in the 'Reports' section. Fixed visual display by dates.",
          "Fixed search by invoice numbers and page transitions. Now the search is not reset when viewing the invoice.",
          "Added the ability to search for an invoice by Description and Reference.",
          "Added error notification when creating/editing a project with the same name.",
          "Fixed a bug related to special characters in the description of tasks in the 'Timer' section.",
          "Fixed a bug with inviting a new user to the team",
          "Added a few new error messages that the user may experience.",
          "Fixed a bug in the Timer section. Recording time in advance and editing time with the intersection of tasks by time.",
        ],
      },
    },
  },
};
