import React from "react";
import { connect } from "react-redux";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import {
  getStripeCustomerDataSuccess,
  changeSubscriptionLoader,
} from "../../../../redux/actions/SubscriptionPageAction";
import {
  finalizeSubscription,
  updatePaymentMethod,
  updateSubscription,
} from "../../../../api/payment";
import Button from "../../../Atoms/Button";
import { showNotificationAction } from "../../../../redux/actions/NotificationActions";

const options = {
  style: {
    base: {
      fontSize: "14px",
      color: "rgba(0,0,0,0.7)",
      letterSpacing: "0.025em",
      fontFamily: "Open Sans, sans-serif",
      "::placeholder": {
        color: "#aab7c4",
      },
      backgroundColor: "#ffffff",
    },
    invalid: {
      color: "red",
      "::placeholder": {
        color: "red",
      },
    },
  },
};

const createPaymentMethodObject = (paymentDetails, cardElement) => {
  const { address, country, emailAddress, name, phoneNumber, postalCode } =
    paymentDetails;
  const requestObject = {
    type: "card",
    card: cardElement,
    billing_details: {
      name: name,
      email: emailAddress,
      address: {
        country: country,
      },
    },
  };

  if (phoneNumber) {
    requestObject.billing_details.phone = phoneNumber;
  }
  if (address) {
    requestObject.billing_details.address.line1 = address;
  }
  if (postalCode) {
    requestObject.billing_details.address.postal_code = postalCode;
  }
  return requestObject;
};

const CheckoutForm = ({
  paymentDetails,
  selectedPlan,
  changePaymentMethod,
  successHandler,
  isProcessing,
  couponId,
  productIdForUpgrade,
  user,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const vocabulary = useSelector((state) => state.languageReducer.vocabulary);

  const handleSubmit = async () => {
    if (!stripe || !elements || isProcessing) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const requestObject = createPaymentMethodObject(
      paymentDetails,
      cardElement,
    );
    const { error, paymentMethod } = await stripe.createPaymentMethod(
      requestObject,
    );

    if (error) {
      dispatch(
        showNotificationAction({
          text: error.message,
          type: "error",
        }),
      );
      console.log("[error]", error);
    } else {
      try {
        let subscription = null;

        dispatch(changeSubscriptionLoader(true));
        if (changePaymentMethod) {
          subscription = (
            await updatePaymentMethod({ paymentMethodId: paymentMethod.id })
          ).data;
        } else {
          const {
            externalPlanData: { pricePeriod },
          } = selectedPlan;

          subscription = (
            await updateSubscription({
              paymentMethodId: paymentMethod.id,
              priceId: pricePeriod.id,
              couponId:
                user.coupon.applies_to === productIdForUpgrade
                  ? couponId
                  : null,
              productId: productIdForUpgrade,
            })
          ).data;
        }
        verifySubscription(subscription);
        dispatch(changeSubscriptionLoader(false));
      } catch (error) {
        dispatch(
          showNotificationAction({
            text: error.response.data.error,
            type: "error",
          }),
        );
        dispatch(changeSubscriptionLoader(false));
        console.log(error);
      }
    }
  };

  const verifySubscription = async (subscription) => {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;

      if (
        status === "requires_action" ||
        status === "requires_payment_method"
      ) {
        try {
          const res = await stripe.confirmCardPayment(client_secret);

          if (res.error) {
            throw res.error;
          } else {
            confirmSubscription(subscription.id);
          }
        } catch (error) {
          confirmSubscription(subscription.id);
          console.log(error);
        }
      } else {
        dispatch(getStripeCustomerDataSuccess(subscription));
        successHandler(true);
      }
    } else {
      dispatch(getStripeCustomerDataSuccess(subscription));
      successHandler(true);
    }
  };

  const confirmSubscription = async (subscriptionId) => {
    try {
      const { data: subscription } = await finalizeSubscription(subscriptionId);

      dispatch(getStripeCustomerDataSuccess(subscription));
      successHandler(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="checkout-form">
      {/* <CardElement /> */}
      <label className="checkout-form__label">
        <div className="checkout-form__label-text">Card number</div>
        <CardNumberElement
          className="checkout-form__stripe-element"
          options={options}
        />
      </label>
      <label className="checkout-form__label--short">
        <div className="checkout-form__label-text">Expires</div>
        <CardExpiryElement
          className="checkout-form__stripe-element"
          options={options}
        />
      </label>
      <label className="checkout-form__label--short">
        <div className="checkout-form__label-text">CVV</div>
        <CardCvcElement
          className="checkout-form__stripe-element"
          options={options}
        />
      </label>

      <Button
        type="primary"
        width="100%"
        disabled={!stripe || isProcessing}
        isLoading={isProcessing}
        onClick={handleSubmit}
      >
        {changePaymentMethod ? "Change payment method" : "Pay Now"}
      </Button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  couponId: state.subscriptionPageReducer.couponId,
  productId: state.subscriptionPageReducer.productId,
  productIdForUpgrade: state.subscriptionPageReducer.productIdForUpgrade,
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(CheckoutForm);
