import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "../../Atoms/Modal";
import ReleaseNotesVersionItem from "../ReleaseNotesVersionItem";

import "./style.scss";

import { APP_VERSION, VERSIONS_LIST } from "../../../constants/versions";
import { setVersionToLocalStorage } from "../../../services/versionService";

class ReleaseNotesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      versionList: [...VERSIONS_LIST],
    };
  }

  closeModal = (event) => {
    setVersionToLocalStorage(APP_VERSION);

    this.props.closeCallback(event);
  };

  render() {
    const { versionList } = this.state;
    const { selectedVersion, vocabulary } = this.props;
    const { v_release_notes } = vocabulary;

    return (
      <Modal
        open={true}
        title={v_release_notes.v_title}
        onClose={(event) => this.closeModal(event)}
        classes={{
          modal: "release-notes-modal",
          wrapper: "release-notes-modal__wrapper",
          container: "release-notes-modal__container",
        }}
      >
        <div className="release-notes-modal__body">
          {Object.values(versionList).map((version, index) => (
            <ReleaseNotesVersionItem
              versionData={version}
              key={index}
              isOpenOnInit={version.version === selectedVersion}
            />
          ))}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(ReleaseNotesModal);
