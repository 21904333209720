import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { AppConfig } from "../../../config";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";
import {isChrome, isFirefox} from 'react-device-detect';

// Services
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsInvoicesManagerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole,
  checkIsMemberByRole,
  checkIsRecruiterByRole,
} from "../../../services/authentication";
import {
  getCustomerDataAction,
  getSubscriptionDataAction,
} from "../../../redux/actions/SubscriptionPageAction";
import { getTimeCountAction } from "../../../redux/actions/MainPageAction";

import moment from "moment";

// Components
import TeamSwitcher from "../TeamSwitcher";
import SupportModal from "../SupportModal";
import UserMenu from "../UserMenu";
// import CustomScrollbar from "../../Unstructured/CustomScrollbar";

// Styles
import "./style.scss";
import { switchMenu } from "../../../redux/actions/ResponsiveActions";
import { SUBSCRIPTIONS } from "../../../constants/constants";
import SwitcherTheme from "../../../components/Unstructured/SwitcherTheme";
import SwitcherThemeIcon from "../../../components/Unstructured/SwitcherThemeIcon";
import {ArrowIconLeft, ArrowIconLeftSidebar} from "../../Atoms/SvgIcons";
import {isClass} from "eslint-plugin-react/lib/util/ast";
import {showNotificationAction} from "../../../redux/actions/NotificationActions";

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: this.props.themes,
      isCollapsed: this.props.isCollapsed,
      supportOpen: false,
    };
  }

  renderTimer = () => {
    const { history, timerTick } = this.props;

      return timerTick ? timerTick : "";
  };

  amountTooltipContent = (timerText, isCollapsed) => {
    const { timerTick } = this.props;
    if (timerTick && isCollapsed) {
      return timerTick;
    } else if (isCollapsed) {
      return timerText;
    }
  };

  checkOnProPlane = (currentPlaneId, planes) => {
    const plane = planes.find((item) => currentPlaneId === item.id);

    // In order for the banner to be displayed with a mounthly Pro subscription, there is a check for the second largest amount after the Pro plan
    if (
      plane?.name === "Business" &&
      this.props.customerData &&
      this.props.customerData.plan?.nickname > 50000
    ) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    this.props.getTimeCountAction();
  }

  componentDidUpdate(prevState) {
    if (prevState.currentTeam.data.role !== this.props.currentTeam.data.role) {
      if (checkIsOwnerByRole(this.props.currentTeam.data.role)) {
        this.props.getCustomerDataAction();
        this.props.getSubscriptionDataAction();
      }
    }
  }

  changeThemes = () => {
    if (localStorage.getItem("themes") === "dark") {
      localStorage.setItem("themes", "light");
    } else {
      localStorage.setItem("themes", "dark");
    }
    this.setState({
      themes: localStorage.getItem("themes"),
    });
  };
  openSupportModal = () => {
    this.setState({ supportOpen: true });
  };
  closeSupportModal = () => {
    this.setState({ supportOpen: false });
  };

  render() {
    const {
      switchMenu,
      isMobile,
      vocabulary,
      currentTeam,
      isOwner,
      timerTick,
      user,
      customerData,
      subscriptionData,
      isCollapsed
    } = this.props;
    const {
      v_help,
      v_timer,
      v_reports,
      v_projects,
      v_clients,
      v_invoices,
      v_subscription,
      v_hr_people,
      v_upgrade_sidebar_title,
      v_upgrade_sidebar_title_trial_banner,
      v_upgrade_sidebar_title_trial_banner_coupon,
      v_upgrade_sidebar_text_trial_banner,
      v_upgrade_sidebar_text_trial_banner_few_days,
      v_upgrade_sidebar_button,
      v_apply_sidebar_button,
      v_last_trial_day,
      v_last_trial_day_with_coupon,
      v_resource_planning,
      v_collapse,
      v_expand,
      v_support,
      v_left_bar
    } = vocabulary;
    const { supportOpen } = this.state;

    let userId = "";

    if (user) {
      userId = user.id;
    }

    const LA_TEAM_ID = "00000000-0000-0000-0000-000000000000";
    // const isShowResourcePlanning =
    const isShowRecruitBoard =
      currentTeam?.data?.id === LA_TEAM_ID || AppConfig.isDemo;

    const onHandleClickWobblyButton = () => {
      if (isChrome) {
        window.open('https://chrome.google.com/webstore/detail/wobbly-button/nmnmeepomkgoejdjmgegdpaafhafjkkg', '_blank', 'noreferrer')
      } else if (isFirefox) {
        window.open('https://addons.mozilla.org/en-US/firefox/addon/wobbly-button/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search', '_blank', 'noreferrer')
      }
    }

    const isValidBrowser = isChrome || isFirefox


    return (
      <div
        className={classNames("wrapper", { "wrapper--mobile": isMobile })}
        data-theme={this.props.themes}
      >
        {/* <CustomScrollbar> */}
        {!isMobile && (
          <>
            <Link onClick={switchMenu} to="/timer">
              <i
                className={classNames("logo_small", {
                  "logo_small-light":
                    localStorage.getItem("themes") === "light",
                })}
              />
            </Link>
            <SwitcherThemeIcon
              themes={this.props.themes}
              handleChange={this.props.changeThemes}
            />
          </>
        )}
        <div className="navigation_links_container">
          <div className={"category-liable"}>
            <span className={isCollapsed ? 'category-liable-text-collapsed' : 'category-liable-text'}> {isCollapsed ? ' ' : v_left_bar.time}</span>
          <NavLink
            activeClassName="active-link"
            className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
            onClick={switchMenu}
            to="/timer"
            style={{ textDecoration: "none" }}
          >
            <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
              <CustomTippy
                content={v_timer}
                disabled={!this.props.isCollapsed || isMobile}
              >
                <div className={'green-button-wrapper'}>
                  {timerTick && <div className={'green-button'}></div>}
                    <i
                      className={classNames("timer-active", {
                        "timer-active-light":
                          localStorage.getItem("themes") === "light",
                      })}
                    ></i>
                </div>
              </CustomTippy>
              <div className="links_text">{v_timer}</div>
              <div className="timer_task">{this.renderTimer()}</div>
            </div>
          </NavLink>
            {isValidBrowser &&
            <div
                className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                onClick={onHandleClickWobblyButton}
                style={{ textDecoration: "none" }}
            >
              <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                <CustomTippy
                    content={v_timer}
                    disabled={!this.props.isCollapsed || isMobile}
                >
                  <div>
                        <i
                            className={"wobbly-button"}
                        />
                  </div>
                </CustomTippy>
                <div className="links_text">Wobbly Button</div>
              </div>
            </div>}
          </div>
          <div className={"category-liable"}>
            <span className={isCollapsed ? 'category-liable-text-collapsed' : 'category-liable-text'}>{isCollapsed ? ' ' : v_left_bar.manage}</span>
            <NavLink
                activeClassName="active-link"
                isActive={() => {
                  const { match } = this.props;

                  return match.path.indexOf("/reports") >= 0;
                }}
                onClick={switchMenu}
                to="/reports/summary"
                className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                style={{ textDecoration: "none" }}
            >
              <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                <CustomTippy
                    content={v_reports}
                    disabled={!this.props.isCollapsed || isMobile}
                >
                  <div>
                    <i
                        className={classNames("reports", {
                          "reports-light":
                              localStorage.getItem("themes") === "light",
                        })}
                    />
                  </div>
                </CustomTippy>
                <div className="links_text">{v_reports}</div>
              </div>
            </NavLink>
            {!checkIsInvoicesManagerByRole(currentTeam.data.role) && (
                <NavLink
                    activeClassName="active-link"
                    onClick={switchMenu}
                    className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                    to="/resource-planning"
                    style={{ textDecoration: "none" }}
                >
                  <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                    <CustomTippy
                        content={v_resource_planning}
                        disabled={!this.props.isCollapsed || isMobile}
                    >
                      <div>
                        <i
                            className={classNames("resource-planning", {
                              "resource-planning-light":
                                  localStorage.getItem("themes") === "light",
                            })}
                        />
                      </div>
                    </CustomTippy>
                    <div className="links_text">{v_resource_planning}</div>
                  </div>
                </NavLink>
            )}
            <NavLink
                activeClassName="active-link"
                onClick={switchMenu}
                className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                to="/projects"
                style={{ textDecoration: "none" }}
            >
              <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                <CustomTippy
                    content={v_projects}
                    disabled={!this.props.isCollapsed || isMobile}
                >
                  <div>
                    <i
                        className={classNames("projects", {
                          "projects-light":
                              localStorage.getItem("themes") === "light",
                        })}
                    />
                  </div>
                </CustomTippy>
                <div className="links_text">{v_projects}</div>
              </div>
            </NavLink>
            <div className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}>
            <div className="wrapper-position-add-team">
              <TeamSwitcher
                  isMobile={isMobile}
                  themes={this.props.themes}
                  isCollapsed={this.props.isCollapsed}
              />
            </div>
          </div>
          </div>
          <div className={"category-liable"}>
            <span className={isCollapsed ? 'category-liable-text-collapsed' : 'category-liable-text'}>{isCollapsed ? ' ' : v_left_bar.invoice}</span>
            {(checkIsAdminByRole(currentTeam.data.role) ||
                checkIsOwnerByRole(currentTeam.data.role) ||
                checkIsHRManagerByRole(currentTeam.data.role) ||
                checkIsProjectManagerByRole(currentTeam.data.role)) && (
                <NavLink
                    activeClassName="active-link"
                    onClick={switchMenu}
                    className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                    to="/clients"
                    style={{ textDecoration: "none" }}
                >
                  <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                    <CustomTippy
                        content={v_clients}
                        disabled={!this.props.isCollapsed || isMobile}
                    >
                      <div>
                        <i
                            className={classNames("clients", {
                              "clients-light":
                                  localStorage.getItem("themes") === "light",
                            })}
                        />
                      </div>
                    </CustomTippy>
                    <div className="links_text">{v_clients}</div>
                  </div>
                </NavLink>
            )}
            {(checkIsOwnerByRole(currentTeam.data.role) ||
                checkIsAdminByRole(currentTeam.data.role) ||
                checkIsInvoicesManagerByRole(currentTeam.data.role)) && (
                <NavLink
                    activeClassName="active-link"
                    onClick={switchMenu}
                    className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                    to="/invoices"
                    style={{ textDecoration: "none" }}
                >
                  <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                    <CustomTippy
                        content={v_invoices}
                        disabled={!this.props.isCollapsed || isMobile}
                    >
                      <div>
                        <i
                            className={classNames("invoices", {
                              "invoices-light":
                                  localStorage.getItem("themes") === "light",
                            })}
                        />
                      </div>
                    </CustomTippy>
                    <div className="links_text">{v_invoices}</div>
                  </div>
                </NavLink>
            )}
          </div>
          {isShowRecruitBoard &&
              !checkIsInvoicesManagerByRole(currentTeam.data.role) &&
              !checkIsMemberByRole(currentTeam.data.role) && (
          <div className={"category-liable"}>
            <span className={isCollapsed ? 'category-liable-text-collapsed' : 'category-liable-text'}>{isCollapsed ? ' ' : v_left_bar.people}</span>
                    <NavLink
                        activeClassName="active-link"
                        className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                        onClick={switchMenu}
                        to="/recruitment/directions"
                        style={{ textDecoration: "none" }}
                    >
                      <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                        <CustomTippy
                            content={v_hr_people.leftBar}
                            disabled={!this.props.isCollapsed || isMobile}
                        >
                          <div>
                            <i
                                className={classNames("people", {
                                  "people-light":
                                      localStorage.getItem("themes") === "light",
                                })}
                            />
                          </div>
                        </CustomTippy>
                        <div className="links_text">{v_hr_people.leftBar}</div>
                      </div>
                    </NavLink>
          </div>
              )}
          <div className={"category-liable"}>
            <span className={isCollapsed ? 'category-liable-text-collapsed' : 'category-liable-text'}>{isCollapsed ? ' ' : v_left_bar.other}</span>
            <a
                className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                style={{ textDecoration: "none"  }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.wobbly.me/"
            >
                <CustomTippy
                    content={v_help}
                    disabled={!this.props.isCollapsed || isMobile}
                >
                  <div>
                    <i
                        className={classNames("help", {
                          "help-light": localStorage.getItem("themes") === "light",
                        })}
                    />
                  </div>
                </CustomTippy>
                <div className="links_text help-text">{v_help}</div>
              </a>
              <div
                  className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                  onClick={() => {
                    this.openSupportModal();
                  }}
                  style={{ textDecoration: "none"  }}
              >
                <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                  <CustomTippy
                      content={v_support}
                      disabled={!this.props.isCollapsed || isMobile}
                  >
                    <div>
                      <i
                          className={classNames("support", {
                            "support-light": localStorage.getItem("themes") === "light",
                          })}
                      />
                    </div>
                  </CustomTippy>
                  <div className="links_text help-text">{v_support}</div>
                </div>
            </div>
            {(checkIsOwnerByRole(currentTeam.data.role) ||
                checkIsAdminByRole(currentTeam.data.role)) && (
                <NavLink
                    activeClassName="active-link"
                    onClick={switchMenu}
                    className={isCollapsed ? 'link-wrapper_collapsed' : 'link-wrapper'}
                    to="/subscription/plans"
                    style={{ textDecoration: "none" }}
                >
                  <div className={isCollapsed ? 'navigation_links_collapsed' : 'navigation_links'}>
                    <CustomTippy
                        content={v_subscription.leftBar}
                        disabled={!this.props.isCollapsed || isMobile}
                    >
                      <div>
                        <i
                            className={classNames("subscription", {
                              "subscription-light":
                                  localStorage.getItem("themes") === "light",
                            })}
                        />
                      </div>
                    </CustomTippy>
                    <div className="links_text">{v_subscription.leftBar}</div>
                  </div>
                </NavLink>
            )}
          </div>
            {!isMobile && (
                <div  className={isCollapsed ? 'link-wrapper_collapsed' : 'hide-sidebar-btn-wrapper'}>
                    <CustomTippy
                        content={this.props.isCollapsed ? v_expand : v_collapse}
                    >
                <span
                    className={isCollapsed ? 'hide-sidebar-btn_collapsed' : 'hide-sidebar-btn'}
                    onClick={this.props.toggleSidebar}
                >
                  <ArrowIconLeftSidebar
                      className={classNames(
                          this.props.isCollapsed ? "arrow-up" : "arrow-down",
                      )}
                      color="white"
                  />
                </span>
                    </CustomTippy>
                </div>
            )}
        </div>


          {checkIsOwnerByRole(currentTeam.data.role) &&
              currentTeam &&
              currentTeam.data.trial && !isCollapsed &&
<div className="button-wrapper" >
  <Link
      className="upgrade-banner__button"
      to="/subscription/plans"
      onClick={switchMenu}
  >
    {user.coupon
        ? v_apply_sidebar_button
        : v_upgrade_sidebar_button}
  </Link>
    <p className="button-wrapper-text">
        {moment(currentTeam.data.trial).diff(moment(), "days") ===
            0 &&
            !user.coupon && <span>{v_last_trial_day}</span>}
        {moment(currentTeam.data.trial).diff(moment(), "days") ===
            0 &&
            user.coupon && <span>{v_last_trial_day_with_coupon}</span>}
        {moment(currentTeam.data.trial).diff(moment(), "days") >
            1 && (
                <span>
                      {`${moment(currentTeam.data.trial).diff(
                          moment(),
                          "days",
                      )}`}{" "}
                    {v_upgrade_sidebar_text_trial_banner}
                    </span>
            )}
        {moment(currentTeam.data.trial).diff(moment(), "days") ===
            1 && (
                <span>
                      {`${moment(currentTeam.data.trial).diff(
                          moment(),
                          "days",
                      )}`}{" "}
                    {v_upgrade_sidebar_text_trial_banner_few_days}
                    </span>
            )}
    </p>
</div>
          }
        <UserMenu
          switchMenu={switchMenu}
          isCollapsed={this.props.isCollapsed}
        />
        {supportOpen && <SupportModal closeModal={this.closeSupportModal} />}
        {/* </CustomScrollbar> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTimer: state.mainPageReducer.currentTimer,
  durationTimeFormat: state.userReducer.durationTimeFormat,
  currentTeam: state.teamReducer.currentTeam,
  customerData: state.subscriptionPageReducer.customerData,
  subscriptionData: state.subscriptionPageReducer.customerData,
  timerTick: state.mainPageReducer.timerTick,
  isOwner: state.teamReducer.currentTeam.data.owner_id,
  user: state.userReducer.user,
  time: state.mainPageReducer.time,
});

const mapDispatchToProps = {
  switchMenu,
  getSubscriptionDataAction,
  getCustomerDataAction,
  getTimeCountAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftBar),
);
