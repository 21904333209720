import React from "react";
import "./style.scss";
import { Link, withRouter } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

const TextareaAutosizeForMobile = ({
  vocabulary,
  cancelHandler,
  handleInputChange,
  value,
}) => (
  <div
    className="textarea-autosize-modal-invoices-manager"
    data-theme={"light"}
  >
    <div className="textarea-autosize-modal-invoices-manager__background" />
    <div className="textarea-autosize-modal-invoices-manager__container">
      <i
        className="textarea-autosize-modal-invoices-manager__container__close"
        onClick={cancelHandler}
      />
      {/* <h2 className="textarea-autosize-modal-invoices-manager__text">
        Please save before attempting to {title}
      </h2> */}
      <TextareaAutosize
        value={value || ""}
        onChange={handleInputChange}
        className="textarea-autosize-modal-invoices-manager__input textarea-autosize-modal-invoices-manager__textarea"
      />
      <div className="textarea-autosize-modal-invoices-manager__buttons-wrap">
        <button
          className="confirmation-modal-manager-icon__button textarea-autosize-modal-invoices-manager__button--confirm"
          onClick={cancelHandler}
        >
          {"OK"}
        </button>
      </div>
    </div>
  </div>
);

export default TextareaAutosizeForMobile;
