import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import "./style.scss";
import classNames from "classnames";
import { Loading } from "../../components/Unstructured/Loading";
import { checkAccessByRole, ROLES } from "../../services/authentication";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

import Directions from "../../components/Unstructured/People/DirectionsComponent";
import Candidates from "../../components/Unstructured/People/CandidatesComponent";
import Applications from "../../components/Unstructured/People/ApplicationsComponent";
import Vacancies from "../../components/Unstructured/People/VacanciesComponent";

const tabsMapReverse = {
  0: "directions",
  1: "applications",
  2: "vacancies",
  3: "candidates",
};

const CustomTabs = withStyles({
  indicator: {
    height: "3px",
    backgroundColor: " #00B173",
  },
  "@media (max-width: 768px)": {
    flexContainer: {
      flexDirection: "column",
    },
    indicator: {
      backgroundColor: "transparent",
    },
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    width: "auto",
    padding: "10px 0 0 0",
    margin: "0 20px",
    textTransform: "none",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#aaa5a5",
    opacity: 1,
    "&:hover": {
      color: "#00B173",
    },
    "&$selected": {
      color: " #00B173",
    },
    "&:focus": {
      color: "#00B173",
    },
  },
  "@media (max-width: 768px)": {
    root: {
      maxWidth: "100%",
      padding: "0",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const PeoplePage = ({ vocabulary, history, userRole }) => {
  const { tab: tabParam } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { v_hr_people, v_page_access_denied, v_page_access_request_owner } =
    vocabulary;
  const [isLoading, setIsLoading] = useState(true);

  const getTabNumberFromParams = (param, tabs) => {
    if (param) {
      let tabNumber = "";

      for (const value in tabs) {
        if (tabs[value] === param) {
          tabNumber = value;
        }
      }

      if (tabNumber) {
        return Number(tabNumber);
      }
      return 0;
    }
    return 0;
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    history.push(tabsMapReverse[newValue]);
  };

  const TABS = [
    {
      title: v_hr_people.header.board,
      id: 0,
      component: <Directions />,
    },
    {
      title: v_hr_people.header.applications,
      id: 1,
      component: <Applications />,
    },
    {
      title: v_hr_people.header.vacancies,
      id: 2,
      component: <Vacancies />,
    },
    {
      title: v_hr_people.header.candidates,
      id: 3,
      component: <Candidates />,
    },
  ];
  useEffect(() => {
    setSelectedTab(getTabNumberFromParams(tabParam, tabsMapReverse));
    setIsLoading(false);
  }, []);
  return (
    <div>
      <Loading flag={isLoading} mode="parentSize" withLogo={false}>
        <div
          className={classNames("people-page", {
            "people-page--blured":
              userRole &&
              checkAccessByRole(userRole, [
                ROLES.ROLE_MEMBER,
                ROLES.ROLE_INVOICES_MANAGER
              ]),
          })}
        >
          <div className="people-page__header">
            <div className="people-page__header__title">
              {v_hr_people.header.title}
            </div>
            <div className="people-page__tabs">
              <CustomTabs value={selectedTab} onChange={handleChangeTab}>
                {TABS.map((tab) => (
                  <CustomTab key={tab.id} label={tab.title} />
                ))}
              </CustomTabs>
            </div>
          </div>
          {userRole &&
            !checkAccessByRole(userRole, [
              ROLES.ROLE_MEMBER,
              ROLES.ROLE_INVOICES_MANAGER,
            ]) && (
              <div className="people-page__content">
                {TABS[selectedTab].component}
              </div>
            )}
        </div>
        {userRole &&
          checkAccessByRole(userRole, [
            ROLES.ROLE_MEMBER,
            ROLES.ROLE_INVOICES_MANAGER,
          ]) && (
            <div className="people-page__access-blocked">
              <div className="people-page__access-blocked-text-block">
                <span className="people-page__access-blocked-text">
                  {v_page_access_denied}
                </span>
                <span className="people-page__access-blocked-text">
                  {v_page_access_request_owner}
                </span>
              </div>
            </div>
          )}
      </Loading>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.responsiveReducer.isMobile,
  vocabulary: state.languageReducer.vocabulary,
  userRole: state.teamReducer.currentTeam.data.role,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PeoplePage),
);
