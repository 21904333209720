import * as React from "react";


function PaginationNextButtonSvg(props: any) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.264 16H4.728C1.904 16 0 14.183 0 11.472V4.536C0 1.824 1.904 0 4.728 0h6.536C14.096 0 16 1.824 16 4.536v6.936C16 14.183 14.096 16 11.264 16zM9.816 7.4h-5.08a.6.6 0 00-.6.6c0 .336.264.6.6.6h5.08l-1.984 1.976a.614.614 0 00-.176.424.63.63 0 00.176.424.604.604 0 00.848 0l3.016-3a.619.619 0 000-.848l-3.016-3a.604.604 0 00-.848 0 .613.613 0 000 .856L9.816 7.4z"
        fill="#fff"
      />
    </svg>
  );
}

export default PaginationNextButtonSvg;
