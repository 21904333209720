export default {
  lang: { short: "en", long: "English" },

  "ERROR.USER.THIS_FACEBOOK_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "This Facebook account already connected to another account",
  "ERROR.USER.THIS_SOCIAL_ID_ALREADY_CONNECTED_TO_ANOTHER_ACCOUNT":
    "This social account already connected",
  "ERROR.USER.THIS_EMAIL_ALREADY_CONNECTED_TO_ANOTHER_FB_ACCOUNT":
    "Account with this e-mail already connected to another account Facebook",
  "ERROR.USER.ACCOUNT_ALREADY_EXISTED": "User account already existed.",
  "ERROR.TIMER.JIRA_WORKLOG_1_MIN_THRESHHOLD":
    "The minimum task duration to add a worklog to Jira is 1 minute.",
  "ERROR.TIMER.JIRA_ADD_WORKLOG_FAILED":
    'The adding worklog to Jira was completed with an error. Make sure that the access to Jira is correct and the name of the task is in the format "JIRA-1 Comment".',
  "ERROR.TIMER.JIRA_WORKLOG_ALREADY_ADDED":
    "The current task has already been added to the Jira worklog.",
  "Request path contains unescaped characters":
    "Check the correctness of the task number",
  "Request failed with status code 404":
    "The task with this number was not found in Jira",
  "Request failed with status code 401":
    "Incorrect synchronization credentials with Jira 'check the correctness in the user profile'",

  "TIMER.JIRA_SYNC_SUCCESS":
    "A test connection with Jira has been established.",
  "ERROR.TIMER.JIRA_SYNC_FAILED":
    "A test connection with Jira has not been established. Make sure the entered data is correct.",

  "SUCCESS.USER.RESET_EMAIL_CHECK":
    "Check the mailbox for a password reset email.",
  "SUCCESS.USER.RESET_PASSWORD": "You've been successfully reset the password.",
  "SUCCESS.USER.PASSWORD_CHANGED":
    "You've been successfully changed the password.",
  "SUCCESS.USER.UPDATE_USER": "The user was successfully updated.",
  "ERROR.CHECK_REQUEST_PARAMS":
    "Internal error while sending the request to the server.",
  "ERROR.INTERNAL": "Sorry, something went wrong. Please try again later.",
  "ERROR.NOT_AUTHORIZED": "Not Authorized request.",
  "ERROR.PROJECT.UPDATE_FAILED":
    "An error occurred while updating the project.",
  "ERROR.TEAM.CREATE_FAILED": "Failed to create the team.",
  "ERROR.TEAM.SWITCH_FAILED": "An error occurred while switching the team.",
  "ERROR.TEAM.RENAME_FAILED": "An error occurred while renaming the team.",
  "ERROR.TIMER.UPDATE_FAILED": "An error occurred while updating the timer.",
  "ERROR.TIMER.DELETE_FAILED": "An error occurred while deleting the timer",
  "ERROR.USER.NOT_MEMBER": "The user isn't a member of any team.",
  "ERROR.USER.RESET_EMAIL_FAILED":
    "An error occurred while sending a password reset email.",
  "ERROR.USER.CURRENT_PASSWORD_WRONG": "Current password is wrong.",
  "ERROR.USER.CHANGE_PASSWORD_FAILED":
    "An error occurred while changing a password.",
  "ERROR.USER.EMAIL_PASSWORD_WRONG": "Email or password wrong.",
  "ERROR.USER.CREATE_INVITE_FAILED":
    "An error occurred while creating an invite the user.",
  "ERROR.USER.ALREADY_TEAMMATE": "User already in the team.",
  "ERROR.USER.EMAIL_EXISTS": "Email exists.",
  "ERROR.USER.CREATE_USER_FAILED": "An error occurred while creating the user.",
  "ERROR.USER.UPDATE_USER_PERMISSIONS_DENIED":
    "You don't have permission to update the user.",
  "ERROR.USER.UPDATE_USER_FAILED": "An error occurred while updating the user.",
  "ERROR.USER.UPDATE_USER_EMAIL_EXISTS": "This Email already exists.",
  "ERROR.USER.UPDATE_USER_ROLE_FAILED":
    "You can't be able to change the role in your own team.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ROLE_FAILED":
    "You can't be able to change the team owner's role.",
  "ERROR.USER.UPDATE_ACTIVE_STATUS_FAILED":
    "You can't be able to set your account as inactive in your own team.",
  "ERROR.USER.UPDATE_TEAM_OWNER_ACTIVE_STATUS_FAILED":
    "You can't be able to set the team owner's account as inactive.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_FAILED":
    "The user was successfully deleted from team.",
  "SUCCESS.USER.DELETE_USER_FROM_TEAM_SUCCEED":
    "The user was successfully deleted from team.",
  "ERROR.USER.DELETE_USER_FROM_TEAM_FAILED":
    "An error occurred while deleting from team.",

  v_a_avatar_upload_error:
    "Loading error! The download file is not an image or its size exceeds 1 Mb.",
  v_a_data_updated_ok: "Your data has been updated",
  v_a_change_password_great_ok: "Great! You can sign in  now again!",
  v_a_change_password_ok: "You've been successfully changed the password!",
  v_a_password_same_error: "The passwords are not the same!",
  v_a_invite_sent: "Invite has been sent!",
  v_a_invite_sent_error:
    "An error occured while sending an invite to the user!",
  v_a_invite_sent_user_error:
    "Something went wrong... Remove user from 'Denied' and invite again",
  v_a_project_created: "Project created",
  v_a_project_updated: "Project updated",
  v_a_project_existed: "Project is already exists",
  v_a_project_created_error: "Project can't be created",
  v_a_project_no_valid_name: "Project name cannot contain '%'",
  v_a_project_created_synchronization_with_jira_error:
    "Jira project is already connected to the project",
  v_a_team_name_empty_error: "Team name can't be empty!",
  v_a_project_edit_error: "Project can't be edited",
  v_a_time_start_error: "Wrong Time start, please check it!",
  v_a_time_end_error: "Wrong Time end, please check it!",
  v_a_time_already_busy: "The task cannot overlap in time with another task",
  v_a_time_future_time_error:
    "Incorrect End time. You can't choose time in future",
  v_a_time_already_busy_current_task:
    "This time is already busy by current task",
  v_a_task_name_error: "Please input task name before saving the time tracking",
  v_a_team_existed: "Team is already exists",
  v_a_team_rename_error: "Team can't be renamed",
  v_a_team_create_error: "Team can't be created",
  v_a_task_name_before: "Please input task name before",
  v_a_project_before: "Please select project before",
  v_a_select_project: "Select project",
  v_a_starting: "starting",
  v_a_stopping: "stopping",
  v_a_time_tracking: "the time tracking",
  v_a_account_create: "Account has been created",
  v_a_confirm_password_error: "Wrong confirm password value",
  v_a_project_name_error: "Project name can't be empty",
  v_a_task_delete: "Do you really want to delete this time entry?",
  v_a_incorect_email: "Incorrect e-mail",
  v_a_error_authorization: "Something went wrong. Try later",
  v_a_incorect_password: "Please enter at least 6 characters",
  v_a_client_existed: "Client is already exists",
  v_a_client_name_empty_error: "Client name can't be empty!",

  v_set_default: "set as default",
  v_default: "default",
  v_help: "Help",
  v_upload_image: "Upload image",
  v_delete_image: "Delete image",
  v_phone: "Phone",
  v_more_option: "More options",
  v_active_team: "Active team",
  v_set: "Set",
  v_team_is_active: "team as active",
  v_ok: "OK",
  v_cancel: "Cancel",
  v_language: "Language",
  v_cofirm_new_password: "Confirm new password",
  v_current_password: "Current password",
  v_save_changes: "Save changes",
  v_your_name: "Your name",
  v_my_profile: "My Profile",
  v_profile_settings: "Profile settings",
  v_change_password: "Change password",
  v_new_password: "New password",
  v_new_team: "New team",
  v_send: "Send",
  v_check_email:
    "If your email is registered in Wobbly check your mail and follow the instructions in the letter",
  v_check_email_error: "Your email was not sent!",
  v_enter_email: "Add your email",
  v_create_team: "Create team",
  v_search_project: "Search project",
  v_search_client: "Search client",
  v_start_timer: "Start timer",
  v_edit_task: "Edit task",
  v_delete_task: "Delete task",
  v_add_confirm_password: "Confirm your password",
  v_registration_terms_and_policy:
    "By registration you agree with Terms and Privacy policy",
  v_login: "Login",
  v_log_in: "Login",
  v_email: "E-mail",
  v_email_error: "Too long email, only 72 symbols available",
  v_register: "Register",
  v_password: "Password",
  v_unsubscribe: "Unsubscribe",
  v_sure: "Do you really want to unsubscribe from this mailing list?",
  v_success_unsubscribe: "User successfully unsubscribed",
  v_error_already_unsubscribe: "User already unsubscribed",
  v_sure_reports: "Do you really want to unsubscribe from reports?",
  v_success_unsubscribe_reports: "User successfully unsubscribed from reports",
  v_error_already_unsubscribe_reports: "User already unsubscribed from reports",
  v_enter: "Login",
  v_incorrect_password_or_email:
    "Your email or password is incorrect. Try again.",
  v_cofirm_password: "Confirm password",
  v_forgot_your_password: "Forgot your password",
  v_dont_have_an_account_yet: "Don't have an account yet",
  v_already_have_an_account: "Already have an account",
  v_sign_up: "Sign up",
  v_timer: "Timer",
  v_reports: "Reports",
  v_report_header: "Report:",
  v_summary: "Summary",
  v_projects: "Projects",
  v_project: "Project",
  v_people: "People",
  v_description: "Description",
  v_teams: "Teams",
  v_bench: "Bench",
  v_team: "Team",
  v_add_team: "Add team",
  v_add_your_password: "Add your password",
  v_my_team: "My team",
  v_total_time: "Total time",
  v_task_name: "Task name",
  v_time_start: "Time start",
  v_time_end: "Time end",
  v_change: "Change",
  v_last_change: "Last change",
  v_create_new_project: "Create new project",
  v_apply: "Apply",
  v_edit_project: "Edit project",
  v_time: "Time",
  v_create_project: "Create project",
  v_log_out: "Log out",
  v_add_user: "Send request",
  v_invite_to_team: "Invite to team",
  v_team_role: "Team Role",
  v_edit_user: "Edit user",
  v_name: "Name",
  v_surname: "Surname",
  v_edit_team_name: "Edit Team Name",
  v_rename_team: "Rename Team",
  v_team_roles: "Team Roles",
  v_summary_report: "Summary Report",
  v_total: "Total",
  v_total_time: "Total time tracked",
  v_total_billable: "Billable",
  v_total_not_billable: "Not billable",
  v_export: "Export",
  v_detailed_proj: "Detailed",
  v_archivate: "Archive",
  v_unarchivate: "Unarchive",
  v_detailed_export: "Detailed report",
  v_simple_export: "Summary report",
  v_user: "User",
  v_select_all: "Select All",
  v_select_none: "Select None",
  v_project_name: "Project name",
  v_issue: "Issue",
  v_sum_tasks: "Sum tasks",
  v_sum_time: "Sum time",
  v_user_name: "Username",
  v_add_your_email: "Add your email",
  v_add_your_task_name: "Add your task name",
  v_find: "Find",
  v_back: "Back",
  v_team_name: "Team name",
  v_team_access: "Access",
  v_connection_problem: "Lost internet connection",
  v_connection_restored: "Internet connection restored",
  v_all_role: "All roles",
  v_all_access: "All access",
  v_all: "All",
  v_active: "Granted",
  v_not_active: "Denied",
  v_today: "Today",
  v_yesterday: "Yesterday",
  v_thisWeek: "This week",
  v_lastWeek: "Last week",
  v_thisMonth: "This month",
  v_lastMonth: "Last month",
  v_this_year: "This year",
  v_last_year: "Last year",
  v_days_up_to_today: "days until today",
  v_days_starting_today: "days starting today",
  v_jira_synchronization: "Jira synchronization",
  v_type: "type",
  v_chart_label_total: "Total hours by date",
  v_enter_to: "Log in to",
  v_to_get_token: "to get the API token",
  v_login_with: "Login with",
  v_or: "or",
  v_or_continue_with: "or continue with",
  v_date_format: "Date format",
  v_first_day_of_week: "First day of week",
  v_time_format: "Time format",
  v_duration_display_format: "Duration display format",
  v_switch_team_to_the: "Team switch to the",
  v_show: "show",
  v_hide: "hide",
  v_clients: "Clients",
  v_client: "Client",
  v_add_new_client: "Add new client",
  v_client_name: "Client name",
  v_add_client: "Add client",
  v_edit_client_name: "Edit client name",
  v_edit_client: "Edit",
  v_select_client: "Select client",
  v_sync_with_jira_project: "Sync with Jira project",
  v_sync_with_jira_project_exist:
    'Jira project is already connected to the project "{{projectName}}"',
  v_jira_synchronization_problem:
    "The tasks from the Jira were not synchronized. Make sure that the access to Jira is correct",
  v_jira_synchronization_ok: "The tasks from the Jira were synchronized",
  v_add_project_name: "Add project name",
  v_edit_project_name: "Edit project name",
  v_verify: "Verify",
  v_classic: "Classic",
  v_improved: "Improved",
  v_decimal: "Decimal",
  v_short_day: "DD",
  v_short_month: "MM",
  v_short_year: "YYYY",
  v_empty: "Is empty",
  v_empty_email: "E-mail is required",
  v_empty_password: "A password is required",
  v_cofirm_password_required: "Password confirm is required",
  v_add_old_password: "Add current password",
  v_add_new_password: "Add your new password",
  v_jira_synchronization_confirm: "Are you sure you want to sync?",
  v_add_member: "Add member",
  v_member_dots: "Member...",
  v_combine: "Combine tasks",
  v_delete_user: "Delete User",
  v_delete_user_text:
    "The user will be permanently deleted. All teams and entries will be lost. Are you sure you want to delete?",
  v_user_deleted: "User deleted",

  v_v_required: "Required field",
  v_v_incorect_url: "Incorrect URL",
  v_no_report: "No report to show!",
  v_no_entries: "No recent entries to show.",
  v_no_entries_sub: "It's been a long time since you've tracked your tasks!",
  v_no_projects: "The current team has no projects for you",
  v_no_projects_sub: "Ask your team admin to add you to the project",
  v_no_clients: "No clients to show!",

  v_enter_number: "Number",

  v_invoices: "Invoices",
  v_cat_not_invoices: "You don't have invoices with this status yet",
  v_add_new_invoice: "New Invoice",
  v_all_invoices: "All invoices",
  v_invoice: "Invoice",
  v_confirm_payment: "Confirm payment",
  v_cancel_confirm: "Cancel confirmation",
  v_send_invoice: "Send Invoice",
  v_from: "From",
  v_to: "To",
  v_amount: "QTY",
  v_rate: "Rate",
  v_tax: "Tax",
  v_subtotal: "Subtotal",
  v_enter_project: "Text",
  v_pic_tax: "Tax",
  v_payment_confirmation_window_confirm:
    "Are you sure you want to confirm payment?",
  v_payment_confirmation_window_cancel:
    "Are you sure you want to cancel payment?",
  v_edit_confirmation_window:
    "The invoice has already been sent to the client, are you sure you want to edit?",
  v_client_no_email: "The client's Email is not filled",
  v_letter_send: "Invoice was sent",
  v_add_part_of_payment: "Add payment",
  v_added_payments: "Added payments",
  v_date: "Date",
  v_currency: "Currency",
  v_outstanding: "Outstanding",
  v_add_a_payment: "Add a payment",
  v_sum: "Sum",
  v_add_payment: "Add payment",
  v_no_zero_error: "Can't be zero",
  v_no_negative: "Can't be negative",
  v_not_less: "Sum should be less than or equal to outstanding",
  v_no_payments: "No payments. Click “+” to add",
  v_no_active_invoices: "No active invoices",
  v_no_active_invoices_text:
    "There are no active invoices found in the system. This could indicate that all invoices have been paid or that there are no invoices at all.",
  v_create_new_invoice: "Create New Invoice",

  v_invoice_summary: "Invoice Summary",
  v_invoice_reference: "Reference",
  v_comments: "Comments",
  v_select_currency: "Select Currency",
  v_invoice_number: "Invoice No",
  v_invoice_date: "Issued",
  v_invoice_due: "Due Date",
  v_edit: "Edit",
  v_clone: "Duplicate",
  v_delete: "Delete",
  v_download: "Download",
  v_pic_date: "Pic Date",
  v_address: "Address",
  v_add_sender: "Add sender",
  v_send_by_email: "Send by email",
  v_choose_recipient: "Choose recipient",
  v_select_logo_file: "Drag your logo here or select a file",
  v_send_invoice_placeholder: "placeholder",
  v_no_overdues: "No overdues",
  v_no_total: "No totals outstanding",
  v_no_drafts: "No drafts",
  v_last_invoices: "Last Invoices",
  v_clear_filters: "Clear filters",
  v_filter_all_projects: "All Projects",
  v_filter_all_clients: "All Clients",
  v_filter_all_invoices: "All Invoices",
  v_filter_active: "Active",
  v_filter_archived: "Archived",
  v_filter_draft: "Draft",
  v_filter_awaiting: "Awaiting",
  v_filter_reviewed: "Reviewed",
  v_filter_paid: "Paid",
  v_filter_overdue: "Overdue",
  v_grand_total: "Grand total left:",
  v_changing_status: "Changing status on",

  v_will_generate: "Will be generated after creation",
  v_save: "Save",
  v_overdue: "Overdue",
  v_total_outstanding: "Total outstanding",
  v_draft: "Draft",
  v_paid: "Paid",
  v_paid_full: "Paid Full",
  v_paid_zero: "No payments",
  v_reviewed: "Reviewed. Change status?",
  v_overdue_tip: "Overdue. Change status?",
  v_draft_tip: "Draft. Change status?",
  v_paid_tip: "Paid. Change status?",

  v_delete_member: "Delete Member",
  v_clone_invoice: "Your invoice was cloned",
  v_send_payment: "Send invoice",
  v_no_invoices: "No invoices to show",
  v_owner: "Owner",
  v_admin: "Admin",
  v_manager: "Invoices Manager",
  v_hr_manager: "HR Manager",
  v_proj_manager: "Project Manager",
  v_recruiter: "Recruiter",
  v_member: "Member",
  v_roles: "Roles",

  v_state: "State",
  v_country: "Country",
  v_city: "City",
  v_enter_text: "Enter text",

  v_zip: "Zip",
  v_address_lowC: "address",
  e_mail: "E-Mail",
  v_billing_code_error: "Invalid zip code",

  v_technologies: "Technology",
  v_add_technology: "Add",
  v_company_name: "Company name",

  client_was_deleted: "Client was deleted",
  client_was_edited: "Client was edited",
  client_was_created: "Client was created",
  client_dont_was_deleted:
    "You cannot delete a client because there are fixed accounts on it. Please delete the accounts associated with it and try again!",

  v_select_logo_file_bigger: "Drag your logo here or select a file",
  v_zip_code: "Zip",
  v_enter_country: "Type country",

  v_street: "Street",
  v_enter_city: "Enter city",
  v_enter_language: "Enter language",
  v_delete_client: "Delete client",
  v_enter_state: "Enter state",
  company_name: "Company name",
  full_name: "First/last name",

  select_country: "Select country",

  v_edit_client_title: "Edit client",
  no_valid_number: "Phone number is not valid",
  no_valid_zip: "Invalid zip code",
  no_valid_user_name: "The First/Last name field cannot contain spaces",
  no_valid_number_mask: "+XXXXXXXXXXXX",
  v_fill_fields: "Please fill all required fields",
  v_fill_fields_company_name: "Please fill company name in sender field",
  v_tags: "Tags",
  v_tags_tooltip: "Skills, technologies, etc.",
  v_cv: "User CV",
  v_edit_sender: "Edit sender",
  v_copy: "Copy",
  v_delete_invoice: "Delete Invoice",
  v_prompt: "Are you sure you want to delete invoice?",
  v_confirm: "Confirm",
  v_invoice_number_exist: "Invoice number exist",
  v_awaiting: "Awaiting. Change status?",
  v_price: "Amount",
  v_status: "Status",
  v_physical_address: "Physical Address",
  v_error_company_name: "Fill in the field - Company name",
  v_time_date: "Time and date",
  v_err_technology_exist: "Tag exist",

  v_search: "Search",
  v_reset: "Reset",
  v_sync_all: "Sync all",
  v_sync_success: "Syncing success",
  v_result: "Results",

  v_support: "Support",
  v_tell_us: "Tell us about your problem",
  v_type_message: "Type message ...",
  v_min: "Must be at least 8 characters",
  v_max: "Must be less  than 500 characters",
  v_message_error: "Error: The letter cannot be empty",
  v_message_sent: "Message sent successfuly",

  v_copy_and_share: "Copy and share link",
  v_show_more: "Show more",
  v_copy_the_link: "Copy the Link",
  v_copy_link: "Copy Link",
  v_done: "Done",
  v_link_copied: "Link copied",
  v_add_a_discount: "Add a Discount",
  v_add_discount: "Add Discount",
  v_discount: "Discount",
  v_discount_subtotal: "of invoice subtotal",
  v_round_to: "Round to:",
  v_report_round: "Decimal rounding of the report",
  v_decimal_report: "Decimal report",
  v_minutes: "minutes",

  v_info: {
    timeTracking: "Time tracking",
    resourcePlanning: "Resource Planning",
    reporting: "Reporting",
    realTimeReports: "Real-time reports",
    invoicing: "Invoicing",
    owner: "Owner",
    admin: "Admin",
    member: "Member",
    invoiceManager: "Invoice manager",
  },

  v_extra: {
    price: "€5/mo",
    feature1: "+1 client",
    feature2: "+1 team",
    feature3: "+2 projects",
    star: "*one-time opportunity",
  },

  v_choose: "Choose your plan",

  v_coupon_doesnt_exist: "This coupon does not exist",
  v_coupon_expired: "This coupon is expired and cannot be applied",
  v_coupon_used_up: "This coupon is used up and cannot be applied",

  v_rp_history: {
    historyS: "history",
    historyL: "History",
    header: {
      when: "Change date",
      who: "Editor",
      type: "Change type",
    },
    table: {
      created: "Created",
      updated: "Updated",
      from: "From: ",
      to: "To: ",
      plan: "Plan name",
      period: "Plan period",
      project: "Project name",
      user: "Assignee",
      description: "Description",
    },
  },

  v_hr_people: {
    leftBar: "Recruitment",
    header: {
      title: "Recruitment",
      board: "Directions",
      applications: "Applications",
      vacancies: "Vacancies",
      candidates: "Candidates",
    },
    candidates_modal: {
      preview: "preview",
      draft: "draft",
      edit: "edit",
      personal: "Personal information",
      social: "Social networks",
      start_work: "Availability of starting work",
      about: "About candidate",
      booking: "Booking meeting and feedbacks",
      jobType: "Job type",
      other: "Other",
      desire: "Desire Date",
      start: "Start Work",
      experience: "Experience",
      cv: "CV",
      cvClient: "CV for client",
      level: "Level",
      salary: "Salary",
      hrInt: "Hr interview date",
      techInt: "Tech interview date",
      feedHr: "Feedback HR",
      feedTech: "Feedback Tech",
      namePlace: "Type name...",
      surnamePlace: "Type surname...",
      emailPlace: "Type e-mail...",
      countryPlace: "Choose country",
      birthdayPlace: "Choose your date of birthday",
      otherPlace: "Other links, etc.",
      desirePlace: "Date want work",
      startPlace: "Date start work",
      cvPlace: "Attach CV",
      direction: "Direction",
      vacancy: "Vacancy",
      noVacancies: "No vacancies",
      minSalPlace: "From",
      maxSalPlace: "To",
      hrIntPlace: "HR interview",
      techIntPlace: "Tech interview",
      feedHrPlace: "Type feedback HR...",
      feedTechPlace: "Type feedback tech...",
    },
    directions: {
      no_directions_text: "There are no directions yet",
      new_direction: "New direction",
      search_by_name_placeholder: "Search by name...",
    },
    candidates: {
      new_candidates: "New candidates",
      no_candidates_text: "There are no candidates yet",
      headerTable: {
        name: "Name",
        job_type: "Job type",
        country: "Country",
        city: "City",
        level: "Level",
        direction: "Direction",
        salary: "Salary:",
        latest_update: "Latest update",
        creation_date: "Creation date",
        junior: "Junior",
        middle: "Middle",
        senior: "Senior",
        trainee: "Trainee",
        remote: "Remote",
        hybrid: "Hybrid",
        office: "Office",
      },
    },
    switcherForVacancies: {
      active: "Active",
      archive: "Archive",
    },
    vacancies: {
      no_vacancies_text: "There are no vacancies yet",
      new_vacancies: "New vacancies",
      vacancies_created: "Vacancy created",
      vacancies_edited: "Vacancy edited",
      link_copied: "Link copied",
      vacancy_data: "Vacancy data",
      job_application: "Job application",
      select_job_application: "Select Job application",
      vacancy_name: "Vacancy name",
      company: "Company",
      type_here: "Type here...",
      priority: "Priority",
      choose_status: "Choose status",
      choose_priority: "Choose priority",
      direction: "Direction",
      experience: "Experience",
      level: "Level",
      job_description: "Job description",
      type_text_here: "Type text here...",
      number_of_positions: "Number of positions",
      type_of_employment: "Type of employment",
      expected_salary: "Expected salary",
      job_location: "Job location",
      location_optional: "Location (optional)",
      job_type: "Job type",
      remote: "Remote",
      hybrid: "Hybrid",
      office: "Office",
      foreign_languages: "Foreign languages",
      additional_information: "Additional information",
      link_to_job: "Link to job vacancies (on different platforms)",
      requirements: "Requirements",
      nice_to_have: "Nice to have",
      working_conditions: "Working conditions",
      preview: "preview",
      responsibilities: "Responsibilities",
      salary_range: "Salary range",
      exit: "Exit",
    },
    vacanciesHeaderTable: {
      name: "Name of vacancy",
      status: "Status",
      priorities: "Priorities",
      number_of_candidates: "Number of candidates",
      latest_update: "Latest update",
      deadline: "Deadline",
      created: "Created",
    },
    new_direction_modal: {
      edit: "Edit direction",
      create: "Create direction",
      name_dir: "Name of the direction",
      name_placeholder: "Type to add direction...",
      vacancies: "Vacancies attached",
      vacancies_placeholder: "Select...",
    },
    applications: {
      no_applications_text: "There are no applications yet",
      new_applications: "New applications",
      archive: "Archive",
      name: "Name",
      typeName: "Type name...",
      direction: "Direction",
      typeDirection: "Type direction...",
      level: "Level",
      typeLevel: "Type level...",
      experience: "Experience",
      typeExperience: "Type experience...",
      salary: "Salary",
      from: "From",
      to: "To",
      deadline: "Deadline",
      invalidDate: "Invalid date",
      applicationPriority: "Application priority...",
      country: "Country",
      searchCountry: "Search country...",
      selectCountry: "Select country",
      city: "City",
      typeEmployment: "Type of employment",
      selectEmployment: "Select type of employment",
      numberPositions: "Number of positions",
      typeNumberPositions: "Type number of positions",
      project: "Project",
      typeProject: "Type name of the project",
      languageSkills: "Language skills",
      enterLanguagesSkills: "Enter languages skills",
      requirements: "Requirements",
      enterRequirements: "Enter the requirements",
      comment: "Comment",
      enterComment: "Enter the сomment",
      successful: "Successful",
      youHaveSuccessful: "You have successfully created a Application",
    },
    headerTable: {
      name: "Name",
      vacancies_available: "Vacancies available",
      priorities: "Priorities",
      number_of_candidates: "Number of candidates",
      latest_update: "Latest update",
      deadline: "Deadline",
      name_applications: "Name applications",
      location: "Location",
      date_of_creation: "Date of creation",
    },
    prioritiesSelectors: {
      high: "High",
      medium: "Medium",
      low: "Low",
    },
    deadlineSelectors: {
      newest: "Newest",
      oldest: "Oldest",
    },
    statusSelectors: {
      open: "Open",
      closed: "Closed",
      rejected: "Rejected",
      onHold: "On hold",
    },
  },

  v_subscription: {
    extraFeatures: "Buy extra features",
    leftBar: "Subscriptions",
    header: {
      all: "All plans",
      overview: "Overview",
      invAndPaym: "Invoices and Payments",
    },
    switcher: {
      trial: "-day free trial included",
      month: "Month",
      year: "Annual",
      discount: "Save up to 20%",
    },
    plan: {
      btnCurrent: "Current plan",
      btnUpgrade: "Upgrade",
      btnDowngarde: "Downgrade",
      mo: "mo per user",
      yr: "mo billed annually",
      btnUpgradeNow: "Upgrade now",
    },
    free: {
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "2 Projects",
      history: "35 days data history",
      clients: "1 Client",
      teams: "1 Team",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Data storage 1 GB",
    },
    basic: {
      teams: "10 Teams",
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "10 Projects",
      history: "365 days data history",
      clients: "10 Clients",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Unlimited data Storage",
    },
    donut: {
      teams: "15 Teams included",
      tracks: "Unlimited time tracking",
      projects: "15 Active projects",
      history: "365 days data history",
      clients: "15 clients",
      invoices: "Invoicing included",
      integrations: "Integrations included",
      storage: "10 GB data storage",
    },
    pro: {
      teams: "Unlimited teams",
      users: "Unlimited users",
      features: "All features included",
      notifications: "Automated Notifications included",
      roles: "Roles Management included",
      projects: "Unlimited projects",
      history: "Unlimited data history",
      clients: "Unlimited clients",
      wobbly: "Wobbly Time App included",
      integrations: "Integrations included",
      storage: "Unlimited data Storage",
    },
    billingModalDowngrade: {
      title: "Subscription overview",
      currentPlan: "Your current plan",
      pait: "paid to",
      text: "plan will be switched to a new plan after your current subscription expires",
      button: "Continue",
    },
    billingModal: {
      next: "Next",
      startExploring: "Start Exploring",
      trialHasNotExpired:
        "You still have not fully used up 'Trial time', it will be lost if you continue. Do you want to make a purchase now?",
      secondStep: {
        title: "Subscription overview",
        plan: "Plan",
        firstCharge: "First charge on",
        billingInfo: "Billing Info",
        changeBilling: "Change",
        paymentInfo: "Payment Info",
        changePayment: "Change",
      },
      congratsStep: {
        congrats: "Congratulations!",
        info: "You can now use all features, including",
      },
      noData: "Please fill payment data",
    },
    billingInfoEditModal: {
      title: "Billing info",
    },
    overview: {
      trialWarning:
        "Please add payment details to continue using {{planName}} after the trial period.",
      trialAddPaymentInfo: "Add payment info",
      updateBillingInfo: "Update",
    },
    invoicesAndPayments: {
      title: "Billing History",
    },
  },
  v_plan_details: {
    subscriptionExpired:
      "Your plan will be changed to 'Free' after your current subscription expires   ",
    subscriptionExpiredTrial:
      "Your plan will be changed to 'Free' after your current trial subscription expires   ",
    status: "Subscription status",
    subscriptionRenewingOn: "Your subscription will be renewed on",
    subscriptionRenewingOnFree: "Free",
    currentPlan: "Current Plan",
    costUserMonth: "Cost User/Month",
    users: "Users",
    daysLeftOnTrial: "Days Left on Trial",
  },
  v_billing_info: {
    title: "Billing Info",
    changeBillingInfo: "Change billing info",
  },
  v_billing_info_details: {
    country: "Country",
    city: "City",
    postalCode: "Zip/Postcode",
    emailAddress: "E-mail",
    street: "Street",
    houseNumber: "House number",
    firstName: "First name",
    lastName: "Last name",
    companyName: "Company name",
    phoneNumber: "Phone number",
  },
  v_payment_info: {
    title: "Payment Info",
    addPaymentInfo: "Add payment info",
    changePaymentInfo: "Change payment info",
  },
  v_payment_method_details: {
    cardDeclined: "Your card is not supported!",
    paymentMethod: "Payment Method",
    noPaymentMethod: "No payment method available",
    labels: {
      country: "Country",
      expiryMonth: "Expiry month",
      expityYear: "Expiry year",
      cardHolder: "Card Holder",
      masked: "Card Pan",
      holder: "Holder",
      bic: "BIC",
      iban: "IBAN",
    },
    methods: {
      CreditCard: "Credit card",
      BankAccount: "Bank account",
    },
  },
  v_billing_history_table: {
    date: "Date",
    description: "Description",
    amount: "Amount",
    invoice: "Invoice",
  },
  v_payment_info_modal: {
    next: "Next",
  },
  v_payment_info_state: {
    updateSuccess: "Payment info has been updated successfully",
    updateError: "Payment info update error",
  },
  v_billing_info_form: {
    country: { label: "Country", placeholder: "Select country" },
    city: { label: "City", placeholder: "Enter city" },
    postalCode: { label: "Zip/Postcode", placeholder: "Enter Code" },
    emailAddress: { label: "E-mail", placeholder: "Enter text" },
    street: { label: "Street", placeholder: "Enter Street" },
    houseNumber: { label: "House number", placeholder: "Enter House number" },
    firstName: { label: "First name", placeholder: "Enter first name" },
    lastName: { label: "Last name", placeholder: "Enter last name" },
    companyName: { label: "Company name", placeholder: "Enter company name" },
    phoneNumber: { label: "Phone number", placeholder: "Enter phone number" },
  },
  v_billing_info_state: {
    updateSuccess: "Billing info has been updated successfully",
    updateError: "Billing info update error",
  },
  v_upgrade_pay_state: {
    updateSuccess: "Subscription has been updated successfully",
    updateError: "Subscription upgrade error",
  },
  v_page_access_denied: "You do not have sufficient rights to view this page",
  v_page_access_request_owner: "Ask the team owner to grant you access",
  v_page_invoice_deleted: "This invoice was deleted",
  v_project_not_available: "The project for this task is not available",
  v_upgrade_projects_title: "Upgrade to create more active projects",
  v_upgrade_projects_description:
    "Unfortunately, you have reached the maximum number of active projects on your plan.",
  v_upgrade_teams_title: "Upgrade to create more teams",
  v_upgrade_teams_description:
    "Unfortunately, you have reached the maximum number of teams on your plan.",
  v_upgrade_button: "Upgrade plan",
  v_upgrade_sidebar_title_trial_banner: "You are using the  Trial version",
  v_upgrade_sidebar_title_trial_banner_coupon: "Last day of trial version",
  v_upgrade_sidebar_text_trial_banner: "days left",
  v_upgrade_sidebar_text_trial_banner_few_days: "day left",
  v_last_trial_day: "Last trial day",
  v_last_trial_day_with_coupon: "Upgrade your plan with a coupon",
  v_upgrade_sidebar_title: "Upgrade plan for more features.",
  v_upgrade_sidebar_button: "Upgrade",
  v_apply_sidebar_button: "Apply code",
  v_upgrade_clients_title: "Upgrade to create more clients",
  v_upgrade_clients_description:
    "Unfortunately, you have reached the maximum number of clients on your plan.",
  v_upgrade_admin_note:
    "If you want to upgrade your plan, please contact your team owner.",
  v_no_results: "No results to show",
  v_project_members: "Project members",
  v_upgrade_label: "Upgrade your plan",
  v_resource_planning: "Resource planning",
  v_collapse: "Collapse",
  v_expand: "Expand",
  v_add_plan: "Add Plan",
  v_delete_plan: "Delete Plan",
  v_delete_plan_confirm: "Are you sure you want to delete Plan?",
  v_edit_plan_confirm: "Are you sure you want to edit plan?",
  v_no_recorded_team_time: "No recorded time in team",
  v_not_active_user: "Not active",
  v_real_time_report: "Real-time report",
  v_rt_reports_plug: {
    title:
      "Manage employee schedules in real-time with work scheduling software.",
    description:
      "Gain insights into project status and workload — real-time reports for the efficient task and project management.",
  },
  v_rp_plug: {
    title: "Simplify team scheduling with resource planning",
    description:
      "Track overall workload for effective resource allocation. Need more capabilities?",
  },
  v_see_plans: "See Plans",
  v_tooltip_upgrade: "This feature is available if you upgrade your package",
  v_real_time: "Real-time",
  v_activity_view: "5 day activity view",
  v_resource_planning_page: {
    v_edit_plan_title: "Edit Plan",
    v_add_plan_title: "Add Plan",
    v_teams: "Teams",
    v_members: "Members",
    v_type_task_name: "Type task name...",
    v_projects: "Projects",
    v_dates: "Dates",
    v_from: "From",
    v_to: "To",
    v_select_or_add: "Select or Add",
    v_description: "Description",
    v_type_description: "Type to add description...",
    v_cancel: "Cancel",
    v_show_all_projects: "Show all projects",
    v_show_all_members: "Show all members",
    v_show_all_tags: "Show all tags",
    v_birthday_date: "Birthday date",
    v_join_date: "Join date",
    v_vacation_reset: "Vacation reset",
    v_country: "Country",
    v_local_time: "Local time",
    v_workload: "Workload",
    v_day_off: "Day off",
    v_total_left: "Total/Left",
    v_sick_leave: "Sick Leave",
    v_vacation: "Vacation",
    v_day_off_with_work_off: "Unpaid vacation",
    v_tags: "Tags",
    v_success_created_plan: "Plan created",
    v_success_edited_plan: "Plan edited",
    v_success_deleted_plan: "Plan deleted",
    v_filter_no_results: "No matches found",
    v_error_start_date: "The start time of the plan is greater than its end",
    v_error_set_wright_date: "Wrong date range, set wright date range",
    v_last_seen: "Last seen",
    v_user_profile_error: "Something went wrong",
    v_success_edited_profile: "Profile has been edited",
    v_user_profile: "User Profile",
    v_edit_user_profile: "Edit Profile",
    v_error_user_is_not_exist_in_project: "User not added to this project",
    v_error_user_does_not_exist_in_this_project:
      "User does not exist in this project",
    v_error_user_not_admin_owner:
      "Only Owner/Admin can create a plan for another user",
    v_error_you_can_not_change_holidays:
      "You cannot change the date of public holidays",
    v_error_delete_plan_in_the_past: "Can not delete plan in the past",
  },
  v_left_bar: {
    time: "TRACK TIME",
    manage: "manage",
    invoice: "INVOICE CLIENTS",
    people: "people",
    other: "other",
  },
  v_days: "days",
  v_members: "Members",
  v_filters: "Filters",
  v_clear_all: "Clear all",
  v_day: "Day",
  v_week: "Week",
  v_month: "Month",
  v_year: "Year",
  v_show_by: "Show by:",
  v_assignee: "Assignee",
  v_plan_info: "Plan Info",
  v_all_members: "All members",
  v_select_projects: "Select projects...",
  v_notifications: "Notifications",
  v_not_notifications: "You don't have notifications",
  v_release_notes: {
    v_title: "Release Notes",
    v_whats_new: "What's New:",
    v_improved: "Improved/Fixed:",
    v_version: "Version",
    v_versions: {
      "1.7.26": {
        v_new: [
          "Work on bugs/improvements.",
          "Subscription payment model has been changed, now the amount depends on the number of users in the team.",
          "The functionality and benefits of subscriptions have been changed.",
          "Added more detailed notifications in case of synchronization error with Jira.",
          "Added the ability to control Workload on the 'Resource Planning' page. View available load and view and edit actual load.",
          "Bench functionality has been added. Possibility to filter out users with no plans on the 'Resource Planning' page.",
        ],
        v_improved: [
          "Fixed a visual error on the login page when the sidebar was visible.",
        ],
      },
      "1.6.25": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Team' and 'Reports'",
          "New sidebar design implemented",
          "Added the possibility to see the total time for billable and not billable tracks on the 'Reports' page",
          "Added the possibility of inviting several users to the Team at a time on the 'Team' page",
        ],
        v_improved: [
          "Fixed bug with creating a plan for the wrong date that was selected and inability to edit plan daterange on the 'Resource Planning' page",
          "Visual improvements on the 'Resource Planning' page",
          "Fixed a bug with updating the status of an invoice when it is assigned the 'Awaiting' status on the 'Invoices' page",
          "Visual improvements on the 'Invoices' page",
        ],
      },
      "1.6.24": {
        v_new: [
          "Work on bugs/improvements in the 'Invoices', 'Resource Planning', 'Teams', 'User Profile', 'Reports', 'Projects', 'Clients' and 'Timer'",
        ],
        v_improved: [
          "Track no longer stops when switching Teams",
          "Fixed a bug when Owner could not remove Admin from the project on the 'Projects' page",
          "Removed the field of synch with Jira when creating and editing a project on the 'Projects' page",
          "Visual and functional improvements on the 'Projects' page",
          "Fixed a bug when Invoice Manager saw the report only by itself on the 'Reports' page",
          "Fixed visual bug with incorrect highlighting of daterange in the calendar on the 'Reports' page",
          "Fixed a bug with incorrect display of birth date calendar on the 'User Profile' page",
          "Fixed a bug where user tags were lost when changing role on the 'Team' page",
          "Visually improved user info window on the 'Resource Planning' page",
          "Improved display of the tooltip with the plan description when there are few users on the 'Resource Planning' page",
          "Improved work of the tooltip with plan description on the 'Resource Planning' page",
          "Changed the order of action for copying a link to an invoice on the 'Invoices' page",
          "Fixed bug with infinite loading of the list of Teams when viewing a sent invoice on the 'Invoices' page",
          "Visual improvements when filling out an invoice on the 'Invoices' page",
        ],
      },
      "1.6.23": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the possibility to purchase a subscription, for which the trial is valid",
        ],
        v_improved: [
          "Fixed a bug where user's name in filters was duplicated on the 'Report' page",
          "Fixed a bug where PM, HR manager and Invoice managers could not use cross-timed reports on the 'Report' page",
          "Fixed a bug where the color of the tooltip did not change when switching the theme on the 'Resource Planning' page",
          "Fixed a bug where PM and HR manager could not invite new users to the team on the 'Team' page",
        ],
      },
      "1.6.22": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to see reports on users with denied access",
        ],
        v_improved: [
          "Improved filter logic on the 'Reports' page",
          "Fixed several bugs on the 'Reports' page",
          "Reports are updated immediately after a Team change",
          "Fixed several bugs on the 'Project' page",
          "Fixed search on 'Client' and 'Project' pages",
          "Improvements have been made to the change history on the 'Resource Planning' page",
        ],
      },
      "1.6.21": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Resource Planning' and 'Timer'",
          "Added the ability to set tracks for the future in 'Timer' page",
          "Added automatic trimming of the estimate when copying a Jira-synchronized task",
          "Added history of plan changes in 'Resource Planning' page",
        ],
        v_improved: [
          "Improved work of the reports page",
          "Fixed a bug where the report was exported incorrectly when cross-team was enabled in 'Reports' page",
          "Several visual improvemets in sidebar",
          "Several visual improvemets in 'Resource Planning' page",
        ],
      },
      "1.6.20": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Roles', 'Resource Planning', 'Timer', 'Projects' and 'E-mail'",
        ],
        v_improved: [
          "Added mass synch with Jira in 'Timer' page",
          "Role-based accesses have been updated",
          "Fixed minor errors with roles",
          "Fixed bug with drag and drop plan by Recruiter and Memebers in 'Resource Planning' page",
          "Fixed a bug where the recruiter and the memeber only saw the plans of the projects to which they were added 'Resource Planning' page",
          "Fixed minor errors in 'Resource Planning' page",
          "Added weekly report emails for new roles",
          "Weekly reports updated, time period added",
          "Fixed a bug with deleting a user from the project in 'Projects' page",
        ],
      },
      "1.6.19": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Team', ''Resource Planning' and 'Invoices'",
          "Added connection with support",
        ],
        v_improved: [
          "User roles have been updated, added new roles in 'Team' page",
          "Added tooltips for collapsed sidebar",
          "Corrected the display of holidays in 'Resource Planning' page",
          "Visual bugs fixed in 'Reports' page",
          "Visual bugs fixed in 'Invoices' page",
        ],
      },
      "1.6.18": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Timer', 'Register page', 'Projects' and 'Invoices'",
          "Added the ability to log in with Google",
        ],
        v_improved: [
          "Several visual improvemets in 'Register page'",
          "Several visual improvemets in 'Reports' page",
          "Fixed a bug when large reports hang up in 'Repors' page",
          "Updated weekly e-mail reports on time tracked",
          "Fixed a bug when a user was not deleted from the project  in 'Projects' page",
          "Several visual improvemets in 'Free invoice generator' page",
          "Several visual improvemets in 'Invoices' page",
        ],
      },
      "1.6.17": {
        v_new: [
          "Work on bugs/improvements in the 'Subscriptions', 'Reports', 'Clients', 'Email' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Several visual improvemets in 'Subscriptions' page",
          "Several visual improvemets in 'Reports' page",
          "Several visual improvemets in 'Clients' page",
          "Several visual improvemets in 'Resource Planning' page",
          "New weekly e-mail reports on time tracked",
        ],
      },
      "1.6.16": {
        v_new: [
          "Work on bugs/improvements in the 'Invoice' and 'Resource Planning'",
        ],
        v_improved: [
          "Several minor improvements in the 'Resource Planning' section",
          "Added ability to change invoice status in the 'Invoices' section",
        ],
      },
      "1.6.15": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Projects' and  'Subscriptions'",
        ],
        v_improved: [
          "Improved calendar in the Add Plan window in the 'Resource Planning' section",
          "Fixed calendar in the User Profile window for web-mobile in the 'Resource Planning' section",
          "Several minor improvements for web and web-mobile in the 'Resource Planning' section",
          "Visual and logical update for coupons in the 'Subscription' section",
          "Several minor improvements in the 'Subscription' section",
          "Fixed 'Team' filter when Cross-team is active in the 'Reports' section",
          "Fixed a bug when interacting with the report on a user not from the current team in the 'Reports' section",
          "Several minor improvements in the 'Reports' section",
          "Fixed 'Total outstanding' in the 'Invoices' section",
          "Fixed tool-tips in the 'Invoices' section",
          "Fixed Free Invoice Generator",
          "Fixed detailed report in the 'Projects' section",
        ],
      },
      "1.6.14": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning', 'Subscriptions' and E-mail sections",
        ],
        v_improved: [
          "Fixed a bug when filters were dropping when scrolling to a new month in the 'Resource Planning' section",
          "Fixed colors of projects in the calendar in the 'Resource Planning' section",
          "The selected filter is moved up in the 'Resource Planning' section",
          "Fixed an error when filters were dropping when deleting a plan in the 'Resource Planning' section",
          "Fixed project colors in filters in the 'Resource Planning' section",
          "Visual improvemetns of 'Resource Planning' page in the 'Resource Planning' section",
          "Fixed photo picker in invoices in the 'Invoices' section",
          "Visual improvemetns of 'Invoices' page for web-mobile",
          "Fixed several minor visual/performance bugs in the 'Invoices' section",
          "Coupons are now saved from the beginning to the end of the trial in the 'Subscriptions' section",
          "The full amount is now visible when you buy a year's subscription in the 'Subscriptions' section",
          "Fixed a bug when users' E-mails were displayed instead of their names in real-time reports when cross-team reports is active in the 'Reports' section",
          "A filter by team has been added to the cross-team reports in the 'Reports' section",
          "Improved web-mobile version in the 'Reports' section",
          "Improved Real-Time Reports page in the 'Reports' section",
          "Fixed several minor visual/performance bugs in the 'Reports' section",
          "Fixed an error that prevented emails from being sent correctly in the 'E-mails' section",
          "Visual improvements/performance in other sections",
        ],
      },
      "1.6.13": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice', 'Resource Planning' and E-mail sections",
        ],
        v_improved: [
          "Fixes filters for summary cross-team reports on the 'Reports'",
          "Reports now sorted by time on the 'Reports'",
          "Added count of users in selected status by online on the 'Reports'",
          "Changed URL for real-time reports on the 'Reports'",
          "Visual changes 'Add plan' window on the 'Resource Planning'",
          "Improved displaying plan name by plan duration in 'Resource Planning'",
          "The plan now has a default duration of 24 hours on the selected day in 'Resource Planning'",
          "Improved 'Join date' window in User Profile window in 'Resource Planning'",
          "Visual improvements in User Profile window in 'Resource Planning'",
          "Visual improvements 'Resource Planning' page",
          "Invoices page reworked when there no invoices",
          "Visual improvements 'Invoices' page",
          "Fractional numbers are available in the QTY and rate fields in 'Invoices'",
          "Fixed bug when trial didn't start",
          "Added errors when using invalid coupons",
          "Added possibility to unsubscribe from e-mails",
          "Several visual improvements",
        ],
      },
      "1.6.12": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "The possibility of using promotional codes has been added",
        ],
        v_improved: [
          "Visual improvements to the loaded Invoice",
          "Fixed a bug when the invoice was not created due to the time zone",
          "Now plan can be created without name in 'Resource Planning'",
          "Bug fixed, cross-team report showed the wrong time, tracked by the user in 'Reports'",
          "Added the ability to enter fractional numbers in QTY and Rate in 'Invoice'",
          "Visual improvements to filters on the 'Resource Planning'",
          "Fixed several minor visual/performance bugs",
        ],
      },
      "1.6.11": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
          "Added Cross-team Reports",
        ],
        v_improved: [
          "Fixed sidebar opening bug when switching to Subscriptions page",
          "Visual improvements to the running timer",
          "Fixed online filters in real-time reports 'Reports' page",
          "Shows the number of the week in the year when the show buy week on the 'Resource Planning' page",
          "Fixed header on the 'Resource Planning' page",
          "Fixed a bug where the created plan was not displayed on the 'Resource Planning' page",
          "Added the ability to start the trial period without entering a card",
          "Several visual improvements",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.10": {
        v_new: [
          "Work on bugs/improvements in the 'Reports', 'Invoice' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Fixed visual bugs in invoice creating page",
          "Fixed visual bugs on 'Free Invoice Generator' page",
          "'Grand total' replaced by 'Grand total left', displays the total amount expected to be paid on 'Invoices' page",
          "The unpaid part is displayed for each invoice now",
          "Fixed visual bugs in 'Resource Planning'",
          "Added the ability to show calendar by year in 'Resource Planning'",
          "Visual improvements to filters on the 'Resource Planning' page",
          "Visual improvements to filters on the 'Reports' page",
          "Real-time Reports show tags instead of roles",
          "Fixed translation in 'Subscriptions'",
          "Fixed timer display when sidebar is hidden",
          "Improved emails",
          "Fixed several minor visual bugs",
        ],
      },
      "1.6.9": {
        v_new: [
          "New Subscriptions",
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections",
        ],
        v_improved: [
          "Increased the number of characters displayed in the task name in 'Reports'",
          "Fixed location of 'summary / real-time' buttons in 'Reports'",
          "Added the ability to hide to hide side bar",
          "Now the header in the 'Resource Planning' is hidden when scrolling down",
          "Filters in 'Resource Planning' are moved to separate buttons",
          "Improved display of plan names in 'Resource Planning'",
          "When hovering on the plan in 'Resource Planning', you can see the start date, end date and duration of the plan",
          "Added the ability to hide usernames in 'Resource Planning'",
          "Fixed slider in 'Resource Planning'",
          "The space is automatically removed in the username if it is in front of the name in 'User profile'",
          "User CV is not displayed when editing a user through 'My team'",
          "Fixed bugs when moving and creating plans in 'Resource Planning'",
          "Week numbering bug fixed in 'Resource Planning'",
          "Fixed a bug with the username when adding it to the team in Notifications",
          "Notifications are now displayed from newest to oldest",
          "Fixed several minor visual bugs",
        ],
      },
      "1.5.9": {
        v_new: [
          "Work on bugs/improvements in the Resource Planning section.",
          "Release versions removed from notification bell. Useful app notifications will now be displayed there.",
        ],
        v_improved: [
          "Added an error when dragging a plan to a user who is not in the project, but to whom the plan was transferred in 'Resource Planning'",
          "Fixed pop-up window when hovering over the plan with the cursor. The window is now displayed under the cursor.",
          "Fixed display of users with a long name in the 'Resource Planning' section",
          "Improved readability of texts in the 'Resource Planning' section on user plans",
          "Changed the limit on the number of characters in the username in the 'Profile Settings' of the user. The user can enter 100 characters",
          "Fixed display of username with long name in user plan under Resource Planning",
          "Added sticky header in resource scheduling",
        ],
      },
      "1.5.8": {
        v_new: [
          "Several new messages have been added to the e-mail, which will now be sent and will help the user working with Wobbly.",
          "Added weekly e-mail reports for owner/admin about members' time tracked in favorites projects",
          "Added weekly e-mail reports for members on time tracked",
        ],
        v_improved: [
          "Members now have the ability to switch Billable/Non-billable in Reports for their tracked tasks",
          "Owner and Admin now have the ability to switch Billable/Non-billable in Reports for the tracked tasks of all users",
          "Only Owner and Admin now can edit Member's User profile in Resource planning",
          "Added the ability to select a year when setting the date of birth in Profile settings",
          "Added the ability to delete a plan in the past in Resource planning",
        ],
      },
      "1.5.7": {
        v_new: [
          "Work on bugs/improvements in the 'Reports' and 'Resource Planning' sections.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed error editing User Profile card by Admin and Owner. The edit button was missing for these roles.",
          "Fixed filters in the 'Resource Planning' section. The filter did not display any results.",
          "Fixed display of user statuses in the 'Resource planning' section. The user's online status was displayed if the user's timer was active in another team.",
          "Fixed navigation arrows for switching dates in the Resource Planning section. The arrows moved by dates within two weeks/months.",
          "Improved visual in Resource Planning section.",
          "Improved work of filters in the 'Reports' section, 'Real-time' page.",
          "Fixed a bug in the 'Reports' section, 'Summary' page. A report with numerous projects and users was not displayed.",
          "Improved/simplified the creation of a per-user plan in the Resource Planning section. When creating a plan for a team member, only those projects on which the user is added are displayed.",
          "Fixed the result of displaying users in the 'Reports' section, 'Real-time' page. Users with status 'Recent' was not displayed in the filter if it was with an active timer in another team.",
        ],
      },
      "1.5.6": {
        v_new: [
          "Added the ability to delete a user account.",
          "Slightly redesigned interaction with the 'Reports' section. 'Reports' are divided into 'Summary' and 'Real-time' pages.",
          "Added some new error messages that the user might encounter.",
        ],
        v_improved: [
          "Improved interaction with the calendar when creating/editing a plan in the 'Resource Planning' section.",
          "Fixed login error messages. Some errors were not displayed.",
          "Fixed time division in the 'Timer' section after 00.00. Tasks that were stopped manually after 00.00 were saved in one time period.",
          "Fixed a bug with displaying project colors in the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.4.5": {
        v_new: [
          "Added 'Real-time report' to the 'Reports' section. Now Owner and Admin can view which task the user is currently recording or the last recorded task for five days.",
          "Added several new colors for projects.",
        ],
        v_improved: [
          "Fixed a bug with changing teams. Now, when changing the team on one browser, the user's team will change on the other browser.",
          "Improved the speed of the 'Timer' section.",
          "Fixed several minor visual bugs.",
        ],
      },
      "1.3.4": {
        v_new: ["Bug fixes, visual fixes, application speed improvements."],
        v_improved: [
          "Fixed a few minor visual bugs.",
          "The light and dark switch has been moved to the app logo from the “Profile Settings” section. Also, now visually changed from a switch to a button.",
          "Improved speed of processing requests and responses in the Timer, Reports, and Projects sections.",
          "Improved the visual components of the “Light” and “Dark” themes.",
          "Added the ability to download a 1-minute rounded report in 'Decimal report'.",
          "Fixed “Billable /Non-billable” task merge bug when viewing project/people reports in detail. Now, tasks with the same name on different “Billable/Non-Billable” statuses are not merged.",
        ],
      },
      "1.3.3": {
        v_new: [
          "Added the ability to record the time as Billable/Non billable in the 'Timer' section.",
          "Added Online status for users. In the 'Projects' section, when viewing users on projects, you can see the green status Online which displayed if the user is currently recording a task in the timer.",
          "Added 'Decimal rounding of reports' in the 'Reports' section. Now the user can upload reports in the new format.",
        ],
        v_improved: [
          "Fixed several minor visual bugs.",
          "Fixed display of usernames in the left menu field.",
          "Fixed editing task time in the 'Timer' section. Tasks from different projects could overlap in time.",
          "Fixed a bug with displaying a client in a project if the client was deleted from the 'Clients' section. The client that was deleted is no longer displayed in the project.",
        ],
      },
      "1.2.2": {
        v_new: [
          "Added a new ability to add projects to favorites, for quick access to projects.",
          "Added display of the total recorded time per month for all teams under the user profile.",
        ],
        v_improved: [
          "User profile. Added a notification if the user missed a required field.",
          "Invoices. Recent invoices now show the company name instead of the customer's name.",
          "The task in the timer can be started without a name.",
          "Added several error messages that users may encounter.",
          "Now users can see other members of the shared project.",
        ],
      },
      "1.1.1": {
        v_new: ["Added new Wobbly light theme in profile settings"],
        v_improved: [
          "Improved search for invoices by: Client name, Company name, Invoice number, Description, Reference. Now the invoice can be found by all these fields",
          "Fixed bug with changing logo in invoices.",
          "Fixed bug with pulling archived clients when increase/decrease a subscription.",
          "Fixed errors when downloading a detailed/summary reports. Reports could come encrypted or be incomplete.",
          "Fixed bug when combining task names in the Reports section.",
          "Fixed search in the Projects section.",
          "Added several error messages that users may encounter.",
          "Fixed bugs in the Timer section. Editing tasks with overlapping times on different teams.",
          "Fixed errors when purchasing a subscription by a user with the Admin role. The subscription now applies to all team members.",
          "Fixed client removal. When deleting a client, the invoices that were sent to him now do not disappear",
        ],
      },
      "1.0": {
        v_new: ["Added release notes"],
        v_improved: [
          "Fixed display special characters in the project name by code when viewing detailed reports in the 'Reports' section.",
          "We fixed a bug where the partial search in the 'Projects' section did not display all matches.",
          "Fixed monitor 'Total hours by date' in the 'Reports' section. Fixed visual display by dates.",
          "Fixed search by invoice numbers and page transitions. Now the search is not reset when viewing the invoice.",
          "Added the ability to search for an invoice by Description and Reference.",
          "Added error notification when creating/editing a project with the same name.",
          "Fixed a bug related to special characters in the description of tasks in the 'Timer' section.",
          "Fixed a bug with inviting a new user to the team",
          "Added a few new error messages that the user may experience.",
          "Fixed a bug in the Timer section. Recording time in advance and editing time with the intersection of tasks by time.",
        ],
      },
    },
  },
};
