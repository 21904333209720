import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment, { lang } from "moment";

import TechnologyTag from "../../Unstructured/TechnologyTag";

import countries from "../../../locales/countries";

import { IDictionary } from "../../../types/dictionary";
import {
  IRPUser,
  IRPUserInfo,
} from "../../../types/resource-planning.interface";

import "./style.scss";
import { IUserTechnology } from "../../../types/team.interface";

const InfoItem = ({
  title,
  sub,
  value,
}: {
  title: string;
  sub: string;
  value: string;
}) =>
  (typeof value === "string" && value) ||
  (typeof value === "object" && moment(value).isValid()) ||
  typeof value === "number" ? (
    <div className="rp-user-modal-info__info">
      <div className="rp-user-modal-info__label" title={title}>
        {title}
      </div>
      <div className="rp-user-modal-info__sub" title={sub}>
        {sub}
      </div>
      <div className="rp-user-modal-info__value">{value}</div>
    </div>
  ) : null;

interface IUserModalFormProps extends RouteComponentProps {
  vocabulary: IDictionary<any>;
  userData: IRPUserInfo;
}

class ResourcePlanningUserModalInfo extends Component<IUserModalFormProps> {
  formatDate = (date: string) => {
    const lang = this.props.vocabulary.lang;

    return moment(date).isValid()
      ? moment(date).locale(lang.short).format("MMM D, YYYY")
      : "";
  };

  render() {
    const { vocabulary, userData } = this.props;
    const { v_resource_planning_page, lang, v_country } = vocabulary;
    const {
      v_birthday_date,
      v_join_date,
      v_vacation_reset,
      v_local_time,
      v_workload,
      v_day_off,
      v_sick_leave,
      v_vacation,
      v_day_off_with_work_off,
      v_tags,
      v_total_left,
    } = v_resource_planning_page;

    const {
      join_date,
      vacation_reset,
      currentWorkload,
      workload,
      day_off,
      sick_leave,
      vacation,
      work_off,
      user,
    }: IRPUserInfo = userData;

    const {
      user_technology,
      country,
      birthday_date,
      timezone_offset,
    }: IRPUser = user;

    return (
      <div className="rp-user-modal-info">
        <div className="rp-user-modal-info__info-container">
          <InfoItem
            title={v_birthday_date}
            sub={``}
            value={this.formatDate(birthday_date)}
          />
          <InfoItem
            title={v_join_date}
            sub={``}
            value={this.formatDate(join_date)}
          />
          <InfoItem
            title={v_vacation_reset}
            sub={``}
            value={this.formatDate(vacation_reset)}
          />
          <InfoItem
            title={v_country}
            sub={``}
            value={(countries as any)[lang.short][country]}
          />
          <InfoItem
            title={v_local_time}
            sub={``}
            value={moment().subtract(timezone_offset).utc().format("HH:mm")}
          />
          <InfoItem
            title={v_workload}
            sub={``}
            value={`${moment
              .duration(currentWorkload)
              .asHours()
              .toFixed()}h/${workload}h`}
          />
        </div>
        <div className="rp-user-modal-info__separator"></div>
        <div className="rp-user-modal-info__info-container">
          <InfoItem title={v_day_off} sub={v_total_left} value={day_off} />
          <InfoItem
            title={v_sick_leave}
            sub={v_total_left}
            value={sick_leave}
          />
          <InfoItem title={v_vacation} sub={v_total_left} value={vacation} />
          <InfoItem title={v_day_off_with_work_off} sub={``} value={work_off} />
        </div>
        <div className="rp-user-modal-info__value rp-user-modal-info__tags-label">
          {v_tags}
        </div>
        {user_technology && Boolean(user_technology.length) && (
          <div className="rp-user-modal-info__tags">
            {user_technology.map((technology: IUserTechnology) => (
              <TechnologyTag
                key={technology.technology.id}
                title={technology.technology.title}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourcePlanningUserModalInfo),
);
