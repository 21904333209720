import React, { Component } from "react";

import moment from "moment";

import "./style.scss";

export default class ResourcePlanningPlanModalDeletePlan extends Component {
  render() {
    const {
      vocabulary,
      closeModalDelete,
      deletePlan,
    } = this.props;
    const { v_confirm, v_delete_plan_confirm, v_delete_plan } = vocabulary;

    return (
      <div className="delete-modal">
        <div className="delete-modal__background" />

        <div className="delete-modal__container">
          <div className="delete-modal__container-header">
            <div className="delete-modal__container-header-title">
              {v_delete_plan}
            </div>
            <i
              className="delete-modal__container-header-close"
              onClick={() => closeModalDelete(false)}
            />
          </div>
          <div className="delete-modal__container-content">
            {v_delete_plan_confirm}
          </div>
          <div
            className="delete-modal__container-btn"
            onClick={() => {
              deletePlan();
            }}
          >
            <div>
              <span>{v_confirm}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
