import React from "react";
import cn from "classnames";

import "./style.scss";

if (localStorage.getItem("themes") === null) {
  localStorage.setItem("themes", "dark");
}

const SwitcherTheme = ({ period, handleChange, themes }) => (
  <div className="preriod-switcher-themes" onClick={handleChange}>
    <div
      className={cn("preriod-switcher-themes__conteiner", {
        "preriod-switcher-themes__conteiner__light": localStorage.getItem("themes") === "light",
      })}
    >
      <p
        className={cn("preriod-switcher-themes__text", {
          "preriod-switcher-themes__black": localStorage.getItem("themes") === "light",
        })}
      >
        {localStorage.getItem("themes") === "light" ? (
          <span>
            {" "}
            <svg className="preriod-switcher-themes__text__sun" />{" "}
          </span>
        ) : (
          <span>
            {" "}
            <svg className="preriod-switcher-themes__text__moon" />{" "}
          </span>
        )}
      </p>
      <p
        className={cn("preriod-switcher-themes__text", {
          "preriod-switcher-themes__black": localStorage.getItem("themes") === "dark",
        })}
      ></p>
      <div
        className={cn("preriod-switcher-themes__runner", {
          "preriod-switcher-themes__right": localStorage.getItem("themes") === "light",
          "preriod-switcher-themes__left": localStorage.getItem("themes") === "dark",
        })}
      />
    </div>
  </div>
);

export default SwitcherTheme;
