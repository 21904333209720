import React, { useState } from "react";
import { connect } from "react-redux";
import { syncAllJiraTasks } from "../../../api/sync";
import { getTimeEntriesListAction } from "../../../redux/actions/MainPageAction";
import { showNotificationAction } from "../../../redux/actions/NotificationActions";
import cn from "classnames"
import "./style.scss";

const SyncAll = ({
  vocabulary,
  showNotificationAction,
  getTimeEntriesListAction,
  currentTeam,
}) => {
  const { v_sync_all, v_sync_success } = vocabulary;
  const [isUpdating, setIsUpdating] = useState(false);
  const syncAllTasks = async () => {
    setIsUpdating(true);
    try {
      await syncAllJiraTasks();
      await getTimeEntriesListAction();
       showNotificationAction({
         text: v_sync_success,
         type: "success",
       });
    } catch (error) {
      if (error.response.data.message) {
        showNotificationAction({
          text: vocabulary[error.response.data.message],
          type: "error",
        });
      } else {
        console.log(error);
      }
    } finally {
      setIsUpdating(false);
    }
    return;
  };

  const disabledSubscriptionCheck =
    currentTeam.data.userPlan?.name === "Free" ||
    currentTeam.data.userPlan?.name === "Starter";

  return !isUpdating ? (
    <button
      className={cn("timer-search__sync-all-btn", {
        "timer-search__sync-all-btn__disabled-check": disabledSubscriptionCheck,
      })}
      onClick={() => syncAllTasks()}
      disabled={disabledSubscriptionCheck}
    >
      {v_sync_all}
    </button>
  ) : (
    <button className="timer-search__sync-all-btn__disabled">
      {v_sync_all}
    </button>
  );
};

const mapStatetToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  currentTeam: state.teamReducer.currentTeam,
});

const mapDispatchToProps = {
  showNotificationAction,
  getTimeEntriesListAction,
};

export default connect(mapStatetToProps, mapDispatchToProps)(SyncAll);
