import React, { useRef, useState } from "react";
import Proptypes from "prop-types";
import classNames from "classnames";

import { connect } from "react-redux";

import "./style.scss";

const InputWithMaxLength = ({
  placeholder,
  maxLength = 60,
  withValidation = false,
  errorMsg,
  vocabulary,
  onChange,
  onBlur,
  name,
  value,
  id,
  disabled =false,
}) => {
  const inputRef = useRef(null);
  const [availableSymbols, setAvailableSymbols] = useState(maxLength);

  const handleChange = (event) => {
    const targetValue = event.target.value;
    const newAvailableSymbols = maxLength - targetValue.length;

    if (newAvailableSymbols < 0) {
      return;
    }

    setAvailableSymbols(newAvailableSymbols);
    onChange(event);
  };

  return (
    <div className="input-max-length">
      <div className="input-max-length__control-container">
        <input
          id={id}
          className={classNames("input-max-length__control", {
            "input-max-length__control--error": errorMsg && withValidation,
            "input-max-length__control--disabled": disabled,
          })}
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleChange(event)}
          onBlur={onBlur}
          disabled={disabled}
        />
        <div className="input-max-length__length-value">{availableSymbols}</div>
      </div>
      {withValidation ? (
        <div className="input-max-length__error-msg">
          {errorMsg ? vocabulary[errorMsg] : ""}
        </div>
      ) : null}
    </div>
  );
};

InputWithMaxLength.propTypes = {
  value: Proptypes.string,
  setValue: Proptypes.func,
  vocabulary: Proptypes.object,
  placeholder: Proptypes.string,
  maxLength: Proptypes.number,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(InputWithMaxLength);
