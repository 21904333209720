import React, { Component } from "react";
import { connect } from "react-redux";
import ResourcePlanningAssigneeItem from "../ResourcePlanningAssigneeItem";
import { ArrowIcon, CrossIcon, ProfileIcon } from "../../Atoms/SvgIcons";

import { scrollToAction } from "../../../redux/actions/ResponsiveActions";

import "./style.scss";

import { AppConfig } from "../../../config";
import classNames from "classnames";

class ResourcePlanningAssigneeSelect extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();
    this.input = React.createRef();

    this.state = {
      isOpen: false,
      inputValue: "",
      members: props.members.data,
      selectedMember: props.initialValue
        ? props.members.data.find((u) => u.user.id === props.initialValue)
        : null,
    };
  }

  openDropdown = (event) => {
    const { onChangeVisibility, disabled } = this.props;

    if (disabled) {
      return;
    }
    this.setState(
      {
        isOpen: true,
      },
      () => onChangeVisibility(true),
    );
    document.addEventListener("click", this.closeDropdown);
  };

  closeDropdown = (event) => {
    if (event.target.classList.length) {
      const { onChangeVisibility } = this.props;

      document.removeEventListener("click", this.closeDropdown);
      this.setState(
        {
          isOpen: false,
        },
        () => onChangeVisibility(false),
      );
    }
  };

  onChangeInput = (event) => {
    const value = event.target.value;

    this.setState({
      inputValue: value.trim().toLowerCase(),
    });
  };

  removeLabel = (event) => {
    event.stopPropagation();
  };

  selectMember = (member) => {
    const { selectedMember } = this.state;
    const memberId = member?.user?.id || null;
    const selectedMemberId = selectedMember?.user?.id || null;

    this.setState({
      selectedMember: selectedMemberId === memberId ? null : member,
      members: this.props.members.data,
      inputValue: "",
      isOpen: false,
    });
    this.props.onChange(selectedMemberId === memberId ? null : memberId);
  };

  handleChangeInput = (event) => {
    const targetValue = event.target.value;
    const afterSearch = this.props.members.data.filter((member) =>
      member.user.username
        .toLowerCase()
        .includes(targetValue.toLowerCase().trim()),
    );

    this.setState({
      inputValue: event.target.value,
      members: afterSearch,
      isOpen: true,
    });
  };

  componentWillUnmount() {
    const { onChangeVisibility } = this.props;

    onChangeVisibility(false);
    document.removeEventListener("click", this.closeDropdown);
  }

  render() {
    const { vocabulary, errorMsg, withValidation, placeholder, disabled } =
      this.props;
    const { v_resource_planning_page } = vocabulary;
    const { v_members } = v_resource_planning_page;
    const { isOpen, members, selectedMember, inputValue } = this.state;

    return (
      <div className="select-assignee">
        <div
          className={classNames("select-assignee__control", {
            "select-assignee__control--error": withValidation && errorMsg,
            "select-assignee__control--disabled": disabled,
          })}
          onClick={this.openDropdown}
        >
          <ProfileIcon color="var(--color-resource-plaaning)" />
          {selectedMember ? (
            <div className="select-assignee__labels-container">
              <div className="select-assignee__label">
                <span className="label_length">
                  {selectedMember.user.username}
                </span>
                <button
                  className="select-assignee__delete-btn"
                  onClick={() => this.selectMember(selectedMember)}
                >
                  <CrossIcon color="#B9BBBE" />
                </button>
              </div>
            </div>
          ) : (
            <input
              className="select-assignee__input"
              type="text"
              value={inputValue}
              placeholder={placeholder}
              onChange={this.handleChangeInput}
            />
          )}

          <div className="select-assignee__arrow">
            <ArrowIcon color="white" />
          </div>
        </div>
        {withValidation && errorMsg ? (
          <div className="select-assignee__error-msg">
            {errorMsg ? vocabulary[errorMsg] : ""}
          </div>
        ) : null}
        {isOpen && (
          <div className="select-assignee__dropdown">
            <div className="select-assignee__group">
              <div className="select-assignee__title">{v_members}</div>
              <div className="select-assignee__grid">
                {members
                  .filter((user) => user.is_active)
                  .map((member, i) => (
                    <ResourcePlanningAssigneeItem
                      key={i}
                      name={member.user.username}
                      avatar={`${AppConfig.apiURL}/${member.user.avatar}`}
                      onSelect={() => this.selectMember(member)}
                      isSelected={Boolean(
                        selectedMember?.user?.id === member.user.id,
                      )}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ResourcePlanningAssigneeSelect.defaultProps = {
  onChangeVisibility: () => {},
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isMobile: state.responsiveReducer.isMobile,
  members: state.teamReducer.currentTeamDetailedData,
});

const mapDispatchToProps = {
  scrollToAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcePlanningAssigneeSelect);
