import React, { Component } from "react";

import "./style.scss";

export default class ResourcePlanningPlanModalEditPlanConfirm extends Component {
  render() {
    const { vocabulary, closeModal, handleConfirm } = this.props;
    const { v_confirm, v_edit_plan_confirm, v_resource_planning_page } =
      vocabulary;
    return (
      <div className="confirm-modal">
        <div className="confirm-modal__background" />

        <div className="confirm-modal__container">
          <div className="confirm-modal__container-header">
            <div className="confirm-modal__container-header-title">
              {v_resource_planning_page.v_edit_plan_title}
            </div>
            <i
              className="confirm-modal__container-header-close"
              onClick={() => closeModal()}
            />
          </div>
          <div className="confirm-modal__container-content">
            {v_edit_plan_confirm}
          </div>
          <div
            className="confirm-modal__container-btn"
            onClick={() => {
              handleConfirm();
            }}
          >
            <div>
              <span>{v_confirm}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
