import React, { useState } from "react";
import { connect } from "react-redux";

import { SearchIcon, CrossIcon } from "../../../Atoms/SvgIcons";

import "./style.scss";
import classNames from "classnames";

const RecruitmentSearchComponent = ({
  vocabulary,
  placeholder,
  handleSearch,
  handleConfirmSearch,
}) => {
  const { v_hr_people } = vocabulary;
  const [value, setValue] = useState("");

  const clearSearch = () => {
    setValue("");
    handleSearch(null);
  };

  const handleSearchValue = (value) => {
    setValue(value);
    handleSearch(value);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      handleConfirmSearch({ typeConfirm: "search" });
    }
  };

  return (
    <div className="recruit-search">
      <div className={classNames("recruit-search__container")}>
        <div className="recruit-search__icon-container">
          <SearchIcon className="recruit-search__search-icon" color="white" />
        </div>
        <form
          action="true"
          className={"recruit-search__form"}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input
            className={classNames("recruit-search__input")}
            type="search"
            placeholder={
              placeholder
                ? placeholder
                : v_hr_people.directions.search_by_name_placeholder
            }
            value={value}
            onChange={(event) => handleSearchValue(event.target.value)}
            onKeyPress={handleKeyPress}
          />
        </form>
        {Boolean(value.length) && (
          <div className="recruit-search__icon-container" onClick={clearSearch}>
            <CrossIcon className="recruit-search__close-icon" color="#B9BBBE" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecruitmentSearchComponent);
