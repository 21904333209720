export const APP_VERSION = "1.7.26";
export const VERSIONS_LIST = [
  {
    date: "31.10.2023",
    version: "1.7.26",
  },
  {
    date: "12.10.2023",
    version: "1.6.25",
  },
  {
    date: "03.10.2023",
    version: "1.6.24",
  },
  {
    date: "12.09.2023",
    version: "1.6.23",
  },
  {
    date: "05.09.2023",
    version: "1.6.22",
  },
  {
    date: "15.08.2023",
    version: "1.6.21",
  },
  {
    date: "27.06.2023",
    version: "1.6.20",
  },
  {
    date: "30.05.2023",
    version: "1.6.19",
  },
  {
    date: "04.05.2023",
    version: "1.6.18",
  },
  {
    date: "14.04.2023",
    version: "1.6.17",
  },
  {
    date: "24.03.2023",
    version: "1.6.16",
  },
  {
    date: "14.03.2023",
    version: "1.6.15",
  },
  {
    date: "22.02.2023",
    version: "1.6.14",
  },
  {
    date: "07.02.2023",
    version: "1.6.13",
  },
  {
    date: "25.01.2023",
    version: "1.6.12",
  },
  {
    date: "17.01.2023",
    version: "1.6.11",
  },
  {
    date: "27.12.2022",
    version: "1.6.10",
  },
  {
    date: "09.12.2022",
    version: "1.6.9",
  },
  {
    date: "08.11.2022",
    version: "1.5.9",
  },
  {
    date: "26.10.2022",
    version: "1.5.8",
  },
  {
    date: "04.10.2022",
    version: "1.5.7",
  },
  {
    date: "26.09.2022",
    version: "1.5.6",
  },
  {
    date: "07.09.2022",
    version: "1.4.5",
  },
  {
    date: "30.08.2022",
    version: "1.3.4",
  },
  {
    date: "08.08.2022",
    version: "1.3.3",
  },
  {
    date: "28.06.2022",
    version: "1.2.2",
  },
  {
    date: "08.06.2022",
    version: "1.1.1",
  },
  {
    date: "12.05.2022",
    version: "1.0",
  },
];
