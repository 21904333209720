import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import cn from "classnames";

import PropTypes from "prop-types";

// Services

// Components

// Actions

// Queries

// Config

// Styles
import "./style.scss";

class ProjectSearchBar extends Component {
  state = {
    SearchValue: "",

    disabled: false,
  };

  checkedDisable = () => {
    this.setState({ disabled: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchValue.length !== this.props.searchValue.length) {
      this.setState({ disabled: true });
    }
    if (
      prevState.searchValue !== this.state.searchValue &&
      this.state.searchValue === ""
    ) {
      this.setState({ disabled: false });
    }
  }

  render() {
    const { vocabulary } = this.props;
    const { v_apply, v_search_project } = vocabulary;

    return (
      <div className="wrapper_project_search_bar">
        <div className="project_search_bar_search_field_container">
          <i
            className={cn("magnifer", {
              magnifer__light: localStorage.getItem("themes") === "light",
            })}
          />
          <input
            placeholder={v_search_project}
            type="text"
            onKeyUp={(e) =>
              e.keyCode === 13 ? this.props.filterProjects() : null
            }
            value={this.props.searchValue}
            onChange={this.props.searchValueHandler}
            className="project_search_bar_search_field"
          />
        </div>
        {/* WOB-3462 -Remove the Apply button
        <div className="project_search_bar_button_container">
          <button
            className="project_search_bar_button"
            disabled={!this.props.searchValue.length || !this.state.disabled}
            // disabled={!this.state.disabled}
            onClick={() => {
              this.props.filterProjects();
              this.checkedDisable();
            }}
          >
            {v_apply}
          </button>
        </div> */}
      </div>
    );
  }
}

ProjectSearchBar.propTypes = {
  searchValue: PropTypes.string.isRequired,
  searchValueHandler: PropTypes.func.isRequired,
  filterProjects: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(ProjectSearchBar);
