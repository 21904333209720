import React from "react";
import Proptypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

import "./style.scss";

const PlanDetails = ({
  vocabulary,
  className,
  subscriptionData,
  plansData,
  teamUsers,
  currentTeam,
}) => {
  const { v_plan_details, lang } = vocabulary;

  return (
    <div className={`plan-details ${className || ""}`}>
      <div className="plan-details__content">
        <div className="plan-details__row">
          <div className="plan-details__col plan-details__col--fixed">
            <ul className="plan-details__list">
              <li className="plan-details__item">
                <div className="plan-details__label plan-details__details-label">
                  {v_plan_details.currentPlan}
                </div>
                <div className="plan-details__value">
                  {currentTeam && currentTeam.data.trial
                    ? "Free trial for "
                    : ""}
                  {
                    plansData.filter(
                      (product) => product.id === subscriptionData.plan.product,
                    )[0].name
                  }
                  {` ${
                    subscriptionData.plan?.nickname === 0
                      ? ""
                      : subscriptionData.plan.interval === "year"
                      ? "annual"
                      : "monthly"
                  }`}
                </div>
              </li>
              <li className="plan-details__item">
                <div className="plan-details__label plan-details__details-label">
                  {subscriptionData.plan?.nickname === 0
                    ? "Cost"
                    : `Cost per ${subscriptionData.plan.interval}`}
                </div>
                <div className="plan-details__value">{`€ ${
                  subscriptionData.plan?.nickname / 100
                } per user`}</div>
              </li>
            </ul>
          </div>
          <div className="plan-details__col plan-details__col--fixed">
            <ul className="plan-details__list">
              <li className="plan-details__item">
                <div className="plan-details__label plan-details__details-label">
                  {v_plan_details.status}
                </div>
                {subscriptionData.plan?.nickname > 0 && (
                  <div className="plan-details__value">
                    {v_plan_details.subscriptionRenewingOn}{" "}
                    {moment
                      .unix(subscriptionData.current_period_end)
                      .locale(lang.short)
                      .format("ll")}
                  </div>
                )}
                {subscriptionData.plan?.nickname === 0 && (
                  <div className="plan-details__value">
                    {v_plan_details.subscriptionRenewingOnFree}
                  </div>
                )}
              </li>
              {currentTeam && currentTeam.data.trial && (
                <li className="plan-details__item">
                  <div className="plan-details__label plan-details__details-label">
                    {v_plan_details.daysLeftOnTrial}
                  </div>
                  <div className="plan-details__value">
                    {`${moment(currentTeam.data.trial).diff(
                      moment(),
                      "days",
                    )} days`}
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

PlanDetails.propTypes = {
  vocabulary: Proptypes.object,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
  plansData: store.subscriptionPageReducer.plansData,
  subscriptionData: store.subscriptionPageReducer.customerData,
  teamUsers: store.teamReducer.currentTeamDetailedData.data,
  currentTeam: store.teamReducer.currentTeam,
});

export default connect(mapStateToProps)(PlanDetails);
