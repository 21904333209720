import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import "./style.scss";

class TeamSearchBar extends Component {
  state = {
    searchValue: "",
  };

  render() {
    const { vocabulary, search } = this.props;
    const { v_apply, v_search } = vocabulary;

    return (
      <div className="wrapper_team_search_bar">
        <div className="team_search_bar_search_field_container">
          <i
            className={cn("magnifer", {
              magnifer__light: localStorage.getItem("themes") === "light",
            })}
          />
          <input
            placeholder={v_search}
            type="text"
            onKeyUp={(e) =>
              e.keyCode === 13 ? search(this.state.searchValue) : null
            }
            value={this.state.searchValue}
            onChange={(e) => this.setState({ searchValue: e.target.value })}
            className="team_search_bar_search_field"
          />
        </div>
        <div className="team_search_bar_button_container">
          <button
            className="team_search_bar_button"
            disabled={!this.state.searchValue.length}
            onClick={(e) => search(this.state.searchValue)}
          >
            {v_apply}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(TeamSearchBar);
