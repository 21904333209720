import React, { ReactElement } from "react";

import "./style.scss";

interface IAvatarProps {
  src: string;
  size?: string | number;
}

const Avatar = ({ src, size = "28px" }: IAvatarProps): ReactElement => (
  <div className="avatar" style={{ width: size, height: size }}>
    {src && (
      <img
        className="avatar__img"
        src={src}
        alt="Avatar"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.style.display = "none";
        }}
      />
    )}
  </div>
);

export default Avatar;
