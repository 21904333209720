import styled from "styled-components/macro";
import EditDirectionImage from "../../../../assets/images/icons/edit-direction.svg"
import TimerDeadlineImage from "../../../../assets/images/icons/timer-deadline.svg"
import TimerDeadlineYellowImage from "../../../../assets/images/icons/timer-deadline-yellow.svg"
import TimerDeadlineRedImage from "../../../../assets/images/icons/timer-deadline-red.svg"
import ArchiveHolderImage from "../../../../assets/images/icons/Archive2.svg"

export const TableRow = styled.tr `
  background-color: var(--bg-resource-planning-item-user);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text);

  &:hover {
    background-color: var(--bg-tag);
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const TableCellName = styled.td`
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:nowrap;
  display:block;
  border-radius: 8px;
`;

export const TableCellContent = styled.div`
  display: flex;
  div {
    display: flex;

    i {
      // margin-left: 15px;
    }
  }

  &.deadline__yellow {
    color: #f3ad26;
  }

  &.deadline__red {
    color: #eb5757;
  }
`;

export const ButtonEdit = styled.div `
  width: fit-content;
  border-radius: 8px;
  padding: 2px;
  &:hover {
    background-color: var(--hover-calendar-item);
  }
`;

export const EditIcon = styled.i`
  display: block;
  height: 24px;
  width: 24px;
  background: url(${EditDirectionImage}) no-repeat center;
`;

export const ArchiveIcon = styled.i`
  display: block;
  height: 24px;
  width: 24px;
  background: url(${ArchiveHolderImage}) no-repeat center;
`;

export const TimerIcon = styled.i`
  display: block;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  background: url(${TimerDeadlineImage}) no-repeat center;
  &.timer__yellow {
    background: url(${TimerDeadlineYellowImage}) no-repeat center;
  }
  &.timer__red {
    background: url(${TimerDeadlineRedImage}) no-repeat center;
  }
`;



