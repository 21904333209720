export const AppConfig = {
    apiURL: 'https://api.wobbly.me/',
    socialAuth: {
        active: true,
        fbAppId: '768843194775259',
        fbAppGoogleId: '697653368732-r2n0r89ds8ibgir73g1splq9hdqua1on.apps.googleusercontent.com'
    },
    stripePublicKey: 'pk_4z061V7rNZjWdAN5llPmRRWUyyFSh',
    subscriptionList: [
        {
            id: 'prod_OvFIidyFygrw9w',
            name: 'Free',
            maxTeams: 1,
            maxProjects: null,
            maxClients: 1,
            maxReportDays: 90
        },
        {
            id: 'prod_OvFPKOn8Sr6uIy',
            name: 'Starter',
            maxTeams: 1,
            maxProjects: null,
            maxClients: 1,
            maxReportDays: null
        },
        {
            id: 'prod_OvFQa3PPbaLaDH',
            name: 'Business',
            maxTeams: null,
            maxProjects: null,
            maxClients: null,
            maxReportDays: null
        }
    ]
};