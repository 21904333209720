import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppConfig } from "../../config";
import { Loading } from "../../components/Unstructured/Loading";
import cn from "classnames";

// Services
import { apiCall } from "../../services/apiService";
import {
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
} from "../../services/tokenStorageService";

// Actions
import { showNotificationAction } from "../../redux/actions/NotificationActions";

// Styles
import "./style.scss";

class VerifyPage extends Component {
  state = {
    isLoading: false,
    haveToken: false,
    loader: true,
  };
  componentDidMount() {
    const secretKey = this.props.match.params.secretKey;
    apiCall(`${AppConfig.apiURL}user/email/verify/${secretKey}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }).then(
      ({ token }) => {
        setTokenToLocalStorage(token);
        this.setState({ haveToken: true });

        this.props.showNotificationAction({
          text: "Your e-mail has been successfully verified",
          type: "success",
        });
        this.setState({ loader: false });
      },

      (err) => {
        err.json().then(({ message }) => {
          let text = message;
          if (text === "ERROR.USER_ALREADY_EXIST") {
            this.props.showNotificationAction({
              text: "This link is not valid",
              type: "error",
            });
          } else {
            this.props.showNotificationAction({
              text,
              type: "error",
            });
          }
          this.props.history.push("/register");
        });
      },
    );
  }

  render() {
    const { haveToken, loader } = this.state;

    if (haveToken && getTokenFromLocalStorage()) {
      localStorage.setItem("redirectFrom", "/register");
      return <Redirect to={"/thank-you-page"} />;
    } else {
      return (
        <Loading flag={loader} mode="parentSize" withLogo={false}>
          <div className="unsubscribe_modal_wrapper">
            <i
              className={cn("page_title", {
                page_title__light: localStorage.getItem("themes") === "light",
              })}
            />
          </div>
        </Loading>
      );
    }
  }
}
const mapDispatchToProps = {
  showNotificationAction,
};

export default withRouter(connect(null, mapDispatchToProps)(VerifyPage));
