import styled, { css } from "styled-components";
import ArrowDown from "../../../assets/images/icons/arrow-down.svg";
import ArrowDownLight from "../../../assets/images/icons/arrow-down-gray.svg";
export const Form = styled.div``;

export const DropdownContainer = styled.div`
  text-align: left;
  border: 1px solid var(--border-color-subscription);
  position: relative;
  border-radius: 8px;
  min-height: 44px;
  min-width: 160px;
`;

export const DropdownInput = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  font-size: 16px;
  color: #b9bbbe;
`;

export const DropdownMenu = styled.div`
  margin-top: 5px;
  position: absolute;
  width: 100%;
  border-radius: 5px;
  z-index: 99;
  max-height: 0;
  overflow: auto;
  transition: max-height 0.3s ease;
  ${(props) =>
    props.showMenu &&
    css`
      &.open {
        max-height: 150px;
      }
    `}
`;

export const Options = styled.div`
  background-color: var(--bg-button-apply);
`;

export const DropdownItem = styled.div`
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text);
  font-weight: 600;
  font-size: 12px;

  &:hover {
    background-color: #9fc3f870;
  }
  &.selected {
    background-color: #0d6efd;
    color: #fff;
  }
`;

export const CheckboxInput = styled.input`
  margin-right: 5px;
  &:checked {
    filter: hue-rotate(240deg);
  }
  &:not(:checked) {
  }
`;

export const DropdownTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const DropdownTagItem = styled.div`
  background-color: #40444b;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

export const DropdownTagClose = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: var(--bg-resource-planning-item-user);
  position: relative;
  svg {
    position: absolute;
    left: 10px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 5px;
  padding-left: 25px;
  box-sizing: border-box;
  border: 1px solid #52565f;
  border-radius: 5px;
  background-color: var(--bg-resource-planning-item-user);
  color: white;
  font-weight: 600;
  &:focus {
    border-color: #52565f !important;
  }
`;

export const DropdownSelectedValue = styled.div`
  /* ваші стилі для .dropdown-selected-value */
`;

export const DropdownTools = styled.div`
  /* ваші стилі для .dropdown-tools */
`;

export const DropdownTool = styled.div`
  /* ваші стилі для .dropdown-tool */
`;

export const ArrowIcon = styled.i`
  &.arrow_down {
    background: url(${ArrowDown}) no-repeat center;
    
    height: 24px;
    margin-left: 5px;
    transition: all 0.3s;
    width: 24px;
  }
  
  &.arrow_up {
    transform: rotate(-180deg);
  }
  
  &.arrow_down__light_themes {
    background: url(${ArrowDownLight});
    no-repeat center;
  }
`;
