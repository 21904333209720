import React, { Component } from "react";
import * as Moment from "moment";
import { extendMoment } from "moment-range";

import { enGB, ru, de, it, ua } from "react-date-range/src/locale";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// dependencies
import classNames from "classnames";
import _ from "lodash";

// Services
import {
  checkIsAdminByRole,
  checkIsOwnerByRole,
  checkIsHRManagerByRole,
  checkIsProjectManagerByRole,
  checkIsInvoicesManagerByRole,
  ROLES,
} from "../../../services/authentication";
import { getParametersString } from "../../../services/apiService";
import {
  getTimeDurationByGivenTimestamp,
  convertDateToISOString,
  convertDateToShiftedISOString,
  getTimestamp,
} from "../../../services/timeService";
import { apiCall } from "../../../services/apiService";

// Components
import ReportsSearchBar from "../../../components/Unstructured/reportsSearchBar";
import { Loading } from "../../../components/Unstructured/Loading";
import { UnitedReportsComponents } from "../UnitedReportsComponents";
import { BlankListComponent } from "../../../components/Unstructured/CommonComponents/BlankListcomponent/BlankListComponent";
import ReportsHeader from "../../../components/Unstructured/Reports/ReportsHeader";

// Actions
import reportsPageAction from "../../../redux/actions/ReportsPageAction";
import { getClientsAction } from "../../../redux/actions/ClientsActions";
import { changeCrossTeamStatusAction } from "../../../redux/actions/UserActions";
import {
  getFormattedDate,
  getArrOfProjectsData,
  getDatesListBetweenStartEndDates,
  getSumTimeEntriesByDay,
  getArrOfUserTeamData,
  getArrOfUserTeamProjectsDataForPeople,
  saveFile,
  getArrOfUserProjectsData,
  getLablesAndTime,
  changeDoughnutChart,
} from "../../../services/reportsService";

// Config
import { AppConfig } from "../../../config";

// Styles
import "./style.scss";
import { checkYourPlane } from "../../MainPage";

import {
  REPORTS_TYPES,
  LINE_CHART_OPTIONS,
  REPORTS_MAX_CACHE_TIME,
} from "../../../constants/reports";
import { fetchReportsFilters } from "../../../api/reports";
import { getCurrentTeamDetailedData } from "../../../api/team";

const moment = extendMoment(Moment);

const localeMap = {
  ru: ru,
  en: enGB,
  de: de,
  it: it,
  uk: ua,
};

class ReportsSummary extends Component {
  state = {
    isFetching: false,
    toggleBar: false,
    toggleChar: false,
    decimal: "decimal",
  };

  customLocale = localeMap[this.props.vocabulary.lang.short];

  abortData = null;

  abortDataCross = null;

  abortList = null;

  abortListCross = null;

  componentDidMount() {
    const { timeRange, vocabulary, lastFetchedSummary } = this.props;
    const { lang } = vocabulary;
    const { startDate, endDate } = timeRange;

    moment.locale(lang.short);

    const isData = !this.props.isCrossTeam
      ? Boolean(this.props.userProjects?.length)
      : Boolean(this.props.reportProjects?.length);

    if (
      lastFetchedSummary + REPORTS_MAX_CACHE_TIME < getTimestamp() ||
      !isData
    ) {
      this.triggerSearch(
        getFormattedDate(
          this.props.location?.propsSearchDate?.startDate || startDate,
        ),
        getFormattedDate(
          this.props.location?.propsSearchDate?.endDate || endDate,
        ),
        true,
      );
    } else {
      this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);

      this.setState({
        toggleBar: true,
        toggleChar: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTeam.data.role !== this.props.currentTeam.data.role) {
      if (
        checkIsAdminByRole(this.props.currentTeam.data.role) ||
        checkIsOwnerByRole(this.props.currentTeam.data.role) ||
        checkIsHRManagerByRole(this.props.currentTeam.data.role) ||
        checkIsProjectManagerByRole(this.props.currentTeam.data.role) ||
        checkIsInvoicesManagerByRole(this.props.currentTeam.data.role)
      ) {
        this.props.getClientsAction();
      }
    }
  }

  lineChartOption = (durationTimeFormat) => ({
    ...LINE_CHART_OPTIONS,
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return getTimeDurationByGivenTimestamp(
            tooltipItem.yLabel,
            durationTimeFormat,
          );
        },
      },
      backgroundColor: "#292b2f",
      borderColor: "#fafafc",
      borderWidth: 1,
    },
  });

  setDataToGraph(object, objectData) {
    const { vocabulary } = this.props;
    const { v_chart_label_total } = vocabulary;
    const newObject = object;

    newObject.labels = objectData.labels;
    newObject.datasets[0].data = objectData.timeArr;
    newObject.datasets[0].label = v_chart_label_total;
    return newObject;
  }

  addDebounceToSearch = _.debounce((selection) => {
    this.props.reportsPageAction("SET_TIME", { data: selection });

    this.triggerSearch(
      getFormattedDate(selection.startDate),
      getFormattedDate(selection.endDate),
    );
  }, 1);

  handleSelect = (ranges, inputSearch = false) => {
    const { dateFormat } = this.props;

    if (
      moment(ranges.selection?.startDate).diff(moment([1919, 11, 18])) === 0
    ) {
      this.props.reportsPageAction("SET_SELECTION_RANGE", ranges.selection);

      this.props.reportsPageAction(
        "SET_START_DATE",
        moment(ranges.selection?.startDate).format(dateFormat),
      );

      this.props.reportsPageAction(
        "SET_END_DATE",
        moment(ranges.selection?.startDate).format(dateFormat),
      );

      return;
    }

    this.props.reportsPageAction("SET_SELECTION_RANGE", ranges.selection);

    this.props.reportsPageAction(
      "SET_START_DATE",
      moment(ranges.selection.startDate).format(dateFormat),
    );

    this.props.reportsPageAction(
      "SET_END_DATE",
      moment(ranges.selection.endDate).format(dateFormat),
    );

    this.addDebounceToSearch(ranges.selection);
  };

  changeCrossTeamStatus = (isChecked) => {
    this.props.changeCrossTeamStatusAction(isChecked);
  };

  export(detailed) {
    const { user, isCrossTeam } = this.props;
    const { selectedProjectsCross } = this.props.filter;
    const { timezoneOffset } = user;

    const projectNamesCross = [];

    selectedProjectsCross.forEach((project) => {
      projectNamesCross.push(project.projectName);
    });

    const dateFrom = getFormattedDate(this.props.selectionRange.startDate);
    const dateTo = getFormattedDate(this.props.selectionRange.endDate);

    const selectedUsers = this.props.selectedUsers
      ? this.props.selectedUsers
      : [];
    const selectedUsersCross = this.props.selectedUsersCross
      ? this.props.selectedUsersCross
      : [];
    const setProjectNames = this.props.selectedProjects
      ? this.props.selectedProjects
      : [];
    const setProjectNamesCross = projectNamesCross ? projectNamesCross : [];

    const dataForReport = {
      detailed: detailed,
      timezoneOffset: timezoneOffset,
      startDate: convertDateToISOString(dateFrom),
      endDate: convertDateToShiftedISOString(dateTo, 24 * 60 * 60 * 1000),
      userEmails: isCrossTeam ? selectedUsersCross : selectedUsers,
      projectNames: isCrossTeam ? setProjectNamesCross : setProjectNames,
      crossTeam: isCrossTeam,
    };

    apiCall(`${AppConfig.apiURL}report/export`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataForReport),
    }).then(
      (result) => saveFile(`${AppConfig.apiURL}${result.path}`),
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  exportDecimal(detailed, round) {
    const { user, isCrossTeam } = this.props;
    const { selectedProjectsCross } = this.props.filter;
    const { timezoneOffset } = user;
    const decimal = this.state.decimal;

    const projectNamesCross = [];

    selectedProjectsCross.forEach((project) => {
      projectNamesCross.push(project.projectName);
    });

    const dateFrom = getFormattedDate(this.props.selectionRange.startDate);
    const dateTo = getFormattedDate(this.props.selectionRange.endDate);

    const selectedUsers = this.props.selectedUsers
      ? this.props.selectedUsers
      : [];
    const selectedUsersCross = this.props.selectedUsersCross
      ? this.props.selectedUsersCross
      : [];
    const setProjectNames = this.props.selectedProjects
      ? this.props.selectedProjects
      : [];
    const setProjectNamesCross = projectNamesCross ? projectNamesCross : [];

    const dataForReport = {
      detailed: detailed,
      timezoneOffset: timezoneOffset,
      startDate: convertDateToISOString(dateFrom),
      endDate: convertDateToShiftedISOString(dateTo, 24 * 60 * 60 * 1000),
      userEmails: isCrossTeam ? selectedUsersCross : selectedUsers,
      projectNames: isCrossTeam ? setProjectNamesCross : setProjectNames,
      timeFormat: decimal ? decimal : "",
      round: round ? round : "",
      crossTeam: isCrossTeam,
    };

    apiCall(`${AppConfig.apiURL}report/export`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataForReport),
    }).then(
      (result) => saveFile(`${AppConfig.apiURL}${result.path}`),
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  triggerSearch(
    dateFrom = getFormattedDate(this.props.selectionRange.startDate),
    dateTo = getFormattedDate(this.props.selectionRange.endDate),
    isInit = false,
  ) {
    this.props.isCrossTeam
      ? this.applySearchSummaryCrossTeam(dateFrom, dateTo)
      : this.applySearch(dateFrom, dateTo);

    if (!isInit) {
      this.props.reportsPageAction("SET_LAST_FETCHED_SUMMARY", getTimestamp());
      this.props.reportsPageAction("SET_LAST_FETCHED_REAL_TIME", 0);
    } else {
      this.props.reportsPageAction("SET_LAST_FETCHED_SUMMARY", getTimestamp());
    }
  }

  applySearch(
    dateFrom = getFormattedDate(this.props.selectionRange.startDate),
    dateTo = getFormattedDate(this.props.selectionRange.endDate),
  ) {
    this.setState({ isFetching: true });
    if (this.abortData) {
      this.abortData.abort();
    }
    if (this.abortList) {
      this.abortList.abort();
    }
    this.abortData = new AbortController();
    this.abortList = new AbortController();

    const data = {
      startDate: convertDateToISOString(dateFrom),
      endDate: convertDateToShiftedISOString(dateTo, 24 * 60 * 60 * 1000),
    };

    let selectedUsers = null;

    const role = this.props.currentTeam.data.role;

    if (
      !checkIsAdminByRole(role) &&
      !checkIsOwnerByRole(role) &&
      !checkIsHRManagerByRole(role) &&
      !checkIsProjectManagerByRole(role) &&
      !checkIsInvoicesManagerByRole(role)
    ) {
      selectedUsers = [this.props.user.email];
    } else if (this.props.selectedUsers && this.props.selectedUsers.length) {
      selectedUsers = this.props.selectedUsers;
    }
    if (selectedUsers) {
      data.userEmails = selectedUsers;
    }

    if (this.props.selectedProjects && this.props.selectedProjects.length) {
      data.projectNames = this.props.selectedProjects;
    }

    apiCall(`${AppConfig.apiURL}project/reports-projects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: this.abortData.signal,
      body: JSON.stringify(data),
    }).then(
      (data) => {
        const dataToGraph = getArrOfProjectsData({
          isCrossTeam: this.props.isCrossTeam,
          startDate: this.props.selectionRange.endDate,
          endDate: this.props.selectionRange.startDate,
          data,
        });

        this.props.reportsPageAction("SET_PROJECTS", {
          data: dataToGraph.statsByProjects,
        });

        const statsByUserProjects = getArrOfUserProjectsData(
          data,
          this.props.isCrossTeam,
        );

        this.props.reportsPageAction("SET_USER_PROJECTS", {
          data: statsByUserProjects,
        });

        const doughnutGraphData = changeDoughnutChart(
          this.props.summaryDoughnutChart,
          dataToGraph.statsByProjects,
        );

        this.props.reportsPageAction("SET_SUMMARY_DOUGHNUT_GRAPH", {
          data: doughnutGraphData,
        });

        this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);

        this.setState({
          toggleChar: true,
        });
      },
      (err) => {
        err.code !== DOMException.ABORT_ERR &&
          this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);

        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );

    apiCall(`${AppConfig.apiURL}timer/reports-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: this.abortList.signal,
      body: JSON.stringify(data),
    }).then(
      (result) => {
        const datePeriod = Object.keys(
          getDatesListBetweenStartEndDates(
            this.props.selectionRange.startDate,
            this.props.selectionRange.endDate,
          ),
        );

        const { entries, entriesBillable } = getSumTimeEntriesByDay({
          datePeriod,
          timeEntries: result,
          selectionRange: this.props.selectionRange,
        });

        const { data, totalTime, totalBillableTime } = getLablesAndTime({
          labels: datePeriod,
          time: entries,
          billableTime: entriesBillable,
          dateFormat: this.props.dateFormat,
          selectionRange: this.props.selectionRange,
        });

        this.props.reportsPageAction(
          "SET_LINE_GRAPH",
          this.setDataToGraph(this.props.dataBarChart, data),
        );

        this.props.reportsPageAction("SET_TOTAL_TIME", {
          totalTime,
          totalBillableTime,
        });

        this.setState({
          toggleBar: true,
          isFetching: false,
        });

        getCurrentTeamDetailedData(
          convertDateToISOString(dateFrom),
          convertDateToShiftedISOString(dateTo, 24 * 60 * 60 * 1000),
        ).then(({ data }) => {
          const teamUsers = data.user_team;
          const teamId = data.id;
          const users = teamUsers.map((teamUser) => ({
            ...teamUser.user,
            is_active: teamUser.is_active,
            teamIds: [teamId],
          }));

          this.props.reportsPageAction("UPDATE_FILTER", {
            ...this.props.filter,
            allUsers: users,
          });

          const selectedUsers = this.props.selectedUsers;
          const usersForUpdate = [];

          for (let i = 0; i < selectedUsers.length; i++) {
            const user = users.find((user) => user.email === selectedUsers[i]);

            if (user) {
              usersForUpdate.push(user);
            } else {
              this.props.reportsPageAction("UPDATE_FILTER", {
                ...this.props.filter,
                selectedUsers: [],
                selectedProjects: [],
                selectedClients: [],
              });
            }
          }

          this.props.reportsPageAction("UPDATE_FILTER", {
            ...this.props.filter,
            selectedUsers: usersForUpdate,
          });

          this.props.reportsPageAction("SET_SELECTED_USERS", {
            data: usersForUpdate.map((u) => u.email),
          });
        });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  applySearchSummaryCrossTeam(
    dateFrom = getFormattedDate(this.props.selectionRange.startDate),
    dateTo = getFormattedDate(this.props.selectionRange.endDate),
  ) {
    this.setState({ isFetching: true });
    if (this.abortDataCross) {
      this.abortDataCross.abort();
    }
    if (this.abortListCross) {
      this.abortListCross.abort();
    }
    this.abortDataCross = new AbortController();
    this.abortListCross = new AbortController();

    const data = {
      startDate: convertDateToISOString(dateFrom),
      endDate: convertDateToShiftedISOString(dateTo, 24 * 60 * 60 * 1000),
    };

    let selectedUsers = null;

    const role = this.props.currentTeam.data.role;

    if (
      !checkIsAdminByRole(role) &&
      !checkIsOwnerByRole(role) &&
      !checkIsHRManagerByRole(role) &&
      !checkIsProjectManagerByRole(role) &&
      !checkIsInvoicesManagerByRole(role)
    ) {
      selectedUsers = [this.props.user.email];
    } else if (
      this.props.selectedUsersCross &&
      this.props.selectedUsersCross.length
    ) {
      selectedUsers = this.props.selectedUsersCross;
    }
    if (selectedUsers) {
      data.userEmails = selectedUsers;
    }

    if (
      this.props.selectedProjectsCross &&
      this.props.selectedProjectsCross.length
    ) {
      data.projectIds = this.props.selectedProjectsCross;
    }

    if (this.props.selectedTeams && this.props.selectedTeams.length) {
      data.teamIds = this.props.selectedTeams;
    }

    apiCall(`${AppConfig.apiURL}team/reports-projects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: this.abortDataCross.signal,
      body: JSON.stringify(data),
    }).then(
      (result) => {
        const data = result;

        const dataToGraph = getArrOfProjectsData({
          isCrossTeam: this.props.isCrossTeam,
          startDate: this.props.selectionRange.endDate,
          endDate: this.props.selectionRange.startDate,
          data,
        });

        this.props.reportsPageAction("SET_PROJECTS", {
          data: dataToGraph.statsByProjects,
        });

        if (data) {
          const statusByUserTeams = getArrOfUserTeamData(data);
          const newArrTeams = getArrOfUserTeamProjectsDataForPeople(data);

          this.props.reportsPageAction(
            "SET_USER_TEAMS_ARRAY",
            statusByUserTeams,
          );
          this.props.reportsPageAction(
            "SET_USER_TEAMS_PEOPLE_ARRAY",
            newArrTeams,
          );
        }

        const obj = changeDoughnutChart(
          this.props.summaryDoughnutChart,
          dataToGraph.statsByProjects,
        );

        this.props.reportsPageAction("SET_SUMMARY_DOUGHNUT_GRAPH", {
          data: obj,
        });

        this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);

        this.setState({
          toggleChar: true,
        });
      },
      (err) => {
        err.code !== DOMException.ABORT_ERR &&
          this.props.reportsPageAction("SET_IS_INITIAL_FETCH", false);

        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );

    apiCall(`${AppConfig.apiURL}timer/teams-reports-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal: this.abortListCross.signal,
      body: JSON.stringify(data),
    }).then(
      (result) => {
        const datePeriod = Object.keys(
          getDatesListBetweenStartEndDates(
            this.props.selectionRange.startDate,
            this.props.selectionRange.endDate,
          ),
        );

        const { entries, entriesBillable } = getSumTimeEntriesByDay({
          datePeriod,
          timeEntries: result,
          selectionRange: this.props.selectionRange,
        });

        const { data, totalTime, totalBillableTime } = getLablesAndTime({
          labels: datePeriod,
          time: entries,
          billableTime: entriesBillable,
          dateFormat: this.props.dateFormat,
          selectionRange: this.props.selectionRange,
        });

        this.props.reportsPageAction(
          "SET_LINE_GRAPH",
          this.setDataToGraph(this.props.dataBarChart, data),
        );

        this.props.reportsPageAction("SET_TOTAL_TIME", {
          totalTime,
          totalBillableTime,
        });

        this.setState({ toggleBar: true, isFetching: false });

        fetchReportsFilters(
          convertDateToISOString(dateFrom),
          convertDateToShiftedISOString(dateTo, 24 * 60 * 60 * 1000),
        ).then(({ data }) => {
          const teamUserCrossTeam = data.users;

          this.props.reportsPageAction("UPDATE_FILTER", {
            ...this.props.filter,
            allUsersCross: teamUserCrossTeam,
          });

          const selectedUsers = this.props.selectedUsersCross;
          const usersForUpdate = [];

          for (let i = 0; i < selectedUsers.length; i++) {
            const user = teamUserCrossTeam.find(
              (user) => user.email === selectedUsers[i],
            );

            if (user) {
              usersForUpdate.push(user);
            } else {
              this.props.reportsPageAction("UPDATE_FILTER", {
                ...this.props.filter,
                selectedUsersCross: [],
                selectedProjectsCross: [],
                selectedClientsCross: [],
                selectedTeams: [],
              });
            }
          }

          this.props.reportsPageAction("UPDATE_FILTER", {
            ...this.props.filter,
            selectedUsersCross: usersForUpdate,
          });

          this.props.reportsPageAction("SET_CROSS_USERS_SELECTED", {
            data: usersForUpdate.map((u) => u.email),
          });
        });
      },
      (err) => {
        if (err instanceof Response) {
          err.text().then((errorMessage) => console.log(errorMessage));
        } else {
          console.log(err);
        }
      },
    );
  }

  render() {
    const {
      isMobile,
      vocabulary,
      currentTeam,
      firstDayOfWeek,
      durationTimeFormat,
      timeRange,
      isInitialFetch,
    } = this.props;
    const {
      v_detailed_export,
      v_simple_export,
      v_total_time,
      v_total_billable,
      v_total_not_billable,
      v_export,
      // lang,
      v_decimal_report,
    } = vocabulary;

    const { isFetching } = this.state;

    const isAdmin = [
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_OWNER,
      ROLES.ROLE_HR_MANAGER,
      ROLES.ROLE_PROJECT_MANAGER,
      ROLES.ROLE_INVOICES_MANAGER,
    ].includes(currentTeam?.data?.role);

    return (
      <Loading flag={isInitialFetch} mode={"parentSize"} withLogo={false}>
        <div
          className={classNames("wrapper_reports_page", {
            "wrapper_reports_page--mobile": isMobile,
          })}
        >
          <div className="data_container_reports_page">
            <ReportsHeader
              isMobile={isMobile}
              isCrossTeam={this.props.isCrossTeam}
              isAdmin={isAdmin}
              currentTab={REPORTS_TYPES.SUMMARY}
              onChangeCrossTeam={this.changeCrossTeamStatus}
              dateFormat={this.props.dateFormat}
              currentTeam={this.props.currentTeam}
              customLocale={this.customLocale}
              firstDayOfWeek={firstDayOfWeek}
              maxReportDays={checkYourPlane(
                this.props.currentTeam.data.productId,
              )}
              startDateValue={this.props.startDateValue}
              endDateValue={this.props.endDateValue}
              focusedRange={this.state.focusedRange}
              selectionRange={this.props.selectionRange}
              setStartDateValue={(e) =>
                this.props.reportsPageAction("SET_START_DATE", e)
              }
              setEndDateValue={(e) =>
                this.props.reportsPageAction("SET_END_DATE", e)
              }
              setFocusedRange={(e) => this.setState({ focusedRange: e })}
              onSelect={this.handleSelect}
            />

            <div className="content_wrapper">
              {isAdmin && (
                <ReportsSearchBar
                  adminOrOwner={isAdmin}
                  applySearch={(init) =>
                    this.triggerSearch(
                      getFormattedDate(this.props.selectionRange.startDate),
                      getFormattedDate(this.props.selectionRange.endDate),
                      init,
                    )
                  }
                  startDate={convertDateToISOString(
                    this.props.selectionRange.startDate,
                  )}
                  endDate={convertDateToShiftedISOString(
                    this.props.selectionRange.endDate,
                    24 * 60 * 60 * 1000,
                  )}
                  inputClientData={this.props.clientsList}
                />
              )}

              <div>
                {this.props.reportProjects.length > 0 ? (
                  <UnitedReportsComponents
                    isFetching={isFetching}
                    isCrossTeam={this.props.isCrossTeam}
                    newArrUserTeams={this.props.newArrUserTeams}
                    newArrUserTeamsForPeople={
                      this.props.newArrUserTeamsForPeople
                    }
                    toggleBar={this.state.toggleBar}
                    toggleChar={this.state.toggleChar}
                    v_total_time={v_total_time}
                    v_total_billable={v_total_billable}
                    v_total_not_billable={ v_total_not_billable}
                    v_decimal_report={v_decimal_report}
                    v_detailed_export={v_detailed_export}
                    v_simple_export={v_simple_export}
                    totalUpChartTime={this.props.totalTime}
                    getTimeDurationByGivenTimestamp={
                      getTimeDurationByGivenTimestamp
                    }
                    durationTimeFormat={durationTimeFormat}
                    export={this.export.bind(this)}
                    exportDecimal={this.exportDecimal.bind(this)}
                    v_export={v_export}
                    data={this.props.dataBarChart}
                    height={isMobile ? 150 : 50}
                    isMobile={isMobile}
                    lineChartOption={this.lineChartOption}
                    selectionRange={timeRange}
                    usersArr={this.props.selectedUsers}
                    reportProjects={this.props.reportProjects}
                    userProjects={this.props.userProjects}
                    currentTeam={this.props.currentTeam}
                  />
                ) : (
                  <BlankListComponent
                    subtext={this.props.vocabulary.v_no_report}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => ({
  dataBarChart: store.reportsPageReducer.dataBarChart,
  reportProjects: store.reportsPageReducer.reportProjects,
  userProjects: store.reportsPageReducer.userProjects,
  summaryDoughnutChart: store.reportsPageReducer.summaryDoughnutChart,
  timeRange: store.reportsPageReducer.timeRange,
  selectedUsers: store.reportsPageReducer.selectedUsers,
  selectedProjects: store.reportsPageReducer.selectedProjects,
  isMobile: store.responsiveReducer.isMobile,
  vocabulary: store.languageReducer.vocabulary,
  user: store.userReducer.user,
  currentTeam: store.teamReducer.currentTeam,
  dateFormat: store.userReducer.dateFormat,
  firstDayOfWeek: store.userReducer.firstDayOfWeek,
  durationTimeFormat: store.userReducer.durationTimeFormat,
  clientsList: store.clientsReducer.clientsList,
  isCrossTeam: store.reportsPageReducer.isCrossTeam,
  selectedProjectsCross: store.reportsPageReducer.selectedProjectsCross,
  selectedTeams: store.reportsPageReducer.selectedTeams,
  selectedUsersCross: store.reportsPageReducer.selectedUsersCross,
  totalTime: store.reportsPageReducer.totalTime,
  selectionRange: store.reportsPageReducer.selectionRange,
  startDateValue: store.reportsPageReducer.startDateValue,
  endDateValue: store.reportsPageReducer.endDateValue,
  newArrUserTeams: store.reportsPageReducer.newArrUserTeams,
  newArrUserTeamsForPeople: store.reportsPageReducer.newArrUserTeamsForPeople,
  lastFetchedSummary: store.reportsPageReducer.lastFetchedSummary,
  isInitialFetch: store.reportsPageReducer.isInitialFetch,
  filter: store.reportsPageReducer.filter,
});

const mapDispatchToProps = (dispatch) => ({
  reportsPageAction: (actionType, toggle) =>
    dispatch(reportsPageAction(actionType, toggle))[1],
  getClientsAction: (params) => dispatch(getClientsAction(params)),
  changeCrossTeamStatusAction: (params) =>
    dispatch(changeCrossTeamStatusAction(params)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsSummary),
);
