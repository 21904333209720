import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";

import {
  getCandidates,
  searchCandidates,
  getDirection,
} from "../../../../redux/actions/PepoplePageActions";

import RecruitmentTableHeader from "../../RecruitmentTableHeader";
import RecruitmentSearchComponent from "../RecruitmentSearchComponent";

import Pagination from "../../../Atoms/Pagination";
import { NoInfo } from "../../NoInfo";
import CardModal from "../CardModal";
import ListOfCandidates from "../ListOfCandidates";

import { TYPES_BUTTON, TYPES_CONFIRM } from "../../../../constants/recruitment";

import {
  IconButtonPlus,
  StyledAddDir,
  StyledAddDirButton,
  StyledCandidatesListWrapper,
  StyledCandidatesSearch,
  StyledMenuWrapper,
  StyledNoCandidates,
  StyledDirHeaderWrapper,
  StyledCandidatesBody,
  Table,
} from "./My.styled";
import classNames from "classnames";
import "./style.scss";

const Candidates = ({
  vocabulary,
  searchCandidatesHandler,
  candidatesDataFromRedux,
  vacanciesDataFromRedux,
  getCandidatesHandler,
  boards,
  getDirection,
}) => {
  const { v_hr_people, v_search } = vocabulary;

  const [directionData, setDirectionData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [pageCandidates, setPageCandidates] = useState(1);
  const [candidatesData, setCandidatesData] = useState([]);
  const [vacanciesData, setVacanciesData] = useState([]);
  const [openCardModal, setOpenCardModal] = useState(true);
  const [directionModal, setDirectionModal] = useState({
    isOpen: false,
    typeDirection: "edit", // create or edit
    directionId: null,
  });
  const [tableHeaderDataList, setTableHeaderDataList] = useState([
    {
      title: v_hr_people.candidates.headerTable.name,
      id: 0,
      isSelector: false,
      isFilter: false,
      selector: [],
      isEditButton: false,
    },
    {
      title: v_hr_people.candidates.headerTable.job_type,
      id: 1,
      isSelector: true,
      isFilter: false,
      selector: [
        {
          titleSelector: v_hr_people.candidates.headerTable.remote,
          selected: false,
        },
        {
          titleSelector: v_hr_people.candidates.headerTable.hybrid,
          selected: false,
        },
        {
          titleSelector: v_hr_people.candidates.headerTable.office,
          selected: false,
        },
      ],
      isEditButton: false,
    },
    {
      title: v_hr_people.candidates.headerTable.country,
      id: 2,
      isSelector: false,
      isFilter: false,
      selector: [],
      isEditButton: false,
      // lib
    },
    {
      title: v_hr_people.candidates.headerTable.city,
      id: 3,
      isSelector: false,
      isFilter: false,
      selector: [],
      isEditButton: false,
    },
    {
      title: v_hr_people.candidates.headerTable.level,
      id: 4,
      isSelector: true,
      isFilter: false,
      selector: [
        {
          titleSelector: v_hr_people.candidates.headerTable.trainee,
          selected: false,
        },
        {
          titleSelector: v_hr_people.candidates.headerTable.junior,
          selected: false,
        },
        {
          titleSelector: v_hr_people.candidates.headerTable.middle,
          selected: false,
        },
        {
          titleSelector: v_hr_people.candidates.headerTable.senior,
          selected: false,
        },
      ],
      isEditButton: false,
    },
    {
      title: v_hr_people.candidates.headerTable.direction,
      id: 5,
      isSelector: false,
      isFilter: true,
      selector: [],
      isEditButton: false,
      // names boards
    },
    {
      title: v_hr_people.candidates.headerTable.salary,
      id: 6,
      isSelector: false,
      isFilter: true,
      selector: [],
      isEditButton: false,
      // two input from & - to $
    },
    {
      title: v_hr_people.candidates.headerTable.latest_update,
      id: 7,
      isSelector: false,
      isFilter: true,
      selector: [],
      isEditButton: false,
    },
    {
      title: v_hr_people.candidates.headerTable.creation_date,
      id: 8,
      isSelector: false,
      isFilter: true,
      selector: [],
      isEditButton: false,
    },
  ]);
  const [initialCandidate, setInitialCandidate] = useState();
  const [candidateBoardId, setCandidateBoardId] = useState();

  useEffect(() => {
    handleCandidates({ typeConfirm: TYPES_CONFIRM.CANDIDATES, page: 1 });
    getDirection(1);
  }, []);

  useEffect(() => {
    const boardsData = get(boards, "boards", []);
    setDirectionData(boardsData);
  }, [boards]);

  useEffect(() => {
    const candidatesDataChanged = get(
      candidatesDataFromRedux,
      "candidates",
      [],
    );

    setCandidatesData(candidatesDataChanged);
  }, [candidatesDataFromRedux]);

  useEffect(() => {
    const vacancyData = get(vacanciesDataFromRedux, "vacancies", []);
    setVacanciesData(vacancyData);
  }, [vacanciesDataFromRedux]);

  useEffect(() => {
    if (searchedValue) {
      handleCandidates({
        typeConfirm: TYPES_CONFIRM.SEARCH,
        page: pageCandidates,
      });
    } else {
      handleCandidates({
        typeConfirm: TYPES_CONFIRM.CANDIDATES,
        page: pageCandidates,
      });
    }
  }, [pageCandidates]);

  const handleOpenViewMode = async (candidate) => {
    await setInitialCandidate(candidate);
    await setCandidateBoardId(candidate.board_id);
    setOpenCardModal(!openCardModal);
  };

  const openModal = async () => {
    await setInitialCandidate();
    setOpenCardModal(!openCardModal);
  };

  const closeCardModal = async () => {
    await setCandidateBoardId();
    setOpenCardModal(!openCardModal);
  };

  const handleModalCreateNewCandidate = ({
    isOpen = true,
    type = "edit",
    id = null,
  }) => {
    setDirectionModal((prevState) => ({
      ...prevState,
      isOpen: isOpen,
      typeDirection: type,
      directionId: id,
    }));
  };

  const handleSetSearchedValue = async (value) => {
    if (value === null) {
      // user clicked clear value
      setSearchedValue("");
      await handleCandidates({
        typeConfirm: TYPES_CONFIRM.CANDIDATES,
        page: 1,
      });
    } else {
      setSearchedValue(value);
    }
  };

  const handleCandidates = async ({
    typeConfirm = TYPES_CONFIRM.SEARCH,
    page = false,
  }) => {
    switch (typeConfirm) {
      case "search":
        if (searchedValue) {
          const data = await searchCandidatesHandler({
            searchedValue: searchedValue,
            page: pageCandidates,
          });

          setCandidatesData(data?.candidates);
        } else {
          await getCandidatesHandler(1);
        }
        break;

      default:
        const passedPage = page ? page : pageCandidates;
        const data = await searchCandidatesHandler({
          searchedValue: "",
          page: passedPage,
        });

        if (data) {
          setCandidatesData(data.candidates);
        }
        break;
    }
  };

  return (
    <StyledCandidatesBody className="directions-body">
      <StyledDirHeaderWrapper className="dir-header-wrapper">
        <StyledCandidatesSearch className="directions-search">
          <RecruitmentSearchComponent
            handleSearch={handleSetSearchedValue}
            handleConfirmSearch={handleCandidates}
            placeholder={v_hr_people.directions.search_by_name_placeholder}
          />
          <StyledAddDirButton
            className="add-dir__btn"
            onClick={handleCandidates}
          >
            {v_search}
          </StyledAddDirButton>
        </StyledCandidatesSearch>
        <StyledAddDir>
          <StyledAddDirButton
            className="add-dir__btn"
            onClick={() => openModal()}
          >
            <IconButtonPlus />
            {v_hr_people.candidates.new_candidates}
          </StyledAddDirButton>
        </StyledAddDir>
      </StyledDirHeaderWrapper>
      <StyledMenuWrapper>
        <Table>
          <RecruitmentTableHeader tableHeaderDataList={tableHeaderDataList} />
          {candidatesData && candidatesData.length >= 1 && (
            <ListOfCandidates
              candidates={candidatesData}
              handleOpenViewMode={handleOpenViewMode}
            />
          )}
        </Table>
      </StyledMenuWrapper>
      <StyledCandidatesListWrapper
        className={classNames("directions-list-wrapper", {
          "directions-list-wrapper__no-directions":
            candidatesData && candidatesData?.length === 0,
        })}
      >
        {candidatesData && candidatesData?.length === 0 && (
          <StyledNoCandidates className="no-directions">
            <NoInfo
              text={v_hr_people.candidates.no_candidates_text}
              btnText={v_hr_people.candidates.new_candidates}
              buttonHandler={() =>
                handleModalCreateNewCandidate({ type: TYPES_BUTTON.CREATE })
              }
            />
          </StyledNoCandidates>
        )}
      </StyledCandidatesListWrapper>
      {candidatesData?.length >= 1 ? (
        <Pagination
          currentPage={candidatesDataFromRedux?.currentPage}
          generalCount={candidatesDataFromRedux?.totalPages}
          setCurrentPage={setPageCandidates}
        />
      ) : null}
      {!openCardModal && (
        <CardModal
          closeCardModal={closeCardModal}
          candidates={initialCandidate}
          selectors={directionData}
          vacanciesData={vacanciesData}
          vacanciesDataFromRedux={vacanciesDataFromRedux}
          boardId={candidateBoardId}
        />
      )}
    </StyledCandidatesBody>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  currentTeam: state.teamReducer.currentTeam.data.id,
  candidatesDataFromRedux: state.boardReducer.candidates,
  vacanciesDataFromRedux: state.boardReducer.vacancies,
  boards: state.boardReducer.directions,
});

const mapDispatchToProps = {
  searchCandidatesHandler: searchCandidates,
  getCandidatesHandler: getCandidates,
  getDirection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
