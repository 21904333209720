import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import countries from "../../../locales/countries";

// Styles
import "./style.scss";

const BillingInfoDetails = ({ vocabulary, lang, data }) => {
  const { v_billing_info_details } = vocabulary;

  return (
    <div className="billing-info-details__row">
      <div className="billing-info-details__col">
        <ul className="billing-info-details__list">
          <li className="billing-info-details__item">
            <div className="billing-info-details__label billing-info-details__details-label">
              {v_billing_info_details.emailAddress}
            </div>
            <div className="billing-info-details__value">
              {data.email || "-"}
            </div>
          </li>
          <li className="billing-info-details__item">
            <div className="billing-info-details__label billing-info-details__details-label">
              {"Name"}
            </div>
            <div className="billing-info-details__value">
              {data.name || "-"}
            </div>
          </li>
          <li className="billing-info-details__item">
            <div className="billing-info-details__label billing-info-details__details-label">
              {v_billing_info_details.phoneNumber}
            </div>
            <div className="billing-info-details__value">
              {data.phone || "-"}
            </div>
          </li>
        </ul>
      </div>
      <div className="billing-info-details__col">
        <ul className="billing-info-details__list">
          <li className="billing-info-details__item">
            <div className="billing-info-details__label billing-info-details__details-label">
              {v_billing_info_details.country}
            </div>
            <div className="billing-info-details__value">
              {countries[lang][data.address.country] || "-"}
            </div>
          </li>
          <li className="billing-info-details__item">
            <div className="billing-info-details__label billing-info-details__details-label">
              {"Address"}
            </div>
            <div className="billing-info-details__value">
              {data.address.line1 || "-"}
            </div>
          </li>
          <li className="billing-info-details__item">
            <div className="billing-info-details__label billing-info-details__details-label">
              {v_billing_info_details.postalCode}
            </div>
            <div className="billing-info-details__value">
              {data.address.postal_code || "-"}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

BillingInfoDetails.propTypes = {
  vocabulary: Proptypes.object,
  data: Proptypes.object,
};

const mapStateToProps = (store) => ({
  vocabulary: store.languageReducer.vocabulary,
  lang: store.languageReducer.selectedLang,
});

export default connect(mapStateToProps)(BillingInfoDetails);
