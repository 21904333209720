import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import classNames from "classnames";

// Services
import { getCurrentDate } from "../../../services/timeService";
import CustomTippy from "../../Unstructured/Tooltip/CustomTippy";

// Components
import TeamAdd from "../TeamAdd";
import { Loading } from "../Loading";

// Actions
import { switchTeamRequestAction } from "../../../redux/actions/TeamActions";
import { switchMenu } from "../../../redux/actions/ResponsiveActions";
import reportsPageAction from "../../../redux/actions/ReportsPageAction";
import {
  changeDateOnTheReportsCalendar,
  newGetUserTeamsAction,
} from "../../../redux/actions/TeamActions";
import { changeCrossTeamStatusAction } from "../../../redux/actions/UserActions";

// Queries

// Config
import { stopTimerSocket } from "../../../api/configSocket";

// Styles
import "./style.scss";
import moment from "moment";
import { getArrOfProjectsData } from "../../../services/reportsService";

class TeamSwitcher extends Component {
  state = {
    openTeamList: false,
    selectionRange: {
      startDate: getCurrentDate(),
      endDate: getCurrentDate(),
      key: "selection",
    },
  };

  handleChange = (e) => {
    this.props.newGetUserTeamsAction();

    e.preventDefault();
    const {
      currentTeam,
      switchTeamRequestAction,
      history,
      switchMenu,
      currentTimer,
      isMobile,
    } = this.props;
    const teamId = e.currentTarget.getAttribute("data-id");

    if (currentTeam.data.id !== teamId) {
      if (currentTimer) {
        // stopTimerSocket();  // Stops the timer when changing teams
      }
      switchTeamRequestAction({ teamId });
    }
    this.setState({ openTeamList: false });
    // Reset filters on the Reports when change team
    this.props.reportsPageAction("SET_SELECTED_PROJECTS", {
      data: [],
    });
    this.props.reportsPageAction("SET_SELECTED_USERS", {
      data: [this.props.user.email],
    });
    this.props.changeDateOnTheReportsCalendar({
      data: this.state.selectionRange,
    });

    this.props.reportsPageAction(
      "SET_SELECTION_RANGE",
      this.state.selectionRange,
    );

    this.props.reportsPageAction(
      "SET_START_DATE",
      moment().format("DD.MM.YYYY"),
    );

    this.props.reportsPageAction("SET_END_DATE", moment().format("DD.MM.YYYY"));

    this.props.reportsPageAction("SET_PROJECTS", {
      data: [],
    });

    this.props.changeCrossTeamStatusAction(false);
    history.push("/team");

    if (isMobile) {
      switchMenu();
    }
  };

  componentDidMount() {
    this.props.newGetUserTeamsAction();
  }

  render() {
    const { openTeamList } = this.state;
    const {
      isMobile,
      vocabulary,
      userTeams,
      currentTeam,
      newUserTeams,
      isCollapsed,
    } = this.props;
    const { v_teams } = vocabulary;
    const teamMarker =
      newUserTeams && newUserTeams?.user_team?.map((it) => it.invite_mark);
    const displayTeamMarker = teamMarker?.includes(true);

    return (
      <Loading
        flag={userTeams.isInitialFetching}
        withLogo={false}
        mode="inline"
      >
        <div
          className={`team_list ${
            isMobile
              ? "team_list_overflow-hidden"
              : "team_list_overflow-visible"
          }`}
          data-theme={this.props.themes}
        >
          <div
            className={isCollapsed ? 'team_link-collapsed' : "team_link"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({ openTeamList: !openTeamList });
            }}
          >
            <div style={{ display: "flex" }}>
              <CustomTippy
                content={v_teams}
                disabled={!isCollapsed || isMobile}
              >
                <div style={{ display: "flex" }}>
                  {displayTeamMarker && <span className={"team_list__circle"} />}
                  <i
                    className={classNames("team", {
                      "team-light": localStorage.getItem("themes") === "light",
                    })}
                  />
                </div>
              </CustomTippy>
              <div className="team_text">{v_teams}</div>
            </div>
            {!isCollapsed &&
            <i
              className={classNames({
                arrow_closed: !openTeamList && !isMobile,
                arrow_open: openTeamList && !isMobile,
                arrow_closed_mobile: !openTeamList && isMobile,
                arrow_open_mobile: openTeamList && isMobile,
              })}
            />}
          </div>
          <TeamAdd
            isMobile={isMobile}
            userTeams={userTeams}
            newUserTeams={this.props.newUserTeams}
            displayTeamMarker={displayTeamMarker}
            currentTeam={currentTeam}
            vocabulary={vocabulary}
            handleChange={this.handleChange}
            openTeamList={openTeamList}
            closeTeamList={(e) => this.setState({ openTeamList: false })}
          />
        </div>
      </Loading>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  userTeams: state.teamReducer.userTeams,
  newUserTeams: state.teamReducer.newUserTeams,
  currentTeam: state.teamReducer.currentTeam,
  currentTimer: state.mainPageReducer.currentTimer,
  user: state.userReducer.user,
});

const mapDispatchToProps = {
  switchTeamRequestAction,
  switchMenu,
  changeCrossTeamStatusAction,
  changeDateOnTheReportsCalendar,
  newGetUserTeamsAction,
  reportsPageAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamSwitcher),
);
