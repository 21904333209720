import jwtDecode from "jwt-decode";

import {
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
} from "./tokenStorageService";

import { store } from "../redux/store";
import { resetAll } from "../redux/actions/UserActions";

import { closeSocket } from "../api/configSocket";

const APP_VERSION = "v1.0.7";

export const ROLES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_MEMBER: "ROLE_MEMBER",
  ROLE_OWNER: "ROLE_OWNER",
  ROLE_INVOICES_MANAGER: "ROLE_INVOICES_MANAGER",
  ROLE_PROJECT_MANAGER: "ROLE_PROJECT_MANAGER",
  ROLE_HR_MANAGER: "ROLE_HR_MANAGER",
  ROLE_RECRUITER: "ROLE_RECRUITER",
};

export const ROLES_TITLES = {
  [ROLES.ROLE_ADMIN]: "Admin",
  [ROLES.ROLE_MEMBER]: "Member",
  [ROLES.ROLE_OWNER]: "Owner",
  [ROLES.ROLE_INVOICES_MANAGER]: "Invoices manager",
  [ROLES.ROLE_PROJECT_MANAGER]: "Project manager",
  [ROLES.ROLE_HR_MANAGER]: "HR manager",
  [ROLES.ROLE_RECRUITER]: "Recruiter",
};

export const checkAppVersion = () => {
  const token = getTokenFromLocalStorage();

  try {
    const { appVersion } = jwtDecode(token);

    return APP_VERSION === appVersion;
  } catch (error) {
    return false;
  }
};

export function checkIsAdminByRole(role) {
  return role === ROLES.ROLE_ADMIN;
}

export function checkIsMemberByRole(role) {
  return role === ROLES.ROLE_MEMBER;
}

export function checkIsOwnerByRole(role) {
  return role === ROLES.ROLE_OWNER;
}

export function checkIsInvoicesManagerByRole(role) {
  return role === ROLES.ROLE_INVOICES_MANAGER;
}

export function checkIsProjectManagerByRole(role) {
  return role === ROLES.ROLE_PROJECT_MANAGER;
}

export function checkIsHRManagerByRole(role) {
  return role === ROLES.ROLE_HR_MANAGER;
}

export function checkIsRecruiterByRole(role) {
  return role === ROLES.ROLE_RECRUITER;
}

export const checkAccessByRole = (role, grantedRoles) =>
  grantedRoles.some((item) => item === role);

export function logoutByUnauthorized() {
  closeSocket();
  removeTokenFromLocalStorage();
  store.dispatch(resetAll());
}
