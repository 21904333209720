import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 50px;
  justify-content: space-between;
  opacity: 0.6;
  align-items: center;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  &:hover {
    cursor: pointer;
  }
`;

export const PreviosButtonWrapper = styled.div`
  opacity: 0.4;
  display: flex;
  flex-direction: row;
  width: 50px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const PageWrapper = styled.div<{ isActive?: boolean }>`
  margin: 0 2px;
  padding: 3px 9px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.1)"};
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const Rotate = styled.div`
  display: flex;
  transform: rotate(180deg);
`;

export const PageText = styled.span`
  font-size: 12px;
  color: white;
`;

export const PagesWrapper = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Separator = styled.span`
  margin: 0 5px;
  color: white;
  font-size: 12px;
`;
