import React from "react";
import moment from "moment";

export default function InfoLabell({ item, group, time, lang }) {
  const date = moment(time, "x");
  const label = group ? group.username : "";

  return (
    <div
      style={{
        position: "fixed",
        left: 225,
        bottom: 50,
        background: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: 10,

        fontSize: 20,
        borderRadius: 5,
        zIndex: 100,
      }}
    >
      {`${date.locale(lang.short).format("LLL")}, ${label}`}
    </div>
  );
}
