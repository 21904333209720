import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_REQUEST_SUCCESS,
  GET_CLIENTS_REQUEST_ERROR,
  SET_CLIENTS_PAGE,
  SET_CLIENTS_LIST_ENDED,
  CHANGE_CLIENTS_REQUEST,
  CHANGE_CLIENTS_SUCCESS,
  CHANGE_CLIENTS_ERROR,
  CHANGE_CLIENTS_SEARCH_VALUE,
  CHANGE_CLIENTS_FILTER_STATUS,
} from "../actions/ClientsActions";

const initialState = {
  clientsList: null,
  isFetching: false,
  searchValue: "",
  filterStatus: "all",
  isInitialFetching: true,
  error: null,
  total: 0,
  pagination: {
    page: 1,
    limit: 15,
    isListEnded: false,
    listFetching: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        pagination: { ...state.pagination, listFetching: true },
        isInitialFetching: false,
      };

    case GET_CLIENTS_REQUEST_SUCCESS:
      return {
        ...state,
        clientsList: payload.clientsList,
        pagination: { ...state.pagination, listFetching: false },
        isInitialFetching: false,
        total: payload.total,
      };

    case GET_CLIENTS_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        pagination: { ...state.pagination, listFetching: false },
        isInitialFetching: false,
      };

    case CHANGE_CLIENTS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case CHANGE_CLIENTS_SUCCESS:
      return {
        ...state,
        clientsList: payload.clientsList,
        isFetching: false,
        total: payload.total,
      };

    case CHANGE_CLIENTS_ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };

    case CHANGE_CLIENTS_SEARCH_VALUE:
      return {
        ...state,
        searchValue: payload,
      };

    case CHANGE_CLIENTS_FILTER_STATUS:
      return {
        ...state,
        filterStatus: payload,
      };

    case SET_CLIENTS_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      };

    case SET_CLIENTS_LIST_ENDED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          isListEnded: payload,
        },
      };

    default:
      return state;
  }
};
