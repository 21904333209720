import React from "react";
import PaginationNextButtonSvg from "../../../assets/images/ComponentIcons/PaginationNextButtonSvg";

import {
  Container,
  NextButtonWrapper,
  ButtonText,
  PreviosButtonWrapper,
  PageWrapper,
  Rotate,
  PageText,
  PagesWrapper,
  Separator
} from "./My.styled"

interface IPaginationButtonsProps {
  generalCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  maxPages?: number
}

const Pagination: React.FC<IPaginationButtonsProps> = ({
                                                         generalCount,
                                                         currentPage,
                                                         setCurrentPage,
                                                         maxPages = 5,
                                                       }) => {
  const getCustomStartPosition = () => {
    if (currentPage <= 1) {
      return 1;
    }
    return currentPage - 1;
  };

  const onNextPress = () => {
    if (currentPage + 1 > generalCount) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPreviosPress = () => {
    if (currentPage - 1 < 1) {
      setCurrentPage(generalCount);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPage = (key: number, page: number, isActive: boolean) => (
    <PageWrapper
      key={key}
      onClick={() => setCurrentPage(page)}
      isActive={isActive}
    >
      <PageText>{page}</PageText>
    </PageWrapper>
  );

  const renderPages = () => {
    if (generalCount < maxPages) {
      return Array(generalCount)
        .fill(null)
        .map((_, index) => {
          const page = index + 1;
          const isActive = currentPage === page;

          return renderPage(index, page, isActive);
        });
    } else if (generalCount - currentPage < maxPages) {
      return Array(maxPages)
        .fill(null)
        .map((_, index) => {
          const page = generalCount - (5 - index) + 1;
          const isActive = currentPage === page;

          return renderPage(index, page, isActive);
        });
    } else {
      return (
        <>
          {Array(maxPages - 1)
            .fill(null)
            .map((_, index) => {
              const page = getCustomStartPosition() + index;
              const isActive = currentPage === page;

              return renderPage(index, page, isActive);
            })}
          <Separator>...</Separator>
          <PageWrapper onClick={() => setCurrentPage(generalCount)}>
            <PageText>{generalCount}</PageText>
          </PageWrapper>
        </>
      );
    }
  };

  return (
    <Container>
      <PreviosButtonWrapper onClick={onPreviosPress}>
        <Rotate>
          <PaginationNextButtonSvg/>
        </Rotate>
        <ButtonText>Prev</ButtonText>
      </PreviosButtonWrapper>
      <PagesWrapper>{renderPages()}</PagesWrapper>
      <NextButtonWrapper onClick={onNextPress}>
        <ButtonText>Next</ButtonText>
        <PaginationNextButtonSvg/>
      </NextButtonWrapper>
    </Container>
  );
};

export default Pagination;
