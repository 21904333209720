import styled from "styled-components/macro";
import PlusButtonImage from "../../../../assets/images/icons/Plan-plus.svg";

export const StyledCandidatesSearch = styled.div`
  /* styles for directions-search */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledAddDir = styled.div`
  /* styles for add-dir */

`;

export const StyledCandidatesBody = styled.div`
  /* styles for directions-body */
  flex: 1 1;
`;

export const StyledDirHeaderWrapper = styled.div`
  /* styles for dir-header-wrapper */
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledAddDirButton = styled.button`
  /* styles for add-dir__btn */
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  color: #fff;
  background-color: #00b173;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-top: 20px;
  margin-right: 10px;
  padding: 12.5px 24px;
  transition: font 0.3s ease-in-out;
  white-space: nowrap;

  :active {
    box-shadow: inset 0 0 13px 0 rgba(0, 0, 0, 0.75);
    background: var(--bg-card-plan);
    color: var(--text);
  }
`;

export const StyledMenuWrapper = styled.div`
  /* styles for menu-wrapper */
  margin-top: 50px;
`;

export const StyledCandidatesListWrapper = styled.div`
  /* styles for directions-list-wrapper */
`;

export const StyledNoCandidates = styled.div`
  /* styles for no-directions */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170%;
`;

export const IconButtonPlus = styled.i`
  /* styles for no-directions */
  display: block;
  height: 20px;
  width: 20px;
  margin-right: 6px;
  background: url(${PlusButtonImage}) no-repeat center;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;
