import React, { Component } from "react";

import { ArrowIcon } from "../../Atoms/SvgIcons";

import "./style.scss";
import classNames from "classnames";

class AccordionComponent extends Component {
  constructor(props) {
    super(props);

    const { isShowOnInit = false } = props;

    this.state = {
      isOpen: isShowOnInit,
    };
  }

  toggleAccordion = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { children, title, className, disabled } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={classNames("accordion", className)}>
        <button
          className="accordion__toggle"
          disabled={disabled}
          onClick={this.toggleAccordion}
        >
          <div>{title}</div>
          {!disabled && (
            <ArrowIcon
              className={classNames("accordion__icon", {
                "accordion__icon--active": isOpen,
              })}
              color="white"
            />
          )}
        </button>
        {!disabled && isOpen && (
          <div className="accordion__content">{children}</div>
        )}
      </div>
    );
  }
}

export default AccordionComponent;
