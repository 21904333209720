import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

// Actions
import { scrollToAction } from "../../../redux/actions/ResponsiveActions";

// Styles
import "./style.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
// import { DateRange } from "react-date-range";
import { DateRange, Calendar } from "react-date-range";
import { enGB, ru, de, it, ua } from "react-date-range/src/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "moment/locale/uk";
import "moment/locale/ru";
import "moment/locale/it";
import "moment/locale/en-gb";
import "moment/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-GB";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";

import { CalendarIcon, CrossIcon, CheckIcon } from "../../Atoms/SvgIcons";

const localeMap = {
  ru: ru,
  en: enGB,
  de: de,
  it: it,
  uk: ua,
};

const muiTheme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },
    MuiTypography: {
      root: {
        fontSize: "1.7rem !important",
        color: "#fff",
      },
      caption: {
        color: "#fff",
      },
    },
    MuiInputAdornment: {
      root: {
        display: "none",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
      },
    },
    MuiInput: {
      root: {
        fontSize: "1.7rem !important",
        color: "#fff",
        borderColor: "#fff",
      },
      underline: {
        borderColor: "#fff !important",
        "&:before": {
          borderColor: "#fff !important",
        },
        "&:after": {
          borderColor: "#fff !important",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: "8px",
      },
    },
  },
});

class DateRangeSelect extends Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();

    const { startDate, endDate } = props;

    this.state = {
      isOpen: false,
      startDate: startDate ? moment(startDate).startOf("day").toDate() : "",
      endDate: endDate ? moment(endDate).endOf("day").toDate() : "",
      startTime: startDate
        ? moment(startDate).startOf("day").toDate()
        : moment(startDate).startOf("day").toDate(),
      endTime: endDate
        ? moment(endDate).endOf("day").toDate()
        : moment(endDate).endOf("day").toDate(),
      isChangedDate: false,
      dateForDay: null,
    };
  }

  openDropdown = (event) => {
    event.stopPropagation();
    const { disabled } = this.props;

    if (disabled) {
      return;
    }
    if (
      event.target.classList.contains("daterange-select__close-button") ||
      event.target.classList.contains("daterange-select__close-icon")
    ) {
      return;
    }
    this.setState({
      isOpen: true,
    });
  };

  handleCloseDropdown = (event) => {
    if (this.dropdown && !this.dropdown.current?.contains(event.target)) {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    if (!moment(this.state.startTime).isValid()) {
      const { startDate } = this.props;
      const prevStartDate = moment(startDate).isValid()
        ? moment(startDate).toDate()
        : null;

      this.setState({ startTime: prevStartDate || new Date() });
    }
    if (!moment(this.state.endTime).isValid()) {
      const { endDate } = this.props;
      const prevEndDate = moment(endDate).isValid()
        ? moment(endDate).toDate()
        : null;

      this.setState({ endTime: prevEndDate || new Date() });
    }

    this.setState({ isOpen: false, isChangedDate: false });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;

    if (!prevState.isOpen && isOpen) {
      const { startDate, endDate } = this.state;
      this.changeDateHandler({ startDate, endDate });

      this.setState({ isChangedDate: false });
    }
  }

  componentDidMount() {
    const { startDate } = this.props;

    if (startDate) {
      setTimeout(
        () => this.changeDay(moment(startDate).startOf("day").toDate()),
        100,
      );
    }
    document.addEventListener("mousedown", this.handleCloseDropdown, true);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleCloseDropdown, true);
  }

  changeDateHandler = ({ startDate, endDate }) => {
    const { startTime, endTime } = this.state;
    let startDateWithTime, endDateWithTime;

    if (startTime) {
      startDateWithTime = moment(startDate)
        .set({ h: startTime.getHours(), m: startTime.getMinutes() })
        .toDate();
    }
    if (endTime) {
      endDateWithTime = moment(endDate)
        .set({ h: endTime.getHours(), m: endTime.getMinutes() })
        .toDate();
    }

    this.props.onChangeDate({
      startDate: startTime ? startDateWithTime : startDate,
      endDate: endTime ? endDateWithTime : endDate,
    });
    this.setState({
      startDate: startTime ? startDateWithTime : startDate,
      endDate: endTime ? endDateWithTime : endDate,
      startTime: startTime ? startTime : startDate,
      endTime: endTime ? endTime : endDate,
      isChangedDate: true,
    });
  };

  changeRangeHandler = ({ selection: { startDate, endDate } }) => {
    this.changeDateHandler({ startDate, endDate });
  };

  changeDay = (item) => {
    this.setState({
      dateForDay: item,
    });
    this.props.onChangeDate(item);
  };

  changeStartTimeHandler = (time) => {
    if (time === "Invalid Date" || time === null) {
      this.setState({ startTime: null });
      return;
    }

    let { startDate } = this.state;

    if (!moment(startDate).isValid()) {
      startDate = moment();
    }

    const dateWithTime = moment(startDate)
      .set({ h: time.getHours(), m: time.getMinutes() })
      .toDate();

    this.setState(
      {
        startTime: time,
        startDate: dateWithTime,
        isChangedDate: true,
      },
      () => {
        this.changeDateHandler({
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        });
      },
    );
  };

  changeEndTimeHandler = (time) => {
    if (time === "Invalid Date" || time === null) {
      this.setState({ endTime: null });
      return;
    }

    let { endDate } = this.state;

    if (!moment(endDate).isValid()) {
      endDate = moment();
    }

    const dateWithTime = moment(endDate)
      .set({ h: time.getHours(), m: time.getMinutes() })
      .toDate();

    this.setState(
      {
        endTime: time,
        endDate: dateWithTime,
        isChangedDate: true,
      },
      () => {
        this.changeDateHandler({
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        });
      },
    );
  };

  blurTimeHandler = () => {
    const { startDate, endDate, startTime, endTime } = this.state;

    if (!startDate || !endDate || !startTime || !endTime) {
      return;
    }

    const startDateWithTime = moment(startDate)
      .set({ h: startTime.getHours(), m: startTime.getMinutes() })
      .toDate();

    const endDateWithTime = moment(endDate)
      .set({ h: endTime.getHours(), m: endTime.getMinutes() })
      .toDate();

    this.props.onChangeDate({
      startDate: startDateWithTime,
      endDate: endDateWithTime,
    });

    this.setState((state) => ({
      startDate: startDateWithTime,
      endDate: endDateWithTime,
    }));
  };

  saveChanges = () => {
    const { startDate, endDate, isChangedDate } = this.state;

    if (isChangedDate) {
      this.changeDateHandler({ startDate, endDate });
      this.closeDropdown();
    }
  };

  preventClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      vocabulary,
      isMobile,
      disabled,
      firstDayOfWeek,
      dateFormat,
      startPlaceholder,
      endPlaceholder,
      withValidation,
      startErrorMsg,
      endErrorMsg,
      timeFormat,
      startDropdownTitle,
      endDropdownTitle,
      isDayCalendar = false,
    } = this.props;
    const { lang } = vocabulary;
    const { isOpen, startDate, endDate, startTime, endTime, isChangedDate } =
      this.state;

    const customLocale = localeMap[lang.short];

    customLocale.options.weekStartsOn = firstDayOfWeek;

    return (
      <div
        ref={(ref) => (this.container = ref)}
        className="daterange-select"
        onClick={this.openDropdown}
      >
        <div
          className={classNames("daterange-select__selected-date", {
            "daterange-select--mobile": isMobile,
            "daterange-select--error": startErrorMsg && withValidation,
            "daterange-select--disabled": disabled,
          })}
        >
          <CalendarIcon color="#fff" />
          {moment(startDate).isValid() ? (
            <span className="daterange-select__date">
              {moment(startDate).locale(lang.short).format("MMM DD")}
            </span>
          ) : (
            <span className="daterange-select__placeholder">
              {startPlaceholder}
            </span>
          )}
          <div className="daterange-select__date-separator"></div>
          {moment(endDate).isValid() ? (
            <span className="daterange-select__date">
              {moment(endDate).locale(lang.short).format("MMM DD")}
            </span>
          ) : (
            <span className="daterange-select__placeholder">
              {endPlaceholder}
            </span>
          )}
        </div>
        {/* <div className="daterange-select__date-separator"></div>
        <div
          className={classNames("daterange-select__selected-date", {
            "daterange-select--mobile": isMobile,
            "daterange-select--error": endErrorMsg && withValidation,
          })}
        >
          <CalendarIcon color="#fff" />
          {moment(endDate).isValid() ? (
            <span className="daterange-select__date">
              {moment(endDate).locale(lang.short).format("MMM DD HH:mm")}
            </span>
          ) : (
            <span className="daterange-select__placeholder">
              {endPlaceholder}
            </span>
          )}
        </div> */}
        {isOpen && (
          <div
            ref={this.dropdown}
            className={classNames("daterange-select__dropdown")}
            onClick={this.preventClick}
          >
            <ThemeProvider theme={muiTheme}>
              <div className="daterange-select__time-wrapper">
                <div className="daterange-select__header">
                  <div className="daterange-select__buttons">
                    <button
                      type="button"
                      className={classNames("daterange-select__button-save", {
                        "daterange-select__button-save--touched": isChangedDate,
                      })}
                      onClick={this.saveChanges}
                    >
                      <CheckIcon
                        className="daterange-select__save-icon"
                        onClick={this.saveChanges}
                      />
                    </button>
                    <button
                      type="button"
                      className="daterange-select__button"
                      onClick={this.closeDropdown}
                    >
                      <CrossIcon
                        className="daterange-select__close-icon"
                        onClick={this.closeDropdown}
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="daterange-select__time-inputs">
                <div>
                  <div className="daterange-select__time-inputs__from-to">
                    {startDropdownTitle || ""}
                  </div>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={enLocale}
                  >
                    <div className="daterange-select__time-input">
                      <KeyboardTimePicker
                        value={startTime}
                        disableToolbar
                        ampm={timeFormat === "12"}
                        onChange={this.changeStartTimeHandler}
                        onBlur={this.blurTimeHandler}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
                <div>
                  <div className="daterange-select__time-inputs__from-to">
                    {endDropdownTitle || ""}
                  </div>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={enLocale}
                  >
                    <div className="daterange-select__time-input">
                      <KeyboardTimePicker
                        value={endTime}
                        disableToolbar
                        ampm={timeFormat === "12"}
                        onChange={this.changeEndTimeHandler}
                        onBlur={this.blurTimeHandler}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div> */}

              {/* eslint-disable-next-line multiline-ternary */}
              {isDayCalendar ? (
                <Calendar
                  onChange={this.changeDay}
                  date={this.state.dateForDay}
                  className="daterange-select__calendar"
                />
              ) : (
                <DateRange
                  locale={customLocale}
                  className="daterange-select__calendar"
                  dateDisplayFormat={dateFormat}
                  onChange={this.changeRangeHandler}
                  moveRangeOnFirstSelection={false}
                  ranges={[
                    {
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    },
                  ]}
                  // minDate={moment().toDate()}
                />
              )}
              {/* <DateRange */}
              {/*   locale={customLocale} */}
              {/*   className="daterange-select__calendar" */}
              {/*   dateDisplayFormat={dateFormat} */}
              {/*   onChange={this.changeRangeHandler} */}
              {/*   moveRangeOnFirstSelection={false} */}
              {/*   ranges={[ */}
              {/*     { */}
              {/*       startDate: startDate, */}
              {/*       endDate: endDate, */}
              {/*       key: "selection", */}
              {/*     }, */}
              {/*   ]} */}
              {/*   // minDate={moment().toDate()} */}
              {/* /> */}
            </ThemeProvider>
          </div>
        )}
        {withValidation ? (
          <div className="daterange-select__error-msg">
            {startErrorMsg
              ? vocabulary[startErrorMsg]
              : endErrorMsg
              ? vocabulary[endErrorMsg]
              : ""}
          </div>
        ) : null}
      </div>
    );
  }
}

DateRangeSelect.defaultProps = {
  onChangeVisibility: () => {},
  isDayCalendar: false,
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
  isMobile: state.responsiveReducer.isMobile,
  timeFormat: state.userReducer.timeFormat,
  dateFormat: state.userReducer.dateFormat,
  firstDayOfWeek: state.userReducer.firstDayOfWeek,
});

const mapDispatchToProps = {
  scrollToAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeSelect);
