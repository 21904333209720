import ReactDOM from "react-dom";
import { ReactElement } from "react";

interface IModalPortal {
  disabled?: boolean;
  children: ReactElement;
}

const ModalPortal = ({ children, disabled = false }: IModalPortal) =>
  disabled ? children : ReactDOM.createPortal(children, document.body);

export default ModalPortal;
