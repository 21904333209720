import React, { Component } from "react";
import { connect } from "react-redux";

import { ChevronDownIcon } from "../../Atoms/SvgIcons";

import moment from "moment";
import { formatReleaseNotesDateForRender } from "../../../services/versionService";

import "./style.scss";

class ReleaseNotesVersionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isOpenOnInit,
    };
  }

  toggleDropdown = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { vocabulary, versionData } = this.props;
    const { isOpen } = this.state;
    const { version, date } = versionData;
    const { v_release_notes, lang } = vocabulary;
    const { v_versions, v_version } = v_release_notes;
    const { v_improved, v_new } = v_versions[version];

    return (
      <div className="release-notes-version-item__item">
        <div
          className="release-notes-version-item__item-header"
          onClick={() => this.toggleDropdown(v_version)}
        >
          <div>
            <span className="release-notes-version-item__version">
              {v_version} {version}
            </span>
            <span className="release-notes-version-item__date">
              {formatReleaseNotesDateForRender(date, lang.short)}
            </span>
          </div>
          <button
            type="button"
            className="release-notes-version-item__button "
            onClick={() => this.toggleDropdown(v_version)}
          >
            <ChevronDownIcon
              color="#fff"
              className={`release-notes-version-item__chevron ${
                isOpen ? "release-notes-version-item__chevron--open" : ""
              }`}
            />
          </button>
        </div>

        <div
          className={`release-notes-version-item__item-body ${
            isOpen ? "release-notes-version-item__item-body--open" : ""
          }`}
        >
          <div className="release-notes-version-item__content">
            <span className="release-notes-version-item__title">
              {vocabulary.v_release_notes.v_whats_new}
            </span>
            <ul className="release-notes-version-item__list">
              {v_new.length &&
                v_new.map((new_item, key) => (
                  <li
                    key={`new_${key}`}
                    className="release-notes-version-item__list-item"
                  >
                    {new_item}
                  </li>
                ))}
            </ul>
          </div>
          <div className="release-notes-version-item__content">
            <span className="release-notes-version-item__title">
              {vocabulary.v_release_notes.v_improved}
            </span>
            <ul className="release-notes-version-item__list">
              {v_improved.length &&
                v_improved.map((improved_item, key) => (
                  <li
                    key={`improved-${key}`}
                    className="release-notes-version-item__list-item"
                  >
                    {improved_item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

export default connect(mapStateToProps)(ReleaseNotesVersionItem);
