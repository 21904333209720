import React, { ReactElement, useEffect } from "react";
import { useState, useRef } from "react";
import { useOutsideClick } from "../../../services/hookHelpers";
import { ChevronDownIcon } from "../SvgIcons";
import "./styles.scss";
import { getClientsAction } from "../../../redux/actions/ClientsActions";
import { useDispatch } from "react-redux";

export type TDropdownOption = {
  id: number;
  name: string;
  label: string;
};

export interface IDropdown {
  placeholder: string;
  list: TDropdownOption[];
  selected?: TDropdownOption | null;
  onSelect?: (option: TDropdownOption) => void;
  classes?: string;
  roleUser?: boolean;
}

const Dropdown = ({
  selected: selectedProp = null,
  classes = "",
  placeholder,
  list,
  onSelect,
  roleUser,
}: IDropdown): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<TDropdownOption | null>(
    selectedProp,
  );
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setOpen(false));

  const dispatch = useDispatch();

  useEffect(() => {
    if (roleUser) {
      dispatch(getClientsAction());
    }

  }, [roleUser]);

  const onOptionClick = (item: TDropdownOption) => {
    setSelected(item);
    if (onSelect) {
      onSelect(item);
    }
    setOpen(false);
  };

  return (
    <div ref={dropdownRef} className={`dropdown ${classes}`}>
      <div className="dropdown__selected-item" onClick={() => setOpen(!isOpen)}>
        <div className="dropdown__selected-item-text">
          {selected ? selected.label : placeholder}
        </div>
        <ChevronDownIcon
          className={`dropdown__arrow ${isOpen ? "dropdown__arrow--open" : ""}`}
          color="#ffffff"
        />
      </div>
      <div
        className={`dropdown__options-list ${
          isOpen ? "dropdown__options-list--open" : ""
        }`}
      >
        {list.map((item: TDropdownOption) => (
          <div
            key={item.id}
            className="dropdown__option"
            onClick={() => onOptionClick(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
