import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../Atoms/Modal";
import DropdownWithSearch from "../../../../Atoms/DropdownWithSearch";
import LinkToVacanciesComponent from "../../../People/LinkToVacancies";
import ReactPhoneInput from "react-phone-input-2";
import CountriesDropdown from "../../../CountriesDropdown";
import countries from "../../../CountriesDropdown/countriesFlat.json";
import AutoExpandingTextarea from "../../../../Unstructured/People/AutoExpandingTextarea/index";
import "react-phone-input-2/lib/style.css";
import DatepickerSelect from "../../../DatepickerSelect";
import CurrencySelect from "../../../../Unstructured/CurrencySelect";
import moment from "moment";
import DateRangeSelect from "../../../../Unstructured/DateRangeSelect";
import {
  createCandidateInfo,
  editCandidateInfo,
} from "../../../../../redux/actions/PepoplePageActions";
import { validationSchema, jobType, levelOptions } from "./utils";
import {
  ButtonContainer,
  CancelButton,
  CountryPhoneContainer,
  CountryContainer,
  StyledForm,
  CountOfStepWrapper,
  CountOfStepNumber,
  CountOfStepText,
  SectionLine,
  Input,
  RowContainer,
  LabelContainer,
  SalaryContainer,
  SalaryWrapper,
  CurrencyBlock,
  JobTypeBlock,
  JobTypeButton,
  SaveButton,
  TextLabel,
  TextLabelB,
  DateContainer,
  ErrorText,
  InputSmall,
  TextareaBlock,
  SymbolsCountForDescription,
  LanguageWrapper,
  AlertLabel,
  FileContainer,
  InputFile,
  FileLabel,
  TitleSubText,
  FilePlaceholder,
  RequiredfieldStar,
} from "./My.styled";
import classNames from "classnames";
import "./style.scss";
import { Formik } from "formik";

const BackArrowSvg = ({}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57 5.92999L3.5 12L9.57 18.07"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5019 12H3.67188"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CardModalForm = ({
  submitForm,
  handleNextSlide,
  renderLines,
  closeCardModal,
  cancelEdit,
  isEditCard,
  candidates,
  createCandidateInfo,
  editCandidateInfo,
  cardId,
  boardId,
  typeForm,
  vocabulary,
  selectors,
  vacanciesData,
  vacanciesDataFromRedux,
  handleGetVacanciesForDropdown,
}) => {
  const initialValues = {
    firstName: candidates?.["first_name"] || "",
    lastName: candidates?.["last_name"] || "",
    email: candidates?.email || "",
    phone: candidates?.phone || "+380",
    birthday: candidates?.birthday || "",
    startWork: candidates?.["start_work"] || "",
    desiredDate: candidates?.["desired_date"] || "",
    city: candidates?.city || "",
    country: candidates?.country || "",
    messenger: candidates?.messenger || "",
    linkedin: candidates?.linkedin || "",
    github: candidates?.github || "",
    experience: candidates?.experience || "",
    file: "",
    clientFile: "",
    minSalary: candidates?.minSalary || "",
    maxSalary: candidates?.maxSalary || "",
    currency: candidates?.currency || "USD",
    jobType: candidates?.jobType || "remote",
    vacancyId: candidates?.["vacancy_id"] || candidates?.vacancy?.id || "",
    level: candidates?.level || "",
    interviewDate: candidates?.["interview_date"] || "",
    techInterviewDate: candidates?.["tech_interview_date"] || "",
    feedbackTech: candidates?.["feedback_tech"] || "",
    feedbackHr: candidates?.["feedback_hr"] || "",
    boardId: boardId || candidates?.board?.id || "",
    linksObj: candidates?.["candidate_link"] || [],
    links: [],
    board: {
      label: candidates?.board?.title || "",
      value: candidates?.board?.id || "",
    },
    vacancy: {
      label: candidates?.vacancy?.title || "",
      value: candidates?.vacancy?.id || "",
    },
  };

  const [isOpenCountriesDropdown, setIsOpenCountriesDropdown] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(
    candidates?.jobType || "remote",
  );
  // const [selectedDir, setSelectedDir] = useState( || "");
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [directionOptions, setDirectionOptions] = useState([]);
  const [vacancyOptions, setVacancyOptions] = useState([]);
  const {
    v_save,
    v_back,
    v_hr_people,
    v_name,
    v_surname,
    v_email,
    v_billing_info_details,
    v_country,
    v_city,
    v_resource_planning_page,
    v_subscription,
  } = vocabulary;
  const [cvPlace, setCvPlace] = useState(v_hr_people.candidates_modal.cvPlace);
  const [cvClientPlace, setCvClientPlace] = useState(
    v_hr_people.candidates_modal.cvClient,
  );
  const [availableVacancies, setAvailableVacancies] = useState(false);
  const [newBoardId, setNewBoardId] = useState(boardId);

  const editCandidate = async (values) => {
    setIsDisabledSubmit(true);
    {
      candidates
        ? editCandidateInfo(candidates.id, values)
        : createCandidateInfo(values);
    }
    setIsDisabledSubmit(false);
  };

  const handleAmountChange = (setFieldValue, state) => (event) => {
    const { value } = event.target;
    const cleanedValue = value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*)\./g, "$1");

    setFieldValue(`${state}`, Number(cleanedValue));
  };

  const ErrorMessage = (error) => <ErrorText>{error}</ErrorText>;

  const handleJobTypeClick = (setFieldValue, selectedJobType) => {
    setFieldValue("jobType", selectedJobType);
    setSelectedJobType(selectedJobType);
  };

  const directionArray = (inputArray) => {
    return inputArray.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  };

  useEffect(() => {
    if (boardId) {
      const currentBoard = selectors.filter((board) => board.id === boardId);
      setDirectionOptions((prevOptions) => {
        const newOptions = directionArray(currentBoard);
        const uniqueOptions = prevOptions.concat(
          newOptions.filter(
            (newOption) =>
              !prevOptions.some(
                (prevOption) => prevOption.value === newOption.value,
              ),
          ),
        );

        return uniqueOptions;
      });
    } else {
      setDirectionOptions((prevOptions) => {
        const newOptions = directionArray(selectors);
        const uniqueOptions = prevOptions.concat(
          newOptions.filter(
            (newOption) =>
              !prevOptions.some(
                (prevOption) => prevOption.value === newOption.value,
              ),
          ),
        );
        return uniqueOptions;
      });
    }
  }, [selectors]);

  useEffect(() => {
    if (newBoardId) {
      const currentBoardVacancies = vacanciesData.filter(
        (vacancy) => vacancy.board_id === newBoardId,
      );
      setVacancyOptions((prevOptions) => {
        const newOptions = directionArray(currentBoardVacancies);
        setAvailableVacancies(true);

        return newOptions;
      });
    } else {
      setAvailableVacancies(false);
    }
  }, [newBoardId, vacanciesData]);

  const fileHandler = (values) => {
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      birthday: values.birthday,
      startWork: values.startWork,
      desiredDate: values.desiredDate,
      city: values.city,
      country: values.country,
      messenger: values.messenger,
      linkedin: values.linkedin,
      github: values.github,
      experience: values.experience,
      file: values.file,
      clientFile: values.clientFile,
      minSalary: values.minSalary,
      maxSalary: values.maxSalary,
      currency: values.currency,
      jobType: values.jobType,
      position: values.vacancy.value,
      level: values.level,
      interviewDate: values.interviewDate,
      techInterviewDate: values.techInterviewDate,
      feedbackTech: values.feedbackTech,
      feedbackHr: values.feedbackHr,
      boardId: values.board.value,
      links: values.links,
    };
    const formData = new FormData();
    for (const property in body) {
      if (body[property] instanceof File) {
        formData.append(property, body[property], body[property].name);
        continue;
      }

      if (body[property] instanceof Array) {
        for (const item of body[property]) {
          formData.append("links[]", [item]);
        }
        continue;
      }

      if (property === "position" && !body[property]) {
        continue;
      }

      formData.append(property, body[property]);
    }

    editCandidate(formData);
  };

  const renderTypeForms = useCallback(
    (formik) => {
      switch (typeForm) {
        case "first":
          const availableInputSymbols = 30;
          const date = moment(formik.values?.birthday).format("YYYY-MM-DD");
          return (
            <>
              <CountOfStepWrapper>
                <CountOfStepNumber>1</CountOfStepNumber>
                <CountOfStepText>
                  {v_hr_people.candidates_modal.personal}
                </CountOfStepText>
              </CountOfStepWrapper>

              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_name}</TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      type="text"
                      value={formik.values.firstName}
                      width={"260px"}
                      onChange={formik.handleChange("firstName")}
                      onBlur={formik.handleBlur}
                      placeholder={v_hr_people.candidates_modal.namePlace}
                      maxLength="30"
                      hasError={formik.errors.title}
                      withValidation
                    />
                    <SymbolsCountForDescription>
                      {availableInputSymbols - formik.values?.firstName.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                  {ErrorMessage(formik.errors.title)}
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_surname}</TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      type="text"
                      value={formik.values.lastName}
                      width={"260px"}
                      onChange={formik.handleChange("lastName")}
                      onBlur={formik.handleBlur}
                      placeholder={v_hr_people.candidates_modal.surnamePlace}
                      maxLength="30"
                      hasError={formik.errors.title}
                      withValidation
                    />
                    <SymbolsCountForDescription>
                      {availableInputSymbols - formik.values?.lastName.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                  {ErrorMessage(formik.errors.title)}
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_email}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur}
                    placeholder={v_hr_people.candidates_modal.emailPlace}
                    hasError={formik.errors.email}
                    withValidation
                  />
                  {ErrorMessage(formik.errors.email)}
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_billing_info_details.phoneNumber}</TextLabel>
                  <CountryPhoneContainer>
                    <ReactPhoneInput
                      defaultCountry="ua"
                      countryCodeEditable={false}
                      autoFormat={false}
                      placeholder=""
                      value={formik.values.phone}
                      onChange={(value) => {
                        formik.setFieldValue("phone", value);
                      }}
                      onBlur={formik.handleBlur}
                      dark
                      hasError={formik.errors.phone}
                      withValidation
                    />
                  </CountryPhoneContainer>
                  {ErrorMessage(formik.errors.phone)}
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_country}</TextLabel>
                  <CountryContainer
                    onClick={() => {
                      setIsOpenCountriesDropdown((prevState) => !prevState);
                    }}
                  >
                    <div className="flag-input-container-selected">
                      {countries[formik.values.country] ? (
                        <>
                          <img
                            className="flag-input-container-selected-flag"
                            src={countries[formik.values.country].flag}
                            alt=""
                          />
                          <span className="flag-input-container-selected-text">
                            {countries[formik.values.country].name.common}
                          </span>
                        </>
                      ) : (
                        <span className="flag-input-container-selected-text-empty">
                          {v_hr_people.candidates_modal.countryPlace}
                        </span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        "flag-input-container-select-arrow",
                        {
                          rotated: isOpenCountriesDropdown,
                        },
                      )}
                    />
                    {isOpenCountriesDropdown && (
                      <CountriesDropdown
                        inputPlaceholder={
                          v_hr_people.candidates_modal.countryPlace
                        }
                        epmtyText={"empty"}
                        onSelect={(item, e) => {
                          e.stopPropagation();
                          formik.setFieldValue("country", item.code);
                          setIsOpenCountriesDropdown(false);
                        }}
                      />
                    )}
                  </CountryContainer>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_city}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.city}
                    onChange={formik.handleChange("city")}
                    onBlur={formik.handleBlur}
                    placeholder={v_city}
                  />
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>
                    {v_resource_planning_page.v_birthday_date}
                  </TextLabel>
                  <DateContainer>
                    <TextLabelB>
                      {date !== "Invalid date"
                        ? date
                        : v_resource_planning_page.v_birthday_date}
                    </TextLabelB>
                    <DateRangeSelect
                      onChangeDate={(date) => {
                        if (date instanceof Date && !isNaN(date)) {
                          formik.setFieldValue(
                            "birthday",
                            moment(date).format(),
                          );
                        }
                      }}
                      isDayCalendar={true}
                    />
                  </DateContainer>
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.jobType}</TextLabel>
                  <JobTypeBlock>
                    {jobType.map((item) => (
                      <JobTypeButton
                        key={item.value}
                        onClick={() =>
                          handleJobTypeClick(formik.setFieldValue, item.value)
                        }
                        style={{
                          background:
                            formik.values.jobType === item.value
                              ? "#52565F"
                              : "none",
                        }}
                      >
                        {item.label}
                      </JobTypeButton>
                    ))}
                  </JobTypeBlock>
                </LabelContainer>
              </RowContainer>
              <SectionLine />
              <ButtonContainer>
                <div />
                <SaveButton
                  type="button"
                  onClick={async () => {
                    const errors = await formik.validateForm();

                    if (!errors.title && !errors.email && !errors.phone) {
                      handleNextSlide("second");
                    }
                  }}
                >
                  {v_subscription.billingModalDowngrade.button}
                </SaveButton>
              </ButtonContainer>
            </>
          );

        case "second":
          const availableTags = 5;
          return (
            <>
              <CountOfStepWrapper>
                <CountOfStepNumber>2</CountOfStepNumber>
                <CountOfStepText>
                  {v_hr_people.candidates_modal.social}
                </CountOfStepText>
              </CountOfStepWrapper>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{"Messenger"}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.messenger}
                    onChange={formik.handleChange("messenger")}
                    onBlur={formik.handleBlur}
                    placeholder={"www.facebook.com/..."}
                  />
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{"LinkedIn"}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.linkedin}
                    onChange={formik.handleChange("linkedin")}
                    onBlur={formik.handleBlur}
                    placeholder={"www.linkedin.com/in/..."}
                  />
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{"GitHub"}</TextLabel>
                  <Input
                    type="text"
                    value={formik.values.github}
                    onChange={formik.handleChange("github")}
                    onBlur={formik.handleBlur}
                    placeholder={"https://github.com/..."}
                  />
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.other}</TextLabel>
                  <LanguageWrapper>
                    <LinkToVacanciesComponent
                      userTechnologies={formik.values.linksObj}
                      setUserTechnologies={(techArr) => {
                        formik.setFieldValue("linksObj", techArr);
                        const techArrString = techArr.map(
                          (option) => option.link,
                        );
                        formik.setFieldValue("links", techArrString);
                      }}
                      showNotificationAction={false}
                      vocabulary={vocabulary}
                      themeLight={true}
                      title={"Language skills"}
                      placeholder={v_hr_people.candidates_modal.otherPlace}
                      availableTags={availableTags}
                      disableParam={formik.values?.links.length}
                      hasError={formik.errors.linksArray}
                      withValidation
                    />
                    {ErrorMessage(formik.errors.linksArray)}
                    <SymbolsCountForDescription>
                      {availableTags - formik.values?.links.length}
                    </SymbolsCountForDescription>
                  </LanguageWrapper>
                </LabelContainer>
              </RowContainer>
              <SectionLine />
              <CountOfStepWrapper>
                <CountOfStepNumber>3</CountOfStepNumber>
                <CountOfStepText>
                  {v_hr_people.candidates_modal.start_work}
                </CountOfStepText>
              </CountOfStepWrapper>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.desire}</TextLabel>
                  <DateContainer>
                    <DatepickerSelect
                      disableToolbar={false}
                      date={formik.values.desiredDate}
                      onChangeDate={(date) => {
                        formik.setFieldValue(
                          "desiredDate",
                          moment(date).format(),
                        );
                      }}
                      placeholder={v_hr_people.candidates_modal.desirePlace}
                      dropdownTitle={v_hr_people.candidates_modal.desirePlace}
                    />
                  </DateContainer>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.start}</TextLabel>
                  <DateContainer>
                    <DatepickerSelect
                      disableToolbar={false}
                      date={formik.values.startWork}
                      onChangeDate={(date) => {
                        formik.setFieldValue(
                          "startWork",
                          moment(date).format(),
                        );
                      }}
                      placeholder={v_hr_people.candidates_modal.startPlace}
                      dropdownTitle={v_hr_people.candidates_modal.startPlace}
                    />
                  </DateContainer>
                </LabelContainer>
              </RowContainer>
              <SectionLine />
              <ButtonContainer>
                <CancelButton
                  type="button"
                  onClick={() => handleNextSlide("first")}
                >
                  <BackArrowSvg /> {v_back}
                </CancelButton>
                <SaveButton
                  type="button"
                  onClick={async () => {
                    const errors = await formik.validateForm();
                    formik.setErrors({});
                    handleNextSlide("third");
                  }}
                >
                  {v_subscription.billingModalDowngrade.button}
                </SaveButton>
              </ButtonContainer>
            </>
          );

        case "third":
          return (
            <>
              <CountOfStepWrapper>
                <CountOfStepNumber>4</CountOfStepNumber>
                <CountOfStepText>
                  {v_hr_people.candidates_modal.about}
                </CountOfStepText>
              </CountOfStepWrapper>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>
                    {v_hr_people.candidates_modal.experience}
                  </TextLabel>
                  <InputSmall
                    type="text"
                    value={formik.values.experience}
                    onChange={formik.handleChange("experience")}
                    onBlur={formik.handleBlur}
                  />
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.cv}</TextLabel>
                  <FileContainer>
                    <FileLabel>
                      <InputFile
                        name="CVfile"
                        type="file"
                        accept=".pdf"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "file",
                            event.currentTarget.files[0],
                          );
                          setCvPlace(
                            event.currentTarget.value.split("\\").pop(),
                          );
                        }}
                        hasError={formik.errors.file}
                        withValidation
                      />
                    </FileLabel>
                    <FilePlaceholder>
                      {candidates?.["cv_file"]
                        ? candidates?.["cv_file"]?.split("/").pop()
                        : cvPlace}
                    </FilePlaceholder>
                  </FileContainer>
                  <AlertLabel>{ErrorMessage(formik.errors.file)}</AlertLabel>
                  <AlertLabel>
                    {"Format: PDF. The maximum size is 1 MB."}
                  </AlertLabel>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.cvClient}</TextLabel>
                  <FileContainer>
                    <FileLabel>
                      <InputFile
                        name="CVforClientFile"
                        type="file"
                        accept=".pdf"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "clientFile",
                            event.currentTarget.files[0],
                          );
                          setCvClientPlace(
                            event.currentTarget.value.split("\\").pop(),
                          );
                        }}
                        hasError={formik.errors.clientFile}
                        withValidation
                      />
                    </FileLabel>
                    <FilePlaceholder>
                      {candidates?.["cv_client"]
                        ? candidates?.["cv_client"]?.split("/").pop()
                        : cvClientPlace}
                    </FilePlaceholder>
                  </FileContainer>
                  <AlertLabel>
                    {ErrorMessage(formik.errors.clientFile)}
                  </AlertLabel>
                  <AlertLabel>
                    {"Format: PDF. The maximum size is 1 MB."}
                  </AlertLabel>
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>
                    {v_hr_people.candidates_modal.direction}
                    <RequiredfieldStar>*</RequiredfieldStar>
                  </TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.candidates_modal.direction}
                    options={directionOptions}
                    onChange={({ value, label }) => {
                      formik.setFieldValue("board", {
                        label: label,
                        value: value,
                      });
                      formik.setFieldValue("boardId", value);
                      setNewBoardId(value);
                    }}
                    value={formik.values.board.label}
                    setDirectionArrayOptions={setDirectionOptions}
                    scroll
                  />
                  {ErrorMessage(formik.errors.boardId)}
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>
                    {availableVacancies
                      ? v_hr_people.candidates_modal.vacancy
                      : v_hr_people.candidates_modal.noVacancies}
                  </TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.candidates_modal.vacancy}
                    options={vacancyOptions}
                    onChange={({ value, label }) => {
                      formik.setFieldValue("vacancy", {
                        label: label,
                        value: value,
                      });
                      formik.setFieldValue("vacancyId", value);
                    }}
                    value={formik.values.vacancy.label}
                    setDirectionArrayOptions={setVacancyOptions}
                    scroll
                    available={availableVacancies}
                    vacanciesDataFromRedux={vacanciesDataFromRedux}
                    handleGetVacanciesForDropdown={
                      handleGetVacanciesForDropdown
                    }
                  />
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.level}</TextLabel>
                  <DropdownWithSearch
                    isSearchable={false}
                    isMulti={false}
                    placeHolder={v_hr_people.candidates_modal.level}
                    options={levelOptions}
                    onChange={({ value, label }) =>
                      formik.setFieldValue("level", label)
                    }
                    value={formik.values.level}
                  />
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.salary}</TextLabel>
                  <SalaryContainer>
                    <SalaryWrapper>
                      <InputSmall
                        type="text"
                        value={formik.values.minSalary}
                        onChange={handleAmountChange(
                          formik.setFieldValue,
                          "minSalary",
                        )}
                        onBlur={formik.handleBlur}
                        placeholder={v_hr_people.candidates_modal.minSalPlace}
                      />
                    </SalaryWrapper>
                    <SalaryWrapper>
                      <InputSmall
                        type="text"
                        value={formik.values.maxSalary}
                        onChange={handleAmountChange(
                          formik.setFieldValue,
                          "maxSalary",
                        )}
                        onBlur={formik.handleBlur}
                        placeholder={v_hr_people.candidates_modal.maxSalPlace}
                      />
                    </SalaryWrapper>
                    <SalaryWrapper>
                      <CurrencyBlock>
                        <CurrencySelect
                          selectedCurrency={formik.values.currency}
                          onChange={(value) =>
                            formik.setFieldValue("currency", value)
                          }
                        />
                      </CurrencyBlock>
                    </SalaryWrapper>
                  </SalaryContainer>
                </LabelContainer>
              </RowContainer>
              <SectionLine />
              <ButtonContainer>
                <CancelButton
                  type="button"
                  onClick={() => handleNextSlide("second")}
                >
                  <BackArrowSvg /> {v_back}
                </CancelButton>
                <SaveButton
                  type="button"
                  onClick={async () => {
                    const errors = await formik.validateForm();
                    if (!errors.file && !errors.clientFile && !errors.boardId) {
                      handleNextSlide("fourth");
                    }
                  }}
                >
                  {v_subscription.billingModalDowngrade.button}
                </SaveButton>
              </ButtonContainer>
            </>
          );

        case "fourth":
          const availableSymbols = 1000;
          return (
            <>
              <CountOfStepWrapper>
                <CountOfStepNumber>5</CountOfStepNumber>
                <CountOfStepText>
                  {v_hr_people.candidates_modal.booking}
                </CountOfStepText>
              </CountOfStepWrapper>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.hrInt}</TextLabel>
                  <DateContainer>
                    <DatepickerSelect
                      disableToolbar={false}
                      date={formik.values.interviewDate}
                      onChangeDate={(date) => {
                        formik.setFieldValue(
                          "interviewDate",
                          moment(date).format(),
                        );
                      }}
                      placeholder={v_hr_people.candidates_modal.hrIntPlace}
                      dropdownTitle={v_hr_people.candidates_modal.hrIntPlace}
                    />
                  </DateContainer>
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.techInt}</TextLabel>
                  <DateContainer>
                    <DatepickerSelect
                      disableToolbar={false}
                      date={formik.values.techInterviewDate}
                      onChangeDate={(date) => {
                        formik.setFieldValue(
                          "techInterviewDate",
                          moment(date).format(),
                        );
                      }}
                      placeholder={v_hr_people.candidates_modal.techIntPlace}
                      dropdownTitle={v_hr_people.candidates_modal.techIntPlace}
                    />
                  </DateContainer>
                </LabelContainer>
              </RowContainer>
              <RowContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.feedHr}</TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      type="text"
                      value={formik.values.feedbackHr}
                      width={"260px"}
                      height={"90px"}
                      onChange={formik.handleChange("feedbackHr")}
                      onBlur={formik.handleBlur}
                      placeholder={v_hr_people.candidates_modal.feedHrPlace}
                      maxLength="1000"
                      hasError={formik.errors.feedback}
                      withValidation
                    />
                    <SymbolsCountForDescription>
                      {availableSymbols - formik.values.feedbackHr.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                  {ErrorMessage(formik.errors.title)}
                </LabelContainer>
                <LabelContainer>
                  <TextLabel>{v_hr_people.candidates_modal.feedTech}</TextLabel>
                  <TextareaBlock>
                    <AutoExpandingTextarea
                      type="text"
                      value={formik.values.feedbackTech}
                      width={"260px"}
                      height={"90px"}
                      onChange={formik.handleChange("feedbackTech")}
                      onBlur={formik.handleBlur}
                      placeholder={v_hr_people.candidates_modal.feedTechPlace}
                      maxLength="1000"
                      hasError={formik.errors.feedback}
                      withValidation
                    />
                    <SymbolsCountForDescription>
                      {availableSymbols - formik.values.feedbackTech.length}
                    </SymbolsCountForDescription>
                  </TextareaBlock>
                  {ErrorMessage(formik.errors.title)}
                </LabelContainer>
              </RowContainer>
              <SectionLine />
              <ButtonContainer>
                <CancelButton
                  type="button"
                  onClick={() => handleNextSlide("third")}
                >
                  <BackArrowSvg /> {v_back}
                </CancelButton>
                <SaveButton
                  type="button"
                  onClick={async () => {
                    await formik.handleSubmit();
                    closeCardModal();
                  }}
                >
                  {v_save}
                </SaveButton>
              </ButtonContainer>
            </>
          );

        default:
          return null;
      }
    },
    [
      typeForm,
      selectedJobType,
      isOpenCountriesDropdown,
      directionOptions,
      vacancyOptions,
      availableVacancies,
      cvPlace,
      cvClientPlace,
    ],
  );

  return (
    <Modal
      open={isEditCard || !candidates}
      title={`${v_hr_people.header.candidates}`}
      headerStatusInModal={
        isEditCard ? (
          <TitleSubText>{v_hr_people.candidates_modal.draft}</TitleSubText>
        ) : null
      }
      onClose={closeCardModal}
      headerAdditionalElements={renderLines()}
      classes={{
        header: "recruitment-modal__header",
        title: "recruitment-modal__title",
        wrapper: "recruitment-modal",
        container: "recruitment-modal__container",
      }}
    >
      <div>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            fileHandler(values);
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                {renderTypeForms(formik)}
              </StyledForm>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
  createCandidateInfo,
  editCandidateInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardModalForm);
