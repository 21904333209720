import React from "react";

const HighPriority = () => (
  <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 15.0001L12 9.00012L6 15.0001" stroke="#EB5757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 20.0001L12 14.0001L6 20.0001" stroke="#EB5757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default HighPriority;

