import React from "react";

import "./style.scss";

const Panel = ({ header, content, className }) => (
  <div className={`panel ${className || ""}`}>
    {header ? <div className="panel__header">{header}</div> : null}
    {content ? <div className="panel__content">{content}</div> : null}
  </div>
);

export default Panel;
