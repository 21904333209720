import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import cn from "classnames";

// Services
import {
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
} from "../../services/tokenStorageService";
import { getUtcOffsetInMilliseconds } from "../../services/timeService";
import { signIn } from "../../api/user";
import { updatePageTitle } from "../../services/pageTitleService";

// Components
import SwitchLanguageLogin from "../../components/Unstructured/SwitchLanguageLogin";
import FacebookButton from "../../components/Unstructured/FacebookButton";
import LoginForm from "../../components/Unstructured/LoginForm";
import GoogleButton from "../../components/Unstructured/GoogleButton/index";

// Actions
import reportsPageAction from "../../redux/actions/ReportsPageAction";
import { showNotificationAction } from "../../redux/actions/NotificationActions";

// Config
import { AppConfig } from "../../config";

// Styles
import "./style.scss";
import { Helmet } from "react-helmet";

class AuthPage extends Component {
  state = {
    haveToken: false,
  };

  setHaveToken = () =>
    this.setState({
      haveToken: true,
      isLoading: false,
    });

  submitForm = async (values) => {
    const { vocabulary, showNotificationAction } = this.props;

    this.setState({ isLoading: true });
    try {
      const response = await signIn({
        ...values,
        timezoneOffset: getUtcOffsetInMilliseconds(),
      });

      updatePageTitle(null); // update page title

      setTokenToLocalStorage(response.data.token);
      document.cookie = "isAuthWobbly=true; path=/; domain=.wobbly.me;";
      this.setState({ haveToken: true });
    } catch (error) {
      const errorMsg = error.response.data.message;

      if (errorMsg === "Your email or password is incorrect. Try again.") {
        showNotificationAction({
          text: vocabulary.v_incorrect_password_or_email,
          type: "error",
        });
      } else {
        showNotificationAction({
          text: vocabulary.v_a_error_authorization,
          type: "error",
        });
        console.log(error);
      }
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { haveToken } = this.state;
    const { history, vocabulary } = this.props;
    const {
      v_forgot_your_password,
      v_dont_have_an_account_yet,
      v_sign_up,
      v_or_continue_with,
    } = vocabulary;

    if (haveToken || getTokenFromLocalStorage()) {
      return <Redirect to={"/timer"} />;
    }

    return (
      <>
        <Helmet>
          <title>Login to Wobbly – Access Your Time Tracking Tool</title>
          <link rel="canonical" href="https://time.wobbly.me/login" />
          <link
            rel="description"
            content="Access Wobbly Time's advanced time tracking tool by logging into your account. Secure, user-friendly, and efficient, our platform will help you manage your projects effortlessly."
          />
        </Helmet>
        <div className="wrapper_authorisation_page">
          <div className="fixed_right_corner">
            <SwitchLanguageLogin dropdown />
          </div>
          <i
            className={cn("page_title", {
              page_title__light: localStorage.getItem("themes") === "light",
            })}
          />
          <div className="authorisation_container">
            <LoginForm
              submitForm={this.submitForm}
              isLoading={this.state.isLoading}
            />
            {AppConfig.socialAuth.active && (
              <div>
                <div className="lines">
                  <div className="line" />
                  <div className={"or"}>{v_or_continue_with}</div>
                  <div className="line" />
                </div>
                <div className="social-auth">
                  <GoogleButton
                    setHaveToken={this.setHaveToken}
                    login={this.login}
                  />
                  <FacebookButton
                    setHaveToken={this.setHaveToken}
                    login={this.login}
                  />
                </div>
              </div>
            )}
            <button
              type="button"
              className="forgot_password_button"
              onClick={(e) => history.push("/forgot-password")}
            >
              {v_forgot_your_password}?
            </button>
          </div>
          <button
            onClick={(e) => history.push("/register")}
            className="register-block__button register-block__button--to-login"
            type="button"
          >
            {v_dont_have_an_account_yet}? {v_sign_up}
          </button>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isMobile: state.responsiveReducer.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  reportsPageAction: (actionType, toggle) =>
    dispatch(reportsPageAction(actionType, toggle))[1],
  showNotificationAction: (payload) =>
    dispatch(showNotificationAction(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthPage),
);
