import React from "react";

const SearchIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.41699 2.33335C4.16183 2.33335 2.33366 4.16152 2.33366 6.41669C2.33366 8.67185 4.16183 10.5 6.41699 10.5C8.67215 10.5 10.5003 8.67185 10.5003 6.41669C10.5003 4.16152 8.67215 2.33335 6.41699 2.33335ZM1.16699 6.41669C1.16699 3.51719 3.5175 1.16669 6.41699 1.16669C9.31649 1.16669 11.667 3.51719 11.667 6.41669C11.667 9.31618 9.31649 11.6667 6.41699 11.6667C3.5175 11.6667 1.16699 9.31618 1.16699 6.41669Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29976 9.30007C9.52757 9.07226 9.89691 9.07226 10.1247 9.30007L12.6622 11.8376C12.89 12.0654 12.89 12.4347 12.6622 12.6625C12.4344 12.8903 12.0651 12.8903 11.8373 12.6625L9.29976 10.125C9.07195 9.89722 9.07195 9.52787 9.29976 9.30007Z" fill="white"/>
  </svg>
);

export default SearchIcon;
