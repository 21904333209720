import styled from "styled-components/macro";
import { Field, Form } from "formik";
// import ArrowImage from "../../../../assets/images/icons/arrow-down-gray.svg"
import ArrowImage from "../../../../assets/images/icons/arrow-down.svg"

export const StyledForm = styled(Form) `
  margin: 0 5%;
  margin-bottom: 10%;
  padding-bottom: 50px;
`;

export const DropdownContainer = styled.div `
  margin-top: 30px;
  & > div {
  }
`;

export const DropdownContainerRow = styled.div `
  display: flex;
  margin-top: 30px;
  gap: 20px;
  & > div {
  }
`;
export const DropdownContainerRowWrapper = styled.div `
  width:  100%;
`
export const SingleDropdownWrapper=styled.div`
  margin-top: 30px;
  max-width: 240px;
`

export const Input = styled(Field) `
  background: none;
  color: var(--text);
  border-radius: 8px;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : 'var(--border-color-team)')};
  padding: 10px 15px;
  width: 100%;
  resize: none;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
  ::placeholder {
    font-weight: 500;
    color: #b9bbbe;
    font-size: 14px;
  }
`;
export const TextareaBlock=styled.div`
  position: relative;
`


export const SymbolsCountForDescription=styled.div`
  position: absolute;
  right: 15px;
  top: 12px;
  background-color: var(--border-color-subscription);
  color: var(--color-resource-plaaning);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border-radius: 4px;
  user-select: none;
`

export const LabelContainer = styled.div `
  width: 100%;
`;

export const ErrorText = styled.div `
  color: red;
  margin-top: 5px;
  position: absolute;
`;

export const DateContainer = styled.div `
  position: relative;
  p{
    z-index: 22;
    position: absolute;
    margin-left: 20px;
  }
  .daterange-select__date-separator{
    display: none;
  }
  .daterange-select__selected-date{
    justify-content: flex-end;
  }
  .daterange-select__date{
    display: none;
  }
  .daterange-select__selected-date {
    background: none;
  }
`;

export const TextLabel = styled.p `
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 15px;
`;

export const ButtonContainerForFirstStep=styled(ButtonContainer)`
  justify-content: end;
`

export const Button = styled.button `
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  height: 44px;
  @media (max-width: 415px) {
    padding: 14px 14px;
  }
`;

export const SalaryContainer = styled.div `
  display: flex;
  gap: 20px;
`;

export const SalaryWrapper = styled.div `
  // margin: 0 5px;

`;

export const SaveButton = styled(Button) `
  background: #00b173;
`;

export const DeleteButton = styled(Button) `
  background: #eb5757;
`;

export const CancelButton = styled(Button) `
  display: flex;
  gap: 10px;
  background: inherit;
`;

export const LabelPhoneInput = styled.div `
  position: absolute;
  left: 15px;
  p{
    font-size: 16px;
    color: #B9BBBE;
  }
`;

export const CountryPhoneContainer = styled.div `
    width: 100%;
    
    position: relative;
    display: flex;
    align-items: center;

    .react-tel-input {
      display: flex;
      border: var(--invoices-detailed-border-input);
      //box-shadow: var(--project-box-shadow);
      height: 44px;

      .selected-flag {
        z-index: 0;
        background: none !important;
      }
    }
    .flag {
      right: 38px !important;
    }
    .form-control {
      background: var(--background-box-time-day);
      border: 0;
      color: var(--user-setting-color);
      font-size: 16px;
      height: 45px;
      line-height: 22px;
      padding: 1px 0 1px 58px;
      width: 100%;
      display: none;
    }
    .flag-dropdown {
      background: none;
      border: 0;
      border-radius: 4px 0 0 4px;
      width: 100%;

      &:hover .selected-flag {
        background: transparent;
        border-radius: 4px 0 0 4px;
      }
    }
  
  .flag-dropdown.open{
    background: none !important;
  }

    .arrow {
      background: url(${ArrowImage})
      no-repeat center !important;
      border: none !important;
      height: 24px !important;
      left: 15px !important;
      margin-top: -2px !important;
      position: relative !important;
      top: -4px !important;
      transition: all 0.3s !important;
      width: 24px !important;
    }

    .arrow.up {
      border: none;
      transform: rotate(-180deg);
    }

    .selected-flag {
      //background: var(--background-box-time-day) !important;
      border: 1px solid #4d5159;
      border-radius: 5px !important;
      width: 100%;
    }

    .country-list {
      background: var(--background-box-time-day);
      box-shadow: 2px 2px 4px var(--reports-export-modal-shadow);
      padding: 5px;
      top: 40px;
      width: 312px;
      margin: 0 !important;
    }

    .country {
      color: var(--user-setting-dropdown-color);
      padding: 5px 10px;

      &:hover {
        background-color: var(--bg-hover-user-setting-list-item) !important;
        color: var(--user-setting-dropdown-color);
      }
      .dial-code {
        color: #b9bbbe;
      }
    }

  .search{
    top: -6px !important;
    background-color: var(--bg-hover-user-setting-list-item) !important;
  }

    .highlight {
      background-color: var(--bg-hover-invoices-list-item) !important;
      color: var(--user-setting-dropdown-color) !important;
    }
`;

export const LanguageWrapper = styled.div `
  margin: 0 5px;
  input{
    color: white;
  }
`;

export const StyledSVG = styled.div<{ active?: boolean }> `
  padding: 0 2px;
  svg{
    width: ${(props) => (props.active ? '50px' : '25px')};
    transition: fill 0.3s ease, width 0.3s ease; /* плавний перехід */
  }
  rect {
    width: ${(props) => (props.active ? '50px' : '25px')};
    fill: ${(props) => (props.active ? '#00B173' : '#52565F')};
    transition: fill 0.3s ease, width 0.3s ease; /* плавний перехід */
  }
`;

export const LinesContainer = styled.div `
  display: flex;
  position: absolute;
  right: 88px;
`;

export const CountOfStepWrapper=styled.div`
  display: flex;
  font-size: 14px;
  color: #FFF;
  gap: 10px;
  align-items: center;
`
export const CountOfStepNumber=styled.div`
  display:  flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background: #52565F;
  border-radius: 4px;
`
export const CountOfStepText=styled.span`
  font-style: normal;
  font-weight: 700;
`
export const RequiredfieldStar=styled.span`
  color: #EB5757;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

export const SectionLine=styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  height: 1px;
  background: #52565F;
  width: 100%;
`

export const JobTypeBlock=styled.div`
  display: flex;
  gap: 10px;
`

export const JobTypeButton=styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #FFF;
  display: flex;
  padding: 10px 24px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #52565F;
  cursor: pointer;
`

export const CurrencyBlock=styled.div`
background: none;
color: var(--text);
border-radius: 8px;
border: 1px solid var(--border-color-team);
padding: 10px 15px;
width: 100%;
resize: none;
font-size: 16px;
font-weight: 600;
height: 44px;
`
export const TitleSubText=styled.div`
  margin-left:10px;
  display: flex;
  line-height: normal;
  color: var(--text);
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #52565F;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`