import { TOGGLE_TEAM_ADD_MODAL } from "../actions/TeamAddPageAction";

const initialState = {
  teamAddModalToggle: false,
};

export function teamAddReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TEAM_ADD_MODAL:
      return { ...state, teamAddModalToggle: action.payload };

    case "RESET_ALL":
      return initialState;

    default:
      return state;
  }
}
